import { ListItem } from '@material-ui/core';

import { Paragraph } from '../../components';
import { theme } from '../../theme';

export const BulletItem = ({ children }: { children: React.ReactNode }) => (
  <ListItem
    sx={{
      py: 1,
      display: 'list-item',
      color: theme.colors.primary,
      '::marker': { fontSize: '20px' },
    }}
  >
    <Paragraph sx={{ my: 0 }}>{children}</Paragraph>
  </ListItem>
);
