import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Box,
  ButtonBase,
  Drawer,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { track } from 'analytics/analytics';
import { IBillingPaymentMethod } from 'apis/billing';
import { KDSIcons } from 'assets/images/kds_icons';
import { BillingPaymentMethodOrganization } from 'billing/models/PaymentMethodOrganization';
import { Button } from 'components';
import { useToggle } from 'hooks/useToggle';
import { theme } from 'theme';

import { AddCardMethod, CardMethod, KOHOMethod } from './PaymentMethod';
import { PaymentMethodsSelect } from './PaymentMethodsSelect';

type PaymentMethodProps = {
  balance: string | undefined;
  closeModal: () => void;
  handleChange: (value: string | undefined) => void;
  kohoDisabled: boolean;
  openBillingMethodsModal: () => void;
  paymentMethods: IBillingPaymentMethod[];
};

const PaymentMethodButtons = ({
  balance,
  closeModal,
  handleChange,
  kohoDisabled,
  openBillingMethodsModal,
  paymentMethods,
}: PaymentMethodProps) => {
  return (
    <>
      {paymentMethods.map((method) => {
        const isKohoMethod =
          method.organization === BillingPaymentMethodOrganization.Koho;
        return (
          <Box
            key={method.id}
            display="flex"
            flexDirection="row"
            sx={{ alignItems: 'center', py: 3 }}
            onClick={() => {
              if (isKohoMethod && kohoDisabled) return;

              handleChange(method.id);
              closeModal();
            }}
          >
            {isKohoMethod ? (
              <KOHOMethod balance={balance} disabled={kohoDisabled} />
            ) : (
              <CardMethod method={method} />
            )}
          </Box>
        );
      })}
      <Button
        sx={{ alignItems: 'center', py: 3 }}
        trackName="add-debit-credit-card"
        variant="clear"
        onClick={() => {
          openBillingMethodsModal();
          closeModal();
        }}
      >
        <AddCardMethod />
      </Button>
    </>
  );
};

const PaymentMethodRadios = ({
  balance,
  closeModal,
  handleChange,
  initialPaymentMethod,
  kohoDisabled,
  openBillingMethodsModal,
  paymentMethods,
}: PaymentMethodProps & { initialPaymentMethod: string | undefined }) => {
  const intl = useIntl();
  const [selectedId, setSelectedId] = useState<string | undefined>(
    initialPaymentMethod,
  );

  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="payment methods"
          name="paymentMethods"
          onChange={(event) => setSelectedId(event.target.value)}
          value={selectedId}
        >
          {paymentMethods.map((method) => {
            const isKohoMethod =
              method.organization === BillingPaymentMethodOrganization.Koho;

            return (
              <FormControlLabel
                key={method.id}
                value={method.id}
                sx={{
                  justifyContent: 'space-between',
                  py: 4,
                  ml: 0,
                  borderBottom: `1px solid ${theme.colors.tertiaryT100}`,
                  color: theme.colors.primary,
                }}
                control={<Radio color="primary" />}
                label={
                  <Box display="flex" sx={{ width: '100%' }}>
                    {isKohoMethod ? (
                      <KOHOMethod balance={balance} disabled={kohoDisabled} />
                    ) : (
                      <CardMethod method={method} />
                    )}
                  </Box>
                }
                labelPlacement="start"
                disabled={isKohoMethod && kohoDisabled}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <Button
        sx={{ alignItems: 'center', py: 4, mb: 2 }}
        trackName="add-debit-credit-card"
        variant="clear"
        onClick={() => {
          openBillingMethodsModal();
          closeModal();
        }}
      >
        <AddCardMethod />
      </Button>
      <Button
        sx={{ bg: theme.colors.blueNavy, fontWeight: 900 }}
        trackName="save-payment-method"
        onClick={() => {
          handleChange(selectedId);
          closeModal();
        }}
      >
        {intl.formatMessage({
          id: 'PaymentMethodsDrawer.Save',
          defaultMessage: 'Save',
        })}
      </Button>
    </>
  );
};

type Props = {
  balance: string | undefined;
  customMobileSelect?: (method: IBillingPaymentMethod | undefined) => ReactNode;
  customTitle?: ReactNode;
  handleChange: (value: string | undefined) => void;
  kohoDisabled: boolean;
  openBillingMethodsModal: () => void;
  paymentMethods: IBillingPaymentMethod[];
  selectedMethod: string | undefined;
  useRadios: boolean;
};

export const PaymentMethodsDrawer = ({
  balance,
  customMobileSelect,
  customTitle,
  handleChange,
  kohoDisabled,
  openBillingMethodsModal,
  paymentMethods,
  selectedMethod,
  useRadios,
}: Props) => {
  const intl = useIntl();
  const { value: isOpen, on: open, off: close } = useToggle();

  const currentlySelectedMethod = paymentMethods.find(
    (method) => method.id === selectedMethod,
  );

  return (
    <>
      <ButtonBase
        sx={{ width: '100%' }}
        onClick={() => {
          open();
          track({
            event: 'Clicked',
            properties: { name: 'choose-other-payment-method' },
          });
        }}
      >
        {customMobileSelect ? (
          customMobileSelect(currentlySelectedMethod)
        ) : (
          <PaymentMethodsSelect
            disabled
            balance={balance}
            handleChange={handleChange}
            kohoDisabled={kohoDisabled}
            openBillingMethodsModal={openBillingMethodsModal}
            paymentMethods={paymentMethods}
            selectedMethod={selectedMethod}
          />
        )}
      </ButtonBase>
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={close}
        disableScrollLock
        sx={{
          '& .MuiPaper-root': {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            padding: 4,
            paddingBottom: 6,
            minHeight: 200,
          },
        }}
      >
        <ButtonBase onClick={close} sx={{ width: 'fit-content', ml: 'auto' }}>
          <KDSIcons.Icons.Cross height={20} width={20} />
        </ButtonBase>
        <Typography
          sx={{
            mb: useRadios ? 0 : 5,
            fontWeight: 900,
            fontSize: 32,
            color: theme.colors.text,
          }}
        >
          {customTitle ??
            intl.formatMessage({
              id: 'PaymentMethod.ChoosePaymentModal',
              defaultMessage: 'Choose a payment method',
            })}
        </Typography>
        {useRadios ? (
          <PaymentMethodRadios
            balance={balance}
            closeModal={close}
            handleChange={handleChange}
            initialPaymentMethod={selectedMethod}
            kohoDisabled={kohoDisabled}
            openBillingMethodsModal={openBillingMethodsModal}
            paymentMethods={paymentMethods}
          />
        ) : (
          <PaymentMethodButtons
            balance={balance}
            closeModal={close}
            handleChange={handleChange}
            kohoDisabled={kohoDisabled}
            openBillingMethodsModal={openBillingMethodsModal}
            paymentMethods={paymentMethods}
          />
        )}
      </Drawer>
    </>
  );
};
