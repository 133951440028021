import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectInboundETransferState = (state: RootState) =>
  state.inboundETransfer;

export const selectInboundETransferRetrieveError = createSelector(
  selectInboundETransferState,
  (state) => state.transferRetrieveError,
);

export const selectInboundETransfer = createSelector(
  selectInboundETransferState,
  (state) => state.transfer,
);

export const selectInboundETransferAnswer = createSelector(
  selectInboundETransferState,
  (state) => state.transferForm?.securityAnswer,
);

export const selectInboundETransferShowIncorrectAnswerError = createSelector(
  selectInboundETransferState,
  (state) => state.transferForm?.showIncorrectAnswerError,
);

export const selectInboundETransferSubmissionError = createSelector(
  selectInboundETransferState,
  (state) => state.transferSubmissionError,
);
export const selectInboundETransferSubmissionIntent = createSelector(
  selectInboundETransferState,
  (state) => state.intent,
);

// API Selectors ⬇️

export const selectInboundETransferRetrieveLoadable = createSelector(
  selectInboundETransferState,
  (state) => state.loadableRetrieveTransfer,
);

export const selectInboundETransferDeclineLoadable = createSelector(
  selectInboundETransferState,
  (state) => state.loadableDeclineTransfer,
);

export const selectInboundETransferAcceptLoadable = createSelector(
  selectInboundETransferState,
  (state) => state.loadableAcceptTransfer,
);
