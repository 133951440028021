/** @jsxImportSource theme-ui */
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Box } from '@material-ui/core';
import deposit from 'assets/images/kds_icons/spot/coin.svg';
import { Button, LocaleContext, TitleMedium } from 'components';
import { TextInput, Validators } from 'components/forms';
import { InboundETransferActions } from 'inboundETransfer/store/slice';
import { Loadable } from 'utility/loadable';

import interac from '../../assets/images/logos/interac-logo.png';

interface TransferDetailProps {
  label: string;
  isNumber?: boolean;
  last?: boolean;
}

const TransferDetail: React.FC<TransferDetailProps> = ({
  label,
  isNumber = false,
  last = false,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        marginBottom: last ? '0' : '3',
      }}
      flexWrap="wrap"
    >
      <Box
        flex="1 1 100%"
        sx={{
          color: '#373E4D',
          fontSize: '12px',
          fontWeight: '500',
        }}
      >
        {label}
      </Box>
      <Box
        flex="1 1 100%"
        sx={{
          color: '#373E4D',
          fontFamily: isNumber ? 'numbers' : 'heading',
          fontSize: isNumber ? '32px' : '16px',
          lineHeight: isNumber ? '38px' : '22px',
          fontWeight: isNumber ? '600' : '700',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

interface TransferProps {
  fromName: string;
  amountCents: number;
  question: string;
  memo: string;
  secretAnswerValue: string;
  showIncorrectAnswerError: boolean;
  declineHandler: () => void;
  declineLoadable: Loadable;
  acceptHandler: () => void;
  acceptLoadable: Loadable;
}

export const InboundETransfer: React.FC<TransferProps> = ({
  fromName,
  amountCents,
  question,
  memo,
  secretAnswerValue,
  showIncorrectAnswerError,
  declineHandler,
  declineLoadable,
  acceptHandler,
  acceptLoadable,
}) => {
  const intl = useIntl();
  const { intlFormatMoney } = useContext(LocaleContext);

  const [inputError, setInputError] = useState(false);

  const dispatch = useDispatch();

  return (
    <Box>
      <TitleMedium
        sx={{
          marginBottom: '4',
        }}
      >
        {intl.formatMessage({
          id: 'InboundETransferPage.Heading',
          defaultMessage: 'Deposit this INTERAC e-Transfer® into your account',
        })}
      </TitleMedium>
      <Box
        sx={{
          marginBottom: '2',
          display: 'flex',
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <img src={deposit} alt="" />
        <img
          sx={{
            width: '28px',
            height: '28px',
          }}
          src={interac}
          alt={intl.formatMessage({
            id: 'InboundETransferPage.InteractETransferLogoAlt',
            defaultMessage: 'Interac e-Transfer Logo',
          })}
        />
      </Box>

      <Box
        sx={{
          boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
          borderRadius: '4xp',
          padding: '3',
          marginBottom: '2',
        }}
      >
        <TransferDetail
          label={intl.formatMessage({
            id: 'InboundETransferPage.AmountLabel',
            defaultMessage: 'Amount (CAD)',
          })}
          isNumber
        >
          <span data-cy="transfer-amount">
            {intlFormatMoney(amountCents).format()}
          </span>
        </TransferDetail>
        <TransferDetail
          last={memo ? false : true}
          label={intl.formatMessage({
            id: 'InboundETransferPage.MemoLabel',
            defaultMessage: 'Sent from',
          })}
        >
          <span data-cy="transfer-name">{fromName}</span>
        </TransferDetail>
        {memo ? (
          <TransferDetail label={'Message'} last>
            <span data-cy="transfer-memo">{memo}</span>
          </TransferDetail>
        ) : null}
      </Box>

      <Box
        sx={{
          boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
          borderRadius: '4xp',
          padding: '3',
          marginBottom: '4',
        }}
      >
        <TransferDetail
          label={intl.formatMessage({
            id: 'InboundETransferPage.SecurityQuestionLabel',
            defaultMessage: 'Security question',
          })}
        >
          <span data-cy="transfer-question">{question}</span>
        </TransferDetail>
        <TextInput
          label="Answer"
          onValidChange={(isValid) => setInputError(isValid)}
          value={secretAnswerValue}
          onChange={(event) => {
            dispatch(
              InboundETransferActions.InboundETransferAnswerUpdate({
                answer: event?.target?.value ?? '',
              }),
            );
          }}
          validators={[
            Validators.required,
            Validators.minLength(3, intl),
            Validators.maxLength(25, intl),
          ]}
          data-cy="transfer-answer-input"
        ></TextInput>
        {showIncorrectAnswerError ? (
          <Box
            sx={{ color: 'red', pt: 1, pl: '13px' }}
            data-cy="iet-warning-incorrect-answer"
          >
            {intl.formatMessage({
              id: 'InboundETransferPage.InlineError.IncorrectAnswer',
              defaultMessage: 'Incorrect answer, please try again',
            })}
          </Box>
        ) : null}
      </Box>
      <Box
        sx={{
          marginBottom: '4',
          color: '#74748B',
        }}
      >
        {intl.formatMessage({
          id: 'InboundETransferPage.InteracLegalCopy',
          defaultMessage:
            'The INTERAC logo and INTERAC e-Transfer are registered Trade-marks of Interac Corp. Used under licence.',
        })}
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridColumnGap: '2',
        }}
      >
        <Button
          disabled={
            !inputError || declineLoadable.loading || acceptLoadable.loading
          }
          variant="secondary"
          data-cy="iet-transfer-decline"
          onClick={declineHandler}
        >
          {intl.formatMessage({
            id: 'InboundETransferPage.DeclineCTA',
            defaultMessage: 'Decline',
          })}
        </Button>
        <Button
          disabled={
            !inputError || declineLoadable.loading || acceptLoadable.loading
          }
          data-cy="iet-transfer-accept"
          onClick={acceptHandler}
        >
          {intl.formatMessage({
            id: 'InboundETransferPage.AcceptCTA',
            defaultMessage: 'Accept',
          })}
        </Button>
      </Box>
    </Box>
  );
};
