import { useContext } from 'react';

import { makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { LocaleContext } from 'components/I18NContextProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';

const useStyles = makeStyles({
  root: {
    justifyContent: 'center',
  },
});

export const SitewideAlert = (): JSX.Element => {
  const { locale } = useContext(LocaleContext);
  const classes = useStyles();

  const sitewideAlert = useFlags()['web.enable-sitewide-alert'];

  if (!sitewideAlert || isEmpty(sitewideAlert)) {
    return <></>;
  }

  const alertCopy =
    sitewideAlert?.copy[locale] ??
    {
      en: 'Website under maintenance',
      fr: 'Site en maintenance',
    }[locale];
  const alertSeverity = sitewideAlert?.type ?? 'alert';

  return (
    <Alert
      severity={alertSeverity}
      classes={{
        root: classes.root,
      }}
    >
      {alertCopy}
    </Alert>
  );
};

export default SitewideAlert;
