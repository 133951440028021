import { hashEmail } from 'utility/hashEmail';

type TEvent =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'ClickButton'
  | 'CompletePayment'
  | 'CompleteRegistration'
  | 'Contact'
  | 'Download'
  | 'InitiateCheckout'
  | 'PlaceAnOrder'
  | 'Search'
  | 'SubmitForm'
  | 'Subscribe'
  | 'ViewContent';

type TProperties = {
  content_type?: 'product' | 'product_group';
  content_id?: string;
  content_category?: string;
  content_name?: string;
};

type TEventSettings = {
  email: string;
};

export const tiktokTrackEvent = (
  event: TEvent,
  properties: { event?: TProperties; settings: TEventSettings },
): void => {
  if (window && (window as any).ttq?.track) {
    const { email } = properties.settings;

    const emailHashed = hashEmail(email);
    const settingsFinal = { event_id: emailHashed };

    (window as any).ttq.track(event, properties.event ?? {}, settingsFinal);
  }
};
