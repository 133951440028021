import { createSelector } from '@reduxjs/toolkit';
import { selectOnfidoCompletedSuccessfully } from 'kyc/store/selectors';
import { selectSmsOtpToken } from 'smsOtp/store/selectors';
import { RootState } from 'store';

export const selectPasswordResetState = (state: RootState) =>
  state.passwordReset;

export const selectPasswordResetError = createSelector(
  selectPasswordResetState,
  (state) => state.error,
);

export const selectPasswordResetAuthToken = createSelector(
  selectPasswordResetState,
  (state) => state.authToken,
);

export const selectPasswordResetUserRef = createSelector(
  selectPasswordResetState,
  (state) => state.userRef,
);

export const selectAuthenticationSuccess = createSelector(
  selectOnfidoCompletedSuccessfully,
  selectSmsOtpToken,
  (onfidoSuccess, smsOtpToken) => onfidoSuccess || !!smsOtpToken,
);
