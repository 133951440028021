import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReferralRequest } from 'apis/registration';

import {
  Loadable,
  createDefaultLoadable,
  createErrorLoadable,
  createLoadingLoadable,
  createSuccessLoadable,
} from '../../utility/loadable';
import { RefereeData, ReferralData } from '../models/Referral';

export interface ReferralsState {
  data: { data: ReferralData | null; loadable: Loadable };
  referralCode: {
    code: string | null;
    loadable: Loadable;
  };
  referees: { data: RefereeData[]; loadable: Loadable };
  registrationReferralCode: ReferralRequest | null;
}

const initialState: ReferralsState = {
  data: { data: null, loadable: createDefaultLoadable() },
  referralCode: {
    code: null,
    loadable: createDefaultLoadable(),
  },
  referees: { data: [], loadable: createDefaultLoadable() },
  registrationReferralCode: null,
};

const referralSlice = createSlice({
  name: 'referral',
  initialState: initialState,
  reducers: {
    getReferrerDataRequest: (state, action: PayloadAction<string>) => {
      state.data.loadable = createLoadingLoadable();
    },
    getReferrerDataResponse: (state, action: PayloadAction<ReferralData>) => {
      state.data.data = action.payload;
      state.data.loadable = createSuccessLoadable();
    },
    getReferrerDataError: (state, action: PayloadAction<any>) => {
      state.data.loadable = createErrorLoadable(
        action.payload || 'unable to get referrer data response',
      );
    },
    postReferralCodeRequest: (state) => {
      state.referralCode.loadable = createSuccessLoadable();
    },
    postReferralCodeResponse: (state, action: PayloadAction<string>) => {
      state.referralCode.code = action.payload;
    },
    postReferralCodeError: (state) => {
      state.referralCode.loadable = createErrorLoadable(
        'unable to get referral code',
      );
    },
    getReferralRefereesRequest: (state) => {
      state.referees.loadable = createSuccessLoadable();
    },
    getReferralRefereesResponse: (
      state,
      action: PayloadAction<RefereeData[]>,
    ) => {
      state.referees.data = action.payload;
    },
    getReferralRefereesError: (state) => {
      state.referees.loadable = createErrorLoadable('unable to get referees');
    },
    setRegistrationReferralCode: (
      state: ReferralsState,
      action: PayloadAction<any>,
    ) => {
      state.registrationReferralCode = {
        ...state.registrationReferralCode,
        ...action.payload,
      };
    },
    noop: (state) => state,
  },
});

export const referralActions = referralSlice.actions;
export default referralSlice.reducer;
