/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';

import { H2, P } from '../components';

export const PasswordResetOtpLimitReached = (props: RouteComponentProps) => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <KDSIcons.Spot.Wait sx={{ mb: 3 }} />
      <H2>
        {intl.formatMessage({
          id: 'OtpLimitReached.Title',
          defaultMessage: `Oops! You've tried too many times`,
        })}
      </H2>
      <P>
        {intl.formatMessage({
          id: 'OtpLimitReached.Content',
          defaultMessage:
            'Please wait at least 30 minutes before attempting again to reset your password. If you still need assistance, please call our support team at 1-855-564-6999.',
        })}
      </P>
    </React.Fragment>
  );
};
