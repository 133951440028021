import { track } from 'analytics/analytics';

import { rootApi } from './rootApi';

export enum Affiliate {
  CanadaPostEmployee = 'CANADAPOSTEMPLOYEE',
}

export interface AffiliateReferralResponse {
  affiliates: Affiliate[];
  referral_code: string;
}

export const registrationApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    postReferralAffiliate: build.mutation<
      AffiliateReferralResponse,
      { referral_code: string }
    >({
      query: (request) => ({
        url: '/2.0/users/affiliates/registration',
        method: 'POST',
        body: request,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          track({ event: 'Post Referral Affiliate Failed' });
        }
      },
      invalidatesTags: ['Affiliates'],
    }),
    getAffiliates: build.query<AffiliateReferralResponse, void>({
      query: () => ({
        url: '/2.0/users/affiliates',
        method: 'GET',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          track({ event: 'Get Affiliates Failed' });
        }
      },
      providesTags: ['Affiliates'],
    }),
  }),
});

export const { usePostReferralAffiliateMutation, useGetAffiliatesQuery } =
  registrationApi;
