import React from 'react';

import { Player } from '@lottiefiles/react-lottie-player';
import { Backdrop, useMediaQuery } from '@material-ui/core';
import { ReactComponent as CheckMark } from 'assets/images/checkmark-success-filled.svg';
import confettiAnimation from 'assets/lottie/koho-confetti-fullscreen.json';
import { Box } from 'components/Box';
import { TitleMedium } from 'components/Typography';
import { theme } from 'theme';
import { ThemeUIStyleObject } from 'theme-ui';

export const SuccessPopup = ({
  checkMarkStyle = {},
  message,
  useConfetti = false,
  useLightCheck = false,
}: {
  checkMarkStyle?: ThemeUIStyleObject;
  message: string;
  useConfetti?: boolean;
  useLightCheck?: boolean;
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const isMobile = useMediaQuery('@media (max-width: 600px)');

  setTimeout(() => {
    setIsOpen(false);
  }, 2000);

  return (
    <Backdrop open={isOpen} sx={{ zIndex: 10 }}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '12px',
          padding: '32px 44px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          width: ['300px', 'auto', 'auto'],
        }}
        data-cy="success-popup"
      >
        {useConfetti &&
          (isMobile ? (
            <Player
              autoplay
              loop
              src={confettiAnimation}
              style={{
                position: 'absolute',
                width: '400px',
                top: -35,
                left: -45,
                zIndex: 11,
              }}
            ></Player>
          ) : (
            <Player
              autoplay
              loop
              src={confettiAnimation}
              style={{
                position: 'absolute',
                width: 'inherit',
                top: -40,
                left: 0,
                zIndex: 11,
              }}
            ></Player>
          ))}

        <CheckMark
          sx={{
            ...(useLightCheck
              ? {
                  '& circle': {
                    fill: theme.colors.white,
                    stroke: theme.colors.successful,
                  },
                  '& path': {
                    stroke: theme.colors.successful,
                  },
                }
              : {}),
            ...checkMarkStyle,
          }}
        />
        <TitleMedium sx={{ marginBottom: 0, textAlign: 'center' }}>
          {message}
        </TitleMedium>
      </Box>
    </Backdrop>
  );
};
