export const trackSpotifySignUpStarted = () => {
  if (window && (window as any).spdt) {
    (window as any).spdt('lead', { category: 'add_to_cart' });
  }
};

export const trackSpotifySignUpFinished = () => {
  if (window && (window as any).spdt) {
    (window as any).spdt('purchase');
  }
};
