import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICurrentBenefit, tiersApi } from 'apis/tiers';

interface desiredTier {
  benefit_group_id: string;
  benefit_group_plan_id: string;
}
export interface TiersState {
  is_paid_tier: boolean;
  data: ICurrentBenefit | null;
  has_acquired_tier: boolean;
  desired_tier: desiredTier | null;
}

const initialState: TiersState | undefined = {
  is_paid_tier: false,
  data: null,
  has_acquired_tier: false,
  desired_tier: null,
};

const tiersSlice = createSlice({
  name: 'tiers',
  initialState,
  reducers: {
    setDesiredTier: (state, action: PayloadAction<desiredTier | null>) => {
      state.desired_tier = action.payload;
    },
    noop: (state) => state,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      tiersApi.endpoints.getCurrentTier.matchFulfilled,
      (state, { payload }) => {
        state.data = payload;

        // Check if the user is on a paid tier
        state.is_paid_tier =
          payload.subscription_id !== '' &&
          payload.subscription_id !== 'default';
      },
    );
  },
});

export const tiersActions = tiersSlice.actions;
export default tiersSlice;
