import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import { InputBaseComponentProps } from '@material-ui/core/InputBase';

export const TextMaskPhone = ({
  inputRef,
  ...props
}: Omit<MaskedInputProps & InputBaseComponentProps, 'css'>) => (
  <MaskedInput
    {...props}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[
      '(',
      /[2-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
    placeholderChar={'\u2000'}
    keepCharPositions={false}
  />
);
