import { rest } from 'msw';

const benefitsHandlers = [
  rest.post(/\/benefits\/subscriptions\/current/, (_req, res, ctx) => {
    return res(
      ctx.json({
        account_group_id: 'aaaa-aaaa-aaaa',
        benefit_group: {
          benefit_group_set_id: 'benefit-group-set-id',
          benefit_group_set_rank: 1,
          created_at: '2023-06-19T16:09:38.756871Z',
          deleted_at: null,
          display_benefits: [
            {
              id: 'display-benefit-1-id',
              key: 'credit-monthly-price',
              name: 'Credit Building Price',
              type: '',
              unit: '$',
              value: '10',
            },
            {
              id: 'display-benefit-2-id',
              key: 'credit-annual-price',
              name: 'Credit Building Price',
              type: '',
              unit: '$',
              value: '60',
            },
            {
              id: 'display-benefit-3-id',
              key: 'save-rate',
              name: 'Interest on your balance',
              type: '',
              unit: '%',
              value: '0.5',
            },
            {
              id: 'display-benefit-4-id',
              key: 'grocery',
              name: 'Groceries',
              type: 'cashback',
              unit: '%',
              value: '1',
            },
            {
              id: 'display-benefit-5-id',
              key: 'transportation',
              name: 'Transportation',
              type: 'cashback',
              unit: '%',
              value: '1',
            },
            {
              id: 'display-benefit-6-id',
              key: 'unlimited-e-transfers',
              name: 'Free e-transfers',
              type: '',
              unit: '',
              value: 'yes',
            },
          ],
          display_description: '',
          display_icon: '',
          display_image: '',
          display_name: 'Easy',
          display_sort_order: 1,
          display_sub_text: '',
          display_theme: '',
          experiment_variation: 'easy',
          id: 'benefit-group-id',
          is_trial_eligible: false,
          name: 'easy',
          updated_at: '2024-01-31T19:01:25.743435Z',
        },
        benefit_group_id: 'benefit-group-id',
        benefit_group_set_id: 'benefit-group-set-id',
        created_at: '2024-03-14T20:16:46.727661Z',
        deleted_at: null,
        end_date: null,
        frequency: '',
        id: 'subscription-id',
        legacy_start_date: null,
        next_payment_date: null,
        start_date: null,
        status: 'active',
        subscription_id: 'default',
        trial_days_remaining: '',
        updated_at: '2024-03-14T20:16:46.727661Z',
      }),
    );
  }),
];

export default benefitsHandlers;
