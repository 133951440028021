import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { RouteComponentProps, navigate } from '@reach/router';
import { ButtonTw } from 'components/ButtonTw';
import CircularProgressTw from 'components/CircularProgressTw';
import { useTypedSelector } from 'hooks/redux';
import { passwordResetActions } from 'passwordReset/store/slice';
import { SmsOtp } from 'smsOtp/components/SmsOtp';

export const PasswordResetSmsRequest = (_: RouteComponentProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const state = useTypedSelector((state) => state);
  const { emailTokenChecked } = state.passwordReset;

  if (emailTokenChecked) {
    const onSuccess = () => {
      dispatch(passwordResetActions.checkAuthentication(true));

      navigate('/password-reset/create');
    };

    if (state.passwordReset.userType === 'identity') {
      onSuccess();
      return null;
    }

    return (
      <div>
        <SmsOtp onSuccess={onSuccess} />

        <ButtonTw
          trackName="Go to Second Auth Method"
          type="button"
          variant="secondary"
          className="mt-8"
          onClick={() => {
            navigate('/kyc/entry');
          }}
        >
          {intl.formatMessage({
            id: 'SmsRequest.NoAccess',
            defaultMessage: 'Don’t have access to this number?',
          })}
        </ButtonTw>
      </div>
    );
  } else {
    return (
      <div className="flex justify-center">
        <CircularProgressTw />
      </div>
    );
  }
};
