import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectReferralsState = (state: RootState) => state.referrals;

export const selectReferrerData = createSelector(
  selectReferralsState,
  (state) => state?.data,
);
export const selectReferrerDataData = createSelector(
  selectReferrerData,
  (state) => state?.data,
);

export const selectReferrerDataLoadable = createSelector(
  selectReferrerData,
  (state) => state?.loadable,
);

export const selectReferrerDataSuccess = createSelector(
  selectReferrerDataLoadable,
  (loadable) => loadable?.success,
);

export const selectReferrerExpired = createSelector(
  selectReferrerDataData,
  (state) => state?.expired,
);

export const selectReferrerClientCopy = createSelector(
  selectReferrerDataData,
  (state) => state?.client_copy,
);

export const selectReferrerTriggerEvent = createSelector(
  selectReferrerDataData,
  (state) => state?.campaign_incentives?.[0]?.trigger_event,
);

export const selectReferralCodeData = createSelector(
  selectReferralsState,
  (state) => state?.referralCode,
);

export const selectReferralCode = createSelector(
  selectReferralCodeData,
  (data) => data?.code,
);

export const selectRefereeState = createSelector(
  selectReferralsState,
  (state) => state.referees,
);

export const selectRefereeData = createSelector(
  selectRefereeState,
  (referees) => referees.data,
);

export const selectRegistrationReferralCode = createSelector(
  selectReferralsState,
  (state) => state.registrationReferralCode,
);
