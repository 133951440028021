import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { useGetCurrentTierQuery, useGetTiersQuery } from 'apis/tiers';
import { Template } from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { desktop, theme } from 'theme';
import { BackToTransactions } from 'tiers/components/BackToTransactions';
import { CancelAnytime } from 'tiers/components/CancelAnytime';
import { ChangePlan } from 'tiers/components/ChangePlan';
import { PlanTabs } from 'tiers/components/PlanTabs';
import { Tiers } from 'tiers/components/Tiers';
import { useSelectedTierPlan } from 'tiers/hooks/useSelectedTierPlan';
import { selectIsPaidTier } from 'tiers/store/selectors';

import { selectLaunchDarklyFlagsIsLoading } from 'libs/launchdarkly/store/selectors';

import { TiersFeatureFlags } from '../models/TiersFeatureFlags';

export const TiersTrialSelectionPage = () => {
  const intl = useIntl();
  const muiTheme = useTheme();
  const isAfterTablet = useMediaQuery(muiTheme.breakpoints.up('md'));
  const isDesktop = useMediaQuery(desktop);
  const isPaidTier = useSelector(selectIsPaidTier);

  const isTiersSelectionEnabled =
    useFlags()[TiersFeatureFlags.EnableNonMemberTiersSelection];
  const ldFlagsIsLoading = useSelector(selectLaunchDarklyFlagsIsLoading);

  const {
    data: tiers,
    isLoading: tiersIsLoading,
    isUninitialized: tiersIsUninitialized,
    isSuccess: tiersIsSuccess,
  } = useGetTiersQuery();

  const { isLoading: currentTierIsLoading } = useGetCurrentTierQuery();

  const { setPlan, setTier, currentTierSelected, currentPlanSelected } =
    useSelectedTierPlan({ tiers: tiers ?? [] });

  if (
    tiersIsUninitialized ||
    tiersIsLoading ||
    ldFlagsIsLoading ||
    currentTierIsLoading
  ) {
    return <Skeleton variant="rect" />;
  }

  if (isPaidTier) {
    navigate('/tiers/my-plan');
    return null;
  }

  if (!isTiersSelectionEnabled) {
    navigate('/transactions', { state: { showWelcomeModal: false } });
    return null;
  }

  if (tiersIsSuccess && (!tiers || tiers.length === 0)) {
    navigate('/transactions', { state: { showWelcomeModal: false } });
    return null;
  }

  return (
    <Template name="Tiers - Trial Selection">
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          pb: isDesktop ? 80 : 48,
          alignItems: 'center',
          width: 'fit-content',
          px: 24,
          pt: isDesktop ? 0 : 80,
          mx: 'auto',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            mb: 24,
            width: '100%',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: '32px',
              fontWeight: 900,
              color: theme.colors.blueDark,
              textAlign: 'center',
              mb: isAfterTablet ? 32 : 24,
            }}
          >
            {intl.formatMessage({
              id: 'TiersTrial.header',
              defaultMessage: 'Try any KOHO plan free for 30 days',
            })}
          </Typography>
          <PlanTabs
            containerSx={{
              width: isAfterTablet
                ? `calc(33% - ${isDesktop ? '16px' : '8px'})`
                : '100%',
            }}
            tab={currentPlanSelected?.key || 'monthly'}
            setTab={setPlan}
          />
        </Box>
        {tiers && (
          <Tiers
            boxSx={{ marginBottom: '8px', paddingX: 0 }}
            selectable
            tiers={tiers}
            plan={currentPlanSelected?.key}
            selectedTier={currentTierSelected?.key}
            handleSelectTier={setTier}
            isFree
            cardTrackName="tiers-trial-selection-card"
          />
        )}

        <Box
          sx={{
            maxWidth: isAfterTablet ? '352px' : 'unset',
            mx: 'auto',
            width: '100%',
          }}
        >
          {currentTierSelected ? (
            <ChangePlan
              isFree
              tier={currentTierSelected}
              selectedPlan={currentPlanSelected}
              trackEventName="tiers-selection-confirm"
            />
          ) : null}
          <BackToTransactions
            dataCy="tier-trial-back-button"
            trackName="tiers-selection-cancel"
          >
            {intl.formatMessage({
              id: 'TiersTrial.Back',
              defaultMessage: 'Back',
            })}
          </BackToTransactions>
          <CancelAnytime />
        </Box>
      </Box>
    </Template>
  );
};
