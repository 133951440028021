import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectBrazeContentCardState = (state: RootState) =>
  state.brazeContentCards;

export const selectHomeCards = createSelector(
  selectBrazeContentCardState,
  (state) => state?.homeCards,
);
