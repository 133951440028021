import { useIntl } from 'react-intl';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';
import { Paragraph, TitleLarge } from 'components/TypographyTw';

export const PasswordResetAccountLocked = (props: RouteComponentProps) => {
  const intl = useIntl();
  return (
    <>
      <KDSIcons.Spot.Wait />
      <TitleLarge>
        {intl.formatMessage({
          id: 'AccountLocked.Title',
          defaultMessage: 'Account locked',
        })}
      </TitleLarge>
      <Paragraph>
        {intl.formatMessage({
          id: 'AccountLocked.Content',
          defaultMessage: `For security reasons, we’ve locked your account. Contact our support team to help you out.`,
        })}
      </Paragraph>
    </>
  );
};
