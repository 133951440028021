/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import { TextInput, TextInputProps, Validators } from '../../components/forms';

interface TextInputNameProps extends TextInputProps {
  className?: string;
}

export const TextInputName = ({ className, ...props }: TextInputNameProps) => {
  const intl = useIntl();

  return (
    <TextInput
      className={className}
      label={intl.formatMessage({
        id: 'TextInputName.Label',
        defaultMessage: 'Name',
      })}
      name="name"
      sx={{ mb: 4 }}
      inputProps={{ maxLength: 25 }}
      validators={[
        Validators.required,
        Validators.alphaWithWhitespace,
        Validators.minLength(2, intl),
        Validators.maxLength(80, intl),
      ]}
      {...props}
    />
  );
};
