import { rootApi } from 'apis/rootApi';

export type AllowedCardBrand = 'visa' | 'mastercard';

interface SetupIntentResponse {
  client_secret: string;
  publishable_key: string; // unused
}

// We had a bug in marshalling the response that led to this response
// having TitleCase keys rather than the typical json snake_case keys
interface PaymentIntentResponse {
  ClientSecret: string;
  PublishableKey: string; // unused
}

interface PaymentMethodResponse {
  payment_methods: PaymentMethod[];
}

export interface PaymentMethod {
  id: string;
  card: {
    last4: string;
    exp_month: number;
    exp_year: number;
    country: string;
    brand: AllowedCardBrand;
  };
}

export const easyloadApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    postPaymentMethod: build.mutation<SetupIntentResponse, void>({
      query: (arg) => ({
        url: '1.0/easyload/payment-methods',
        method: 'POST',
      }),
    }),
    getPaymentMethods: build.query<PaymentMethodResponse, void>({
      query: (arg) => ({
        url: '1.0/easyload/payment-methods',
        method: 'GET',
      }),
      providesTags: ['PaymentMethods'],
    }),
    deletePaymentMethod: build.mutation<void, string>({
      query: (paymentMethodId) => ({
        url: `1.0/easyload/payment-methods`,
        method: 'DELETE',
        body: {
          payment_method_id: paymentMethodId,
        },
      }),
      invalidatesTags: ['PaymentMethods'],
    }),
    createPaymentIntent: build.mutation<PaymentIntentResponse, string>({
      query: (amount) => ({
        url: '1.0/easyload/session',
        method: 'POST',
        body: { amount },
      }),
    }),
  }),
});

export const {
  usePostPaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useDeletePaymentMethodMutation,
  useCreatePaymentIntentMutation,
} = easyloadApi;
