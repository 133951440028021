export interface AccountOwner {
  given_name: string;
  family_name: string;
  user_ref_id: string;
}

export type AccountCategory = 'personal' | 'joint';
export type AccountRelation = 'primary' | 'virtual';

export interface Account {
  category: AccountCategory;
  group_identifier: string;
  owners: AccountOwner[];
  primary_account_identifier: string;
  primary_prn: string;
}

export interface AccountsAllAccount {
  id: string;
  category: AccountCategory;
  group_id: string;
  relation: AccountRelation;
  owners: [
    {
      user_reference_id: string;
      given_name: string;
      family_name: string;
    },
  ];
  balance: string;
  legacy_number: string;
}

export const groupIdHeaderKey = 'x-account-id';
