/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';

import { H2, P } from '../components';

export const PasswordResetAccountLocked = (props: RouteComponentProps) => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <KDSIcons.Spot.Wait sx={{ mb: 3 }} />
      <H2>
        {intl.formatMessage({
          id: 'AccountLocked.Title',
          defaultMessage: 'Account locked',
        })}
      </H2>
      <P>
        {intl.formatMessage({
          id: 'AccountLocked.Content',
          defaultMessage: `For security reasons, we’ve locked your account. Contact our support team to help you out.`,
        })}
      </P>
    </React.Fragment>
  );
};
