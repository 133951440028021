/** @jsxImportSource theme-ui */
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectTheme } from 'appState/appState.slice';
import { ReLoginModal } from 'auth/components/ReLoginModal';
import { SessionRefreshErrorModal } from 'auth/components/SessionRefreshErrorModal';
import { Card, FooterImageWrapper, LayoutBasic } from 'components/LayoutBasic';
import { TitleLarge } from 'components/TypographyTw';
import { LoginForm } from 'login/components/LoginForm';

import footerImg from '../../assets/images/login/footer.png';
import { desktop, theme } from '../../theme';

export const LoginPage = ({ ...props }) => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);

  const [failedLoginAttempts, setFailedLoginAttempts] = useState<number>(0);

  return (
    <LayoutBasic
      chat={failedLoginAttempts >= 2}
      pageName="Login"
      className="bg-grey-100 light:bg-white"
      includeLanguageToggle={true}
    >
      <SessionRefreshErrorModal />
      <ReLoginModal />
      <Card sx={{ p: 5, borderRadius: '8px' }}>
        <TitleLarge className="mt-0">
          {intl.formatMessage({
            id: 'LoginPage.Title',
            defaultMessage: 'Login to KOHO',
            description: 'Title of the login page',
          })}
        </TitleLarge>
        <LoginForm
          emailAddress={props.location?.state?.emailAddress}
          setFailedLoginAttempts={setFailedLoginAttempts}
        />
      </Card>
      {themeName === 'legacy' && (
        <FooterImageWrapper bg={theme.colors.orange} sx={{ pb: 2 }}>
          <img
            alt={`${intl.formatMessage({
              id: 'LoginPage.FooterImageAltTag',
              defaultMessage: 'login page footer',
            })}`}
            src={footerImg}
            sx={{
              position: 'relative',
              zIndex: 2,
              width: '95px',
              [desktop]: {
                width: '125px',
              },
            }}
          />
        </FooterImageWrapper>
      )}
    </LayoutBasic>
  );
};
