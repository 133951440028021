import { ReactNode } from 'react';

import {
  ButtonBase,
  Drawer,
  DrawerProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { KDSIcons } from 'assets/images/kds_icons';
import { Modal } from 'components';
import type { ModalProps } from 'components/Modal';
import { useToggle } from 'hooks/useToggle';
import { theme } from 'theme';
import { ThemeUIStyleObject } from 'theme-ui';

export type Props = {
  children?: ReactNode;
  content: ({
    openModal,
    closeModal,
  }: {
    openModal?: () => void;
    closeModal: () => void;
  }) => ReactNode;
  title?: string;
  drawerProps?: Omit<DrawerProps, 'open' | 'title'>;
  drawerPaperSx?: ThemeUIStyleObject;
  modalProps?: Omit<ModalProps, 'open' | 'title'>;
  modalSx?: ThemeUIStyleObject;
};

export const StatelessDrawerModal = ({
  children,
  closeModal,
  content,
  drawerProps,
  drawerPaperSx,
  isOpen,
  modalProps,
  modalSx,
  openModal,
  title,
}: Props & {
  closeModal: () => void;
  isOpen: boolean;
  openModal?: () => void;
}) => {
  const muiTheme = useTheme();
  const isAfterTablet = useMediaQuery(muiTheme.breakpoints.up('md'));

  return (
    <>
      {children && <ButtonBase onClick={openModal}>{children}</ButtonBase>}
      {isAfterTablet ? (
        <Modal
          disableScrollLock
          open={isOpen}
          onClose={closeModal}
          sx={
            modalSx ?? {
              '& .MuiPaper-root': { p: 2 },
              '& .MuiDialogContent-root': { pt: 0 },
            }
          }
          title={title}
          {...modalProps}
        >
          {content({ openModal, closeModal })}
        </Modal>
      ) : (
        <Drawer
          anchor="bottom"
          disableScrollLock
          open={isOpen}
          onClose={closeModal}
          sx={{
            '& .MuiPaper-root': {
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              padding: 4,
              paddingBottom: 6,
              minHeight: 200,
              ...drawerPaperSx,
            },
          }}
          {...drawerProps}
        >
          <ButtonBase
            onClick={closeModal}
            sx={{ width: 'fit-content', ml: 'auto' }}
          >
            <KDSIcons.Icons.Cross height={20} width={20} />
          </ButtonBase>
          {title ? (
            <Typography
              variant="h1"
              sx={{
                fontWeight: 900,
                color: theme.colors.text,
                fontSize: 32,
                lineHeight: '36px',
                pb: '12px',
              }}
            >
              {title}
            </Typography>
          ) : null}
          {content({ openModal, closeModal })}
        </Drawer>
      )}
    </>
  );
};

export const DrawerModal = (props: Props) => {
  const { value: isOpen, on: openModal, off: closeModal } = useToggle();

  return (
    <StatelessDrawerModal
      isOpen={isOpen}
      openModal={openModal}
      closeModal={closeModal}
      {...props}
    />
  );
};
