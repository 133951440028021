import React, { useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { parse } from 'query-string';

import { deepLinksEn, deepLinksFr } from '../deepLinks';
import English from '../lang/en.json';
import French from '../lang/fr.json';
import { IntlNumber } from '../models/IntlNumber';
import { Money } from '../models/Money';

interface LocaleContextType {
  locale: string;
  localeChanged: boolean;
  setLanguage: Function;
  intlFormatMoney: (moneyAmount: any) => Money;
  intlFormatNumber: (number: any) => IntlNumber;
  apiLanguageHeader: string;
  deepLinks: typeof deepLinksEn;
}

export const LOCALE_EN = 'en';
export const LOCALE_FR = 'fr';
export const BROWSER_LOCALE = /^fr\b/.test(navigator.language)
  ? LOCALE_FR
  : LOCALE_EN;

const allLanguages = [LOCALE_EN, LOCALE_FR];

export const LocaleContext = React.createContext({} as LocaleContextType);

const reactIntlError = (error) => {
  if (error.code === 'MISSING_TRANSLATION') {
    return;
  }
  throw error;
};

const I18NContextProvider = (props) => {
  const [locale, setLocale] = useState(
    localStorage.getItem('locale') ?? BROWSER_LOCALE,
  );
  const [localeChanged, setLocaleChanged] = useState(false);
  const [messages, setMessages] = useState(
    locale === LOCALE_EN ? English : French,
  );
  const [apiLanguageHeader, setApiLanguageHeader] = useState(BROWSER_LOCALE);
  const [deepLinks, setDeepLinks] = useState(deepLinksEn);

  const setLanguage = useCallback(
    (newLocale) => {
      if (locale !== newLocale) {
        setLocaleChanged(true);
        setLocale(newLocale);
        setApiLanguageHeader(newLocale);
        if (newLocale === LOCALE_FR) {
          setMessages(French);
        } else {
          setMessages(English);
        }
      }
    },
    [locale],
  );

  const intlFormatMoney = useCallback(
    (moneyAmount) => {
      return new Money(moneyAmount, locale);
    },
    [locale],
  );

  const intlFormatNumber = useCallback(
    (number) => {
      return new IntlNumber(number, locale);
    },
    [locale],
  );

  useEffect(() => {
    const parsed = parse(window.location.search);
    const language = parsed?.language;
    if (allLanguages.find((allowed) => allowed === language)) {
      setLanguage(language);
    }
  }, [setLanguage]);

  useEffect(() => {
    if (locale === LOCALE_FR) {
      setDeepLinks(deepLinksFr);
    } else {
      setDeepLinks(deepLinksEn);
    }
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{
        apiLanguageHeader,
        deepLinks,
        intlFormatMoney,
        intlFormatNumber,
        locale,
        localeChanged,
        setLanguage,
      }}
    >
      <IntlProvider
        messages={messages}
        locale={locale}
        onError={reactIntlError}
      >
        {props.children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default I18NContextProvider;
