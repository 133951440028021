/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import { KDSIcons } from 'assets/images/kds_icons';

import { Box, ParagraphSmall } from '.';
import { theme } from '../theme';

export const SecurelyStoredInfo = () => {
  const intl = useIntl();
  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: '#F4F4F4',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        p: 3,
      }}
    >
      <KDSIcons.Icons.LockDetailed
        sx={{ mr: 2, color: theme.colors.midGrey }}
      />
      <ParagraphSmall sx={{ color: theme.colors.midGrey, m: 0 }}>
        {intl.formatMessage({
          id: 'SecurelyStoredInfo.Title',
          defaultMessage: 'Your information is securely stored.',
        })}
      </ParagraphSmall>
    </Box>
  );
};
