import { Box } from '@material-ui/core';
import { KDSIcons } from 'assets/images/kds_icons';
import { theme } from 'theme';

export const ChecklistItem = ({
  color = theme.colors.successful,
  children,
}: {
  color?: string;
  children: React.ReactNode;
}) => {
  return (
    <Box
      component="li"
      sx={{
        listStyle: 'none',
        display: 'grid',
        gridTemplateColumns: '18px 1fr',
        columnGap: '16px',
        ':not(:last-of-type)': {
          marginBottom: '16px',
        },
      }}
    >
      <KDSIcons.Icons.CheckmarkFilled
        color={color ? color : theme.colors.successful}
      />
      <Box display="flex" alignItems="center">
        {children}
      </Box>
    </Box>
  );
};
