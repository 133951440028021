import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@material-ui/core';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { useNavigate } from '@reach/router';
import { GetTheAppModal } from 'OnboardingChecklistDynamic/components/GetTheAppModal';
import {
  OnboardingChecklistDynamicItemKeys,
  useChecklistDismissMutation,
  useChecklistGetQuery,
} from 'apis/onboardingChecklistDynamicApi';
import { KDSIcons } from 'assets/images/kds_icons';
import { ReactComponent as RightArrow } from 'assets/images/rightArrow.svg';
import {
  Button,
  ButtonUnstyled,
  LocaleContext,
  Paragraph,
  ParagraphSmall,
  TitleMedium,
} from 'components';
import { HISAFeatureFlags } from 'hisa/hisaFeatureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { theme } from 'theme';

export const OnboardingChecklistDynamic = () => {
  const intl = useIntl();

  const { data: checklistData } = useChecklistGetQuery();
  const [dismissChecklist] = useChecklistDismissMutation();
  const { deepLinks } = React.useContext(LocaleContext);

  const [appDownloadModalOpen, appDownloadModalOpenSet] = useState(false);
  const [lastClickedChecklistItemKey, lastClickedChecklistItemKeySet] =
    useState<OnboardingChecklistDynamicItemKeys>('HasSeenCreditScore');

  const navigate = useNavigate();
  const isHisaReleased = useFlags()[HISAFeatureFlags.EnableOnboardingFlow];

  if (checklistData === undefined) {
    return null;
  }

  const checklistItems = checklistData.line_items;
  const completedItems = checklistItems.filter((c) => c.completed);

  const handleItemClick = (key: OnboardingChecklistDynamicItemKeys) => {
    switch (key) {
      case 'IsAccountCreated':
        window.open(deepLinks.KOHO.VirtualCardFAQ, '_blank');
        break;
      case 'IsCardAddedToWallet':
        window.open(deepLinks.KOHO.CardsFAQ, '_blank');
        break;
      case 'HasMadePurchase':
        window.open(deepLinks.KOHO.CardsFAQ, '_blank');
        break;
      case 'IsAccountFunded':
      case 'IsReferralRewardUnlocked':
        navigate('/load');
        break;
      case 'IsIdentityVerified':
        navigate('/kyc/entry');
        break;
      case 'IsEarningInterest':
        if (isHisaReleased) {
          navigate('/hisa/registration');
        } else {
          lastClickedChecklistItemKeySet(key);
          appDownloadModalOpenSet(true);
        }
        break;
      case 'HasSeenCreditScore':
      case 'IsCreditBuildingRegistered':
      case 'HasSetUtilizationRate':
      case 'IsSavingGoal':
        lastClickedChecklistItemKeySet(key);
        appDownloadModalOpenSet(true);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        mb: 4,
        borderRadius: '8px',
        p: 3,
        pb: 4,
        boxShadow: '3px 6px 15px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <TitleMedium sx={{ mt: 0, mb: 2 }}>{checklistData.header}</TitleMedium>
      <Paragraph sx={{ mt: 0 }}>{checklistData.subheader}</Paragraph>
      <Box
        sx={{
          height: '4px',
          width: '100%',
          backgroundColor: 'greyLight',
          borderRadius: '4px',
          mb: 4,
        }}
      >
        <Box
          sx={{
            width: `${(completedItems.length / checklistItems.length) * 100}%`,
            height: 'inherit',
            borderRadius: '8px',
            background: theme.colors.successful,
          }}
        ></Box>
      </Box>
      <Box flexDirection="column" sx={{ display: 'flex', gap: 3 }}>
        {checklistData.line_items.map((item, index) => (
          <ButtonUnstyled
            key={index}
            onClick={() => handleItemClick(item.key)}
            disabled={item.completed && !item.is_info_item}
            data-cy={`OnboardingChecklistDynamic-item-${item.key}`}
            trackName={`dynamic-onboarding-checklist-item-${item.key}`}
            sx={{ width: '100%' }}
          >
            <Box
              flexDirection="row"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 3,
              }}
            >
              {item.completed ? (
                <KDSIcons.Icons.CheckmarkFilled
                  color={theme.colors.successful}
                  sx={{ alignSelf: 'center' }}
                />
              ) : (
                <CircleUnchecked
                  sx={{ color: 'greyLight', ml: '-2px', alignSelf: 'center' }}
                />
              )}
              <ParagraphSmall
                sx={{
                  my: 0,
                  color: item.completed
                    ? theme.colors.midGrey
                    : theme.colors.text,
                }}
              >
                {item.label}
              </ParagraphSmall>
              <Box sx={{ ml: 'auto', color: theme.colors.primary }}>
                {item.is_info_item ? (
                  <KDSIcons.Icons.Question />
                ) : !item.completed ? (
                  <RightArrow />
                ) : null}
              </Box>
            </Box>
          </ButtonUnstyled>
        ))}
      </Box>
      {checklistData.status === 'completed' ? (
        <Button
          trackName="dismiss-complete-checklist"
          sx={{ mt: 3 }}
          onClick={() => dismissChecklist()}
        >
          {intl.formatMessage({
            id: 'OnboardingChecklistDynamic.Completed',
            defaultMessage: 'Thanks KOHO',
          })}
        </Button>
      ) : null}
      {checklistData.status === 'dismissible' ? (
        <Button
          trackName="dismiss-incomplete-checklist"
          variant="tertiary"
          sx={{ mt: 3 }}
          onClick={() => dismissChecklist()}
        >
          {intl.formatMessage({
            id: 'OnboardingChecklistDynamic.Dismiss',
            defaultMessage: 'Dismiss',
          })}
        </Button>
      ) : null}

      <GetTheAppModal
        open={appDownloadModalOpen}
        checklistItemKey={lastClickedChecklistItemKey}
        onClose={() => appDownloadModalOpenSet(false)}
      />
    </Box>
  );
};
