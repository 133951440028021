import { KDSIcons } from 'assets/images/kds_icons';

import { rootApi } from './rootApi';

export const personalizedOnboardingChecklistItem = {
  IsAccountCreated: 'IsAccountCreated',
  IsAccountFunded: 'IsAccountFunded',
  HasMadePurchase: 'HasMadePurchase',
  IsIdentityVerified: 'IsIdentityVerified',
  HasSeenCreditScore: 'HasSeenCreditScore',
  IsCreditBuildingRegistered: 'IsCreditBuildingRegistered',
  IsEarningInterest: 'IsEarningInterest',
  IsReferralRewardUnlocked: 'IsReferralRewardUnlocked',
  IsCoverActive: 'IsCoverActive',
  HasViewedCards: 'HasViewedCards',
  HasEarnedCashback: 'HasEarnedCashback',
  IsPlanActive: 'IsPlanActive',
} as const;

export type PersonalizedOnboardingChecklistItemKey =
  (typeof personalizedOnboardingChecklistItem)[keyof typeof personalizedOnboardingChecklistItem];

export interface PersonalizedOnboardingState {
  step: number;
  total_steps: number;
  intent: PersonalizedOnboardingIntent;
  hidden: boolean;
  spend_card: {
    header: string;
    body: string;
    completed: boolean;
  };
  onboarding_page: {
    title: string;
    header: {
      title: string;
      body: string;
      key: PersonalizedOnboardingChecklistItemKey;
      button_text?: string;
    };
    checklist: PersonalizedOnboardingChecklistItem[];
  };
}

export const personalizedOnboardingIntent = {
  spending: 'spending',
  credit: 'credit',
  saving: 'saving',
  borrowing: 'borrowing',
  referral: 'referral',
} as const;

export type PersonalizedOnboardingIntent =
  (typeof personalizedOnboardingIntent)[keyof typeof personalizedOnboardingIntent];

export const personalizedOnboardingIntentEmojiMap: Record<
  PersonalizedOnboardingIntent,
  string
> = {
  [personalizedOnboardingIntent.spending]: '💸',
  [personalizedOnboardingIntent.credit]: '📈',
  [personalizedOnboardingIntent.saving]: '💰',
  [personalizedOnboardingIntent.borrowing]: '🛡️',
  [personalizedOnboardingIntent.referral]: '🎁',
};

export const checklistItemIconMap: Record<
  PersonalizedOnboardingChecklistItemKey,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  [personalizedOnboardingChecklistItem.IsAccountCreated]:
    KDSIcons.Icons.Profile,
  [personalizedOnboardingChecklistItem.IsAccountFunded]: KDSIcons.Icons.Rich,
  [personalizedOnboardingChecklistItem.HasMadePurchase]:
    KDSIcons.Features.Roundups,
  [personalizedOnboardingChecklistItem.IsIdentityVerified]: KDSIcons.Icons.Book,
  [personalizedOnboardingChecklistItem.HasSeenCreditScore]:
    KDSIcons.Icons.CreditMeter,
  [personalizedOnboardingChecklistItem.IsCreditBuildingRegistered]:
    KDSIcons.Icons.Fractional,
  [personalizedOnboardingChecklistItem.IsEarningInterest]:
    KDSIcons.Icons.PieChart,
  [personalizedOnboardingChecklistItem.IsReferralRewardUnlocked]:
    KDSIcons.Features.Roundups,
  [personalizedOnboardingChecklistItem.IsCoverActive]: KDSIcons.Icons.Bills,
  [personalizedOnboardingChecklistItem.HasViewedCards]:
    KDSIcons.Icons.CreditCard,
  [personalizedOnboardingChecklistItem.HasEarnedCashback]:
    KDSIcons.Features.Roundups,
  [personalizedOnboardingChecklistItem.IsPlanActive]:
    KDSIcons.Features.Roundups,
};

export interface PersonalizedOnboardingChecklistItem {
  completed: boolean;
  key: PersonalizedOnboardingChecklistItemKey;
  title: string;
  subtitle?: string;
}

export const personalizedOnboardingApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getPersonalizedOnboarding: build.query<PersonalizedOnboardingState, void>({
      query: () => ({ url: '/2.0/users/personalized-onboarding' }),
      providesTags: ['Checklist'],
    }),
    updatePersonalizedOnboardingItem: build.mutation<
      PersonalizedOnboardingState,
      string
    >({
      query: (checklistKey) => ({
        url: '/2.0/users/onboarding-checklist',
        method: 'PATCH',
        body: {
          checklist_key: checklistKey,
        },
      }),
      invalidatesTags: ['Checklist'],
    }),
    checklistDismiss: build.mutation<void, void>({
      query: () => ({
        url: '/2.0/users/onboarding-checklist/dismiss',
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: ['Checklist'],
      // optimistically update the query data to mark the checklist as hidden
      // if it fails, it will simply undo the update, since the checklist
      // tag will be invalidated
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          personalizedOnboardingApi.util.updateQueryData(
            'getPersonalizedOnboarding',
            undefined,
            (draft) => {
              draft.hidden = true;
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {
  useChecklistDismissMutation,
  useGetPersonalizedOnboardingQuery,
  useUpdatePersonalizedOnboardingItemMutation,
} = personalizedOnboardingApi;
