import axios, { AxiosResponse } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RootState } from 'store';

import { Account, AccountsAllAccount, groupIdHeaderKey } from '../models';
import { accountsActions } from './slice';

export interface AccountsResponse {
  data: Account[];
  error_code?: number;
  status_code: number;
  status_name: string;
}

const getAccountsAllEpic: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(
      accountsActions.getAccountsAllRequest.toString(),
      accountsActions.getAccountsAllAtLoginRequest.toString(),
    ),
    switchMap(({ type }) => {
      return from(
        axios.get(`${import.meta.env.VITE_GATEWAY_API}accounts/accounts/all`),
      ).pipe(
        map((response: AxiosResponse<{ accounts: AccountsAllAccount[] }>) => {
          const groupId =
            response.data.accounts.find(
              (account) => account.category === 'personal',
            )?.group_id ?? null;

          /**
           * With webgateway in place, most authenticated calls will require an account group id
           * We'll use this global approach for adding the x-account-id header for now and monitor
           *   how it pans out. The alternative is to manually send this id with every call
           */
          axios.defaults.headers[groupIdHeaderKey] = groupId;

          const actions = {
            [accountsActions.getAccountsAllRequest.toString()]:
              accountsActions.getAccountsAllResponse,
            [accountsActions.getAccountsAllAtLoginRequest.toString()]:
              accountsActions.getAccountsAllAtLoginResponse,
          };
          return actions[type]({ accounts: response.data.accounts });
        }),
        catchError((error) => {
          const actions = {
            [accountsActions.getAccountsAllRequest.toString()]:
              accountsActions.getAccountsAllError,
            [accountsActions.getAccountsAllAtLoginRequest.toString()]:
              accountsActions.getAccountsAllAtLoginError,
          };
          return of(actions[type](error.response?.data));
        }),
      );
    }),
  );
};

const exportedArray = [getAccountsAllEpic];

export default exportedArray;
