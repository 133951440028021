/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';
import { useTypedSelector } from 'hooks/redux';

import { Button, H2, P, SpinnerCircularProgress } from '../components';
import { passwordResetActions } from './store/slice';

export const PasswordResetEmailSent = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const state = useTypedSelector((state) => state);
  const { email } = state.passwordReset.request;
  const { loading } = state.passwordReset.emailResetLoadable;
  const intl = useIntl();

  return (
    <form
      sx={{ '& > *': { mb: 3 } }}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(passwordResetActions.resetEmailLoadableLoading());
      }}
    >
      <KDSIcons.Spot.Success />
      <H2>
        {intl.formatMessage({
          id: 'EmailSent.Title',
          defaultMessage: 'Password reset sent',
        })}
      </H2>
      <P>
        {intl.formatMessage(
          {
            id: 'EmailSent.Content',
            defaultMessage: `We’ve sent an email to {boldEmail} containing a link to reset your password.`,
          },
          { boldEmail: <b>{email}</b> },
        )}
      </P>
      <Button type="submit" trackName="Send reset link">
        {!loading &&
          intl.formatMessage({
            id: 'Global.Button.ResendEmail',
            defaultMessage: 'Resend email',
          })}
        {loading && <SpinnerCircularProgress variant="large" />}
      </Button>
    </form>
  );
};
