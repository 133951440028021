import { isValid } from 'date-fns';

export const formatDate = (date: string) => {
  // return today's date if invalid to match moment.js implementation (replaced with datefns)
  if (!isValid(new Date(date))) {
    return new Date().toDateString();
  }
  // replace date delimiter "-" to avoid date being one day off
  if (date?.length === 10) {
    return date.replace(/-/g, '/');
  }
  return date;
};
