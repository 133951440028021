import { navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import { authActions } from 'auth/store/slice';
import { profileActions } from 'profile/store/slice';
import { Epic, ofType } from 'redux-observable';
import { map, switchMap, tap } from 'rxjs/operators';
import { RootState } from 'store';

import { loginActions } from './slice';

const loginSuccessEpic: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(loginActions.loginSuccess),
    switchMap(() => {
      return [loginActions.smsOtpStarted(), authActions.setSession()];
    }),
    tap(() => {
      if (!store.value.auth.isNewLogin) {
        navigate('/sms-verify');
      }
    }),
  );
};

const loginSmsOtpSuccessEpic: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(loginActions.smsOtpSuccess),
    switchMap(({ payload }) => {
      return [
        authActions.setOtpTimeout(),
        authActions.setSession(),
        loginActions.navigateToLoginDestination(),
        profileActions.getProfileRequestWithEvents(payload),
      ];
    }),
  );
};

const loginRememberDeviceEpic = (action$) => {
  return action$.pipe(
    ofType(loginActions.rememberDeviceSelected),
    tap((payload) =>
      track({
        event: 'Login Remember Device Toggled',
        properties: { payload },
      }),
    ),
    map(() => loginActions.noop()),
  );
};

const loginNavigateToDestinationEpic: Epic<any, any, RootState> = (
  actions$,
  store,
) => {
  return actions$.pipe(
    ofType(loginActions.navigateToLoginDestination),
    tap(() => {
      navigate(store.value.login.destination);
    }),
    map(() => loginActions.noop()),
  );
};

const exportedArray = [
  loginSuccessEpic,
  loginRememberDeviceEpic,
  loginSmsOtpSuccessEpic,
  loginNavigateToDestinationEpic,
];
export default exportedArray;
