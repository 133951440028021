import React from 'react';

import { globalHistory } from '@reach/router';

// Used to activate Google Optimize every route change
const activateOptimize = () => {
  if (window && window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'optimize.activate' });
  }
};

export function useAnalytics() {
  activateOptimize();
  React.useEffect(() => {
    globalHistory.listen(() => {
      activateOptimize();
    });
  }, []);
}
