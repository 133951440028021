import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// components
import App from 'App';
import I18NContextProvider from 'components/I18NContextProvider';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { worker } from 'mocks/browser';
// store
import { epicMiddleware, setupStore } from 'store';
import { rootEpic } from 'store/epics';

// libs
import { AxiosInit } from 'libs/axios';
import { launchDarklyFlags as flags } from 'libs/launchdarkly/flags';
import SentryInit from 'libs/sentry';

// styles
import './styles.css';

AxiosInit();
SentryInit();

const LDProvider = withLDProvider({
  clientSideID:
    import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID || '5f05eea3fa48230af9ecce82', // staging key on LD,
  flags,
  options: {
    sendEventsOnlyForVariation: true,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(App);

const store = setupStore();

//  Running middleware here allows for some flexibility with code splitting.See: https://redux-observable.js.org/docs/api/EpicMiddleware.html
epicMiddleware.run(rootEpic);

ReactDOM.render(
  <I18NContextProvider>
    <Provider store={store}>
      <LDProvider />
    </Provider>
  </I18NContextProvider>,
  document.getElementById('root'),
);

// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (import.meta.env.VITE_MOCK_SERVICE_WORKER_ENABLE) {
  worker.start({
    onUnhandledRequest: 'bypass', // change to `warn` if you want to see unhandled requests
  });
}
