import { useIntl } from 'react-intl';

import { navigate } from '@reach/router';
import { Button, Widget } from 'components';

export const VerifyIdentityWidget = () => {
  const intl = useIntl();

  const ctaText = intl.formatMessage({
    id: 'VerifyIdentityWidget.Button',
    defaultMessage: 'Verify my identity now',
  });

  const description = intl.formatMessage({
    id: 'VerifyIdentityWidget.UnlockLimits.Content',
    defaultMessage:
      'By verifying your identity you can start earning interest, build credit, and more!',
  });

  const title = intl.formatMessage({
    id: 'VerifyIdentityWidget.Title',
    defaultMessage: 'Want to get more out of KOHO?',
  });

  const img = {
    url: `${import.meta.env.VITE_STATIC_URL}/widgets/verify_identity.jpeg`,
  };

  return (
    <Widget
      description={description}
      image={img}
      title={title}
      inverted
      data-cy="kyc-verify-identity-widget"
    >
      <Button
        aria-label={ctaText}
        data-cy="kyc-verify-identity-widget-cta"
        onClick={() => navigate('/kyc/entry')}
        trackName="Verify identity widget"
        variant="primaryInverted"
      >
        {ctaText}
      </Button>
    </Widget>
  );
};
