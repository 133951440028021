import axios, { AxiosResponse } from 'axios';
import { Epic } from 'redux-observable';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RootState } from 'store';

import { DirectDeposit } from '../models/directDeposit';
import { directDepositActions } from './slice';

interface StandardResponse {
  status_code: number;
  status_name: string;
}

type DirectDepositResponse = StandardResponse & DirectDeposit;

const getDirectDepositEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(directDepositActions.getDirectDepositRequest.toString()),
    switchMap(() => {
      return from(
        axios.get(`${import.meta.env.VITE_GATEWAY_API}user/direct_deposit`),
      ).pipe(
        map((response: AxiosResponse<DirectDepositResponse>) =>
          directDepositActions.getDirectDepositSuccess(response.data),
        ),
        catchError(() => {
          return of(directDepositActions.getDirectDepositError());
        }),
      );
    }),
  );
};

const exportedArray = [getDirectDepositEpic];
export default exportedArray;
