import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import { IBenefitGroup, IPlan, useGetTiersQuery } from 'apis/tiers';
import { useGetProfileQuery } from 'apis/users';
import { Button, LayoutBasic } from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RegistrationFeatureFlags } from 'registration/models/RegistrationFeatureFlags';
import { HelpAndAccessibility } from 'support/components';
import { theme } from 'theme';
import { CancelAnytime } from 'tiers/components/CancelAnytime';
import { Tiers } from 'tiers/components/Tiers';
import { tiersActions } from 'tiers/store/slice';

import { useLaunchDarkly } from 'libs/launchdarkly/hooks/useLaunchDarkly';
import { selectLaunchDarklyFlagsIsLoading } from 'libs/launchdarkly/store/selectors';

import { useTiersData } from '../hooks/useTiersData';

export const TierSelectionPage = () => {
  useLaunchDarkly();

  const { getHeaderText } = useTiersData();
  const intl = useIntl();
  const muiTheme = useTheme();
  const isTablet = useMediaQuery(muiTheme.breakpoints.up('sm'));
  const ldFlagsIsLoading = useSelector(selectLaunchDarklyFlagsIsLoading);
  const dispatch = useDispatch();

  const isSelectTiersScreenEnabled =
    useFlags()[RegistrationFeatureFlags.EnableTiersSelection];

  const isAlwaysRedirectToKycEnabled =
    useFlags()[RegistrationFeatureFlags.AlwaysRedirectToKYC];

  const {
    data: getTiersData,
    isLoading: getTiersIsLoading,
    isUninitialized: getTiersIsUninitialized,
    isSuccess: getTiersIsSuccess,
    isError: getTiersIsError,
  } = useGetTiersQuery();

  const { data: profileData, isLoading: profileIsLoading } =
    useGetProfileQuery();

  if (
    getTiersIsUninitialized ||
    getTiersIsLoading ||
    profileIsLoading ||
    ldFlagsIsLoading
  ) {
    return <Skeleton variant="rect" />;
  }

  if (isSelectTiersScreenEnabled === false) {
    navigate('/transactions', { state: { showWelcomeModal: true } });
  }

  if (
    (getTiersIsSuccess && (!getTiersData || getTiersData.length === 0)) ||
    getTiersIsError
  ) {
    navigate('/transactions', { state: { showWelcomeModal: true } });
  }

  const onMaybeLaterClicked = () => {
    if (isAlwaysRedirectToKycEnabled) {
      navigate('/kyc/entry');
    } else {
      navigate('/transactions', { state: { showWelcomeModal: true } });
    }
  };

  return (
    <LayoutBasic pageName="Registration - Tier Selection" chat={false}>
      {getTiersIsLoading ? (
        <Skeleton variant="rect" />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            justifyContent: 'center',
            paddingBottom: 0,
            marginBottom: '64px',
          }}
        >
          <Typography
            sx={{
              fontSize: isTablet ? '32px' : '24px',
              maxWidth: '420px',
              marginLeft: 'auto',
              marginRight: 'auto',
              fontWeight: 900,
              color: theme.colors.blueDark,
              textAlign: 'center',
              marginBottom: '24px',
              '& span': {
                color: theme.colors.orange,
              },
            }}
            data-cy="tier-selection-header"
          >
            {getHeaderText(profileData?.profile.intended_use)}
          </Typography>
          <Typography
            sx={{ fontSize: '16px', textAlign: 'center', marginBottom: '32px' }}
            data-cy="tier-selection-reminder"
          >
            🔔{' '}
            {intl.formatMessage({
              id: 'TierSelectionPage.TrialReminder',
              defaultMessage: "We'll remind you before your trial ends.",
            })}
          </Typography>
          {getTiersData && (
            <Tiers
              boxSx={{ marginBottom: '35px' }}
              tiers={getTiersData}
              isFree
              onPlanChange={(tier: IBenefitGroup, plan: IPlan | undefined) => {
                track({ event: `Tiers - Selection — CTA - ${tier.key}` });
                tier.plans &&
                  plan &&
                  dispatch(
                    tiersActions.setDesiredTier({
                      benefit_group_id: tier.id,
                      benefit_group_plan_id: plan.id,
                    }),
                  );
                navigate('/kyc/entry');
              }}
              cardTrackName="tiers-selection-card"
            />
          )}
          <Button
            onClick={onMaybeLaterClicked}
            data-cy="tier-selection-maybe-later-button"
            trackName="Tiers  - Selection — Decline"
            variant="clear"
          >
            {intl.formatMessage({
              id: 'TierSelectionPage.MaybeLater',
              defaultMessage: 'Maybe later',
            })}
          </Button>
          <CancelAnytime />
        </Box>
      )}

      <HelpAndAccessibility />
    </LayoutBasic>
  );
};
