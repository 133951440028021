export class IntlNumber {
  public number: number | string;
  public locale: string | undefined;

  constructor(numberValue: number | string, locale?: string) {
    this.number = numberValue;
    this.locale = locale;
  }

  public format(format: 'default' | 'percent'): string {
    switch (format) {
      case 'percent':
        const percentSign = '%';
        return this.percentage(this.number).toString() + percentSign;

      default:
        const stripNumberValue = this.stripNumber(this.number);
        return new Intl.NumberFormat(this.locale, {
          style: 'decimal',
        })
          .format(stripNumberValue)
          .toString();
    }
  }

  private percentage(percent) {
    let percentValue = this.stripNumber(percent);
    return new Intl.NumberFormat(this.locale, {
      style: 'decimal',
    }).format(percentValue);
  }

  private stripNumber(numberAmount) {
    if (typeof numberAmount === 'string') {
      return Number(numberAmount.replace(/[^0-9.]/g, ''));
    } else {
      return numberAmount;
    }
  }
}
