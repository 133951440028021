import { rootApi } from 'apis/rootApi';
import { Goal } from 'goals/models/Goal';

interface GoalsResponse {
  data: Goal[];
  status_code: number;
}

const goalsApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getGoals: build.query<Goal[], void>({
      query: () => ({
        url: '1.0/goals',
        method: 'GET',
      }),
      transformResponse: (response: GoalsResponse) => response.data,
    }),
  }),
});

export const { useGetGoalsQuery } = goalsApi;
