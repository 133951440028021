import Box from '@material-ui/core/Box';
import { Box as KhBox, Paragraph, TitleMedium } from 'components';
// types
import { Image } from 'types/Image';

/**
 * @typedef {Object} WidgetProps
 * @property {ReactNode} children - Any ReactNode children
 * @property {string} description - The copy text for the widget
 * @property {Image} image - {@link Image} object
 * @property {boolean} [inverted] - Whether the widget should be inverted
 * @property {string} title - The title of the modal
 */
type WidgetProps = {
  alerts?: React.ReactNode;
  children: React.ReactNode;
  description: string | React.ReactNode;
  image: Image;
  inverted?: boolean;
  title: string;
};

/**
 * Displays a widget with a title and description. Optionally can display an image or children React nodes.
 * @param {WidgetProps} - {@link WidgetProps} object
 * @returns a Widget component
 */
export const Widget = ({
  alerts,
  children,
  description,
  image = { url: '', alt: '' },
  inverted = false,
  title,
  ...props
}: WidgetProps) => {
  return (
    <KhBox
      sx={{
        borderRadius: '8px',
        height: 'fit-content',
        overflow: 'hidden',
        color: inverted ? 'white' : 'text',
        lineHeight: 0,
      }}
      variant="shadow"
      {...props}
    >
      {image.url !== '' && (
        <img
          src={image.url}
          alt={image.alt}
          sx={{
            height: ['140px', '260px', '160px'],
            width: '100%',
            objectFit: 'cover',
          }}
        />
      )}
      <Box sx={{ p: 4, backgroundColor: inverted ? '#2F3CCF' : '#FFF' }}>
        {alerts}
        <TitleMedium sx={{ mt: 0, color: inverted ? '#FFF' : 'text' }}>
          {title}
        </TitleMedium>
        <Paragraph sx={{ color: inverted ? '#FFF' : 'text' }}>
          {description}
        </Paragraph>
        {children}
      </Box>
    </KhBox>
  );
};

export default Widget;
