/** @jsxImportSource theme-ui */
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { KDSIcons } from 'assets/images/kds_icons';
import { Flex } from 'theme-ui';

import {
  NumberSmall,
  Paragraph,
  ParagraphSmall,
  TitleMedium,
} from '../../components';
import { LocaleContext } from '../../components/I18NContextProvider';
import { Money } from '../../models/Money';
import { mobile, theme } from '../../theme';
import { Transaction } from '../models';
import {
  selectTransactions,
  selectTransactionsLoading,
} from '../store/selectors';
import { transactionsActions } from '../store/slice';

export const TransactionsList = ({ ...props }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const transactions = useSelector(selectTransactions);
  const loading = useSelector(selectTransactionsLoading);
  const context = useContext(LocaleContext);

  useEffect(() => {
    dispatch(transactionsActions.getTransactionsRequest());
  }, [dispatch, context.apiLanguageHeader]);

  if (loading) {
    return (
      <Box py={2}>
        <Skeleton variant="rect" height={40} sx={{ mb: 3 }} />
        <Skeleton variant="rect" height={'60vh'} />
      </Box>
    );
  }

  if (!transactions || transactions.length === 0) {
    return (
      <Flex sx={{ flexDirection: 'column' }}>
        <Flex
          sx={{
            backgroundColor: theme.colors.greyXLight,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
            flexDirection: 'column',
            textAlign: 'center',
            p: 4,
          }}
          {...props}
        >
          <KDSIcons.EmptyStates.Activity />
          <Paragraph>
            {intl.formatMessage({
              id: 'TransactionsList.NoHistory',
              defaultMessage:
                'You currently have no transaction history. Make a purchase to see your transactions here.',
            })}
          </Paragraph>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        pb: 4,
        px: 4,
        [mobile]: {
          p: 0,
        },
      }}
      {...props}
    >
      <TitleMedium>
        {intl.formatMessage({
          id: 'TransactionsList.Title',
          defaultMessage: 'Latest activity',
        })}
      </TitleMedium>

      {transactions.map((transaction: Transaction, index) => (
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: `solid 1px ${theme.colors.greyLight}`,
            minHeight: '72px',
            py: 1,
          }}
          key={`transaction-${index}`}
        >
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <img
              src={transaction.merchant.logo || transaction.category.icon_url}
              alt={`${transaction.merchant.name || transaction.category.name}`}
              sx={{
                borderRadius: '20%',
                minWidth: '45px',
                width: '45px',
              }}
            />
            <Flex
              sx={{
                ml: 4,
                flexDirection: 'column',
                [mobile]: { ml: 3 },
              }}
            >
              <Paragraph sx={{ fontWeight: 'bold', m: 0 }}>
                {transaction.details}
              </Paragraph>
              <ParagraphSmall>
                {intl.formatDate(new Date(transaction.when), {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
                &nbsp;•&nbsp;
                {new Intl.DateTimeFormat(context.locale, {
                  hour: 'numeric',
                  minute: 'numeric',
                }).format(new Date(transaction.when))}
                &nbsp;•&nbsp;
                {transaction.category.name}
              </ParagraphSmall>
            </Flex>
          </Flex>
          <NumberSmall isCredit={new Money(transaction.amount).gt(0)}>
            {context.intlFormatMoney(transaction.amount).format('deficit')}
          </NumberSmall>
        </Flex>
      ))}
    </Flex>
  );
};
