export const Tiers = ({
  className,
  color,
}: {
  className?: string;
  color: string;
}) => {
  const uniqueId = `path-3-inside-1_7000_8840_${(
    Math.random() * 100000
  ).toString()}`;

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.635 9.48158C21.6352 9.48144 21.6352 9.48105 21.6349 9.48095L21.1482 9.30805C19.9936 8.89722 18.9398 8.22918 18.0996 7.37638C17.2601 6.5301 16.6002 5.48239 16.1917 4.34628L16.0186 3.86445C15.9404 3.64636 15.7325 3.5 15.5007 3.5C15.2689 3.5 15.0611 3.64636 14.9836 3.86445L14.809 4.34918C14.4077 5.48094 13.7508 6.52792 12.9084 7.3771C12.0638 8.22918 11.0093 8.8965 9.86194 9.30805L9.36723 9.48122C9.14777 9.55874 9 9.76742 9 10C9 10.2326 9.14777 10.442 9.36796 10.5195L9.85904 10.692C10.9853 11.0955 12.0407 11.7686 12.9098 12.6381C13.7493 13.4779 14.4056 14.5205 14.8097 15.6544L14.9828 16.1355C15.0611 16.3529 15.2689 16.5 15.5 16.5C15.7311 16.5 15.9397 16.3536 16.0172 16.1363L16.1903 15.6552C16.601 14.5191 17.2601 13.4757 18.0967 12.6381C18.9687 11.7657 20.0234 11.0926 21.146 10.692L21.6342 10.5188C21.853 10.4413 22 10.2326 22 10C22 9.76769 21.8533 9.55996 21.635 9.48221C21.6347 9.48211 21.6347 9.48172 21.635 9.48158V9.48158Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7754 18.181C10.7755 18.1809 10.7755 18.1806 10.7753 18.1806L10.4758 18.0742C9.76532 17.8214 9.11678 17.4103 8.59973 16.8855C8.08313 16.3647 7.67707 15.7199 7.42568 15.0208L7.31915 14.7243C7.27101 14.5901 7.14308 14.5 7.00045 14.5C6.85781 14.5 6.72989 14.5901 6.68219 14.7243L6.57478 15.0226C6.32784 15.719 5.92356 16.3633 5.40517 16.8859C4.88545 17.4103 4.23646 17.8209 3.53042 18.0742L3.22599 18.1807C3.09093 18.2285 3 18.3569 3 18.5C3 18.6431 3.09093 18.772 3.22644 18.8197L3.52864 18.9258C4.22175 19.1742 4.87118 19.5884 5.40606 20.1235C5.92267 20.6402 6.3265 21.2819 6.57522 21.9797L6.68175 22.2757C6.72989 22.4095 6.85781 22.5 7 22.5C7.14219 22.5 7.27056 22.4099 7.31825 22.2762L7.42478 21.9801C7.67751 21.281 8.08313 20.6389 8.59795 20.1235C9.13461 19.5866 9.7836 19.1724 10.4745 18.9258L10.7749 18.8193C10.9095 18.7715 11 18.6431 11 18.5C11 18.357 10.9097 18.2292 10.7754 18.1814C10.7752 18.1813 10.7752 18.1811 10.7754 18.181V18.181Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id={uniqueId} fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 1.5C4.58579 1.5 4.25 1.83579 4.25 2.25V3.75H2.75C2.33579 3.75 2 4.08579 2 4.5C2 4.91421 2.33579 5.25 2.75 5.25H4.25V6.75C4.25 7.16421 4.58579 7.5 5 7.5C5.41421 7.5 5.75 7.16421 5.75 6.75V5.25H7.25C7.66421 5.25 8 4.91421 8 4.5C8 4.08579 7.66421 3.75 7.25 3.75H5.75V2.25C5.75 1.83579 5.41421 1.5 5 1.5Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1.5C4.58579 1.5 4.25 1.83579 4.25 2.25V3.75H2.75C2.33579 3.75 2 4.08579 2 4.5C2 4.91421 2.33579 5.25 2.75 5.25H4.25V6.75C4.25 7.16421 4.58579 7.5 5 7.5C5.41421 7.5 5.75 7.16421 5.75 6.75V5.25H7.25C7.66421 5.25 8 4.91421 8 4.5C8 4.08579 7.66421 3.75 7.25 3.75H5.75V2.25C5.75 1.83579 5.41421 1.5 5 1.5Z"
        fill={color}
      />
      <path
        d="M4.25 3.75V4.75H5.25V3.75H4.25ZM4.25 5.25H5.25V4.25H4.25V5.25ZM5.75 5.25V4.25H4.75V5.25H5.75ZM5.75 3.75H4.75V4.75H5.75V3.75ZM5.25 2.25C5.25 2.38807 5.13807 2.5 5 2.5V0.5C4.0335 0.5 3.25 1.2835 3.25 2.25H5.25ZM5.25 3.75V2.25H3.25V3.75H5.25ZM2.75 4.75H4.25V2.75H2.75V4.75ZM3 4.5C3 4.63807 2.88807 4.75 2.75 4.75V2.75C1.7835 2.75 1 3.5335 1 4.5H3ZM2.75 4.25C2.88807 4.25 3 4.36193 3 4.5H1C1 5.4665 1.7835 6.25 2.75 6.25V4.25ZM4.25 4.25H2.75V6.25H4.25V4.25ZM5.25 6.75V5.25H3.25V6.75H5.25ZM5 6.5C5.13807 6.5 5.25 6.61193 5.25 6.75H3.25C3.25 7.7165 4.0335 8.5 5 8.5V6.5ZM4.75 6.75C4.75 6.61193 4.86193 6.5 5 6.5V8.5C5.9665 8.5 6.75 7.7165 6.75 6.75H4.75ZM4.75 5.25V6.75H6.75V5.25H4.75ZM7.25 4.25H5.75V6.25H7.25V4.25ZM7 4.5C7 4.36193 7.11193 4.25 7.25 4.25V6.25C8.2165 6.25 9 5.4665 9 4.5H7ZM7.25 4.75C7.11193 4.75 7 4.63807 7 4.5H9C9 3.5335 8.2165 2.75 7.25 2.75V4.75ZM5.75 4.75H7.25V2.75H5.75V4.75ZM4.75 2.25V3.75H6.75V2.25H4.75ZM5 2.5C4.86193 2.5 4.75 2.38807 4.75 2.25H6.75C6.75 1.2835 5.9665 0.5 5 0.5V2.5Z"
        fill={color}
        mask={`url(#${uniqueId})`}
      />
    </svg>
  );
};
