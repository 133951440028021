import { Box } from '@material-ui/core';
import { theme } from 'theme';

export const ProgressBar = ({
  step,
  totalSteps,
}: {
  step: number;
  totalSteps: number;
}) => {
  return (
    <Box
      sx={{
        height: '4px',
        width: '100%',
        backgroundColor: theme.colors.greyLight,
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          width: `${(step / totalSteps) * 100}%`,
          height: 'inherit',
          borderRadius: '8px',
          background: theme.colors.successful,
          transition: '1s ease',
        }}
      ></Box>
    </Box>
  );
};
