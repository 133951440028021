export const LAUNCH_DARKLY_STORAGE_KEY = 'launchDarklyAnonymousUserId';

export const getLaunchDarklyAnonymousUserId = () => {
  const stored = localStorage.getItem(LAUNCH_DARKLY_STORAGE_KEY);
  if (stored) {
    return stored;
  }

  // randomly assign `a` or `b` as ID for anonymous users
  const rand = Math.floor(Math.random() * 2);
  const id = rand === 0 ? 'anonymous-a' : 'anonymous-b';
  localStorage.setItem(LAUNCH_DARKLY_STORAGE_KEY, id);
  return id;
};
