import { navigate } from '@reach/router';
import axios, { AxiosResponse } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RootState } from 'store';

import { anonymousUserActions } from './slice';

export interface IAnonymousUserResponse {
  profile: {
    telephone: string;
  };
  user_id: string;
  status_code: 200 | 401 | 500;
  status_name: 'OK' | 'Unauthorized' | 'Internal Service Error';
}

export const getUserDataEpic: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(anonymousUserActions.fetch.toString()),

    switchMap(() => {
      return from(
        axios(`${import.meta.env.VITE_GATEWAY_API}users/anonymous`, {
          headers: {
            Authorization: `Email ${store.value.passwordReset.request.emailToken}`,
          },
        }),
      ).pipe(
        map((res: AxiosResponse<IAnonymousUserResponse>) => {
          return anonymousUserActions.set({
            id: res.data.user_id,
            phoneNumberExcerpt: res.data.profile.telephone.slice(-4),
          });
        }),
        catchError(() => {
          navigate('/error');
          return of({ type: 'noop' });
        }),
      );
    }),
  );
};

const exportedArray = [getUserDataEpic];
export default exportedArray;
