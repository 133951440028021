import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import { twMerge } from 'tailwind-merge';

export const SecurityAssurance = ({ className }: { className?: string }) => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);

  const LockIcon =
    themeName === 'light' ? KDSIcons.Icons.SecurityLock : LockOutlinedIcon;

  return (
    <div
      className={twMerge(
        'flex gap-2 items-center justify-center text-grey-300',
        className,
      )}
    >
      <LockIcon />
      <div className={twMerge('text-sm font-body', 'light:font-normal')}>
        {intl.formatMessage({
          id: 'SecurityAssurance.VariantTitle',
          defaultMessage: 'Your information is secured with encryption.',
        })}
      </div>
    </div>
  );
};
