import { useEffect } from 'react';

import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { muiTheme } from 'App';
import { track } from 'analytics/analytics';
import {
  PersonalizedOnboardingIntent,
  personalizedOnboardingIntentEmojiMap,
} from 'apis/personalizedOnboardingApi';
import { KDSIcons } from 'assets/images/kds_icons';
import confetti from 'canvas-confetti';
import { StatelessDrawerModal } from 'components/DrawerModal';
import { useToggle } from 'hooks/useToggle';
import { useCompletionModalContent } from 'personalizedOnboarding/utils/useCompletionModalContent';
import { theme } from 'theme';

const triggerConfetti = (emoji: string, originY: number) => {
  const scalar = 4;
  const emojiShape = confetti.shapeFromText({ text: emoji, scalar });
  const shoot = () =>
    confetti({
      shapes: [emojiShape],
      scalar,
      origin: { y: originY },
      particleCount: 12,
      spread: 300,
      ticks: 280,
      gravity: 2,
      decay: 0.98,
      startVelocity: 8,
      // the types are missing this option, even though it is valid
      // @ts-ignore
      flat: true,
      zIndex: 2000,
    });

  setTimeout(() => {
    shoot();
  }, 400);
  setTimeout(() => {
    shoot();
  }, 550);
};

export const CompletionModal = ({
  intent,
  isOpen,
  openModal,
  closeModal,
}: {
  closeModal: () => void;
  intent: PersonalizedOnboardingIntent;
  isOpen: boolean;
  openModal: () => void;
}) => {
  const isAfterTablet = useMediaQuery(muiTheme.breakpoints.up('md'));
  const {
    value: isLearnMoreOpen,
    on: openLearnMore,
    off: closeLearnMore,
  } = useToggle();
  const content = useCompletionModalContent({
    intent,
    closeModal,
    openLearnMore,
  });
  const confettiIcon = personalizedOnboardingIntentEmojiMap[intent];

  useEffect(() => {
    if (isOpen) triggerConfetti(confettiIcon, isAfterTablet ? 0.2 : 0.4);
    if (!isOpen) confetti.reset();
  }, [confettiIcon, isAfterTablet, isOpen]);

  return (
    <>
      <StatelessDrawerModal
        isOpen={isOpen}
        openModal={openModal}
        closeModal={() => {
          track({
            event: 'Clicked',
            properties: {
              name: 'personalized-onboarding-close-completed',
              intent,
            },
          });
          content.handleClose();
        }}
        modalSx={{
          '& .MuiPaper-root': {
            backgroundColor: theme.colors.blueNavy,
            '& > div:first-of-type': { pb: 1 },
          },
          '& .MuiDialogContent-root': { pt: 0 },
          '& .MuiSvgIcon-root': { color: theme.colors.white },
        }}
        drawerPaperSx={{
          backgroundColor: theme.colors.blueNavy,
          pb: 0,
          '& button > svg': { color: theme.colors.white },
        }}
        content={() => (
          <>
            <Box
              display="flex"
              sx={{
                mx: '56px',
                mb: '24px',
                justifyContent: 'center',
                minHeight: '200px',
              }}
            >
              <img
                src={content.image}
                sx={{
                  borderRadius: '16px',
                  width: 'auto',
                  maxHeight: '250px',
                  objectFit: 'contain',
                  maxWidth: '100%',
                }}
                alt=""
                role="presentation"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                alignItems: 'center',
                '& > *:not(button)': {
                  textAlign: 'center',
                  color: theme.colors.text,
                },
                p: 5,
                pb: '48px',
                mx: -4,
                mb: isAfterTablet ? -4 : 0,
                backgroundColor: theme.colors.white,
              }}
            >
              <Typography
                variant="h1"
                sx={{ fontWeight: '900', fontSize: '32px', mb: 2 }}
              >
                {content.title}
              </Typography>
              <Box
                sx={{ mb: 4, '& b': { fontWeight: '900' } }}
                data-cy="onboarding-completion-modal-subtitle"
              >
                {content.subtitle}
              </Box>
              {content.action}
            </Box>
          </>
        )}
      />
      <StatelessDrawerModal
        isOpen={isLearnMoreOpen}
        openModal={openLearnMore}
        closeModal={closeLearnMore}
        modalProps={{ icon: <KDSIcons.Spot.Tip width={64} height={64} /> }}
        modalSx={{
          '& .MuiPaper-root > div:nth-child(2)': { my: 0 },
          '& .MuiPaper-root > h1': { px: 4 },
        }}
        title={content.learnMore?.title}
        content={() => (
          <Box sx={{ mt: 2, color: theme.colors.text }}>
            {content.learnMore?.content}
          </Box>
        )}
      />
    </>
  );
};
