import { MessageDescriptor, defineMessage } from 'react-intl';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  Loadable,
  createDefaultLoadable,
  createErrorLoadable,
  createLoadingLoadable,
  createSuccessLoadable,
} from '../../utility/loadable';
import {
  OTP,
  Payee,
  PayeeCreateSubmitError,
  PayeeCreateSubmitRequest,
  PayeePayments,
  Payees,
} from '../models/billPay';

export interface PayeesState {
  otp: OTP;
  data: Payees;
  payments: PayeePayments;
  payeesSaved: Payee[];
  payeeSelectedIdentifier: string;
  payeeSelectedAmount: string;
  loadableSearch: Loadable;
  loadablePayPayee: Loadable;
  loadableGetPayees: Loadable;
  loadableCreatePayee: Loadable;
  loadableDeletePayee: Loadable;
  loadablePaymentHistory: Loadable;
}

const initialState: PayeesState = {
  otp: {
    isEnabled: false,
    last4Digits: '',
    verificationUrl: '',
    loadableOTPResend: createDefaultLoadable(),
    loadableOTPVerification: createDefaultLoadable(),
  },
  data: {
    payees: [],
    payeesDetails: '',
    payeesCount: 0,
  },
  payments: {},
  payeesSaved: [],
  payeeSelectedAmount: '',
  payeeSelectedIdentifier: '',
  loadableSearch: createDefaultLoadable(),
  loadablePayPayee: createDefaultLoadable(),
  loadableGetPayees: createDefaultLoadable(),
  loadableCreatePayee: createDefaultLoadable(),
  loadableDeletePayee: createDefaultLoadable(),
  loadablePaymentHistory: createDefaultLoadable(),
};

const payeesSlice = createSlice({
  name: 'billPay',
  initialState: initialState,
  reducers: {
    getPayeesRequest: (state) => {
      state.loadableGetPayees = createLoadingLoadable();
    },
    getPayeesSuccess: (state, { payload }: PayloadAction<Payee[]>) => {
      state.loadableGetPayees = createSuccessLoadable();
      state.payeesSaved = payload;
    },
    getPayeesError: (state) => {
      state.loadableGetPayees = createErrorLoadable(
        defineMessage({
          id: 'BillPay.GetPayeesError',
          defaultMessage: 'Failed to get payees',
        }),
      );
    },
    getPayeeSearchRequest: (
      state,
      _: PayloadAction<{ searchText: string }>,
    ) => {
      state.loadableSearch = createLoadingLoadable();
    },
    getPayeeSearchSuccess: (state, { payload }: PayloadAction<Payees>) => {
      state.loadableSearch = createSuccessLoadable();
      state.data = payload;
    },
    getPayeeSearchReset: (state) => {
      state.data.payees = [];
      state.loadableCreatePayee = createDefaultLoadable();
      state.loadableSearch = createDefaultLoadable();
    },
    getPayeeSearchError: (state) => {
      state.loadableSearch = createErrorLoadable(
        defineMessage({
          id: 'BillPay.SearchPayeeError',
          defaultMessage: 'Failed to search for payees',
        }),
      );
      state.data.payees = [];
    },
    submitCreatePayeeOTPTransition: (state, action) => {
      let { phoneNumber, verificationUrl } = action.payload;
      phoneNumber = phoneNumber.trim();
      phoneNumber = phoneNumber.slice(phoneNumber.length - 4);
      state.otp.isEnabled = true;
      state.otp.last4Digits = phoneNumber;
      state.otp.verificationUrl = verificationUrl.replace('/1.0/', '');
      state.loadableCreatePayee = createSuccessLoadable();
    },
    submitCreatePayeeOTPReset: (state) => {
      state.otp.loadableOTPVerification = createDefaultLoadable();
    },
    submitCreatePayeeOTPRequest: (
      state,
      _: PayloadAction<PayeeCreateSubmitRequest>,
    ) => {
      state.otp.loadableOTPVerification = createLoadingLoadable();
    },
    submitCreatePayeeOTPSuccess: (state) => {
      state.otp.loadableOTPVerification = createSuccessLoadable();
    },
    submitCreatePayeeOTPError: (state) => {
      state.otp.loadableOTPVerification = createErrorLoadable(
        defineMessage({
          id: 'BillPay.VerifyOtpError',
          defaultMessage: 'Failed to verify OTP',
        }),
      );
    },
    submitCreatePayeesResendOTPRequest: (
      state,
      _: PayloadAction<PayeeCreateSubmitRequest>,
    ) => {
      state.otp.loadableOTPResend = createLoadingLoadable();
    },
    submitCreatePayeesResendOTPResponse: (state) => {
      state.otp.loadableOTPResend = createSuccessLoadable();
    },
    submitCreatePayeesResendOTPError: (state) => {
      state.otp.loadableOTPResend = createErrorLoadable(
        defineMessage({
          id: 'BillPay.ResendOtpError',
          defaultMessage: 'Failed to resend OTP',
        }),
      );
    },
    submitCreatePayeesRequest: (
      state,
      _: PayloadAction<PayeeCreateSubmitRequest>,
    ) => {
      state.loadableCreatePayee = createLoadingLoadable();
    },
    submitCreatePayeesSuccess: (state) => {
      state.loadableCreatePayee = createSuccessLoadable();
    },
    submitCreatePayeesError: (
      state,
      action: PayloadAction<PayeeCreateSubmitError | undefined>,
    ) => {
      state.loadableCreatePayee = createErrorLoadable(
        action.payload?.error_message ||
          defineMessage({
            id: 'BillPay.CreatePayeeError',
            defaultMessage: 'Failed to create payee details',
          }),
      );
    },
    selectPayeeByIdentifier: (
      state,
      action: PayloadAction<{ identifier: string }>,
    ) => {
      state.payeeSelectedIdentifier = action.payload.identifier;
    },
    setPayeePayAmount: (state, action: PayloadAction<{ amount: string }>) => {
      state.payeeSelectedAmount = action.payload.amount;
    },
    resetPaymentToPayee: (state) => {
      state.payeeSelectedIdentifier = '';
      state.payeeSelectedAmount = '';
      state.loadablePayPayee = createDefaultLoadable();
    },
    submitPaymentToPayeeRequest: (
      state,
      _: PayloadAction<{
        identifier: string;
        amount: string;
      }>,
    ) => {
      state.loadablePayPayee = createLoadingLoadable();
    },
    submitPaymentToPayeeSuccess: (state) => {
      state.loadablePayPayee = createSuccessLoadable();
    },
    submitPaymentToPayeeError: (
      state,
      action: PayloadAction<MessageDescriptor>,
    ) => {
      state.loadablePayPayee = createErrorLoadable(action.payload);
    },
    deletePayeeByIdentifierRequest: (
      state,
      _: PayloadAction<{ identifier: string }>,
    ) => {
      state.loadableDeletePayee = createLoadingLoadable();
    },
    deletePayeeByIdentifierSuccess: (state) => {
      state.loadableDeletePayee = createSuccessLoadable();
    },
    deletePayeeByIdentifierError: (state) => {
      state.loadableDeletePayee = createErrorLoadable(
        defineMessage({
          id: 'BillPay.DeletePayeeError',
          defaultMessage: 'Failed to delete payee',
        }),
      );
    },
    getPayeePaymentHistoryRequest: (
      state,
      _: PayloadAction<{ identifier: string }>,
    ) => {
      state.loadablePaymentHistory = createLoadingLoadable();
    },
    getPayeePaymentHistorySuccess: (
      state,
      action: PayloadAction<PayeePayments>,
    ) => {
      state.loadablePaymentHistory = createSuccessLoadable();
      state.payments = action.payload;
    },
    getPayeePaymentHistoryError: (
      state,
      action: PayloadAction<MessageDescriptor>,
    ) => {
      state.loadablePaymentHistory = createErrorLoadable(action.payload);
    },
    noop: (state) => state,
  },
});

export const payeesActions = payeesSlice.actions;
export default payeesSlice.reducer;
