import { defineMessage } from 'react-intl';

import { navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RootState } from 'store';

import { PasswordResetUserType, passwordResetActions } from './slice';

type Violations = {
  code: number;
};

type PasswordResetWithSmsOtpResponse = {
  status:
    | 204 /* success */
    | 400 /* password invalid */
    | 401 /* unauthorized, one of the tokens is invalid */
    | 500;
  error_code?: number;
  violations?: Violations[];
};

export type AuthenticationMechanism = 'onfido' | 'sms' | 'email';

const violationCodes = {
  samePassword: 17,
  passwordHistory: 18,
  passwordLength: 19,
  uppercase: 20,
  lowercase: 21,
  username: 23,
} as const;

const passwordResetErrorMessage = (
  err: AxiosError<PasswordResetWithSmsOtpResponse>,
) => {
  const errorCode = err.response?.data.status;
  const characterRequirements = err.response?.data.violations?.some(
    (x) =>
      x.code === violationCodes.passwordLength ||
      x.code === violationCodes.uppercase ||
      x.code === violationCodes.lowercase,
  );
  const history = err.response?.data.violations?.some(
    (x) => x.code === violationCodes.passwordHistory,
  );
  const sameLastPassword = err.response?.data.violations?.some(
    (x) => x.code === violationCodes.samePassword,
  );
  const isSameAsUsername = err.response?.data.violations?.some(
    (x) => x.code === violationCodes.username,
  );

  if (errorCode === 400 && characterRequirements) {
    return defineMessage({
      id: 'PasswordResetError.PasswordRules',
      defaultMessage:
        'Your password must contain at least 8 characters, 1 uppercase, and 1 lowercase character',
    });
  } else if (errorCode === 400 && (history || sameLastPassword)) {
    return defineMessage({
      id: 'PasswordResetError.SamePassword',
      defaultMessage: 'Your password must be different from previous passwords',
    });
  } else if (errorCode === 400 && isSameAsUsername) {
    return defineMessage({
      id: 'PasswordResetError.Username',
      defaultMessage: 'Your password cannot contain your full email',
    });
  } else {
    return null;
  }
};

const getAuthenticationMechanisms = (
  emailToken?: string,
  smsOtpToken?: string,
  isOnfidoSuccess?: boolean,
): AuthenticationMechanism[] => {
  const authenticationMechanisms: AuthenticationMechanism[] = [];

  if (emailToken) {
    authenticationMechanisms.push('email');
  }

  if (smsOtpToken) {
    authenticationMechanisms.push('sms');
  }

  if (isOnfidoSuccess) {
    authenticationMechanisms.push('onfido');
  }

  return authenticationMechanisms;
};

export const passwordResetSubmitWithSmsOtpEpic: Epic<any, any, RootState> = (
  action$,
  store,
) => {
  return action$.pipe(
    ofType(passwordResetActions.submitWithSmsOtp.toString()),
    switchMap(() => {
      const url = `${import.meta.env.VITE_GATEWAY_API}users/users/${
        store.value.anonymousUser.id
      }/password`;
      const { password, emailToken } = store.value.passwordReset.request;
      const smsOtpToken = store.value.smsOtp.token;
      const isOnfidoSuccess = store.value.onfido.completedSuccessfully;

      return from(
        axios.put(url, {
          password: password,
          email_token: emailToken,
          sms_token: smsOtpToken,
          authentication_mechanisms: getAuthenticationMechanisms(
            emailToken,
            smsOtpToken,
            isOnfidoSuccess,
          ),
        }),
      ).pipe(
        map(() => {
          track({ event: 'Password reset succeeded' });
          navigate('/password-reset/success');
          return passwordResetActions.noop();
        }),
        catchError((err: AxiosError<PasswordResetWithSmsOtpResponse>) => {
          switch (err.response?.data.status) {
            case 400:
              track({
                event: 'Password reset failed',
                properties: {
                  reason:
                    'Password reset with sms-otp failed due to invalid password',
                },
              });

              return of(
                passwordResetActions.updateResetError(
                  passwordResetErrorMessage(err),
                ),
              );
            case 401:
              track({
                event: 'Password reset failed',
                properties: {
                  reason:
                    'Password reset with sms-otp failed due to one of the tokens being invalid',
                },
              });
              break;
            case 500:
              track({
                event: 'Password reset failed',
                properties: {
                  reason:
                    'Password reset with sms-otp failed due to an internal server error',
                },
              });
              break;
            default:
              track({
                event: 'Password reset failed',
                properties: {
                  reason:
                    'Password reset with sms-otp failed due to an unknown error',
                },
              });
              break;
          }

          navigate('/password-reset/error');
          return of(
            passwordResetActions.updateResetError(
              passwordResetErrorMessage(err),
            ),
          );
        }),
      );
    }),
  );
};

export const passwordResetSubmitForIdentityUserEpic: Epic<
  any,
  any,
  RootState
> = (action$, store) => {
  return action$.pipe(
    ofType(passwordResetActions.submitForIdentityUser),
    switchMap(() => {
      const url = `${
        import.meta.env.VITE_GATEWAY_API
      }user/password_reset/complete`;
      const { password, emailToken } = store.value.passwordReset.request;

      return from(
        axios.post(url, {
          password: password,
          token: emailToken,
          // temporary workaround to prevent identity-only users
          // from getting an email saying they need a passcode
          passCode: '',
        }),
      ).pipe(
        map(() => {
          track({ event: 'Password reset succeeded' });
          navigate('/password-reset/success');
          return passwordResetActions.noop();
        }),
        catchError((err) => {
          switch (err.response?.status) {
            case 403:
              track({
                event: 'Password reset failed',
                properties: {
                  reason:
                    'Password reset for identity user failed due to account being locked',
                },
              });
              navigate('/password-reset/account-locked');
              break;
            case 404:
              track({
                event: 'Password reset failed',
                properties: {
                  reason:
                    'Password reset for identity user failed due to link being expired',
                },
              });
              navigate('/password-reset/link-expired');
              break;
            default:
              track({
                event: 'Password reset failed',
                properties: {
                  reason:
                    'Password reset for identity user failed due to unhandled error',
                },
              });

              navigate('/password-reset/error');
          }
          return of(
            passwordResetActions.updateResetError(
              passwordResetErrorMessage(err),
            ),
          );
        }),
      );
    }),
  );
};

export const passwordResetCheckTokenEpic: Epic<any, any, RootState> = (
  action$,
) => {
  return action$.pipe(
    ofType(passwordResetActions.setEmailToken.toString()),
    switchMap(({ payload }) => {
      const url = `${
        import.meta.env.VITE_GATEWAY_API
      }user/password_reset/verify_token`;

      return from(axios.post(url, { token: payload })).pipe(
        map(
          ({
            data: { user_type, authorization_token, user_id },
          }: AxiosResponse<{
            user_type: PasswordResetUserType;
            authorization_token: string;
            user_id: string;
          }>) => {
            return passwordResetActions.checkTokenComplete({
              userType: user_type,
              authToken: authorization_token,
              userRef: user_id,
            });
          },
        ),
        catchError(() => {
          navigate('/password-reset/link-expired');
          return of(
            passwordResetActions.checkTokenComplete({
              userType: undefined,
              authToken: null,
              userRef: undefined,
            }),
          );
        }),
      );
    }),
  );
};

export const passwordResetRequestEmailEpic: Epic<any, any, RootState> = (
  action$,
  store,
) => {
  return action$.pipe(
    ofType(passwordResetActions.resetEmailLoadableLoading.toString()),

    switchMap(() => {
      const url = `${import.meta.env.VITE_GATEWAY_API}user/password_reset`;
      const { email } = store.value.passwordReset.request;

      return from(
        axios.post(url, {
          email: email.toLowerCase(),
          use_reset_link: true,
        }),
      ).pipe(
        map(() => {
          return passwordResetActions.resetEmailSendFulfilled();
        }),
        tap(() => {
          navigate('/password-reset/email-sent');
        }),
        catchError((err: AxiosError) => {
          switch (err.response?.status) {
            case 403:
              navigate('/password-reset/account-locked');
              return of(passwordResetActions.resetEmailSendError(null));
            case 404:
              return of(
                passwordResetActions.resetEmailSendError(
                  defineMessage({
                    id: 'PasswordResetError.NoAccount',
                    defaultMessage: `We couldn't find your KOHO account`,
                  }),
                ),
              );
            default:
              return of(passwordResetActions.noop());
          }
        }),
      );
    }),
  );
};

const exportedArray = [
  passwordResetSubmitForIdentityUserEpic,
  passwordResetSubmitWithSmsOtpEpic,
  passwordResetCheckTokenEpic,
  passwordResetRequestEmailEpic,
];
export default exportedArray;
