/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import headerImg from 'assets/images/kds_icons/spot/wait.svg';
import { Flex } from 'theme-ui';

import { ButtonUnstyled, Paragraph, TitleMedium } from '../../components';

export interface Props extends Omit<DialogProps, 'css'> {
  closeButton?: boolean;
  onClose: (event: object, reason: string) => void;
  open: boolean;
}

export const DeviceVerificationModal = ({
  onClose,
  open,
  children,
  closeButton = true,
  ...rest
}: Props) => {
  const intl = useIntl();

  return (
    <Dialog onClose={onClose} open={open} {...rest}>
      <DialogTitle sx={{ px: 3, my: 0, pb: 0, maxWidth: '375px' }}>
        <Flex
          sx={{
            alignItems: 'flex-start',
          }}
        >
          <img
            alt={`${intl.formatMessage({
              id: 'DeviceVerificationModal.ImageAltTag',
              defaultMessage: 'modal-header',
            })}`}
            src={headerImg}
          />

          {closeButton && (
            <div
              sx={{
                right: '5%',
                position: 'absolute',
              }}
            >
              <ButtonUnstyled
                onClick={onClose}
                aria-label={intl.formatMessage({
                  id: 'DeviceVerificationModal.CloseButton.AriaLabel',
                  defaultMessage: 'Close modal',
                })}
              >
                <CloseIcon fontSize="large" />
              </ButtonUnstyled>
            </div>
          )}
        </Flex>

        <TitleMedium
          sx={{
            pl: 3,
            mb: 0,
          }}
        >
          {intl.formatMessage({
            id: 'DeviceVerificationModal.Title',
            defaultMessage: 'Confirm your device to continue',
          })}
        </TitleMedium>
      </DialogTitle>
      <DialogContent sx={{ px: 5, mt: 0, pt: 0, maxWidth: '375px' }}>
        <Paragraph>
          {intl.formatMessage({
            id: 'DeviceVerificationModal.Content',
            defaultMessage:
              'Check your email inbox for a link to confirm this device. For security purposes, we require all users to do this to protect our community from fraudulent users.',
          })}
        </Paragraph>
        {children}
      </DialogContent>
    </Dialog>
  );
};
