/** @jsxImportSource theme-ui */
import { ReactNode } from 'react';

import { Flex } from 'theme-ui';

import { theme } from '../../../theme';

const Circle = ({
  children,
  light = false,
}: {
  children: ReactNode;
  light?: boolean;
}) => {
  const styles = {
    // material-ui/icons or material-ui/core "add_circle" doesn't work even in the latest version 4.11.x
    width: '48px',
    height: '48px',
    background: light ? theme.colors.greyXLight : theme.colors.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    flexShrink: 0,
  };

  return <Flex sx={styles}>{children}</Flex>;
};

export default Circle;
