import { navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import { rootApi } from 'apis/rootApi';
import { authActions } from 'auth/store/slice';
import { deleteOtpTimeout } from 'auth/store/utils';
import { brazeContentCardActions } from 'braze/store/slice';
import { loginActions } from 'login/store/slice';
import { RegistrationPage } from 'registration/models/RegistrationPage';
import { RegistrationUrl } from 'registration/models/RegistrationUrls';

import { getDeviceID } from 'libs/axios';

import {
  FingerprintEventTypes,
  createFingerprints,
} from '../fingerprints/fingerprints';

export type MFAOption = 'bypass' | 'sms' | 'totp' | 'disabled';

export interface LoginRequest {
  email: string;
  password: string;
}

export interface KycChallenge {
  blocking: boolean;
  vendor: 'manual' | 'onfido';
}

export interface LoginProfileAttributes {
  can_retry_kyc: boolean;
  cleared: boolean;
  initial_kyc_skipped: boolean;
  reference_identifier: string;
  verified_phone: boolean;
}

export interface LoginResponse {
  has_account: boolean;
  has_otp: boolean;
  is_identity_only: boolean;
  masked_phone_number: string;
  kyc_challenges?: KycChallenge[];
  profile?: LoginProfileAttributes;
}

export interface LoginFailedResponse {
  data?: {
    errors: { code: number }[];
  };
  status: number;
}

export interface NewLoginRequest {
  email: string;
  mfa_option?: MFAOption;
  password: string;
  skipFingerprint?: boolean;
}

export interface NewLoginResponse {
  errors?: { name: string; code: number }[];
  has_account: boolean;
  has_otp: boolean;
  is_identity_only: boolean;
  kyc_challenges?: { vendor_name: string; blocking: boolean }[];
  login_type: string;
  mfa_option: MFAOption;
  mfa_phone_digits?: string;
  profile: LoginProfileAttributes;
}

export const authApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<
      LoginResponse,
      {
        login: LoginRequest;
        removeProfileProperties: boolean;
        identityVerificationEnabled: boolean;
      }
    >({
      query: ({ login }) => ({
        url: '2.0/login',
        method: 'POST',
        body: {
          email: login.email,
          password: login.password,
        },
      }),
      async onQueryStarted(
        { removeProfileProperties, identityVerificationEnabled },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled;

          track({ event: 'Login Succeeded' });
          // the following checks for the user account state, and
          // redirects them accordingly

          // identity only user
          if (data?.is_identity_only) {
            navigate('/registration', {
              state: { registrationPage: RegistrationPage.UserInfo },
            });
            return;
          }

          if (data.profile?.initial_kyc_skipped) {
            // user does not have an account and
            // phone is not verified

            if (!data.profile.verified_phone) {
              navigate('/registration', {
                state: { registrationPage: RegistrationPage.VerifyMobile },
              });
              return;
            }

            // user does not have an account and
            // if has otp = does not need to go through sms verification
            // if !has otp = has to go through sms verification
            if (!data.has_account) {
              if (!data.has_otp) {
                dispatch(
                  loginActions.setLoginDestination(
                    RegistrationUrl.AccountFinish,
                  ),
                );
                dispatch(loginActions.loginSuccess());
                return;
              } else {
                dispatch(
                  loginActions.setLoginDestination(
                    RegistrationUrl.AccountFinish,
                  ),
                );
                dispatch(loginActions.smsOtpSuccess(removeProfileProperties));

                return;
              }
            }
          }

          // user has a blocking kyc challenge and needs to reverify their identity
          // currently only available in app, so are redirected to the app
          const kycChallenges = data?.kyc_challenges;
          if (
            identityVerificationEnabled &&
            kycChallenges &&
            Array.isArray(kycChallenges) &&
            kycChallenges.some((challenge) => challenge.blocking === true)
          ) {
            dispatch(
              loginActions.setLoginDestination('/identity-verification'),
            );
          }

          // user has an account and
          // if has otp = does not need to go through sms verification
          // if !has otp = has to go through sms verification
          if (data.has_otp) {
            dispatch(loginActions.smsOtpSuccess(removeProfileProperties));
            return;
          } else {
            dispatch(loginActions.loginSuccess());
          }
        } catch (error) {
          const errorRes = error as LoginFailedResponse;
          track({
            event: 'Login Failed',
            properties: { code: errorRes.status },
          });
          deleteOtpTimeout();
        }
      },
    }),
    logout: build.mutation<
      void,
      { navigateToLogin?: boolean; deleteSession?: boolean }
    >({
      query: () => ({
        url: '1.0/logout',
        method: 'POST',
      }),
      async onQueryStarted({ navigateToLogin, deleteSession }, { dispatch }) {
        if (navigateToLogin) {
          navigate('/login');
        }

        if (deleteSession) {
          dispatch(authActions.deleteSession());
        }

        dispatch(brazeContentCardActions.setHomeCards(null));
      },
      invalidatesTags: ['User'],
    }),
    newLogin: build.mutation<NewLoginResponse, NewLoginRequest>({
      query: (req) => {
        const body: Record<string, string> = {
          email: req.email,
          password: req.password,
        };

        if (req.mfa_option) {
          body.mfa_option = req.mfa_option;
        }

        return {
          url: '3.0/login',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            authActions.setNewLoginInfo({
              email: arg.email,
              isNewLogin: true,
              loginType: data.login_type ?? '',
              mfaOption: data.mfa_option ?? '',
              mfaPhoneDigits: data.mfa_phone_digits ?? '',
              pass: arg.password,
            }),
          );

          track({ event: 'Login Succeeded' });

          // We skip creating a fingerprint if the user initially has a disabled 2SV option
          if (!arg.skipFingerprint) {
            createFingerprints(
              getDeviceID(),
              FingerprintEventTypes.LoggedIn,
              data.login_type,
              data.mfa_option,
            );
          }

          if (data?.is_identity_only) {
            navigate('/registration', {
              state: { registrationPage: RegistrationPage.UserInfo },
            });
            return;
          }

          if (data.profile?.initial_kyc_skipped) {
            // phone is not verified

            if (!data.profile.verified_phone) {
              navigate('/registration', {
                state: { registrationPage: RegistrationPage.VerifyMobile },
              });
              return;
            }
          }

          // user has a blocking kyc challenge and needs to reverify their identity
          // currently only available in app, so are redirected to the app
          const kycChallenges = data?.kyc_challenges;
          if (
            kycChallenges &&
            Array.isArray(kycChallenges) &&
            kycChallenges.some((challenge) => challenge.blocking === true)
          ) {
            dispatch(
              loginActions.setLoginDestination('/identity-verification'),
            );
            dispatch(loginActions.smsOtpSuccess(true));

            return;
          }

          if (data.mfa_option === 'bypass') {
            dispatch(loginActions.loginSuccess());
            return;
          }

          if (data.mfa_option === 'disabled') {
            navigate('/sms-verify', { state: { fallback: true } });
            return;
          }

          if (data.mfa_option === 'sms') {
            navigate('/sms-verify');
            return;
          }
          if (data.mfa_option === 'totp') {
            navigate('/mfa-verify');
            return;
          }
        } catch (e) {
          return;
        }
      },
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useNewLoginMutation } =
  authApi;
