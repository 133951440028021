import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Box, Button } from 'components';
import {
  KhAutocompleteSelect,
  SelectOption,
} from 'components/inputs/KhAutocompleteSelect';
import { hisaActions } from 'hisa/store/slice';
import countries from 'models/countryCodes.json';

type Props = {
  value: string;
  onContinue: () => void;
};

export const CitizenshipSelect = ({ value, onContinue }: Readonly<Props>) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const options = Object.keys(countries).map((code) => ({
    value: code,
    label: intl.formatMessage({
      id: `Country.${code}`,
      defaultMessage: countries[code],
    }),
  }));

  return (
    <Box sx={{ mt: 3 }}>
      <KhAutocompleteSelect
        label={intl.formatMessage({
          id: 'CitizenshipSelect.Label',
          defaultMessage: 'Citizenship',
        })}
        value={value}
        options={options}
        onChange={(_: React.ChangeEvent<{}>, selectOption: SelectOption) => {
          dispatch(hisaActions.setCitizenship(selectOption?.value || ''));
        }}
      />

      <Button
        sx={{ mt: 4 }}
        trackName="HISA Citizenship Continue"
        disabled={!value}
        onClick={onContinue}
      >
        {intl.formatMessage({
          id: 'HisaSetupPage.Continue',
          defaultMessage: 'Continue',
        })}
      </Button>
    </Box>
  );
};
