import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AnonymousUserState {
  phoneNumberExcerpt: string;
  id: string;
}

const initialState: AnonymousUserState = {
  phoneNumberExcerpt: '',
  id: '',
};

const anonymousUser = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    fetch: (state) => state,
    set: (state, action: PayloadAction<AnonymousUserState>) => {
      state.phoneNumberExcerpt = action.payload.phoneNumberExcerpt;
      state.id = action.payload.id;
    },
  },
});

export const anonymousUserActions = anonymousUser.actions;
export default anonymousUser.reducer;
