export enum SupportedTransfers {
  koho = 'koho_transfer',
  eTransfer = 'etransfer',
}

export interface RecipientData {
  identifier: string;
  full_name: string;
  address: string;
  security_question: string;
  active: boolean;
  supported_transfers: SupportedTransfers[];
}

export class Recipient {
  public id: string;
  public fullName: string;
  public address: string;
  public securityQuestion: string;
  public active: boolean;
  public supportedTransfers: SupportedTransfers[];

  constructor(data: RecipientData) {
    this.id = data.identifier;
    this.fullName = data.full_name;
    this.address = data.address;
    this.securityQuestion = data.security_question;
    this.active = data.active;
    this.supportedTransfers = data.supported_transfers;
  }

  public isKOHO(): boolean {
    return this.supportedTransfers.indexOf(SupportedTransfers.koho) > -1;
  }

  public supportsETransfer(): boolean {
    return this.supportedTransfers.includes(SupportedTransfers.eTransfer);
  }

  public label(): string {
    return this.fullName || this.address || '#';
  }

  public get initials(): string {
    let initials = '',
      names: string[] = [],
      value = this.label();

    if (!value) {
      return '';
    }

    value = value.replace(/[^0-9a-zA-Z ]/gi, '');
    names = value.split(' ', 2);

    if (names.length === 1) {
      return names[0].charAt(0);
    }

    names.forEach((group) => {
      initials += group.charAt(0);
    });

    return initials.toUpperCase();
  }
}
