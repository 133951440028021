import { useContext } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import { trackInput } from 'analytics/analytics';
// assets
import headerImg from 'assets/images/app-download/app-download-bg.9aslkna.jpg';
import appStoreEn from 'assets/images/app-download/apple-en.svg';
import appStoreFr from 'assets/images/app-download/apple-fr.svg';
import googlePlayEn from 'assets/images/app-download/google-en.png';
import googlePlayFr from 'assets/images/app-download/google-fr.png';
// components
import { DialogWithImage, LOCALE_EN, Link, LocaleContext } from 'components';
import { Paragraph } from 'components/Typography';
import DownloadAppButton from 'features/buttons/DownloadApp';
// types
import type { Image } from 'types/Image';

interface DownloadAppProps {
  description?: string;
  onClose?: () => void;
  open?: boolean;
}

const image: Image = {
  displayHeights: ['160px', '350px'],
  displayWidths: ['370px', '600px'],
  url: headerImg,
};

const DownloadApp = ({
  description,
  onClose,
  open = false,
}: DownloadAppProps) => {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const modalDescription =
    description ||
    intl.formatMessage({
      id: 'DownloadAppModal.Description',
      defaultMessage:
        'Manage your plan and enjoy all your benefits with the KOHO app.',
    });

  const modalTitle = intl.formatMessage({
    id: 'DownloadAppModal.Title',
    defaultMessage: 'Get the app',
  });

  const OrText = intl.formatMessage({
    id: 'DownloadAppModal.CTA.OptionalText',
    defaultMessage: 'Or',
  });

  const handleClose = () => {
    trackInput({
      type: 'Clicked',
      name: 'Modal - Download App — Close',
      details: { element: 'button' },
    });

    onClose && onClose();
  };

  return (
    <DialogWithImage
      description={modalDescription}
      image={image}
      inverted
      open={open}
      onClose={handleClose}
      title={modalTitle}
      data-cy="download-app-modal"
    >
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <DownloadAppButton justText />
        </Grid>
        <Grid item xs={12}>
          <Paragraph sx={{ fontWeight: 700, m: 0 }}>{OrText}</Paragraph>
        </Grid>

        <Grid item xs={12}>
          <Link
            trackName="Modal - Download App — Apple App Store"
            external
            to="https://apps.apple.com/ca/app/koho-financial/id1091010942"
            aria-label={intl.formatMessage({
              id: 'DownloadAppModal.DownloadFromApple',
              defaultMessage: 'Download KOHO from the Apple app store',
            })}
          >
            <img
              src={locale === LOCALE_EN ? appStoreEn : appStoreFr}
              alt=""
              height="44"
              style={{ display: 'block' }}
            />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link
            trackName="Modal - Download App — Google Play"
            external
            to="https://play.google.com/store/apps/details?id=ca.koho"
            aria-label={intl.formatMessage({
              id: 'DownloadAppModal.DownloadFromGoogle',
              defaultMessage: 'Download KOHO from Google Play',
            })}
          >
            <img
              src={locale === LOCALE_EN ? googlePlayEn : googlePlayFr}
              alt=""
              style={{
                aspectRatio: 'auto 562 / 166',
                height: 40,
                display: 'block',
              }}
            />
          </Link>
        </Grid>
      </Grid>
    </DialogWithImage>
  );
};

export default DownloadApp;
