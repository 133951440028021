import { navigate } from '@reach/router';
import * as Sentry from '@sentry/browser';
import { track } from 'analytics/analytics';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { RootState } from 'store';

import { OnfidoInitOptions, initOnfido } from '../models/onfido';
import {
  CreateApplicantErrorCodes,
  CreateCheckErrorCodes,
  GetCheckResultErrorCode,
  OnfidoApplicantResponse,
  OnfidoResultResponse,
  onfidoActions,
} from './slice';

const createApplicantEpic: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(onfidoActions.createApplicantRequest),
    tap(() => {
      track({ event: 'Post KYC Applicant Started' });
    }),
    switchMap(() => {
      return from(
        axios({
          method: 'post',
          url: `${import.meta.env.VITE_GATEWAY_API}users/kyc/selfie/applicant`,
          data: {
            entry_point: store?.value?.passwordReset?.authToken
              ? 'password_reset'
              : 'kyc',
          },
        }),
      ).pipe(
        tap(() => {
          track({ event: 'Post KYC Applicant Succeeded' });
        }),
        map(({ data }: AxiosResponse<OnfidoApplicantResponse>) =>
          onfidoActions.createApplicantResponse({ response: data }),
        ),
        catchError((error) =>
          of(onfidoActions.createApplicantError({ error })),
        ),
      );
    }),
  );
};

const createApplicantErrorEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(onfidoActions.createApplicantError),
    tap(({ payload: { error } }) => {
      track({
        event: 'Post KYC Applicant Failed',
        properties: {
          error_code: error?.response?.data?.error_code || 'other',
        },
      });
      if (
        error.response?.data.error_code ===
        CreateApplicantErrorCodes.CheckInProgress
      ) {
        navigate('/kyc/wait');
      } else if (
        error.response?.data.error_code ===
        CreateApplicantErrorCodes.ApplicantAlreadyCleared
      ) {
        navigate('/kyc/success');
      } else {
        navigate('/kyc/fail');
      }
    }),
    map(onfidoActions.noop),
  );
};

const initializeOnfidoEpic: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(onfidoActions.initializeOnfido),
    tap(async ({ payload: { locale } }) => {
      const onfidoData = store.value.onfido.data;

      const onComplete: OnfidoInitOptions['onComplete'] = (_) => {
        track({ event: 'Onfido User Completed' });
        Sentry.captureMessage('uploaded');
        navigate('/kyc/wait');
      };
      const onError: OnfidoInitOptions['onError'] = (error) => {
        track({ event: 'Onfido User Failed' });
        Sentry.captureException(error);
      };

      await initOnfido({
        token: onfidoData?.token,
        reportTypes: onfidoData?.report_types,
        locale,
        onComplete,
        onError,
      });
    }),
    map(() => onfidoActions.noop()),
  );
};

const createCheckEpic: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(onfidoActions.createCheckRequest),
    tap(() => {
      track({ event: 'Post KYC Check Started' });
    }),
    switchMap(() => {
      return from(
        axios({
          method: 'post',
          url: `${
            import.meta.env.VITE_GATEWAY_API
          }users/kyc/selfie/check/create`,
          data: {
            entry_point: store?.value?.passwordReset?.authToken
              ? 'password_reset'
              : 'kyc',
          },
        }),
      ).pipe(
        tap(() => {
          track({
            event: 'Post KYC Check Succeeded',
          });
        }),
        switchMap(() => [
          onfidoActions.createCheckResponse(),
          onfidoActions.getCheckResultRequest(),
        ]),
        catchError((error) => of(onfidoActions.createCheckError({ error }))),
      );
    }),
  );
};

const createCheckErrorEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(onfidoActions.createCheckError),
    tap(({ payload: { error } }) => {
      track({
        event: 'Post KYC Check Failed',
        properties: {
          error_code: error?.response?.status || 'other',
        },
      });
      if (error.response?.status === CreateCheckErrorCodes.CheckInProgress) {
        navigate('/kyc/wait');
      } else {
        navigate('/kyc/fail');
      }
    }),
    map(() => onfidoActions.noop()),
  );
};

const getCheckResultEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(onfidoActions.getCheckResultRequest),
    tap(() => {
      track({
        event: 'Get KYC Check Verify Started',
      });
    }),
    switchMap(() => {
      return from(
        axios.get(
          `${import.meta.env.VITE_GATEWAY_API}users/kyc/selfie/check/verify`,
        ),
      ).pipe(
        map((response: AxiosResponse<OnfidoResultResponse>) => {
          if (response.status === 200 && response.data.data.next_step) {
            // kyc failed
            track({
              event: 'Get KYC Check Verify Failed',
              properties: {
                next_step: response.data.data.next_step,
              },
            });
            return onfidoActions.getCheckResultError();
          } else if (response.status === 204) {
            // kyc passed
            track({
              event: 'Get KYC Check Verify Succeeded',
            });
            return onfidoActions.getCheckResultResponse(response.data);
          }
        }),
        catchError((error: AxiosError) => {
          if (
            error.response?.status === GetCheckResultErrorCode.CheckInProgress
          ) {
            return of(onfidoActions.retryGetCheckResult());
          } else {
            return of(onfidoActions.getCheckResultError());
          }
        }),
      );
    }),
  );
};

const getCheckResultResponseEpic: Epic<any, any, RootState> = (
  action$,
  store,
) => {
  return action$.pipe(
    ofType(onfidoActions.getCheckResultResponse),
    tap(async () => {
      if (store.value.passwordReset.authToken) {
        await navigate('/password-reset/create');
      } else {
        await navigate('/kyc/success');
      }
    }),
    map(onfidoActions.noop),
  );
};

const retryGetCheckResultEpic: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(onfidoActions.retryGetCheckResult),
    tap(() => {
      const retry = store.value.onfido.retries;
      track({
        event: 'Get KYC Check Verify Retried',
        properties: { retry },
      });
    }),
    switchMap(() => {
      const retry = store.value.onfido.retries;
      const timer = store.value.onfido.timer;

      if (retry === timer.length) {
        track({
          event: 'Get KYC Check Verify Retry Timeout',
        });
        return of(onfidoActions.getCheckResultError());
      }

      return of(onfidoActions.getCheckResultRequest()).pipe(
        delay(timer[retry]),
      );
    }),
  );
};

const getCheckResultErrorEpic: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(onfidoActions.getCheckResultError),
    tap(() => {
      const retry = store.value.onfido.retries;
      track({
        event: 'Get KYC Check Verify Retry Failed',
        properties: {
          retry: retry,
        },
      });
    }),
    tap(() => navigate('/kyc/fail')),
    map(onfidoActions.noop),
  );
};

const exportedArray = [
  createApplicantEpic,
  createApplicantErrorEpic,
  createCheckEpic,
  createCheckErrorEpic,
  getCheckResultEpic,
  getCheckResultErrorEpic,
  getCheckResultResponseEpic,
  initializeOnfidoEpic,
  retryGetCheckResultEpic,
];

export default exportedArray;
