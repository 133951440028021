import { useIntl } from 'react-intl';

import { Box, Typography } from '@material-ui/core';
import { KDSIcons } from 'assets/images/kds_icons';
import { theme } from 'theme';

export const CancelAnytime = () => {
  const intl = useIntl();

  return (
    <Box textAlign="center">
      <KDSIcons.Icons.Time color={theme.colors.primary} />
      <Typography
        sx={{
          fontSize: '18px',
          fontWeight: 900,
          color: theme.colors.text,
        }}
      >
        {intl.formatMessage({
          id: 'TierSelectionPage.CancelAnyTime',
          defaultMessage: 'Cancel any time',
        })}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          color: theme.colors.text,
          maxWidth: '200px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {intl.formatMessage({
          id: 'TierSelectionPage.CancelAnyTimeText',
          defaultMessage: 'Cancel your plan at any time - no penalties or fees',
        })}
      </Typography>
    </Box>
  );
};
