/** @jsxImportSource theme-ui */
import { CSSProperties, ReactNode } from 'react';

import { RouteComponentProps } from '@reach/router';
import clsx from 'clsx';
import NavbarBasic from 'components/NavbarBasic';
import SitewideAlert from 'components/SitewideAlert';
import { twMerge } from 'tailwind-merge';
import { useTrackPageViewedOnceDeprecated } from 'utility/analyticsHooks';

export const FooterImageWrapper = ({
  bg = 'no-value',
  colorStripHeight = '50%',
  ...props
}) => (
  <div
    style={
      {
        '--bg': bg,
        '--color-strip-height': colorStripHeight,
      } as CSSProperties
    }
    className={twMerge(
      clsx(
        `w-full relative flex items-center justify-center before:content-[""] before:absolute before:w-full before:z-[1] before:h-[var(--color-strip-height)] before:bottom-0`,
        bg !== 'no-value' ? 'before:bg-[var(--bg)]' : 'before:bg-primary-300',
      ),
      props.className,
    )}
  >
    {props.children}
  </div>
);

export const Card = ({ ...props }) => (
  <div
    className={twMerge(
      'bg-transparent lg:bg-white max-w-[500px] w-full md:w-[500px] p-6 rounded mb-5 [&>*:last-child]:mb-0',
      props.className,
    )}
  >
    {props.children}
  </div>
);

export const LayoutBasic = ({
  children,
  pageName,
  includeNavBar = true,
  chat = true,
  includeLanguageToggle,
  className,
}: {
  children: ReactNode;
  pageName: string;
  chat?: boolean;
  includeNavBar?: boolean;
  includeLanguageToggle?: boolean;
  className?: string;
} & RouteComponentProps) => {
  useTrackPageViewedOnceDeprecated({ name: pageName });

  return (
    <div className={clsx('min-h-screen flex flex-col', className)}>
      <SitewideAlert />
      {includeNavBar ? (
        <NavbarBasic
          languageToggle={includeLanguageToggle}
          className="mb-3"
          chat={chat}
        />
      ) : null}
      <main className="flex-1 flex min-h-full flex-col justify-between items-center w-full">
        {children}
      </main>
    </div>
  );
};
