import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  selectLaunchDarklyFlagsIsError,
  selectLaunchDarklyFlagsIsLoading,
  selectLaunchDarklyFlagsIsSuccess,
} from 'libs/launchdarkly/store/selectors';

export interface LDHookType<T> {
  ldIsLoading: boolean;
  ldFlagsIsLoading: boolean;
  ldFlagsIsSuccess: boolean;
  ldFlagsIsError: boolean;
  ldValue: T;
}

/** @deprecated please use LaunchDarkly's useFlags hook */
export function useIsLdLoaded<T>(experimentFlag: string): LDHookType<T> {
  // ldIsLoading is required to initialize the loading
  // state as true.  This overrides selectLaunchDarklyFlagsIsLoading
  // initial state of false to avoid a race condition
  const [ldIsLoading, setLdIsLoading] = useState<boolean>(true);
  const ldFlagsIsLoading = useSelector(selectLaunchDarklyFlagsIsLoading);
  const ldFlagsIsSuccess = useSelector(selectLaunchDarklyFlagsIsSuccess);
  const ldFlagsIsError = useSelector(selectLaunchDarklyFlagsIsError);
  const variant = useFlags()[experimentFlag];
  const [ldValue, setLdValue] = useState<T>(variant);

  useEffect(() => {
    if (ldFlagsIsSuccess) {
      setLdIsLoading(false);
      setLdValue(variant);
    } else if (ldFlagsIsLoading) {
      setLdIsLoading(true);
    } else if (ldFlagsIsError) {
      setLdIsLoading(false);
    }
  }, [
    ldFlagsIsSuccess,
    ldFlagsIsLoading,
    ldFlagsIsError,
    variant,
    experimentFlag,
  ]);

  return {
    ldIsLoading,
    ldFlagsIsLoading,
    ldFlagsIsSuccess,
    ldFlagsIsError,
    ldValue,
  };
}
