/** @jsxImportSource theme-ui */
import { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RouteComponentProps, useLocation } from '@reach/router';
import { useLanguageMutation } from 'apis/users';
import LoadingOverlay from 'components/Loading/Overlay';
import { loginActions } from 'login/store/slice';
import { selectPasswordResetAuthToken } from 'passwordReset/store/selectors';

import { selectHasRetrievedAccounts } from '../../accounts/store/selectors';
import { accountsActions } from '../../accounts/store/slice';
import { LOCALE_EN, LOCALE_FR, LocaleContext } from '../../components';
import {
  selectProfile,
  selectProfileDone,
  selectProfileLoading,
} from '../../profile/store/selectors';
import { profileActions } from '../../profile/store/slice';
import {
  selectAuthIsAuthenticated,
  selectAuthIsForcedLogout,
} from '../store/selectors';
import { authActions } from '../store/slice';
import { SessionRefreshModal } from './';

type PrivateRouteProps = { component } & RouteComponentProps;

export const PrivateRoute = ({
  component: Component,
  ...props
}: PrivateRouteProps) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const isForcedLogout = useSelector(selectAuthIsForcedLogout);
  const profile = useSelector(selectProfile);
  const profileIsLoading = useSelector(selectProfileLoading);
  const profileDone = useSelector(selectProfileDone);
  const hasRetrievedAccounts = useSelector(selectHasRetrievedAccounts);
  const resetPasswordAuthToken = useSelector(selectPasswordResetAuthToken);
  const context = useContext(LocaleContext);
  const [languageMutation] = useLanguageMutation();
  const [isInit, setIsInit] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (!resetPasswordAuthToken && (!isAuthenticated || isForcedLogout)) {
      dispatch(authActions.deleteSession());

      let destination = `${location.pathname}`;
      if (destination.includes('/settings')) {
        destination = '/transactions';
      }

      destination += location.search;

      dispatch(loginActions.setLoginDestination(destination));
    }
  }, [
    dispatch,
    isAuthenticated,
    isForcedLogout,
    resetPasswordAuthToken,
    location,
  ]);

  useEffect(() => {
    if (isAuthenticated && !isForcedLogout && !profileDone) {
      dispatch(profileActions.getProfileRequest());
    }
  }, [dispatch, isAuthenticated, isForcedLogout, profileDone]);

  useEffect(() => {
    if (isAuthenticated && !isForcedLogout && !hasRetrievedAccounts) {
      dispatch(accountsActions.getAccountsAllRequest());
    }
  }, [dispatch, isAuthenticated, hasRetrievedAccounts, isForcedLogout]);

  if (isInit && profile) {
    if (profile.language !== context.locale) {
      const localeToSet = context.locale === LOCALE_FR ? LOCALE_FR : LOCALE_EN;

      languageMutation({
        userId: profile?.reference_identifier,
        request: { language: localeToSet },
      }).unwrap();
    }

    setIsInit(false);
  }

  if (
    !resetPasswordAuthToken &&
    (!isAuthenticated || profileIsLoading || !hasRetrievedAccounts)
  ) {
    return <LoadingOverlay />;
  }

  return (
    <Fragment>
      <SessionRefreshModal />
      <Component {...props} />
    </Fragment>
  );
};
