import { createSlice } from '@reduxjs/toolkit';

export interface DeviceVerificationState {
  open: boolean;
}

const initialState: DeviceVerificationState = {
  open: false,
};

const deviceVerificationSlice = createSlice({
  name: 'deviceVerification',
  initialState: initialState,
  reducers: {
    openDeviceVerification: (state) => {
      state.open = true;
    },
    closeDeviceVerification: (state) => {
      state.open = false;
    },
  },
});

export const deviceVerificationActions = deviceVerificationSlice.actions;
export default deviceVerificationSlice.reducer;
