/** @jsxImportSource theme-ui */
import React from 'react';

import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
} from '@material-ui/lab';
import { trackInput } from 'analytics/analytics';

import { EnterKey, KhInputText } from './KhInputText';

export interface SelectOption {
  value: string;
  label: string;
}

export type KhAutocompleteSelectProps = AutocompleteProps<
  SelectOption,
  boolean,
  boolean,
  boolean
> & {
  trackName: string;
  value: string;
  label: string;
  onChange: (
    event: React.ChangeEvent<{}>,
    value: string | SelectOption | (string | SelectOption)[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined,
  ) => void;
  onEnterPressed?: () => void;
};

export const KhAutocompleteSelect = React.forwardRef<any, any>(
  (
    {
      value,
      onChange,
      label,
      onFocus,
      onEnterPressed,
      trackName,
      ...props
    }: KhAutocompleteSelectProps,
    ref,
  ) => {
    const [selectValue, setSelectValue] = React.useState<string | null>(value);

    React.useEffect(() => {
      setSelectValue(value);
    }, [value]);

    const focus = (event: React.FocusEvent<HTMLDivElement>) => {
      if (trackName) {
        trackInput({ name: trackName, type: 'Focused' });
      }

      if (onFocus) {
        onFocus(event);
      }
    };

    const keyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (onEnterPressed && event.key === EnterKey) {
        onEnterPressed();
      }
    };

    return (
      <Autocomplete
        ref={ref}
        {...props}
        getOptionLabel={(option: SelectOption) => option.label || ''}
        value={
          props.options.find((option) => option.value === selectValue) || ''
        }
        onFocus={focus}
        fullWidth={true}
        renderInput={(params) => (
          <KhInputText variant="outlined" label={label} {...params} />
        )}
        onChange={(event, value, reason, details) => {
          onChange(event, value, reason, details);
          setSelectValue(value ? (value as SelectOption).value : null);
        }}
        onKeyDown={keyDown}
      />
    );
  },
);
