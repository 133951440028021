/** @jsxImportSource theme-ui */
import { ReactNode } from 'react';

import { theme } from '../theme';

export const Ul = ({ children, ...props }) => {
  return (
    <ul
      sx={{
        fontSize: 2,
        listStyle: 'none',
        p: 0,
        m: 0,
      }}
      {...props}
    >
      {children}
    </ul>
  );
};

export const Li = ({
  defaultStyling = true,
  className,
  children,
  ...props
}: {
  children: ReactNode;
  className?: string;
  defaultStyling?: boolean;
}) => {
  return (
    <li
      className={className}
      sx={
        defaultStyling
          ? {
              '&::before': {
                content: '"•"',
                color: theme.colors.orange,
                p: 1,
                fontSize: 3,
              },
            }
          : {}
      }
      {...props}
    >
      {children}
    </li>
  );
};
