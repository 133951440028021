import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as braze from '@braze/web-sdk';
import { brazeContentCardActions } from 'braze/store/slice';

export const useBrazeInitialize = () => {
  const dispatch = useDispatch();

  let options = {
    allowCrawlerActivity: false,
    allowUserSuppliedJavascript: true,
    baseUrl: `${import.meta.env.VITE_BRAZE_SDK_ENDPOINT}`,
    enableLogging: !!import.meta.env.VITE_BRAZE_ENABLE_LOGGING,
    manageServiceWorkerExternally: !!import.meta.env
      .VITE_BRAZE_MANAGE_SERVICE_WORKER_EXTERNALLY,
  };

  braze.initialize(`${import.meta.env.VITE_BRAZE_API_KEY}`, options);

  useEffect(() => {
    const subscriptionId = braze.subscribeToContentCardsUpdates((cards) => {
      const filteredCards = cards.cards
        .filter(
          (card) =>
            card.extras.card_type === 'home_card' &&
            card instanceof braze.ClassicCard &&
            !!card.extras.web_link,
        )
        .sort(
          (a, b) =>
            Number(a.extras.card_priority) - Number(b.extras.card_priority),
        );

      dispatch(
        brazeContentCardActions.setHomeCards(
          filteredCards as braze.ClassicCard[],
        ),
      );

      return () => {
        if (!!subscriptionId) {
          braze.removeSubscription(subscriptionId);
        }
      };
    });
  }, [dispatch]);
};

export const showBrazeInAppMessage = () => {
  braze.subscribeToInAppMessage(function (inAppMessage) {
    if (inAppMessage.isControl) {
      // v4.5.0+, otherwise use  `inAppMessage instanceof braze.ControlMessage`
      return braze.showInAppMessage(inAppMessage);
    }

    braze.showInAppMessage(inAppMessage);
  });
};
