/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useLocation } from '@reach/router';
import { BalanceModal, useBalanceModal } from 'balance/components/BalanceModal';
import { SuccessPopup } from 'components/popups/SuccessPopup';
import WelcomeModal from 'features/modals/Welcome';
import { Flex } from 'theme-ui';
import {
  DowngradeSuccess,
  DowngradeSuccessProps,
} from 'tiers/components/downgradeConfirmationModal/DowngradeSuccess';

import { showBrazeInAppMessage } from 'libs/braze';

import { selectHasRetrievedAccounts } from './accounts/store/selectors';
import { InPageNotification, Template } from './components';
import { desktop, theme } from './theme';
import {
  TransactionsList,
  TransactionsSidebar,
  VirtualCardWithBalance,
} from './transactions/components';

interface TransactionsLocationState {
  showWelcomeModal?: boolean;
  showUpgradePlanSuccess?: boolean;
  downgradePlanSuccess?: DowngradeSuccessProps;
}

export const TransactionsPage = () => {
  const intl = useIntl();
  const hasLoaded = useSelector(selectHasRetrievedAccounts);
  const isDesktop = useMediaQuery(desktop);
  const { state } = useLocation();

  const { isShowing: balanceIsShowing, showBalanceModal } = useBalanceModal();
  const showBalance = React.useCallback(
    () => showBalanceModal(true),
    [showBalanceModal],
  );

  const {
    showWelcomeModal = false,
    showUpgradePlanSuccess = false,
    downgradePlanSuccess = undefined,
  } = (state as TransactionsLocationState) || {
    showWelcomeModal: false,
    showUpgradePlanSuccess: false,
    downgradePlanSuccess: undefined,
  };

  showBrazeInAppMessage();

  return (
    <Template name="Transactions Page">
      <Grid
        container
        direction="row-reverse"
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          p: theme.space[1],
          pt: 0,
          [desktop]: {
            p: 6,
            pt: 2,
          },
        }}
      >
        <Grid
          item
          xs={12}
          lg={4}
          sx={{
            [desktop]: {
              pl: 4,
            },
          }}
        >
          <TransactionsSidebar showBalance={showBalance} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            [desktop]: {
              pr: 4,
            },
          }}
        >
          <Flex
            sx={{
              gap: theme.space[4],
              flexFlow: 'column',
            }}
          >
            {isDesktop ? (
              <VirtualCardWithBalance showBalance={showBalance} />
            ) : null}
            {!hasLoaded && (
              <InPageNotification>
                <div sx={{ color: theme.colors.primary }}>
                  {intl.formatMessage({
                    id: 'TransactionPage.LoadFunds',
                    defaultMessage:
                      'Load funds to your account to start spending! Add this card to your phone’s wallet to shop in-store.',
                  })}
                </div>
              </InPageNotification>
            )}
            <TransactionsList />
          </Flex>
        </Grid>
      </Grid>

      <BalanceModal
        open={balanceIsShowing}
        onClose={() => showBalanceModal(false)}
      />

      <WelcomeModal open={showWelcomeModal} />
      {showUpgradePlanSuccess && (
        <SuccessPopup
          useConfetti
          useLightCheck
          message={intl.formatMessage({
            id: 'TiersUpgrade.Modal.UpgradeSuccess',
            defaultMessage: 'Plan upgraded',
          })}
        />
      )}
      {downgradePlanSuccess && <DowngradeSuccess {...downgradePlanSuccess} />}
    </Template>
  );
};
