import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectNotificationState = (state: RootState) =>
  state.snackbarNotification;

export const selectNotification = createSelector(
  selectNotificationState,
  (state) => state,
);
