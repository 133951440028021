/** @jsxImportSource theme-ui */
import { ReactNode } from 'react';

import { theme } from '../theme';

type CustomBox = {
  variant?: 'basic' | 'outlined' | 'shadow';
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
};

export const Box = ({
  variant = 'basic',
  className,
  children,
  onClick,
  ...props
}: CustomBox) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onClick?.();
    }
  };

  const isInteractive = Boolean(onClick);

  return (
    <div
      {...props}
      onClick={onClick}
      onKeyDown={isInteractive ? handleKeyDown : undefined}
      className={className}
      tabIndex={isInteractive ? 0 : undefined}
      sx={() => {
        const boxStylesByVariant = {
          basic: {},
          outlined: {
            borderRadius: theme.borderRadius.small,
            borderColor: theme.colors.grey,
            borderStyle: 'solid',
            borderWidth: '1px',
          },
          shadow: {
            boxShadow: 'light',
            borderRadius: theme.borderRadius.small,
          },
        };
        return {
          ...boxStylesByVariant[variant],
        };
      }}
    >
      {children}
    </div>
  );
};
