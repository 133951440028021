export const ColourWheelValues = {
  a: '#1b7184',
  b: '#0b918a',
  c: '#098754',
  d: '#07af6b',
  e: '#469929',
  f: '#df7100',
  g: '#b90500',
  h: '#81000b',
  i: '#c60340',
  j: '#964c62',
  k: '#b21247',
  l: '#b13b76',
  m: '#a42990',
  n: '#c90060',
  o: '#d82a89',
  p: '#792bb9',
  q: '#4b25ad',
  r: '#1b3fc2',
  s: '#553079',
  t: '#1d5b8e',
  u: '#1963b0',
  v: '#1236b7',
  w: '#157dee',
  x: '#045f7a',
  y: '#027d86',
  z: '#06ab8e',
};
