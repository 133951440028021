import { useCallback, useState } from 'react';

import { IBenefitGroup, IPlan } from 'apis/tiers';
import { PlanOption } from 'tiers/components/PlanTabs';
import { TierKey } from 'tiers/models';

type Props = {
  initialPlan?: PlanOption;
  tiers: IBenefitGroup[];
};

export const useSelectedTierPlan = ({
  initialPlan = 'monthly',
  tiers,
}: Props) => {
  const [plan, setPlan] = useState(initialPlan);
  const [selectedTier, setSelectedTier] = useState(TierKey.Essential);

  const currentTierSelected = tiers.find(({ key }) => key === selectedTier);
  const currentPlanSelected = currentTierSelected?.plans?.find(
    ({ key }) => key === plan,
  ) as (Omit<IPlan, 'key'> & { key: PlanOption }) | undefined;

  const setTier = useCallback((tier: TierKey) => setSelectedTier(tier), []);

  return {
    setPlan,
    setTier,
    currentTierSelected,
    currentPlanSelected,
  };
};
