import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  Loadable,
  createDefaultLoadable,
  createErrorLoadable,
  createLoadingLoadable,
  createSuccessLoadable,
} from '../../utility/loadable';
import { DirectDeposit } from '../models/directDeposit';

export interface DirectDepositState {
  data: DirectDeposit;
  loadable: Loadable;
}

const initialState: DirectDepositState = {
  data: {
    client_name: '',
    client_number: '',
    financial_institution_name: '',
    financial_institution_number: '',
    transit_number: '',
  },
  loadable: createDefaultLoadable(),
};

const directDepositSlice = createSlice({
  name: 'directDeposit',
  initialState: initialState,
  reducers: {
    getDirectDepositRequest: (state) => {
      state.loadable = createLoadingLoadable();
    },
    getDirectDepositSuccess: (state, action: PayloadAction<DirectDeposit>) => {
      state.loadable = createSuccessLoadable();
      state.data = action.payload;
    },
    getDirectDepositError: (state) => {
      state.loadable = createErrorLoadable('Failed to get profile info');
    },
    noop: (state) => state,
  },
});

export const directDepositActions = directDepositSlice.actions;
export default directDepositSlice.reducer;
