export const intercomUpdate = (attributes?: { [key: string]: any }) => {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom('update', attributes);
  }
};

export const intercomBoot = (attributes?: { [key: string]: any }) => {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom('boot', {
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      ...attributes,
    });
  }
};

export const intercomTrack = (eventName?: string) => {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom('trackEvent', eventName);
  }
};

export const intercomShow = () => {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom('show');
  }
};

export const intercomHide = () => {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom('hide');
  }
};

export const intercomShutdown = () => {
  const Intercom = (window as any).Intercom;
  if (Intercom) {
    Intercom('shutdown');
  }

  // There is a strange behavior (possibly a bug) with the intercom widget, where at certain times,
  // calling shutdown triggers a slightly different outcome. As part of the shutdown method, the script
  // attempts to overwrite the intercom session and id cookies, but for some reason is inconsistent
  // with the domain it uses. So, while it typically writes those cookies to the top level domain,
  // at times, when it attempts to overwrite them, it overwrites them with the subdomain included as part
  // of the domain, and so this means that the actual cookie that was written with the top level domain
  // stays in place, leading to a security issue.
  //
  // This following script is to manually overwrite the intercom session and id cookies, but leave the device-id
  // one intact to mirror how intercom's script behaves with respect to their cookies (when it works).

  const hostName = window.location.hostname;
  // get just the top level domain, without the subdomain
  const domainName = hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1,
  );

  document.cookie
    .split('; ')
    .filter(
      (cookie) =>
        cookie.startsWith('intercom-') &&
        !cookie.startsWith('intercom-device-id'),
    )
    .forEach((cookie) => {
      const name = cookie.split('=')[0];
      const newCookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/; domain=${domainName}`;
      document.cookie = newCookie;
    });
};

export const intercomReboot = () => {
  intercomShutdown();
  intercomBoot();
};
