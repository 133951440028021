import { Suspense, lazy, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import { Redirect, Router } from '@reach/router';
import { SavingsPage } from 'SavingsPage';
import { appStateActions, selectTheme } from 'appState/appState.slice';
import BasisGrotesqueRegular from 'assets/fonts/basis-grotesque-regular-pro.woff2';
import { CardDetails } from 'cards/pages/CardDetails';
import { MyCardsPage } from 'cards/pages/MyCards';
import { OrderPhysicalCardPage } from 'cards/pages/OrderPhysical';
import LoadingOverlay from 'components/Loading/Overlay';
import { ContactModal } from 'contact/ContactModal';
import { contactSelectors } from 'contact/store/contact.selectors';
import { contactActions } from 'contact/store/contact.slice';
import { CraRegistration } from 'craRegistration/CraRegistration';
import { useForethought } from 'forethought/useForethought';
import { HisaAddressPage } from 'hisa/pages/HisaAddressPage';
import { HisaAgreementPage } from 'hisa/pages/HisaAgreementPage';
import { HisaAssumptionsPage } from 'hisa/pages/HisaAssumptionsPage';
import { HisaEntryPage } from 'hisa/pages/HisaEntryPage';
import { HisaReleaseRoute } from 'hisa/pages/HisaReleaseRoute';
import { HisaRequirementsPage } from 'hisa/pages/HisaRequirementsPage';
import { HisaSetupPage } from 'hisa/pages/HisaSetupPage';
import { useAppDispatch } from 'hooks/redux';
import { IdentityVerificationLandingPage } from 'identity-verification/IdentityVerificationLandingPage';
import { InboundETransfersPage } from 'inboundETransfer/pages/InboundETransfersPage';
import { SelectPlatformPage } from 'inboundETransfer/pages/SelectPlatformPage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LoginPageWrapper } from 'login/pages/LoginPageWrapper';
import { SmsVerifyPage } from 'login/pages/SmsVerifyPage';
import { MFAFeatureFlags } from 'mfa/models/MFAFeatureFlags';
import { MFAVerify } from 'mfa/pages/MFAVerify';
import { ReferAndEarnPage } from 'referAndEarn/pages/ReferAndEarnPage';
import { referAndEarnFlags } from 'referAndEarn/referAndEarnFlags';
import { ReferralPage } from 'referrals/ReferralEarnPage';
import { RegistrationAccountErrorPage } from 'registration/pages/RegistrationAccountErrorPage';
import { RegistrationAccountFinishPage } from 'registration/pages/RegistrationAccountFinishPage';
import { RequestMoneyIntermediaryPage } from 'requestMoney/pages/RequestMoneyIntermediaryPage';
import { ThemeProvider } from 'theme-ui';
import NbaPage from 'tiers/nba-incentives/NbaPage';
import { TierManagementPage } from 'tiers/pages/TierManagementScreen';
import { TierSelectionPage } from 'tiers/pages/TierSelectionScreen';
import { TierUpgradePage } from 'tiers/pages/TierUpgradeScreen';
import { TiersTrialSelectionPage } from 'tiers/pages/TiersTrialSelectionScreen';
import { WelcomeToPlan } from 'tiers/pages/WelcomeToPlan';

import { setRequestLanguageHeader } from 'libs/axios';
import { handleScriptEqualWeb } from 'libs/equalweb';

import { GenericErrorPage } from './ErrorGeneric';
import { TransactionsPage } from './TransactionsPage';
import {
  AddMoneyMethods,
  LoadDebitCardPage,
  LoadDirectDepositPage,
  LoadViaETransfer,
} from './addFunds/pages';
import { useAnalytics } from './analytics/useAnalytics';
import { storeUtmsInSession } from './analytics/utm';
import { PrivateRoute } from './auth/components/PrivateRoute';
import { AUTH_SESSION_TIMEOUT } from './auth/models';
import {
  BROWSER_LOCALE,
  LocaleContext,
  SnackbarNotification,
} from './components';
import { DeviceVerificationLandingPage } from './deviceVerification/DeviceVerificationLanding';
import { EmailChangeConfirmation } from './emailChangeConfirmation/EmailChangeConfirmation';
import { EmailVerificationLanding } from './emailVerification/EmailVerificationLanding';
import { intercomBoot, intercomShutdown } from './intercom/intercom';
import { useBrazeInitialize } from './libs/braze';
import { useLaunchDarkly } from './libs/launchdarkly/hooks/useLaunchDarkly';
import {
  PasswordReset,
  PasswordResetAccountLocked,
  PasswordResetEmailRequest,
  PasswordResetEmailSent,
  PasswordResetLinkExpired,
  PasswordResetOtpLimitReached,
  PasswordResetPassword,
  PasswordResetSmsRequest,
  PasswordResetSuccess,
} from './passwordReset/';
import { PasswordResetErrorPage } from './passwordReset/pages';
import { ReferralLandingPage } from './referrals/ReferralLandingPage';
import { Registration, RegistrationMainFlowPage } from './registration/pages';
import { SendMoneyDetailsPage } from './sendMoney/pages/SendMoneyDetailsPage';
import { theme } from './theme';

type FontDisplay = 'auto' | 'block' | 'swap' | 'fallback' | 'optional';
const SettingsPage = lazy(() =>
  import('./Settings/pages/SettingsPage').then(({ SettingsPage }) => ({
    default: SettingsPage,
  })),
);

const KycEntryPage = lazy(() =>
  import('kyc/pages/KycEntryPage').then(({ KycEntryPage }) => ({
    default: KycEntryPage,
  })),
);
const KycWaitPage = lazy(() =>
  import('kyc/pages/KycWaitPage').then(({ KycWaitPage }) => ({
    default: KycWaitPage,
  })),
);
const KycFailPage = lazy(() =>
  import('kyc/pages/KycFailPage').then(({ KycFailPage }) => ({
    default: KycFailPage,
  })),
);
const KycSuccessPage = lazy(() =>
  import('kyc/pages/KycSuccessPage').then(({ KycSuccessPage }) => ({
    default: KycSuccessPage,
  })),
);
const KycOccupationIntendedUsePage = lazy(() =>
  import('kyc/pages/KycOccupationIntendedUsePage').then(
    ({ KycOccupationIntendedUsePage }) => ({
      default: KycOccupationIntendedUsePage,
    }),
  ),
);
const KycBankConnectedPage = lazy(() =>
  import('kyc/pages/KycBankConnected').then(({ KycBankConnectedPage }) => ({
    default: KycBankConnectedPage,
  })),
);

const CancelPlanPage = lazy(() =>
  import('tiers/pages/CancelPlanScreen').then(({ CancelPlanPage }) => ({
    default: CancelPlanPage,
  })),
);

const MyPlanPage = lazy(() =>
  import('tiers/pages/MyPlanScreen').then(({ MyPlanPage }) => ({
    default: MyPlanPage,
  })),
);

const LoginAndPasswordsPage = lazy(() =>
  import('Settings/pages/LoginAndPasswordsPage').then(
    ({ LoginAndPasswordsPage }) => ({
      default: LoginAndPasswordsPage,
    }),
  ),
);

const MFASetupPage = lazy(() =>
  import('Settings/pages/MFASetupPage').then(({ MFASetupPage }) => ({
    default: MFASetupPage,
  })),
);

const PhoneSetupPage = lazy(() =>
  import('Settings/pages/PhoneSetupPage').then(({ PhoneSetupPage }) => ({
    default: PhoneSetupPage,
  })),
);

const AuthenticatorSetupPage = lazy(() =>
  import('Settings/pages/AuthenticatorSetupPage').then(
    ({ AuthenticatorSetupPage }) => ({
      default: AuthenticatorSetupPage,
    }),
  ),
);

const SetupTotpPage = lazy(() =>
  import('Settings/pages/SetupTotpPage').then(({ SetupTotpPage }) => ({
    default: SetupTotpPage,
  })),
);

const VerifyMFA = lazy(() =>
  import('Settings/pages/VerifyMFAPage').then(({ VerifyMFAPage }) => ({
    default: VerifyMFAPage,
  })),
);

const MFASetupSuccessPage = lazy(() =>
  import('Settings/pages/MFASetupSuccessPage').then(
    ({ MFASetupSuccessPage }) => ({
      default: MFASetupSuccessPage,
    }),
  ),
);

const MFASetupErrorPage = lazy(() =>
  import('Settings/pages/MFASetupErrorPage').then(({ MFASetupErrorPage }) => ({
    default: MFASetupErrorPage,
  })),
);

const PersonalizedOnboardingChecklistPage = lazy(() =>
  import('personalizedOnboarding/pages/OnboardingChecklist').then(
    ({ OnboardingChecklist }) => ({
      default: OnboardingChecklist,
    }),
  ),
);

const CoverCheckoutPage = lazy(() =>
  import('cover/pages/CoverCheckoutPage').then(({ CoverCheckoutPage }) => ({
    default: CoverCheckoutPage,
  })),
);

const CoverDisabledPage = lazy(() =>
  import('cover/pages/CoverDisabledPage').then(({ CoverDisabledPage }) => ({
    default: CoverDisabledPage,
  })),
);

const CoverPage = lazy(() =>
  import('cover/pages/CoverPage').then(({ CoverPage }) => ({
    default: CoverPage,
  })),
);

const CoverReleaseRoute = lazy(() =>
  import('cover/pages/CoverReleaseRoute').then(({ CoverReleaseRoute }) => ({
    default: CoverReleaseRoute,
  })),
);

const CoverSuccessPage = lazy(() =>
  import('cover/pages/CoverSuccessPage').then(({ CoverSuccessPage }) => ({
    default: CoverSuccessPage,
  })),
);

const CreditBuildingPage = lazy(() =>
  import(
    'creditBuilding/pages/CreditBuildingLandingPage/CreditBuildingPageWrapper'
  ).then(({ CreditBuildingPage }) => ({
    default: CreditBuildingPage,
  })),
);

const colors: { [key: string]: string } = theme.colors as {
  [key: string]: string;
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: '"Basis Grotesque Pro", system-ui, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          {
            fontFamily: 'Basis Grotesque Pro',
            fontStyle: 'normal',
            fontDisplay: 'swap' as FontDisplay,
            fontWeight: 400,
            src: `url(${BasisGrotesqueRegular})`,
          },
        ],
      },
    },
  },
});

const App = () => {
  const dispatch = useAppDispatch();
  const { locale, localeChanged } = useContext(LocaleContext);
  const contactModalOpen = useSelector(
    contactSelectors.contactModalOpenSelector,
  );
  const themeName = useSelector(selectTheme);

  // startup scripts
  useEffect(() => {
    storeUtmsInSession();

    // on app's initialization, force delete intercom's cookie
    // if user doesn't have an active session
    if (!localStorage.getItem(AUTH_SESSION_TIMEOUT)) {
      intercomShutdown();
    }

    intercomBoot({ language_override: BROWSER_LOCALE });
  }, [dispatch]);

  useForethought();
  useAnalytics();
  useLaunchDarkly();
  useBrazeInitialize();

  useEffect(() => {
    setRequestLanguageHeader(localeChanged ? locale : BROWSER_LOCALE);
    dispatch(
      appStateActions.languageChanged(localeChanged ? locale : BROWSER_LOCALE),
    );

    if (import.meta.env.VITE_ENABLE_ACCESSIBILITY) {
      handleScriptEqualWeb({
        locale: localeChanged ? locale : BROWSER_LOCALE,
        localeChanged,
      });
    }
  }, [locale, localeChanged, dispatch]);

  const newLoginEnabled = useFlags()[MFAFeatureFlags.EnableNewLogin];
  const localStorage2SV = localStorage.getItem('2SV_ENABLED');

  if (newLoginEnabled && localStorage2SV !== 'true') {
    localStorage.setItem('2SV_ENABLED', 'true');
  }

  const referAndEarnEnabled = useFlags()[referAndEarnFlags.enabled];

  return (
    <div className={themeName}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Suspense fallback={<LoadingOverlay />}>
            <Router>
              <Redirect from="/*" to="/registration" noThrow />
              <Redirect
                from="/direct-deposit/*"
                to="/load/direct-deposit"
                noThrow
              />
              <Redirect from="/kyc/start" to="/kyc/entry" noThrow />
              <Redirect from="/add-funds-info" to="/load/e-transfer" noThrow />
              <Redirect
                from="/direct-deposit-method"
                to="/load/direct-deposit"
                noThrow
              />

              <RegistrationMainFlowPage path="registration/" />
              <RegistrationAccountErrorPage path="registration-account-error"></RegistrationAccountErrorPage>
              <RegistrationAccountFinishPage path="registration-account-finish"></RegistrationAccountFinishPage>
              <Registration path="/" />
              <PrivateRoute
                component={TierSelectionPage}
                path="registration/tiers"
              />
              <PrivateRoute
                component={TiersTrialSelectionPage}
                path="/tiers/trial-selection"
              />
              <PrivateRoute component={TierUpgradePage} path="/tiers/upgrade" />
              <PrivateRoute
                component={TierManagementPage}
                path="/tiers/manage"
              />
              <PrivateRoute component={MyPlanPage} path="tiers/my-plan" />
              <PrivateRoute
                component={WelcomeToPlan}
                path="/registration/tiers/welcome"
              />
              <PrivateRoute component={CancelPlanPage} path="/cancel-plan" />
              <PrivateRoute component={NbaPage} path="/nba-incentives" />

              <LoginPageWrapper path="login" />
              <SmsVerifyPage path="sms-verify" />
              <MFAVerify path="mfa-verify" />

              <SelectPlatformPage path="e-transfer/inbound/:transferId" />
              <PrivateRoute
                component={InboundETransfersPage}
                path="e-transfer/deposit/:transferId"
              />

              <PrivateRoute component={MyCardsPage} path="cards" />
              <PrivateRoute component={CardDetails} path="cards/:id" />
              <PrivateRoute
                component={OrderPhysicalCardPage}
                path="cards/physical"
              />

              <CoverReleaseRoute component={CoverPage} path="cover" />
              <CoverReleaseRoute
                component={CoverDisabledPage}
                path="cover/disabled"
              />
              <CoverReleaseRoute
                component={CoverSuccessPage}
                path="cover/success"
              />
              <CoverReleaseRoute
                component={CoverCheckoutPage}
                path="cover/checkout"
              />

              <PrivateRoute component={SavingsPage} path="savings" />
              <PrivateRoute
                component={SendMoneyDetailsPage}
                path="send-money"
              />
              <PrivateRoute component={TransactionsPage} path="transactions" />

              <PrivateRoute
                component={PersonalizedOnboardingChecklistPage}
                path="/onboarding-checklist"
              />

              {/* New Load methods */}
              <PrivateRoute component={AddMoneyMethods} path="load/" />
              <PrivateRoute
                component={LoadViaETransfer}
                path="load/e-transfer"
              />
              <PrivateRoute
                component={LoadDirectDepositPage}
                path="load/direct-deposit"
              />
              <PrivateRoute
                component={LoadDebitCardPage}
                path="load/debit-card"
              />

              {/* HISA Onboarding */}
              <HisaReleaseRoute
                component={HisaEntryPage}
                path="/hisa/registration"
              />
              <HisaReleaseRoute
                component={HisaAddressPage}
                path="/hisa/address"
              />
              <HisaReleaseRoute
                component={HisaRequirementsPage}
                path="/hisa/requirements"
              />
              <HisaReleaseRoute component={HisaSetupPage} path="/hisa/setup" />
              <HisaReleaseRoute
                component={HisaAssumptionsPage}
                path="/hisa/assumptions"
              />
              <HisaReleaseRoute
                component={HisaAgreementPage}
                path="/hisa/agreement"
              />
              {/* End HISA */}

              <PrivateRoute
                component={CreditBuildingPage}
                path="/credit-building"
              />

              <PrivateRoute
                component={
                  referAndEarnEnabled ? ReferAndEarnPage : ReferralPage
                }
                path="referrals"
              />
              <ReferralLandingPage path="referral" />
              <ReferralLandingPage path="referral/:paramReferralCode" />
              <RequestMoneyIntermediaryPage path="request/:requestCode" />
              <DeviceVerificationLandingPage path="device-verification" />
              <EmailVerificationLanding path="email-verification" />
              <EmailChangeConfirmation path="confirm-email-change" />
              <GenericErrorPage path="error" />
              <PasswordReset path="password-reset">
                <Redirect from="/*" to="sms-otp" noThrow />
                <PasswordResetPassword path="create" />
                <PasswordResetSuccess path="success" />
                <PasswordResetEmailRequest path="email" />
                <PasswordResetEmailSent path="email-sent" />
                <PasswordResetSmsRequest path="sms-otp" />
                <PasswordResetAccountLocked path="account-locked" />
                <PasswordResetLinkExpired path="link-expired" />
                <PasswordResetOtpLimitReached path="otp-limit-reached" />
                <PasswordResetErrorPage path="error" />
              </PasswordReset>
              <PrivateRoute component={KycEntryPage} path="kyc/entry" />
              <PrivateRoute component={KycWaitPage} path="kyc/wait" />
              <PrivateRoute component={KycFailPage} path="kyc/fail" />
              <PrivateRoute component={KycSuccessPage} path="kyc/success" />
              <PrivateRoute
                component={KycOccupationIntendedUsePage}
                path="kyc/occupation-intended-use"
              />

              <KycBankConnectedPage path="kyc/bank-connected" />
              <PrivateRoute
                path="/settings"
                component={SettingsPage}
              ></PrivateRoute>
              <PrivateRoute
                path="/settings/login-and-passwords"
                component={LoginAndPasswordsPage}
              />

              <PrivateRoute path="/settings/mfa" component={MFASetupPage} />
              {/* Route for universal link */}
              <PrivateRoute
                path="/two-step-verification"
                component={MFASetupPage}
              />

              <PrivateRoute
                path="/settings/setup-phone"
                component={PhoneSetupPage}
              />
              <PrivateRoute
                path="/settings/setup-authenticator-app"
                component={AuthenticatorSetupPage}
              />

              <PrivateRoute
                path="/settings/totp-setup"
                component={SetupTotpPage}
              />
              <PrivateRoute
                path="/settings/totp-setup/success"
                component={MFASetupSuccessPage}
              />
              <PrivateRoute
                path="/settings/totp-setup/error"
                component={MFASetupErrorPage}
              />
              <PrivateRoute path="/settings/mfa-verify" component={VerifyMFA} />

              <PrivateRoute
                component={IdentityVerificationLandingPage}
                path="identity-verification"
              />
              <CraRegistration path="cra-registration" />
            </Router>
            <ContactModal
              open={contactModalOpen}
              onClose={() => {
                dispatch(contactActions.closeModal());
              }}
            />
            <SnackbarNotification />
          </Suspense>
        </ThemeProvider>
      </MuiThemeProvider>
    </div>
  );
};

export default App;
