import { ReactNode } from 'react';

import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { ParagraphSmall, TitleSmall } from 'components/TypographyTw';
import { useIcons } from 'hooks/useIcon';
import { twMerge } from 'tailwind-merge';

type StepProps = {
  description: string;
  icon: ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  title: string;
};

export const Step = ({
  description,
  icon,
  isFirst = false,
  isLast = false,
  title,
}: StepProps) => {
  return (
    <div className="flex flex-row">
      <div
        className={twMerge(
          clsx(
            'flex justify-center min-h-full bg-secondary-75 text-primary-500 min-w-[22px] max-w-[22px] w-[22px] mr-4 z-[2]',
            'legacy:bg-secondary-300 legacy:text-white',
            isFirst && 'pt-1 rounded-t-full',
            isLast && 'h-[26px] min-h-[26px] rounded-b-full',
          ),
        )}
      >
        {icon}
      </div>
      <Box className="mb-5">
        <TitleSmall className="text-grey-400 legacy:text-base text-base m-0">
          {title}
        </TitleSmall>
        <ParagraphSmall className="legacy:text-grey-400 text-grey-350 legacy:font-light">
          {description}
        </ParagraphSmall>
      </Box>
    </div>
  );
};

const Unlock = () => {
  const {
    Icons: { Unlock },
  } = useIcons();
  return (
    <Unlock className="legacy:text-white text-primary-500 legacy:w-[18px] light:scale-[0.75]" />
  );
};

const Calendar = () => {
  const {
    Icons: { Calendar },
  } = useIcons();
  return (
    <Calendar className="legacy:text-white text-primary-500 legacy:w-[16px] light:scale-[0.7]" />
  );
};

const Renew = () => {
  const {
    Icons: { Transfer },
  } = useIcons();
  return (
    <Transfer className="legacy:text-white text-primary-500 legacy:w-[16px] light:scale-[0.7]" />
  );
};

export const StepIcons = { Unlock, Calendar, Renew };
