import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useGetGoalsQuery } from 'apis/goals';
import { useGetHISAStatusQuery } from 'apis/hisa';
import { KDSIcons } from 'assets/images/kds_icons';
import {
  selectInterestEarned,
  selectVaultBalance,
} from 'balance/store/selectors';
import {
  Box,
  Button,
  LocaleContext,
  Modal,
  NumberLarge,
  NumberMedium,
  NumberSmall,
  Paragraph,
  SpinnerCircularProgress,
  Template,
  TitleLarge,
  TitleMedium,
  TitleSmall,
} from 'components';
import DownloadApp from 'features/buttons/DownloadApp';
import { Goal } from 'goals/models/Goal';
import { theme } from 'theme';

import { hisaActions, selectHisaOnboarding } from './hisa/store/slice';

const cardStyles = {
  px: 3,
  pt: 1,
  pb: 3,
  borderRadius: 8,
  boxShadow: 'light',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
};

export const SavingsPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const goals = useGetGoalsQuery();

  const { successfullyCreated } = useSelector(selectHisaOnboarding);

  return (
    <Template
      name="Savings Page"
      variant="center"
      widths={['90%', '75%', '75%', '65%']}
    >
      <TitleLarge sx={{ mb: 5 }}>
        {intl.formatMessage({
          id: 'SavingsPage.Savings.Title',
          defaultMessage: 'My savings',
        })}
      </TitleLarge>
      <Grid container spacing={4} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <InterestTile />
        </Grid>
        <Grid item xs={12} md={6}>
          <VaultTile />
        </Grid>
      </Grid>

      <Box sx={{ pt: 4 }}>
        {goals.data && goals.data.length > 0 && (
          <TitleMedium>
            {intl.formatMessage({
              id: 'SavingsPage.Goals.Title',
              defaultMessage: 'My goals',
            })}
          </TitleMedium>
        )}
        <List sx={{ pb: 5 }}>
          {goals.data &&
            goals.data.map((goal, index) => (
              <GoalRow goal={goal} key={index} />
            ))}
          {goals.data && goals.data.length === 0 && (
            <EmptyGoals isError={goals.isError} isLoading={goals.isLoading} />
          )}
          {goals.isLoading && <Skeleton width="100%" height="75px" />}
        </List>
      </Box>
      <Modal
        open={successfullyCreated}
        onClose={() => dispatch(hisaActions.setSuccessfullyCreated(false))}
        PaperProps={{
          style: {
            maxWidth: '422px',
          },
        }}
        data-cy="session-refresh-modal"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <KDSIcons.Spot.Success />
          <TitleLarge sx={{ mt: 0, mb: 0 }}>
            {intl.formatMessage({
              id: 'SavingsPage.SuccessFullOnboarding.Title',
              defaultMessage: 'Your work is done!',
            })}
          </TitleLarge>

          <Paragraph sx={{ my: 0 }}>
            {intl.formatMessage({
              id: 'SavingsPage.SuccessFullOnboarding.Description',
              defaultMessage:
                'We’ll finish setting up your account within the next 5 business days.',
            })}
          </Paragraph>
          <Button
            onClick={() => dispatch(hisaActions.setSuccessfullyCreated(false))}
            trackName="Save onboarding success"
            data-cy="session-refresh-modal-cta"
            type="button"
          >
            {intl.formatMessage({
              id: 'SavingsPage.SuccessFullOnboarding.Close',
              defaultMessage: 'Got it',
            })}
          </Button>
        </Box>
      </Modal>
    </Template>
  );
};

function VaultTile() {
  const intl = useIntl();
  const localeContext = useContext(LocaleContext);

  const vaultBalance = localeContext
    .intlFormatMoney(useSelector(selectVaultBalance))
    .format();

  return (
    <Box sx={{ ...cardStyles, flexDirection: 'column' }}>
      <TitleMedium>
        <span
          style={{
            verticalAlign: 'top',
            height: '28px',
            marginRight: '8px',
          }}
        >
          <KDSIcons.Navigation.Saving />
        </span>
        {intl.formatMessage({
          id: 'SavingsPage.Vault.Title',
          defaultMessage: 'Vault',
        })}
      </TitleMedium>
      <Paragraph sx={{ mt: 0 }}>
        {intl.formatMessage({
          id: 'SavingsPage.Vault.Paragraph',
          defaultMessage:
            'Protect your savings by locking it away in your vault with the KOHO app.',
        })}
      </Paragraph>
      <NumberLarge>{vaultBalance}</NumberLarge>
    </Box>
  );
}

function InterestTile() {
  const intl = useIntl();
  const localeContext = useContext(LocaleContext);

  const HISA = useGetHISAStatusQuery();

  const interestEarned = localeContext
    .intlFormatMoney(useSelector(selectInterestEarned))
    .format();

  if (HISA.isLoading) {
    return (
      <Box sx={{ ...cardStyles, justifyContent: 'center' }}>
        <SpinnerCircularProgress />
      </Box>
    );
  }

  const interestMessageConfig = {
    bold: (chunk: string) => <strong>{chunk}</strong>,
    interestRate: localeContext
      .intlFormatNumber(HISA.data?.interest_rate)
      .format('percent'),
  };

  let interestMessage = intl.formatMessage(
    {
      id: 'SavingsPage.Interest.Paragraph',
      defaultMessage: `You're earning <bold>{interestRate}</bold> interest on your entire balance.`,
    },
    interestMessageConfig,
  );

  if (!HISA.data?.is_agreement_signed) {
    interestMessage = intl.formatMessage(
      {
        id: 'SavingsPage.Interest.PotentialEarnings',
        defaultMessage: `You could be earning <bold>{interestRate}</bold> interest on your entire balance!`,
      },
      interestMessageConfig,
    );
  }

  return (
    <Box sx={{ ...cardStyles, flexDirection: 'column' }}>
      <TitleMedium>
        <span
          style={{
            verticalAlign: 'top',
            height: '28px',
            marginRight: '8px',
          }}
        >
          <KDSIcons.Features.Interest />
        </span>
        {intl.formatMessage({
          id: 'SavingsPage.Interest.Title',
          defaultMessage: 'Interest Earned',
        })}
      </TitleMedium>
      <Paragraph sx={{ mt: 0 }}>{interestMessage}</Paragraph>
      <NumberLarge>{interestEarned}</NumberLarge>
    </Box>
  );
}

const useAvatarStyles = makeStyles({
  colorDefault: {
    backgroundColor: theme.colors.primary100,
  },
});

function GoalRow({ goal }: { goal: Goal }) {
  const intl = useIntl();
  const localeContext = useContext(LocaleContext);

  const avatarClasses = useAvatarStyles();

  const byText = intl.formatMessage({
    id: 'SavingsPage.Goals.Byline',
    defaultMessage: 'By',
  });

  const formattedDate = intl.formatDate(goal.target_complete_date, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <ListItem divider={true} sx={{ px: 0 }}>
      <ListItemAvatar>
        <Avatar variant="rounded" classes={avatarClasses}>
          <KDSIcons.Icons.CalendarTime />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{ fontWeight: 'bold' }}
        primary={<TitleSmall sx={{ my: 1 }}>{goal.name}</TitleSmall>}
        secondary={byText + ' ' + formattedDate}
      ></ListItemText>
      <div style={{ textAlign: 'right' }}>
        <NumberMedium>
          {localeContext.intlFormatMoney(goal.current_balance).format()}
        </NumberMedium>
        <br />
        <NumberSmall sx={{ color: theme.colors.midGrey, fontWeight: 600 }}>
          &nbsp;/&nbsp;
          {localeContext.intlFormatMoney(goal.target_amount).format()}
        </NumberSmall>
      </div>
    </ListItem>
  );
}

function EmptyGoals({
  isError,
  isLoading,
}: {
  isError: boolean;
  isLoading: boolean;
}) {
  const intl = useIntl();

  let message: string | JSX.Element = intl.formatMessage({
    id: 'SavingsPage.Goals.EmptyMessage',
    defaultMessage:
      'Create a goal in-app to start saving for anything you want.',
  });

  if (isError) {
    message = intl.formatMessage({
      id: 'SavingsPage.Goals.EmptyError',
      defaultMessage:
        "Sorry, we're not able to load your goals right now. Please try again later or open the Savings tab in-app.",
    });
  }

  if (isLoading) {
    message = <SpinnerCircularProgress />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        background: '#EEF5F9',
        borderRadius: 4,
        p: 5,
        fontWeight: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <KDSIcons.Spot.Richer sx={{ mb: 3 }} />
      {message}
      <Box sx={{ mt: 3 }}>
        <DownloadApp />
      </Box>
    </Box>
  );
}
