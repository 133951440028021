import { useIntl } from 'react-intl';

import { AllowedCardBrand, PaymentMethod } from 'apis/easyloadApi';
import { KDSIcons } from 'assets/images/kds_icons';
import { ReactComponent as MCard } from 'assets/images/logos/mastercard.svg';
import { ReactComponent as Visa } from 'assets/images/logos/visa.svg';
import {
  Box,
  ButtonUnstyled,
  Paragraph,
  SubtitleSmall,
  TitleMedium,
} from 'components';
import { theme } from 'theme';

interface PaymentMethodCardProps {
  user: string;
  paymentMethod: PaymentMethod;
  checked?: boolean;
  editForm?: boolean;
  viewOnly?: boolean;
  onClick?: (arg?: PaymentMethod) => void;
}

export const PaymentMethodCard = ({
  paymentMethod,
  user,
  viewOnly,
  checked,
  editForm,
  onClick,
}: Readonly<PaymentMethodCardProps>) => {
  const intl = useIntl();

  const { card } = paymentMethod;

  return (
    <CardWrapper onClick={editForm ? undefined : onClick}>
      <Box variant="shadow" sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <CardLogo brand={card.brand} />
          </Box>
          <Box sx={{ flex: 1, ml: 2 }}>
            <TitleMedium sx={{ mt: 0, mb: 1 }}>
              **** **** **** {card.last4}
            </TitleMedium>
            <Paragraph sx={{ mt: 0, mb: 1 }}>{user}</Paragraph>
            <SubtitleSmall>
              {intl.formatMessage(
                {
                  id: 'PaymentMethodCard.Expires',
                  defaultMessage: 'Expires {month}/{year}',
                },
                { month: card.exp_month, year: card.exp_year },
              )}
            </SubtitleSmall>
          </Box>
          {viewOnly || (
            <Box sx={{ flex: 0 }}>
              {editForm && onClick ? (
                <ButtonUnstyled
                  onClick={() => onClick(paymentMethod)}
                  sx={{
                    p: 2,
                    mt: '-8px',
                    mr: '-8px',
                    '&:active': { backgroundColor: theme.colors.greyXLight },
                  }}
                >
                  <KDSIcons.Icons.Trash />
                </ButtonUnstyled>
              ) : (
                <Checkmark checked={checked || false} />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </CardWrapper>
  );
};

function CardWrapper({
  children,
  onClick,
}: Readonly<{
  onClick?: () => void;
  children: React.ReactNode;
}>) {
  if (onClick) {
    return (
      <ButtonUnstyled
        onClick={() => onClick()}
        sx={{
          mb: 3,
          width: '100%',
          '&:active': {
            backgroundColor: theme.colors.greyXLight,
            borderColor: theme.colors.greyDark,
          },
          '&:focus:hover': {
            borderColor: theme.colors.greyLight,
            backgroundColor: theme.colors.greyXLight,
          },
        }}
      >
        {children}
      </ButtonUnstyled>
    );
  } else {
    return (
      // styles here are to match the button styling so the transition between them is seamless
      <Box
        sx={{
          mb: 3,
          width: '100%',
          boxSizing: 'border-box',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderRadius: '5px',
          borderColor: 'transparent',
        }}
      >
        {children}
      </Box>
    );
  }
}

function CardLogo({ brand }: { brand: AllowedCardBrand }) {
  switch (brand) {
    case 'visa':
      return <Visa />;
    case 'mastercard':
      return <MCard />;
    default:
      return <KDSIcons.Navigation.Spending />;
  }
}

function Checkmark({ checked }: { checked: boolean }) {
  return checked ? (
    <KDSIcons.Icons.CheckmarkFilled color={theme.colors.primary} />
  ) : (
    <Box
      sx={{
        borderRadius: '100%',
        border: '1px solid',
        borderColor: checked ? theme.colors.primary : theme.colors.midGrey,
        width: '24px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  );
}
