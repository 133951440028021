/** @jsxImportSource theme-ui */
import { Box } from '@material-ui/core';
import { Logo } from 'components/Logo';

import { LanguageToggle } from '.';
import { IntercomChatButton } from '../intercom/IntercomChatButton';

const navItemStyle = {
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  ':first-of-type > span': {
    mr: 'auto',
  },
  ':last-of-type > span': {
    ml: 'auto',
  },
};

export const NavbarBasic = ({
  className,
  chat = true,
  languageToggle = true,
  ...props
}: {
  className?: string;
  chat: boolean | undefined;
  languageToggle: boolean | undefined;
}) => {
  return (
    <Box
      component="nav"
      {...props}
      className={className}
      sx={{
        overflow: 'auto',
        py: [3, 3, 4, 4],
        px: [5, '5%', '2%', '2%'],
        display: 'flex',
      }}
    >
      <Box sx={navItemStyle}>
        <span>
          <Logo />
        </span>
      </Box>

      {languageToggle ? (
        <Box sx={navItemStyle}>
          <span>
            <LanguageToggle />
          </span>
        </Box>
      ) : null}
      {chat ? (
        <Box sx={navItemStyle}>
          <span>
            <IntercomChatButton
              sx={{ position: 'relative', top: 0, right: 0 }}
            />
          </span>
        </Box>
      ) : null}
    </Box>
  );
};

export default NavbarBasic;
