import { useDispatch, useSelector } from 'react-redux';

import InvertColorsIcon from '@material-ui/icons/InvertColors';
import { appStateActions, selectTheme } from 'appState/appState.slice';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ThemeSwitch = () => {
  const isThemeEnabled = useFlags()['web.enable-theme-switch'];

  const dispatch = useDispatch();
  const currentTheme = useSelector(selectTheme);

  if (!isThemeEnabled) {
    return null;
  }

  const handleClick = () => {
    const themeName =
      currentTheme && currentTheme === 'legacy' ? 'light' : 'legacy';
    dispatch(appStateActions.setTheme(themeName));
  };

  return (
    <button
      onClick={handleClick}
      className="absolute left-1/2 -translate-x-1/2"
    >
      <InvertColorsIcon />
      <span className="text-primary-300 font-bold underline">
        {currentTheme}
      </span>
    </button>
  );
};

export default ThemeSwitch;
