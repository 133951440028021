import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Close from '@material-ui/icons/Close';
import { KDSIcons } from 'assets/images/kds_icons';

import { selectAccountPersonalAccountGroupId } from '../accounts/store/selectors';
import { ReactComponent as TadaIcon } from '../assets/images/transactions/tada.svg';
import { ButtonUnstyled } from '../components';
import LoadingCentered from '../components/Loading/Centered';
import { Money } from '../models/Money';
import {
  selectCashOutLoading,
  selectCashOutSuccess,
  selectRewardsAllTimeBalance,
  selectRewardsBalance,
  selectRewardsLoading,
} from '../rewards/store/selectors';
import { rewardsActions } from '../rewards/store/slice';
import { transactionsActions } from '../transactions/store/slice';
import { BoxTw } from './BoxTw';
import { ButtonTw } from './ButtonTw';
import { LocaleContext } from './I18NContextProvider';
import { NumberExtraLarge, Paragraph, TitleLarge } from './TypographyTw';

export const CashOut = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const context = useContext(LocaleContext);

  const isLoading = useSelector(selectRewardsLoading);
  const balanceCurrent = useSelector(selectRewardsBalance);
  const balanceAllTimeTotal = useSelector(selectRewardsAllTimeBalance);

  const [isModal, setModal] = useState(false);
  const balance = new Money(balanceCurrent || 0);
  const personalAccountIdentifier = useSelector(
    selectAccountPersonalAccountGroupId,
  );

  useEffect(() => {
    if (personalAccountIdentifier) {
      dispatch(rewardsActions.getRewardsRequest());
      dispatch(rewardsActions.getRewardsConfigurationRequest());
    }
  }, [dispatch, context.apiLanguageHeader, personalAccountIdentifier]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = (isCashOut: boolean) => {
    setModal(false);
    dispatch(rewardsActions.confirmCashOutReset());
    if (isCashOut) {
      dispatch(rewardsActions.getRewardsRequest());
      dispatch(rewardsActions.getRewardsConfigurationRequest());
      dispatch(transactionsActions.getTransactionsRequest());
    }
  };

  if (isLoading) return <LoadingCentered />;

  return (
    <>
      <BoxTw variant="shadow" className="light:bg-white">
        <div className="flex flex-col gap-4 px-6 py-4">
          <div className="w-full flex flex-row items-center justify-between">
            <Paragraph className="legacy:font-bold font-medium my-1">
              {intl.formatMessage({
                id: 'CashOut.Earned',
                defaultMessage: 'Earned',
              })}
            </Paragraph>
            <NumberExtraLarge>
              {balanceCurrent &&
                context.intlFormatMoney(balanceCurrent).format()}
            </NumberExtraLarge>
          </div>
          <ButtonTw
            disabled={balance.lteq(0)}
            onClick={openModal}
            data-cy="btn-cash-out"
          >
            {intl.formatMessage({
              id: 'CashOut.CashOutRewards',
              defaultMessage: 'Cash out',
            })}
          </ButtonTw>
          <div className="legacy:bg-grey-200 bg-grey-75 w-full h-[1px]" />
          <div className="flex flex-row items-center w-full justify-between">
            <Paragraph className="font-bold mb-0">
              {intl.formatMessage({
                id: 'CashOut.AllTimeTotal',
                defaultMessage: 'All-time total',
              })}
            </Paragraph>
            <Paragraph className="font-bold legacy:font-legacyNumbers font-number mb-0">
              {balanceAllTimeTotal &&
                context.intlFormatMoney(balanceAllTimeTotal).format()}
            </Paragraph>
          </div>
        </div>
      </BoxTw>

      <CashOutModal open={isModal} handleClose={() => closeModal(true)} />
    </>
  );
};

const CashOutModal = ({
  handleClose,
  open,
}: {
  handleClose: any;
  open: boolean;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isFinal, setIsFinal] = useState(false);
  const isLoading = useSelector(selectCashOutLoading);
  const isSuccess = useSelector(selectCashOutSuccess);
  const balanceCurrent = useSelector(selectRewardsBalance);
  const context = useContext(LocaleContext);

  const balance = new Money(balanceCurrent || 0);
  const intlFormatBalanceCurrent =
    balanceCurrent && context.intlFormatMoney(balanceCurrent).format();

  const onHandleClose = (isCashOut: boolean): void => {
    setIsFinal(false);
    handleClose(isCashOut);
  };

  const onHandleSubmit = (): void => {
    dispatch(rewardsActions.confirmCashOutRequest());
    setIsFinal(true);
  };

  const cashOutTransactionDisplay = () => {
    if (isSuccess) {
      return {
        title: (
          <div className="flex flex-col w-[400px]">
            <TadaIcon />
            <div>
              {intl.formatMessage({
                id: 'CashOut.Success',
                defaultMessage: 'Success!',
              })}
            </div>
          </div>
        ),
        content: intl.formatMessage(
          {
            id: 'CashOut.PromotionAdded',
            defaultMessage: "We've added {balance} to your Spendable.",
          },
          {
            balance: intlFormatBalanceCurrent,
          },
        ),
        buttons: (
          <div className="flex flex-col w-full">
            <ButtonTw
              disabled={isLoading || balance.lteq(0)}
              onClick={() => onHandleClose(true)}
            >
              {intl.formatMessage({
                id: 'CashOut.Okay',
                defaultMessage: 'Okay, got it',
              })}
            </ButtonTw>
          </div>
        ),
      };
    }
    return {
      title: (
        <div className="flex flex-col w-[400px]">
          <KDSIcons.Spot.Wait />
          <div>
            {intl.formatMessage({
              id: 'CashOut.Oops',
              defaultMessage: 'Oops!',
            })}
          </div>
        </div>
      ),
      content: intl.formatMessage({
        id: 'CashOut.OopsErrorMessage',
        defaultMessage: 'Oops! We could not cash out your cash back.',
      }),
      buttons: (
        <div className="flex flex-col w-full">
          <ButtonTw
            disabled={isLoading || balance.lteq(0)}
            onClick={() => onHandleClose(true)}
          >
            {intl.formatMessage({
              id: 'CashOut.TryAgain',
              defaultMessage: 'Try again',
            })}
          </ButtonTw>
        </div>
      ),
    };
  };

  const map = new Map([
    [
      true,
      {
        ...cashOutTransactionDisplay(),
      },
    ],
    [
      false,
      {
        title: intl.formatMessage({
          id: 'CashOut.CashOut',
          defaultMessage: 'Cash out your cash back?',
        }),
        content: intl.formatMessage(
          {
            id: 'CashOut.AddedSpenableBalance',
            defaultMessage:
              'We’ll add the {balance} to your Spendable balance.',
          },
          {
            balance: intlFormatBalanceCurrent,
          },
        ),
        buttons: (
          <div className="flex gap-4 w-[400px]">
            <div className="w-1/2 flex flex-col">
              <ButtonTw
                disabled={isLoading}
                variant="secondary"
                onClick={() => onHandleClose(false)}
              >
                {intl.formatMessage({
                  id: 'CashOut.Nevermind',
                  defaultMessage: 'Nevermind',
                })}
              </ButtonTw>
            </div>
            <div className="w-1/2 flex flex-col">
              <ButtonTw
                disabled={isLoading || balance.lteq(0)}
                onClick={onHandleSubmit}
              >
                {intl.formatMessage({
                  id: 'CashOut.Yes',
                  defaultMessage: 'Yes',
                })}
              </ButtonTw>
            </div>
          </div>
        ),
      },
    ],
  ]);

  return (
    <Dialog onClose={handleClose} aria-labelledby="koho-cashout" open={open}>
      {isLoading ? (
        <LoadingCentered />
      ) : (
        <>
          <DialogTitle disableTypography id="koho-cashout" className="p-4 pb-0">
            <div className="flex justify-end">
              <ButtonUnstyled
                onClick={handleClose}
                aria-label={intl.formatMessage({
                  id: 'CashOut.CloseButton.AriaLabel',
                  defaultMessage: 'Close modal',
                })}
              >
                <Close className="text-grey-400" />
              </ButtonUnstyled>
            </div>
            <TitleLarge className="p-0 m-0">
              {map.get(isFinal)?.title}
            </TitleLarge>
          </DialogTitle>
          <DialogContent className="py-2 px-4">
            <DialogContentText className="text-grey-400 mb-0" id="koho-cashout">
              {map.get(isFinal)?.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="pt-2 pb-6 px-4">
            {map.get(isFinal)?.buttons}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
