import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { MoneyRequest } from 'apis/requestMoneyApi';
import { KDSIcons } from 'assets/images/kds_icons';
import {
  Box,
  ButtonLinkExternal,
  LocaleContext,
  Paragraph,
  ParagraphSmall,
  TitleLarge,
} from 'components';
import { theme } from 'theme';

import { RequestMoneyEmojiBackground } from './RequestMoneyEmojiBackground';
import { RequestMoneyInitialsAvatar } from './RequestMoneyInitialsAvatar';

export const RequestMoneyInfoMobile = ({
  request,
}: {
  request: MoneyRequest;
}) => {
  const intl = useIntl();
  const context = useContext(LocaleContext);

  return (
    <Box>
      <TitleLarge>
        {request.amount
          ? intl.formatMessage(
              {
                id: 'RequestMoneyInfoMobile.Title',
                defaultMessage: 'Send {requesterName} {amount}',
              },
              {
                requesterName: request.requester_given_name,
                amount: context.intlFormatMoney(request.amount).format(),
              },
            )
          : intl.formatMessage(
              {
                id: 'RequestMoneyInfoMobile.TitleNoAmount',
                defaultMessage: 'Send {requesterName} money',
              },
              {
                requesterName: request.requester_given_name,
              },
            )}
      </TitleLarge>
      <Box
        sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}
      >
        <RequestMoneyEmojiBackground
          emoji={request.emoji}
          sx={{ width: '100%', height: '115px' }}
        />
        <RequestMoneyInitialsAvatar
          initials={request.requester_initials}
          position="absolute"
          sx={{
            zIndex: 11,
            top: request.message ? '17px' : '34px',
          }}
        />
        {request.message && (
          <Paragraph
            sx={{
              borderRadius: '4px',
              backgroundColor: theme.colors.greyXLight,
              textAlign: 'center',
              p: 2,
              mb: 4,
              mt: 0,
              position: 'absolute',
              zIndex: 10,
              top: '55px',
            }}
          >
            {intl.formatMessage(
              {
                id: 'RequestMoneyInfoMobile.Message',
                defaultMessage: '“{message}”',
              },
              {
                message: request.message,
              },
            )}
          </Paragraph>
        )}
      </Box>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 3,
          }}
        >
          <KDSIcons.Icons.QuestionBold sx={{ mr: 2 }} />
          <ParagraphSmall>
            {intl.formatMessage(
              {
                id: 'RequestMoneyExpiry.ExpiresAt',
                defaultMessage: 'This request will expire on {date}',
              },
              {
                date: intl.formatDate(request.expires_at, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  timeZone: 'utc',
                }),
              },
            )}
          </ParagraphSmall>
        </Box>
        <ButtonLinkExternal
          href={request.payment_deep_link}
          variant="coral"
          sx={{ my: 3 }}
          trackName="request-money-pay-with-koho"
        >
          {intl.formatMessage({
            id: 'RequestMoneyInfoMobile.OpenApp',
            defaultMessage: 'Pay with KOHO',
          })}
        </ButtonLinkExternal>
      </Box>
    </Box>
  );
};
