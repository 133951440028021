/** @jsxImportSource theme-ui */
import { theme } from '../theme';

export const Divider = ({ margin = 5, ...props }) => {
  return (
    <div
      sx={{
        borderTop: '1px',
        borderTopStyle: 'solid',
        borderTopColor: theme.colors.tertiaryT100,
        my: margin,
      }}
      {...props}
    />
  );
};
