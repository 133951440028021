import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectOnfidoState = (state: RootState) => state.onfido;

export const selectOnfidoLoadable = createSelector(
  selectOnfidoState,
  (state) => state?.loadable,
);

export const selectOnfidoIsLoading = createSelector(
  selectOnfidoLoadable,
  (loadable) => loadable?.loading,
);

export const selectOnfidoData = createSelector(
  selectOnfidoState,
  (state) => state.data,
);

export const selectOnfidoApplicantReportTypes = createSelector(
  selectOnfidoData,
  (state) => state?.report_types || [],
);

export const selectOnfidoSourceStart = createSelector(
  selectOnfidoState,
  (state) => state.sourceStart,
);

export const selectOnfidoCompletedSuccessfully = createSelector(
  selectOnfidoState,
  (state) => state.completedSuccessfully,
);
