/** @jsxImportSource theme-ui */
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { KDSIcons } from 'assets/images/kds_icons';
import { ProgressBar } from 'components';
import { theme } from 'theme';

import fistbump from '../assets/images/referrals/fistbump-no-background.png';
import { usePagePermissions } from '../auth/hooks/usePagePermissions';
import {
  Paragraph,
  ParagraphSmall,
  Template,
  TitleMedium,
  TitleSmall,
  ValueCopier,
} from '../components';
import { selectRefereeData, selectReferralCode } from './store/selectors';
import { referralActions } from './store/slice';

export const ReferralPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pagePermissions = usePagePermissions();
  const referralCode = useSelector(selectReferralCode);
  const referees = useSelector(selectRefereeData);

  useEffect(() => {
    if (!referralCode) {
      dispatch(referralActions.postReferralCodeRequest());
    }
    dispatch(referralActions.getReferralRefereesRequest());
  }, [dispatch, referralCode]);

  const referAndEarn = (
    <div
      sx={{
        background: 'linear-gradient(180deg, #CCDFEE 66%, #B8131A 34%)',
        borderRadius: '8px',
        width: '100%',
        margin: 'auto',
      }}
    >
      <img
        sx={{ width: '100%', pb: [3, 3] }}
        src={fistbump}
        alt={`${intl.formatMessage({
          id: 'ReferralLandingPage.FistBumpImageAltTag',
          defaultMessage: 'Fist bump',
        })}`}
      />
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'center',
          padding: '0px 24px',
        }}
      >
        <TitleMedium sx={{ color: 'background', mb: 0 }}>
          {intl.formatMessage({
            id: 'ReferralEarnPage.Title',
            defaultMessage: 'Refer & Earn',
          })}
        </TitleMedium>
        <Paragraph sx={{ color: 'background', mt: 0 }}>
          {intl.formatMessage({
            id: 'ReferralEarnPage.Subheading',
            defaultMessage: 'Get rewarded when you refer a friend',
          })}
        </Paragraph>
      </div>
    </div>
  );

  const referralLink = (
    <section>
      <Paragraph sx={{ fontWeight: '700', mb: 0, mt: 40 }}>
        {intl.formatMessage({
          id: 'ReferralEarnPage.PersonalizedLink',
          defaultMessage: 'Share your personalized link',
        })}
      </Paragraph>
      <ValueCopier
        sx={{ fontWeight: 'bold', color: 'primary' }}
        trackName={'Referral Earn Page Code Copied'}
        value={
          referralCode ? `https://web.koho.ca/referral/${referralCode}` : ''
        }
      />
    </section>
  );

  const howItWorksList = [
    intl.formatMessage({
      id: 'ReferralEarnPage.HowItWorks.Invite',
      defaultMessage: 'Invite your friends',
    }),
    intl.formatMessage({
      id: 'ReferralEarnPage.HowItWorks.Signup',
      defaultMessage: 'They sign-up + make a purchase',
    }),
    intl.formatMessage({
      id: 'ReferralEarnPage.HowItWorks.Reward',
      defaultMessage: 'You both get an amazing reward!',
    }),
  ];

  const howItWorks = (
    <section>
      <Paragraph sx={{ mb: 0, mt: 24, fontWeight: 'bold' }}>
        {intl.formatMessage({
          id: 'ReferralEarnPage.HowItWorks',
          defaultMessage: 'How it works',
        })}
      </Paragraph>
      <List sx={{ pt: 0 }}>
        {howItWorksList.map((item) => {
          return (
            <ListItem sx={{ pl: 0 }}>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <KDSIcons.Icons.CheckmarkBold
                  sx={{ height: '24px', width: '24px' }}
                />
              </ListItemIcon>
              <ListItemText>{item}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      <ParagraphSmall>
        {intl.formatMessage({
          id: 'ReferralEarnPage.Deadline',
          defaultMessage:
            'The purchase must be made within 30 days of using the referral code.',
        })}
      </ParagraphSmall>
    </section>
  );

  const referralAmount =
    referees.filter(
      (ref) =>
        !ref.is_expired &&
        ref.progress_step === ref.progress_step_total &&
        ref.status_message.includes('20') &&
        ref.status_message.includes('$'),
    )?.length * 20;

  const referrersList = (
    <section
      sx={{
        pt: [5, 5, 0],
        width: ['100%', '100%', '300px'],
      }}
    >
      <TitleSmall sx={{ fontSize: '16px', mb: [0, 0, 2], mt: 0 }}>
        {intl.formatMessage(
          {
            id: 'ReferralEarnPage.Referrers.Header',
            defaultMessage: `You've earned <color>$\{referralAmount}</color> from referring`,
          },
          {
            color: (message) => <span sx={{ color: 'orange' }}>{message}</span>,
            referralAmount: referralAmount,
          },
        )}
      </TitleSmall>
      {referees.map((ref) => {
        return (
          <>
            <Paragraph sx={{ m: 0, pt: 3, pb: 1, fontWeight: 'bold' }}>
              {ref.name}
            </Paragraph>
            <ProgressBar
              currentStep={ref.is_expired ? 0 : ref.progress_step}
              totalSteps={ref.progress_step_total}
              showSteps={false}
            />
            <Paragraph sx={{ m: 0, pb: 3 }}>{ref.status_message}</Paragraph>
            <div
              sx={{
                borderBottom: `1px solid ${theme.colors.greyLight}`,
              }}
            ></div>
          </>
        );
      })}
    </section>
  );

  return (
    <Template name={'Referral Earn Page'}>
      {pagePermissions.canSeeReferralPage && (
        <Grid container justifyContent="space-around" alignItems="flex-start">
          <Grid
            sx={{
              px: [4, 4, 4, 0],
              py: 0,
              width: ['100%', 500, 500, 500],
            }}
          >
            {referAndEarn}
            {referralLink}
            {howItWorks}
          </Grid>
          {referees.length > 0 && (
            <Grid
              sx={{
                width: ['88%', '60%', '300px'],
              }}
            >
              {referrersList}
            </Grid>
          )}
        </Grid>
      )}
    </Template>
  );
};
