export enum InvalidReferralReasons {
  // a code belongs to a campaign that is no longer granting a reward
  DISABLED = 'disabled',
  // a code has been used a number of times equal to its campaign's max
  EXHAUSTED = 'exhausted',
}

export enum Sources {
  Partner = 'partner',
  User = 'referral',
}

export interface ReferralsValidationData {
  type: Sources;
  invalid_reason?: InvalidReferralReasons;
}
