import { rootApi } from './rootApi';

export interface AutodepositAlias {
  alias_ref_id: string;
  created_at: string;
  email: string;
  status: 'ACTIVE' | 'NOT_ACTIVE' | 'PENDING';
}

export const autodepositApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getAutodepositAliases: build.query<AutodepositAlias[], void>({
      query: () => ({
        url: '1.0/autodeposit/aliases',
        method: 'GET',
      }),
      providesTags: ['Aliases'],
    }),
    registerAlias: build.mutation<AutodepositAlias[], string>({
      query: (email) => ({
        url: '1.0/autodeposit/aliases',
        method: 'POST',
        body: { email },
      }),
      invalidatesTags: ['Aliases'],
    }),
    unregisterAlias: build.mutation<AutodepositAlias, string>({
      query: (aliasRefId) => ({
        url: `1.0/autodeposit/aliases/${aliasRefId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Aliases'],
    }),
  }),
});

export const {
  useGetAutodepositAliasesQuery,
  useRegisterAliasMutation,
  useUnregisterAliasMutation,
} = autodepositApi;
