import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { RouteComponentProps } from '@reach/router';
import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import axios, { AxiosError } from 'axios';
import CircularProgressTw from 'components/CircularProgressTw';
import { Paragraph, TitleMedium } from 'components/TypographyTw';

import { track } from '../analytics/analytics';
import phonesImage from '../assets/images/general/phone@2x.png';
import {
  Card,
  FooterImageWrapper,
  LayoutBasic,
} from '../components/LayoutBasic';

export const EmailChangeConfirmation = (props: RouteComponentProps) => {
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';
  return (
    <LayoutBasic pageName="Email change confirmation" className="bg-white">
      <Card className="mb-16">
        <Main />
      </Card>
      {!isLight && (
        <FooterImageWrapper className="before:bg-secondary-200">
          <img
            src={phonesImage}
            alt=""
            className="w-[500px] max-w-full relative z-[2]"
          />
        </FooterImageWrapper>
      )}
    </LayoutBasic>
  );
};

export const Main = () => {
  const intl = useIntl();
  const [screen, setScreen] = useState<
    'pending' | 'success' | 'failed' | 'expired'
  >('pending');
  const [token, setToken] = useState('');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);

      // support legacy ? urls and the preferred #
      const paramsDelimeter = window.location.href.includes('?') ? '?' : '#';
      const params = new URLSearchParams(
        window.location.href.split(paramsDelimeter).pop(),
      );
      const token = params.get('token');

      setToken(token || '');

      axios
        .post(`${import.meta.env.VITE_GATEWAY_API}user/confirm-email-change`, {
          token,
        })
        .then(() => {
          setScreen('success');
          track({ event: 'Email change confirmation success' });
        })
        .catch((error: AxiosError) => {
          if (error.code === '403') {
            setScreen('expired');
          } else {
            setScreen('failed');
          }
          track({ event: 'Email change confirmation failed' });
        });
    }
  }, [token, loaded]);

  switch (screen) {
    case 'pending':
      return <CircularProgressTw />;

    case 'success':
      return (
        <>
          <KDSIcons.Spot.Success className="mb-4" />
          <TitleMedium>
            {intl.formatMessage({
              id: 'EmailChangeConfirmation.SuccessTitle',
              defaultMessage: 'Thanks for confirming your email',
            })}
          </TitleMedium>
          <Paragraph>
            {intl.formatMessage({
              id: 'EmailChangeConfirmation.SuccessContent',
              defaultMessage: 'Your email has been updated.',
            })}
          </Paragraph>
        </>
      );

    case 'expired':
      return (
        <>
          <KDSIcons.Spot.Stop className="mb-4" />
          <TitleMedium>
            {intl.formatMessage({
              id: 'EmailChangeConfirmation.ExpiredTitle',
              defaultMessage: 'Sorry, the verification expired.',
            })}
          </TitleMedium>
          <Paragraph>
            {intl.formatMessage({
              id: 'EmailChangeConfirmation.ExpiredContent',
              defaultMessage: `You'll have to get another one.`,
            })}
          </Paragraph>
        </>
      );
    case 'failed':
      return (
        <>
          <KDSIcons.Spot.Stop className="mb-4" />
          <TitleMedium>
            {intl.formatMessage({
              id: 'EmailChangeConfirmation.FailedTitle',
              defaultMessage: 'Sorry, something went wrong.',
            })}
          </TitleMedium>
          <Paragraph>
            {intl.formatMessage({
              id: 'EmailChangeConfirmation.FailedContent',
              defaultMessage: 'Please try again later.',
            })}
          </Paragraph>
        </>
      );
    default:
      return null;
  }
};
