/** @jsxImportSource theme-ui */
import React from 'react';

import { TextField, TextFieldProps } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { trackInput } from 'analytics/analytics';

import { theme } from '../../theme';

export const EnterKey = 'Enter';

export type KhInputTextProps = TextFieldProps & {
  trackName?: string;
  onEnterPressed?: () => void;
};

const CustomStyleTextField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      background: theme.colors.background,
      fontWeight: 700,
    },
    '& .Mui-error': {
      color: theme.colors.danger,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      '& fieldset': {
        borderColor: theme.colors.danger,
      },
    },
    '& .MuiFormHelperText-root': {
      color: theme.colors.danger,
      marginLeft: 0,
    },
  },
})(TextField);

export const KhInputText = React.forwardRef<any, KhInputTextProps>(
  ({ trackName, onFocus, onEnterPressed, ...props }, ref) => {
    const focus = (event: React.FocusEvent<HTMLInputElement>) => {
      if (trackName) {
        trackInput({ name: trackName, type: 'Focused' });
      }

      if (onFocus) {
        onFocus(event);
      }
    };

    const keyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (onEnterPressed && event.key === EnterKey) {
        onEnterPressed();
      }
    };

    return (
      <CustomStyleTextField
        variant={'outlined'}
        type={'text'}
        fullWidth={true}
        ref={ref}
        onFocus={focus}
        onKeyDown={keyDown}
        {...props}
      />
    );
  },
);
