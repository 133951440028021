import { MessageDescriptor, defineMessage } from 'react-intl';

interface IndustryData {
  title: MessageDescriptor;
  code: string;
  value: string;
}

// prettier-ignore
export const industries: IndustryData[] = [
  { code: '11', title: defineMessage({ id: 'Industry.11', defaultMessage: 'Agriculture, Forestry, Fishing and Hunting' }), value: 'Agriculture, Forestry, Fishing and Hunting' },
  { code: '21', title: defineMessage({ id: 'Industry.21', defaultMessage: 'Mining' }), value: 'Mining' },
  { code: '22', title: defineMessage({ id: 'Industry.22', defaultMessage: 'Utilities' }), value: 'Utilities' },
  { code: '23', title: defineMessage({ id: 'Industry.23', defaultMessage: 'Construction' }), value: 'Construction' },
  { code: '31 - 33', title: defineMessage({ id: 'Industry.31', defaultMessage: 'Manufacturing' }), value: 'Manufacturing' },
  { code: '42', title: defineMessage({ id: 'Industry.42', defaultMessage: 'Wholesale Trade' }), value: 'Wholesale Trade' },
  { code: '44 - 45', title: defineMessage({ id: 'Industry.44', defaultMessage: 'Retail Trade' }), value: 'Retail Trade' },
  { code: '48 - 49', title: defineMessage({ id: 'Industry.48', defaultMessage: 'Transportation and Warehousing' }), value: 'Transportation and Warehousing' },
  { code: '51', title: defineMessage({ id: 'Industry.51', defaultMessage: 'Information' }), value: 'Information' },
  { code: '52', title: defineMessage({ id: 'Industry.52', defaultMessage: 'Finance and Insurance' }), value: 'Finance and Insurance' },
  { code: '53', title: defineMessage({ id: 'Industry.53', defaultMessage: 'Real Estate Rental and Leasing' }), value: 'Real Estate Rental and Leasing' },
  { code: '54', title: defineMessage({ id: 'Industry.54', defaultMessage: 'Professional, Scientific, and Technical Services' }), value: 'Professional, Scientific, and Technical Services' },
  { code: '55', title: defineMessage({ id: 'Industry.55', defaultMessage: 'Management of Companies and Enterprises' }), value: 'Management of Companies and Enterprises' },
  { code: '56', title: defineMessage({ id: 'Industry.56', defaultMessage: 'Admin, Support, Waste Management, and Remediation Services' }), value: 'Admin, Support, Waste Management, and Remediation Services' },
  { code: '61', title: defineMessage({ id: 'Industry.61', defaultMessage: 'Educational Services' }), value: 'Educational Services' },
  { code: '62', title: defineMessage({ id: 'Industry.62', defaultMessage: 'Health Care and Social Assistance' }), value: 'Health Care and Social Assistance' },
  { code: '71', title: defineMessage({ id: 'Industry.71', defaultMessage: 'Arts, Entertainment, and Recreation' }), value: 'Arts, Entertainment, and Recreation' },
  { code: '72', title: defineMessage({ id: 'Industry.72', defaultMessage: 'Accommodation and Food Services' }), value: 'Accommodation and Food Services' },
  { code: '81', title: defineMessage({ id: 'Industry.81', defaultMessage: 'Other Services (except Public Administration)' }), value: 'Other Services (except Public Administration)' },
  { code: '92', title: defineMessage({ id: 'Industry.92', defaultMessage: 'Public Administration' }), value: 'Public Administration' },
];
