import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { navigate } from '@reach/router';
import * as Sentry from '@sentry/browser';
import {
  IBenefitGroup,
  IPlan,
  useGetCurrentTierQuery,
  useManageSubscriptionMutation,
} from 'apis/tiers';
import { DowngradeSuccessProps } from 'tiers/components/downgradeConfirmationModal/DowngradeSuccess';
import { selectIsPaidTier } from 'tiers/store/selectors';

type Props = {
  downgradeData?: DowngradeSuccessProps;
  onConfirm: () => void;
  selectedTier: IBenefitGroup;
  selectedPlan: IPlan;
};

export const useChangeTier = ({
  downgradeData,
  onConfirm,
  selectedTier,
  selectedPlan,
}: Props) => {
  const {
    data: activeTier,
    isSuccess: activeTierSuccess,
    isError: activeTierError,
  } = useGetCurrentTierQuery();

  const isDowngrade = downgradeData !== undefined;
  const isPaidTier = useSelector(selectIsPaidTier);

  const [
    manageSubscription,
    {
      isLoading: isSubscriptionLoading,
      isSuccess: subscribedSuccessfully,
      isError: subscriptionFailed,
      error: subscriptionError,
    },
  ] = useManageSubscriptionMutation();

  useEffect(() => {
    if (subscribedSuccessfully) {
      onConfirm();

      if (isDowngrade) {
        navigate('/transactions', {
          state: { downgradePlanSuccess: downgradeData },
        });
      } else if (isPaidTier) {
        navigate('/tiers/my-plan', { state: { showUpgradePlanSuccess: true } });
      } else {
        navigate('/transactions', {
          state: { showUpgradePlanSuccess: true },
        });
      }
    }
  }, [
    subscribedSuccessfully,
    onConfirm,
    isDowngrade,
    downgradeData,
    isPaidTier,
  ]);

  useEffect(() => {
    if (subscriptionFailed && subscriptionError) {
      Sentry.captureException(
        new Error('Error changing tier', {
          cause: { error: subscriptionError },
        }),
      );
    }
  }, [subscriptionFailed, subscriptionError]);

  const handleSubscription = useCallback(() => {
    activeTier &&
      manageSubscription({
        benefit_group_id: selectedTier.id,
        benefit_group_plan_id: selectedPlan.id,
        subscription_id: activeTier.subscription_id,
      });
  }, [activeTier, manageSubscription, selectedTier.id, selectedPlan.id]);

  return {
    handleSubscription,
    activeTierSuccess,
    activeTierError,
    isSubscriptionLoading,
    subscriptionFailed,
    subscribedSuccessfully,
  };
};
