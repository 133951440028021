import { FormEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { FormLabel } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import { track } from 'analytics/analytics';
import { usePostPaymentMethodMutation } from 'apis/easyloadApi';
import { Box, Button, TitleLarge, TitleSmall } from 'components';
import { validPostalCodeRegex } from 'components/forms';
import { TextMaskPostalCode } from 'components/forms/TextMaskPostalCode';
import { KhInputText } from 'components/inputs/KhInputText';
import { selectProfileAddress } from 'profile/store/selectors';
import { theme } from 'theme';

// styles here are used to override Stripe's default properties in order to
// mimic MUI's Text input that we use for Postal Code. This ensures consistency
// across the form even though our source elements are from different libraries.
const css = `
  .KHCardNumberElement {
    border-color: rgb(200, 199, 204);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    padding: 16.5px 14px;
    min-height: 1.1876em;
    box-sizing: border-box;
    margin: 1px;
  }
  .KHCardNumberElement:hover {
    border-color: rgb(0, 0, 0);
  }
  .KHCardExpiryElement {
    border-color: rgb(200, 199, 204);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    padding: 16.5px 14px;
    min-height: 1.1876em;
    margin: 1px;
  }
  .KHCardExpiryElement:hover {
    border-color: rgb(0, 0, 0);
  }
  .KHCardCVCElement {
    border-color: rgb(200, 199, 204);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    padding: 16.5px 14px;
    min-height: 1.1876em;
    margin: 1px;
  }
  .KHCardCVCElement:hover {
    border-color: rgb(0, 0, 0);
  }
  .StripeElement--focus {
    border-color: ${theme.colors.primary};
    border-width: 2px;
    margin: initial;
  }
  .StripeElement--focus:hover {
    border-color: ${theme.colors.primary};
    border-width: 2px;
    margin: initial;
  }
  .StripeElement--invalid {
    border-color: ${theme.colors.danger};
  }
`;

interface FormCompleteData {
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
  postalCode: boolean;
}

type PaymentFormProps = {
  onSubmit: () => void;
};

export const PaymentForm = ({ onSubmit }: Readonly<PaymentFormProps>) => {
  const stripe = useStripe();
  const elements = useElements();
  const intl = useIntl();

  const address = useSelector(selectProfileAddress);

  const [createStripeSecret] = usePostPaymentMethodMutation();

  const [formComplete, setFormComplete] = useState<FormCompleteData>({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    postalCode: !!address?.postal_code,
  });
  const [loading, setLoading] = useState(false);
  const [cardError, setCardError] = useState<string | undefined>(undefined);

  const formIsClean =
    formComplete.cardNumber &&
    formComplete.cardExpiry &&
    formComplete.cardCvc &&
    formComplete.postalCode;

  const handlePCOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valid = validPostalCodeRegex.test(event.target.value);

    setFormComplete((prev) => {
      return { ...prev, postalCode: valid };
    });
  };

  const handleOnChange = (event: StripeElementChangeEvent) => {
    setFormComplete((prev) => {
      return { ...prev, [event.elementType]: event.complete };
    });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      Sentry.captureException(new Error('Stripe.js not loaded'));
      return null;
    }

    setLoading(true);

    const setupIntentResponse = await createStripeSecret().unwrap();
    const secret = setupIntentResponse.client_secret;

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      Sentry.captureException(new Error('Stripe Card Elements not found'));
      setLoading(false);
      setCardError('card_element_error');
      return null;
    }

    const paymentMethodResult = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          line1: address?.line1,
          line2: address?.line2,
          city: address?.city,
          state: address?.province,
          country: 'CA',
          postal_code: address?.postal_code,
        },
      },
    });

    if (paymentMethodResult.error) {
      track({
        event: 'Create Payment Method error',
        properties: { error: paymentMethodResult.error.code },
      });
      setLoading(false);
      setCardError(paymentMethodResult.error.code);
      return null;
    }

    let { paymentMethod } = paymentMethodResult;

    // check for paymentMethodResult.paymentMethod.card.funding
    const cardType = paymentMethod.card?.funding;

    const allowedToAddCard =
      cardType && (cardType === 'prepaid' || cardType === 'debit');

    if (!allowedToAddCard) {
      setLoading(false);
      setCardError('non_debit_card_error');
      return null;
    }

    const setupIntent = await stripe.confirmCardSetup(secret, {
      payment_method: paymentMethod.id,
    });

    setLoading(false);

    if (setupIntent.error) {
      track({
        event: 'Create SetupIntent error',
        properties: { error: setupIntent.error.code },
      });
      setCardError(setupIntent.error.code);
      return null;
    } else {
      // The setup has succeeded. Display a success message to your customer.
      track({ event: 'Add Payment Method success' });
      onSubmit();
    }
  };

  let cardErrorText = '';

  switch (cardError) {
    // https://docs.stripe.com/error-codes
    case 'expired_card':
    case 'incorrect_cvc':
    case 'incorrect_number':
    case 'incorrect_zip':
    case 'invalid_cvc':
    case 'invalid_number':
    case 'invalid_expiry_year':
    case 'invalid_expiry_month':
    case 'postal_code_invalid':
      cardErrorText = intl.formatMessage({
        id: 'PaymentMethodForm.Errors.InvalidCardError',
        defaultMessage:
          'Unable to add your card. Please check your inputs or try a different card.',
      });
      break;
    case 'non_debit_card_error':
      cardErrorText = intl.formatMessage({
        id: 'PaymentMethodForm.Errors.NonDebitCardError',
        defaultMessage:
          'Only debit cards are allowed. Please provide a different payment method.',
      });
      break;
    case 'card_element_error':
    default:
      cardErrorText = intl.formatMessage({
        id: 'PaymentMethodForm.Errors.CardElementError',
        defaultMessage:
          'Unable to proceed with loading. Please try again later.',
      });
  }

  return (
    <>
      <TitleLarge sx={{ mt: 0 }}>
        {intl.formatMessage({
          id: 'PaymentMethodForm.Title',
          defaultMessage: 'Add debit card',
        })}
      </TitleLarge>
      <TitleSmall sx={{ color: cardError ? theme.colors.danger : 'initial' }}>
        {cardError
          ? cardErrorText
          : intl.formatMessage({
              id: 'PaymentMethodForm.Subtitle',
              defaultMessage: 'Card information',
            })}
      </TitleSmall>
      <form onSubmit={handleSubmit}>
        <Box sx={{ pb: 3 }}>
          <FormLabel>
            {intl.formatMessage({
              id: 'PaymentMethodForm.Labels.CardNumber',
              defaultMessage: '16-digit card number',
            })}
            <CardNumberElement
              onChange={handleOnChange}
              options={{
                classes: {
                  base: 'KHCardNumberElement',
                },
                style: {
                  base: {
                    fontSize: '16px',
                    lineHeight: '22px',
                    '::placeholder': {
                      color: '#A2A2A2',
                      fontWeight: 300,
                    },
                  },
                },
              }}
            />
          </FormLabel>
        </Box>
        <Box sx={{ pb: 3 }}>
          <FormLabel>
            {intl.formatMessage({
              id: 'PaymentMethodForm.Labels.ExpiryDate',
              defaultMessage: 'Expiry Date MM/YY',
            })}
            <CardExpiryElement
              onChange={handleOnChange}
              options={{
                classes: {
                  base: 'KHCardExpiryElement',
                },
                placeholder: '01/25',
                style: {
                  base: {
                    fontSize: '16px',
                    lineHeight: '22px',
                    '::placeholder': {
                      color: '#A2A2A2',
                      fontWeight: 300,
                    },
                  },
                },
              }}
            />
          </FormLabel>
        </Box>
        <Box sx={{ pb: 3 }}>
          <FormLabel>
            {intl.formatMessage({
              id: 'PaymentMethodForm.Labels.CVV',
              defaultMessage: 'CVV',
            })}
            <CardCvcElement
              onChange={handleOnChange}
              options={{
                classes: {
                  base: 'KHCardCVCElement',
                },
                placeholder: '123',
                style: {
                  base: {
                    fontSize: '16px',
                    lineHeight: '22px',
                    '::placeholder': {
                      color: '#A2A2A2',
                      fontWeight: 300,
                    },
                  },
                },
              }}
            />
          </FormLabel>
        </Box>
        <Box sx={{ pb: 5 }}>
          <FormLabel>
            {intl.formatMessage({
              id: 'PaymentMethodForm.Labels.PostalCode',
              defaultMessage: 'Postal Code',
            })}
            <KhInputText
              defaultValue={address?.postal_code || ''}
              onChange={handlePCOnChange}
              placeholder="M1M 1M1"
              sx={{
                fontSize: '16px',
                lineHeight: '22px',
              }}
              InputProps={{ inputComponent: TextMaskPostalCode }}
            />
          </FormLabel>
        </Box>
        <Button
          trackName="Submit new funding card"
          type="submit"
          disabled={!formIsClean || loading || !stripe}
        >
          {intl.formatMessage({
            id: 'PaymentMethodForm.Buttons.AddCard',
            defaultMessage: 'Add new card',
          })}
        </Button>
      </form>
      <style>{css}</style>
    </>
  );
};
