import axios, { AxiosResponse } from 'axios';
import { Epic } from 'redux-observable';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { RootState } from 'store';

import { selectHasAccount } from '../../accounts/store/selectors';
import { Transaction } from '../models';
import { transactionsActions } from './slice';

interface TransactionsResponse {
  transactions: Transaction[];
  status_code: number;
  status_name: string;
}

const getTranactionsEpic: Epic<any, any, RootState> = (action$, state$) => {
  return action$.pipe(
    ofType(transactionsActions.getTransactionsRequest.toString()),
    filter(() => !!selectHasAccount(state$.value)),
    switchMap(() => {
      return from(
        axios.get(`${import.meta.env.VITE_GATEWAY_API}user/past_transactions`),
      ).pipe(
        map((response: AxiosResponse<TransactionsResponse>) =>
          transactionsActions.getTransactionsResponse(
            response.data.transactions,
          ),
        ),
        catchError(() => {
          return of(transactionsActions.getTransactionsError());
        }),
      );
    }),
  );
};

const exportedArray = [getTranactionsEpic];
export default exportedArray;
