import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '@material-ui/core';
import { KDSIcons } from 'assets/images/kds_icons';
import { Button } from 'components';
import { theme } from 'theme';

import { DrawerModal } from './DrawerModal';

const Info = () => (
  <KDSIcons.Icons.QuestionBold
    color={theme.colors.blueDark}
    sx={{ ml: 1, mb: '2px' }}
  />
);

export type ModalProps = {
  content: ReactNode;
  icon?: ReactNode;
  title: string;
};

const ModalContent = ({
  content,
  icon,
  title,
  close,
}: ModalProps & { close: () => void }) => {
  const intl = useIntl();
  return (
    <>
      {icon}
      <Typography
        sx={{
          mb: 2,
          mt: icon ? 0 : 3,
          fontWeight: 900,
          fontSize: 32,
          color: theme.colors.text,
        }}
      >
        {title}
      </Typography>
      <Typography
        component="span"
        sx={{
          display: 'block',
          color: theme.colors.text,
          '& ul': { my: 0 },
          mb: 3,
        }}
      >
        {content}
      </Typography>
      <Button onClick={close}>
        {intl.formatMessage({
          id: 'InfoModal.Tooltip.Done',
          defaultMessage: 'Got it',
        })}
      </Button>
    </>
  );
};

export const InfoModal = (props: ModalProps) => {
  return (
    <DrawerModal
      content={({ closeModal }) => (
        <ModalContent {...props} close={closeModal} />
      )}
    >
      <Info />
    </DrawerModal>
  );
};
