import { useIntl } from 'react-intl';

import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
import { track } from 'analytics/analytics';
import { IBillingPaymentMethod } from 'apis/billing';
import { BillingPaymentMethodOrganization } from 'billing/models/PaymentMethodOrganization';
import { theme } from 'theme';

import { AddCardMethod, CardMethod, KOHOMethod } from './PaymentMethod';

type Props = {
  balance: string | undefined;
  disabled?: boolean;
  handleChange: (value: string | undefined) => void;
  kohoDisabled: boolean;
  openBillingMethodsModal: () => void;
  paymentMethods: IBillingPaymentMethod[];
  selectedMethod: string | undefined;
};

export const PaymentMethodsSelect = ({
  balance,
  disabled = false,
  handleChange,
  kohoDisabled,
  openBillingMethodsModal,
  paymentMethods,
  selectedMethod,
}: Props) => {
  const intl = useIntl();
  const selectedMethodExistsInOptions = !!paymentMethods.find(
    (method) => method.id === selectedMethod,
  );
  return (
    <FormControl variant="outlined" fullWidth>
      <Select
        id="select-payment-method"
        value={selectedMethodExistsInOptions ? selectedMethod : ''}
        disabled={disabled}
        displayEmpty
        onOpen={() => {
          track({
            event: 'Clicked',
            properties: { name: 'choose-other-payment-method' },
          });
        }}
        onChange={(event) => {
          handleChange(event.target.value as string | undefined);
        }}
        MenuProps={{ disableScrollLock: true }}
        sx={{
          textAlign: 'left',
          color: selectedMethod ? theme.colors.text : theme.colors.midGrey,
          '& .MuiSelect-root': {
            py: 2,
            px: 3,
          },
          '& .MuiSvgIcon-root': {
            color: theme.colors.text,
          },
        }}
      >
        <MenuItem value="" disabled sx={{ display: 'none' }}>
          {intl.formatMessage({
            id: 'PaymentMethodsSelect.Placeholder',
            defaultMessage: 'Choose a method',
          })}
        </MenuItem>
        {paymentMethods.map((method, i) => (
          <MenuItem
            sx={{ mb: 1, py: 3 }}
            key={method.id}
            value={method.id}
            disabled={
              kohoDisabled &&
              method.organization === BillingPaymentMethodOrganization.Koho
            }
          >
            <Box
              display="flex"
              flexDirection="row"
              sx={{ alignItems: 'center' }}
            >
              {method.organization === BillingPaymentMethodOrganization.Koho ? (
                <KOHOMethod balance={balance} disabled={kohoDisabled} />
              ) : (
                <CardMethod method={method} />
              )}
            </Box>
          </MenuItem>
        ))}{' '}
        <MenuItem
          value="add-debit-credit-card"
          onClick={openBillingMethodsModal}
          sx={{ py: 3 }}
        >
          <AddCardMethod />
        </MenuItem>
      </Select>
    </FormControl>
  );
};
