import { useIntl } from 'react-intl';

import { Box, BoxProps, InputAdornment } from '@material-ui/core';
import { MoneyRequest } from 'apis/requestMoneyApi';
import { Paragraph, ParagraphSmall, TitleMedium } from 'components';
import { CopyButton } from 'components/CopyButton';
import { KhInputText } from 'components/inputs/KhInputText';
import { theme } from 'theme';

export const RequestMoneyETransferDetails = ({
  request,
  ...props
}: BoxProps & {
  request: MoneyRequest;
}) => {
  const intl = useIntl();

  return (
    <Box {...props}>
      <TitleMedium>
        {intl.formatMessage(
          {
            id: 'RequestMoneyETransferDetails.Title',
            defaultMessage: 'e-Transfer {name}',
          },
          { name: request.requester_given_name },
        )}
      </TitleMedium>
      <ParagraphSmall sx={{ mb: 4 }}>
        {intl.formatMessage(
          {
            id: 'RequestMoneyETransferDetails.Subtitle',
            defaultMessage:
              'Interac e-Transfer from your bank to {name}’s KOHO account.',
          },
          { name: request.requester_given_name },
        )}
      </ParagraphSmall>
      <KhInputText
        disabled={true}
        sx={{
          '.MuiInputBase-input': { color: theme.colors.text },
          mb: 0,
        }}
        defaultValue={request.etransfer_email}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CopyButton
                value={request.etransfer_email}
                trackingLabel="ETransfer Email"
              />
            </InputAdornment>
          ),
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.colors.greyLight}`,
        }}
      >
        <Paragraph>
          {intl.formatMessage({
            id: 'RequestMoneyETransferDetails.Question',
            defaultMessage: 'Security question',
          })}
          :
        </Paragraph>
        <Box sx={{ display: 'flex' }}>
          <Paragraph>
            {intl.formatMessage({
              id: 'RequestMoneyETransferDetails.QuestionValue',
              defaultMessage: 'KOHO Load code',
            })}
          </Paragraph>
          <CopyButton
            value={intl.formatMessage({
              id: 'RequestMoneyETransferDetails.QuestionValue',
              defaultMessage: 'KOHO Load code',
            })}
            trackingLabel="Security question"
            sx={{ ml: 2, px: 3 }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.colors.greyLight}`,
        }}
      >
        <Paragraph>
          {intl.formatMessage({
            id: 'RequestMoneyETransferDetails.Answer',
            defaultMessage: 'Security answer',
          })}
          :
        </Paragraph>
        <Box sx={{ display: 'flex' }}>
          <Paragraph>{request.etransfer_answer}</Paragraph>
          <CopyButton
            value={request.etransfer_answer}
            trackingLabel="Security answer"
            sx={{ ml: 2, px: 3 }}
          />
        </Box>
      </Box>
    </Box>
  );
};
