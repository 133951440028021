import { Box, BoxProps, useMediaQuery, useTheme } from '@material-ui/core';
import { IBenefitGroup, IPlan } from 'apis/tiers';
import { desktop } from 'theme';
import { TierKey } from 'tiers/models';

import { TierCard } from './tierCard/index';

type Props = {
  boxSx?: BoxProps['sx'];
  cardTrackName: string;
  handleSelectTier?: (tier: TierKey) => void;
  isFree?: boolean;
  onPlanChange?: (tier: IBenefitGroup, plan: IPlan | undefined) => void;
  plan?: string;
  selectable?: boolean;
  selectedTier?: TierKey;
  tiers: IBenefitGroup[];
};

export const Tiers = ({
  boxSx = {},
  cardTrackName,
  handleSelectTier,
  isFree = false,
  onPlanChange,
  plan = 'monthly',
  selectable = false,
  selectedTier,
  tiers,
}: Props) => {
  const muiTheme = useTheme();
  const isAfterTablet = useMediaQuery(muiTheme.breakpoints.up('md'));
  const isDesktop = useMediaQuery(desktop);

  return (
    <Box
      display="flex"
      flexDirection={isAfterTablet ? 'row' : 'column'}
      sx={{
        gap: isDesktop ? '32px' : '16px',
        padding: '0 16px',
        width: '100%',
        ...boxSx,
      }}
    >
      {tiers
        .filter((benefit) => benefit.key !== TierKey.Easy)
        .map((benefit) => (
          <TierCard
            key={benefit.id}
            benefit={benefit}
            expandable={selectable && !isAfterTablet}
            selectable={selectable}
            selected={selectedTier === benefit.key}
            handleSelectTier={handleSelectTier}
            plan={plan}
            isFree={isFree}
            onPlanChange={onPlanChange}
            trackname={cardTrackName}
          />
        ))}
    </Box>
  );
};
