import { createSelector } from '@reduxjs/toolkit';
import { BillingAddress } from 'profile/models/address';
import { RootState } from 'store';

export const selectProfileState = (state: RootState) => state.profile;

export const selectProfile = createSelector(
  selectProfileState,
  (state) => state?.data,
);

export const selectProfileEmail = createSelector(
  selectProfile,
  (profile) => profile?.email,
);

export const selectUserRefID = createSelector(
  selectProfile,
  (profile) => profile?.reference_identifier,
);

export const selectProfileFamilyName = createSelector(
  selectProfile,
  (profile) => profile?.family_name,
);

export const selectProfileGivenName = createSelector(
  selectProfile,
  (profile) => profile?.given_name,
);

export const selectProfileName = createSelector(selectProfile, (profile) => {
  if (!profile) return '';
  return profile.given_name + ' ' + profile.family_name;
});

export const selectProfileCanDoKyc = createSelector(
  selectProfile,
  (profile) => !profile?.cleared || profile.can_retry_kyc,
);

export const selectProfileTel = createSelector(
  selectProfile,
  (profile) => profile?.tel,
);

export const selectProfileAddress = createSelector(
  selectProfile,
  (profile): BillingAddress | null => {
    if (!profile) return null;
    return {
      line1: profile.street_address,
      line2: profile.unit_number,
      city: profile.locality,
      province: profile.region,
      postal_code: profile.postal_code,
      country: profile.country_name,
    };
  },
);

export const selectProfileEtransferEmail = createSelector(
  selectProfile,
  (state) => state?.etransfer_email,
);

export const selectProfileEtransferAnswer = createSelector(
  selectProfile,
  (state) => state?.etransfer_answer,
);

export const selectProfileTelLast4Digits = createSelector(
  selectProfileTel,
  (tel) => tel?.split('').splice(-4).join(''),
);

export const selectProfileHasAccessCode = createSelector(
  selectProfile,
  (profile) => profile?.has_access_code,
);

export const selectProfileLoadable = createSelector(
  selectProfileState,
  (state) => state?.loadable,
);

export const selectProfileLoading = createSelector(
  selectProfileLoadable,
  (loadable) => loadable?.loading,
);

export const selectProfileDone = createSelector(
  selectProfileLoadable,
  (loadable) => loadable?.success,
);

export const selectProfileVerified = createSelector(
  selectProfile,
  (profile) => profile?.cleared,
);

export const selectProfileInitialKycSkipped = createSelector(
  selectProfile,
  (profile) => profile?.initial_kyc_skipped,
);

export const selectProfileIsMidRegistration = createSelector(
  selectProfile,
  (profile) => !profile || !profile.verified_phone,
);
