import { MessageDescriptor } from 'react-intl';

export interface Loadable {
  loading: boolean;
  success: boolean;
  error: any;
}

export const createDefaultLoadable = (): Loadable => ({
  loading: false,
  success: false,
  error: null,
});

export const createLoadingLoadable = (): Loadable => ({
  loading: true,
  success: false,
  error: null,
});

export const createSuccessLoadable = (): Loadable => ({
  loading: false,
  success: true,
  error: null,
});

export const createErrorLoadable = (
  error:
    | string
    | MessageDescriptor
    | { code: number; message: string | MessageDescriptor },
): Loadable => ({
  loading: false,
  success: false,
  error,
});

export const isLoadableDefault = (loadable: Loadable): boolean => {
  return (
    loadable.loading === false &&
    loadable.success === false &&
    loadable.error === null
  );
};
