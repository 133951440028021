import { track } from 'analytics/analytics';
import { selectIsAuthenticationReady } from 'auth/store/selectors';
import axios from 'axios';
import {
  forethoughtChatClose,
  forethoughtChatOpen,
} from 'forethought/useForethought';
import { intercomHide, intercomShow } from 'intercom/intercom';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RootState } from 'store';

import { contactActions } from './contact.slice';

const intercomOpenEpic$: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(contactActions.intercomOpen),
    map(() => {
      forethoughtChatClose();
      intercomShow();
      track({ event: 'Intercom Opened' });
      return { type: 'noop' };
    }),
  );
};

const intercomCloseEpic$: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(contactActions.intercomClose),
    map(() => {
      intercomHide();
      track({ event: 'Intercom closed' });
      return { type: 'noop' };
    }),
  );
};

const forethoughtOpenEpic$: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(contactActions.forethoughtOpen),
    map(() => {
      intercomHide();
      forethoughtChatOpen();
      track({ event: 'Forethought Opened' });
      return { type: 'noop' };
    }),
  );
};

const forethoughtCloseEpic$: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(contactActions.forethoughtClose),
    map(() => {
      forethoughtChatClose();
      track({ event: 'Forethought closed' });
      return { type: 'noop' };
    }),
  );
};

const chatButtonClickedEpic$: Epic<any, any, RootState> = (action$, state$) => {
  return action$.pipe(
    ofType(contactActions.chatButtonClicked),
    tap(() => {
      forethoughtChatClose();
      intercomHide();
    }),
    map(() => {
      const loggedIn = Boolean(selectIsAuthenticationReady(state$.value));
      const flagValue = state$.value.contact.useOpenChatsEndpointLdVal;
      const useOpenChatsEndpoint = loggedIn && flagValue;
      return useOpenChatsEndpoint;
    }),
    map((useOpenChatsEndpoint) =>
      useOpenChatsEndpoint
        ? contactActions.useOpenChatsEndpoint()
        : contactActions.openModal(),
    ),
  );
};

const useOpenChatsEndpointEpic$: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(contactActions.useOpenChatsEndpoint),
    switchMap(() =>
      axios.get<{ open_chats: boolean }>(
        `${import.meta.env.VITE_GATEWAY_API}users/open-chats`,
      ),
    ),
    map(({ data: { open_chats } }) =>
      open_chats
        ? contactActions.intercomOpen()
        : contactActions.forethoughtOpen(),
    ),
    catchError(() => of(contactActions.openModal())),
  );
};

const modalOpenEpic$: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(contactActions.openModal),
    map(() => {
      track({ event: 'Contact modal opened' });
      return { type: 'noop' };
    }),
  );
};

const modalCloseEpic$: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(contactActions.closeModal),
    map(() => {
      track({ event: 'Contact modal closed' });
      return { type: 'noop' };
    }),
  );
};

export const contactEpics = [
  intercomOpenEpic$,
  intercomCloseEpic$,
  forethoughtOpenEpic$,
  forethoughtCloseEpic$,
  chatButtonClickedEpic$,
  modalOpenEpic$,
  modalCloseEpic$,
  useOpenChatsEndpointEpic$,
];
