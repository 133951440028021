/** @jsxImportSource theme-ui */
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import Close from '@material-ui/icons/Close';
import { Flex } from 'theme-ui';

import { ButtonUnstyled } from '../components';
import { theme } from '../theme';

export const InPageNotification = ({
  children,
  closeButton = true,
}: {
  children: ReactNode;
  closeButton?: boolean;
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const intl = useIntl();

  if (isHidden) return null;
  return (
    <Flex
      sx={{
        color: theme.colors.text,
        backgroundColor: theme.colors.greyXLight,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: theme.borderRadius.small,
        gap: theme.space[4],
        flexDirection: 'row',
        p: theme.space[1],
      }}
    >
      {children}
      {closeButton && (
        <ButtonUnstyled
          onClick={() => setIsHidden(true)}
          aria-label={intl.formatMessage({
            id: 'InPageNotification.CloseButton.AriaLabel',
            defaultMessage: 'Close notification',
          })}
        >
          <Close sx={{ color: theme.colors.primary }} />
        </ButtonUnstyled>
      )}
    </Flex>
  );
};
