import { HTMLAttributes, ReactNode } from 'react';

import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface ParagraphProps {
  children?: ReactNode;
  className?: string;
}

export const Paragraph = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement> & ParagraphProps) => (
  <p
    className={twMerge(
      clsx(
        'font-normal leading-5 text-base tracking-tight mb-4',
        'legacy:font-legacyBody light:font-body',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </p>
);

export const ParagraphSmall = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <p
    className={twMerge(
      'font-body m-0 p-0 text-sm/[18px] text-grey-300 tracking-tight',
      className,
    )}
  >
    {children}
  </p>
);

export const BodyXs = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement> & ParagraphProps) => (
  <p
    className={twMerge(
      clsx(
        'font-normal text-sm/[18px] text-grey-300 m-0 p-0',
        'legacy:font-legacyBody legacy:leading-[-0.2px]',
        'light:text-grey-500 light:font-body light:text-xs/[18px]',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </p>
);

export const BodySm = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement> & ParagraphProps) => (
  <p
    className={twMerge(
      clsx(
        'mb-4 text-sm',
        'light:font-body light:font-medium',
        'legacy:font-normal legacy:leading-5 legacy:tracking-tight',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </p>
);

export const LabelLg = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={twMerge(
      'legacy:text-xs/[18px] legacy:not-italic legacy:font-semibold legacy:tracking-[-0.2px] legacy:text-grey-400 legacy:m-0 legacy:p-0',
      'text-base/5 font-medium text-grey-800',
      'legacy:font-legacyBody light:font-body',
      className,
    )}
  >
    {children}
  </div>
);

interface TitleLargeProps {
  children?: ReactNode;
  className?: string;
}

export const TitleLarge = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & TitleLargeProps) => (
  <h1
    className={twMerge(
      clsx(
        'font-black leading-10 my-4 tracking-[-0.2px] text-3xl text-grey-400',
        'legacy:font-legacyHeading light:font-heading',
        'light:uppercase light:font-bold light:text-2xl/6 light:tracking-[-0.12px] light:text-grey-1000',
        className,
      ),
    )}
    {...props}
  >
    {children}
  </h1>
);
