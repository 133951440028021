import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link, navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import { useValidateMFAMutation } from 'apis/mfa';
import { KDSIcons } from 'assets/images/kds_icons';
import { ErrorParagraph, LayoutBasic } from 'components';
import { HelpAndAccessibility } from 'support/components';
import { mobile, theme } from 'theme';
import { useGetErrorMessage } from 'utility/useGetAuthErrorMessage';

const OTP_VALID_LENGTH = 6;

export const MFAVerify = ({ path }: { path?: string }) => {
  const getErrorMessage = useGetErrorMessage();
  const [code, setCode] = useState('');
  const intl = useIntl();

  const classes = useStyles();

  const [validateMFA, validateMFAData] = useValidateMFAMutation();

  const inputPlaceholder = intl.formatMessage({
    defaultMessage: 'Verification code',
    id: 'MFAVerify.Placeholder',
  });

  useEffect(() => {
    if (validateMFAData.isSuccess) {
      navigate('/transactions');
    }
  }, [validateMFAData.isSuccess]);

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const code = event.target.value;
    setCode(code);

    if (code === '' || parseInt(code.trim())) {
      const val = code.length ? code.substring(0, 6) : '';

      if (val.length === OTP_VALID_LENGTH) {
        validateMFA({
          mfa_token: val,
          mfa_option: 'totp',
        }).unwrap();
      }
    }
  };

  const handlePasteCode = () => {
    navigator.clipboard.readText().then((val) => {
      setCode(val);
      if (val.length === OTP_VALID_LENGTH) {
        validateMFA({
          mfa_token: val,
          mfa_option: 'totp',
        }).unwrap();
      }
    });
  };

  const trackTextField = () => {
    track({
      event: 'Clicked',
      properties: {
        name: '2sv-verify-input',
      },
    });
  };

  return (
    <LayoutBasic pageName="MFA - Verify" chat={false}>
      <Box className={classes.container}>
        <Typography className={classes.title}>
          {intl.formatMessage({
            defaultMessage: 'Check your authenticator app',
            id: 'MFAVerify.CheckYourAuthenticatorApp',
          })}
        </Typography>
        <Typography className={classes.description}>
          {intl.formatMessage({
            defaultMessage:
              'Your authenticator app should have a 6-digit code for KOHO. Copy and paste it below before the code expires.',
            id: 'MFAVerify.Description',
          })}
        </Typography>
        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
          className={classes.inputContainer}
        >
          <TextField
            className={classes.input}
            variant="outlined"
            label={inputPlaceholder}
            required
            autoFocus
            value={code}
            onChange={onChange}
            error={validateMFAData.isError}
            data-cy="mfa-code-input"
            onClick={() => trackTextField()}
            onFocus={() => trackTextField()}
          />
        </form>
        <Button
          className={classes.pasteButton}
          onClick={() => {
            track({
              event: 'Clicked',
              properties: {
                name: '2sv-verify-paste',
              },
            });
            handlePasteCode();
          }}
          disabled={validateMFAData.isLoading}
        >
          {validateMFAData.isLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            intl.formatMessage({
              defaultMessage: 'Paste from clipboard',
              id: 'MFAVerify.PasteFromClipboard',
            })
          )}
        </Button>
        {validateMFAData.isError && (
          <ErrorParagraph>
            {getErrorMessage(
              (validateMFAData.error as { status: number }).status,
            )}
          </ErrorParagraph>
        )}
        <Box display="flex" alignItems="center">
          <Link
            state={{
              fallback: true,
            }}
            onClick={() =>
              track({
                event: 'Clicked',
                properties: {
                  name: '2sv-verify-help',
                },
              })
            }
            to="/sms-verify"
            className={classes.cantAccessLink}
            data-cy="cant-access-link"
          >
            {intl.formatMessage({
              defaultMessage: "Can't access your authenticator app?",
              id: 'MFAVerify.CantAccessYourAuthenticatorApp',
            })}
          </Link>
          <KDSIcons.Icons.ArrowRight className={classes.rightArrow} />
        </Box>
      </Box>
      <HelpAndAccessibility />
    </LayoutBasic>
  );
};

const useStyles = makeStyles({
  container: {
    paddingTop: '20px',
    margin: '0 auto',
    maxWidth: '452px',
    color: theme.colors.text,
    [mobile]: {
      paddingInline: '32px',
    },
  },
  title: {
    fontSize: '32px',
    fontWeight: 900,
    marginBottom: '16px',
    [mobile]: {
      lineHeight: '32px',
    },
  },
  description: {
    fontSize: '16px',
    marginBottom: '36px',
  },
  inputContainer: {
    marginBottom: '16px',
  },
  input: {
    width: '100%',
    borderRadius: '4px',
    '&::placeholder': {
      color: theme.colors.midGrey,
      fontSize: '16px',
    },
  },
  pasteButton: {
    marginBottom: '24px',
    color: theme.colors.primary,
    border: `1px solid ${theme.colors.primary}`,
    backgroundColor: theme.colors.white,
    textTransform: 'unset',
    width: '100%',
    fontSize: '16px',
    fontWeight: 900,
    paddingBlock: '11px',
  },
  cantAccessLink: {
    fontSize: '16px',
    fontWeight: 900,
    textDecoration: 'none',
    color: theme.colors.primary,
    marginRight: '8px',
  },
  rightArrow: {
    color: theme.colors.primary,
    width: '16px',
    height: '16px',
  },
});
