import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectTiersState = (state: RootState) => state.tiers;

export const selectTiersData = createSelector(
  selectTiersState,
  (tiers) => tiers?.data,
);

export const selectIsPaidTier = createSelector(
  selectTiersState,
  (tiers) => tiers?.is_paid_tier,
);

export const selectDesiredTier = createSelector(
  selectTiersState,
  (tiers) => tiers.desired_tier,
);
