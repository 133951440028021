/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';

import { RouteComponentProps, navigate } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';

import { Button, H2, P } from '../components';

export const PasswordResetLinkExpired = (props: RouteComponentProps) => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <KDSIcons.Spot.Wait sx={{ mb: 3 }} />
      <H2>
        {intl.formatMessage({
          id: 'Global.OopsErrorMessage',
          defaultMessage: 'Oops! We’re having issues',
        })}
      </H2>
      <P>
        {intl.formatMessage({
          id: 'LinkExpired.Content',
          defaultMessage:
            'Sorry, the verification link expired or something went wrong on our end. Please try again.',
        })}
      </P>
      <Button type="button" onClick={() => navigate('/password-reset/email')}>
        {intl.formatMessage({
          id: 'Global.Button.ResendEmail',
          defaultMessage: 'Resend email',
        })}
      </Button>
    </React.Fragment>
  );
};
