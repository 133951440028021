import { IntercomChatButton } from 'intercom/IntercomChatButton';

import { AccessibilityButton } from './AccessibilityButton';

export const HelpAndAccessibility = () => {
  return (
    <div className="flex justify-center gap-6 my-4">
      <IntercomChatButton variant="text" />
      <AccessibilityButton />
    </div>
  );
};
