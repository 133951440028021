import { IntendedUse } from 'kyc/models/IntendedUse';
import { RegistrationIntentComponent } from 'registration/components/RegistrationIntentComponent';
import { useTrackPageViewedOnceDeprecated } from 'utility/analyticsHooks';

export const RegistrationIntentPage = ({
  intentClicked,
}: {
  intentClicked: (intent: IntendedUse) => Promise<void>;
}) => {
  useTrackPageViewedOnceDeprecated({
    name: 'registration-intent',
    overrideUrl: `registration/intent`,
  });

  return <RegistrationIntentComponent intentClicked={intentClicked} />;
};
