import { rootApi } from 'apis/rootApi';
import { AccountCard, CardIssuer } from 'cards/models';
import { CardProductData } from 'models/CardProductData';
import { Profile } from 'profile/models/profile';

type RequestPhysicalCard = {
  design: string;
  profile: Profile;
};

interface CardImageResponse {
  card_image: string;
}

const cardsApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getCards: build.query<AccountCard[], void>({
      query: () => ({
        url: '1.0/accounts/cards',
      }),
      transformResponse: (response: { data: AccountCard[] }) =>
        response.data.filter((card) => card.issuer === CardIssuer.Mastercard),
      providesTags: ['Cards'],
    }),
    getCardImage: build.query<string, string>({
      query: (card_id: string) => ({
        url: '1.0/accounts/card-image',
        method: 'POST',
        body: { card_id },
      }),
      transformResponse(response: { data: CardImageResponse }) {
        return response.data.card_image;
      },
      forceRefetch() {
        // this API returns a card image URL from galileo, but this can only be fetched a
        // small number of times. Rather than reuse the same URL whenever the card img tag
        // is removed and re-added, we'll just always fetch a fresh URL
        return true;
      },
    }),
    lockCard: build.mutation<
      { data: AccountCard },
      { id: string; lock: boolean }
    >({
      query: ({ id, lock }) => ({
        url: '1.0/accounts/card/' + id,
        method: 'PATCH',
        body: { is_locked: lock },
      }),
      async onQueryStarted({ id, lock }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            cardsApi.util.updateQueryData('getCards', undefined, (draft) => {
              const card = draft.find((card) => card.card_id === id);

              if (card) {
                Object.assign(card, data.data);
              }
            }),
          );
        } catch (error) {
          console.error('lockCard mutation failed:', error);
        }
      },
    }),
    getDesigns: build.query<CardProductData[], void>({
      query: () => ({
        url: '1.0/cards/designs',
      }),
      transformResponse: (response: { designs: CardProductData[] }) =>
        response.designs,
    }),
    requestPhysical: build.mutation<void, RequestPhysicalCard>({
      query: ({ design, profile }: RequestPhysicalCard) => {
        return {
          url: `2.0/accounts`,
          method: 'POST',
          body: {
            card_design: design,
            intended_use: profile.intended_use,
            is_physical: true,
          },
        };
      },
    }),
  }),
});

export const {
  useGetCardsQuery,
  useLazyGetCardsQuery,
  useGetCardImageQuery,
  useLockCardMutation,
  useRequestPhysicalMutation,
  useGetDesignsQuery,
} = cardsApi;
