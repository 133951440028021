/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import { KDSIcons } from 'assets/images/kds_icons';
import { ButtonUnstyled, Paragraph } from 'components';

export const ProgressBar = ({
  currentStep,
  totalSteps,
  showSteps = true,
  showBackButton = false,
  showBackButtonMobile = false,
  onBack,
}: {
  currentStep: number;
  totalSteps: number;
  showSteps?: boolean;
  showBackButton?: boolean;
  showBackButtonMobile?: boolean;
  onBack?: Function;
}) => {
  const intl = useIntl();
  const backButton = (
    <ButtonUnstyled
      onClick={() => {
        window.history.back();
        onBack && onBack();
      }}
      aria-label={intl.formatMessage({
        id: 'Global.Button.Back',
        defaultMessage: 'Back',
      })}
      sx={{
        boxSizing: 'content-box',
        width: '24px',
        height: '24px',
        display: [showBackButtonMobile ? 'block' : 'none', 'block'],
      }}
    >
      <KDSIcons.Icons.ChevronLeft sx={{ width: '100%', height: '100%' }} />
    </ButtonUnstyled>
  );

  return (
    <div sx={{ mb: 2 }}>
      {showBackButton && backButton}
      {showSteps && (
        <Paragraph
          sx={{
            color: 'midGrey',
            fontSize: 1,
            mb: '14px',
          }}
        >
          {intl.formatMessage({
            id: 'ProgressBar.Title',
            defaultMessage: 'Step',
          })}{' '}
          {currentStep}/{totalSteps}
        </Paragraph>
      )}
      <div
        sx={{
          height: '4px',
          width: '100%',
          backgroundColor: 'greyLight',
          borderRadius: '4px',
        }}
      >
        <div
          sx={{
            width:
              currentStep === totalSteps && showSteps
                ? `${(currentStep / totalSteps) * 100 - 5}%`
                : `${(currentStep / totalSteps) * 100}%`,
            height: 'inherit',
            borderRadius: '8px',
            background: 'linear-gradient(90deg, #398EB1 0%, #00579B 100%)',
          }}
        ></div>
      </div>
    </div>
  );
};
