import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RecipientData } from '../../models/Recipient';
import { TransferFormData } from '../../models/TransferFormData';
import {
  Loadable,
  createDefaultLoadable,
  createErrorLoadable,
  createLoadingLoadable,
  createSuccessLoadable,
} from '../../utility/loadable';

export const DEVICE_VERIFICATION_ERROR = 'Failed to verify device';
export const E_TRANSFER_ERROR = 'Failed to send e-transfer';
export const E_TRANSFER_FUNDS_ERROR =
  'Not enough money in your available balance';

export interface TransferState {
  recipients: RecipientData[];
  loadableAddRecipient: Loadable;
  loadableUpdateRecipient: Loadable;
  loadableGetRecipients: Loadable;
  loadableSendETransfer: Loadable;
}

const initialState: TransferState = {
  recipients: [],
  loadableAddRecipient: createDefaultLoadable(),
  loadableUpdateRecipient: createDefaultLoadable(),
  loadableGetRecipients: createDefaultLoadable(),
  loadableSendETransfer: createDefaultLoadable(),
};

const transferSlice = createSlice({
  name: 'transfer',
  initialState: initialState,
  reducers: {
    addRecipientRequest: (state, _: PayloadAction<TransferFormData>) => {
      state.loadableAddRecipient = createLoadingLoadable();
    },
    addRecipientSuccess: (state, action: PayloadAction<RecipientData>) => {
      state.loadableAddRecipient = createSuccessLoadable();
      state.recipients = state.recipients
        ? [...state.recipients, action.payload]
        : [action.payload];
    },
    addRecipientError: (state) => {
      state.loadableAddRecipient = createErrorLoadable(
        'Failed to add recipient',
      );
    },
    updateRecipientRequest: (
      state,
      _: PayloadAction<{
        recipientID: string;
        formData: TransferFormData;
      }>,
    ) => {
      state.loadableUpdateRecipient = createLoadingLoadable();
    },
    updateRecipientSuccess: (state, action: PayloadAction<RecipientData>) => {
      state.loadableUpdateRecipient = createSuccessLoadable();
      updateRecipient(state.recipients, action.payload);
    },
    updateRecipientError: (state) => {
      state.loadableUpdateRecipient = createErrorLoadable(
        'Failed to update recipient',
      );
    },
    getRecipientsRequest: (state) => {
      state.loadableGetRecipients = createLoadingLoadable();
    },
    getRecipientsSuccess: (
      state,
      action: PayloadAction<{ data: RecipientData[] }>,
    ) => {
      state.loadableGetRecipients = createSuccessLoadable();
      state.recipients = action.payload.data;
    },
    getRecipientsError: (state) => {
      state.loadableGetRecipients = createErrorLoadable(
        'Failed to get recipients',
      );
    },
    sendETransferRequest: (
      state,
      _: PayloadAction<{ amount: string; recipientID: string }>,
    ) => {
      state.loadableSendETransfer = createLoadingLoadable();
    },
    sendETransferSuccess: (state) => {
      state.loadableSendETransfer = createSuccessLoadable();
    },
    sendETransferError: (state, action) => {
      state.loadableSendETransfer = createErrorLoadable(action.payload);
    },
    resetSendETransfer: (state) => {
      state.loadableSendETransfer =
        state.loadableAddRecipient =
        state.loadableUpdateRecipient =
          createDefaultLoadable();
    },
    clickSendETransfer: (
      _,
      __: PayloadAction<{ formData: TransferFormData }>,
    ) => {},
    resetLoadable: (state) => {
      state.loadableAddRecipient = createDefaultLoadable();
      state.loadableUpdateRecipient = createDefaultLoadable();
      state.loadableSendETransfer = createDefaultLoadable();
    },
    noop: (state) => state,
  },
});

function updateRecipient(
  recipients: RecipientData[],
  updatedRecipientData: RecipientData,
) {
  const index = recipients.findIndex(
    (r) => r.identifier === updatedRecipientData.identifier,
  );
  const updatedRecipients = [...recipients];
  updatedRecipients.splice(index, 1, updatedRecipientData);
  return updatedRecipients;
}

export const transferActions = transferSlice.actions;
export default transferSlice.reducer;
