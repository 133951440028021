import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  Loadable,
  createDefaultLoadable,
  createErrorLoadable,
  createLoadingLoadable,
  createSuccessLoadable,
} from 'utility/loadable';

export interface InboundETransferRetrieveRequest {
  transferId: string;
}
export interface InboundETransferDeclineRequest {
  transferId: string;
  answer: string;
}
export interface InboundETransferAcceptRequest {
  transferId: string;
  answer: string;
  authenticationMethod: string;
}

export interface InboundETransferAcceptDeclineApiError {
  status?: number;
  message?: string;
}

export enum InboundETransferStatus {
  accepted = 'ACCEPTED',
  available = 'AVAILABLE',
  cancelled = 'CANCELLED',
  failed = 'FAILED',
  securityAnswerFailure = 'SECURITY_ANSWER_FAILURE',
  expired = 'EXPIRED',
  declined = 'DECLINED',
  depositInitiated = 'DEPOSIT_INITIATED',
  depositPending = 'DEPOSIT_PENDING',
  depositComplete = 'DEPOSIT_COMPLETE',
}

export interface InboundETransfer {
  fromName: string;
  amountCadCents: number;
  securityQuestion: string;
  memo: string;
  status: InboundETransferStatus;
}

export interface InboundETransferForm {
  securityAnswer: string;
  showIncorrectAnswerError: boolean;
}

export interface InboundETransferForm {
  securityAnswer: string;
}

export enum InboundETransferErrors {
  Generic,
  ServiceTemporarilyUnavailable, // we'd get this when our partners services are unavailable
  RetrieveTransferUnavailable,
  RetrieveBadTransferId,
  RetrieveKycNotCleared,
  SubmissionIncorrectAnswer,
  SubmissionTransferCanceled,
}

export enum InboundETransferSubmissionIntent {
  Accept,
  Decline,
}

export interface InboundETransferRetrieveError {
  errorType: InboundETransferErrors;
}
export interface InboundETransferSubmissionError {
  unAcknowledgedError: boolean;
  error: InboundETransferErrors;
}

export interface InboundETransferState {
  transfer: InboundETransfer;
  transferForm: InboundETransferForm;
  transferRetrieveError: InboundETransferErrors;
  transferSubmissionError: InboundETransferSubmissionError;
  intent: InboundETransferSubmissionIntent | undefined;
  loadableRetrieveTransfer: Loadable;
  loadableDeclineTransfer: Loadable;
  loadableAcceptTransfer: Loadable;
}

const initialTransferState: InboundETransfer = {
  fromName: '',
  amountCadCents: 0,
  securityQuestion: '',
  memo: '',
  status: InboundETransferStatus.available,
};

const initialState: InboundETransferState = {
  transfer: initialTransferState,
  transferForm: {
    securityAnswer: '',
    showIncorrectAnswerError: false,
  },
  transferRetrieveError: InboundETransferErrors.Generic,
  transferSubmissionError: {
    unAcknowledgedError: false,
    error: InboundETransferErrors.Generic,
  },
  intent: undefined,
  loadableRetrieveTransfer: createDefaultLoadable(),
  loadableDeclineTransfer: createDefaultLoadable(),
  loadableAcceptTransfer: createDefaultLoadable(),
};

const inboundETransferSlice = createSlice({
  name: 'inboundETransfer',
  initialState: initialState,
  reducers: {
    InboundETransferAnswerUpdate: (
      state,
      action: PayloadAction<{ answer: string }>,
    ) => {
      state.transferForm.securityAnswer = action.payload.answer;
      state.transferForm.showIncorrectAnswerError = false;
    },
    InboundETransferResetFeature: (state) => {
      state.transfer = { ...initialState.transfer };
      state.transferForm.securityAnswer = '';
      state.loadableRetrieveTransfer = createDefaultLoadable();
      state.loadableDeclineTransfer = createDefaultLoadable();
      state.loadableAcceptTransfer = createDefaultLoadable();
    },
    InboundETransferSetSubmissionError: (
      state,
      action: PayloadAction<InboundETransferErrors>,
    ) => {
      state.transferSubmissionError.unAcknowledgedError = true;
      state.transferSubmissionError.error = action.payload;

      if (action.payload === InboundETransferErrors.SubmissionIncorrectAnswer) {
        state.transferForm.showIncorrectAnswerError = true;
        state.transferForm.securityAnswer = '';
      }
    },
    InboundETransferAcknowledgeSubmissionError: (state) => {
      state.transferSubmissionError.unAcknowledgedError = false;
      state.transferSubmissionError.error = InboundETransferErrors.Generic;
    },
    /////////////////////
    // API related reducers/actions below ⬇️
    /////////////////////
    retrieveInboundETransferRequest: (
      state,
      action: PayloadAction<InboundETransferRetrieveRequest>,
    ) => {
      state.loadableRetrieveTransfer = createLoadingLoadable();
    },
    retrieveInboundETransferSuccess: (
      state,
      action: PayloadAction<InboundETransfer>,
    ) => {
      state.transfer = action.payload;
      state.loadableRetrieveTransfer = createSuccessLoadable();
    },
    retrieveInboundETransferError: (
      state,
      action: PayloadAction<InboundETransferRetrieveError>,
    ) => {
      state.loadableRetrieveTransfer = createErrorLoadable(
        'Failed to retrieve transfer',
      );
      state.transferRetrieveError = action.payload.errorType;
    },
    declineInboundETransferRequest: (
      state,
      action: PayloadAction<InboundETransferDeclineRequest>,
    ) => {
      state.loadableDeclineTransfer = createLoadingLoadable();
      state.intent = InboundETransferSubmissionIntent.Decline;
    },
    declineInboundETransferSuccess: (state) => {
      state.loadableDeclineTransfer = createSuccessLoadable();
    },
    declineInboundETransferError: (
      state,
      action: PayloadAction<InboundETransferAcceptDeclineApiError>,
    ) => {
      state.loadableDeclineTransfer = createErrorLoadable(
        'Failed to decline transfer',
      );
    },
    acceptInboundETransferRequest: (
      state,
      action: PayloadAction<InboundETransferAcceptRequest>,
    ) => {
      state.loadableAcceptTransfer = createLoadingLoadable();
      state.intent = InboundETransferSubmissionIntent.Accept;
    },
    acceptInboundETransferSuccess: (state) => {
      state.loadableAcceptTransfer = createSuccessLoadable();
    },
    acceptInboundETransferError: (
      state,
      action: PayloadAction<InboundETransferAcceptDeclineApiError>,
    ) => {
      state.loadableAcceptTransfer = createErrorLoadable(
        'Failed to accept transfer',
      );
    },
  },
});

export const InboundETransferActions = inboundETransferSlice.actions;
export const InboundETransferReducer = inboundETransferSlice.reducer;
