import { useIntl } from 'react-intl';

import { Box, BoxProps } from '@material-ui/core';
import { track } from 'analytics/analytics';
import { theme } from 'theme';

export type PlanOption = 'monthly' | 'annual';

const TierTab = ({
  changeTab,
  label,
  selected,
}: {
  changeTab: () => void;
  label: string;
  selected: boolean;
}) => {
  return (
    <Box
      component={'button'}
      onClick={changeTab}
      boxSizing="border-box"
      sx={{
        border: `1px solid ${
          selected ? theme.colors.greyXLight : theme.colors.greyLight
        }`,
        borderRadius: theme.borderRadius.small,
        background: selected ? theme.colors.greyXLight : theme.colors.white,
        color: selected ? theme.colors.primary : theme.text,
        transition: 'all 0.2s ease-in-out',
        fontWeight: 700,
        fontSize: 16,
        py: 14,
        px: 12,
        width: '100%',
        cursor: 'pointer',
        ':hover': {
          color: theme.colors.primary,
          background: theme.colors.greyXLight,
          border: `1px solid ${theme.colors.greyXLight}`,
        },
      }}
    >
      {label}
    </Box>
  );
};

type PlanTabProps = {
  containerSx?: BoxProps['sx'];
  setTab: (p: PlanOption) => void;
  tab: PlanOption;
};

export const PlanTabs = ({ containerSx, setTab, tab }: PlanTabProps) => {
  const intl = useIntl();
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        gap: '12px',
        justifyContent: 'center',
        width: '100%',
        ...containerSx,
      }}
    >
      <TierTab
        label={intl.formatMessage({
          id: 'TiersTrial.Tabs.monthly',
          defaultMessage: 'Monthly',
        })}
        selected={tab === 'monthly'}
        changeTab={() => {
          setTab('monthly');
          track({
            event: 'Clicked',
            properties: { name: 'tiers-selection-monthly' },
          });
        }}
      />
      <TierTab
        label={intl.formatMessage({
          id: 'TiersTrial.Tabs.yearly',
          defaultMessage: 'Yearly',
        })}
        selected={tab === 'annual'}
        changeTab={() => {
          setTab('annual');
          track({
            event: 'Clicked',
            properties: { name: 'tiers-selection-annual' },
          });
        }}
      />
    </Box>
  );
};
