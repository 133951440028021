import React from 'react';
import { useIntl } from 'react-intl';

import { Theme, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { navigate } from '@reach/router';
import { INbaIncentiveEligibility } from 'apis/tiers';
import nbaLogo from 'assets/images/tiers/nba/nba-logo.png';
import nbaStoreLogo from 'assets/images/tiers/nba/nba-store-logo.png';
import { ButtonUnstyled } from 'components';
import { theme } from 'theme';

type NbaBannerProps = {
  nbaData: INbaIncentiveEligibility;
};

const useStyles = makeStyles<Theme>({
  container: {
    borderRadius: '8px',
  },
  text: {
    color: theme.colors.white,
    fontWeight: 700,
    fontSize: '16px',
    margin: '0px',
  },
  textbox: {
    width: '58%',
    minWidth: '170px',
    paddingRight: '8px',
  },
  contentBox: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    minHeight: '92px',
    backgroundPositionX: 'calc(100% - 3%)',
    backgroundPositionY: 'calc(100% - 85%)',
    backgroundRepeat: 'no-repeat',
  },
});

const NbaGiftCardWidget: React.FC<NbaBannerProps> = ({ nbaData }) => {
  const classes = useStyles();
  const intl = useIntl();
  const muiTheme = useTheme();
  const hasGiftCard = !!nbaData?.reward_link;
  const isAfterTablet = useMediaQuery(muiTheme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(muiTheme.breakpoints.up('sm'));

  return (
    <ButtonUnstyled
      onClick={() => navigate('/nba-incentives')}
      sx={{ width: '100%' }}
    >
      <div
        className={classes.container}
        sx={{
          backgroundColor: hasGiftCard ? '#000' : '#1D428A',
        }}
      >
        <div
          className={classes.contentBox}
          sx={{
            backgroundImage: hasGiftCard
              ? `url(${nbaStoreLogo})`
              : `url(${nbaLogo})`,
            backgroundSize: isTablet && !isAfterTablet ? '18%' : '28%',
          }}
        >
          {hasGiftCard ? (
            <div className={classes.textbox}>
              <p className={classes.text}>
                {intl.formatMessage({
                  defaultMessage:
                    'Your $75 NBAStore.ca e-Gift card is ready! 🏀',
                  id: 'NbaBanner.GiftCardReady',
                })}
              </p>
            </div>
          ) : (
            <div className={classes.textbox}>
              <p className={classes.text}>
                {intl.formatMessage({
                  defaultMessage: 'Want a $75 NBAStore.ca e-Gift card? 🏀',
                  id: 'NbaBanner.GiftCardNotReady',
                })}
              </p>
            </div>
          )}
          <div sx={{ display: 'flex' }}>
            <ArrowForwardIcon
              style={{ fontSize: 24, color: theme.colors.white as string }}
            />
          </div>
        </div>
      </div>
    </ButtonUnstyled>
  );
};

export default NbaGiftCardWidget;
