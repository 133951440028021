export enum ReferralSources {
  Partner = 'partner',
  User = 'referral',
}

export interface ReferralRequest {
  campaign: string;
  source: ReferralSources;
  partner_user_id?: string;
}

export interface ReferralResponse {
  status_code: number;
  status_name: string;
}

export interface ReferralData {
  client_copy: string[];
  expired: boolean;
  campaign_incentives?: {
    trigger_event: string;
    referrer_incentive_id: string;
    referrer_incentive_description: {
      name: string;
      translation_key: string;
      value: string;
    };
    referee_incentive_id: string;
    referee_incentive_description: {
      name: string;
      translation_key: string;
      value: string;
    };
  }[];
  referral_user?: {
    given_name: string;
  };
}

export interface RefereeData {
  name: string;
  referee_id: string;
  status_message: string;
  progress_step: number;
  progress_step_total: number;
  is_expired: boolean;
}
