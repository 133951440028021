export enum PaymentMethodCardNetwork {
  // List of available card networks that can be used to process the payment.
  Amex = 'amex',
  CartesBancaires = 'cartes_bancaires',
  Diners = 'diners',
  Discover = 'discover',
  Interac = 'interac',
  Jcb = 'jcb',
  Mastercard = 'mastercard',
  Unionpay = 'unionpay',
  Unknown = 'unknown',
  Visa = 'visa',
}
