import { IntlShape, MessageDescriptor, defineMessage } from 'react-intl';

import { validLowerCaseRegex, validUpperCaseRegex } from 'components/forms';

import { PasswordRequirement } from '../models';

export type TPasswordValidationResult = {
  valid: boolean;
  message: string;
};

export const scoreLabels: { [key: number]: MessageDescriptor } = {
  0: defineMessage({
    id: 'PasswordLabel.ExtremelyWeak',
    defaultMessage: 'Extremely weak password, try different characters',
  }),
  1: defineMessage({
    id: 'PasswordLabel.Weak',
    defaultMessage: 'Weak password',
  }),
  2: defineMessage({
    id: 'PasswordLabel.Ok',
    defaultMessage: 'OK password',
  }),
  3: defineMessage({
    id: 'PasswordLabel.Good',
    defaultMessage: 'Good password',
  }),
  4: defineMessage({
    id: 'PasswordLabel.Strong',
    defaultMessage: 'Strong password',
  }),
};

export const userInputs = ['KOHO', 'phrase', 'passphrase'];

export const validatePassword = async (
  password: string,
  intl: IntlShape,
): Promise<TPasswordValidationResult> => {
  const result = await import('zxcvbn').then((zxcvbn) => {
    return zxcvbn.default(password, userInputs);
  });
  const valid = result.score > 0 && password.length >= 8;

  let message = '';
  if (password?.length >= 8) {
    message = intl.formatMessage(scoreLabels[result.score]);
  } else {
    message = intl.formatMessage({
      id: 'PasswordLabel.Longer',
      defaultMessage: 'Password needs to be longer',
    });
  }
  return {
    valid,
    message,
  };
};

export const validatePasswordComplexity = (
  password: string,
): PasswordRequirement => {
  return {
    hasMinLength: password.length >= 8,
    hasUpperCase: validUpperCaseRegex.test(password),
    hasLowerCase: validLowerCaseRegex.test(password),
  };
};

export const isPasswordRequirementsMet = (
  passwordRequirements: PasswordRequirement,
) => {
  let isValid = true;
  Object.keys(passwordRequirements).forEach((key) => {
    if (!passwordRequirements[key]) {
      isValid = false;
    }
  });

  return isValid;
};
