import { rootApi } from 'apis/rootApi';
import { BillingAddress } from 'profile/models/address';

export interface CreateHisaAccountRequest {
  citizenships: [{ iso_code: string }];
  employment_status: string;
  employment_detail?: {
    company_name: string;
    company_category: string;
    role: string;
  };
  sin: string;
}

interface ValidateAddressPayload {
  street_address: string;
  unit_number: string;
  city: string;
  province: string;
  postal_code: string;
}

interface HisaAgreement {
  body: string;
  prompt: string;
}

interface HisaResponse {
  is_external_account_created: boolean;
  is_agreement_signed: boolean;
  is_disabled: boolean;
  total_interest_earned: number;
  start_date: string;
  bonus_interest: {
    is_earning_bonus_interest: boolean;
    bonus_interest_percent: number;
  };
  is_account_created: boolean;
  lifetime_earned: number;
  interest_rate: string;
}

export const hisaApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getHISAStatus: build.query<HisaResponse, void>({
      query: () => ({
        url: '1.0/hisa',
        method: 'GET',
      }),
      providesTags: ['HISA'],
    }),
    validateAddress: build.query<void, BillingAddress>({
      query: (address) => ({
        url: '1.0/accounts/validate-hisa-address',
        method: 'POST',
        body: {
          street_address: address.line1,
          unit_number: address.line2,
          city: address.city,
          province: address.province,
          postal_code: address.postal_code,
        } as ValidateAddressPayload,
      }),
    }),
    createHisaAccount: build.mutation<void, CreateHisaAccountRequest>({
      query: (userData) => ({
        url: '1.0/hisa',
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: ['HISA'],
    }),
    getAgreement: build.query<HisaAgreement, void>({
      query: () => ({
        url: '1.0/hisa/agreement',
        method: 'GET',
      }),
    }),
    signAgreement: build.mutation<void, void>({
      query: () => ({
        url: '1.0/hisa/signature',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['HISA', 'Checklist'],
    }),
  }),
});

export const {
  useGetAgreementQuery,
  useValidateAddressQuery,
  useCreateHisaAccountMutation,
  useGetHISAStatusQuery,
  useSignAgreementMutation,
} = hisaApi;
