import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import { useGetCurrentTierQuery } from 'apis/tiers';
import reminderTimeline from 'assets/images/tiers/reminder-timeline_EN.png';
import { Button, Template } from 'components';
import { format } from 'date-fns';
import { theme } from 'theme';

export const WelcomeToPlan = () => {
  const intl = useIntl();

  const {
    data,
    isLoading: getTierIsLoading,
    isUninitialized: getTierInitializing,
    isSuccess: getTierIsSuccess,
  } = useGetCurrentTierQuery();

  useEffect(() => {
    if (getTierIsSuccess && (!data || Number(data.trial_days_remaining) <= 0)) {
      navigate('/transactions', { state: { showWelcomeModal: true } });
    }
  }, [data, getTierIsSuccess]);

  if (getTierIsLoading || getTierInitializing) return <Skeleton />;

  const freeTrialEndDate = format(
    new Date(data?.free_trial_end_date ?? new Date().toISOString()),
    'MMM dd',
  );

  const handleCancelClick = () => {
    track({ event: 'Tiers - Welcome — Continue' });
    navigate('/transactions', { state: { showWelcomeModal: true } });
  };

  return (
    <Template name="Registration - Tiers Welcome">
      <Box
        textAlign="center"
        sx={{
          pt: '80px',
          px: '24px',
          pb: '48px',
          maxWidth: '457px',
          mx: 'auto',
        }}
      >
        <Box sx={{ mb: '48px' }}>
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: '24px',
              color: theme.colors.blueDark,
              '& span': {
                color: theme.colors.orange,
                textTransform: 'uppercase',
              },
            }}
            data-cy="welcome-to-plan-header"
          >
            {intl.formatMessage(
              {
                id: 'WelcomeScreen.Welcome',
                defaultMessage: 'Welcome to KOHO {tier}!',
              },
              {
                tier: <span>{data?.benefit_group?.name}</span>,
              },
            )}
          </Typography>
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: '24px',
              color: theme.colors.blueDark,
              mt: '-4px',
            }}
            data-cy="welcome-to-plan-subheader"
          >
            {intl.formatMessage({
              id: 'WelcomeScreen.Welcome2',
              defaultMessage: 'Your trial has started!',
            })}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              color: theme.colors.blueDark,
              fontWeight: 400,
              '& span': {
                fontWeight: 900,
              },
            }}
            data-cy="welcome-to-plan-reminder"
          >
            {intl.formatMessage(
              {
                id: 'WelcomeScreen.CancelBefore',
                defaultMessage:
                  "Cancel before {date} and you won't be charged.",
              },
              {
                date: <span>{freeTrialEndDate}</span>,
              },
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            borderRadius: '12px',
            border: `1px solid ${theme.colors.lightCyan}`,
            px: '16px',
            py: '24px',
            mb: '48px',
          }}
          data-cy="welcome-to-plan-get-app-container"
        >
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 900,
              color: theme.colors.blueDark,
            }}
          >
            {intl.formatMessage({
              defaultMessage: 'Want to be reminded when your trial expires?',
              id: 'WelcomeScreen.Reminder',
            })}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              color: theme.colors.blueDark,
              fontWeight: 500,
              mb: '24px',
            }}
          >
            {intl.formatMessage({
              defaultMessage: "Download the KOHO app and we'll remind you.",
              id: 'WelcomeScreen.DownloadApp',
            })}
          </Typography>
          <Box display="flex" alignItems="flex-end">
            <Typography
              sx={{
                color: theme.colors.green400,
                fontWeight: 900,
                fontSize: '16px',
                flex: 1,
                textAlign: 'left',
              }}
            >
              {intl.formatMessage({
                defaultMessage: 'Free 30 days',
                id: 'WelcomeScreen.TrialDaysRemaining',
              })}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              flex={1}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 500,
                  color: theme.colors.tiers100,
                }}
              >
                {intl.formatMessage({
                  defaultMessage: 'Plan renews',
                  id: 'WelcomeScreen.PlanRenews',
                })}
              </Typography>
              <Typography
                sx={{
                  fontWeight: '900',
                  color: theme.colors.blueDark,
                  fontSize: '16px',
                }}
              >
                {intl.formatMessage(
                  {
                    defaultMessage: '{renewDate}',
                    id: 'WelcomeScreen.RenewDate',
                  },
                  { renewDate: freeTrialEndDate },
                )}
              </Typography>
            </Box>
          </Box>
          <Box>
            <img
              src={reminderTimeline}
              style={{ maxWidth: '100%' }}
              alt={`${intl.formatMessage({
                id: 'WelcomeScreen.TimeLineAltText',
                defaultMessage: 'Timeline of reminder notifications',
              })}`}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          data-cy="welcome-to-plan-cta-container"
        >
          <Button onClick={handleCancelClick} variant="primary">
            {intl.formatMessage({
              defaultMessage: 'Got it!',
              id: 'WelcomeScreen.Continue',
            })}
          </Button>
        </Box>
      </Box>
    </Template>
  );
};
