import { useIntl } from 'react-intl';

import { Button, Modal, Paragraph, TitleLarge } from 'components';

export const RequestMoneyRequestCodeInfoModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();

  return (
    <Modal open={open} onClose={onClose}>
      <TitleLarge>
        {intl.formatMessage({
          id: 'RequestMoneyRequestCodeInfoModal.Title',
          defaultMessage: 'Request codes',
        })}
      </TitleLarge>
      <Paragraph>
        {intl.formatMessage({
          id: 'RequestMoneyRequestCodeInfoModal.Description',
          defaultMessage:
            'If you have the KOHO app, you can find and pay requests by entering a code. Just tap the ‘Send’ button on your Spend tab, tap the ‘Enter a request code’ banner, and enter the code you see here.',
        })}
      </Paragraph>
      <Button onClick={onClose}>
        {intl.formatMessage({
          id: 'RequestMoneyRequestCodeInfoModal.Button',
          defaultMessage: 'Got it',
        })}
      </Button>
    </Modal>
  );
};
