import CircularProgressTw from 'components/CircularProgressTw';

const LoadingCentered = (): JSX.Element => {
  return (
    <div className="text-center m-2" data-testid="loading-ui--centered">
      <CircularProgressTw className="text-primary-300" />
    </div>
  );
};

export default LoadingCentered;
