import { IBenefitGroup, ICurrentBenefit } from 'apis/tiers';
import { TierKey, tierOrder } from 'tiers/models';

export const isTrialEligible = (tiers: IBenefitGroup[]) =>
  tiers.every((tier) => tier.is_trial_eligible);

export const getTierManagementUrl = ({
  userVerified,
  isPaidTier,
  trialEligible,
}: {
  userVerified: boolean;
  isPaidTier: boolean;
  trialEligible: boolean;
}) => {
  if (!userVerified) return '/kyc/entry';
  if (isPaidTier) return '/tiers/manage';
  if (trialEligible) return '/tiers/trial-selection';
  return '/tiers/upgrade';
};

export const isOnFreeTrial = (currentTier: ICurrentBenefit) =>
  !!currentTier.next_payment_date && !!currentTier.trial_days_remaining;

export const getNextHighestRankedTierKey = (
  currentUsersTier: TierKey | undefined,
) => {
  if (!currentUsersTier) return TierKey.Essential;

  const currentTierIndex = tierOrder.indexOf(currentUsersTier);

  if (currentTierIndex === tierOrder.length - 1)
    return tierOrder[currentTierIndex - 1];
  return tierOrder[currentTierIndex + 1];
};

export const isDowngradingTier = (currentTier: TierKey, newTier: TierKey) => {
  const currentTierIndex = tierOrder.indexOf(currentTier);
  const newTierIndex = tierOrder.indexOf(newTier);

  return newTierIndex < currentTierIndex;
};

export const tierIsCancelled = (currentTier: ICurrentBenefit) =>
  currentTier.status === 'cancelled_scheduled' &&
  !currentTier.delayed_downgrade?.benefit_group;
