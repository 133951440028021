import { useIntl } from 'react-intl';

import { Grid } from '@material-ui/core';
import { useGetReferralsRewardsQuery } from 'apis/referralApi';
import { SpinnerCircularProgress, Template } from 'components';
import { ReferAndEarnShare } from 'referAndEarn/components/ReferAndEarnShare';
import { ReferAndEarnUnlockRewards } from 'referAndEarn/components/ReferAndEarnUnlockRewards';

export const ReferAndEarnPage = () => {
  const intl = useIntl();

  const { data: referralsRewardsData, isSuccess } =
    useGetReferralsRewardsQuery();

  return (
    <Template
      name={intl.formatMessage({
        id: 'ReferAndEarnPage.Title',
        defaultMessage: 'Refer & Earn',
      })}
    >
      {isSuccess ? (
        <Grid container spacing={8} sx={{ p: [24, 24, 48], pt: [0, 0, '8px'] }}>
          <Grid item xs={12} md={8}>
            <ReferAndEarnShare referralsRewardsData={referralsRewardsData} />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReferAndEarnUnlockRewards
              referralsRewardsData={referralsRewardsData}
              canRedeem={referralsRewardsData.gifts > 0}
            />
          </Grid>
        </Grid>
      ) : (
        <SpinnerCircularProgress sx={{ ml: '48px' }} />
      )}
    </Template>
  );
};
