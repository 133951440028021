import { getUnixTime } from 'date-fns';

import { formatDate } from '../utility/formatDate';
import { Profile } from './models/profile';

interface IntercomProfileAttributes {
  email: string;
  phone: string;
  name: string;
  'KYC Cleared': boolean;
  'Year Born': number;
  'KYC Chat Url': string;
  user_hash: string;
  user_id: string;
}

interface IntercomUserAttributes extends IntercomProfileAttributes {
  signed_up_at: number;
  Occupation?: string;
  'Instant Issue'?: boolean;
  Source?: string;
  'Source Code'?: string;
}

/**
 *
 * fix intercom attributes for profile + registation
 */

export const buildIntercomProfileTraits = (
  profile: Profile,
): IntercomProfileAttributes => {
  const dob = profile.dob;
  return {
    email: profile.email,
    phone: profile.tel,
    name: `${profile.given_name} ${profile.family_name}`,
    'KYC Cleared': profile.cleared,
    'Year Born': new Date(formatDate(dob)).getFullYear(),
    'KYC Chat Url': profile.kyc_chat_url,
    user_hash: profile.intercom_web_hmac,
    user_id: profile.reference_identifier,
  };
};

export const buildIntercomUserAttributes = (
  intercomProfileAttributes: IntercomProfileAttributes,
  additionalAttrbutes: {
    Occupation?: string;
    'Instant Issue'?: boolean;
    Source?: string;
    'Source Code'?: string;
  },
): IntercomUserAttributes => {
  const optionalAttributes = Object.keys(additionalAttrbutes).reduce(
    (memo, key) => {
      const attribute = additionalAttrbutes[key];
      if (attribute) {
        memo[key] = attribute;
      }
      return memo;
    },
    {},
  );

  return {
    ...intercomProfileAttributes,
    signed_up_at: getUnixTime(new Date()),
    ...optionalAttributes,
  } as IntercomUserAttributes;
};
