import shajs from 'sha.js';

export const hashEmail = (email: string) => {
  // Strip leading/trailing whitespace + lowercase
  let emailFormatted = email.trim().toLowerCase();
  const emailSplit = emailFormatted.split('@');

  // Only on gmail addresses: Remove any dot in the first part of the address
  if (emailSplit[1].startsWith('gmail') && emailSplit[0].includes('.')) {
    const emailNameFormatted = emailSplit[0].split('.').join('');
    emailFormatted = `${emailNameFormatted}@${emailSplit[1]}`;
  }

  return shajs('sha256').update(emailFormatted).digest('hex');
};
