/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';

import { TextInput, TextInputProps, Validators } from '../../components/forms';

export const TextInputSecretQuestion = (props: TextInputProps) => {
  const intl = useIntl();

  return (
    <TextInput
      label={intl.formatMessage({
        id: 'TextInputSecretQuestion.Label',
        defaultMessage: 'Secret question',
      })}
      name="secretQuestion"
      sx={{ mb: 4 }}
      inputProps={{ maxLength: 40 }}
      validators={[
        Validators.required,
        Validators.minLength(1, intl),
        Validators.maxLength(40, intl),
        Validators.secretQuestion,
      ]}
      {...props}
    />
  );
};
