import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ContactState {
  modalOpen: boolean;
  useOpenChatsEndpointLdVal: boolean;
}

const contactInitialState: ContactState = {
  modalOpen: false,
  useOpenChatsEndpointLdVal: false,
};

const contactSlice = createSlice({
  name: 'contact',
  initialState: contactInitialState,
  reducers: {
    openModal: (state) => {
      state.modalOpen = true;
    },
    closeModal: (state) => {
      state.modalOpen = false;
    },
    intercomOpen: (state) => {
      state.modalOpen = false;
    },
    intercomClose() {},
    forethoughtOpen: (state) => {
      state.modalOpen = false;
    },
    forethoughtClose() {},
    chatButtonClicked() {},
    updateUseOpenChatsEndpointLdVal(state, action: PayloadAction<boolean>) {
      state.useOpenChatsEndpointLdVal = action.payload;
    },
    useOpenChatsEndpoint() {},
  },
});

export const contactActions = contactSlice.actions;
export const contactReducer = contactSlice.reducer;
