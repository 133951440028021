export enum CardProductPackage {
  free = 'free',
  premium = 'premium',
}

export interface CardProductData {
  product_id: number;
  name: string;
  description: string;
  image_url: string;
  available: boolean;
  product_group: string;
  card_design: string;
  package: CardProductPackage;
  colour_palette_url: string;
}
