import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Loadable, createDefaultLoadable } from '../../utility/loadable';
import { Balance } from '../models';

export interface BalanceState {
  data: { [groupIdentifier: string]: Balance } | null;
  loadable: Loadable;
}

const initialState: BalanceState = {
  data: null,
  loadable: createDefaultLoadable(),
};

const balanceSlice = createSlice({
  name: 'balance',
  initialState: initialState,
  reducers: {
    getBalanceRequest: (state) => {
      state.loadable = {
        loading: true,
        success: false,
        error: null,
      };
    },
    getBalanceResponse: (
      state,
      action: PayloadAction<{ [groupIdentifier: string]: Balance }>,
    ) => {
      state.loadable = {
        loading: false,
        success: true,
        error: null,
      };
      state.data = action.payload;
    },
    getBalanceError: (state) => {
      state.loadable = {
        loading: false,
        success: false,
        error: 'Failed to get balance',
      };
    },
    noop: (state) => state,
  },
});

export const balanceActions = balanceSlice.actions;
export default balanceSlice.reducer;
