/** @jsxImportSource theme-ui */
import { Fragment } from 'react';

import List from '@material-ui/core/List';
import { Logo } from 'components/Logo';
import { Flex } from 'theme-ui';

interface Props {
  paths: React.ReactNode[];
}

export function DesktopNav({ paths }: Readonly<Props>) {
  return (
    <Fragment>
      <div
        sx={{
          height: '10%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Logo />
      </div>
      <Flex sx={{ height: '90%', ml: '10%', mr: 0 }}>
        <List
          component="nav"
          aria-label="navigations"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            mb: 4,
          }}
        >
          {paths}
        </List>
      </Flex>
    </Fragment>
  );
}
