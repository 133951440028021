import { track } from 'analytics/analytics';

export const handleCopyValue = (value: string, trackingLabel: string) => {
  track({
    event: 'Clicked',
    properties: {
      name: `Copied ${trackingLabel}`,
    },
  });

  if (window?.navigator && value) {
    window.navigator.clipboard.writeText(value);
  }
};
