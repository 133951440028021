import { useIntl } from 'react-intl';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';

import { Paragraph, TitleLarge } from '../components/TypographyTw';

export const PasswordResetOtpLimitReached = (props: RouteComponentProps) => {
  const intl = useIntl();
  return (
    <>
      <KDSIcons.Spot.Wait />
      <TitleLarge>
        {intl.formatMessage({
          id: 'OtpLimitReached.Title',
          defaultMessage: `Oops! You've tried too many times`,
        })}
      </TitleLarge>
      <Paragraph>
        {intl.formatMessage({
          id: 'OtpLimitReached.Content',
          defaultMessage:
            'Please wait at least 30 minutes before attempting again to reset your password. If you still need assistance, please call our support team at 1-855-564-6999.',
        })}
      </Paragraph>
    </>
  );
};
