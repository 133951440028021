import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useNavigate } from '@reach/router';
import { AddFundsFeatureFlags } from 'addFunds/feature-flags';
import { track } from 'analytics/analytics';
import transferIcon from 'assets/images/kds_icons/icons/transfer.svg';
import idIcon from 'assets/images/kds_icons/navigation/spending.svg';
import interac from 'assets/images/logos/interac-logo.png';
import {
  Box,
  ButtonUnstyled,
  Paragraph,
  Template,
  TitleLarge,
  TitleMedium,
} from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectProfileVerified } from 'profile/store/selectors';
import { theme } from 'theme';

function Card({ children }) {
  return (
    <Box variant="shadow" sx={{ p: 3, mb: 3 }}>
      {children}
    </Box>
  );
}

function CardHeader({ children }) {
  return (
    <TitleMedium sx={{ mt: 0, mb: 1, fontSize: '18px' }}>
      {children}
    </TitleMedium>
  );
}

function CardContent({ children }) {
  return <Paragraph sx={{ m: 0 }}>{children}</Paragraph>;
}

export const AddMoneyMethods = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const userIsVerified = useSelector(selectProfileVerified);

  const debitCardEnabled =
    useFlags()[AddFundsFeatureFlags.DebitCardLoadingEnabled];

  function handleETransferClick(to: string) {
    track({
      event: 'Clicked',
      properties: { name: 'eTransfer', to, element: 'button', type: 'button' },
    });
    navigate(to);
  }

  function handleDDClick(to: string) {
    track({
      event: 'Clicked',
      properties: {
        name: 'direct-deposit',
        to,
        element: 'button',
        type: 'button',
      },
    });

    if (!userIsVerified) {
      navigate('/kyc/entry');
    } else {
      navigate(to);
    }
  }

  function handleDebitClick(to: string) {
    track({
      event: 'Clicked',
      properties: {
        name: 'debit-card-load',
        to,
        element: 'button',
        type: 'button',
      },
    });

    if (debitCardEnabled) {
      if (!userIsVerified) {
        navigate('/kyc/entry');
      } else {
        navigate(to);
      }
    }
  }

  return (
    <Template name="AddMoneyMethods" variant="center">
      <TitleLarge>
        {intl.formatMessage({
          id: 'AddMoneyMethods.AddMoney.Title',
          defaultMessage: 'Add money',
        })}
      </TitleLarge>

      {/* e-Transfer */}
      <ButtonUnstyled onClick={() => handleETransferClick('/load/e-transfer')}>
        <Card>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <img
              src={interac}
              alt="Interac logo"
              sx={{ width: '33px', height: '33px' }}
            />

            <Box sx={{ pl: 3 }}>
              <CardHeader>
                {intl.formatMessage({
                  id: 'AddMoneyMethods.eTransfer.Title',
                  defaultMessage: 'e-Transfer from your bank',
                })}
              </CardHeader>
              <CardContent>
                {intl.formatMessage({
                  id: 'AddMoneyMethods.eTransfer.Description',
                  defaultMessage:
                    'Securely transfer funds and get it within 30 minutes.',
                })}
              </CardContent>
            </Box>
          </Box>
        </Card>
      </ButtonUnstyled>

      {/* Direct Deposit */}
      <ButtonUnstyled onClick={() => handleDDClick('/load/direct-deposit')}>
        <Card>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '36px',
                height: '36px',
                backgroundColor: theme.colors.greyXLight,
                borderRadius: '4px',
              }}
            >
              <Box sx={{ width: '24px', height: '24px' }}>
                <img src={transferIcon} alt="Money transfer" />
              </Box>
            </Box>

            <Box sx={{ pl: 3 }}>
              <CardHeader>
                {intl.formatMessage({
                  id: 'AddMoneyMethods.DirectDeposit.Title',
                  defaultMessage: 'Direct deposit',
                })}
              </CardHeader>
              <CardContent>
                {intl.formatMessage({
                  id: 'AddMoneyMethods.DirectDeposit.Description',
                  defaultMessage:
                    'Your paycheque or government benefits, and get it within 1–5 days.',
                })}
              </CardContent>
            </Box>
          </Box>
        </Card>
      </ButtonUnstyled>

      {debitCardEnabled || (
        <TitleLarge sx={{ mt: 4 }}>
          {intl.formatMessage({
            id: 'AddMoneyMethods.ComingSoon.Title',
            defaultMessage: 'Coming soon...',
          })}
        </TitleLarge>
      )}

      {/* Debit Card (Stripe) */}
      <ButtonUnstyled onClick={() => handleDebitClick('/load/debit-card')}>
        <Card>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '36px',
                height: '36px',
                backgroundColor: theme.colors.greyXLight,
                borderRadius: '4px',
              }}
            >
              <Box sx={{ width: '24px', height: '24px' }}>
                <img src={idIcon} alt="Debit card" />
              </Box>
            </Box>
            <Box sx={{ pl: 3 }}>
              <CardHeader>
                {intl.formatMessage({
                  id: 'AddMoneyMethods.DebitCard.Title',
                  defaultMessage: 'Debit card',
                })}
              </CardHeader>
              <CardContent>
                {intl.formatMessage({
                  id: 'AddMoneyMethods.DebitCard.Description',
                  defaultMessage:
                    'Instantly add money by paying with a Mastercard or Visa debit card.',
                })}
              </CardContent>
            </Box>
          </Box>
        </Card>
      </ButtonUnstyled>
    </Template>
  );
};
