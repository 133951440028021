import { useIntl } from 'react-intl';

interface Metadata {
  ctaText: string;
  description: string | React.ReactNode;
  img: { url: string };
  title: string;
}

export const useUpgradeMetadata = (
  _intendedUse: string | undefined,
): Metadata => {
  const intl = useIntl();

  const ctaText = intl.formatMessage({
    id: 'UpgradePlanWidget.default.Button',
    defaultMessage: 'Get plan',
  });

  const description = intl.formatMessage(
    {
      id: 'UpgradePlanWidget.default.Content',
      defaultMessage:
        '<boldText>You could be earning more</boldText> cash back, interest, and discounts on credit products.',
    },
    {
      boldText: (text: string) => boldText(text),
    },
  );

  const img = {
    url: `${import.meta.env.VITE_STATIC_URL}/widgets/get_plan.jpg`,
  };

  const title = intl.formatMessage({
    id: 'UpgradePlanWidget.default.Title',
    defaultMessage: 'Want to earn even more?',
  });

  return {
    ctaText,
    description,
    img,
    title,
  };
};

// TODO - move to shared
const boldText = (text: string) => <strong>{text}</strong>;
