/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import { Box, Dialog, DialogContent } from '@material-ui/core';
import { Button, Paragraph, TitleSmall } from 'components';

export interface ConfirmDeclinePromptProps {
  isOpen: boolean;
  handleNoAndClose: () => void;
  handleYesAndClose: () => void;
}

export const ConfirmDeclinePrompt: React.FC<ConfirmDeclinePromptProps> = ({
  isOpen,
  handleNoAndClose,
  handleYesAndClose,
}) => {
  const intl = useIntl();

  return (
    <Dialog
      open={isOpen}
      onClose={handleNoAndClose}
      aria-labelledby="iet-confirm-prompt-title"
      aria-describedby="iet-confirm-prompt-description"
      data-cy="iet-confirm-prompt"
    >
      <DialogContent>
        <div id="iet-alert-dialog-title">
          <TitleSmall sx={{ mb: 3, mt: 0 }}>
            {intl.formatMessage({
              id: 'InboundETransferPage.ConfirmDeclinePrompt.Heading',
              defaultMessage: 'Decline Transfer',
            })}
          </TitleSmall>
        </div>
        <Paragraph
          sx={{ mt: 0 }}
          id="iet-confirm-prompt-description"
          data-cy="iet-confirm-prompt-message"
        >
          {intl.formatMessage({
            id: 'InboundETransferPage.ConfirmDeclinePrompt.Message',
            defaultMessage: 'Are you sure you want to decline transfer?',
          })}
        </Paragraph>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: '2',
          }}
        >
          <Button
            onClick={handleNoAndClose}
            sx={{ mb: 2 }}
            data-cy="iet-confirm-prompt-cta-reject"
            variant={'secondary'}
          >
            {intl.formatMessage({
              id: 'InboundETransferPage.ConfirmDeclinePrompt.Reject',
              defaultMessage: `No`,
            })}
          </Button>
          <Button
            onClick={handleYesAndClose}
            sx={{ mb: 2 }}
            data-cy="iet-confirm-prompt-cta-confirm"
          >
            {intl.formatMessage({
              id: 'InboundETransferPage.ConfirmDeclinePrompt.Confirm',
              defaultMessage: `Yes, Decline`,
            })}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
