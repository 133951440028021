import { validPOBoxRegex, validPostalCodeRegex } from 'components/forms';
import { InputValidationMsg } from 'components/forms/useValidationMsg';
import { z } from 'zod';

export const zodFormRegistrationAddress = z.object({
  addressLine1: z.string().min(1, {
    message: InputValidationMsg.ValidStreetAddressRequired,
  }),
  addressLine2: z.string(),
  city: z.string().min(1, {
    message: InputValidationMsg.ValidCityRequired,
  }),
  province: z.string().min(1, {
    message: InputValidationMsg.FieldRequired,
  }),
  postalCode: z
    .string()
    .min(1, {
      message: InputValidationMsg.InvalidPostalCode,
    })
    .regex(validPostalCodeRegex, {
      message: InputValidationMsg.InvalidPostalCode,
    }),
});

export const zodFormRegistrationAddressHome = zodFormRegistrationAddress.extend(
  {
    addressLine1: z
      .string()
      .min(1, {
        message: InputValidationMsg.ValidStreetAddressRequired,
      })
      .refine((data) => !validPOBoxRegex.test(data), {
        message: InputValidationMsg.POBoxNotAllowed,
      }),
    addressLine2: z
      .string()
      .refine((data) => !validPOBoxRegex.test(data), {
        message: InputValidationMsg.POBoxNotAllowed,
      })
      .optional(),
  },
);

export type AddressFormSchema = z.infer<typeof zodFormRegistrationAddress>;
