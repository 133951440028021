import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import { IBenefitGroup, IPlan, useGetTiersQuery } from 'apis/tiers';
import { useGetProfileQuery } from 'apis/users';
import { LayoutBasic } from 'components';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph } from 'components/TypographyTw';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RegistrationFeatureFlags } from 'registration/models/RegistrationFeatureFlags';
import { HelpAndAccessibility } from 'support/components';
import { CancelAnytime } from 'tiers/components/CancelAnytime';
import { Tiers } from 'tiers/components/Tiers';
import { tiersActions } from 'tiers/store/slice';

import { useLaunchDarkly } from 'libs/launchdarkly/hooks/useLaunchDarkly';
import { selectLaunchDarklyFlagsIsLoading } from 'libs/launchdarkly/store/selectors';

import { useTiersData } from '../hooks/useTiersData';

export const TierSelectionPage = () => {
  useLaunchDarkly();

  const { getHeaderText } = useTiersData();
  const intl = useIntl();
  const ldFlagsIsLoading = useSelector(selectLaunchDarklyFlagsIsLoading);
  const dispatch = useDispatch();

  const isSelectTiersScreenEnabled =
    useFlags()[RegistrationFeatureFlags.EnableTiersSelection];

  const isAlwaysRedirectToKycEnabled =
    useFlags()[RegistrationFeatureFlags.AlwaysRedirectToKYC];

  const {
    data: getTiersData,
    isLoading: getTiersIsLoading,
    isUninitialized: getTiersIsUninitialized,
    isSuccess: getTiersIsSuccess,
    isError: getTiersIsError,
  } = useGetTiersQuery();

  const { data: profileData, isLoading: profileIsLoading } =
    useGetProfileQuery();

  if (
    getTiersIsUninitialized ||
    getTiersIsLoading ||
    profileIsLoading ||
    ldFlagsIsLoading
  ) {
    return <Skeleton variant="rect" />;
  }

  if (isSelectTiersScreenEnabled === false) {
    navigate('/transactions', { state: { showWelcomeModal: true } });
  }

  if (
    (getTiersIsSuccess && (!getTiersData || getTiersData.length === 0)) ||
    getTiersIsError
  ) {
    navigate('/transactions', { state: { showWelcomeModal: true } });
  }

  const onMaybeLaterClicked = () => {
    if (isAlwaysRedirectToKycEnabled) {
      navigate('/kyc/entry');
    } else {
      navigate('/transactions', { state: { showWelcomeModal: true } });
    }
  };

  return (
    <LayoutBasic pageName="Registration - Tier Selection" chat={false}>
      {getTiersIsLoading ? (
        <Skeleton variant="rect" />
      ) : (
        <BoxTw className="flex flex-col justify-center pb-0 mb-16">
          <h1
            className="md:text-3xl text-2xl legacy:max-w-[420px] light:max-w-[500px] mx-auto font-black text-tiers-blue-400 light:text-primary-300 text-center mb-6 [&_span]:text-secondary-300 light:[&_span]:text-accent-200 light:font-heading light:uppercase light:px-4"
            data-cy="tier-selection-header"
          >
            {getHeaderText(profileData?.profile.intended_use)}
          </h1>
          <Paragraph
            className="text-base text-center mb-8"
            data-cy="tier-selection-reminder"
          >
            🔔{' '}
            {intl.formatMessage({
              id: 'TierSelectionPage.TrialReminder',
              defaultMessage: "We'll remind you before your trial ends.",
            })}
          </Paragraph>
          {getTiersData && (
            <Tiers
              registrationFlow
              boxClassName="mb-[35px]"
              tiers={getTiersData}
              isFree
              onPlanChange={(tier: IBenefitGroup, plan: IPlan | undefined) => {
                track({ event: `Tiers - Selection — CTA - ${tier.key}` });
                tier.plans &&
                  plan &&
                  dispatch(
                    tiersActions.setDesiredTier({
                      benefit_group_id: tier.id,
                      benefit_group_plan_id: plan.id,
                    }),
                  );
                navigate('/kyc/entry');
              }}
              cardTrackName="tiers-selection-card"
            />
          )}
          <ButtonTw
            onClick={onMaybeLaterClicked}
            data-cy="tier-selection-maybe-later-button"
            trackName="Tiers  - Selection — Decline"
            variant="clear"
          >
            {intl.formatMessage({
              id: 'TierSelectionPage.MaybeLater',
              defaultMessage: 'Maybe later',
            })}
          </ButtonTw>
          <CancelAnytime />
        </BoxTw>
      )}

      <HelpAndAccessibility />
    </LayoutBasic>
  );
};
