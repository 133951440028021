import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { BROWSER_LOCALE } from '../components/I18NContextProvider';

export const DEVICE_ID_KEY = 'x-device-id';

export function getDeviceID(): string {
  let deviceID = localStorage.getItem(DEVICE_ID_KEY);
  if (!deviceID) {
    deviceID = uuidv4();
    localStorage.setItem(DEVICE_ID_KEY, deviceID);
  }

  return deviceID;
}

export function setRequestHeaders(): void {
  axios.defaults.headers.common['x-koho-app-version'] =
    import.meta.env.VITE_APP_VERSION_HEADER;
  axios.defaults.headers.common['x-koho-device-platform'] =
    import.meta.env.VITE_DEVICE_PLATFORM_HEADER;
  axios.defaults.headers.common['X-Organization'] =
    import.meta.env.VITE_DEVICE_ORGANIZATION_HEADER;
  axios.defaults.headers.common[DEVICE_ID_KEY] = getDeviceID();
}

export function resetRequestHeaders(): void {
  delete axios.defaults.headers.common['x-koho-app-version'];
  delete axios.defaults.headers.common['x-koho-device-platform'];
  delete axios.defaults.headers.common['X-Organization'];
  delete axios.defaults.headers.common['x-device-id'];
  axios.defaults.withCredentials = false;
}

export function setRequestLanguageHeader(language?: string): void {
  axios.defaults.headers.common['Accept-Language'] = language || BROWSER_LOCALE;
}

export function AxiosInit() {
  setRequestHeaders();
  axios.defaults.withCredentials = true;
}
