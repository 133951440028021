import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Switch,
  makeStyles,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Skeleton } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetCardImageQuery,
  useGetCardsQuery,
  useLockCardMutation,
} from 'apis/cards';
import { KDSIcons } from 'assets/images/kds_icons';
import { CardStatusIndicator } from 'cards/components/CardStatusIndicator';
import {
  Box,
  Button,
  ButtonUnstyled,
  Link,
  LocaleContext,
  Paragraph,
  ParagraphSmall,
  SubtitleMedium,
  Template,
  TitleLarge,
  TitleSmall,
} from 'components';
import DownloadApp from 'features/modals/DownloadApp';
import { theme } from 'theme';

const useStyles = makeStyles({
  // page styles
  accordionRoot: {
    padding: 0,
    boxShadow: 'none',
    marginTop: '12px',
    '&:before': {
      content: 'none',
    },
  },
  noPadding: {
    padding: 0,
    margin: 0,
  },
  details: {
    padding: 0,
    display: 'block',
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: '1px solid #EFEFEF',
  },
});

export const CardDetails = (props: { id: string }) => {
  const intl = useIntl();
  const classes = useStyles();
  const context = useContext(LocaleContext);

  const { data: cards, isLoading } = useGetCardsQuery();

  const card = cards?.find((card) => card.card_id === props.id);

  useEffect(() => {
    // handle case when page is refreshed
    if (!card) navigate('/cards');
  }, [card]);

  const { data: imageUrl } = useGetCardImageQuery(card?.card_id ?? skipToken);

  const [toggleCard, toggleRequest] = useLockCardMutation();

  const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);

  const showCardNumber =
    card?.card_status === 'active' || card?.card_status === 'locked';

  const inTransit =
    card?.card_status === 'waiting for payment' ||
    card?.card_status === 'shipped';

  return (
    <Template variant="center" name="Card Details">
      <div>
        <ButtonUnstyled
          onClick={() => window.history.back()}
          sx={{ ml: '-4px' }}
        >
          <KDSIcons.Icons.ArrowRight style={{ transform: 'rotate(180deg)' }} />
        </ButtonUnstyled>
      </div>

      <TitleLarge sx={{ mb: 5 }}>
        {card?.is_virtual
          ? intl.formatMessage({
              id: 'MyCards.CardLink.VirtualCard',
              defaultMessage: 'Virtual card',
            })
          : intl.formatMessage({
              id: 'MyCards.CardLink.PhysicalCard',
              defaultMessage: 'Physical card',
            })}
      </TitleLarge>

      {card && (
        <>
          <Box
            sx={{
              /**
               * We use a little trick here with relative/absolute positioning and fixed width/height.
               * This is to ensure that the skeleton and the image have the same dimensions while the
               * card image renders. We're pulling the card image from galileo, remember, so we can't
               * hide the skeleton as soon as we have the image URL. Doing so would cause this wrapping
               * Box to collapse because its size isn't known until the full image downloads. So what
               * we're doing here is fixing the size of the skeleton so the Box doesn't collapse, and
               * rendering the image as a relative-absolute element, covering the skeleton rather than
               * replacing it.
               */
              p: 4,
              borderRadius: '14px',
              backgroundColor: theme.colors.blueNavy,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              position: 'relative',
              mb: 2,
            }}
          >
            <Skeleton
              variant="rect"
              sx={{
                display: 'block',
                borderRadius: 16,
                width: '70vw',
                maxWidth: '386px',
                maxHeight: '244px',
                height: '44vw',
              }}
            />
            {imageUrl && (
              <Box
                sx={{
                  position: 'absolute',
                  width: '70vw',
                  maxWidth: '386px',
                  maxHeight: '244px',
                  height: '44vw',
                }}
              >
                <img
                  alt="Your KOHO card"
                  src={showCardNumber ? imageUrl : card.image_url}
                  style={{
                    borderRadius: 16,
                    width: '100%',
                  }}
                />
                {inTransit && (
                  <Paragraph
                    sx={{
                      borderRadius: '4px',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      position: 'absolute',
                      left: '0',
                      top: '0',
                      marginLeft: 4,
                      px: 2,
                      py: 1,
                      color: 'white',
                      fontSize: 1,
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    <KDSIcons.Icons.CreditCardBold sx={{ mr: 2 }} />
                    <CardStatusIndicator color="inherit" card={card} />
                  </Paragraph>
                )}
              </Box>
            )}
          </Box>

          {inTransit && (
            <>
              <TitleLarge sx={{ mb: 0 }}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.Ordered',
                  defaultMessage: 'Your card is being delivered',
                })}
              </TitleLarge>
              <Paragraph sx={{ color: theme.colors.primary }}>
                <CardStatusIndicator card={card} />
              </Paragraph>
            </>
          )}

          {inTransit && (
            <Box>
              <TitleSmall>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.NextSteps',
                  defaultMessage: 'Next steps',
                })}
              </TitleSmall>
              <StepRow icon={KDSIcons.Icons.Email}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.YourCardWillArrive',
                  defaultMessage: 'Your card will arrive in the mail',
                })}
              </StepRow>
              <StepRow icon={KDSIcons.Premium.Card}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.ActivateYourCard',
                  defaultMessage: 'Set your PIN and activate your card',
                })}
              </StepRow>
              <StepRow icon={KDSIcons.Icons.Bills}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.MakeAPurchase',
                  defaultMessage: 'Make a purchase and you’re done',
                })}
              </StepRow>
            </Box>
          )}

          {!inTransit && (
            <Box
              sx={{
                py: 3,
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '1px solid #EFEFEF',
              }}
            >
              <div>
                <Paragraph sx={{ my: 1, fontWeight: 700 }}>
                  {intl.formatMessage({
                    id: 'MyCards.CardLink.LockCard',
                    defaultMessage: 'Lock card',
                  })}
                </Paragraph>
                <ParagraphSmall>
                  {intl.formatMessage({
                    id: 'MyCards.CardLink.LockCardDescription',
                    defaultMessage: 'Instantly freeze your card from use.',
                  })}
                </ParagraphSmall>
              </div>
              <Switch
                color="primary"
                disabled={toggleRequest.isLoading || isLoading}
                checked={card.is_locked}
                onChange={() =>
                  toggleCard({ id: card.card_id, lock: !card.is_locked })
                }
              />
            </Box>
          )}

          {!inTransit && (
            <Box
              sx={{
                py: 2,
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '1px solid #EFEFEF',
              }}
            >
              <Paragraph sx={{ fontWeight: 700 }}>
                {card.is_virtual ? 'Need a replacement?' : 'Lost or stolen?'}
              </Paragraph>
              <ButtonUnstyled onClick={() => setDownloadModalOpen(true)}>
                <Paragraph sx={{ color: theme.colors.primary, fontWeight: 4 }}>
                  {card.is_virtual ? 'Request one' : 'Request a new card'}
                </Paragraph>
              </ButtonUnstyled>
            </Box>
          )}

          {card.is_virtual && !inTransit && (
            <Box
              sx={{
                py: 2,
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '1px solid #EFEFEF',
              }}
            >
              <Paragraph sx={{ fontWeight: 700 }}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.VirtualCard',
                  defaultMessage: 'What is a virtual card?',
                })}
              </Paragraph>
              <Paragraph sx={{ color: theme.colors.primary }}>
                <Link
                  external={true}
                  trackName="what-is-a-virtual-card-link"
                  to={`https://help.koho.ca/${context.locale}/articles/2145453-what-is-a-virtual-card`}
                >
                  {intl.formatMessage({
                    id: 'MyCards.CardLink.LearnMore',
                    defaultMessage: 'Learn more',
                  })}
                </Link>
              </Paragraph>
            </Box>
          )}

          {!card.is_virtual && inTransit && (
            <Button onClick={() => setDownloadModalOpen(true)} sx={{ mt: 4 }}>
              {intl.formatMessage({
                id: 'MyCards.CardLink.ActivateCard',
                defaultMessage: 'Activate card',
              })}
            </Button>
          )}
        </>
      )}

      {!inTransit && (
        <div>
          <Accordion
            classes={{ root: classes.accordionRoot }}
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon sx={{ color: 'black' }} />}
              classes={{
                root: classes.noPadding,
                content: classes.noPadding,
                expanded: classes.noPadding,
              }}
            >
              <div>
                <TitleSmall sx={{ mb: 1, fontWeight: 700 }}>
                  {intl.formatMessage({
                    id: 'MyCards.CardLink.AddToWallet',
                    defaultMessage: 'Add your card to a digital wallet',
                  })}
                </TitleSmall>
                <SubtitleMedium>
                  {intl.formatMessage({
                    id: 'MyCards.CardLink.TapYourPhone',
                    defaultMessage: 'Tap your phone to make purchases.',
                  })}
                </SubtitleMedium>
              </div>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.details }}>
              <AccordionRow icon={KDSIcons.Icons.Share}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.OpenYourDigitalWallet',
                  defaultMessage:
                    'Open your digital wallet (Apple, Google, etc.)',
                })}
              </AccordionRow>
              <AccordionRow icon={KDSIcons.Icons.Add}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.AddYourCard',
                  defaultMessage: 'Click to add a new card',
                })}
              </AccordionRow>
              <AccordionRow icon={KDSIcons.Icons.CreditCard}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.EnterYourVirtualCardDetails',
                  defaultMessage:
                    'Enter your virtual card details (number, expiry)',
                })}
              </AccordionRow>
              <AccordionRow icon={KDSIcons.Icons.Question}>
                {intl.formatMessage({
                  id: 'MyCards.CardLink.NeedMoreHelp',
                  defaultMessage: 'Need more help?',
                })}
                &nbsp;
                <Link
                  external={true}
                  trackName="what-is-a-virtual-card-link"
                  to={`https://help.koho.ca/${context.locale}/articles/2145453-what-is-a-virtual-card#h_dc628035ce`}
                >
                  {intl.formatMessage({
                    id: 'MyCards.CardLink.LearnMoreHere',
                    defaultMessage: 'Learn more here.',
                  })}
                </Link>
              </AccordionRow>
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      <DownloadApp
        onClose={() => setDownloadModalOpen(false)}
        open={downloadModalOpen}
      />
    </Template>
  );
};

function StepRow({ icon, children }) {
  const Icon = icon;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: theme.colors.greyXLight,
          borderRadius: 4,
          p: 2,
        }}
      >
        <Icon sx={{ color: theme.colors.primary }} />
      </Box>
      <Paragraph sx={{ ml: 3 }}>{children}</Paragraph>
    </Box>
  );
}

function AccordionRow({ icon, children }) {
  const Icon = icon;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon />
      <Paragraph sx={{ lineHeight: '24px', ml: 3, my: 2, flex: 1 }}>
        {children}
      </Paragraph>
    </Box>
  );
}
