import React from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '@material-ui/core';
import {
  ReferralReward,
  ReferralRewardsResponse,
  RewardTypes,
  useClaimRewardMutation,
} from 'apis/referralApi';
import { KDSIcons } from 'assets/images/kds_icons';
import {
  Box,
  Button,
  ButtonLinkExternal,
  Paragraph,
  TitleLarge,
  TitleSmall,
} from 'components';
import { KhChip } from 'components/kds/KhChip';
import { theme } from 'theme';

import { RewardItemInfoModal } from './RewardItemInfoModal';

export const ReferAndEarnUnlockRewards = ({
  referralsRewardsData,
  canRedeem,
}: {
  referralsRewardsData: ReferralRewardsResponse;
  canRedeem: boolean;
}) => {
  const intl = useIntl();

  const [infoModalReward, setInfoModalReward] =
    React.useState<ReferralReward | null>(null);

  const [claimReward] = useClaimRewardMutation();

  const claimRewardClicked = (rewardId: string) => {
    claimReward(rewardId)
      .unwrap()
      .then(() => {
        // open success modal
      })
      .catch(() => {
        // open error modal
      });
  };

  return (
    <>
      <TitleLarge>
        {intl.formatMessage({
          id: 'ReferAndEarnUnlockRewards.Title',
          defaultMessage: 'Unlock Rewards',
        })}
      </TitleLarge>
      {referralsRewardsData?.rewards && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          {referralsRewardsData.rewards.map((reward) => (
            <Box
              key={reward.id}
              sx={{
                boxShadow: '3px 6px 15px 0px rgba(0, 0, 0, 0.10)',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage:
                    'linear-gradient(to right, #2f3ccf, #f36350)',
                  display: !reward?.auto_redeem ? 'none' : 'block',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    left: '2px',
                    top: '2px',
                    width: 'calc(100% - 4px)',
                    height: 'calc(100% - 4px)',
                    borderRadius: '6px',
                    bg: 'white',
                    zIndex: 0,
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  zIndex: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                  p: 16,
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TitleSmall sx={{ my: 0 }}>{reward.name}</TitleSmall>
                    {reward.new ? (
                      <KhChip decoration="stars" type="light" className="ml-2">
                        {intl.formatMessage({
                          id: 'ReferAndEarnUnlockRewards.Reward.New',
                          defaultMessage: 'New!',
                        })}
                      </KhChip>
                    ) : null}
                  </Box>
                  {reward.limited_time_offer ? (
                    <KhChip
                      type="light"
                      icon={
                        <KDSIcons.Icons.TimeBold
                          sx={{
                            color: theme.colors.primary,
                            marginRight: '4px',
                          }}
                        />
                      }
                    >
                      {intl.formatMessage({
                        id: 'ReferAndEarnUnlockRewards.Reward.LimitedOffer',
                        defaultMessage: 'Limited offer!',
                      })}
                    </KhChip>
                  ) : reward.reward_type !== RewardTypes.Cash ? (
                    <IconButton onClick={() => setInfoModalReward(reward)}>
                      <KDSIcons.Icons.QuestionBold
                        color={theme.colors.primary}
                      />
                    </IconButton>
                  ) : null}
                </Box>
                {reward.value &&
                reward.discounted_value &&
                reward.reward_type !== RewardTypes.Cash ? (
                  <Box sx={{ display: 'flex' }}>
                    <Paragraph
                      sx={{
                        my: 0,
                        mr: 1,
                        color: theme.colors.midGrey,
                        textDecoration: 'line-through',
                        display: 'inline-block',
                      }}
                    >
                      {reward.value}
                    </Paragraph>
                    <Paragraph
                      sx={{
                        my: 0,
                        fontWeight: '900',
                        display: 'inline-block',
                        color: 'successful',
                      }}
                    >
                      {reward.discounted_value}
                    </Paragraph>
                  </Box>
                ) : null}
                <Paragraph sx={{ my: 0 }}>{reward.description}</Paragraph>
                {reward.cta_button ? (
                  <ButtonLinkExternal
                    href={reward.cta_button.cta_link}
                    trackName="referral-cta-button"
                    trackMetadata={{ rewardId: reward.id }}
                    variant="outline"
                  >
                    {reward.cta_button.cta_copy}
                  </ButtonLinkExternal>
                ) : (
                  <Button
                    disabled={!canRedeem}
                    onClick={() => claimRewardClicked(reward.id)}
                    trackName="referral-cta-button"
                    trackMetadata={{ rewardId: reward.id }}
                  >
                    {canRedeem ? (
                      intl.formatMessage({
                        id: 'ReferAndEarnUnlockRewards.Reward.CTA',
                        defaultMessage: 'Redeem',
                      })
                    ) : (
                      <>
                        <KDSIcons.Icons.LockDetailed
                          width={16}
                          height={16}
                          sx={{
                            color: 'white',
                            marginRight: '10px',
                            marginBottom: '-2px',
                          }}
                        />
                        {intl.formatMessage({
                          id: 'ReferAndEarnUnlockRewards.Reward.CTA.Disabled',
                          defaultMessage: 'Refer to redeem',
                        })}
                      </>
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <RewardItemInfoModal
        open={infoModalReward !== null}
        onClose={() => setInfoModalReward(null)}
        canRedeem={canRedeem}
        onRedeemClicked={claimRewardClicked}
        reward={infoModalReward}
      />
    </>
  );
};
