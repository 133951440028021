import { differenceInYears, format } from 'date-fns';
import shajs from 'sha.js';

import { formatDate } from '../utility/formatDate';
import { Profile } from './models/profile';

// will this be used anywhere? TBD
export const ProvinceNameMap: { [s: string]: string } = {
  'British Columbia': 'BC',
  Alberta: 'AB',
  Manitoba: 'MB',
  'New Brunswick': 'NB',
  Newfoundland: 'NL', // DEPRECATED?
  'Nova Scotia': 'NS',
  'Northwest Territories': 'NT',
  Ontario: 'ON',
  'Prince Edward Island': 'PE',
  Quebec: 'QC',
  Saskatchewan: 'SK',
  Yukon: 'YT',
  Nunavut: 'NU',
  'Colombie-Britannique': 'BC',
  'Nouveau-Brunswick': 'NB',
  'Newfoundland and Labrador': 'NL',
  'Terre-Neuve-et-Labrador': 'NL',
  'Territoires du Nord-Ouest': 'NT',
  'Nouvelle-Écosse': 'NS',
  'Île-du-Prince-Édouard': 'PE',
  Québec: 'QC',
};

interface UserProfileTraits {
  hashId: string;
  firstName: string;
  lastName: string;
  email: string;
  isActivated: boolean;
  cleared: boolean;
  address: {
    unitNumber: string;
    street: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
  };
  age: number;
  birthday: string;
  phone: string;
  createdAt: string;
  updatedOn: string;
  hasAccessCode: boolean;
  verifiedEmail: boolean;
  notActivatedReasons: string[];
  'Year Born': number;
}

export const buildUserProfileTraits = (profile: Profile): UserProfileTraits => {
  const hashId = profile.reference_identifier
    ? shajs('sha256').update(profile.reference_identifier).digest('hex')
    : '';

  const dob = profile.dob;
  return {
    hashId,
    firstName: profile.given_name,
    lastName: profile.family_name,
    email: profile.email,
    isActivated: profile.is_activated,
    cleared: profile.cleared,
    address: {
      unitNumber: profile.unit_number,
      street: profile.street_address,
      city: profile.locality,
      postalCode: profile.postal_code,
      state: ProvinceNameMap[profile.region],
      country: 'Canada',
    },
    age: differenceInYears(new Date(), new Date(formatDate(dob))),
    birthday: format(new Date(formatDate(dob)), 'yyyy-MM-dd'),
    phone: profile.tel,
    createdAt: format(new Date(), 'yyyy-MM-dd'),
    updatedOn: format(
      new Date(formatDate(profile.updated_on)),
      "yyyy-MM-dd'T'HH:mm:ss.SSSSSS",
    ),
    hasAccessCode: profile.has_access_code,
    verifiedEmail: profile.verified_email,
    notActivatedReasons: profile.reason_not_activated,
    'Year Born': new Date(formatDate(dob)).getFullYear(),
  };
};

export const buildUserRegisteredTraits = (
  profileTraits: UserProfileTraits,
  additionalInfo: {
    occupation: string;
    productId?: number;
    source?: string;
    sourceCode?: string;
  },
) => {
  let userTraits = {};

  userTraits = {
    ...profileTraits,
    Occupation: additionalInfo.occupation,
    'Product ID': additionalInfo.productId,
  };

  if (additionalInfo.source) {
    return {
      ...userTraits,
      ...additionalInfo,
    };
  }

  return userTraits;
};
