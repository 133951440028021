import { SetStateAction, useEffect } from 'react';

import { IBillingPaymentMethod } from 'apis/billing';
import { BillingPaymentMethodOrganization } from 'billing/models/PaymentMethodOrganization';

const findPaymentMethodByOrg = (
  organization: BillingPaymentMethodOrganization,
  paymentMethods: IBillingPaymentMethod[] | undefined,
) => {
  if (!paymentMethods) return;

  return paymentMethods.find(
    (paymenthMethod) => paymenthMethod.organization === organization,
  );
};

export const useSetDefaultPreferredBillingMethod = ({
  isProductTrialEligible,
  kohoBalanceTooLow,
  paymentMethods,
  preferredBillingMethod,
  setSelectedMethod,
}: {
  isProductTrialEligible: boolean;
  kohoBalanceTooLow: boolean;
  paymentMethods: IBillingPaymentMethod[] | undefined;
  preferredBillingMethod: IBillingPaymentMethod | undefined;
  setSelectedMethod: (value: SetStateAction<string | undefined>) => void;
}) => {
  useEffect(() => {
    let method: IBillingPaymentMethod | undefined = undefined;

    if (isProductTrialEligible) method = preferredBillingMethod;

    if (
      kohoBalanceTooLow &&
      preferredBillingMethod?.organization ===
        BillingPaymentMethodOrganization.Koho
    ) {
      method = findPaymentMethodByOrg(
        BillingPaymentMethodOrganization.Stripe,
        paymentMethods,
      );
    } else {
      method =
        preferredBillingMethod?.organization !==
        BillingPaymentMethodOrganization.Koho
          ? preferredBillingMethod
          : findPaymentMethodByOrg(
              BillingPaymentMethodOrganization.Koho,
              paymentMethods,
            );
    }

    setSelectedMethod(method?.id);
  }, [
    paymentMethods,
    kohoBalanceTooLow,
    isProductTrialEligible,
    preferredBillingMethod,
    setSelectedMethod,
  ]);
};
