import { Theme } from 'theme-ui';

interface CustomTheme extends Theme {
  buttons: any;
  widths: any[];
  borderRadius: any; // TODO replace with radii
  transitions: any;
  links: any;
  fonts: any;
  shadows: any;
  fontSizes: number[];
  space: number[];
  fontWeights: number[];
  colors: Record<string, string>;
}

export const theme: CustomTheme = {
  // these currently match material-ui defaults, if changed, need to pass through to mui
  breakpoints: ['600px', '960px', '1280px', '1920px'],
  widths: ['100%', '100%', '100%', 1000],
  space: [
    0, // 0
    4, // 1
    8, // 2
    16, // 3
    24, // 4
    32, // 5
    64,
    128,
    256,
    512,
  ],
  fonts: {
    body: '"Basis Grotesque Pro", system-ui, sans-serif',
    heading: '"Basis Grotesque Pro", system-ui, sans-serif',
    numbers: 'Barlow, system-ui, sans-serif',
  },
  shadows: {
    light: 'rgba(0, 0, 0, 0.1) 3px 3px 10px 0px',
    heavy: 'rgba(0, 0, 0, 0.1) 3px 6px 15px 0px',
  },
  radii: {
    small: '5px',
    medium: '8px',
    large: '12px',
  },
  // TODO: start removing usage of this as radii is supported
  borderRadius: {
    small: '5px',
    medium: '8px',
    large: '12px',
  },
  transitions: {
    quick: '0.33s',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: [300, 400, 600, 700, 900],
  lineHeights: {
    body: 1.75,
    heading: 1.25,
  },
  colors: {
    text: '#373E4D',
    textDark: '#22262F',
    background: '#ffffff',
    primary: '#00579B', // koho blue
    primary50: '#E6EEF5',
    primary100: '#6B9EC5',
    primaryAlt: '#2D5BA8',
    primaryDisabled: '#B2CCE1', // primary-75
    primaryActive: '#003A67',
    secondary: '#cc00cc',
    secondaryS200: '#F5988C',
    tertiary: '#373E4D',
    coral: '#FF8571',
    highlight: '#ee00ee',
    muted: '#f6f6ff',
    grey: '#ccc',
    midGrey: '#74748B',
    error: '#f44336',
    orange: '#f38374',
    lightGrey: '#dddddd',
    success: '#3D7F69',
    danger: '#E12D3F',
    white: '#ffffff',
    whiteSmoke: '#F9F9F9',
    greyDark: '#9B9EA6',
    greyLavender: '#C3C5C9',
    greyLight: '#D8D8D8',
    greyXLight: '#F1F5F8',
    // duplicate name, but the one above doesn't correspond to the design system 'error' color
    // https://www.figma.com/file/ZbHWaogLAtaPLcNkJyLmXD/KDS?node-id=0%3A1
    alert: '#F24759',
    alertDark: '#942B36',
    alertLight: '#FEEDEE',
    successful: '#53AC8F',
    warning: '#FFC962',
    blueLight: '#CDD5FA75',
    blueDark: '#2834B2',
    blueNavy: '#2F3CCF',
    paleNavy: '#EBEEFF',
    lightCyan: '#CDD5FA',
    green400: '#3A7864',
    tiers100: '#768CF1',
    dangerD75: '#FAB4BB',
    tertiaryT100: '#EFEFEF',
    tertiaryT300: '#74748B',
  },
  buttons: {
    primary: {
      fontFamily: 'body',
      color: 'background',
      bg: 'primary',
      fontWeight: 4,
      '&:hover': {
        bg: 'text',
        cursor: 'pointer',
      },
    },
    secondary: {
      color: 'background',
      bg: 'secondary',
    },
    unstyledTab: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: 'small',
      borderColor: 'transparent',
    },
  },
  links: {
    nav: {
      px: 2,
      py: 1,
      fontFamily: 'body',
      fontWeight: 2,
      '&:hover': {
        bg: 'secondary',
        transition: '0.5s',
        color: '#ccc',
      },
      color: '#fff',
    },
  },
};

export const mobile = '@media (max-width: 959px)';
export const tablet = '@media (min-width: 960px) and (max-width: 1279px)';
export const fromTablet = '@media (min-width: 960px)';
export const desktop = '@media (min-width: 1280px)';

export const MOBILE_WIDTH = 959;
