/** @jsxImportSource theme-ui */
import { Paragraph } from '../../components';
import { ColourWheelValues } from '../../models/ColorWheelValues';
import { Recipient } from '../../models/Recipient';
import { theme } from '../../theme';

export const RecipientAvatar = ({ recipient }: { recipient: Recipient }) => {
  return (
    <div
      sx={{
        backgroundColor: ColourWheelValues[recipient.initials.toLowerCase()[0]],
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.borderRadius.large,
      }}
    >
      <Paragraph sx={{ color: 'white', fontWeight: 'bold', m: 0 }}>
        {recipient.initials.toUpperCase()}
      </Paragraph>
    </div>
  );
};
