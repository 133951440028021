import { Box, makeStyles } from '@material-ui/core';
import { theme } from 'theme';

const useSpinnerStyles = makeStyles({
  ldsSpinner: {
    color: theme.colors.text,
    display: 'inline-block',
    position: 'relative',
    width: 80,
    height: 80,
    boxSizing: 'border-box',
    scale: '60%',
    '& div, & div:after': {
      boxSizing: 'border-box',
    },
    '& div': {
      transformOrigin: '40px 40px',
      animation: '$spinning 1.2s linear infinite',
    },
    '& div:after': {
      boxSizing: 'border-box',
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '3.2px',
      left: '36.8px',
      width: '6.4px',
      height: '17.6px',
      borderRadius: '10%',
      borderTopRightRadius: '6px',
      borderTopLeftRadius: '6px',
      background: 'currentColor',
    },
    '& div:nth-child(1)': {
      transform: 'rotate(0deg)',
      animationDelay: '-1.1s',
    },
    '& div:nth-child(2)': {
      transform: 'rotate(30deg)',
      animationDelay: '-1s',
    },
    '& div:nth-child(3)': {
      transform: 'rotate(60deg)',
      animationDelay: '-0.9s',
    },
    '& div:nth-child(4)': {
      transform: 'rotate(90deg)',
      animationDelay: '-0.8s',
    },
    '& div:nth-child(5)': {
      transform: 'rotate(120deg)',
      animationDelay: '-0.7s',
    },
    '& div:nth-child(6)': {
      transform: 'rotate(150deg)',
      animationDelay: '-0.6s',
    },
    '& div:nth-child(7)': {
      transform: 'rotate(180deg)',
      animationDelay: '-0.5s',
    },
    '& div:nth-child(8)': {
      transform: 'rotate(210deg)',
      animationDelay: '-0.4s',
    },
    '& div:nth-child(9)': {
      transform: 'rotate(240deg)',
      animationDelay: '-0.3s',
    },
    '& div:nth-child(10)': {
      transform: 'rotate(270deg)',
      animationDelay: '-0.2s',
    },
    '& div:nth-child(11)': {
      transform: 'rotate(300deg)',
      animationDelay: '-0.1s',
    },
    '& div:nth-child(12)': {
      transform: 'rotate(330deg)',
      animationDelay: '0s',
    },
  },
  '@keyframes spinning': {
    '0%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
});

export const LoadingModal = () => {
  const { ldsSpinner } = useSpinnerStyles();

  return (
    <>
      <Box
        position="fixed"
        sx={{
          background: theme.colors.tertiary,
          opacity: '20%',
          left: 0,
          top: 0,
          zIndex: 1400,
          height: '100%',
          width: '100%',
        }}
      />
      <Box
        display="flex"
        position="fixed"
        sx={{
          left: 0,
          top: 0,
          zIndex: 1400,
          height: '100vh',
          width: '100vw',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          display="flex"
          position="absolute"
          sx={{
            width: 105,
            height: 105,
            zIndex: 1401,
            borderRadius: 8,
            background: theme.colors.white,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box className={ldsSpinner}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </Box>
        </Box>
      </Box>
    </>
  );
};
