/** @jsxImportSource theme-ui */
import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import appStoreEn from 'assets/images/app-download/apple-en.svg';
import appStoreFr from 'assets/images/app-download/apple-fr.svg';
import googlePlayEn from 'assets/images/app-download/google-en.png';
import googlePlayFr from 'assets/images/app-download/google-fr.png';
import kohoIcon from 'assets/images/app-download/koho-icon.png';
import footerImg from 'assets/images/login/footer.png';
import {
  FooterImageWrapper,
  LOCALE_EN,
  LayoutBasic,
  Link,
  LocaleContext,
  Paragraph,
  TitleMedium,
} from 'components';
import { ThemeUIStyleObject } from 'theme-ui';

import { desktop } from '../theme';

export const IdentityVerificationLandingPage = (props: RouteComponentProps) => {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const kohoAppBadge = (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flex: '1 0 50%',
        alignItems: ['center', 'flex-end'],
        pr: [0, '35px'],
      }}
    >
      <Paragraph
        sx={{
          fontWeight: '700',
          mt: 0,
          mb: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'tertiary',
        }}
      >
        <img
          sx={{
            width: '70px',
            mb: 2,
          }}
          src={kohoIcon}
          // alt not needed, text below describes the image
          alt=""
        />
        {intl.formatMessage({
          id: 'IdentityVerificationLandingPage.KohoFinancial',
          defaultMessage: 'KOHO Financial',
        })}
      </Paragraph>
    </Box>
  );

  const downloadImgSx: ThemeUIStyleObject = {
    width: ['110px', '110px', '150px'],
    mb: 2,
    display: 'block',
  };

  const appDownloadLinks = (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flex: '1 0 50%',
        alignItems: ['center', 'flex-start'],
        pl: [0, '35px'],
      }}
    >
      <Link
        trackName="IdentityVerification Apple Download"
        external
        to="https://apps.apple.com/ca/app/koho-financial/id1091010942"
        data-cy="btn-appleDownload"
        aria-label={intl.formatMessage({
          id: 'IdentityVerificationLandingPage.DownloadFromApple',
          defaultMessage: 'Download KOHO from the Apple app store',
        })}
      >
        <img
          src={locale === LOCALE_EN ? appStoreEn : appStoreFr}
          alt={intl.formatMessage({
            id: 'IdentityVerificationLandingPage.DownloadFromApple',
            defaultMessage: 'Download KOHO from the Apple app store',
          })}
          sx={downloadImgSx}
        />
      </Link>
      <Link
        trackName="IdentityVerification Google Play Download"
        external
        to="https://play.google.com/store/apps/details?id=ca.koho"
        data-cy="btn-googleDownload"
        aria-label={intl.formatMessage({
          id: 'IdentityVerificationLandingPage.DownloadFromGoogle',
          defaultMessage: 'Download KOHO from Google Play',
        })}
      >
        <img
          src={locale === LOCALE_EN ? googlePlayEn : googlePlayFr}
          alt={intl.formatMessage({
            id: 'IdentityVerificationLandingPage.DownloadFromGoogle',
            defaultMessage: 'Download KOHO from Google Play',
          })}
          sx={downloadImgSx}
        />
      </Link>
    </Box>
  );

  return (
    <LayoutBasic
      pageName="Secure your account"
      sx={{ bg: '#F5F5F5' }}
      includeLanguageToggle={true}
    >
      <Box
        sx={{
          maxWidth: ['100%', '100%', 890],
          margin: '0 auto',
          px: [4, 4, 0],
        }}
      >
        <TitleMedium
          sx={{
            textAlign: 'center',
          }}
        >
          {intl.formatMessage({
            id: 'IdentityVerificationLandingPage.PageTitle',
            defaultMessage: `For your account's security we need to verify your identity`,
          })}
        </TitleMedium>
        <Paragraph
          sx={{
            textAlign: 'center',
            mb: ['40px', '40px', '65px'],
          }}
        >
          {intl.formatMessage({
            id: 'IdentityVerificationLandingPage.PageBodyParagraph',
            defaultMessage: `Please download and use the KOHO app on your mobile device to securely verify your identity.`,
          })}
        </Paragraph>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 6,
          }}
        >
          {kohoAppBadge}
          {appDownloadLinks}
        </Box>
      </Box>
      <FooterImageWrapper
        bg="#F8A894"
        sx={{ pb: 2, position: 'relative', bottom: '0' }}
      >
        <img
          // alt text not needed image is decorative
          alt=""
          src={footerImg}
          sx={{
            position: 'relative',
            zIndex: 2,
            width: '95px',
            [desktop]: {
              width: '125px',
            },
          }}
        />
      </FooterImageWrapper>
    </LayoutBasic>
  );
};
