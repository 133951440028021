import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import MaskedInput from 'react-text-mask';

import { InputBaseComponentProps } from '@material-ui/core';
import { Box, Button } from 'components';
import { KhInputText } from 'components/inputs/KhInputText';
import { hisaActions } from 'hisa/store/slice';

const SinFormatCustom = ({ inputRef, ...other }: InputBaseComponentProps) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      keepCharPositions={false}
    />
  );
};

type Props = {
  value: string;
  onContinue: () => void;
};

export const SocialInsuranceNumber = ({
  value,
  onContinue,
}: Readonly<Props>) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // TODO add proper validation
  const disabled = !value || value.length < 9;

  return (
    <Box sx={{ my: 3 }}>
      <KhInputText
        autoFocus
        label="SIN"
        placeholder="XXX-XXX-XXX"
        value={value}
        onChange={(e) => dispatch(hisaActions.setSIN(e.target.value))}
        InputProps={{ inputComponent: SinFormatCustom }}
      />
      <Button
        sx={{ mt: 4 }}
        trackName="HISA SIN Continue"
        disabled={disabled}
        onClick={onContinue}
      >
        {intl.formatMessage({
          id: 'HisaSetupPage.Continue',
          defaultMessage: 'Continue',
        })}
      </Button>
    </Box>
  );
};
