import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useNavigate } from '@reach/router';
import { AddFundsFeatureFlags } from 'addFunds/feature-flags';
import { track } from 'analytics/analytics';
import { selectTheme } from 'appState/appState.slice';
import transferIconV2 from 'assets/images/kds_icons/icons/debit-card.svg';
import dollarIconV2 from 'assets/images/kds_icons/icons/dollar-v2.svg';
import dollarIcon from 'assets/images/kds_icons/icons/dollar.svg';
import idIconV2 from 'assets/images/kds_icons/icons/spending-v2.svg';
import interacV2 from 'assets/images/kds_icons/icons/transfer-v2.svg';
import { default as transferIcon } from 'assets/images/kds_icons/icons/transfer.svg';
import idIcon from 'assets/images/kds_icons/navigation/spending.svg';
import interac from 'assets/images/logos/interac-logo.png';
import clsx from 'clsx';
import { BoxTw } from 'components/BoxTw';
import ButtonUnstyled from 'components/ButtonUnstyledTw';
import { Paragraph, TitleLarge, TitleMedium } from 'components/TypographyTw';
import DownloadApp from 'features/modals/DownloadApp';
import { useToggle } from 'hooks/useToggle';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TemplateTw, WrapperWithSidebar } from 'layout/TemplateTw';
import { selectProfileVerified } from 'profile/store/selectors';
import { twMerge } from 'tailwind-merge';

function Card({ children, className = '' }) {
  return (
    <BoxTw
      variant="shadow"
      className={twMerge(
        clsx('p-4 mb-4 light:py-3 light:px-0 light:my-0', className),
      )}
    >
      {children}
    </BoxTw>
  );
}

function CardHeader({ children }) {
  return (
    <TitleMedium className="legacy:text-lg mb-1 mt-0 light:normal-case light:font-body light:text-base light:font-medium">
      {children}
    </TitleMedium>
  );
}

function CardContent({ children }) {
  return <Paragraph className="m-0">{children}</Paragraph>;
}

export const AddMoneyMethods = () => {
  const items = useGetItems({
    etransfer: () => handleETransferClick('/load/e-transfer'),
    directDeposit: () => handleDDClick('/load/direct-deposit'),
    debitCard: () => handleDebitClick('/load/debit-card'),
    cashLoads: () => openDownloadAppModal(),
  });

  const intl = useIntl();
  const navigate = useNavigate();

  const {
    value: isDownloadAppModalOpen,
    on: openDownloadAppModal,
    off: closeDownloadAppModal,
  } = useToggle();

  const userIsVerified = useSelector(selectProfileVerified);

  const debitCardEnabled =
    useFlags()[AddFundsFeatureFlags.DebitCardLoadingEnabled];

  function handleETransferClick(to: string) {
    track({
      event: 'Clicked',
      properties: { name: 'eTransfer', to, element: 'button', type: 'button' },
    });
    navigate(to);
  }

  function handleDDClick(to: string) {
    track({
      event: 'Clicked',
      properties: {
        name: 'direct-deposit',
        to,
        element: 'button',
        type: 'button',
      },
    });

    if (!userIsVerified) {
      navigate('/kyc/entry');
    } else {
      navigate(to);
    }
  }

  function handleDebitClick(to: string) {
    track({
      event: 'Clicked',
      properties: {
        name: 'debit-card-load',
        to,
        element: 'button',
        type: 'button',
      },
    });

    if (debitCardEnabled) {
      if (!userIsVerified) {
        navigate('/kyc/entry');
      } else {
        navigate(to);
      }
    }
  }

  return (
    <TemplateTw name="AddMoneyMethods">
      <WrapperWithSidebar>
        <div className="light:bg-white lg:light:min-h-[638px] light:py-14 light:rounded-xl">
          <div className="light:max-w-[450px] light:mx-auto">
            <TitleLarge className="light:mb-6">
              {intl.formatMessage({
                id: 'AddMoneyMethods.AddMoney.Title',
                defaultMessage: 'Add money',
              })}
            </TitleLarge>
            {items.map((item, index) => (
              <Fragment key={item.title}>
                {index === 2
                  ? debitCardEnabled || (
                      <TitleLarge className="mt-6">
                        {intl.formatMessage({
                          id: 'AddMoneyMethods.ComingSoon.Title',
                          defaultMessage: 'Coming soon...',
                        })}
                      </TitleLarge>
                    )
                  : null}
                <ButtonUnstyled onClick={item.onClick} className="w-full">
                  <Card
                    className={clsx(
                      index !== items.length - 1 && 'border-b border-grey-75',
                    )}
                  >
                    <BoxTw className="flex flex-row light:grid light:grid-cols-[20px_1fr] light:gap-4">
                      <img
                        src={item.image}
                        alt={item.title + ' logo'}
                        className="w-[33px] h-[33px]"
                      />
                      <BoxTw className="pl-4 light:pl-0">
                        <CardHeader>{item.title}</CardHeader>
                        <CardContent>{item.description}</CardContent>
                      </BoxTw>
                    </BoxTw>
                  </Card>
                </ButtonUnstyled>
              </Fragment>
            ))}
          </div>
        </div>
        <DownloadApp
          open={isDownloadAppModalOpen}
          description={intl.formatMessage({
            id: 'AddMoneyMethods.DownloadApp.Description',
            defaultMessage: 'Deposit your cash with the app.',
          })}
          onClose={closeDownloadAppModal}
        />
      </WrapperWithSidebar>
    </TemplateTw>
  );
};

const useGetItems = (clickEvent: {
  etransfer: () => void;
  directDeposit: () => void;
  debitCard: () => void;
  cashLoads: () => void;
}): {
  image: string;
  title: string;
  description: string;
  onClick: () => void;
}[] => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';

  return [
    {
      image: isLight ? interacV2 : interac,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.eTransfer.Title',
        defaultMessage: 'e-Transfer from your bank',
      }),
      description: intl.formatMessage({
        id: 'AddMoneyMethods.eTransfer.Description',
        defaultMessage: 'Securely transfer funds and get it within 30 minutes.',
      }),
      onClick: clickEvent.etransfer,
    },
    {
      image: isLight ? transferIconV2 : transferIcon,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.DirectDeposit.Title',
        defaultMessage: 'Direct deposit',
      }),
      description: intl.formatMessage({
        id: 'AddMoneyMethods.DirectDeposit.Description',
        defaultMessage:
          'Your paycheque or government benefits, and get it within 1–5 days.',
      }),
      onClick: clickEvent.directDeposit,
    },
    {
      image: isLight ? idIconV2 : idIcon,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.DebitCard.Title',
        defaultMessage: 'Debit card',
      }),
      description: intl.formatMessage({
        id: 'AddMoneyMethods.DebitCard.Description',
        defaultMessage:
          'Instantly add money by paying with a Mastercard or Visa debit card.',
      }),
      onClick: clickEvent.debitCard,
    },
    {
      image: isLight ? dollarIconV2 : dollarIcon,
      title: intl.formatMessage({
        id: 'AddMoneyMethods.Cash.Title',
        defaultMessage: 'Cash deposit',
      }),
      description: intl.formatMessage({
        id: 'AddMoneyMethods.Cash.Description',
        defaultMessage: 'At a Canada Post location. Arrives within 10 minutes.',
      }),
      onClick: clickEvent.cashLoads,
    },
  ];
};
