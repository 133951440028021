import { ChangeEvent, FormEvent, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { ButtonTw } from 'components/ButtonTw';
import CircularProgressTw from 'components/CircularProgressTw';
import { Paragraph, TitleLarge } from 'components/TypographyTw';
import { KhInputEmailTw } from 'components/inputs/KhInputEmailTw';
import { useTypedSelector } from 'hooks/redux';
import { validateEmail } from 'utility';

import { passwordResetActions } from './store/slice';

export const PasswordResetEmailRequest = ({ ...props }) => {
  const dispatch = useDispatch();
  const passwordResetState = useTypedSelector((state) => state.passwordReset);
  const { email } = passwordResetState.request;
  const { error, loading } = passwordResetState.emailResetLoadable;
  const intl = useIntl();

  const [emailFormatValid, setEmailFormatValid] = useState<boolean>(
    validateEmail(email),
  );

  useMemo(() => {
    if (!!props.location?.state?.emailAddress) {
      dispatch(
        passwordResetActions.requestValue({
          email: props.location.state.emailAddress,
        }),
      );
      setEmailFormatValid(validateEmail(props.location.state.emailAddress));
    }
  }, [dispatch, setEmailFormatValid, props.location?.state?.emailAddress]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setEmailFormatValid(validateEmail(email));
    dispatch(passwordResetActions.requestValue({ email }));
    dispatch(passwordResetActions.resetEmailResetLoadable());
  };

  return (
    <form
      className="space-y-4"
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(passwordResetActions.resetEmailLoadableLoading());
      }}
    >
      <TitleLarge>
        {intl.formatMessage({
          id: 'EmailRequest.Title',
          defaultMessage: 'Forgot Password',
        })}
      </TitleLarge>
      <Paragraph>
        {intl.formatMessage({
          id: 'EmailRequest.Content',
          defaultMessage:
            'Enter your KOHO email address and we’ll send you a link to reset your password.',
        })}
      </Paragraph>
      <KhInputEmailTw
        label={`${intl.formatMessage({
          id: 'EmailRequest.EmailAddressField',
          defaultMessage: 'Email address',
        })}`}
        id="password-reset-email"
        autoFocus
        error={
          (!emailFormatValid && !!email.length) || typeof error === 'string'
        }
        helperText={error}
        value={email}
        onChange={onChange}
        name="email"
        autoComplete="username"
      />
      <ButtonTw
        type="submit"
        trackName="Send reset link"
        disabled={!emailFormatValid || email.length === 0 || error}
      >
        {!loading &&
          intl.formatMessage({
            id: 'EmailRequest.Button',
            defaultMessage: 'Send reset link',
          })}
        {loading && <CircularProgressTw variant="large" />}
      </ButtonTw>
    </form>
  );
};
