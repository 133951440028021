export const trimObjectStrings = (obj: any): any => {
  if (typeof obj === 'string') {
    return obj.trim();
  } else if (Array.isArray(obj)) {
    return obj.map(trimObjectStrings);
  } else if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = trimObjectStrings(obj[key]);
      }
    }
  }
  return obj;
};
