import { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Box, BoxProps } from '@material-ui/core';
import { TitleSmall } from 'components';
import QRCodeStyling from 'qr-code-styling';

import { RequestMoneyInitialsAvatar } from './RequestMoneyInitialsAvatar';

export const RequestMoneyQRCode = ({
  destination,
  initials,
  ...props
}: BoxProps & {
  destination: string;
  initials: string;
}) => {
  const intl = useIntl();

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: 175,
        height: 175,
        margin: 0,
        data: destination,
        // setting error correction level to H (the highest possible) to make sure the QR code is
        // readable even though we cover portion of it with the user's initials.
        qrOptions: { typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' },
        dotsOptions: { type: 'square', color: '#373e4d' },
        cornersSquareOptions: { type: 'square', color: '#373e4d' },
        cornersDotOptions: { type: 'square', color: '#373e4d' },
      }),
    [destination],
  );

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [ref, qrCode]);

  useEffect(() => {
    qrCode.update({
      data: destination,
    });
  }, [destination, qrCode]);

  return (
    <Box {...props}>
      <TitleSmall sx={{ color: 'white', textAlign: 'center' }}>
        {intl.formatMessage({
          id: 'RequestMoneyQRCode.Scan',
          defaultMessage: 'Scan to pay',
        })}
      </TitleSmall>
      <Box
        sx={{
          borderRadius: 4,
          background: 'white',
          height: '180px',
          width: '180px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          sx={{ margin: 'auto', height: '175px', position: 'relative' }}
          ref={ref}
        />
        <RequestMoneyInitialsAvatar
          initials={initials}
          position="absolute"
          sx={{ top: '50%' }}
        />
      </Box>
    </Box>
  );
};
