import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectTransferState = (state: RootState) => state.transfer;

export const selectRecipientsData = createSelector(
  selectTransferState,
  (state) => state.recipients,
);

export const selectAddRecipientLoadable = createSelector(
  selectTransferState,
  (state) => state.loadableAddRecipient,
);

export const selectUpdateRecipientLoadable = createSelector(
  selectTransferState,
  (state) => state.loadableUpdateRecipient,
);

export const selectGetRecipientsLoadable = createSelector(
  selectTransferState,
  (state) => state.loadableGetRecipients,
);

export const selectSendETransferLoadable = createSelector(
  selectTransferState,
  (state) => state.loadableSendETransfer,
);
