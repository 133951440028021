import { HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { LabelLg } from 'components/TypographyTw';
import { PasswordRequirement } from 'models';
import { twMerge } from 'tailwind-merge';

export const PasswordValidationRules = ({
  passwordRequirements,
  className = '',
  ...props
}: HTMLAttributes<HTMLSpanElement> & {
  passwordRequirements: PasswordRequirement;
  className?: string;
}) => {
  const intl = useIntl();

  const passwordRulesText: Array<{ text: string; rule: string }> = [
    {
      text: intl.formatMessage({
        id: 'PasswordRequirements.MinCharacters',
        defaultMessage: '8 characters minimum',
      }),
      rule: 'hasMinLength',
    },
    {
      text: intl.formatMessage({
        id: 'PasswordRequirements.Lowercase',
        defaultMessage: '1 lower case character',
      }),
      rule: 'hasLowerCase',
    },
    {
      text: intl.formatMessage({
        id: 'PasswordRequirements.Uppercase',
        defaultMessage: '1 upper case character',
      }),
      rule: 'hasUpperCase',
    },
  ];

  return (
    <div className={twMerge('grid grid-cols-2', className)} {...props}>
      {passwordRulesText.map((rule, i) => (
        <div key={i} className="flex items-center mb-2">
          {passwordRequirements[rule.rule] ? (
            <CheckCircleIcon className="mr-2 text-primary-300" />
          ) : (
            <RadioButtonUncheckedIcon className="mr-2 text-grey-300" />
          )}

          <LabelLg data-cy={`password-rule-${rule.rule}`}>{rule.text}</LabelLg>
        </div>
      ))}
    </div>
  );
};
