import { useIntl } from 'react-intl';

import { navigate } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';
import {
  ButtonUnstyled,
  ButtonUnstyledProps,
} from 'components/ButtonUnstyledTw';

interface ButtonBackProps extends ButtonUnstyledProps {
  destination?: string;
  onBack?: () => void;
  onClick?: () => void;
}

export const ButtonBackTw = ({
  onClick,
  destination,
  onBack,
  ...props
}: ButtonBackProps) => {
  const intl = useIntl();

  const onButtonClicked = () => {
    if (onBack) {
      onBack();
      return;
    }

    if (destination) {
      navigate(destination);
    } else {
      window.history.back();
    }

    onClick && onClick();
  };

  return (
    <ButtonUnstyled
      onClick={onButtonClicked}
      aria-label={intl.formatMessage({
        id: 'Global.Button.Back',
        defaultMessage: 'Back',
      })}
      className="box-content h-6 w-6"
      {...props}
    >
      <KDSIcons.Icons.ChevronLeft className="w-full h-full" />
    </ButtonUnstyled>
  );
};

export default ButtonBackTw;
