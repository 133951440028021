import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { KDSIcons } from 'assets/images/kds_icons';
import {
  Box,
  ButtonLinkExternal,
  ButtonUnstyled,
  LocaleContext,
  Modal,
  Paragraph,
  ParagraphSmall,
  SubtitleMedium,
  Template,
  TitleLarge,
  TitleSmall,
} from 'components';
import { selectDirectDeposit } from 'directDeposit/store/selectors';
import { directDepositActions } from 'directDeposit/store/slice';
import { selectProfileName } from 'profile/store/selectors';
import { mobile, theme } from 'theme';

import { LineCopier } from '../../components/LineCopier';

const useAccordionStyles = makeStyles({
  root: {
    background: theme.colors.greyXLight,
    borderRadius: 3,
    '& .Mui-expanded.MuiAccordionSummary-content': {
      marginTop: '10px',
      marginBottom: '10px',
    },
    '& p': { margin: 0 },
  },
  detailsRoot: { display: 'block' },
  summaryRoot: { '&.Mui-expanded': { minHeight: 0 } },
  interiorRoot: { margin: 0, background: 'transparent', boxShadow: 'none' },
  interiorDetails: { padding: '8px 0' },
  interiorExpanded: { '&:before': { opacity: 1 } },
});

export const LoadDirectDepositPage = () => {
  const intl = useIntl();
  const context = useContext(LocaleContext);
  const dispatch = useDispatch();

  const accordionClasses = useAccordionStyles();

  const [instructionsModalOpen, setInstructionsModalOpen] = useState(false);

  const openModal = () => setInstructionsModalOpen(true);
  const closeModal = () => setInstructionsModalOpen(false);

  const clientInfo = useSelector(selectDirectDeposit);

  const name = useSelector(selectProfileName);

  useEffect(() => {
    dispatch(directDepositActions.getDirectDepositRequest());
  }, [dispatch]);

  return (
    <Template name="LoadDirectDeposit" variant="center">
      <TitleLarge>
        {intl.formatMessage({
          id: 'LoadDirectDeposit.Title',
          defaultMessage: 'Get paid directly into your account',
        })}
      </TitleLarge>

      <Paragraph sx={{ mt: 0 }}>
        {intl.formatMessage({
          id: 'LoadDirectDeposit.Description',
          defaultMessage:
            'Deposit a portion or all of your paycheque directly.',
        })}
      </Paragraph>

      <Box
        sx={{
          pb: 4,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <ButtonUnstyled sx={{ width: '100%' }} onClick={openModal}>
          <Box
            sx={{
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: 4,
              py: 2,
              display: 'flex',
              alignItems: 'start',
              transition: 'transform 0.2s',
            }}
          >
            <KDSIcons.Icons.Question
              sx={{ mt: 3, ml: 2, color: theme.colors.primary, flexBasis: 48 }}
            />

            <Paragraph
              sx={{ mx: 2, fontWeight: 700, color: theme.colors.primary }}
            >
              {intl.formatMessage({
                id: 'LoadDirectDeposit.BenefitLink',
                defaultMessage:
                  'What are the benefits of setting up direct deposit?',
              })}
            </Paragraph>
          </Box>
        </ButtonUnstyled>
      </Box>

      <Box sx={{ my: 2 }}>
        <TitleSmall sx={{ mb: 3 }}>
          {intl.formatMessage({
            id: 'LoadDirectDeposit.TapInstructions',
            defaultMessage: 'Tap to copy your account info',
          })}
        </TitleSmall>

        <LineCopier
          label={intl.formatMessage({
            id: 'DirectDepositInstructions.AccountNumber',
            defaultMessage: 'Account number',
          })}
          value={clientInfo?.client_number}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '24px',
            [mobile]: { flexDirection: 'column', gap: 0 },
          }}
        >
          <LineCopier
            label={intl.formatMessage({
              id: 'DirectDepositInstructions.Institution',
              defaultMessage: 'Institution',
            })}
            value={clientInfo?.financial_institution_number}
            sx={{ flexBasis: '50%' }}
          />
          <LineCopier
            label={intl.formatMessage({
              id: 'DirectDepositInstructions.Transit',
              defaultMessage: 'Transit',
            })}
            value={clientInfo?.transit_number}
            sx={{ flexBasis: '50%' }}
          />
        </Box>
        <LineCopier
          label={intl.formatMessage({
            id: 'DirectDepositInstructions.InstitutionName',
            defaultMessage: 'Institution name',
          })}
          value={clientInfo?.financial_institution_name}
        />
      </Box>

      <ParagraphSmall sx={{ mt: 0, mb: 4 }}>
        {intl.formatMessage(
          {
            id: 'LoadDirectDeposit.NameAssurance',
            defaultMessage:
              'Make sure the name on the deposit is {name}, otherwise it might be rejected.',
          },
          { name },
        )}
      </ParagraphSmall>

      {/* <Button sx={{ mb: 4 }}>Void cheque</Button> */}

      <Box>
        <Accordion
          square={false}
          defaultExpanded={true}
          classes={{ root: accordionClasses.root }}
        >
          <AccordionSummary
            classes={{ root: accordionClasses.summaryRoot }}
            expandIcon={<ArrowDropDownIcon sx={{ color: 'black' }} />}
          >
            <TitleSmall sx={{ margin: 0 }}>
              {intl.formatMessage({
                id: 'LoadDirectDeposit.FAQ.Title',
                defaultMessage: 'Have questions?',
              })}
            </TitleSmall>
          </AccordionSummary>
          <AccordionDetails classes={{ root: accordionClasses.detailsRoot }}>
            <Accordion
              classes={{
                root: accordionClasses.interiorRoot,
                expanded: accordionClasses.interiorExpanded,
              }}
            >
              <AccordionSummary
                classes={{ root: accordionClasses.interiorDetails }}
                expandIcon={<ArrowDropDownIcon sx={{ color: 'black' }} />}
              >
                <Paragraph sx={{ margin: 0, fontWeight: 700 }}>
                  {intl.formatMessage({
                    id: 'LoadDirectDeposit.FAQ.Paycheque',
                    defaultMessage: 'Do I need to deposit my full paycheque?',
                  })}
                </Paragraph>
              </AccordionSummary>
              <AccordionDetails
                classes={{ root: accordionClasses.interiorDetails }}
              >
                <Paragraph>
                  {intl.formatMessage({
                    id: 'LoadDirectDeposit.FAQ.PaychequeAnswer',
                    defaultMessage:
                      'Not at all! Your paycheque can be split into different accounts. Just let your employer know how much you’d like to deposit',
                  })}
                </Paragraph>
              </AccordionDetails>
            </Accordion>
            <Accordion
              classes={{
                root: accordionClasses.interiorRoot,
                expanded: accordionClasses.interiorExpanded,
              }}
            >
              <AccordionSummary
                classes={{ root: accordionClasses.interiorDetails }}
                expandIcon={<ArrowDropDownIcon sx={{ color: 'black' }} />}
              >
                <Paragraph sx={{ margin: 0, fontWeight: 700 }}>
                  {intl.formatMessage({
                    id: 'LoadDirectDeposit.FAQ.Delays',
                    defaultMessage:
                      'Are there any delays in receiving my paycheque?',
                  })}
                </Paragraph>
              </AccordionSummary>
              <AccordionDetails
                classes={{ root: accordionClasses.interiorDetails }}
              >
                <Paragraph>
                  {intl.formatMessage({
                    id: 'LoadDirectDeposit.FAQ.DelaysAnswer',
                    defaultMessage:
                      'For government deposits (ie. from the CRA), you can typically expect to receive those within 3-5 business days from the issue date.',
                  })}
                </Paragraph>
              </AccordionDetails>
            </Accordion>
            <ButtonLinkExternal
              href={`https://help.koho.ca/${context.locale}/collections/132492-move-money`}
              variant="tertiary"
              sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)', paddingTop: 5 }}
            >
              {intl.formatMessage({
                id: 'LoadDirectDeposit.FAQ.More',
                defaultMessage: 'View more direct deposit FAQs',
              })}
            </ButtonLinkExternal>
          </AccordionDetails>
        </Accordion>
      </Box>

      <InstructionsModal open={instructionsModalOpen} onClose={closeModal} />
    </Template>
  );
};

const InstructionsModal = ({ open, onClose }) => {
  const intl = useIntl();

  return (
    <Modal open={open} onClose={onClose} maxWidth="xs">
      <Box sx={{ px: 2 }}>
        <TitleLarge sx={{ mt: 0, mb: 4 }}>
          {intl.formatMessage({
            id: 'LoadDirectDeposit.InstructionsModal.Title',
            defaultMessage: 'Benefits of setting up a direct deposit',
          })}
        </TitleLarge>

        <IllustrationTile icon={<KDSIcons.Spot.Bank />}>
          <Paragraph sx={{ fontWeight: 700, my: 1 }}>
            {intl.formatMessage({
              id: 'LoadDirectDeposit.InstructionsModal.EarlyTitle',
              defaultMessage: 'Have your deposit generally arrive a day early',
            })}
          </Paragraph>
          <SubtitleMedium>
            {intl.formatMessage({
              id: 'LoadDirectDeposit.InstructionsModal.EarlyDescription',
              defaultMessage:
                'Compared to other institutions. However, we can’t always guarantee this.',
            })}
          </SubtitleMedium>
        </IllustrationTile>

        <IllustrationTile icon={<KDSIcons.Spot.Growth />}>
          <Paragraph sx={{ fontWeight: 700, my: 1 }}>
            {intl.formatMessage({
              id: 'LoadDirectDeposit.InstructionsModal.InterestTitle',
              defaultMessage: 'Earn interest on your balance',
            })}
          </Paragraph>
          <SubtitleMedium>
            {intl.formatMessage({
              id: 'LoadDirectDeposit.InstructionsModal.InterestDescription',
              defaultMessage:
                'You can earn up to 5% interest on your entire balance based on your plan.',
            })}
          </SubtitleMedium>
        </IllustrationTile>

        <IllustrationTile icon={<KDSIcons.Spot.Documents />}>
          <Paragraph sx={{ fontWeight: 700, my: 1 }}>
            {intl.formatMessage({
              id: 'LoadDirectDeposit.InstructionsModal.BillsTitle',
              defaultMessage: 'Never miss a bill',
            })}
          </Paragraph>
          <SubtitleMedium>
            {intl.formatMessage({
              id: 'LoadDirectDeposit.InstructionsModal.BillsDescription',
              defaultMessage:
                'Set up pre-authorized debits (PAD) to pay your bills on time, every time.',
            })}
          </SubtitleMedium>
        </IllustrationTile>
      </Box>
    </Modal>
  );
};

const IllustrationTile = ({ icon, children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        gap: 3,
        mb: 4,
      }}
    >
      <Box sx={{ minWidth: '64px', height: '64px' }}>{icon}</Box>
      <Box>{children}</Box>
    </Box>
  );
};
