import * as braze from '@braze/web-sdk';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface BrazeContentCardState {
  homeCards: braze.ClassicCard[] | null;
}

const initialState: BrazeContentCardState = {
  homeCards: null,
};

export const brazeContentCardSlice = createSlice({
  name: 'brazeContentCard',
  initialState,
  reducers: {
    setHomeCards: (
      state,
      action: PayloadAction<braze.ClassicCard[] | null>,
    ) => {
      state.homeCards = action.payload;
    },
  },
});

export const brazeContentCardActions = brazeContentCardSlice.actions;
export default brazeContentCardSlice.reducer;
