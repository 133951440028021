import { ReactNode, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  IBillingPaymentMethod,
  billingApi,
  useGetPreferredBillingMethodsQuery,
} from 'apis/billing';
import { selectBalancePersonalSpendable } from 'balance/store/selectors';
import { balanceActions } from 'balance/store/slice';
import { useSetDefaultPreferredBillingMethod } from 'billing/hooks/useSetDefaultPreferredBillingMethod';
import { IBillingProducts } from 'billing/models/BillingProducts';
import { BillingPaymentMethodOrganization } from 'billing/models/PaymentMethodOrganization';
import { LocaleContext } from 'components/I18NContextProvider';
import { useToggle } from 'hooks/useToggle';
import { Money } from 'models';
import { mobile, theme } from 'theme';

import { BillingMethodsAddCardModal } from '../BillingMethodsAddCardModal';
import { PaymentMethodsInfoModal } from '../PaymentMethodsInfoModal';
import { PaymentMethodsDrawer } from './PaymentMethodsDrawer';
import { PaymentMethodsSelect } from './PaymentMethodsSelect';

const Title = () => {
  const intl = useIntl();
  return (
    <div
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 1,
      }}
    >
      <Typography sx={{ fontWeight: 900, color: theme.colors.text, mb: 2 }}>
        {intl.formatMessage({
          id: 'PaymentMethodOptions.Select.PaymentMethod',
          defaultMessage: 'Payment method',
        })}
      </Typography>
      <PaymentMethodsInfoModal />
    </div>
  );
};

const useCurrentPreferredBillingMethod = (product: IBillingProducts) => {
  const {
    data: preferredBillingMethods,
    isLoading: isLoadingPreferredBillingMethod,
  } = useGetPreferredBillingMethodsQuery();

  return {
    isLoadingPreferredBillingMethod,
    preferredBillingMethod: preferredBillingMethods?.find(
      (method) => method.product === product,
    ),
  };
};

type Props = {
  customMobileSelect?: (method: IBillingPaymentMethod | undefined) => ReactNode;
  customModalTitle?: ReactNode;
  hideTitle?: boolean;
  isProductTrialEligible: boolean;
  minBillingAmount: Money;
  onAddNewCard?: () => void;
  noKohoOption?: boolean;
  /**
   * If `onChange` is causing a re-render, consider using `useCallback` to memoize the function.
   * See: https://github.com/kohofinancial/app-web-react/pull/2461
   */
  onChange: (output: IBillingPaymentMethod) => void;
  paymentConfirmationCTA?: string;
  product: IBillingProducts;
  useRadios?: boolean;
};

export const PaymentMethodOptions = ({
  customMobileSelect,
  customModalTitle,
  hideTitle = false,
  isProductTrialEligible,
  minBillingAmount,
  onAddNewCard,
  noKohoOption = false,
  onChange,
  paymentConfirmationCTA,
  product,
  useRadios = false,
}: Props) => {
  const dispatch = useDispatch();
  const context = useContext(LocaleContext);
  const isMobileSize = useMediaQuery(mobile);

  const { isLoadingPreferredBillingMethod, preferredBillingMethod } =
    useCurrentPreferredBillingMethod(product);

  const [getBillingMethods, billingMethodsRequest] =
    billingApi.useLazyGetBillingMethodsQuery();
  const [selectedMethod, setSelectedMethod] = useState<string>();
  const {
    value: isBillingMethodsModalOpen,
    off: closeBillingMethodsModal,
    on: openBillingMethodsModal,
  } = useToggle();

  const balance = useSelector(selectBalancePersonalSpendable);
  const accountBalance = parseFloat(balance?.replace('$', '').trim() ?? '');
  const balanceIsLessThanMinBillingAmount =
    Number.isNaN(accountBalance) || minBillingAmount.gt(accountBalance * 100);

  const kohoBalanceTooLow =
    balanceIsLessThanMinBillingAmount && !isProductTrialEligible;

  const billingPaymentMethods = noKohoOption
    ? billingMethodsRequest.currentData?.payment_methods?.filter(
        (method) =>
          method.organization !== BillingPaymentMethodOrganization.Koho,
      )
    : billingMethodsRequest.currentData?.payment_methods;

  useEffect(() => {
    dispatch(balanceActions.getBalanceRequest());
    getBillingMethods();
  }, [dispatch, context.apiLanguageHeader, getBillingMethods]);

  useSetDefaultPreferredBillingMethod({
    isProductTrialEligible,
    kohoBalanceTooLow,
    paymentMethods: billingPaymentMethods,
    preferredBillingMethod,
    setSelectedMethod,
  });

  const handleChange = (newMethodId: string | undefined) => {
    setSelectedMethod(newMethodId);

    if (!billingPaymentMethods) return;

    const currentSelectedPaymentMethod = billingPaymentMethods.find(
      (paymentMethod) => paymentMethod.id === newMethodId,
    );

    if (
      !currentSelectedPaymentMethod ||
      preferredBillingMethod?.id === currentSelectedPaymentMethod?.id
    )
      return;

    onChange(currentSelectedPaymentMethod);
  };

  const handleBillingMethod = async (billingMethodId: string) => {
    let methods = await getBillingMethods().unwrap();

    closeBillingMethodsModal();
    setSelectedMethod(billingMethodId);

    const selectedMethod = methods.payment_methods.find(
      (paymentMethod) => paymentMethod.id === billingMethodId,
    );

    if (selectedMethod) onChange(selectedMethod);
    onAddNewCard && onAddNewCard();
  };

  if (
    !billingPaymentMethods ||
    billingMethodsRequest.isUninitialized ||
    billingMethodsRequest.isFetching ||
    isLoadingPreferredBillingMethod
  ) {
    return (
      <Box>
        {!hideTitle && <Title />}
        <Skeleton width="100%" height="44px" />
      </Box>
    );
  }

  return (
    <Box>
      {!hideTitle && <Title />}
      {isMobileSize ? (
        <PaymentMethodsDrawer
          customMobileSelect={customMobileSelect}
          customTitle={customModalTitle}
          balance={balance}
          handleChange={handleChange}
          kohoDisabled={kohoBalanceTooLow}
          openBillingMethodsModal={openBillingMethodsModal}
          paymentMethods={billingPaymentMethods}
          selectedMethod={selectedMethod}
          useRadios={useRadios}
        />
      ) : (
        <PaymentMethodsSelect
          balance={balance}
          handleChange={handleChange}
          kohoDisabled={kohoBalanceTooLow}
          openBillingMethodsModal={openBillingMethodsModal}
          paymentMethods={billingPaymentMethods}
          selectedMethod={selectedMethod}
        />
      )}

      <BillingMethodsAddCardModal
        open={isBillingMethodsModalOpen}
        cardAdded={handleBillingMethod}
        onClose={closeBillingMethodsModal}
        ctaText={paymentConfirmationCTA}
      />
    </Box>
  );
};
