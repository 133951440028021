import axios, { AxiosResponse } from 'axios';
import { Epic } from 'redux-observable';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { RootState } from 'store';
import { v4 as uuidv4 } from 'uuid';

import { selectHasAccount } from '../../accounts/store/selectors';
import { Rewards, RewardsBackEnd } from '../models/rewards';
import { RewardConfiguration } from '../models/rewards-configuration';
import { rewardsActions } from './slice';

interface RewardsResponse {
  data: RewardsBackEnd;
  status_code: number;
  status_name: string;
}

interface RewardsConfigurationResponse {
  base: RewardConfiguration[];
  boosts: RewardConfiguration[];
  status_code: number;
  status_name: string;
}

interface CashOutResponse {
  status_code: number;
  status_name: string;
}

const getRewardsEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(rewardsActions.getRewardsRequest.toString()),
    switchMap(() => {
      return from(
        axios.get(`${import.meta.env.VITE_GATEWAY_API}user/rewards`),
      ).pipe(
        map((response: AxiosResponse<RewardsResponse>) => {
          const { rate_pct, ...rest } = response.data.data;
          const result: Rewards = {
            rate_percentage: rate_pct,
            ...rest,
          };
          return rewardsActions.getRewardsSuccess(result);
        }),
        catchError(() => {
          return of(rewardsActions.getRewardsError());
        }),
      );
    }),
  );
};

const getRewardsConfigurationEpic: Epic<any, any, RootState> = (
  action$,
  state$,
) => {
  return action$.pipe(
    ofType(rewardsActions.getRewardsConfigurationRequest),
    filter(() => !!selectHasAccount(state$.value)),
    switchMap(() => {
      return from(
        axios.get(
          `${import.meta.env.VITE_GATEWAY_API}user/rewards/configurations`,
        ),
      ).pipe(
        map((response: AxiosResponse<RewardsConfigurationResponse>) => {
          return rewardsActions.getRewardsConfigurationSuccess(response.data);
        }),
        catchError((error) => {
          return of(rewardsActions.getRewardsConfigurationError());
        }),
      );
    }),
  );
};

const confirmCashOutEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(rewardsActions.confirmCashOutRequest.toString()),
    switchMap(() => {
      return from(
        axios.post(`${import.meta.env.VITE_GATEWAY_API}user/rewards/payouts`, {
          payout_id: uuidv4(),
        }),
      ).pipe(
        map((_: AxiosResponse<CashOutResponse>) =>
          rewardsActions.confirmCashOutSuccess(),
        ),
        catchError(() => {
          return of(rewardsActions.confirmCashOutError());
        }),
      );
    }),
  );
};

const exportedArray = [
  getRewardsEpic,
  getRewardsConfigurationEpic,
  confirmCashOutEpic,
];

export default exportedArray;
