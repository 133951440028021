import { useIntl } from 'react-intl';

import { AccountCard } from 'cards/models';
import { addDays, format, parseISO } from 'date-fns';
import { theme } from 'theme';

export function CardStatusIndicator({
  card,
  color,
}: Readonly<{ card: AccountCard; color?: string }>) {
  const intl = useIntl();

  switch (card.card_status) {
    case 'cancelled':
      return (
        <span style={{ color: color || theme.colors.danger }}>
          {intl.formatMessage({
            id: 'MyCards.CardLink.Cancelled',
            defaultMessage: 'Cancelled',
          })}
        </span>
      );
    case 'preparing':
      return (
        <span style={{ color: color || theme.colors.primary }}>
          {intl.formatMessage({
            id: 'MyCards.CardLink.Preparing',
            defaultMessage: 'Preparing',
          })}
        </span>
      );
    case 'waiting for payment':
      return (
        <span style={{ color: color || theme.colors.primary }}>
          {intl.formatMessage(
            {
              id: 'MyCards.CardLink.WaitingForPayment',
              defaultMessage:
                "<strong>Ordered</strong>{dot}We'll mail it soon!",
            },
            {
              strong: (args) => <strong>{args}</strong>,
              dot: <>&nbsp;•&nbsp;</>,
            },
          )}
        </span>
      );
    case 'shipped':
      const embossed = card.embossed_cards?.find((c) => c.status === 'shipped');
      if (!embossed) break;

      const embossdate = parseISO(embossed.emboss_date);
      const delivery = addDays(embossdate, 10);
      return (
        <span style={{ color: color || theme.colors.primary }}>
          {intl.formatMessage(
            {
              id: 'MyCards.CardLink.Shipped',
              defaultMessage: '<strong>Estimated Delivery</strong>: {date}',
            },
            {
              date: format(delivery, 'MMMM d, yyyy'),
              strong: (args) => <strong>{args}</strong>,
            },
          )}
        </span>
      );
    default:
      break;
  }

  return <></>;
}
