import { useIntl } from 'react-intl';

export const useGetErrorMessage = () => {
  const intl = useIntl();

  const message: Record<number | string, string> = {
    400: intl.formatMessage({
      defaultMessage: 'Invalid Code',
      id: 'getErrorMessage.400',
    }),
    401: intl.formatMessage({
      defaultMessage: 'User or password incorrect. Try again.',
      id: 'getErrorMessage.401',
    }),
    403: intl.formatMessage({
      defaultMessage: 'Contact our support.',
      id: 'getErrorMessage.403',
    }),
    429: intl.formatMessage({
      defaultMessage: 'Too many requests. Try again later.',
      id: 'getErrorMessage.429',
    }),
    FETCH_ERROR: intl.formatMessage({
      defaultMessage: 'Too many requests. Try again later.',
      id: 'getErrorMessage.FETCH_ERROR',
    }),
    500: intl.formatMessage({
      defaultMessage: 'Something went wrong. Try again later.',
      id: 'getErrorMessage.500',
    }),
  };

  const getErrorMessage = (code: number | undefined): string => {
    if (!code)
      return intl.formatMessage({
        defaultMessage: 'Unknown error. Try again later.',
        id: 'getErrorMessage.unknown',
      });

    return message[code];
  };

  return getErrorMessage;
};
