import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Box, Typography } from '@material-ui/core';
import { KDSIcons } from 'assets/images/kds_icons';
import { Alert } from 'components';
import { theme } from 'theme';

export const ErrorAlert = ({
  icon,
  message,
}: {
  icon?: ReactNode;
  message?: string;
}) => {
  const intl = useIntl();
  return (
    <Alert
      alignTop
      background={theme.colors.alertDark}
      icon={
        icon ?? (
          <KDSIcons.Icons.QuestionBold
            sx={{
              color: theme.colors.white,
              mt: '4px',
            }}
          />
        )
      }
    >
      <Box>
        {message ? (
          <Typography sx={{ color: theme.colors.white }}>{message}</Typography>
        ) : (
          <>
            <Typography sx={{ color: theme.colors.white }}>
              {intl.formatMessage({
                id: 'TiersChange.Modal.SomethingWrong',
                defaultMessage: 'Something went wrong.',
              })}
            </Typography>
            <Typography sx={{ color: theme.colors.white }}>
              {intl.formatMessage({
                id: 'TiersChange.Modal.TryAgain',
                defaultMessage: 'Please try again later.',
              })}
            </Typography>
          </>
        )}
      </Box>
    </Alert>
  );
};
