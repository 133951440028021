import { Box, Typography } from '@material-ui/core';
import { ChecklistItem } from 'components/ChecklistItem';
import { InfoModal } from 'components/InfoModal';
import { theme } from 'theme';
import { TierKey } from 'tiers/models';

import { TFeatures } from '../../hooks/useTiersData';

type Props = {
  disableMultiColourChecks?: boolean;
  features: TFeatures;
  tierKey: TierKey;
};

export const Features = ({
  disableMultiColourChecks = false,
  features,
  tierKey,
}: Props) => {
  return (
    <Box component="ul" sx={{ paddingLeft: 0, mb: 16 }}>
      {Object.entries(features)
        .filter(([_, item]) => item.value !== '')
        .map(([key, item]) => (
          <ChecklistItem
            key={key}
            color={
              tierKey === TierKey.Everything && !disableMultiColourChecks
                ? theme.colors.blueDark
                : theme.colors.successful
            }
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '6px',
                '& s': {
                  fontWeight: 500,
                  color: theme.colors.midGrey,
                },
                '& strong': {
                  fontWeight: 900,
                },
              }}
            >
              {item.value}
            </Typography>
            {item.tooltip && <InfoModal {...item.tooltip} />}
          </ChecklistItem>
        ))}
    </Box>
  );
};
