/** @jsxImportSource theme-ui */
import { ReactNode } from 'react';

import { RouteComponentProps } from '@reach/router';
import NavbarBasic from 'components/NavbarBasic';
import SitewideAlert from 'components/SitewideAlert';
import ThemeSwitch from 'components/ThemeSwitch';
import { useTrackPageViewedOnceDeprecated } from 'utility/analyticsHooks';

export const FooterImageWrapper = ({
  bg = '#0058A2',
  colorStripHeight = '50%',
  ...props
}) => (
  <div
    sx={{
      width: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        zIndex: 1,
        height: colorStripHeight,
        bg: bg,
        bottom: 0,
      },
    }}
    {...props}
  />
);

export const Card = ({ ...props }) => {
  return (
    <div
      sx={{
        bg: ['transparent', null, 'white'],
        maxWidth: 500,
        width: ['100%', 500],
        p: 24,
        borderRadius: 4,
        mb: 5,
        '& > *:last-child': {
          mb: 0,
        },
      }}
      {...props}
    />
  );
};

export const LayoutBasic = ({
  children,
  pageName,
  includeNavBar = true,
  chat = true,
  includeLanguageToggle,
  className,
  ...props
}: {
  children: ReactNode;
  pageName: string;
  chat?: boolean;
  includeNavBar?: boolean;
  includeLanguageToggle?: boolean;
  className?: string;
} & RouteComponentProps) => {
  useTrackPageViewedOnceDeprecated({ name: pageName });

  return (
    <div
      {...props}
      className={className}
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <SitewideAlert />
      <ThemeSwitch />
      {includeNavBar ? (
        <NavbarBasic
          languageToggle={includeLanguageToggle}
          sx={{ mb: [3] }}
          chat={chat}
        />
      ) : null}
      <main
        sx={{
          flex: 1,
          display: 'flex',
          minHeight: `100%`,
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {children}
      </main>
    </div>
  );
};
