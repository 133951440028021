import { HttpStatusCode } from 'axios';

import { rootApi } from './rootApi';

export interface MoneyRequest {
  amount?: number;
  message?: string;
  emoji?: string;
  requester_given_name: string;
  requester_initials: string;
  requester_user_ref: string;
  etransfer_email: string;
  etransfer_answer: string;
  request_code: string;
  referral_code: string;
  referral_link: string;
  payment_deep_link: string;
  qr_code_destination: string;
  expires_at: Date;
}

export interface PutMoneyRequestErrorResponse {
  status: HttpStatusCode;
  error: {
    code: number;
  };
}

export enum PutMoneyRequestError {
  InvalidCode = 'InvalidCode',
  RequestExpired = 'RequestExpired',
  RequestFulfilled = 'RequestFulfilled',
  UnknownError = 'UnknownError',
}

export enum PutMoneyRequestErrorCodes {
  RequestExpired = 400020,
}

export const requestMoneyApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    moneyRequestPutNonce: build.mutation<MoneyRequest, string>({
      query: (requestCode) => ({
        url: `/2.0/users/money-request/${requestCode}/nonce`,
        method: 'PUT',
      }),
    }),
  }),
});

export const { useMoneyRequestPutNonceMutation } = requestMoneyApi;
