import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import { InputBaseComponentProps } from '@material-ui/core/InputBase';

export const TextMaskDate = ({
  inputRef,
  ...props
}: Omit<MaskedInputProps & InputBaseComponentProps, 'css'>) => (
  <MaskedInput
    {...props}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
    placeholderChar={'\u2000'}
    keepCharPositions={false}
  />
);

export const TextMaskDateMMYY = ({
  inputRef,
  ...props
}: Omit<MaskedInputProps & InputBaseComponentProps, 'css'>) => (
  <MaskedInput
    {...props}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]}
    placeholderChar={'\u2000'}
    keepCharPositions={false}
    guide={false}
  />
);
