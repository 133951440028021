/**
 * Setting Conversion Goals: https://documentation.unbounce.com/hc/en-us/articles/203879180-Setting-Conversion-Goals-in-the-Classic-Builder
 *
 * Section: Setting Up External Conversion Tracking in the Classic Builder
 */

const trackingScript = `
  var _ubaq = _ubaq || [];
  _ubaq.push(['trackGoal', 'convert']);

  (function() {
  var ub_script = document.createElement('script');
  ub_script.type = 'text/javascript';
  ub_script.src = 
    ('https:' == document.location.protocol ? 'https://' : 'http://') + 
    'd3pkntwtp2ukl5.cloudfront.net/uba.js';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(ub_script, s);
  }) ();
`;

export const trackEventUnbounce = () => {
  const scriptElement = document.createElement('script');
  scriptElement.innerHTML = trackingScript;
  document.body.appendChild(scriptElement);
};
