import Box from '@material-ui/core/Box';
import SpinnerCircularProgress from 'components/SpinnerCircularProgress';

const LoadingCentered = (sx): JSX.Element => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        m: 1,
        ...sx,
      }}
      data-testid="loading-ui--centered"
    >
      <SpinnerCircularProgress color="primary" />
    </Box>
  );
};

export default LoadingCentered;
