import { Tiers } from './Tiers';
import { ReactComponent as Add } from './add.svg';
import { ReactComponent as Alert } from './alert.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as Bills } from './bills.svg';
import { ReactComponent as Blocks } from './blocks.svg';
import { ReactComponent as Book } from './book.svg';
import { ReactComponent as CalendarTimeBold } from './calendar-time-bold.svg';
import { ReactComponent as CalendarTime } from './calendar-time.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as ChatLegacy } from './chat-legacy.svg';
import { ReactComponent as Chat } from './chat.svg';
import { ReactComponent as CheckmarkBold } from './checkmark-bold.svg';
import { ReactComponent as CheckmarkFilled } from './checkmark-filled.svg';
import { ReactComponent as Checkmark } from './checkmark.svg';
import { ReactComponent as ChevronLeft } from './chevron-left.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as CopyBold } from './copy-bold.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as CreditCardBold } from './credit-card-bold.svg';
import { ReactComponent as CreditCard } from './credit-card.svg';
import { ReactComponent as CreditMeter } from './credit-meter.svg';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Delivery } from './delivery.svg';
import { ReactComponent as Dollar } from './dollar.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Drilldown } from './drilldown.svg';
import { ReactComponent as Dropdown } from './dropdown.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as Fractional } from './fractional.svg';
import { ReactComponent as GiftBold } from './gift-bold.svg';
import { ReactComponent as Gift } from './gift.svg';
import { ReactComponent as GoalAmount } from './goal-amount.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as KOHOCard } from './koho-card.svg';
import { ReactComponent as LockDetailed } from './lock-detailed.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as Payroll } from './payroll.svg';
import { ReactComponent as PieChart } from './pie-chart.svg';
import { ReactComponent as Profile } from './profile.svg';
import { ReactComponent as QuestionBold } from './question-bold.svg';
import { ReactComponent as Question } from './question.svg';
import { ReactComponent as Rich } from './rich.svg';
import { ReactComponent as SecurityLock } from './security-lock.svg';
import { ReactComponent as Send } from './send.svg';
import { ReactComponent as Share } from './share.svg';
import { ReactComponent as TimeBold } from './time-bold.svg';
import { ReactComponent as Time } from './time.svg';
import { ReactComponent as Transfer } from './transfer.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as Unlock } from './unlock.svg';
import { ReactComponent as Warning } from './warning.svg';

export const Icons = {
  Add,
  Alert,
  ArrowRight,
  Bills,
  Blocks,
  Book,
  Calendar,
  CalendarTime,
  CalendarTimeBold,
  Chat,
  ChatLegacy,
  CheckmarkBold,
  CheckmarkFilled,
  Checkmark,
  ChevronLeft,
  Clock,
  Copy,
  CopyBold,
  CreditCard,
  CreditCardBold,
  CreditMeter,
  Cross,
  Delivery,
  Dollar,
  Download,
  Drilldown,
  Dropdown,
  Email,
  Fractional,
  Gift,
  GiftBold,
  GoalAmount,
  Info,
  KOHOCard,
  Lock,
  LockDetailed,
  Payroll,
  PieChart,
  Profile,
  Rich,
  SecurityLock,
  Send,
  Share,
  Tiers,
  Time,
  TimeBold,
  Transfer,
  Trash,
  Question,
  QuestionBold,
  Unlock,
  Warning,
};
