import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Box, IconButton, InputAdornment } from '@material-ui/core';
import { ReferralRewardsResponse } from 'apis/referralApi';
import { KDSIcons } from 'assets/images/kds_icons';
import referAndEarnHands from 'assets/images/referAndEarn/referAndEarnHands.png';
import { Button, LocaleContext, Paragraph, TitleXLarge } from 'components';
import { KhInputText } from 'components/inputs/KhInputText';
import { theme } from 'theme';

export const ReferAndEarnShare = ({
  referralsRewardsData,
}: {
  referralsRewardsData: ReferralRewardsResponse;
}) => {
  const intl = useIntl();

  const { deepLinks } = useContext(LocaleContext);

  const [linkCopied, setLinkCopied] = React.useState(false);

  const copyReferralLink = () => {
    navigator['clipboard'].writeText(referralsRewardsData?.referral_url || '');
    setLinkCopied(true);
  };
  const copyReferralId = () => {
    navigator['clipboard'].writeText(referralsRewardsData?.referral_code || '');
  };

  return (
    <Box
      sx={{
        boxShadow: '3px 6px 15px 0px rgba(0, 0, 0, 0.10)',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          bg: theme.colors.primary,
          display: 'flex',
          justifyContent: 'space-between',
          height: 164,
          backgroundImage: `url(${referAndEarnHands})`,
          backgroundSize: ['70%', '350px'],
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'right',
          pl: 24,
          pr: 16,
          py: 16,
        }}
      >
        <TitleXLarge sx={{ alignSelf: 'flex-end', color: 'white' }}>
          {referralsRewardsData?.rewards_header || ''}
        </TitleXLarge>
        <a
          href={deepLinks.KOHO.ReferralFAQ || ''}
          target="_blank"
          rel="noreferrer"
          aria-label={intl.formatMessage({
            id: 'ReferAndEarnPage.Share.InfoLink',
            defaultMessage: 'An article about how to refer friends to KOHO',
          })}
          sx={{ alignSelf: ['flex-end', 'flex-end', 'flex-start'] }}
        >
          <IconButton aria-hidden="true">
            <KDSIcons.Icons.Info color={theme.colors.white} />
          </IconButton>
        </a>
      </Box>
      <Box sx={{ p: 16, pb: 24 }}>
        <Paragraph sx={{ mt: 0, mb: 24 }}>
          {referralsRewardsData?.rewards_sub_header || ''}
        </Paragraph>
        <div
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 16,
            flexDirection: ['column', 'column', 'row'],
          }}
        >
          <KhInputText
            value={referralsRewardsData?.referral_code || ''}
            sx={{ maxWidth: ['100%', '100%', '50%'] }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={copyReferralId}>
                    <KDSIcons.Icons.CopyBold color={theme.colors.primary} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            onClick={copyReferralLink}
            sx={{ maxWidth: ['100%', '100%', '30%'] }}
            disabled={linkCopied}
          >
            {linkCopied
              ? intl.formatMessage({
                  id: 'ReferAndEarnPage.Share.CTACopied',
                  defaultMessage: 'Link copied',
                })
              : intl.formatMessage({
                  id: 'ReferAndEarnPage.Share.CTA',
                  defaultMessage: 'Copy link',
                })}
          </Button>
        </div>
      </Box>
    </Box>
  );
};
