/** @jsxImportSource theme-ui */
import React, { Fragment, useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { Money } from '../models/Money';
import { TransferFormData } from '../models/TransferFormData';
import { ButtonTw } from './ButtonTw';
import CircularProgressTw from './CircularProgressTw';
import { LocaleContext } from './I18NContextProvider';
import {
  NumberLarge,
  NumberSmall,
  Paragraph,
  TitleLarge,
} from './TypographyTw';
import { TransferSecret } from './forms/TransferSecret';

export const TransferReview = ({
  onContinue,
  type,
  formData,
  fee,
  busy,
}: {
  onContinue: (updatedSecret: { question: string; answer: string }) => void;
  type?: 'k2k' | 'etransfer';
  formData?: TransferFormData;
  fee?: Money;
  busy?: boolean;
}) => {
  const intl = useIntl();
  const context = useContext(LocaleContext);
  // TODO - remove this line after fixing references (ie. WithdrawPay)
  formData = formData || {
    amount: '$100.00',
    recipient: {
      name: 'Foo Baz',
      email: 'foo@baz.com',
      secretQuestion: 'last name',
      secretAnswer: 'baz',
    },
  };

  let amount = context.intlFormatMoney(formData.amount),
    email = formData.recipient.email,
    secretQuestion = formData.recipient.secretQuestion,
    secretAnswer = formData.recipient.secretAnswer || '';

  const [secretState, setSecretState] = useState({
    secretQuestion: {
      isValid: true,
      value: secretQuestion,
    },
    secretAnswer: {
      isValid: true,
      value: secretAnswer,
    },
  });
  const handleSecretChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setSecretState((prevState) => ({
      ...prevState,
      [name]: {
        value,
      },
    }));
  };

  const setSecretStateValid = (fieldName: string, isValid: boolean) => {
    setSecretState((prevState) => ({
      ...prevState,
      [fieldName]: {
        ...prevState[fieldName],
        isValid,
      },
    }));
  };

  const onClickContinue = () => {
    onContinue({
      question: secretState.secretQuestion.value,
      answer: secretState.secretAnswer.value,
    });
  };

  const getTotalAmount = () => {
    return fee
      ? context.intlFormatMoney(amount.sub(fee)).format()
      : context.intlFormatMoney(amount).format();
  };

  const breakdown = (
    <ul className="grid grid-cols-2 list-none ml-0 my-4 p-0 [&_li]:mb-2 [&_li:nth-of-type(2n)]:text-right">
      <li>
        <Paragraph className="m-0">
          {intl.formatMessage({
            id: 'TransferReview.Amount',
            defaultMessage: 'Amount',
          })}
        </Paragraph>
      </li>
      <li>
        <NumberSmall>{context.intlFormatMoney(amount).format()}</NumberSmall>
      </li>
      <li>
        <Paragraph className="m-0">
          {intl.formatMessage({
            id: 'TransferReview.ServiceFee',
            defaultMessage: 'Service fee',
          })}
        </Paragraph>
      </li>
      <li>
        <NumberSmall>-{context.intlFormatMoney(fee)?.format()}</NumberSmall>
      </li>
    </ul>
  );

  return (
    <Fragment>
      <TitleLarge className="mt-0 mb-2">
        {type === 'etransfer'
          ? intl.formatMessage(
              {
                id: 'TransferReview.ETransfer.Title',
                defaultMessage: 'Review {italics} e-Transfer®',
              },
              { italics: <em>{'Interac'}</em> },
            )
          : intl.formatMessage({
              id: 'TransferReview.Default.Title',
              defaultMessage: 'Review Transfer',
            })}
      </TitleLarge>
      <div className="mt-2 mb-4">
        <Paragraph className="m-0">
          {intl.formatMessage({
            id: 'TransferReview.Recipient.Label',
            defaultMessage: 'Sending',
          })}{' '}
          {email}
        </Paragraph>
        <NumberLarge>{getTotalAmount()}</NumberLarge>
      </div>

      {fee && fee.gt(0) ? breakdown : null}

      <form noValidate>
        <TransferSecret
          onChange={handleSecretChange}
          onValidChange={setSecretStateValid}
          controlledValue={{
            question: secretState.secretQuestion.value,
            answer: '',
          }}
          obfuscateAnswer={true}
        ></TransferSecret>
      </form>

      <ButtonTw
        onClick={onClickContinue}
        disabled={
          !(
            secretState.secretQuestion.isValid &&
            secretState.secretAnswer.isValid
          ) || busy
        }
      >
        {intl.formatMessage({
          id: 'TransferReview.ETransfer.Send',
          defaultMessage: 'Send',
        })}
        {busy ? <CircularProgressTw variant="large" className="ml-4" /> : null}
      </ButtonTw>
    </Fragment>
  );
};
