import { useIntl } from 'react-intl';

import { Box, BoxProps } from '@material-ui/core';
import { PutMoneyRequestError } from 'apis/requestMoneyApi';
import { ReactComponent as CheckMark } from 'assets/images/checkmark-success-filled.svg';
import { KDSIcons } from 'assets/images/kds_icons';
import { Button, Paragraph, TitleLarge, TitleSmall } from 'components';
import { theme } from 'theme';

export const RequestMoneyErrorCard = ({
  error,
  ...props
}: BoxProps & {
  error: PutMoneyRequestError;
}) => {
  const intl = useIntl();
  switch (error) {
    case PutMoneyRequestError.RequestFulfilled:
      return (
        <Box
          sx={{
            borderRadius: '8px',
            background: '#EEF7F4',
            p: ['16px', '24px 40px'],
            width: '100%',
          }}
          {...props}
        >
          <TitleLarge sx={{ textAlign: 'center' }}>
            {intl.formatMessage({
              id: 'RequestMoneyErrorCard.Fulfilled.Title',
              defaultMessage: 'This request was paid',
            })}
          </TitleLarge>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CheckMark />
          </Box>
        </Box>
      );
    case PutMoneyRequestError.RequestExpired:
      return (
        <Box
          sx={{
            borderRadius: '8px',
            background: theme.colors.greyXLight,
            p: ['16px', '24px 40px'],
          }}
          {...props}
        >
          <TitleLarge sx={{ textAlign: 'center' }}>
            {intl.formatMessage({
              id: 'RequestMoneyErrorCard.Expired.Title',
              defaultMessage: 'This request expired',
            })}
          </TitleLarge>
          <Paragraph sx={{ textAlign: 'center' }}>
            {intl.formatMessage({
              id: 'RequestMoneyErrorCard.Expired.Body',
              defaultMessage: 'Contact the original requester to settle-up.',
            })}
          </Paragraph>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <KDSIcons.Spot.Stop />
          </Box>
        </Box>
      );
    default:
      return (
        <Box {...props}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              borderRadius: '8px',
              alignItems: 'center',
              background: '#EFEFEF',
              p: ['16px', '24px 40px'],
              mb: '16px',
            }}
          >
            <KDSIcons.Icons.WarningLegacy color={theme.colors.danger} />
            <TitleSmall sx={{ mb: 0 }}>
              {intl.formatMessage({
                id: 'RequestMoneyErrorCard.Unknown.Title',
                defaultMessage: 'Something went wrong',
              })}
            </TitleSmall>
            <Paragraph sx={{ textAlign: 'center' }}>
              {intl.formatMessage({
                id: 'RequestMoneyErrorCard.Unknown.Body',
                defaultMessage: 'Please try again or contact support',
              })}
            </Paragraph>
          </Box>
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            {intl.formatMessage({
              id: 'RequestMoneyErrorCard.Unknown.Button',
              defaultMessage: 'Retry',
            })}
          </Button>
        </Box>
      );
  }
};
