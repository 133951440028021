import { useIntl } from 'react-intl';

import { ErrorParagraph } from 'components';

export const StripeErrorMessage = () => {
  const intl = useIntl();
  return (
    <>
      <ErrorParagraph sx={{ mt: 0 }}>
        {intl.formatMessage({
          id: 'LoadDebitCardPage.Error.NoStripe',
          defaultMessage:
            "We're unable to proceed with funding. Browser or adblocker settings may be blocking our payment processor.",
        })}
      </ErrorParagraph>

      <ErrorParagraph sx={{ mt: 0 }}>
        {intl.formatMessage({
          id: 'LoadDebitCardPage.Error.NoStripe2',
          defaultMessage: 'Please check your settings and try again.',
        })}
      </ErrorParagraph>
    </>
  );
};
