import { ReactNode } from 'react';

import { Box, Collapse, Typography } from '@material-ui/core';
import { track } from 'analytics/analytics';
import { IBenefitGroup, IPlan, useGetTiersQuery } from 'apis/tiers';
import { KDSIcons } from 'assets/images/kds_icons';
import { theme } from 'theme';
import { TierKey } from 'tiers/models';

import { useTiersData } from '../../hooks/useTiersData';
import { ChangePlan } from '../ChangePlan';
import { Card } from './Card';
import { Features } from './Features';
import { TierCardHeader } from './TierCardHeader';

const SavingsPerYear = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="auto 1fr"
      gridColumnGap="8px"
      sx={{
        backgroundColor: theme.colors.paleNavy,
        padding: '12px 16px',
        borderRadius: '8px',
      }}
    >
      <KDSIcons.Icons.Tiers color={theme.colors.blueNavy} />
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 900,
          color: theme.colors.blueDark,
          '& span': {
            color: theme.colors.orange,
          },
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

type Props = {
  benefit: IBenefitGroup;
  expandable?: boolean;
  handleSelectTier?: (tier: TierKey) => void;
  isFree?: boolean;
  onPlanChange?: (tier: IBenefitGroup, plan: IPlan | undefined) => void;
  plan?: string;
  selectable?: boolean;
  selected?: boolean;
  trackname: string;
};

export const TierCard = ({
  benefit,
  expandable = false,
  handleSelectTier,
  isFree = false,
  onPlanChange,
  plan = 'monthly',
  selectable = false,
  selected = false,
  trackname,
}: Props) => {
  const { data: tiers } = useGetTiersQuery();
  const essentialCreditBuildingBenefit = tiers
    ?.find((tier) => tier.key === TierKey.Essential)
    ?.benefits.find((benefit) => benefit.key === 'credit-monthly-price');
  const { getFeatures, getSavingPerYear, getTierDescription } = useTiersData();

  const selectedPlan = benefit.plans?.find(({ key }) => key === plan);

  const features = getFeatures(
    benefit.benefits,
    benefit.key,
    essentialCreditBuildingBenefit,
  );
  const savingsPerYear = getSavingPerYear(
    benefit.average_savings_per_year,
    benefit.name,
  );
  const tierDescription = selectable ? getTierDescription(benefit.key) : '';

  return (
    <Card
      dataCy={`tier-card-${benefit.key}`}
      selectable={selectable}
      selected={selected}
      onSelect={() => {
        handleSelectTier && handleSelectTier(benefit.key);
        track({
          event: 'Clicked',
          properties: {
            name: trackname,
            tier_name: benefit.key,
            frequency: plan,
          },
        });
      }}
    >
      <Box>
        <TierCardHeader
          tier={benefit}
          plan={selectedPlan}
          selectable={selectable}
          selected={selected}
          description={tierDescription}
          isFree={isFree}
        />
        {expandable ? (
          <Collapse in={selected}>
            <Features
              disableMultiColourChecks={selectable}
              tierKey={benefit.key}
              features={features}
            />
          </Collapse>
        ) : (
          <Features
            disableMultiColourChecks={selectable}
            tierKey={benefit.key}
            features={features}
          />
        )}
        {!selectable && <SavingsPerYear>{savingsPerYear}</SavingsPerYear>}
      </Box>
      {selectable ? (
        <SavingsPerYear>{savingsPerYear}</SavingsPerYear>
      ) : (
        <ChangePlan
          tier={benefit}
          selectedPlan={selectedPlan}
          isFree={isFree}
          onPlanChange={onPlanChange}
          askForConfirmation={false}
        />
      )}
    </Card>
  );
};
