import { MessageDescriptor } from 'react-intl';

import { track } from 'analytics/analytics';
import { rootApi } from 'apis/rootApi';

export interface VerifyRequest {
  verification_code: string;
  remember_me: boolean;
}
export interface DispatchOtpErrorResponse {
  detail: string;
  status: number;
  title: string;
}

export interface VerifyOtpErrorResponse<T> {
  data: {
    errors: { code: T }[];
  };
  status: number;
}
export interface VerifyOtpError {
  data: {
    errorMessage: MessageDescriptor;
    errorCode: number;
  };
  status: number;
}

export enum OtpErrorCodes {
  TokenInvalid = 400022,
  EmailConflict = 400023,
  DispatchBadRequest = 400024,
  VerifyBadRequest = 400025,
  TooManyRequests = 429002,
  DispatchOTPFailed = 500025,
  FailedUnknown = 500026,
  VerifyFailed = 500027,
  VerifyFailedUnknown = 500028,
  JWTFailed = 500029,
}

export const otpSmsApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    dispatchOtp: build.mutation<void, { clickedResend?: boolean }>({
      query: (_) => ({
        url: '1.0/otp/sms',
        method: 'POST',
      }),
      async onQueryStarted({ clickedResend }, { queryFulfilled }) {
        if (clickedResend) {
          track({ event: 'SMS OTP Code Resent' });
        }
        try {
          await queryFulfilled;
          track({
            event: 'SMS OTP Request Succeeded',
          });
        } catch (error) {
          const errorRes = error as DispatchOtpErrorResponse;
          track({
            event: 'SMS OTP Request Failed',
            properties: {
              code: errorRes?.detail,
            },
          });
        }
      },
    }),
    verifyOtp: build.mutation<void, VerifyRequest>({
      query: ({ verification_code, remember_me }) => ({
        url: '1.0/otp/sms/verify',
        method: 'POST',
        body: {
          verification_code,
          remember_me,
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        track({ event: 'SMS OTP Verify Code Submitted' });

        try {
          await queryFulfilled;
        } catch (err) {
          const errorRes = err as VerifyOtpError;
          track({
            event: 'SMS OTP Verification Failed',
            properties: {
              code: errorRes?.data?.errorCode || errorRes?.status,
            },
          });
        }
      },
    }),
  }),
});

export const { useDispatchOtpMutation, useVerifyOtpMutation } = otpSmsApi;
