import axios, { AxiosError, AxiosResponse } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ReferralData } from '../models/Referral';
import { referralActions } from './slice';

interface ReferrerResponse {
  referral_code: string;
  data: ReferralData;
  status_code: number;
  status_name: string;
}

const referralResponseDataEpic: Epic = (action$) => {
  return action$.pipe(
    ofType(referralActions.getReferrerDataRequest),
    switchMap(({ payload }) => {
      const referral_code = payload;
      return from(
        axios.get(
          `${import.meta.env.VITE_GATEWAY_API}referrals/code/${referral_code}`,
        ),
      ).pipe(
        map((response: AxiosResponse<ReferrerResponse>) =>
          referralActions.getReferrerDataResponse(response.data.data),
        ),
        catchError((error: AxiosError) => {
          return of(
            referralActions.getReferrerDataError(error?.response?.status),
          );
        }),
      );
    }),
  );
};

const referralCodeEpic = (action$) => {
  return action$.pipe(
    ofType(referralActions.postReferralCodeRequest),
    switchMap(() => {
      return from(
        axios.post(
          `${import.meta.env.VITE_GATEWAY_API}referrals/user/code`,
          {},
        ),
      ).pipe(
        map((response) => {
          return referralActions.postReferralCodeResponse(
            response.data.data.referral_code,
          );
        }),
        catchError(() => {
          return of(referralActions.postReferralCodeError());
        }),
      );
    }),
  );
};

const referralRefereesEpic = (action$) => {
  return action$.pipe(
    ofType(referralActions.getReferralRefereesRequest),
    switchMap(() => {
      return from(
        axios.get(`${import.meta.env.VITE_GATEWAY_API}referrals/referees`),
      ).pipe(
        map((response) => {
          return referralActions.getReferralRefereesResponse(
            response.data.referees,
          );
        }),
      );
    }),
  );
};

const exportedArray = [
  referralResponseDataEpic,
  referralCodeEpic,
  referralRefereesEpic,
];

export default exportedArray;
