import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { selectAccountPersonalAccountGroupId } from '../../accounts/store/selectors';
import { Money } from '../../models/Money';

const selectBalanceState = (state: RootState) => state.balance;

export const selectBalanceData = createSelector(
  selectBalanceState,
  (state) => state && state.data,
);

export const selectBalancePersonalAccount = createSelector(
  selectBalanceData,
  selectAccountPersonalAccountGroupId,
  (balanceData, personalAccountIdentifier) => {
    if (balanceData && personalAccountIdentifier) {
      return balanceData[personalAccountIdentifier];
    }
    return null;
  },
);

export const selectBalancePersonalSpendable = createSelector(
  selectBalancePersonalAccount,
  (balance) => balance?.spendable,
);

export const selectBalanceUsable = createSelector(
  selectBalancePersonalAccount,
  (balance) => {
    if (balance) {
      return new Money(balance?.spendable)
        .add(new Money(balance?.goals))
        .add(new Money(balance?.roundups));
    }
    return null;
  },
);

export const selectBalanceRevolvingCredit = createSelector(
  selectBalancePersonalAccount,
  (balance) => ({
    availableCredit: balance?.revolving_credit?.available_credit,
    balance: balance?.revolving_credit?.balance,
    creditLimit: balance?.revolving_credit?.credit_limit,
  }),
);

export const selectBalanceLoadable = createSelector(
  selectBalanceState,
  (balance) => balance && balance.loadable,
);

export const selectBalanceIsLoading = createSelector(
  selectBalanceLoadable,
  (balanceLoadable) => balanceLoadable && balanceLoadable.loading,
);
export const selectBalanceIsError = createSelector(
  selectBalanceLoadable,
  (balanceLoadable) => balanceLoadable && balanceLoadable.error,
);

export const selectVaultBalance = createSelector(
  selectBalancePersonalAccount,
  (balance) => balance?.vault,
);

export const selectInterestEarned = createSelector(
  selectBalancePersonalAccount,
  (balance) => {
    if (balance?.interest_earned) {
      return balance?.interest_earned.lifetime_total;
    }
    return null;
  },
);
