import { track } from 'analytics/analytics';

import { ProfileResponse } from './../profile/store/epics';
import { rootApi } from './rootApi';

interface SetOccupationAndIntendedUseRequest {
  intended_use: string;
  occupation: string;
  code: string;
}

interface ToggleLanguageRequest {
  language: 'en' | 'fr';
}

export const usersApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    occupationIntendedUse: build.mutation<
      void,
      SetOccupationAndIntendedUseRequest
    >({
      query: (request) => ({
        url: `1.0/users/occupation-intended-use`,
        method: 'PUT',
        body: request,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          track({ event: 'Set Occupation and Intended Use Succeeded' });
        } catch {
          track({ event: 'Set Occupation and Intended Use Failed' });
        }
      },
    }),
    getProfile: build.query<ProfileResponse, void>({
      query: () => ({
        url: `1.0/user/profile`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    language: build.mutation<
      void,
      { userId: string; request: ToggleLanguageRequest }
    >({
      query: ({ userId, request }) => ({
        url: `1.0/users/${userId}/language`,
        method: 'PUT',
        body: request,
      }),
      // need to invalidate checklist on language change so
      // we download the correct translations for it
      invalidatesTags: ['Checklist', 'User'],
    }),
  }),
});

export const {
  useOccupationIntendedUseMutation,
  useLanguageMutation,
  useLazyGetProfileQuery,
  useGetProfileQuery,
} = usersApi;
