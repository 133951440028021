/** @jsxImportSource theme-ui */
import { Fragment, useState } from 'react';

import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import { Logo } from 'components/Logo';

import { track } from '../../analytics/analytics';
import { ReactComponent as CancelMenuBar } from '../../assets/images/menu-bar-cancel.svg';
import { ReactComponent as Hamburger } from '../../assets/images/menubar.svg';
import { theme } from '../../theme';
import ButtonUnstyled from '../ButtonUnstyled';

interface Props {
  paths: React.ReactNode[];
}

export const MobileNav = ({ paths }: Readonly<Props>) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    track({ event: 'Menu Closed' });
  };

  const handleClickHamburger = (event: any) => {
    setIsOpen(true);
    track({ event: 'Menu Opened' });
  };

  return (
    <Fragment>
      <div
        id="menu-mobile"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '72px',
          width: '100vw',
          paddingLeft: 4,
          borderBottom: `1px solid ${theme.colors.greyLight}`,
        }}
      >
        <div>
          <Logo />
        </div>
        <div
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ButtonUnstyled
            onClick={handleClickHamburger}
            sx={{
              p: 3,
              '&:focus': {
                borderColor: 'midGrey',
              },
              color: '#373E4D',
            }}
          >
            {isOpen ? <CancelMenuBar /> : <Hamburger />}
          </ButtonUnstyled>
        </div>
      </div>
      <Menu
        id="menu"
        open={isOpen}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorReference="none"
        style={{
          backgroundColor: 'rgba(0,0,0,0.3)',
          top: '72px',
        }}
        PaperProps={{
          style: {
            maxWidth: '100%',
            left: 0,
            padding: 0,
            borderRadius: 0,
          },
        }}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
      >
        <List
          component="nav"
          aria-label="navigation"
          sx={{
            width: window.innerWidth,
            padding: 0,
          }}
        >
          {paths}
        </List>
      </Menu>
    </Fragment>
  );
};
