const trackPurchaseScript = (referralCode?: string) => `
  (function(w, d){
    var id="vpr-capture", n = "script"
    if (!d.getElementById(id)) {
      vpr = w.vpr || function() {(w.vpr.a = w.vpr.a || []).push(arguments);};
      var e = d.createElement(n); e.id = id; e.async=1
      e.src = "https://cdn.veritonic.com/static/vpr.min.js"
      var s = d.getElementsByTagName(n)[0]
      s.parentNode.insertBefore(e, s)
    }
    vpr("veritonic_id", "HMuBDPwp");
    vpr('purchase', {
      value: 10.0,
      currency: 'CAD',
      ${referralCode ? `discount_code: "${referralCode}",` : ''}
      quantity: 1,
      line_items: [
        {
          value: 10.0,
          quantity: 1,
          product_id: '1',
          product_name: 'koho registration',
          product_type: 'registration',
          product_vendor: 'koho',
          variant_id: 'registration id',
          variant_name: 'registration'
        }
      ]
    });
  })(window, document);
`;

export const trackVeritonicPurchase = (referralCode?: string) => {
  const scriptElement = document.createElement('script');
  scriptElement.innerHTML = trackPurchaseScript(referralCode);
  document.body.appendChild(scriptElement);
};
