function SvgComponent(props) {
  return (
    <svg width={65} height={65} viewBox="0 0 65 65" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M47.121 23.262a.667.667 0 00-.943 0c-1.126 1.126-3.078 2.242-5.017 3.059l-.39.16-.013-.04a5.528 5.528 0 00-1.213-2.024l-.155-.161c-1.512-1.512-3.718-2.086-5.145-1.205-2.563 1.582-1.692 4.404.833 5.614.853.409 2.491.234 4.332-.305l.283-.085-.011.197a5.188 5.188 0 01-.426 1.65l-.097.213c-1.385 2.909-6.155 3.32-14.435.958a.667.667 0 00-.366 1.282c8.853 2.525 14.23 2.062 16.005-1.667.487-1.022.697-2.033.662-2.985l-.006-.107.207-.08c2.177-.854 4.375-2.088 5.736-3.377l.16-.155a.667.667 0 000-.942zm-8.674 1.937c.465.465.81.998 1.018 1.583l.054.16-.175.057c-1.57.508-2.941.734-3.597.501l-.093-.038c-1.707-.818-2.185-2.365-.709-3.276.826-.51 2.385-.104 3.502 1.013zM27.687 2.044a.667.667 0 00-.943 0c-2.938 2.937-2.997 5.452-1.22 10.295l.594 1.567c.398 1.054.655 1.808.859 2.56.795 2.944-1.324 6.743-6.5 11.349a.667.667 0 00.885.996c5.518-4.907 7.867-9.121 6.902-12.692a24.59 24.59 0 00-.668-2.064l-.82-2.173c-1.614-4.39-1.556-6.429.91-8.895a.667.667 0 000-.943zM62.97 43.982c-.86-5.42-3.281-8.257-7.227-8.257-2.65 0-4.246.857-7.226 3.37l-.967.817c-1.435 1.2-2.227 1.765-3.32 2.329-.46.238-.928.44-1.41.606-3.205 1.108-4.982.537-5.692-1.674a.667.667 0 00-1.27.408c.971 3.024 3.557 3.854 7.398 2.526a12.01 12.01 0 001.586-.681l.35-.186c1.023-.559 1.845-1.16 3.194-2.288l.721-.61c2.93-2.497 4.323-3.284 6.636-3.284 3.185 0 5.143 2.293 5.91 7.133a.667.667 0 101.317-.21z"
          fill="#373E4D"
        />
        <path
          d="M54.85 19.453l3.567 3.566-3.566 3.566-3.566-3.566 3.566-3.566zM45.936 53.33a2.521 2.521 0 113.565-3.566 2.521 2.521 0 01-3.565 3.565zM14.445 18.965a1.778 1.778 0 112.514-2.515 1.778 1.778 0 01-2.514 2.515zM9.668 20.244a1.778 1.778 0 112.514-2.514 1.778 1.778 0 01-2.514 2.514zM13.44 24.016a1.778 1.778 0 112.514-2.514 1.778 1.778 0 01-2.514 2.514z"
          fill="#F38374"
        />
        <path
          d="M20.204 39.788l-1.776-6.629m-7.925 25.249l-3.137 1.411a1.034 1.034 0 01-1.368-1.368L17.43 33.046a1.034 1.034 0 011.675-.307l13.973 13.973a1.034 1.034 0 01-.307 1.675l-19.744 8.885-2.524 1.136zm1.94-13.314l2.944 10.992-2.945-10.992zm11.072 7.05l-1.384-5.166 1.384 5.166zm-1.982-7.396l-.597-2.23.597 2.23z"
          stroke="#373E4D"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h65v65H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
