import accountsHandlers from './accountsHandlers';
import benefitsHandlers from './benefitsHandlers';
import registrationAccountsHandlers from './registration/accounts';
import registrationContextHandlers from './registration/context';
import registrationIdentitiesHandlers from './registration/identities';
import registrationTiersHandlers from './registration/tiers';
import registrationUserHandlers from './registration/users';
import userHandlers from './userHandlers';

// NOTE: Comment or uncomment the handlers that you need
const handlers = [
  ...accountsHandlers,
  ...benefitsHandlers,
  ...userHandlers,
  ...registrationAccountsHandlers,
  ...registrationContextHandlers,
  ...registrationIdentitiesHandlers,
  ...registrationTiersHandlers,
  ...registrationUserHandlers,
];

export default handlers;
