import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectDeviceVerificationState = (state: RootState) =>
  state.deviceVerification;

export const selectDeviceVerificationOpen = createSelector(
  selectDeviceVerificationState,
  (state) => state?.open,
);
