import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';
import { LinkTw } from 'components/LinkTw';
import { Paragraph, TitleLarge } from 'components/TypographyTw';

import { selectPasswordResetError } from '../store/selectors';

export const PasswordResetErrorPage = (props: RouteComponentProps) => {
  const error = useSelector(selectPasswordResetError);
  const intl = useIntl();
  let errorMessage = (
    <Paragraph>
      {intl.formatMessage({
        id: 'ErrorGeneric.Content',
        defaultMessage:
          'Please check your internet connection or try again in a few minutes. If the problem persists, please contact our support team.',
      })}
    </Paragraph>
  );
  if (error) {
    errorMessage = (
      <Paragraph>
        {intl.formatMessage(error)}.{' '}
        <LinkTw to="/password-reset/email" trackName="password-reset">
          {intl.formatMessage({
            id: 'ErrorGeneric.TryAgain',
            defaultMessage: 'Please try again!',
          })}
        </LinkTw>
      </Paragraph>
    );
  }

  return (
    <>
      <KDSIcons.Spot.Wait />
      <TitleLarge>
        {intl.formatMessage({
          id: 'ErrorGeneric.Title',
          defaultMessage: 'Oops! Something went wrong',
        })}
      </TitleLarge>
      {errorMessage}
    </>
  );
};
