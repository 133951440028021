import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import InputAdornment from '@material-ui/core/InputAdornment';
import Skeleton from '@material-ui/lab/Skeleton';
import { CircularProgressTw } from 'components/CircularProgressTw';
import { Paragraph, TitleLarge, TitleSmall } from 'components/TypographyTw';
import { useTypedSelector } from 'hooks/redux';

import { anonymousUserActions } from '../../anonymousUser/store/slice';
import { ButtonTw } from '../../components/ButtonTw';
import { KhInputTextTw } from '../../components/inputs/KhInputTextTw';
import { smsOtpActions } from '../store/slice';

export const SmsOtp = ({ onSuccess }: { onSuccess: () => void }) => {
  const dispatch = useDispatch();
  const state = useTypedSelector((state) => state);
  const [smsRequestLoading, setSmsRequestLoading] = useState<boolean>(false);
  const intl = useIntl();

  const [secondsSinceSmsOtpResent, setSecondsSinceSmsOtpResent] =
    useState<number>(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (secondsSinceSmsOtpResent) {
        setSecondsSinceSmsOtpResent(secondsSinceSmsOtpResent - 1);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [secondsSinceSmsOtpResent]);

  useEffect(() => {
    if (state.anonymousUser.id) {
      dispatch(smsOtpActions.screenSet('request'));
    } else {
      dispatch(anonymousUserActions.fetch());
    }
  }, [state.anonymousUser.id, dispatch]);

  useEffect(() => {
    if (state.smsOtp.loadable.success && onSuccess) {
      onSuccess();
    }
  }, [state.smsOtp.loadable.success, onSuccess]);

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const numberRegex = /^[0-9\b]+$/;
    const code = event.target.value;
    if (event.target.value === '' || numberRegex.test(event.target.value)) {
      const val = code.length ? code.substr(0, 6) : '';
      dispatch(smsOtpActions.set(val));

      if (val.length === 6) {
        dispatch(smsOtpActions.verify());
      }
    }
  };

  const resendCode = () => {
    setSecondsSinceSmsOtpResent(60);
    dispatch(smsOtpActions.requestForgotPassword());
  };

  switch (state.smsOtp.screen) {
    case 'request':
      return (
        <div>
          <TitleLarge>
            {intl.formatMessage({
              id: 'SmsOtp.Screen.Title',
              defaultMessage: 'Confirm your phone number',
            })}
          </TitleLarge>
          <Paragraph>
            {intl.formatMessage({
              id: 'SmsOtp.Screen.Content',
              defaultMessage:
                'This is what we currently have on file for you. If this is correct, confirm and continue. If not, chat with us to update your phone number.',
            })}
          </Paragraph>

          {state.anonymousUser.phoneNumberExcerpt ? (
            <div className="py-2 px-4 rounded-4 mb-4">
              <TitleSmall className="legacy:text-xs">
                {intl.formatMessage({
                  id: 'SmsOtp.PhoneNumber',
                  defaultMessage: 'Phone number',
                })}
              </TitleSmall>
              <Paragraph>
                <b>+1</b> *** *** {state.anonymousUser.phoneNumberExcerpt}
              </Paragraph>
            </div>
          ) : (
            <Skeleton variant="rect" className="mb-4" height={54} />
          )}

          {state.anonymousUser.phoneNumberExcerpt ? (
            <ButtonTw
              trackName="Request SMS-OTP"
              type="button"
              onClick={() => {
                dispatch(smsOtpActions.requestForgotPassword());
                setSmsRequestLoading(true);
              }}
            >
              {smsRequestLoading ? (
                <CircularProgressTw variant="large" />
              ) : (
                intl.formatMessage({
                  id: 'Global.Button.Continue',
                  defaultMessage: 'Continue',
                })
              )}
            </ButtonTw>
          ) : (
            <Skeleton variant="rect" height={54} />
          )}
        </div>
      );
    case 'enter':
      return (
        <form onSubmit={(e) => e.preventDefault()}>
          <TitleLarge>
            {intl.formatMessage({
              id: 'SmsOtp.Enter.Title',
              defaultMessage: 'Enter your code',
            })}
          </TitleLarge>
          <Paragraph>
            {intl.formatMessage(
              {
                id: 'SmsOtp.Enter.Content',
                defaultMessage: `We've sent a code to your phone number ending in {boldNumber} — please enter it below. Be sure not to share this info with anyone!`,
              },
              { boldNumber: <b>{state.anonymousUser.phoneNumberExcerpt}</b> },
            )}
          </Paragraph>

          <KhInputTextTw
            label={intl.formatMessage({
              id: 'Global.Label.SmsCode',
              defaultMessage: 'SMS Code',
            })}
            onChange={onChange}
            autoFocus
            id="sms-otp"
            value={state.smsOtp.otp}
            className="mb-4 appearance-none [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-inner-spin-button]:m-0"
            error={!!state.smsOtp.loadable.error}
            helperText={
              state.smsOtp.loadable.error
                ? intl.formatMessage({
                    id: 'SmsOtp.Enter.Error',
                    defaultMessage:
                      'Oops! The code you entered is wrong or expired. Try again or tap resend code.',
                  })
                : ''
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {state.smsOtp.loadable.loading && (
                    <div className="w-6 h-6 pr-4">
                      <CircularProgressTw variant="large" />
                    </div>
                  )}
                </InputAdornment>
              ),
            }}
            inputProps={{ inputMode: 'numeric' }}
          />
          <ButtonTw
            type="button"
            onClick={resendCode}
            trackName="verify SMS-OTP"
            variant="tertiary"
            disabled={!!secondsSinceSmsOtpResent}
          >
            {secondsSinceSmsOtpResent === 0
              ? intl.formatMessage({
                  id: 'SmsOtp.Resend',
                  defaultMessage: 'Resend me the code',
                })
              : intl.formatMessage(
                  {
                    id: 'SmsOtp.ResendAfterSeconds',
                    defaultMessage: `Resend code after {time} seconds`,
                  },
                  { time: secondsSinceSmsOtpResent },
                )}
          </ButtonTw>
        </form>
      );
    default:
      return null;
  }
};
