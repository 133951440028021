export function getMobileOperatingSystem() {
  if (typeof window !== `undefined`) {
    const userAgent = navigator.userAgent || navigator.vendor;

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/android/i.test(userAgent)) {
      return 'android';
    } else if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      !(window as any).MSStream
    ) {
      return 'ios';
    } else {
      return 'unknown';
    }
  } else {
    return 'unknown';
  }
}
