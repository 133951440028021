import React, { useContext } from 'react';

import { LocaleContext } from '../components';

// got sick of trying to remember how to format money correctly. bon apetit.
export function useMoneyFormatter() {
  const context = useContext(LocaleContext);
  return React.useCallback(
    (amount) => context.intlFormatMoney(amount).format(),
    [context],
  );
}
