import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@reach/router';
import {
  LoginFailedResponse,
  useLoginMutation,
  useNewLoginMutation,
} from 'apis/auth';
// components
import { ErrorParagraph } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { LinkTw } from 'components/LinkTw';
import { useValidationMsg } from 'components/forms/useValidationMsg';
import { KhInputEmailTw } from 'components/inputs/KhInputEmailTw';
import { KhInputPasswordTw } from 'components/inputs/KhInputPasswordTw';
import { IdentityVerificationFlags } from 'identity-verification/IdentityVerificationFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MFAFeatureFlags } from 'mfa/models/MFAFeatureFlags';
import { useGetErrorMessage } from 'utility/useGetAuthErrorMessage';

import { AnalyticsFeatureFlags } from '../../analytics/AnalyticsFeatureFlags';
import { LoginFormSchema, zodLoginForm } from '../zodForms/zodFormLogin';

export const LoginForm = ({
  emailAddress,
  setFailedLoginAttempts,
}: {
  emailAddress?: string;
  setFailedLoginAttempts?: Dispatch<SetStateAction<number>>;
}) => {
  const getErrorMessage = useGetErrorMessage();
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = useForm<LoginFormSchema>({
    mode: 'onTouched',
    resolver: zodResolver(zodLoginForm),
  });

  const [login, loginData] = useLoginMutation({
    fixedCacheKey: 'shared-login',
  });

  const [newLogin, newLoginData] = useNewLoginMutation();

  const identityVerificationEnabled =
    useFlags()[IdentityVerificationFlags.kycChallengesEnabled];
  const removeProfileProperties =
    useFlags()[AnalyticsFeatureFlags.RemoveSegmentProfileProperties];

  const enableNewLogin =
    useFlags()[MFAFeatureFlags.EnableNewLogin] ||
    localStorage.getItem('2SV_ENABLED') === 'true';

  const onNewLoginSubmit: SubmitHandler<LoginFormSchema> = async ({
    email,
    'current-password': password,
  }) => {
    await newLogin({
      email,
      password,
    });
  };

  const onLoginSubmit = async ({
    email,
    'current-password': password,
  }: LoginFormSchema) => {
    try {
      await login({
        login: { email, password },
        removeProfileProperties,
        identityVerificationEnabled,
      }).unwrap();
    } catch (err) {
      const errRes = err as LoginFailedResponse;
      let errorMsg = getLoginErrorMsgByStatusCode(errRes.status);
      setErrorMessage(intl.formatMessage(errorMsg));

      if (setFailedLoginAttempts) {
        setFailedLoginAttempts((prevState) => prevState + 1);
      }
    }
  };

  useEffect(() => {
    if (emailAddress) {
      setValue('email', emailAddress);
      trigger('email');
    }
  }, [emailAddress, dispatch, setValue, trigger]);

  return (
    <>
      <form
        onSubmit={handleSubmit(
          enableNewLogin ? onNewLoginSubmit : onLoginSubmit,
        )}
        noValidate
      >
        <div className="mb-4">
          <KhInputEmailTw
            label={intl.formatMessage({
              id: 'Login.LoginForm.EmailLabel',
              defaultMessage: 'Email',
            })}
            trackName="login-input-email"
            {...register('email')}
            error={!!errors.email}
            helperText={useValidationMsg(errors.email?.message)}
            data-cy="email-input"
            id="email"
            autoComplete="username"
          />
        </div>
        <div className="mb-4">
          <KhInputPasswordTw
            {...register('current-password')}
            label={intl.formatMessage({
              id: 'Login.LoginForm.Label',
              defaultMessage: 'Password',
            })}
            trackName="login-input-password"
            error={!!errors['current-password']}
            helperText={useValidationMsg(errors['current-password']?.message)}
            data-cy="password-input"
            id="current-password"
            autoComplete="current-password"
          />
        </div>
        <div className="flex justify-start mb-6">
          <ButtonTw
            type="button"
            variant="tertiary"
            className="w-auto ps-0 pe-0"
            onClick={() => {
              navigate('/password-reset/email', {
                state: { emailAddress: getValues('email') },
              });
            }}
            trackName="Forgot Password"
          >
            {intl.formatMessage({
              id: 'LoginForm.ForgotPassword',
              defaultMessage: 'Forgot Password?',
            })}
          </ButtonTw>
        </div>
        <div className="mb-4">
          <ErrorParagraph data-cy="login-error">
            {loginData.isError && errorMessage}
            {newLoginData.isError &&
              getErrorMessage(
                (newLoginData.error as { status: number }).status,
              )}
          </ErrorParagraph>
        </div>
        <ButtonTw
          className="mb-6"
          data-cy="btn-login"
          disabled={!isValid || loginData.isLoading || newLoginData.isLoading}
          loading={loginData.isLoading || newLoginData.isLoading}
          trackName="Login"
        >
          {intl.formatMessage({
            id: 'LoginForm.Button',
            defaultMessage: 'Log in',
          })}
        </ButtonTw>
        <div className="text-center">
          <LinkTw to="/signUp" trackName="SignUp">
            {intl.formatMessage({
              id: 'LoginForm.Signup',
              defaultMessage: 'Sign up',
            })}
          </LinkTw>
        </div>
      </form>
    </>
  );
};

const errorMessages = defineMessages({
  default: {
    id: 'LoginError.Error.TryAgain',
    defaultMessage: 'Failed to Login. Please try again',
  },
  invalid: {
    id: 'LoginForm.Error.InvalidCredentials',
    defaultMessage: 'The email and password combination entered is invalid',
  },
  tooMany: {
    id: 'LoginForm.Error.TooManyRequests',
    defaultMessage:
      'Oops, you’ve tried to log in too many times. Please try again later',
  },
  unknown: {
    id: 'LoginForm.Error.Unknown',
    defaultMessage: 'Oops something went wrong. Please try again later',
  },
});

const getLoginErrorMsgByStatusCode = (errStatus: number) => {
  let msg = errorMessages.default;

  if (errStatus === 401) {
    msg = errorMessages.invalid;
  }

  if (errStatus === 429) {
    msg = errorMessages.tooMany;
  }

  if (errStatus === 500) {
    msg = errorMessages.unknown;
  }

  return msg;
};
