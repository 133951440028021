/** @jsxImportSource theme-ui */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { RouteComponentProps } from '@reach/router';
import { TextInput, Validators } from 'components/forms';
import { PasswordValidationRules } from 'components/inputs/PasswordValidationRules';
import { useTypedSelector } from 'hooks/redux';
import {
  selectAuthenticationSuccess,
  selectPasswordResetError,
} from 'passwordReset/store/selectors';
import { validatePasswordComplexity } from 'utility/validatePassword';

import { trackInput } from '../analytics/analytics';
import { Button, SpinnerCircularProgress, TitleMedium } from '../components';
import { SecurityAssurance } from '../components';
import * as Text from '../components/Typography';
import { InputPassword, InputPasswordProps } from './Components/InputPassword';
import { PasswordResetUserType, passwordResetActions } from './store/slice';

export const PasswordResetPassword = (_: RouteComponentProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [passwordValid, setPasswordValid] = useState<boolean | undefined>(
    undefined,
  );
  const [confirmInput, setConfirmInput] = useState<string>('');
  const [confirmValid, setConfirmValid] = useState<boolean>(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    hasMinLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
  });

  const state = useTypedSelector((state) => state);
  const { userType } = state.passwordReset;
  const { password } = state.passwordReset.request;
  const requestError = useSelector(selectPasswordResetError);
  const authenticationChecked = useSelector(selectAuthenticationSuccess);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Added new track event to override legacy event with the wrong name
    trackInput({
      type: 'Clicked',
      name: 'Update password CTA',
      details: { type: 'submit', element: 'button' },
    });

    if (userType === PasswordResetUserType.Identity) {
      dispatch(passwordResetActions.submitForIdentityUser());
    } else {
      dispatch(passwordResetActions.submitWithSmsOtp());
    }
  };

  const onChange: InputPasswordProps['onValueChange'] = ({ valid, value }) => {
    const isPasswordMatch = value === confirmInput;

    setPasswordValid(valid);
    setConfirmValid(isPasswordMatch);
    setPasswordRequirements(validatePasswordComplexity(value));
    dispatch(passwordResetActions.updateResetError(null));
    dispatch(passwordResetActions.requestValue({ password: value }));
  };

  const onConfirmChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valid = password === value;

    setConfirmValid(valid);
    setConfirmInput(value);
    dispatch(passwordResetActions.updateResetError(null));
  };

  if (userType === 'identity' || authenticationChecked) {
    return (
      <form sx={{ '& > *': { mb: 3 } }} onSubmit={onSubmit}>
        <TitleMedium>
          {intl.formatMessage({
            id: 'UpdatePassword.Title',
            defaultMessage: 'Create new password',
          })}
        </TitleMedium>

        <Text.Paragraph sx={{ mt: 16, mb: 24 }}>
          {intl.formatMessage({
            id: 'UpdatePassword.ReusedPasswordMessage',
            defaultMessage:
              'Remember to choose a password that is different from your old passwords.',
          })}
        </Text.Paragraph>

        <InputPassword
          autoFocus
          label={`${intl.formatMessage({
            id: 'PasswordReset.InputPasswordField',
            defaultMessage: 'Password',
          })}`}
          onValueChange={onChange}
        />

        <TextInput
          variant="outlined"
          name="Confirm Password Input"
          type={'password'}
          value={confirmInput}
          label={`${intl.formatMessage({
            id: 'PasswordReset.InputConfirmPasswordField',
            defaultMessage: 'Confirm Password',
          })}`}
          helperText={requestError && intl.formatMessage(requestError)}
          validators={[Validators.passwordMatch(password)]}
          error={(!confirmValid && confirmInput.length !== 0) || !!requestError}
          onChange={onConfirmChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {confirmValid && (
                  <CheckCircleIcon sx={{ color: 'success', mr: 2 }} />
                )}
              </InputAdornment>
            ),
          }}
          id="confirm-password"
          autoComplete="confirm-password"
        />

        <PasswordValidationRules
          sx={{ mt: 4 }}
          passwordRequirements={passwordRequirements}
        />

        <Button
          type="submit"
          trackName="Resend device verification email"
          disabled={!passwordValid || !confirmValid || !!requestError}
        >
          {intl.formatMessage({
            id: 'UpdatePassword.Button',
            defaultMessage: 'Update password',
          })}
        </Button>

        <SecurityAssurance />
      </form>
    );
  } else {
    return (
      <div sx={{ display: 'flex', justifyContent: 'center' }}>
        <SpinnerCircularProgress />
      </div>
    );
  }
};
