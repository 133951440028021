/** @jsxImportSource theme-ui */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { zodResolver } from '@hookform/resolvers/zod';
import MuiBox from '@material-ui/core/Box';
import { useOtpRequestMutation } from 'apis/registration';
import {
  Button,
  ErrorParagraph,
  Link,
  Paragraph,
  TitleLarge,
} from 'components';
import {
  InputValidationMsg,
  useValidationMsg,
} from 'components/forms/useValidationMsg';
import { KhInputText } from 'components/inputs/KhInputText';
import { selectLoginTelLast4Digits } from 'login/store/selectors';
import { selectProfileTelLast4Digits } from 'profile/store/selectors';
import {
  RegistrationSmsOtpVerifyForm,
  zodFormRegistrationSmsOtpVerify,
} from 'registration/zodForms/zodFormRegistrationSmsOtp';
import {
  useTrackElementViewedOnce,
  useTrackPageViewedOnceDeprecated,
} from 'utility/analyticsHooks';

export const RegistrationSmsOtpPage = ({
  phoneNumber,
  editMobileNumber,
  mobileNumberEdited,
  smsOtpInvalid,
  setSmsOtpInvalid,
  submitVerifyOtp,
  isLoading,
}: {
  phoneNumber: string;
  editMobileNumber: () => void;
  mobileNumberEdited: string;
  smsOtpInvalid: boolean;
  setSmsOtpInvalid: (invalid: boolean) => void;
  submitVerifyOtp: (data: RegistrationSmsOtpVerifyForm) => Promise<void>;
  isLoading: boolean;
}) => {
  const intl = useIntl();

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<RegistrationSmsOtpVerifyForm>({
    mode: 'onBlur',
    resolver: zodResolver(zodFormRegistrationSmsOtpVerify),
  });

  const [requestOtp, requestOtpMutationData] = useOtpRequestMutation();

  const loginMaskedTel = useSelector(selectLoginTelLast4Digits);
  const profileMaskedTel = useSelector(selectProfileTelLast4Digits);
  const maskedPhoneNumber = loginMaskedTel || profileMaskedTel;
  const mobileNumberUnedited = maskedPhoneNumber
    ? maskedPhoneNumber
    : phoneNumber;

  useTrackElementViewedOnce({
    element: errors.otp,
    name: 'verification-code-input-error',
  });

  useTrackElementViewedOnce({
    element: smsOtpInvalid,
    name: 'verification-code-input-error',
  });

  useTrackElementViewedOnce({
    element: requestOtpMutationData.error,
    name: 'otp-request-error',
  });

  const [smsOtpRequestedOnLoad, setSmsOtpRequestedOnLoad] =
    React.useState(false);

  useTrackPageViewedOnceDeprecated({
    name: 'registration-sms-otp',
    overrideUrl: `registration/sms-otp`,
  });

  if (!smsOtpRequestedOnLoad) {
    requestOtp();
    setSmsOtpRequestedOnLoad(true);
  }

  const submitRequestOtp = () => {
    requestOtp()
      .unwrap()
      .then(() => {
        setSecondsSinceSmsOtpResent(60);
      });
  };

  const [secondsSinceSmsOtpResent, setSecondsSinceSmsOtpResent] =
    React.useState<number>(0);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (secondsSinceSmsOtpResent) {
        setSecondsSinceSmsOtpResent(secondsSinceSmsOtpResent - 1);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [secondsSinceSmsOtpResent]);

  return (
    <form onSubmit={handleSubmit(submitVerifyOtp)}>
      <TitleLarge sx={{ mb: 4 }} data-cy="sms-otp-title">
        {intl.formatMessage({
          id: 'RegistrationSmsOtpPage.Title',
          defaultMessage: 'Verify your mobile number',
        })}
      </TitleLarge>
      <MuiBox sx={{ mb: 4 }}>
        <Paragraph sx={{ mb: 0 }}>
          {intl.formatMessage(
            {
              id: 'RegistrationSmsOtpPage.Instructions',
              defaultMessage:
                'Enter the 6 digit code we texted to you at {mobile}. ',
            },
            {
              mobile: mobileNumberEdited
                ? mobileNumberEdited
                : mobileNumberUnedited,
            },
          )}
        </Paragraph>

        <Button
          type="button"
          variant="tertiary"
          onClick={editMobileNumber}
          sx={{ p: 0, width: 'auto' }}
          trackName="Edit Phone Number Clicked"
        >
          {intl.formatMessage({
            id: 'RegistrationSmsOtpPage.EditNumber.Button',
            defaultMessage: `Edit number.`,
          })}
        </Button>
      </MuiBox>
      <KhInputText
        {...register('otp', { onChange: () => setSmsOtpInvalid(false) })}
        placeholder={intl.formatMessage({
          id: 'RegistrationSmsOtpPage.InputPlaceholder',
          defaultMessage: 'Verification code',
        })}
        error={!!errors.otp}
        helperText={useValidationMsg(
          smsOtpInvalid ? InputValidationMsg.InvalidCode : errors.otp?.message,
        )}
        sx={{ mb: 3 }}
        data-cy="sms-otp-code-input"
        trackName="verification-code"
      ></KhInputText>
      <label sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <Paragraph sx={{ my: 0, pt: '2px' }}>
          {intl.formatMessage(
            {
              id: 'RegistrationSmsOtpPage.TermsConditions',
              defaultMessage:
                'I have read and agree to be bound by KOHO’s <terms>terms and conditions</terms>.',
            },
            {
              terms: TermsConditionsLink,
            },
          )}
        </Paragraph>
      </label>

      <Button
        type="submit"
        sx={{ mb: 3 }}
        disabled={!isValid || isLoading}
        loading={isLoading}
        data-cy="sms-otp-cta-button"
        trackName="move-verify-mobile"
      >
        {intl.formatMessage({
          id: 'RegistrationSmsOtpPage.Next',
          defaultMessage: 'Next',
        })}
      </Button>

      <Button
        type="button"
        variant="tertiary"
        onClick={submitRequestOtp}
        loading={requestOtpMutationData.isLoading}
        trackName="resend-code"
        disabled={!!secondsSinceSmsOtpResent}
      >
        {secondsSinceSmsOtpResent === 0
          ? intl.formatMessage({
              id: 'RegistrationSmsOtpPage.Resend',
              defaultMessage: 'Didn’t receive it? Resend code',
            })
          : intl.formatMessage(
              {
                id: 'RegistrationSmsOtpPage.ResendAfterSeconds',
                defaultMessage: `Resend code in {time}s`,
              },
              { time: secondsSinceSmsOtpResent },
            )}
      </Button>
      {requestOtpMutationData.isError && (
        <ErrorParagraph
          sx={{
            textAlign: 'center',
          }}
        >
          {intl.formatMessage({
            id: 'RegistrationSmsOtpPage.Error',
            defaultMessage: `Something went wrong. Please try again.`,
          })}
        </ErrorParagraph>
      )}
    </form>
  );
};

const TermsConditionsLink = (str: string) => {
  const intl = useIntl();

  return (
    <Link
      to={intl.formatMessage({
        id: 'RegistrationSmsOtpPage.TermsConditionsLink',
        defaultMessage:
          'https://www.koho.ca/legal/?ver=mastercard#CardHolderAgreement',
      })}
      external={true}
      trackName="registration - Terms and Conditions link"
    >
      {str}
    </Link>
  );
};
