// File meant for analytics relating to global tag manager

type TId = 'cpBzCOuZ4ZYDEI-zyK4D';

export const trackRemarketing = (id: TId) => {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: `AW-902961551/${id}`,
      value: 1.0,
      currency: 'CAD',
      aw_remarketing_only: true,
    });
  }
};
