import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  Loadable,
  createDefaultLoadable,
  createErrorLoadable,
  createLoadingLoadable,
  createSuccessLoadable,
} from '../../utility/loadable';
import { AccountCard, CardImage } from '../models';

export interface CardsState {
  cards: {
    data: AccountCard[];
    loadable: Loadable;
  };
  cardImage: {
    data: CardImage;
    loadable: Loadable;
  };
}

const initialState: CardsState = {
  cards: {
    data: [],
    loadable: createDefaultLoadable(),
  },
  cardImage: {
    data: {
      card_url: '',
    },
    loadable: createDefaultLoadable(),
  },
};

const cardsSlice = createSlice({
  name: 'cards',
  initialState: initialState,
  reducers: {
    changeCardUsabilityRequest: (
      state,
      _action: PayloadAction<{
        cardId: string;
        isLocked: boolean;
        successMessage: string;
      }>,
    ) => {
      state.cards.loadable = createLoadingLoadable();
    },
    changeCardUsabilityResponse: (
      state,
      action: PayloadAction<AccountCard>,
    ) => {
      state.cards.loadable = createSuccessLoadable();
      if (state.cards && state.cards.data && state.cards.data.length) {
        Object.assign(state.cards.data[0], action.payload);
      }
    },
    changeCardUsabilityError: (state) => {
      state.cards.loadable = createErrorLoadable(
        'Failed to lock or unlock card',
      );
    },
    noop: (state) => state,
  },
});

export const cardsActions = cardsSlice.actions;
export default cardsSlice.reducer;
