import { rest } from 'msw';

const registrationAccountsHandlers = [
  rest.post(/\/accounts/, (req, res, ctx) => {
    return res(
      ctx.status(201),
      ctx.json({
        id: 'aaaaaaaa-a628-42a2-8988-a763f35266a8',
        group_id: '5a077faa-a882-4aa1-9167-11526a8aaa28',
        account_number: '123456789123',
        is_primary: true,
        is_root: true,
        network: '',
        is_physical: false,
        card_design: 'new-virtual',
        created_at: '2023-01-05T10:17:48.171986Z',
        updated_at: '2023-01-05T10:17:48.171986Z',
      }),
    );
  }),
];

export default registrationAccountsHandlers;
