import axios, { AxiosResponse } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RootState } from 'store';

import { Balance } from '../models';
import { balanceActions } from './slice';

interface BalanceResponse {
  data: { [groupdIdentifier: string]: Balance };
  status_code: number;
  status_name: string;
}

const getBalanceEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(balanceActions.getBalanceRequest.toString()),
    switchMap(() => {
      return from(
        axios.get(`${import.meta.env.VITE_GATEWAY_API}accounts/balance`),
      ).pipe(
        map((response: AxiosResponse<BalanceResponse>) =>
          balanceActions.getBalanceResponse(response.data.data),
        ),
        catchError(() => {
          return of(balanceActions.getBalanceError());
        }),
      );
    }),
  );
};

const exportedArray = [getBalanceEpic];
export default exportedArray;
