import { ReactNode } from 'react';

import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { theme } from 'theme';

type Props = {
  children: ReactNode;
  dataCy?: string;
  onSelect: () => void;
  selectable: boolean;
  selected: boolean;
};

export const Card = ({
  children,
  dataCy,
  onSelect,
  selectable,
  selected,
}: Props) => {
  const muiTheme = useTheme();
  const isAfterTablet = useMediaQuery(muiTheme.breakpoints.up('md'));

  return (
    <Box
      data-cy={dataCy}
      tabIndex={selectable ? 0 : undefined}
      onClick={onSelect}
      onKeyDown={(e) => {
        (e.code === 'Space' || e.code === 'Enter') && onSelect();
      }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        borderRadius: '8px',
        border: `1px solid ${
          selected ? theme.colors.blueNavy : theme.colors.blueLight
        }`,
        boxShadow: '3px 6px 15px 0px #0000001A',
        padding: '16px 16px 24px 16px',
        maxWidth: isAfterTablet ? '352px' : 'unset',
        ...(selectable
          ? {
              cursor: 'pointer',
              transition: 'all 0.2s ease-in-out',
              ':hover': {
                border: `1px solid ${theme.colors.blueNavy}`,
              },
            }
          : {}),
      }}
    >
      {children}
    </Box>
  );
};
