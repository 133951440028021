import { useIntl } from 'react-intl';

import { ICurrentBenefit } from 'apis/tiers';
import { useTiersData } from 'tiers/hooks/useTiersData';

import { Step, StepIcons } from '../tierChangeSteps';

export type DowngradeStepsProps = {
  frequency: string;
  newTierCost: string;
  newTierName: string;
  oldTier: ICurrentBenefit;
};

export const DowngradeSteps = ({
  frequency,
  newTierCost,
  newTierName,
  oldTier,
}: DowngradeStepsProps) => {
  const intl = useIntl();
  const { getTierName } = useTiersData();

  const oldTierName = oldTier.benefit_group
    ? getTierName(oldTier.benefit_group?.name)
    : '';

  const downgradeDate = oldTier.next_payment_date
    ? intl.formatDate(oldTier.next_payment_date, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
    : '';

  const todayTitle = intl.formatMessage({
    id: 'DowngradeSuccess.Modal.Today',
    defaultMessage: 'Today',
  });

  const costPerFrequency =
    frequency === 'monthly'
      ? intl.formatMessage(
          {
            id: 'DowngradeSuccess.Modal.PlanCost.monthly',
            defaultMessage: '{cost}/month',
          },
          { cost: newTierCost },
        )
      : intl.formatMessage(
          {
            id: 'DowngradeSuccess.Modal.PlanCost.yearly',
            defaultMessage: '{cost}/year',
          },
          { cost: newTierCost },
        );

  return (
    <>
      <Step
        isFirst
        icon={<StepIcons.Unlock />}
        title={todayTitle}
        description={intl.formatMessage(
          {
            id: 'DowngradeSuccess.Modal.FirstStep',
            defaultMessage: 'You keep your {oldTierName} benefits',
          },
          { oldTierName },
        )}
      />
      <Step
        isLast
        icon={<StepIcons.Calendar />}
        title={downgradeDate}
        description={intl.formatMessage(
          {
            id: 'DowngradeSuccess.Modal.LastStep',
            defaultMessage:
              "Your {newTierName} benefits start, and you'll pay {costPerFrequency}",
          },
          { newTierName, costPerFrequency },
        )}
      />
    </>
  );
};
