import { ButtonHTMLAttributes, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton } from '@material-ui/lab';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { PaymentForm } from 'addFunds/components/PaymentForm';
import {
  PaymentMethod,
  easyloadApi,
  useDeletePaymentMethodMutation,
} from 'apis/easyloadApi';
import { ReactComponent as EmptyCard } from 'assets/images/empty-card.svg';
import { KDSIcons } from 'assets/images/kds_icons';
import {
  selectBalanceLoadable,
  selectBalancePersonalSpendable,
} from 'balance/store/selectors';
import { balanceActions } from 'balance/store/slice';
import {
  Box,
  Button,
  LocaleContext,
  Modal,
  Paragraph,
  Template,
  TitleLarge,
  TitleSmall,
} from 'components';
import { TextInputPayment, Validators } from 'components/forms';
import { SuccessPopup } from 'components/popups/SuccessPopup';
import { selectProfileName } from 'profile/store/selectors';
import { theme } from 'theme';

import {
  FundingStatusModal,
  FundingStatusScreenStates,
} from '../components/FundingStatusModal';
import { PaymentIntentButton } from '../components/PaymentIntentButton';
import { PaymentMethodCard } from '../components/PaymentMethodCard';
import { StripeErrorMessage } from '../components/StripeErrorMessage';

// stripe publishable key
let stripePromise: Promise<Stripe | null>;

try {
  // fails in Storybook because we still use webpack and import.meta
  // is not supported in webpack
  stripePromise = loadStripe(import.meta.env.VITE_STRIPE_SDK_KEY).catch((e) => {
    // Catch script loading errors. See @stripe/stripe-js/dist/stripe.mjs
    console.error('Failed to load remote stripe sdk: ', e);
    return Promise.resolve(null);
  });
} catch (e) {
  console.error('Failed to load local stripe sdk: ', e);
  stripePromise = Promise.resolve(null);
}

const moneyAmounts = ['20.00', '50.00', '100.00', '200.00', '500.00'];
const MIN_VALUE = 100;

enum SuccessMessages {
  CardAdded,
  CardRemoved,
}

export const LoadDebitCardPage = () => {
  const context = useContext(LocaleContext);
  const intl = useIntl();
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [moneyToLoad, setMoneyToLoad] = useState<string>(moneyAmounts[0]);
  const [cardUpForDeletion, setCardUpForDeletion] = useState<PaymentMethod>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number>(0);
  const [hasSufficientBalance, setHasSufficientBalance] =
    useState<boolean>(false);

  const [successMessage, setSuccessMessage] = useState<SuccessMessages>();
  const [showCardChangePopup, setShowCardChangePopup] =
    useState<boolean>(false);

  const [openPaymentForm, setOpenPaymentForm] = useState<boolean>(false);
  const [waitingForFunds, setWaitingForFunds] = useState<boolean>(false);
  const [fundingScreen, setFundingScreen] =
    useState<FundingStatusScreenStates>('done');
  const [declinePromptOpen, setDeclinePromptOpen] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [deletePaymentMethod] = useDeletePaymentMethodMutation();
  const [getPaymentMethods, paymentMethodRequest] =
    easyloadApi.useLazyGetPaymentMethodsQuery();

  const paymentMethods = paymentMethodRequest.currentData;

  const name = useSelector(selectProfileName);
  const balance = useSelector(selectBalancePersonalSpendable);
  const balanceLoadable = useSelector(selectBalanceLoadable);

  const handleOnChange = (e) => {
    const value = context.intlFormatMoney(e.currentTarget.value);
    const valueStripped = value.format().replace('$', '');

    setMoneyToLoad(valueStripped);
  };

  const handleOnValidChange = (valid) => {
    setHasSufficientBalance(valid);
  };

  const displaySuccessPopup = (msg: SuccessMessages) => {
    setSuccessMessage(msg);
    setShowCardChangePopup(true);
    setTimeout(() => setShowCardChangePopup(false), 3000);
  };

  useEffect(() => {
    dispatch(balanceActions.getBalanceRequest());
    getPaymentMethods();
  }, [dispatch, getPaymentMethods]);

  const minAmount = context.intlFormatMoney(MIN_VALUE);

  const userHasNoCards = paymentMethods?.payment_methods.length === 0;

  return (
    <Template name="StripeLoad" variant="center">
      <TitleLarge sx={{ mb: 3 }}>
        {intl.formatMessage({
          id: 'LoadDebitCardPage.Title',
          defaultMessage: 'Load debit card',
        })}
      </TitleLarge>

      {balanceLoadable.loading && (
        <Skeleton variant="rect" width="100%" height={32} sx={{ mb: 2 }} />
      )}
      {balanceLoadable.success && (
        <TitleSmall sx={{ my: 2 }}>
          {intl.formatMessage(
            {
              id: 'LoadDebitCardPage.AvailableBalance',
              defaultMessage: 'Spendable balance: {balance}',
            },
            { balance: context.intlFormatMoney(balance).format() },
          )}
        </TitleSmall>
      )}

      <Box sx={{ my: 2 }}>
        {balance && (
          <TextInputPayment
            onChange={handleOnChange}
            onValidChange={handleOnValidChange}
            controlledValue={context.intlFormatMoney(moneyToLoad).format()}
            validators={[
              Validators.required,
              Validators.min(
                minAmount.toNumber(),
                intl,
                intl.formatMessage(
                  {
                    id: 'TransferAmount.Validation.MinAmount',
                    defaultMessage:
                      "Please enter an amount that's more than {amount}",
                  },
                  { amount: minAmount.format() },
                ),
              ),
            ]}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
        {moneyAmounts.map((amount) => (
          <MoneyButton
            key={amount}
            onClick={() => setMoneyToLoad(amount)}
            selected={amount === moneyToLoad}
          >
            {context.intlFormatMoney(amount).format('pretty')}
          </MoneyButton>
        ))}
      </Box>

      <Box
        sx={{
          pt: 3,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TitleSmall sx={{ flex: 1 }}>
          {editMode
            ? intl.formatMessage({
                id: 'LoadDebitCardPage.PaymentMethod.Manage',
                defaultMessage: 'Manage my cards',
              })
            : intl.formatMessage({
                id: 'LoadDebitCardPage.PaymentMethod.Title',
                defaultMessage: 'Payment method',
              })}
        </TitleSmall>

        {userHasNoCards || (
          <Button
            sx={{
              width: 'auto',
              '&:active': { backgroundColor: theme.colors.greyXLight },
            }}
            variant="clear"
            trackName="Manage debit cards"
            onClick={() => setEditMode(!editMode)}
          >
            {intl.formatMessage({
              id: 'LoadDebitCardPage.ManageCards',
              defaultMessage: 'Manage',
            })}
          </Button>
        )}
      </Box>

      {paymentMethodRequest.isFetching && (
        <Skeleton variant="rect" width="100%" height={108} sx={{ mb: 5 }} />
      )}

      <Box sx={{ mb: 5 }}>
        {paymentMethods && !paymentMethodRequest.isFetching && (
          <div>
            {paymentMethods.payment_methods.map((paymentMethod, i) => (
              <PaymentMethodCard
                key={paymentMethod.id}
                paymentMethod={paymentMethod}
                user={name}
                editForm={editMode}
                checked={selectedPaymentMethod === i}
                onClick={
                  editMode
                    ? (paymentMethod?: PaymentMethod) => {
                        setCardUpForDeletion(paymentMethod);
                        return setDeclinePromptOpen(true);
                      }
                    : () => setSelectedPaymentMethod(i)
                }
              />
            ))}
          </div>
        )}

        {paymentMethodRequest.isSuccess &&
          !paymentMethodRequest.isFetching &&
          userHasNoCards && (
            <Box
              sx={{
                textAlign: 'center',
                p: 4,
                backgroundColor: theme.colors.greyXLight,
                borderRadius: '6px',
              }}
            >
              <EmptyCard />
              <Paragraph>
                {intl.formatMessage({
                  id: 'LoadDebitCardPage.NoPaymentMethods',
                  defaultMessage:
                    'Add a debit card to view and manage your cards here',
                })}
              </Paragraph>
              <Button
                variant="outline"
                trackName="Add new funding card"
                onClick={() => setOpenPaymentForm(true)}
              >
                {intl.formatMessage({
                  id: 'LoadDebitCardPage.AddCard',
                  defaultMessage: 'Add new card',
                })}
              </Button>
            </Box>
          )}
      </Box>

      {paymentMethods && !userHasNoCards && !editMode && (
        <Elements stripe={stripePromise}>
          <PaymentIntentButton
            amount={moneyToLoad}
            disabled={!hasSufficientBalance}
            updateProgress={(progress) => {
              if (progress === 'loading') {
                setWaitingForFunds(true);
              }
              if (progress === 'done') {
                dispatch(balanceActions.getBalanceRequest());
              }
              setFundingScreen(progress);
            }}
            paymentMethod={
              paymentMethods.payment_methods[selectedPaymentMethod]
            }
          />
        </Elements>
      )}

      {editMode && !userHasNoCards && (
        <Elements stripe={stripePromise}>
          <AddCardButton onClick={() => setOpenPaymentForm(true)} />
        </Elements>
      )}

      <Modal
        fullWidth={true}
        maxWidth="xs"
        open={openPaymentForm}
        onClose={() => setOpenPaymentForm(false)}
      >
        <Elements stripe={stripePromise}>
          <PaymentForm
            onSubmit={() => {
              getPaymentMethods();
              setOpenPaymentForm(false);
              displaySuccessPopup(SuccessMessages.CardAdded);
            }}
          />
        </Elements>
      </Modal>

      <Modal
        open={declinePromptOpen}
        onClose={() => {
          setCardUpForDeletion(undefined);
          return setDeclinePromptOpen(false);
        }}
      >
        <TitleLarge sx={{ mt: 0 }}>
          {intl.formatMessage({
            id: 'LoadDebitCardPage.ConfirmDeleteCard.Title',
            defaultMessage: 'Remove card',
          })}
        </TitleLarge>
        <Paragraph>
          {intl.formatMessage({
            id: 'LoadDebitCardPage.ConfirmDeleteCard.Description',
            defaultMessage: 'Are you sure you want to remove your card?',
          })}
        </Paragraph>
        <Box sx={{ mb: 4 }}>
          {cardUpForDeletion && (
            <PaymentMethodCard
              viewOnly={true}
              key={cardUpForDeletion.id}
              paymentMethod={cardUpForDeletion}
              user={name}
            />
          )}
        </Box>
        <Button
          onClick={async () => {
            const card = cardUpForDeletion as PaymentMethod;

            // make the call to remove the card
            deletePaymentMethod(card.id)
              .unwrap()
              .then(() => displaySuccessPopup(SuccessMessages.CardRemoved))
              .catch(() => setIsError(true));

            if (paymentMethods?.payment_methods.length === 1) {
              setEditMode(false);
            }

            return setDeclinePromptOpen(false);
          }}
          sx={{ mb: 2 }}
          data-cy="iet-confirm-prompt-cta-confirm"
        >
          {intl.formatMessage({
            id: 'LoadDebitCardPage.ConfirmDeleteCard.Confirm',
            defaultMessage: 'Remove',
          })}
        </Button>
        <Button
          onClick={() => setDeclinePromptOpen(false)}
          data-cy="iet-confirm-prompt-cta-reject"
          variant="tertiary"
        >
          {intl.formatMessage({
            id: 'LoadDebitCardPage.ConfirmDeleteCard.Reject',
            defaultMessage: 'Cancel',
          })}
        </Button>
      </Modal>

      <FundingStatusModal
        disableEnforceFocus
        open={waitingForFunds}
        screen={fundingScreen}
        onConfirm={() => setWaitingForFunds(false)}
      />

      <Modal
        open={isError}
        closeButton={false}
        onClose={() => setIsError(false)}
      >
        <KDSIcons.Spot.Stop sx={{ mt: 4 }} />
        <TitleLarge>
          {intl.formatMessage({
            id: 'LoadDebitCardPage.ErrorModal.ErrorTitle',
            defaultMessage: 'Something went wrong...',
          })}
        </TitleLarge>
        <Paragraph sx={{ mb: 5 }}>
          {intl.formatMessage({
            id: 'LoadDebitCardPage.ErrorModal.ErrorDescription',
            defaultMessage:
              "Sorry, we weren't able to complete that action. Please try again later.",
          })}
        </Paragraph>
        <Button onClick={() => setIsError(false)}>
          {intl.formatMessage({
            id: 'LoadDebitCardPage.ErrorModal.Done',
            defaultMessage: 'Got it',
          })}
        </Button>
      </Modal>

      {showCardChangePopup && successMessage === SuccessMessages.CardAdded && (
        <SuccessPopup
          message={intl.formatMessage({
            id: 'LoadDebitCardPage.CardAdded',
            defaultMessage: 'Card added',
          })}
        />
      )}

      {showCardChangePopup &&
        successMessage === SuccessMessages.CardRemoved && (
          <SuccessPopup
            message={intl.formatMessage({
              id: 'LoadDebitCardPage.CardRemoved',
              defaultMessage: 'Card removed',
            })}
          />
        )}
    </Template>
  );
};

interface RowButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  children: React.ReactNode;
  selected?: boolean;
}

const MoneyButton = ({
  children,
  onClick,
  selected,
}: Readonly<RowButtonProps>) => {
  const style = {
    py: '14px',
    borderRadius: '4px',
    backgroundColor: selected ? theme.colors.greyXLight : 'white',
    color: selected ? theme.colors.primary : theme.colors.text,
    borderWidth: selected ? '0px' : '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.greyLight,
    fontFamily: theme.fonts.numbers,
    fontSize: '16px',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: theme.colors.greyXLight,
      cursor: 'pointer',
    },
  };

  return (
    <button
      onClick={onClick}
      sx={{ display: 'block', width: '100%', ...style }}
    >
      {children}
    </button>
  );
};

const AddCardButton = ({ onClick }) => {
  const stripe = useStripe();
  const intl = useIntl();

  if (!stripe) {
    return <StripeErrorMessage />;
  }

  return (
    <Button trackName="Add new funding card" onClick={onClick}>
      {intl.formatMessage({
        id: 'LoadDebitCardPage.AddCard',
        defaultMessage: 'Add new card',
      })}
    </Button>
  );
};
