import axios, { AxiosResponse } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { RootState } from 'store';

import { launchDarklyActions } from './slice';

interface LaunchDarklyUserHashResponse {
  secure_mode_hash: string;
  status_code: number;
  status_name: string;
}

const getUserHash: Epic<any, any, RootState> = (action$, store$) => {
  return action$.pipe(
    ofType(launchDarklyActions.getUserHashRequest.toString()),
    withLatestFrom(store$),
    map(
      ([_, state]) =>
        state.profile.data?.reference_identifier || state.passwordReset.userRef,
    ),
    filter((value) => !!value),
    switchMap((userRef) => {
      return from(
        axios.get(
          `${
            import.meta.env.VITE_GATEWAY_API
          }users/users/${userRef}/flags/hash`,
        ),
      ).pipe(
        map((response: AxiosResponse<LaunchDarklyUserHashResponse>) =>
          launchDarklyActions.getUserHashResponse(
            response.data.secure_mode_hash,
          ),
        ),
        catchError(() => of(launchDarklyActions.getUserHashError())),
      );
    }),
  );
};

const getAnonymousUserHash: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(launchDarklyActions.getAnonymousUserHashRequest.toString()),
    switchMap(() => {
      const anonymousId = store.value.launchDarkly.anonymous.id;
      return from(
        axios.get(
          `${
            import.meta.env.VITE_GATEWAY_API
          }users/users/${anonymousId}/flags/hash`,
        ),
      ).pipe(
        map((response: AxiosResponse<LaunchDarklyUserHashResponse>) =>
          launchDarklyActions.getAnonymousUserHashResponse(
            response.data.secure_mode_hash,
          ),
        ),
        catchError(() => of(launchDarklyActions.getAnonymousUserHashError())),
      );
    }),
  );
};

const exportedArray = [getUserHash, getAnonymousUserHash];
export default exportedArray;
