const SvgComponent = (props) => (
  <svg
    width={65}
    height={65}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M43.122 10.603H15a2 2 0 0 0-2 2v37.022a2 2 0 0 0 2 2h28.122a2 2 0 0 0 2-2V12.603a2 2 0 0 0-2-2Z"
      stroke="#373E4D"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M45.817 18.85h4.576a2 2 0 0 1 2 2V55.34a2 2 0 0 1-2 2H22.88a2 2 0 0 1-2-2v-2.746M19.331 29.449h18.796M19.331 32.839h18.796M19.331 39.619h11.601M19.331 36.229h18.796"
      stroke="#373E4D"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M40.228 14.73H32.04a1.109 1.109 0 1 0 0 2.217h8.187a1.109 1.109 0 1 0 0-2.217ZM37.68 18.3h-5.64a1.109 1.109 0 1 0 0 2.217h5.64a1.109 1.109 0 1 0 0-2.217Z"
      fill="#F38374"
    />
    <path
      d="M24.027 16.974v2.435c0 1.224-.968 2.217-2.162 2.217h-.372c-1.194 0-2.162-.993-2.162-2.217V16.9v.047-7.73C19.331 7.993 20.3 7 21.493 7h.372c1.194 0 2.162.993 2.162 2.217v1.385"
      stroke="#F38374"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgComponent;
