import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { IconButton, InputAdornment } from '@material-ui/core';
import { ReferralRewardsResponse } from 'apis/referralApi';
import { KDSIcons } from 'assets/images/kds_icons';
import referAndEarnHands from 'assets/images/referAndEarn/referAndEarnHands.png';
import { LocaleContext } from 'components';
import { BoxTw } from 'components/BoxTw';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph, TitleXLarge } from 'components/TypographyTw';
import { KhInputTextTw } from 'components/inputs/KhInputTextTw';

export const ReferAndEarnShare = ({
  referralsRewardsData,
}: {
  referralsRewardsData: ReferralRewardsResponse;
}) => {
  const intl = useIntl();

  const { deepLinks } = useContext(LocaleContext);

  const [linkCopied, setLinkCopied] = React.useState(false);

  const copyReferralLink = () => {
    navigator['clipboard'].writeText(referralsRewardsData?.referral_url || '');
    setLinkCopied(true);
  };
  const copyReferralId = () => {
    navigator['clipboard'].writeText(referralsRewardsData?.referral_code || '');
  };

  return (
    <BoxTw className="rounded-lg overflow-hidden shadow-[3px_6px_15px_0px_rgba(0,0,0,0.10)]">
      <BoxTw
        className="bg-primary-300 flex justify-between h-[164px] bg-no-repeat pl-6 pr-4 py-4 [background-position-x:right] bg-[length:70%] md:bg-[length:350px]"
        css={{
          backgroundImage: `url(${referAndEarnHands})`,
        }}
      >
        <TitleXLarge className="self-end text-white">
          {referralsRewardsData?.rewards_header || ''}
        </TitleXLarge>
        <a
          href={deepLinks.KOHO.ReferralFAQ || ''}
          target="_blank"
          rel="noreferrer"
          aria-label={intl.formatMessage({
            id: 'ReferAndEarnPage.Share.InfoLink',
            defaultMessage: 'An article about how to refer friends to KOHO',
          })}
          className="self-end lg:self-start"
        >
          <IconButton aria-hidden="true">
            <KDSIcons.Icons.Info className="text-white" />
          </IconButton>
        </a>
      </BoxTw>
      <BoxTw className="p-4 pb-6">
        <Paragraph className="mt-0 mb-6">
          {referralsRewardsData?.rewards_sub_header || ''}
        </Paragraph>
        <div className="flex justify-start gap-4 md:flex-row flex-col">
          <KhInputTextTw
            className="max-w-full lg:max-w-[50%]"
            value={referralsRewardsData?.referral_code || ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={copyReferralId}>
                    <KDSIcons.Icons.CopyBold className="text-white" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ButtonTw
            className="max-w-full lg:max-w-[30%]"
            onClick={copyReferralLink}
            disabled={linkCopied}
          >
            {linkCopied
              ? intl.formatMessage({
                  id: 'ReferAndEarnPage.Share.CTACopied',
                  defaultMessage: 'Link copied',
                })
              : intl.formatMessage({
                  id: 'ReferAndEarnPage.Share.CTA',
                  defaultMessage: 'Copy link',
                })}
          </ButtonTw>
        </div>
      </BoxTw>
    </BoxTw>
  );
};
