import { ReactNode } from 'react';

import { RouteComponentProps } from '@reach/router';

import { RegistrationMainFlowPage } from './RegistrationMainFlowPage';

export const Registration = ({
  children,
}: { children?: ReactNode } & RouteComponentProps) => {
  return <>{children}</>;
};

export { RegistrationMainFlowPage };
