/** @jsxImportSource theme-ui */
import React, { MouseEventHandler, useContext } from 'react';
import { useIntl } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import { RouteComponentProps, navigate, useParams } from '@reach/router';
import { track } from 'analytics/analytics';
import appStoreEn from 'assets/images/app-download/apple-en.svg';
import appStoreFr from 'assets/images/app-download/apple-fr.svg';
import googlePlayEn from 'assets/images/app-download/google-en.png';
import googlePlayFr from 'assets/images/app-download/google-fr.png';
import interac from 'assets/images/logos/interac-logo.png';
import {
  Box,
  ButtonUnstyled,
  Card,
  LOCALE_EN,
  LayoutBasic,
  Li,
  Link,
  LocaleContext,
  Paragraph,
  SpinnerCircularProgress,
  TitleLarge,
  TitleSmall,
  Ul,
} from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ThemeUIStyleObject } from 'theme-ui';
import { getMobileOperatingSystem } from 'utility/getMobileOperatingSystem';

const downloadImgSx: ThemeUIStyleObject = {
  width: ['110px', '110px', '150px'],
  mb: 2,
  display: 'block',
};

const defaultStyles = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  borderColor: '#D8D8D8',
  mt: '8px',
};

export const SelectPlatformPage = (props: RouteComponentProps) => {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const isInteracEnabled =
    useFlags()['web.enable-inbound-etransfer-platform-select'];

  const deepLinkUrl = useFlags()['web.inbound-etransfer-link-configuration'];
  const { transferId } = useParams();

  const os = getMobileOperatingSystem();
  const isMobile = os !== 'unknown';

  const mobileDeepLink = deepLinkUrl + transferId;

  let appDownloadLinks;
  if (os === 'ios') {
    appDownloadLinks = (
      <Link
        trackName="IdentityVerification Apple Download"
        external
        to="https://apps.apple.com/ca/app/koho-financial/id1091010942"
        data-cy="btn-appleDownload"
        aria-label={intl.formatMessage({
          id: 'IdentityVerificationLandingPage.DownloadFromApple',
          defaultMessage: 'Download KOHO from the Apple app store',
        })}
      >
        <img
          src={locale === LOCALE_EN ? appStoreEn : appStoreFr}
          alt={intl.formatMessage({
            id: 'IdentityVerificationLandingPage.DownloadFromApple',
            defaultMessage: 'Download KOHO from the Apple app store',
          })}
          sx={downloadImgSx}
        />
      </Link>
    );
  } else if (os === 'android') {
    appDownloadLinks = (
      <Link
        trackName="IdentityVerification Google Play Download"
        external
        to="https://play.google.com/store/apps/details?id=ca.koho"
        data-cy="btn-googleDownload"
        aria-label={intl.formatMessage({
          id: 'IdentityVerificationLandingPage.DownloadFromGoogle',
          defaultMessage: 'Download KOHO from Google Play',
        })}
      >
        <img
          src={locale === LOCALE_EN ? googlePlayEn : googlePlayFr}
          alt={intl.formatMessage({
            id: 'IdentityVerificationLandingPage.DownloadFromGoogle',
            defaultMessage: 'Download KOHO from Google Play',
          })}
          sx={downloadImgSx}
        />
      </Link>
    );
  }

  React.useEffect(() => {
    if ((!isInteracEnabled && isInteracEnabled !== undefined) || !isMobile) {
      navigate(`/e-transfer/deposit/${transferId}`);
    }
  }, [isInteracEnabled, transferId, isMobile]);

  const handleMobileClicked:
    | MouseEventHandler<HTMLAnchorElement>
    | undefined = () => {
    track({
      event: `Clicked`,
      properties: {
        element: 'button',
        type: 'button',
        name: ' e-Transfer Select Platform Mobile Button',
      },
    });
  };

  const listItems = (
    <Ul>
      <Li defaultStyling={false} sx={defaultStyles}>
        <TitleSmall sx={{ m: '12px', display: 'block', width: '100%' }}>
          {intl.formatMessage(
            {
              id: 'InboundEtransfer.SelectDepositPlatform.Mobile',
              defaultMessage: '<mobileLink>Mobile app</mobileLink>',
            },
            {
              mobileLink: (link) => (
                <a
                  href={mobileDeepLink}
                  onClick={handleMobileClicked}
                  sx={{
                    textDecoration: 'none',
                    color: 'text',
                    display: 'block',
                    width: '100%',
                  }}
                >
                  {link}
                </a>
              ),
            },
          )}
        </TitleSmall>
      </Li>
      <Li defaultStyling={false} sx={defaultStyles}>
        <ButtonUnstyled
          sx={{ width: ['100%'] }}
          onClick={() => {
            navigate(`/e-transfer/deposit/${transferId}`);
          }}
          trackName="e-Transfer Select Platform Web Button"
        >
          <TitleSmall sx={{ m: '12px' }}>
            {intl.formatMessage({
              id: 'InboundEtransfer.SelectDepositPlatform.Web',
              defaultMessage: 'Web browser',
            })}
          </TitleSmall>
        </ButtonUnstyled>
      </Li>
    </Ul>
  );

  return (
    <LayoutBasic
      pageName="Interac e-Transfer Select Platform"
      includeLanguageToggle={true}
    >
      <Card sx={{ mb: 6, p: 0 }}>
        <>
          <Box
            sx={{
              display: 'flex',
              backgroundColor: '#E6EEF5',
              borderRadius: '4px',
              py: '8px',
              px: '24px',
              alignItems: 'center',
            }}
          >
            <img
              sx={{
                width: '28px',
                height: '28px',
                mr: '8px',
              }}
              src={interac}
              alt={intl.formatMessage({
                id: 'InboundETransferPage.InteractETransferLogoAlt',
                defaultMessage: 'Interac e-Transfer Logo',
              })}
            />
            <Paragraph>
              {intl.formatMessage({
                id: 'InboundEtransfer.SelectDepositPlatform.InteracTitle',
                defaultMessage: 'INTERAC e-Transfer®',
              })}
            </Paragraph>
          </Box>
          <MuiBox sx={{ p: '24px' }}>
            <TitleLarge>
              {intl.formatMessage({
                id: 'InboundEtransfer.SelectDepositPlatform.Title',
                defaultMessage:
                  'How would you like to deposit your e-Transfer?',
              })}
            </TitleLarge>
            {!!deepLinkUrl && !!transferId ? (
              listItems
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <SpinnerCircularProgress />
              </Box>
            )}
            {isMobile ? (
              <MuiBox
                display="flex"
                flexDirection="column"
                sx={{
                  flex: '1 0 50%',
                  alignItems: ['center'],
                  pl: [0, '35px'],
                }}
              >
                <Paragraph>
                  {intl.formatMessage({
                    id: 'InboundEtransfer.SelectDepositPlatform.AppCTA',
                    defaultMessage: `Don't have the app? Get it here: `,
                  })}
                </Paragraph>
                {appDownloadLinks}
              </MuiBox>
            ) : null}
          </MuiBox>
        </>
      </Card>
    </LayoutBasic>
  );
};
