import { useEffect, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import { Paragraph, ProgressBar, Template, TitleLarge } from 'components';
import { selectHisaOnboarding } from 'hisa/store/slice';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { theme } from '../../theme';
import { CitizenshipSelect } from '../components/CitizenshipSelect';
import { EmploymentDetails } from '../components/EmploymentDetails';
import { EmploymentStatus } from '../components/EmploymentStatus';
import { SocialInsuranceNumber } from '../components/SocialInsuranceNumber';
import { HISAFeatureFlags } from '../hisaFeatureFlags';

export const HisaRegistrationSteps = [
  {
    title: defineMessage({
      id: 'HisaSetupPage.Citizenship.Title',
      defaultMessage: "What's your citizenship?",
    }),
    lead: defineMessage({
      id: 'HisaSetupPage.Citizenship.Lead',
      defaultMessage:
        'Your personal information will always be kept private and safe.',
    }),
  },
  {
    title: defineMessage({
      id: 'HisaSetupPage.Employment.Title',
      defaultMessage: 'Tell us about your employment status',
    }),
    lead: defineMessage({
      id: 'HisaSetupPage.Employment.Lead',
      defaultMessage:
        'Your personal information will always be kept private and safe.',
    }),
  },
  {
    title: defineMessage({
      id: 'HisaSetupPage.Details.Title',
      defaultMessage: 'Just a little bit more about yourself',
    }),
    lead: defineMessage({
      id: 'HisaSetupPage.Details.Lead',
      defaultMessage:
        'We need some additional information to level up your account. Your personal information will be kept safe.',
    }),
  },
  {
    title: defineMessage({
      id: 'HisaSetupPage.SIN.Title',
      defaultMessage: "What's your SIN?",
    }),
    lead: defineMessage({
      id: 'HisaSetupPage.SIN.Lead',
      defaultMessage:
        'This is required to open interest-earning accounts in Canada.',
    }),
  },
];

export const HisaSetupPage = () => {
  const isWealthSimpleDeprecated =
    useFlags()[HISAFeatureFlags.WsHisaDeprecated];

  const defaultStartFromStep = isWealthSimpleDeprecated ? 3 : 0;

  const historyState = window.history.state?.step || defaultStartFromStep;

  const intl = useIntl();
  const [currentStep, setCurrentStep] = useState(0);

  const onboardingData = useSelector(selectHisaOnboarding);

  useEffect(() => {
    if (
      historyState > 0 &&
      onboardingData.citizenship === '' &&
      !isWealthSimpleDeprecated
    ) {
      // This starts the flow over in case of a reset (which clears the store).
      // If we wanted to have CA citizenship set up as a default, we could instead track
      // a dedicated value for "has the user started the onboarding flow" and use that.
      navigate('/hisa/setup');
    } else if (historyState !== currentStep) {
      // update the page when the user navigates back and forth
      setCurrentStep(historyState);
    }
  }, [historyState, currentStep, onboardingData, isWealthSimpleDeprecated]);

  const pageTitle = HisaRegistrationSteps[currentStep].title;
  const pageLead = HisaRegistrationSteps[currentStep].lead;

  const handleContinue = () => {
    if (currentStep >= HisaRegistrationSteps.length - 1) {
      if (isWealthSimpleDeprecated) {
        navigate('/hisa/agreement');
      } else {
        navigate('/hisa/assumptions');
      }
    } else {
      let nextStep = currentStep + 1;

      // EmploymentStatus step
      if (currentStep === 1) {
        let status = onboardingData.employmentStatus;

        // we skip a step depending on the employment status
        if (
          status === 'retired' ||
          status === 'student' ||
          status === 'unemployed'
        ) {
          nextStep = currentStep + 2;
        }
      }

      window.history.pushState({ step: nextStep }, '');
      setCurrentStep(nextStep);
    }
  };
  return (
    <Template name="HISA Setup" variant="center">
      {isWealthSimpleDeprecated && (
        <Typography
          variant="caption"
          sx={{ color: theme.colors.midGrey, fontWeight: 700 }}
        >
          {intl.formatMessage({
            id: 'HisaSetupPage.EarnInterest',
            defaultMessage: 'Earn Interest',
          })}
        </Typography>
      )}
      {!isWealthSimpleDeprecated && (
        <ProgressBar
          currentStep={currentStep + 1}
          totalSteps={HisaRegistrationSteps.length + 2}
        />
      )}
      <TitleLarge sx={{ mb: 1 }}>{intl.formatMessage(pageTitle)}</TitleLarge>
      <Paragraph sx={{ mb: 1 }}>{intl.formatMessage(pageLead)}</Paragraph>

      {currentStep === 0 && (
        <CitizenshipSelect
          value={onboardingData.citizenship}
          onContinue={handleContinue}
        />
      )}
      {currentStep === 1 && (
        <EmploymentStatus
          status={onboardingData.employmentStatus}
          onContinue={handleContinue}
        />
      )}
      {currentStep === 2 && (
        <EmploymentDetails
          role={onboardingData.employmentDetails.employmentRole.title || ''}
          industry={onboardingData.employmentDetails.companyCategory.code || ''}
          company={onboardingData.employmentDetails.companyName}
          onContinue={handleContinue}
        />
      )}
      {currentStep === 3 && (
        <SocialInsuranceNumber
          value={onboardingData.sin}
          onContinue={handleContinue}
        />
      )}
    </Template>
  );
};
