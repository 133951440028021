import { ReactNode } from 'react';

import { TrackEvent, track } from 'analytics/analytics';
import { twMerge } from 'tailwind-merge';

export interface ButtonUnstyledProps {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: any;
  trackEvent?: TrackEvent;
  trackName?: string;
}
export const ButtonUnstyled = ({
  children,
  className,
  onClick,
  trackEvent,
  trackName,
  ...props
}: Omit<ButtonUnstyledProps, 'css'>) => {
  const handleClick = () => {
    if (trackName) {
      track({
        event: `Clicked`,
        properties: {
          element: 'button',
          type: 'button',
          name: trackName,
        },
      });
    }
    if (trackEvent) {
      track(trackEvent);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className={twMerge(
        'bg-transparent',
        'border-2 border-solid rounded border-transparent outline-offset-2', // accessibility
        'box-border',
        'cursor-pointer',
        'disabled:cursor-not-allowed',
        'inline-block',
        'm-0.5',
        'p-0',
        'rounded-sm',
        'text-current text-unset',
        className,
      )}
      type="button"
      onClick={handleClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default ButtonUnstyled;
