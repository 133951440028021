import { useContext } from 'react';
import { useIntl } from 'react-intl';

import * as Sentry from '@sentry/browser';
import { useStripe } from '@stripe/react-stripe-js';
import { FundingStatusScreenStates } from 'addFunds/components/FundingStatusModal';
import { StripeErrorMessage } from 'addFunds/components/StripeErrorMessage';
import {
  PaymentMethod,
  useCreatePaymentIntentMutation,
} from 'apis/easyloadApi';
import { Button, LocaleContext } from 'components';

interface PaymentIntentButtonProps {
  amount: string;
  paymentMethod: PaymentMethod;
  disabled: boolean;
  updateProgress: (progress: FundingStatusScreenStates) => void;
}

export const PaymentIntentButton = ({
  amount,
  paymentMethod,
  disabled,
  updateProgress,
}: PaymentIntentButtonProps) => {
  const context = useContext(LocaleContext);
  const stripe = useStripe();
  const intl = useIntl();

  const [createPaymentIntent, paymentIntentRequest] =
    useCreatePaymentIntentMutation();

  const handleAddMoneyRequest = async () => {
    if (!stripe) {
      Sentry.captureException(new Error('Stripe.js not loaded'));
      return null;
    }

    updateProgress('loading');

    try {
      const { ClientSecret: clientSecret } = await createPaymentIntent(
        amount,
      ).unwrap();

      console.log('Create payment Intent', { clientSecret });

      const confirmPaymentResults = await stripe.confirmPayment({
        clientSecret,
        confirmParams: { payment_method: paymentMethod.id },
        redirect: 'if_required',
      });

      if (confirmPaymentResults.error) {
        console.error('Error confirming payment', confirmPaymentResults.error);
        // remove some sensitive data
        confirmPaymentResults.error.payment_intent = undefined;
        Sentry.captureException(
          new Error('Error confirming payment', {
            cause: { error: confirmPaymentResults.error },
          }),
        );
        updateProgress('error');
        return null;
      }

      console.log('Payment confirmed', confirmPaymentResults);

      setTimeout(() => {
        // we don't get any notification from the server, so we just wait a bit
        updateProgress('done');
      }, 3000);
    } catch (e: any) {
      if ('status' in e && e.status === 409) {
        return updateProgress('velocityLimit');
      }

      Sentry.captureException(
        new Error('Error confirming payment', {
          cause: { error: e },
        }),
      );

      console.error('Error creating payment intent', e);
      updateProgress('error');
    }
  };

  if (!stripe) {
    return <StripeErrorMessage />;
  }

  return (
    <Button
      disabled={disabled || paymentIntentRequest.isLoading}
      onClick={handleAddMoneyRequest}
    >
      {intl.formatMessage(
        {
          id: 'LoadDebitCardPage.AddMoney',
          defaultMessage: 'Add {amount}',
        },
        { amount: context.intlFormatMoney(amount).format() },
      )}
    </Button>
  );
};
