/** @jsxImportSource theme-ui */
import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeOutlined from '@material-ui/icons/NavigateBeforeOutlined';
import { Flex } from 'theme-ui';

import { TitleLarge } from '../components';

const useStyles = makeStyles({
  dialogContentRoot: {
    padding: '8px 24px 24px',
    '&:first-child': {
      paddingTop: 16,
    },
  },
  dialogContentTextRoot: {
    margin: 0,
  },
});

export const ModalHeader = ({
  closeButton = true,
  backButton = false,
  icon,
  title,
  onClose,
  onClickBackButton,
  closeIconProps,
  closeIconFontSize = 'large',
}: {
  closeButton?: boolean;
  backButton?: boolean;
  icon?: React.ReactElement;
  title?: string;
  onClose?: Extract<DialogProps['onClose'], Function>;
  onClickBackButton?: () => void;
  closeIconProps?: Omit<SvgIconProps, 'css'>;
  closeIconFontSize?: 'small' | 'medium' | 'large';
}) => {
  const intl = useIntl();

  return (
    <>
      {(closeButton || backButton) && (
        <Flex
          sx={{
            display: 'flex',
            justifyContent: backButton ? 'space-between' : 'flex-end',
            padding: '24px 24px 16px',
            width: '100%',
          }}
        >
          {backButton ? (
            <ModalButton onClick={onClickBackButton || onClose}>
              <NavigateBeforeOutlined />
              {intl.formatMessage({
                id: 'Modal.Back',
                defaultMessage: 'Back',
              })}
            </ModalButton>
          ) : null}
          {closeButton ? (
            <ModalButton onClick={onClose} sx={{ alignItems: 'flex-start' }}>
              <CloseIcon fontSize={closeIconFontSize} {...closeIconProps} />
            </ModalButton>
          ) : null}
        </Flex>
      )}

      {icon && <div sx={{ m: [3, 3] }}>{icon}</div>}
      {title && (
        <TitleLarge sx={{ mt: 0, mb: 0, p: 4, py: 0 }}>{title}</TitleLarge>
      )}
    </>
  );
};

export interface ModalProps extends Omit<DialogProps, 'css'> {
  open: boolean;
  title?: string;
  icon?: React.ReactElement;
  closeButton?: boolean;
  closeIconProps?: Omit<SvgIconProps, 'css'>;
  backButton?: boolean;
  onClickBackButton?: () => void;
  closeIconFontSize?: 'small' | 'medium' | 'large';
  setNewSubscription?: Dispatch<SetStateAction<boolean>>;
}

export const Modal = (props: Readonly<ModalProps>) => {
  const {
    children,
    onClickBackButton,
    onClose,
    open,
    title,
    icon,
    maxWidth = 'xs',
    closeIconProps,
    closeIconFontSize,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth={maxWidth} onClose={onClose} {...rest}>
      <ModalHeader
        {...props}
        closeIconProps={closeIconProps}
        closeIconFontSize={closeIconFontSize}
      />
      <DialogContent className={classes.dialogContentRoot}>
        <DialogContentText
          component="div"
          classes={{ root: classes.dialogContentTextRoot }}
        >
          {children}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const ModalButton = ({ children, onClick, ...props }) => (
  <button
    onClick={onClick}
    sx={{
      alignItems: 'center',
      background: 'transparent',
      border: 'none',
      color: '#373E4D',
      cursor: 'pointer',
      display: 'grid',
      fontSize: 1,
      fontWeight: 3,
      gridTemplateColumns: 'max-content max-content',
      padding: 0,
    }}
    {...props}
  >
    {children}
  </button>
);
