/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';

import CircularProgress from '@material-ui/core/CircularProgress';

interface CustomSpinnerCircularProgress {
  variant?: 'small' | 'large';
  size?: number;
  color?: 'primary' | 'secondary' | 'inherit';
  className?: string;
}

/** @deprecated - Use CircularProgressTw from components/CircularProgressTw instead */
export const SpinnerCircularProgress = ({
  variant,
  className,
  ...props
}: CustomSpinnerCircularProgress) => {
  const intl = useIntl();

  return variant === 'large' ? (
    <React.Fragment>
      <CircularProgress
        className={className}
        color="inherit"
        size={20}
        sx={{ position: 'absolute' }}
        aria-label={intl.formatMessage({
          id: 'CircularProgress.Loading',
          defaultMessage: 'Loading',
        })}
        aria-busy="true"
        {...props}
      />
      <span>&nbsp;</span>
    </React.Fragment>
  ) : (
    <CircularProgress
      className={className}
      aria-label={intl.formatMessage({
        id: 'CircularProgress.Loading',
        defaultMessage: 'Loading',
      })}
      aria-busy="true"
      {...props}
    />
  );
};

export default SpinnerCircularProgress;
