import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { navigate } from '@reach/router';
import { trackInput } from 'analytics/analytics';
import { selectTheme } from 'appState/appState.slice';
// components
import { ButtonTw } from 'components/ButtonTw';
import { Modal } from 'components/Modal';
import { Paragraph, TitleLarge } from 'components/TypographyTw';
// types
import type { Image } from 'types/Image';

const imageUrlLegacy = 'https://assets.koho.ca/tiers/welcome_modal.jpeg';
const imageUrl = 'https://assets.koho.ca/modals/welcome.png';

const WelcomeModal = ({ open = false }: { open: boolean }) => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);

  const [localOpen, setLocalOpen] = useState(open);

  const isLight = themeName === 'light';

  const handleClose = () => {
    trackInput({
      type: 'Clicked',
      name: 'Modal - Welcome -- Cancel',
      details: { element: 'button' },
    });

    setLocalOpen(!localOpen);
  };

  useEffect(() => {
    setLocalOpen(open);
  }, [open]);

  const modalDescription = intl.formatMessage({
    id: 'WelcomeModal.Description',
    defaultMessage:
      'Thanks for choosing KOHO. The first step on your financial journey is adding money to your account. Would you like to add some now?',
  });

  const modalTitle = intl.formatMessage({
    id: 'WelcomeModal.Title',
    defaultMessage: 'Welcome to KOHO!',
  });

  const ctaAddMoney = intl.formatMessage({
    id: 'WelcomeModal.CTA.AddMoney.Text',
    defaultMessage: 'Add money',
  });

  const ctaCancel = intl.formatMessage({
    id: 'WelcomeModal.CTA.Cancel.Text',
    defaultMessage: 'Not now',
  });

  const image: Image = {
    displayHeights: ['160px', '350px'],
    displayWidths: ['370px', '600px'],
    url: isLight ? imageUrl : imageUrlLegacy,
  };

  return (
    <Modal
      data-cy="welcome-modal"
      image={image}
      open={localOpen}
      onClose={handleClose}
      classes={{
        paper: 'bg-tiers-blue-300 light:bg-primary-500',
        root: '[&_.MuiSvgIcon-root]:text-white text-center light:[&_.MuiDialogContent-root]:pb-5',
      }}
      absolutePositioning={false}
      maxWidth="sm"
    >
      <TitleLarge className="legacy:text-white light:text-secondary-75">
        {modalTitle}
      </TitleLarge>
      <Paragraph className="text-white mb-8">{modalDescription}</Paragraph>
      <div className="flex gap-2">
        <ButtonTw
          data-cy="welcome-modal-cta-add-money"
          onClick={() => navigate('/load')}
          trackName="Modal - Welcome -- Add money"
          variant={isLight ? 'secondaryInverted' : 'primaryInverted'}
        >
          {ctaAddMoney}
        </ButtonTw>
        <ButtonTw
          data-cy="welcome-modal-cta-cancel"
          onClick={handleClose}
          className="text-white"
          variant={isLight ? 'clearInverted' : 'clear'}
        >
          {ctaCancel}
        </ButtonTw>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
