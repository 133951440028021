import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectDirectDepositState = (state: RootState) =>
  state.directDeposit;

export const selectDirectDeposit = createSelector(
  selectDirectDepositState,
  (state) => state.data,
);

export const selectDirectDepositClientName = createSelector(
  selectDirectDeposit,
  (state) => state.client_name,
);

export const selectDirectDepositClientNumber = createSelector(
  selectDirectDeposit,
  (state) => state.client_number,
);

export const selectDirectDepositInstitutionName = createSelector(
  selectDirectDeposit,
  (state) => state.financial_institution_name,
);

export const selectDirectDepositInstitutionTransit = createSelector(
  selectDirectDeposit,
  (state) => state.transit_number,
);

export const selectDirectDepositInstitutionId = createSelector(
  selectDirectDeposit,
  (state) => state.financial_institution_number,
);

export const selectDirectDepositLoadable = createSelector(
  selectDirectDepositState,
  (state) => state.loadable,
);

export const selectDirectDepositLoading = createSelector(
  selectDirectDepositLoadable,
  (state) => state.loading,
);

export const selectDirectDepositError = createSelector(
  selectDirectDepositLoadable,
  (state) => state.error,
);
