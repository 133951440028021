import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { IBenefitGroup, IPlan } from 'apis/tiers';
import { ReactComponent as TiersIconGradient } from 'assets/images/logos/everything-gradient-en.svg';
import { ReactComponent as TiersIconGradientFr } from 'assets/images/logos/everything-gradient-fr.svg';
import { LOCALE_EN, LOCALE_FR, LocaleContext } from 'components';
import { Money } from 'models';
import { theme } from 'theme';
import { useTiersData } from 'tiers/hooks/useTiersData';
import { TierKey } from 'tiers/models';

const everythingLogoOptions = {
  [LOCALE_EN]: TiersIconGradient,
  [LOCALE_FR]: TiersIconGradientFr,
};

type Props = {
  isFree: boolean;
  plan: IPlan;
  tier: IBenefitGroup;
};

export const PlanCard = ({ isFree, plan, tier }: Props) => {
  const { locale } = useContext(LocaleContext);
  const intl = useIntl();
  const isEverything = tier.key === TierKey.Everything;
  const { getTierName } = useTiersData();
  const isSmallMobile = useMediaQuery('@media (max-width: 380px)');

  const tierName = getTierName(tier.name);

  const EverythingLogo = everythingLogoOptions[locale];
  const billed = {
    monthly: intl.formatMessage({
      id: 'TiersUpgrade.Modal.BilledMonthly',
      defaultMessage: 'Billed monthly',
    }),
    annual: intl.formatMessage({
      id: 'TiersUpgrade.Modal.BilledYearly',
      defaultMessage: 'Billed yearly',
    }),
  }[plan.key];

  return (
    <Box
      data-cy={`plan-card-${tier.key}`}
      sx={{
        background: isEverything
          ? `linear-gradient(to right, ${theme.colors.blueNavy}, #A36EFF, #F36350)`
          : 'none',
        borderRadius: '8px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          bg: isEverything ? theme.colors.whiteSmoke : theme.colors.paleNavy,
          m: isEverything ? '1px' : '0px',
          borderRadius: isEverything ? '7px' : '8px',
          px: 14,
          py: 16,
        }}
      >
        {isEverything ? (
          <EverythingLogo
            height="34px"
            data-cy="tiers-upgrade-payment-tier-Everything"
          />
        ) : (
          <Typography
            data-cy={`tiers-upgrade-payment-tier-${tier.name}`}
            sx={{
              fontSize: '24px',
              fontWeight: 900,
              color: theme.colors.blueDark,
              mb: 1,
            }}
          >
            {tierName}
          </Typography>
        )}
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          sx={{ gap: 1, alignItems: 'center' }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 900,
              color: theme.colors.text,
              textDecoration: isFree ? 'line-through' : 'none',
              fontSize: isSmallMobile ? '12px' : undefined,
              whiteSpace: 'nowrap',
            }}
          >
            {intl.formatMessage(
              {
                id: 'TiersUpgrade.Modal.CostPerMonth',
                defaultMessage: '{cost}/month',
              },
              {
                cost: new Money(
                  plan.key === 'monthly'
                    ? plan.amount_cents
                    : plan.amount_cents / 12,
                  locale,
                ).format('pretty'),
              },
            )}
          </Typography>
          <Box display="flex" sx={{ gap: 1 }}>
            {isFree && (
              <Typography
                data-cy="tiers-upgrade-payment-free-trial"
                variant="body2"
                sx={{
                  fontWeight: 900,
                  color: theme.colors.blueDark,
                  fontSize: isSmallMobile ? '12px' : undefined,
                  whiteSpace: 'nowrap',
                }}
              >
                {intl.formatMessage({
                  id: 'TiersUpgrade.Modal.FreeTrial',
                  defaultMessage: 'Free trial',
                })}
              </Typography>
            )}
            <Typography
              variant="body2"
              sx={{
                color: theme.colors.text,
                fontSize: isSmallMobile ? '12px' : undefined,
                whiteSpace: 'nowrap',
              }}
              data-cy={`tiers-upgrade-payment-billed-${plan.key}`}
            >
              • {billed}
            </Typography>
          </Box>
        </Box>
        <Box></Box>
      </Box>
    </Box>
  );
};
