// configs
import { configureStore } from '@reduxjs/toolkit';
import { rootApi } from 'apis/rootApi';
//components
import { createEpicMiddleware } from 'redux-observable';
import { rootReducer } from 'store/reducers';

export const epicMiddleware = createEpicMiddleware();

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(epicMiddleware, rootApi.middleware),
    devTools: import.meta.env.VITE_REDUX_DEVTOOLS_ENABLED,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
