import { MessageDescriptor } from 'react-intl';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Loadable, createDefaultLoadable } from '../../utility/loadable';

type PasswordResetRequestType = {
  emailToken: string;
  password: string;
  email: string;
};

export enum PasswordResetUserType {
  User = 'user',
  Identity = 'identity',
}

export type PasswordResetState = {
  emailTokenChecked: boolean;
  authenticationChecked: boolean;
  userType: PasswordResetUserType | undefined;
  userRef?: string;
  authToken: string | null;
  tokenExpiredModalOpen: boolean;
  accountLockedModalOpen: boolean;
  emailResetLoadable: Loadable;
  request: PasswordResetRequestType;
  error: MessageDescriptor | null;
};

export const initialState: PasswordResetState = {
  emailTokenChecked: false,
  authenticationChecked: false,
  userType: undefined,
  userRef: undefined,
  authToken: null,
  tokenExpiredModalOpen: false,
  accountLockedModalOpen: false,
  emailResetLoadable: createDefaultLoadable(),
  request: {
    emailToken: '',
    password: '',
    email: '',
  },
  error: null,
};

const passwordResetSlice = createSlice({
  name: 'passwordReset',
  initialState,
  reducers: {
    submitWithSmsOtp: (state) => state,
    submitForIdentityUser: (state) => state,
    requestValue: (
      state,
      action: PayloadAction<Partial<PasswordResetRequestType>>,
    ) => {
      state.request = {
        ...state.request,
        ...action.payload,
      };
    },
    setEmailToken: (state, action: PayloadAction<string>) => {
      state.request.emailToken = action.payload;
    },
    updateResetError: (
      state,
      action: PayloadAction<MessageDescriptor | null>,
    ) => {
      state.error = action.payload;
    },
    checkTokenComplete: (
      state,
      {
        payload: { userType, authToken, userRef },
      }: PayloadAction<{
        userType: PasswordResetState['userType'];
        authToken: PasswordResetState['authToken'];
        userRef: PasswordResetState['userRef'];
      }>,
    ) => {
      state.emailTokenChecked = true;
      state.userType = userType;
      state.authToken = authToken;
      state.userRef = userRef;
    },
    checkAuthentication: (state, { payload }: PayloadAction<boolean>) => {
      state.authenticationChecked = payload;
    },
    resetEmailLoadableLoading: (state) => {
      state.emailResetLoadable = {
        loading: true,
        success: false,
        error: null,
      };
    },
    resetEmailSendFulfilled: (state) => {
      state.emailResetLoadable = {
        loading: false,
        success: true,
        error: null,
      };
    },
    resetEmailSendError: (
      state,
      action: PayloadAction<MessageDescriptor | null>,
    ) => {
      state.emailResetLoadable = {
        loading: false,
        success: false,
        error: action.payload,
      };
    },
    resetEmailResetLoadable: (state) => {
      state.emailResetLoadable = createDefaultLoadable();
    },
    noop: (state) => state,
  },
});

export const passwordResetActions = passwordResetSlice.actions;
export default passwordResetSlice.reducer;
