import { ReactNode } from 'react';

import { Box, Typography } from '@material-ui/core';
import { KDSIcons } from 'assets/images/kds_icons';
import { theme } from 'theme';

type StepProps = {
  description: string;
  icon: ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  title: string;
};

export const Step = ({
  description,
  icon,
  isFirst = false,
  isLast = false,
  title,
}: StepProps) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box
        display="flex"
        sx={{
          bg: theme.colors.orange,
          justifyContent: 'center',
          height: isLast ? '26px' : undefined,
          minHeight: isLast ? '26px' : '100%',
          minWidth: 22,
          maxWidth: 22,
          width: 22,
          mr: 3,
          pt: isFirst ? 1 : 0,
          borderTopLeftRadius: isFirst ? '100px' : 0,
          borderTopRightRadius: isFirst ? '100px' : 0,
          borderBottomLeftRadius: isLast ? '100px' : 0,
          borderBottomRightRadius: isLast ? '100px' : 0,
          zIndex: 2,
        }}
      >
        {icon}
      </Box>
      <Box sx={{ mb: '20px' }}>
        <Typography sx={{ fontWeight: 900, color: theme.colors.text }}>
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Box>
  );
};

const Unlock = () => (
  <KDSIcons.Icons.Unlock
    sx={{
      '> *': {
        stroke: theme.colors.white,
      },
      width: '18px',
    }}
  />
);

const Calendar = () => (
  <KDSIcons.Icons.Calendar
    sx={{
      '> *': { fill: theme.colors.white },
      width: '16px',
    }}
  />
);

const Renew = () => (
  <KDSIcons.Icons.Transfer
    sx={{ '> *': { stroke: theme.colors.white }, width: '16px' }}
  />
);

export const StepIcons = { Unlock, Calendar, Renew };
