/** @jsxImportSource theme-ui */
import { Fragment, useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Paragraph, SubtitleSmall, TitleLarge } from '.';
import { Money } from '../models/Money';
import { theme } from '../theme';
import { LocaleContext } from './I18NContextProvider';
import { Validators } from './forms';
import { TextInputPayment } from './forms/TextInputPayment';

export const TransferAmount = ({
  type,
  min,
  max,
  initial,
  showTotalAvailable,
  onContinue,
  feeAmount,
}: {
  type: 'in' | 'out';
  min?: Money;
  max: Money;
  initial: string;
  showTotalAvailable: boolean;
  onContinue: (amount: string) => void;
  feeAmount: string;
}) => {
  const intl = useIntl();
  const context = useContext(LocaleContext);
  const [amount, setAmount] = useState<Money>(context.intlFormatMoney(initial));
  const [valid, setValid] = useState(false);

  return (
    <Fragment>
      <div sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <TitleLarge sx={{ mt: 0, mb: 0, pb: 3 }}>
          {type === 'in'
            ? intl.formatMessage({
                id: 'TransferAmount.HowMuchToWithdraw',
                defaultMessage: 'How much would you like to withdraw?',
              })
            : intl.formatMessage({
                id: 'TransferAmount.HowMuchToSend',
                defaultMessage: 'How much do you want to send?',
              })}
        </TitleLarge>
        {showTotalAvailable ? (
          <Paragraph sx={{ mt: 0 }}>
            {intl.formatMessage({
              id: 'TransferAmount.AvailableFunds',
              defaultMessage: `Available funds:`,
            })}{' '}
            {max && max.format()}
          </Paragraph>
        ) : null}
        <TextInputPayment
          sx={{
            mb: 4,
            input: {
              color: theme.colors.primary,
              backgroundColor: theme.colors.greyXLight,
              borderRadius: theme.borderRadius.medium,
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
          name="amount"
          controlledValue={context.intlFormatMoney(amount).format()}
          onChange={(event) =>
            setAmount(context.intlFormatMoney(event.currentTarget.value))
          }
          onValidChange={(event) => setValid(event)}
          type="amount"
          validators={[
            Validators.required,
            Validators.min(
              min?.toNumber() || 0,
              intl,
              intl.formatMessage(
                {
                  id: 'TransferAmount.Validation.MinAmount',
                  defaultMessage:
                    'Please enter an amount that’s more than {amount}',
                },
                { amount: feeAmount },
              ),
            ),
            Validators.max(
              max.toNumber(),
              intl,
              intl.formatMessage({
                id: 'TransferAmount.Validation.Max',
                defaultMessage:
                  'You do not have enough funds in your available balance.',
              }),
            ),
          ]}
        ></TextInputPayment>
        <Button
          onClick={() => onContinue(amount.format())}
          disabled={!valid}
          sx={{ mt: 2 }}
        >
          {intl.formatMessage({
            id: 'Global.Button.Continue',
            defaultMessage: 'Continue',
          })}
        </Button>

        <div sx={{ my: '32px', textAlign: 'center' }}>
          <SubtitleSmall>
            {intl.formatMessage({
              id: 'TransferAmount.LegalFootnote.One',
              defaultMessage:
                '® Trademark of Interac Corp. KOHO Financial authorized user of the trademark.',
            })}
          </SubtitleSmall>

          <SubtitleSmall>
            {intl.formatMessage({
              id: 'TransferAmount.LegalFootnote.Two',
              defaultMessage:
                '® Trademark of Interac Corp. Used under license.',
            })}
          </SubtitleSmall>
        </div>
      </div>
    </Fragment>
  );
};
