/** @jsxImportSource theme-ui */
import React from 'react';

import { InputAdornment } from '@material-ui/core';
import { trackInput } from 'analytics/analytics';
import { ShowHideButton } from 'passwordReset/Components/ButtonShowHide';

import { KhInputText, KhInputTextProps } from './KhInputText';

export const KhInputPassword = React.forwardRef<
  HTMLInputElement,
  KhInputTextProps
>((props: KhInputTextProps, ref) => {
  const [passVisible, setPassVisible] = React.useState(false);

  const onShowHideClick = () => {
    trackInput({ name: 'password-show-hide', type: 'Clicked' });
    setPassVisible(!passVisible);
  };

  return (
    <KhInputText
      type={passVisible ? 'text' : 'password'}
      ref={ref}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ShowHideButton onClick={onShowHideClick} visible={passVisible} />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
});
