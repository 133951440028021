import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Box,
  ButtonBase,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { useGetCurrentTierQuery, useGetTiersQuery } from 'apis/tiers';
import { KDSIcons } from 'assets/images/kds_icons';
import { Button, Template } from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { desktop, theme } from 'theme';
import { CancelAnytime } from 'tiers/components/CancelAnytime';
import { ChangePlan } from 'tiers/components/ChangePlan';
import { PlanCard } from 'tiers/components/PlanCard';
import { Tiers } from 'tiers/components/Tiers';
import { useSelectedTierPlan } from 'tiers/hooks/useSelectedTierPlan';
import { TiersFeatureFlags } from 'tiers/models/TiersFeatureFlags';
import { selectIsPaidTier } from 'tiers/store/selectors';
import { getNextHighestRankedTierKey } from 'tiers/utils/helpers';

import { selectLaunchDarklyFlagsIsLoading } from 'libs/launchdarkly/store/selectors';

const BackButton = () => (
  <ButtonBase
    sx={{ mr: 'auto', mb: 4 }}
    onClick={() => navigate('/tiers/my-plan')}
    data-cy="tier-management-back-button"
  >
    <KDSIcons.Icons.ChevronLeft
      height={24}
      sx={{
        '& > path': {
          stroke: theme.colors.text,
          transition: 'all 0.2s ease-in-out',
        },
        '&:hover path': {
          stroke: theme.colors.textDark,
        },
      }}
    />
  </ButtonBase>
);

export const TierManagementPage = () => {
  const intl = useIntl();
  const muiTheme = useTheme();
  const isAfterTablet = useMediaQuery(muiTheme.breakpoints.up('md'));
  const isDesktop = useMediaQuery(desktop);
  const isPaidTier = useSelector(selectIsPaidTier);

  const isTiersManagementEnabled =
    useFlags()[TiersFeatureFlags.EnableCurrentTiersManagement];
  const ldFlagsIsLoading = useSelector(selectLaunchDarklyFlagsIsLoading);

  const {
    data: tiers,
    isLoading: tiersIsLoading,
    isUninitialized: tiersIsUninitialized,
  } = useGetTiersQuery();
  const { data: currentTier, isLoading: currentTierIsLoading } =
    useGetCurrentTierQuery();

  const currentUsersTier = currentTier?.benefit_group?.key;

  const { setTier, currentTierSelected } = useSelectedTierPlan({
    tiers: tiers ?? [],
  });

  useEffect(() => {
    const nextHighestTier = getNextHighestRankedTierKey(currentUsersTier);

    setTier(nextHighestTier);
  }, [currentUsersTier, setTier]);

  if (
    tiersIsUninitialized ||
    tiersIsLoading ||
    currentTierIsLoading ||
    ldFlagsIsLoading
  ) {
    return <Skeleton variant="rect" />;
  }

  if (!isPaidTier) {
    navigate('/transactions', { state: { showWelcomeModal: false } });
    return null;
  }

  if (!isTiersManagementEnabled) {
    navigate('/transactions', { state: { showWelcomeModal: false } });
    return null;
  }

  if (!tiers || tiers.length === 0) {
    navigate('/transactions', { state: { showWelcomeModal: false } });
    return null;
  }

  const selected = intl.formatMessage({
    id: 'TiersManage.PendingDowngrade.Selected',
    defaultMessage: 'selected',
  });

  const currentUsersTierPlan = currentTier?.benefit_group?.plans?.find(
    (plan) => plan.key === currentTier.frequency,
  );
  const selectedPlan = currentTierSelected?.plans?.find(
    (plan) => plan.key === currentTier?.frequency,
  );
  const tiersToShow = tiers.filter(
    (tier) => tier.key !== currentTier?.benefit_group?.key,
  );
  const delayedDowngradeTierKey =
    currentTier?.delayed_downgrade?.benefit_group?.key;
  const hasSelectedDowngradeTier =
    delayedDowngradeTierKey &&
    delayedDowngradeTierKey === currentTierSelected?.key;

  return (
    <Template name="Tiers - Manage">
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          pb: isDesktop ? 80 : 48,
          alignItems: 'center',
          width: 'fit-content',
          px: 24,
          pt: isDesktop ? 0 : 32,
          mx: 'auto',
        }}
      >
        <BackButton />
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            justifyContent: 'center',
            alignItems: isAfterTablet ? 'center' : 'start',
            mx: 16,
            width: isAfterTablet ? '352px' : '100%',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: '32px',
              fontWeight: 900,
              textAlign: 'center',
              mb: 16,
              mr: isAfterTablet ? 0 : 'auto',
              color: theme.colors.textDark,
            }}
            data-cy="tier-manage-header"
          >
            {intl.formatMessage({
              id: 'TiersManage.header',
              defaultMessage: 'Your plan',
            })}
          </Typography>
          {currentTier?.benefit_group && currentUsersTierPlan ? (
            <Box display="flex" sx={{ mb: 5, width: '100%', height: 'auto' }}>
              <PlanCard
                tier={currentTier?.benefit_group}
                plan={currentUsersTierPlan}
                isFree={false}
              />
            </Box>
          ) : null}
          <Typography
            variant="h3"
            sx={{
              fontSize: '24px',
              fontWeight: 900,
              mb: 16,
              color: theme.colors.text,
            }}
          >
            {intl.formatMessage({
              id: 'TiersManage.plansHeader',
              defaultMessage: 'Available plans',
            })}
          </Typography>
        </Box>
        <Tiers
          boxSx={{ marginBottom: '8px', paddingX: 0 }}
          selectable
          tiers={tiersToShow}
          plan={selectedPlan?.key}
          selectedTier={currentTierSelected?.key}
          handleSelectTier={setTier}
          cardTrackName="tiers-change-select"
        />

        <Box
          sx={{
            maxWidth: isAfterTablet ? '352px' : 'unset',
            mx: 'auto',
            width: '100%',
          }}
        >
          {hasSelectedDowngradeTier ? (
            <Typography
              sx={{
                color: theme.colors.error,
                fontSize: '14px',
                fontWeight: 700,
                textAlign: isAfterTablet ? 'center' : 'left',
                mt: 4,
              }}
              data-cy="selected-downgrade-tier-error"
            >
              {intl.formatMessage(
                {
                  id: 'TiersManage.PendingDowngrade',
                  defaultMessage:
                    'There is a pending downgrade for the {selected} plan.  Please select another plan.',
                },
                { selected: <b>{selected}</b> },
              )}
            </Typography>
          ) : null}
          {currentTierSelected && currentTier?.benefit_group ? (
            <ChangePlan
              currentUserTier={currentTier}
              tier={currentTierSelected}
              selectedPlan={selectedPlan}
              trackEventName="tiers-change-confirm"
              onPaidTier
              disabled={hasSelectedDowngradeTier}
            />
          ) : null}
          <Button
            sx={{
              mt: 16,
              mb: 48,
              py: 12,
              color: theme.colors.error,
              fontWeight: 900,
            }}
            data-cy="tiers-change-cancel-plan"
            trackName="tiers-change-cancel-plan"
            variant="clear"
            onClick={() => navigate('/cancel-plan')}
          >
            {intl.formatMessage({
              id: 'TiersManage.CancelPlan',
              defaultMessage: 'Cancel plan',
            })}
          </Button>
          <CancelAnytime />
        </Box>
      </Box>
    </Template>
  );
};
