import { useIntl } from 'react-intl';

import { useNavigate } from '@reach/router';
import { MoneyRequest } from 'apis/requestMoneyApi';
import {
  Box,
  Button,
  ButtonLinkExternal,
  Paragraph,
  ParagraphSmall,
  TitleMedium,
} from 'components';
import { CopyButton } from 'components/CopyButton';
import { theme } from 'theme';
import { getMobileOperatingSystem } from 'utility/getMobileOperatingSystem';

export const RequestMoneyReferralDetails = ({
  request,
}: {
  request: MoneyRequest;
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const os = getMobileOperatingSystem();

  return (
    <Box>
      <TitleMedium>
        {intl.formatMessage(
          {
            id: 'RequestMoneyReferralDetails.Title',
            defaultMessage:
              'Join {requesterName} and 1 million+ other Canadians',
          },
          {
            requesterName: request.requester_given_name,
          },
        )}
      </TitleMedium>
      <Paragraph sx={{ mb: 16 }}>
        {intl.formatMessage(
          {
            id: 'RequestMoneyReferralDetails.Description',
            defaultMessage:
              'Not a KOHO member? Settle-up, and <boldText>get a $20 bonus</boldText> when you download the app, sign-up, and make your first purchase.',
          },
          {
            boldText: (text: string) => boldText(text),
          },
        )}
      </Paragraph>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.colors.greyLight}`,
          mb: [16, 24],
        }}
      >
        <Paragraph sx={{ fontWeight: 'bold' }}>
          {intl.formatMessage({
            id: 'RequestMoneyReferralDetails.ReferralCode',
            defaultMessage: 'Referral code',
          })}
          :
        </Paragraph>
        <Box sx={{ display: 'flex' }}>
          <Paragraph>{request.referral_code}</Paragraph>
          <CopyButton
            value={request.referral_code}
            trackingLabel="Referral code"
            sx={{ ml: 2, px: 3 }}
          />
        </Box>
      </Box>

      {os === 'unknown' ? (
        <Button
          variant="coral"
          onClick={() =>
            navigate(`/registration#referral=${request.referral_code}`)
          }
          sx={{ mb: 3 }}
          trackName="request-money-sign-up"
        >
          {intl.formatMessage({
            id: 'RequestMoneyReferralDetails.SignupButton',
            defaultMessage: 'Sign-up for KOHO',
          })}
        </Button>
      ) : (
        <ButtonLinkExternal
          variant="coral"
          sx={{ mb: 3 }}
          href={
            os === 'ios'
              ? `https://go.onelink.me/app/ad584467?af_sub1=${request.referral_code}&af_channel=request_money_referral`
              : `https://go.onelink.me/app/e76f5835?af_sub1=${request.referral_code}&af_channel=request_money_referral`
          }
          trackName="request-money-download-koho"
        >
          {intl.formatMessage({
            id: 'RequestMoneyReferralDetails.DownloadButton',
            defaultMessage: 'Download KOHO',
          })}
        </ButtonLinkExternal>
      )}

      <ParagraphSmall sx={{ textAlign: 'center' }}>
        {intl.formatMessage({
          id: 'RequestMoneyReferralDetails.Footer',
          defaultMessage: '⏰ It only takes a few minutes',
        })}
      </ParagraphSmall>
    </Box>
  );
};

const boldText = (text: string) => <strong>{text}</strong>;
