import { AddFundsFeatureFlags } from 'addFunds/feature-flags';
import { AnalyticsFeatureFlags } from 'analytics/AnalyticsFeatureFlags';
import { BrazeFeatureFlags } from 'braze/models/BrazeFeatureFlags';
import { contactFeatureFlags } from 'contact/contact-feature-flags';
import { CoverFeatureFlags } from 'cover/cover-feature-flags';
import { CreditBuildingFeatureFlags } from 'creditBuilding/models/flags';
import {
  NewUsersAppDownloadExperiment,
  NewUsersAppDownloadExperimentValue,
} from 'downloadApp/models/flags';
import { HISAFeatureFlags } from 'hisa/hisaFeatureFlags';
import { IdentityVerificationFlags } from 'identity-verification/IdentityVerificationFlags';
import { MFAFeatureFlags } from 'mfa/models/MFAFeatureFlags';
import { PersonalizedOnboardingFeatureFlags } from 'personalizedOnboarding/models/PersonalizedOnboardingFlags';
import { referAndEarnFlags } from 'referAndEarn/referAndEarnFlags';
import { RegistrationFeatureFlags } from 'registration/models/RegistrationFeatureFlags';
import { TiersFeatureFlags } from 'tiers/models/TiersFeatureFlags';
import { TxFeatureFlags } from 'transactions/transactions-feature-flags';

export const launchDarklyFlags = {
  // key & default value for all features flag need to be defined here
  'web-nba-incentive-enabled': false,
  'web.argyle-delay-time': 5000,
  'web.enable-account-data-sources': false,
  'web.enable-employee-data-sources': false,
  'web.enable-inbound-etransfer-platform-select': false,
  'web.enable-my-cards-page': false,
  'web.enable-new-account-cards-endpoint': false,
  'web.enable-pay-cycle-data-sources': false,
  'web.enable-push-prompt': false,
  'web.enable-sitewide-alert': {},
  'web.enable-theme-switch': false,
  'web.flinks-improvements': '0',
  'web.inbound-etransfer-link-configuration': false,
  'web.kyc-with-onfido': false,
  'web.post-registration-funnel-variant': 'baseline',
  'web.sign-up.mastercard': false,
  [CoverFeatureFlags.EnableCoverFlowEntry]: false,
  [CoverFeatureFlags.EnableCoverRegistrationFeature]: false,
  [AddFundsFeatureFlags.AutodepositCardEnabled]: false,
  [AddFundsFeatureFlags.DebitCardLoadingEnabled]: false,
  [AnalyticsFeatureFlags.RemoveSegmentProfileProperties]: false,
  [contactFeatureFlags.useOpenChatsEndpoint]: false,
  [HISAFeatureFlags.EnableOnboardingFlow]: false,
  [HISAFeatureFlags.BypassRequirementsPage]: false,
  [HISAFeatureFlags.WsHisaDeprecated]: false,
  [IdentityVerificationFlags.kycChallengesEnabled]: false,
  [RegistrationFeatureFlags.HomeAddressRequiredIfPOBoxUsed]: false,
  [RegistrationFeatureFlags.InterestRate]: '5',
  [referAndEarnFlags.enabled]: false,
  [RegistrationFeatureFlags.EnableTiersSelection]: false,
  [RegistrationFeatureFlags.EnableSMSDownloadLink]: true,
  [RegistrationFeatureFlags.AlwaysRedirectToKYC]: false,
  [TiersFeatureFlags.EnableNonMemberTiersSelection]: false,
  [TiersFeatureFlags.EnableTiersStripePayment]: false,
  [TxFeatureFlags.ShowBalanceTooltip]: false,
  [MFAFeatureFlags.EnableSettings]: false,
  [MFAFeatureFlags.EnableNewLogin]: false,
  [MFAFeatureFlags.EnableDeviceVerification]: false,
  [PersonalizedOnboardingFeatureFlags.EnablePersonalizedOnboarding]: false,
  [RegistrationFeatureFlags.EnableAffiliateRegistration]: false,
  [CreditBuildingFeatureFlags.Enable]: false,
  [CreditBuildingFeatureFlags.LimitReached]: false,
  [BrazeFeatureFlags.HomeCardsEnabled]: false,
  [NewUsersAppDownloadExperiment.Enable]:
    NewUsersAppDownloadExperimentValue.OldUser,
};
