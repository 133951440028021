import { rest } from 'msw';

const registrationTiersHandlers = [
  rest.get(/\/benefits\/benefit-groups/, (req, _res, _ctx) => {
    return req.passthrough();

    // res(ctx.status(200), ctx.json(tiersJsonData));
  }),
  rest.get(/\/benefits\/subscriptions\/current/, async (req, res, ctx) => {
    const originalResponse = await ctx.fetch(req);
    const originalResponseData = await originalResponse.json();

    return res(
      ctx.status(200),
      ctx.json({
        ...originalResponseData,
        benefit_group: {
          ...originalResponseData.benefit_group,
          name: 'Everything',
        },
      }),
    );
  }),
];

export default registrationTiersHandlers;
