import { rootApi } from './rootApi';

type OnboardingChecklistDynamicLineItemData = {
  key: OnboardingChecklistDynamicItemKeys;
  label: string;
  completed: boolean;
  is_info_item: boolean;
};

type OnboardingChecklistDynamicStatus =
  | 'initial'
  | 'dismissible'
  | 'completed'
  | 'hidden';

export type OnboardingChecklistDynamicItemKeys =
  | 'IsAccountCreated'
  | 'IsAccountFunded'
  | 'IsCardAddedToWallet'
  | 'HasMadePurchase'
  | 'IsIdentityVerified'
  | 'HasSeenCreditScore'
  | 'IsCreditBuildingRegistered'
  | 'HasSetUtilizationRate'
  | 'IsEarningInterest'
  | 'IsSavingGoal'
  | 'IsReferralRewardUnlocked';

export type OnboardingChecklistDynamicResponse = {
  header: string;
  subheader: string;
  status: OnboardingChecklistDynamicStatus;
  line_items: OnboardingChecklistDynamicLineItemData[];
};

export const onboardingChecklistDynamicApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    checklistGet: build.query<OnboardingChecklistDynamicResponse, void>({
      query: () => ({ url: '/2.0/users/onboarding-checklist' }),
      providesTags: ['Checklist'],
    }),
    checklistDismiss: build.mutation<void, void>({
      query: () => ({
        url: '/2.0/users/onboarding-checklist/dismiss',
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: ['Checklist'],
      // optimistically update the query data to mark the checklist as hidden
      // if it fails, it will simply undo the update, since the checklist
      // tag will be invalidated
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          onboardingChecklistDynamicApi.util.updateQueryData(
            'checklistGet',
            undefined,
            (draft) => {
              draft.status = 'hidden';
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const { useChecklistGetQuery, useChecklistDismissMutation } =
  onboardingChecklistDynamicApi;
