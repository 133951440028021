import { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Link } from '@reach/router';
import { track } from 'analytics/analytics';
import { KDSIcons } from 'assets/images/kds_icons';
import { ReactComponent as KOHOCardIcon } from 'assets/images/transactions/koho-card.svg';
import { theme } from 'theme';

// TODO: Allow Stripe payment
enum PaymentMethods {
  KohoAccount = 'koho-account',
}

type Props = {
  balance: string;
  notEnoughFunds: boolean;
};

export const PaymentMethod = ({ balance, notEnoughFunds }: Props) => {
  const intl = useIntl();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(
    PaymentMethods.KohoAccount,
  );

  return (
    <Box>
      <Typography sx={{ fontWeight: 900, color: theme.colors.text, mb: 2 }}>
        {intl.formatMessage({
          id: 'TiersUpgrade.Modal.PaymentMethod',
          defaultMessage: 'Payment method',
        })}
      </Typography>
      <FormControl variant="outlined" sx={{ width: '100%' }}>
        <Select
          id="tiers-upgrade-payment-method"
          value={paymentMethod}
          onChange={(event) =>
            setPaymentMethod(event.target.value as PaymentMethods)
          }
        >
          <MenuItem value={PaymentMethods.KohoAccount}>
            <Box
              display="flex"
              flexDirection="row"
              sx={{ alignItems: 'center' }}
            >
              <KOHOCardIcon sx={{ width: 40, mr: '12px' }} />
              <Typography
                sx={{ fontWeight: 900, color: theme.colors.text, mr: 1 }}
              >
                KOHO
              </Typography>
              <Typography sx={{ fontWeight: 500, color: theme.colors.text }}>
                ({balance})
              </Typography>
            </Box>
          </MenuItem>
        </Select>
      </FormControl>
      {notEnoughFunds && (
        <>
          <Typography
            variant="body2"
            sx={{
              color: theme.colors.danger,
              mt: 2,
              fontWeight: 700,
              lineHeight: '18px',
            }}
          >
            {intl.formatMessage({
              id: 'TiersUpgrade.Modal.NotEnoughFunds',
              defaultMessage:
                'Your balance is too low. Please add money to upgrade.',
            })}
          </Typography>
          <Link
            to="/load"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontWeight: 900,
              py: 12,
              color: theme.colors.blueDark,
              textDecoration: 'none',
              ':hover': { textDecoration: 'underline' },
            }}
            onClick={() =>
              track({
                event: 'Clicked',
                properties: { name: 'tiers-confirm-modal-load-money' },
              })
            }
            data-cy="tiers-upgrade-payment-add-money"
          >
            <KDSIcons.Icons.Add
              width="16px"
              height="16px"
              sx={{
                mr: 2,
                mb: '2px',
                '> *': { stroke: theme.colors.blueDark },
              }}
            />
            {intl.formatMessage({
              id: 'TiersUpgrade.Modal.AddMoney',
              defaultMessage: 'Add money',
            })}
          </Link>
        </>
      )}
    </Box>
  );
};
