import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import { Link, navigate } from '@reach/router';
import { trackInput } from 'analytics/analytics';
import { ICurrentBenefit, useGetCurrentTierQuery } from 'apis/tiers';
import { KDSIcons } from 'assets/images/kds_icons';
import { ReactComponent as TiersIcon } from 'assets/images/logos/everything-blue-en.svg';
import { ReactComponent as TiersIconFr } from 'assets/images/logos/everything-blue-fr.svg';
import everythingBg from 'assets/images/tiers/everything-background-gradient.png';
import { LOCALE_EN, LOCALE_FR, LocaleContext } from 'components';
import DownloadApp from 'features/modals/DownloadApp';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { theme } from 'theme';
import { useTiersData } from 'tiers/hooks/useTiersData';
import { TiersFeatureFlags } from 'tiers/models/TiersFeatureFlags';

import { TierRenewal } from './TierRenewal';

const everythingLogoOptions = {
  [LOCALE_EN]: TiersIcon,
  [LOCALE_FR]: TiersIconFr,
};

const useStyles = makeStyles<
  Theme,
  {
    isEverything: boolean;
    isFrench: boolean;
    isCurrentTierManagementEnabled: boolean;
  }
>({
  container: {
    borderRadius: '8px',
    backgroundImage: (props) =>
      props.isEverything
        ? `url(${everythingBg})`
        : 'linear-gradient(98.61deg, #5347E1 0.03%, #2F3CCF 37.02%, #6D79FD 100.06%)',
    backgroundSize: '105%',
    backgroundPosition: 'top left',
    paddingBlock: '20px',
    paddingInline: '24px',
    marginBottom: '16px',
    cursor: (props) =>
      props.isCurrentTierManagementEnabled ? 'pointer' : undefined,
  },
  tierName: {
    display: (props) => (props.isEverything ? 'none' : 'block'),
    fontSize: '24px',
    fontWeight: 900,
    color: theme.colors.white,
  },
  everythingImage: {
    display: (props) => (props.isEverything ? 'block' : 'none'),
    width: (props) => (props.isFrench ? '110px' : '135px'),
    height: 'auto',
    marginBottom: '8px',
  },
  managePlan: {
    color: (props) =>
      props.isEverything ? theme.colors.blueNavy : theme.colors.white,
    fontSize: '16px',
    fontWeight: 900,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  sparklesIcon: {
    marginRight: '10px',
  },
});

const goToMyPlan = () => {
  trackInput({
    type: 'Clicked',
    name: 'Tiers - Current tier widget - Manage plan',
    details: {
      element: 'a',
      to: '/tiers/my-plan',
    },
  });

  navigate('/tiers/my-plan');
};

export const ManagePlanWidget = ({ benefit }: { benefit: ICurrentBenefit }) => {
  const isEverything = benefit.benefit_group?.name === 'Everything';
  const { locale } = useContext(LocaleContext);
  const isCurrentTierManagementEnabled =
    useFlags()[TiersFeatureFlags.EnableCurrentTiersManagement];
  const classes = useStyles({
    isEverything,
    isFrench: locale === LOCALE_FR,
    isCurrentTierManagementEnabled,
  });
  const intl = useIntl();
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { data: currentTier } = useGetCurrentTierQuery();
  const { getTierName } = useTiersData();
  const tierName = benefit.benefit_group?.name
    ? getTierName(benefit.benefit_group?.name)
    : '';

  const EverythingLogo = everythingLogoOptions[locale];

  const handleOnClick = () => {
    trackInput({
      type: 'Clicked',
      name: 'Tiers - Current tier widget - Manage plan',
      details: { element: 'a', to: '' },
    });

    setShowDownloadModal(true);
  };
  const handleOnClose = () => setShowDownloadModal(false);

  return (
    <Box
      className={classes.container}
      data-cy="manage-plan-widget"
      onClick={isCurrentTierManagementEnabled ? goToMyPlan : undefined}
    >
      <Typography classes={{ root: classes.tierName }}>{tierName}</Typography>

      <EverythingLogo className={classes.everythingImage} />
      {currentTier ? (
        <TierRenewal
          currentTier={currentTier}
          textColor={isEverything ? theme.colors.text : theme.colors.white}
        />
      ) : null}
      {!isCurrentTierManagementEnabled && (
        <>
          <Box display="flex" alignItems="center" sx={{ mt: '16px' }}>
            <KDSIcons.Icons.Tiers
              color={isEverything ? theme.colors.blueNavy : theme.colors.white}
              className={classes.sparklesIcon}
            />
            <Link to="#" className={classes.managePlan} onClick={handleOnClick}>
              {intl.formatMessage({
                defaultMessage: 'Manage my plan',
                id: 'ManagePlanWidget.ManagePlan',
              })}
            </Link>
          </Box>
          <DownloadApp open={showDownloadModal} onClose={handleOnClose} />
        </>
      )}
    </Box>
  );
};
