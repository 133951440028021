/** @jsxImportSource theme-ui */
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@material-ui/core';
import { useGetCurrentTierQuery } from 'apis/tiers';
import { KDSIcons } from 'assets/images/kds_icons';
import { useTrackPageViewedOnceDeprecated } from 'utility/analyticsHooks';

import SitewideAlert from '../components/SitewideAlert';
import { desktop, mobile, tablet, theme } from '../theme';
import { Link } from './Link';
import { Paragraph } from './Typography';
import { DesktopNav, MobileNav, PathItem, usePathList } from './navs';

export type Variants = 'center' | 'default';

export const Template = ({
  bgColor,
  children,
  hideSettingsLink = false,
  name,
  variant = 'default',
  widths = ['90%', '90%', '50%', '50%'],
}: {
  bgColor?: string;
  children: ReactNode;
  hideSettingsLink?: boolean;
  name: string;
  widths?: [string, string, string, string];
  variant?: Variants;
}) => {
  useGetCurrentTierQuery();

  useTrackPageViewedOnceDeprecated({ name });

  const pathList = usePathList();
  const paths = pathList.map((page) => {
    if (page.path === undefined && page.onClick === undefined) {
      throw new Error('fix your paths');
    }

    return <PathItem page={page} key={page.name}></PathItem>;
  });

  return (
    <div>
      <SitewideAlert />
      <div sx={{ [desktop]: { display: 'none' } }}>
        <MobileNav paths={paths} />
      </div>

      <div
        sx={{
          [mobile]: { display: 'none' },
          [tablet]: { display: 'none' },
          position: 'fixed',
          left: 0,
          width: '20%',
          height: '100%',
          backgroundColor: theme.colors.greyXLight,
        }}
      >
        <DesktopNav paths={paths} />
      </div>

      <main
        sx={{
          [desktop]: {
            width: '80%',
            height: '100%',
            position: 'absolute',
            right: 0,
            backgroundColor: bgColor ? bgColor : theme.colors.white,
          },
        }}
      >
        <SettingsLink background={!!bgColor} hide={hideSettingsLink} />

        <ContentWrap center={variant === 'center'} widths={widths}>
          {children}
        </ContentWrap>
      </main>
    </div>
  );
};

const SettingsLink = ({ background, hide }) => {
  const intl = useIntl();

  if (hide) return null;

  return (
    <Box
      pr={4}
      pt={3}
      display="none"
      sx={{ [desktop]: { display: 'flex', justifyContent: 'flex-end' } }}
    >
      <Link
        to="/settings"
        trackName="settings"
        sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
      >
        <KDSIcons.Icons.Profile
          sx={{
            mr: 2,
            color: background ? theme.colors.white : theme.colors.text,
          }}
        />
        <Paragraph
          sx={{ color: background ? theme.colors.white : theme.colors.text }}
        >
          {intl.formatMessage({
            id: 'TemplateSideMenu.Settings',
            defaultMessage: 'Settings',
          })}
        </Paragraph>
      </Link>
    </Box>
  );
};

const ContentWrap = ({ children, center, widths }) =>
  center ? (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        px={3}
        py={5}
        sx={{ width: widths, height: '100%' }}
      >
        {children}
      </Box>
    </Box>
  ) : (
    <Box>{children}</Box>
  );
