import { MessageDescriptor, defineMessage } from 'react-intl';

export interface Province {
  code: string;
  name: string;
  localizedName: MessageDescriptor;
}
export const provinceInfo: {
  [s: string]: Province;
} = {
  AB: {
    code: 'AB',
    name: 'Alberta',
    localizedName: defineMessage({
      id: 'Province.AB',
      defaultMessage: 'Alberta',
    }),
  },
  BC: {
    code: 'BC',
    name: 'British Columbia',
    localizedName: defineMessage({
      id: 'Province.BC',
      defaultMessage: 'British Columbia',
    }),
  },
  MB: {
    code: 'MB',
    name: 'Manitoba',
    localizedName: defineMessage({
      id: 'Province.MB',
      defaultMessage: 'Manitoba',
    }),
  },
  NB: {
    code: 'NB',
    name: 'New Brunswick',
    localizedName: defineMessage({
      id: 'Province.NB',
      defaultMessage: 'New Brunswick',
    }),
  },
  NL: {
    code: 'NL',
    name: 'Newfoundland and Labrador',
    localizedName: defineMessage({
      id: 'Province.NL',
      defaultMessage: 'Newfoundland and Labrador',
    }),
  },
  NT: {
    code: 'NT',
    name: 'Northwest Territories',
    localizedName: defineMessage({
      id: 'Province.NT',
      defaultMessage: 'Northwest Territories',
    }),
  },
  NS: {
    code: 'NS',
    name: 'Nova Scotia',
    localizedName: defineMessage({
      id: 'Province.NS',
      defaultMessage: 'Nova Scotia',
    }),
  },
  NU: {
    code: 'NU',
    name: 'Nunavut',
    localizedName: defineMessage({
      id: 'Province.NU',
      defaultMessage: 'Nunavut',
    }),
  },
  ON: {
    code: 'ON',
    name: 'Ontario',
    localizedName: defineMessage({
      id: 'Province.ON',
      defaultMessage: 'Ontario',
    }),
  },
  PE: {
    code: 'PE',
    name: 'Prince Edward Island',
    localizedName: defineMessage({
      id: 'Province.PE',
      defaultMessage: 'Prince Edward Island',
    }),
  },
  QC: {
    code: 'QC',
    name: 'Quebec',
    localizedName: defineMessage({
      id: 'Province.QC',
      defaultMessage: 'Quebec',
    }),
  },
  SK: {
    code: 'SK',
    name: 'Saskatchewan',
    localizedName: defineMessage({
      id: 'Province.SK',
      defaultMessage: 'Saskatchewan',
    }),
  },
  YT: {
    code: 'YT',
    name: 'Yukon',
    localizedName: defineMessage({
      id: 'Province.YT',
      defaultMessage: 'Yukon',
    }),
  },
};
