import { useEffect } from 'react';
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Box, Button, TitleSmall } from 'components';
import {
  KhAutocompleteSelect,
  SelectOption,
} from 'components/inputs/KhAutocompleteSelect';
import { KhInputText } from 'components/inputs/KhInputText';
import { EmploymentInputRecord, hisaActions } from 'hisa/store/slice';
import { industries } from 'profile/data/industries';
import { occupations } from 'profile/data/occupations';
import { selectProfile } from 'profile/store/selectors';

type Props = {
  company: string;
  industry: string;
  onContinue: () => void;
  role: string;
};

const formatToList =
  (intl: IntlShape) => (item: { title: MessageDescriptor; value: string }) => ({
    value: item.value,
    label: item.title ? intl.formatMessage(item.title) : item.value,
  });

export const EmploymentDetails = ({
  role,
  company,
  industry,
  onContinue,
}: Readonly<Props>) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const occupationList = occupations.map(formatToList(intl));
  const industryList = industries.map(formatToList(intl));

  const profile = useSelector(selectProfile);

  useEffect(() => {
    if (profile?.occupation && !role) {
      dispatch(
        hisaActions.setRole({
          title: profile?.occupation,
          code: profile?.occupation_code,
        }),
      );
    }
  }, [profile, role, dispatch]);

  const continueDisabled = !role || !company || !industry;

  // second-order function since both Autocomplete inputs use the same logic
  const handleSelectChange =
    (action: ActionCreatorWithPayload<EmploymentInputRecord>) =>
    (_: React.ChangeEvent<{}>, selectOption: SelectOption) => {
      if (!selectOption) {
        dispatch(action({ title: '', code: null }));
      } else {
        dispatch(
          action({ title: selectOption.label, code: selectOption.value }),
        );
      }
    };

  return (
    <Box sx={{ mt: 1, mb: 2 }}>
      <TitleSmall sx={{ mb: 4 }}>Employment Details</TitleSmall>
      <KhAutocompleteSelect
        disableClearable
        sx={{ my: 2 }}
        label={intl.formatMessage({
          id: 'EmploymentDetails.Role.Label',
          defaultMessage: 'Your role',
        })}
        value={role}
        options={occupationList}
        onChange={handleSelectChange(hisaActions.setRole)}
      />
      <KhInputText
        autoFocus
        sx={{ my: 2 }}
        label={intl.formatMessage({
          id: 'EmploymentDetails.Company.Label',
          defaultMessage: 'Company name',
        })}
        value={company}
        onChange={(e) => {
          dispatch(hisaActions.setCompanyName(e.target.value));
        }}
      />
      <KhAutocompleteSelect
        disableClearable
        sx={{ mt: 2 }}
        label={intl.formatMessage({
          id: 'EmploymentDetails.Category.Label',
          defaultMessage: 'Industry',
        })}
        value={industry}
        options={industryList}
        onChange={handleSelectChange(hisaActions.setIndustry)}
      />

      <Button
        sx={{ mt: 4 }}
        trackName="HISA Employer Continue"
        disabled={continueDisabled}
        onClick={onContinue}
      >
        {intl.formatMessage({
          id: 'HisaSetupPage.Continue',
          defaultMessage: 'Continue',
        })}
      </Button>
    </Box>
  );
};
