import { Box, BoxProps } from '@material-ui/core';
import { SubtitleSmall } from 'components/Typography';
import { twMerge } from 'tailwind-merge';

export type KhChipType =
  | 'dark'
  | 'light'
  | 'light-transparent'
  | 'outline'
  | 'outline-light';
export type KhChipDecorationType = 'stars';
export type KhChipTheme =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'warning'
  | 'danger'
  | 'success'
  | 'tiers-blue';

const getChipClassName = (type: KhChipType, chipTheme: KhChipTheme): string => {
  let className = '';

  switch (type) {
    case 'dark':
      switch (chipTheme) {
        case 'primary':
          className = 'text-white bg-primary-300 border-primary-300';
          break;
        case 'secondary':
          className = 'text-white bg-secondary-300 border-secondary-300';
          break;
        case 'tertiary':
          className = 'text-white bg-tertiary-400 border-tertiary-400';
          break;
        case 'tiers-blue':
          className =
            'text-tiers-blue-50 bg-tiers-blue-300 border-tiers-blue-300';
          break;
        case 'success':
          className = 'text-white bg-success-300 border-success-300';
          break;
        case 'warning':
          className = 'text-white bg-warning-400 border-warning-400';
          break;
        case 'danger':
          className = 'text-white bg-danger-300 border-danger-300';
          break;
      }
      break;
    case 'light':
      switch (chipTheme) {
        case 'primary':
          className = 'text-primary-300 bg-primary-50 border-primary-50';
          break;
        case 'secondary':
          className = 'text-secondary-400 bg-secondary-50 border-secondary-50';
          break;
        case 'tertiary':
          className = 'text-tertiary-400 bg-tertiary-100 border-tertiary-100';
          break;
        case 'tiers-blue':
          className =
            'text-tiers-blue-300 bg-tiers-blue-50 border-tiers-blue-50';
          break;
        case 'success':
          className = 'text-success-400 bg-success-50 border-success-50';
          break;
        case 'warning':
          className = 'text-warning-500 bg-warning-50 border-warning-50';
          break;
        case 'danger':
          className = 'text-danger-300 bg-danger-50 border-danger-50';
          break;
      }
      break;
    case 'light-transparent':
      switch (chipTheme) {
        case 'primary':
          className = 'text-primary-300 bg-primary-300/10 border-transparent';
          break;
        case 'secondary':
          className =
            'text-secondary-300 bg-secondary-300/10 border-transparent';
          break;
        case 'tertiary':
          className = 'text-tertiary-400 bg-tertiary-400/10 border-transparent';
          break;
        case 'tiers-blue':
          className =
            'text-tiers-blue-300 bg-tiers-blue-300/10 border-transparent';
          break;
        case 'success':
          className = 'text-success-300 bg-success-300/10 border-transparent';
          break;
        case 'warning':
          className = 'text-warning-300 bg-warning-300/10 border-transparent';
          break;
        case 'danger':
          className = 'text-danger-300 bg-danger-300/10 border-transparent';
          break;
      }
      break;
    case 'outline':
      switch (chipTheme) {
        case 'primary':
          className =
            'text-primary-300 bg-transparent border-primary-100 border-solid border';
          break;
        case 'secondary':
          className =
            'text-secondary-400 bg-transparent border-secondary-100 border-solid border';
          break;
        case 'tertiary':
          className =
            'text-tertiary-400 bg-transparent border-tertiary-200 border-solid border';
          break;
        case 'tiers-blue':
          className =
            'text-tiers-blue-300 bg-transparent border-tiers-blue-100 border-solid border';
          break;
        case 'success':
          className =
            'text-success-400 bg-transparent border-success-100 border-solid border';
          break;
        case 'warning':
          className =
            'text-warning-500 bg-transparent border-warning-100 border-solid border';
          break;
        case 'danger':
          className =
            'text-danger-300 bg-transparent border-danger-100 border-solid border';
          break;
      }
      break;
    case 'outline-light':
      switch (chipTheme) {
        case 'primary':
          className =
            'text-tertiary-75 bg-transparent border-primary-100 border-solid border';
          break;
        case 'secondary':
          className =
            'text-tertiary-75 bg-transparent border-secondary-100 border-solid border';
          break;
        case 'tertiary':
          className =
            'text-white bg-transparent border-tertiary-200 border-solid border';
          break;
        case 'tiers-blue':
          className =
            'text-tertiary-75 bg-transparent border-tiers-blue-100 border-solid border';
          break;
        case 'success':
          className =
            'text-tertiary-75 bg-transparent border-success-100 border-solid border';
          break;
        case 'warning':
          className =
            'text-tertiary-75 bg-transparent border-warning-100 border-solid border';
          break;
        case 'danger':
          className =
            'text-tertiary-75 bg-transparent border-danger-100 border-solid border';
          break;
      }
      break;
  }

  return className;
};

const getStarsClassName = (
  type: KhChipType,
  chipTheme: KhChipTheme,
): string => {
  let className = '';
  switch (type) {
    case 'dark':
      className = 'hidden';
      break;
    case 'light':
    case 'light-transparent':
    case 'outline':
      switch (chipTheme) {
        case 'primary':
          className = 'fill-primary-300';
          break;
        case 'secondary':
          className = 'fill-secondary-400';
          break;
        case 'tertiary':
          className = 'fill-tertiary-400';
          break;
        case 'tiers-blue':
          className = 'fill-tiers-blue-300';
          break;
        case 'success':
          className = 'fill-success-400';
          break;
        case 'warning':
          className = 'fill-warning-500';
          break;
        case 'danger':
          className = 'fill-danger-300';
          break;
      }
      break;
    case 'outline-light':
      className = 'fill-tertiary-75';
      break;
  }

  return className;
};

export const KhChip = ({
  children,
  decoration,
  type = 'dark',
  chipTheme = 'primary',
  className,
  icon,
  ...props
}: BoxProps & {
  decoration?: KhChipDecorationType;
  type?: KhChipType;
  chipTheme?: KhChipTheme;
  className?: string;
  children: string;
  icon?: React.ReactNode;
}) => {
  return (
    <Box
      className={twMerge(
        getChipClassName(type, chipTheme),
        `relative rounded-3xl pt-1 px-2 pb-[3px] inline-block flex-grow-0 w-fit`,
        className,
      )}
      {...props}
    >
      <Box className="flex">
        {icon}
        <SubtitleSmall sx={{ color: 'currentColor' }}>{children}</SubtitleSmall>
      </Box>
      {decoration === 'stars' ? (
        <>
          <StarSVG
            className={twMerge(
              getStarsClassName(type, chipTheme),
              'absolute top-[-2px] left-[-2px]',
            )}
          />
          <StarSVG
            className={twMerge(
              getStarsClassName(type, chipTheme),
              'absolute bottom-[-2px] right-[-2px]',
            )}
          />
        </>
      ) : null}
    </Box>
  );
};

const StarSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M5.75729 1.13942C5.81985 0.885981 6.18015 0.885982 6.24271 1.13942L6.89841 3.79548C7.07645 4.51669 7.63956 5.0798 8.36077 5.25784L11.0168 5.91354C11.2703 5.9761 11.2703 6.3364 11.0168 6.39896L8.36077 7.05466C7.63956 7.2327 7.07645 7.79581 6.89841 8.51702L6.24271 11.1731C6.18015 11.4265 5.81985 11.4265 5.75729 11.1731L5.10159 8.51702C4.92355 7.79581 4.36044 7.2327 3.63923 7.05466L0.983174 6.39896C0.729731 6.3364 0.729732 5.9761 0.983175 5.91354L3.63923 5.25784C4.36044 5.0798 4.92355 4.51669 5.10159 3.79548L5.75729 1.13942Z"
      fill="currentColor"
    />
  </svg>
);
