import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';

import { List, ListItem, ListItemIcon } from '@material-ui/core';
import { track } from 'analytics/analytics';
import { KDSIcons } from 'assets/images/kds_icons';
import { Button, Paragraph, ParagraphSmall, TitleLarge } from 'components';
import { IntendedUse } from 'kyc/models/IntendedUse';

interface IntentOption {
  title: string;
  subheading?: string;
  intent: IntendedUse;
  icon: ReactElement;
}

export const RegistrationIntentComponent = ({
  intentClicked,
}: {
  intentClicked: (intent: IntendedUse) => Promise<void>;
}) => {
  const intl = useIntl();
  const [otherOptionsShown, setOtherOptionsShown] = useState(false);

  const mainOptions: Array<IntentOption> = [
    {
      title: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.Cashback.Title',
        defaultMessage: 'Earn cash back',
      }),
      subheading: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.Cashback.Subheading',
        defaultMessage: 'Start earning on everyday spending',
      }),
      intent: IntendedUse.Cashback,
      icon: <KDSIcons.Features.Roundups sx={{ color: '#00579B' }} />,
    },
    {
      title: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.CreditBuilding.Title',
        defaultMessage: 'Build credit',
      }),
      subheading: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.CreditBuilding.Subheading',
        defaultMessage: 'Grow your credit history with ease',
      }),
      intent: IntendedUse.CreditBuilding,
      icon: <KDSIcons.Navigation.Grow sx={{ color: '#00579B' }} />,
    },
    {
      title: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.Saving.Title',
        defaultMessage: 'Save more',
      }),
      subheading: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.Saving.Subheading',
        defaultMessage: 'Earn interest, set goals, and more',
      }),
      intent: IntendedUse.Saving,
      icon: <KDSIcons.Icons.Rich sx={{ color: '#00579B' }} />,
    },
    {
      title: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.Borrowing.Title',
        defaultMessage: 'Borrow money',
      }),
      subheading: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.Borrowing.Subheading',
        defaultMessage: 'Borrow with zero-interest, no credit check',
      }),
      intent: IntendedUse.Borrowing,
      icon: <KDSIcons.Icons.Transfer sx={{ color: '#00579B' }} />,
    },
  ];

  const otherOptions: Array<IntentOption> = [
    {
      title: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.DailyLiving.Title',
        defaultMessage: 'Daily living expenses',
      }),
      intent: IntendedUse.DailyLivingExpenses,
      icon: <KDSIcons.Icons.Bills sx={{ color: '#00579B' }} />,
    },
    {
      title: intl.formatMessage({
        id: 'Registration.IntentPage.Intents.VirtualCurrency.Title',
        defaultMessage: 'Virtual currency purchases',
      }),
      intent: IntendedUse.VirtualCurrency,
      icon: <KDSIcons.Icons.Dollar sx={{ color: '#00579B' }} />,
    },
  ];

  return (
    <>
      <TitleLarge
        sx={{ mb: 0, fontSize: [24, 30] }}
        data-cy="intent-page-title"
      >
        {intl.formatMessage({
          id: 'Registration.IntentPage.Title',
          defaultMessage: 'What would you like to do next?',
        })}
      </TitleLarge>
      <Paragraph sx={{ mb: 5 }}>
        {intl.formatMessage({
          id: 'Registration.IntentPage.Subtitle',
          defaultMessage: 'Choose the one that’s most important to you.',
        })}
      </Paragraph>
      <List>
        {mainOptions.map((option) => {
          return (
            <ListItem
              button
              divider
              key={option.intent}
              onClick={() => {
                track({
                  event: 'Clicked',
                  properties: {
                    name: `intent-${option.intent}`,
                  },
                });
                intentClicked(option.intent);
              }}
              data-cy="main-intent-option"
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <div sx={{ display: 'block' }}>
                <Paragraph sx={{ fontWeight: '700', mb: 0 }}>
                  {option.title}
                </Paragraph>
                <ParagraphSmall sx={{ fontSize: '12px' }}>
                  {option.subheading}
                </ParagraphSmall>
              </div>
              <KDSIcons.Icons.Drilldown
                sx={{ marginLeft: 'auto', color: '#C3C5C9' }}
              />
            </ListItem>
          );
        })}
      </List>
      <Button
        variant="tertiary"
        onClick={() => setOtherOptionsShown(true)}
        disabled={otherOptionsShown}
      >
        {otherOptionsShown
          ? intl.formatMessage({
              id: 'Registration.IntentPage.OtherOptions',
              defaultMessage: 'Other options',
            })
          : intl.formatMessage({
              id: 'Registration.IntentPage.SomethingElse',
              defaultMessage: 'Something else',
            })}
      </Button>
      {otherOptionsShown && (
        <List>
          {otherOptions.map((option) => {
            return (
              <ListItem
                button
                divider
                key={option.intent}
                onClick={() => intentClicked(option.intent)}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <div sx={{ display: 'block' }}>
                  <Paragraph sx={{ fontWeight: '700' }}>
                    {option.title}
                  </Paragraph>
                </div>
                <KDSIcons.Icons.Drilldown
                  sx={{ marginLeft: 'auto', color: '#C3C5C9' }}
                />
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
};
