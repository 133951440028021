import { ReactNode, memo } from 'react';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
// components
import { KDSIcons } from 'assets/images/kds_icons';
import { ButtonUnstyled } from 'components';
// theme
import { theme } from 'theme';
// types
import { Image } from 'types/Image';

/**
 * @typedef {Object} DialogWithImageProps
 * @property {ReactNode} children - Any ReactNode children
 * @property {string} description - The copy text for the modal
 * @property {Image} image - {@link Image} object
 * @property {boolean} inverted - Determine if the widget needs to be inverted or not
 * @property {string} title - The title of the modal
 */
interface DialogWithImageProps {
  children: ReactNode;
  description: string;
  image: Image;
  inverted?: boolean;
  open?: boolean;
  onClose?: () => void;
  title: string;
}

const useStyles = makeStyles({
  dialogActions: {
    color: theme.colors.white,
    marginBottom: '32px',
    marginLeft: '24px',
    marginRight: '24px',
    padding: 0,
  },
  dialogActionsInverted: {
    color: theme.colors.text,
    marginBottom: '32px',
    marginLeft: '24px',
    marginRight: '24px',
    padding: 0,
  },
  dialogContent: {
    marginBottom: '24px',
    marginLeft: '24px',
    marginRight: '24px',
    padding: 0,
  },
  dialogContentText: {
    color: theme.colors.white,
    fontFamily: `"Basis Grotesque Pro", sans-serif`,
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '-0.2px',
    lineHeight: '22px',
    textAlign: 'center',
  },
  dialogContentTextInverted: {
    color: theme.colors.text,
    fontFamily: `"Basis Grotesque Pro", sans-serif`,
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '-0.2px',
    lineHeight: '22px',
    textAlign: 'center',
  },
  dialogTitle: {
    color: theme.colors.white,
    fontFamily: `"Basis Grotesque Pro", sans-serif`,
    fontSize: '32px',
    fontWeight: 700,
    letterSpacing: '-0.2px',
    lineHeight: '36px',
    paddingTop: 0,
    textAlign: 'center',
  },
  dialogTitleInverted: {
    color: theme.colors.text,
    fontFamily: `"Basis Grotesque Pro", sans-serif`,
    fontSize: '32px',
    fontWeight: 700,
    letterSpacing: '-0.2px',
    lineHeight: '36px',
    paddingTop: 0,
    textAlign: 'center',
  },
  paper: {
    backgroundColor: theme.colors.blueNavy,
    borderRadius: '12px',
    color: theme.colors.white,
    colorTextSecondary: {
      color: theme.colors.white,
    },
  },
  paperInverted: {
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    color: theme.colors.blueNavy,
    colorTextSecondary: {
      color: theme.colors.blueNavy,
    },
  },
});

/**
 * Displays a modal with an image
 * @param {DialogWithImageProps} - {@link DialogWithImageProps} object
 * @returns
 */
export const DialogWithImage = ({
  children,
  description,
  image,
  inverted = false,
  open = false,
  onClose,
  title,
  ...props
}: DialogWithImageProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      {...props}
      classes={{
        paper: inverted ? classes.paperInverted : classes.paper,
      }}
    >
      {image && (
        <div
          style={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <div
            sx={{
              backgroundImage: `url(${image.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              ...(image?.displayHeights && { height: image.displayHeights }),
              ...(image?.displayWidths && { width: [image.displayWidths] }),
            }}
          ></div>
        </div>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '24px',
          marginTop: '24px',
          marginBottom: 0,
          color: inverted ? theme.colors.text : theme.colors.white,
        }}
      >
        <ButtonUnstyled onClick={onClose}>
          <KDSIcons.Icons.Cross />
        </ButtonUnstyled>
      </Box>
      <DialogTitle
        id="dialog-title"
        disableTypography
        classes={{
          root: inverted ? classes.dialogTitleInverted : classes.dialogTitle,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <DialogContentText
          id="alert-dialog-description"
          classes={{
            root: inverted
              ? classes.dialogContentTextInverted
              : classes.dialogContentText,
          }}
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        classes={{
          root: inverted
            ? classes.dialogActionsInverted
            : classes.dialogActions,
        }}
      >
        {children}
      </DialogActions>
    </Dialog>
  );
};

export default memo(DialogWithImage);
