import { createSelector } from '@reduxjs/toolkit';
import { differenceInDays } from 'date-fns';
import { RootState } from 'store';

import { Transaction } from '../models';

export const selectTransactionsState = (state: RootState) => state.transactions;

export const selectTransactions = createSelector(
  selectTransactionsState,
  (state) => state && state.data,
);

export const selectTransactionsLoadable = createSelector(
  selectTransactionsState,
  (state) => state && state.loadable,
);

export const selectTransactionsLoading = createSelector(
  selectTransactionsLoadable,
  (state) => state && state.loading,
);

export const selectTransactionVisaSettle = createSelector(
  selectTransactions,
  (data) => {
    const visaSettle = 'VS';
    return (
      data &&
      data?.filter((type: Transaction) => type.activityType === visaSettle)
    );
  },
);

export const selectUserHasSettledTransactionForOnboardingChecklist =
  createSelector(selectTransactionVisaSettle, (transactions) => {
    if (transactions) {
      const earliestTimestamp = transactions.pop()?.postTimestamp;
      if (earliestTimestamp) {
        let delta = differenceInDays(new Date(), new Date(earliestTimestamp));
        return delta > 5;
      }
    }
  });
