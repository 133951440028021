import { isAfter, isBefore, isValid, parseISO, subYears } from 'date-fns';
import { formatDate } from 'utility/formatDate';

const dateOfBirthDateValidator = (dateOfBirth: string): boolean => {
  return isValid(parseISO(dateOfBirth));
};

const dateOfBirthMinAgeValidator = (dateOfBirth: string): boolean => {
  const date = new Date(formatDate(dateOfBirth));
  const ageOfMajorityDoB = subYears(new Date(), 18);

  return isBefore(date, ageOfMajorityDoB);
};

const dateOfBirthMaxAgeValidator = (dateOfBirth: string): boolean => {
  const date = new Date(formatDate(dateOfBirth));
  const ageOfOldestDoB = subYears(new Date(), 119);

  return isAfter(date, ageOfOldestDoB);
};

export const registrationValidators = {
  dateOfBirthDateValidator,
  dateOfBirthMinAgeValidator,
  dateOfBirthMaxAgeValidator,
};
