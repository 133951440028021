/** @jsxImportSource theme-ui */
import React from 'react';

import { TextMaskPhone } from '../forms/TextMaskPhone';
import { KhInputText, KhInputTextProps } from './KhInputText';

export const KhInputMobileNumber = React.forwardRef<
  HTMLInputElement,
  KhInputTextProps
>((props: KhInputTextProps, ref) => {
  return (
    <KhInputText
      ref={ref}
      type="tel"
      InputProps={{ inputComponent: TextMaskPhone, inputMode: 'numeric' }}
      {...props}
    />
  );
});
