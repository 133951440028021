import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { navigate } from '@reach/router';
import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import { Widget } from 'components';
import { ButtonTw } from 'components/ButtonTw';

export const VerifyIdentityWidget = () => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);

  const ctaText = intl.formatMessage({
    id: 'VerifyIdentityWidget.Button',
    defaultMessage: 'Verify my identity now',
  });

  const description = intl.formatMessage({
    id: 'VerifyIdentityWidget.UnlockLimits.Content',
    defaultMessage:
      'By verifying your identity you can start earning interest, build credit, and more!',
  });

  const title = intl.formatMessage({
    id: 'VerifyIdentityWidget.Title',
    defaultMessage: 'Want to get more out of KOHO?',
  });

  const img = {
    url: `${import.meta.env.VITE_STATIC_URL}/widgets/verify_identity.jpeg`,
  };

  return (
    <Widget
      description={description}
      image={img}
      title={title}
      data-cy="kyc-verify-identity-widget"
    >
      <ButtonTw
        className="[&>span]:flex [&>span]:flex-row [&>span]:gap-2 [&>span]:items-center [&>span]:justify-center"
        aria-label={ctaText}
        data-cy="kyc-verify-identity-widget-cta"
        onClick={() => navigate('/kyc/entry')}
        trackName="Verify identity widget"
        variant="primaryInverted"
      >
        {ctaText}
        {themeName === 'light' ? <KDSIcons.Icons.ArrowNarrowRight /> : null}
      </ButtonTw>
    </Widget>
  );
};
