import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { navigate } from '@reach/router';
import { trackInput } from 'analytics/analytics';
// components
import { Button } from 'components';
import DialogWithImage from 'components/DialogWithImage';
// types
import type { Image } from 'types/Image';

const imageUrl = 'https://assets.koho.ca/tiers/welcome_modal.jpeg';

const WelcomeModal = ({ open = false }: { open: boolean }) => {
  const intl = useIntl();

  const [localOpen, setLocalOpen] = useState(open);

  const handleClose = () => {
    trackInput({
      type: 'Clicked',
      name: 'Modal - Welcome -- Cancel',
      details: { element: 'button' },
    });

    setLocalOpen(!localOpen);
  };

  useEffect(() => {
    setLocalOpen(open);
  }, [open]);

  const modalDescription = intl.formatMessage({
    id: 'WelcomeModal.Description',
    defaultMessage:
      'Thanks for choosing KOHO. The first step on your financial journey is adding money to your account. Would you like to add some now?',
  });

  const modalTitle = intl.formatMessage({
    id: 'WelcomeModal.Title',
    defaultMessage: 'Welcome to KOHO!',
  });

  const ctaAddMoney = intl.formatMessage({
    id: 'WelcomeModal.CTA.AddMoney.Text',
    defaultMessage: 'Add money',
  });

  const ctaCancel = intl.formatMessage({
    id: 'WelcomeModal.CTA.Cancel.Text',
    defaultMessage: 'Not now',
  });

  const image: Image = {
    displayHeights: ['160px', '350px'],
    displayWidths: ['370px', '600px'],
    url: imageUrl,
  };

  return (
    <DialogWithImage
      data-cy="welcome-modal"
      description={modalDescription}
      image={image}
      title={modalTitle}
      open={localOpen}
      onClose={handleClose}
    >
      <Button
        data-cy="welcome-modal-cta-add-money"
        onClick={() => navigate('/load')}
        trackName="Modal - Welcome -- Add money"
        variant="primaryInverted"
      >
        {ctaAddMoney}
      </Button>
      <Button
        data-cy="welcome-modal-cta-cancel"
        onClick={handleClose}
        sx={{ color: 'white' }}
        variant="clear"
      >
        {ctaCancel}
      </Button>
    </DialogWithImage>
  );
};

export default WelcomeModal;
