import { forwardRef } from 'react';

import { InputAdornment } from '@material-ui/core';
import { trackInput } from 'analytics/analytics';
import { useToggle } from 'hooks/useToggle';
import { ShowHideButtonTw } from 'passwordReset/Components/ButtonShowHideTw';

import { KhInputTextProps, KhInputTextTw } from './KhInputTextTw';

export const KhInputPasswordTw = forwardRef<HTMLInputElement, KhInputTextProps>(
  (props: KhInputTextProps, ref) => {
    const { value: passVisible, toggle: passToggle } = useToggle();

    const onShowHideClick = () => {
      trackInput({ name: 'password-show-hide', type: 'Clicked' });
      passToggle();
    };

    return (
      <KhInputTextTw
        type={passVisible ? 'text' : 'password'}
        ref={ref}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ShowHideButtonTw
                onClick={onShowHideClick}
                visible={passVisible}
              />
            </InputAdornment>
          ),
        }}
        {...props}
      />
    );
  },
);
