import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectTheme } from 'appState/appState.slice';
import { ReactComponent as AccessibilityIcon } from 'assets/images/accessibility.svg';
import { ReactComponent as LegacyAccessibilityIcon } from 'assets/images/legacy/accessibility-blue.svg';
import { Paragraph } from 'components/TypographyTw';
import { twMerge } from 'tailwind-merge';

export const AccessibilityButton = ({ className }: { className?: string }) => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);

  const Icon =
    themeName === 'light' ? AccessibilityIcon : LegacyAccessibilityIcon;

  return (
    <button
      className="inline-flex items-center"
      onClick={() => (window as any)?.interdeal?.a11y?.openMenu()}
    >
      <Paragraph
        className={twMerge(
          'font-bold m-0 text-base text-primary-300 hover:underline',
          'light:font-medium light:text-grey-500',
          className,
        )}
      >
        {intl.formatMessage({
          id: 'Accessibility.Text',
          defaultMessage: 'Accessibility',
        })}
      </Paragraph>
      <Icon className="ml-2 duration-300" />
    </button>
  );
};
