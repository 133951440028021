import axios, { AxiosError } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RootState } from 'store';
import { v4 as uuidv4 } from 'uuid';

import { track } from '../../analytics/analytics';
import { Money } from '../../models/Money';
import { RecipientData } from '../../models/Recipient';
import { RecipientFormData } from '../../models/RecipientFormData';
import { TransferFormData } from '../../models/TransferFormData';
import { E_TRANSFER_FUNDS_ERROR, transferActions } from './slice';
import { DEVICE_VERIFICATION_ERROR, E_TRANSFER_ERROR } from './slice';

interface StandardResponse {
  status_code: number;
  status_name: string;
}

interface SendETransferError extends AxiosError {
  error_code: number;
}

type AddUpdateRecipientResponse = StandardResponse & {
  data: RecipientData & StandardResponse;
};

enum SEND_ERROR_CODES {
  INSUFFICIENT_FUNDS = 1,
}

const addRecipientEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(transferActions.addRecipientRequest.toString()),
    switchMap((action: { payload: TransferFormData }) => {
      const { payload } = action;
      return addRecipient(payload.recipient).pipe(
        tap(() => track({ event: 'E-Transfer Recipient Added' })),
        switchMap((response) => [
          transferActions.addRecipientSuccess(response.data.data),
          transferActions.updateRecipientRequest({
            recipientID: response.data.data.identifier,
            formData: payload,
          }),
        ]),
        catchError(() => {
          track({ event: 'E-Transfer Add Recipient Failed' });
          return of(transferActions.addRecipientError());
        }),
      );
    }),
  );
};

const updateRecipientEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(transferActions.updateRecipientRequest.toString()),
    switchMap(
      (action: {
        payload: { recipientID: string; formData: TransferFormData };
      }) => {
        const { payload } = action;

        return updateRecipient(
          payload.recipientID,
          payload.formData.recipient,
        ).pipe(
          tap(() => track({ event: 'E-Transfer Recipient Updated' })),
          switchMap((response) => [
            transferActions.updateRecipientSuccess(response.data.data),
            transferActions.sendETransferRequest({
              recipientID: response.data.data.identifier,
              amount: payload.formData.amount,
            }),
          ]),
          catchError(() => {
            tap(() => track({ event: 'E-Transfer Update Recipient Failed' }));
            return of(transferActions.updateRecipientError());
          }),
        );
      },
    ),
  );
};

type GetRecipientsResponse = StandardResponse & { data: RecipientData[] };

const getRecipientsEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(transferActions.getRecipientsRequest.toString()),
    switchMap(() => {
      return getRecipients().pipe(
        map((response) => transferActions.getRecipientsSuccess(response.data)),
        catchError(() => {
          return of(transferActions.getRecipientsError());
        }),
      );
    }),
  );
};

const sendETransferEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(transferActions.sendETransferRequest.toString()),
    switchMap(
      (action: { payload: { recipientID: string; amount: string } }) => {
        const { recipientID, amount } = action.payload;

        return sendETransfer(recipientID, amount).pipe(
          tap(() => track({ event: 'E-Transfer Sent' })),
          map(() => transferActions.sendETransferSuccess()),
          catchError((error: AxiosError) => {
            tap(() => track({ event: 'E-Transfer Send Failed' }));

            const data = error?.response?.data as SendETransferError;

            if (
              error?.response?.status === 400 &&
              data?.error_code === SEND_ERROR_CODES.INSUFFICIENT_FUNDS
            ) {
              return of(
                transferActions.sendETransferError(E_TRANSFER_FUNDS_ERROR),
              );
            }
            if (error?.response?.status === 403) {
              return of(
                transferActions.sendETransferError(DEVICE_VERIFICATION_ERROR),
              );
            }
            return of(transferActions.sendETransferError(E_TRANSFER_ERROR));
          }),
        );
      },
    ),
  );
};

const clickSendETransferEpic: Epic<any, any, RootState> = (action$, state$) => {
  return action$.pipe(
    ofType(transferActions.clickSendETransfer.toString()),
    switchMap((action: { payload: { formData: TransferFormData } }) => {
      const { formData } = action.payload;
      const recipients: RecipientData[] = state$.value.transfer.recipients;

      const existingRecipient =
        recipients &&
        recipients.find((r) => r.address === formData.recipient.email);

      if (!existingRecipient)
        return [transferActions.addRecipientRequest(formData)];

      if (
        existingRecipient.full_name !== formData.recipient.name ||
        existingRecipient.security_question !==
          formData.recipient.secretQuestion ||
        formData.recipient.secretAnswer
      ) {
        return [
          transferActions.updateRecipientRequest({
            recipientID: existingRecipient.identifier,
            formData: formData,
          }),
        ];
      }

      return [
        transferActions.sendETransferRequest({
          recipientID: existingRecipient.identifier,
          amount: formData.amount,
        }),
      ];
    }),
  );
};

function addRecipient(payload: RecipientFormData) {
  return from(
    axios.post<AddUpdateRecipientResponse>(
      `${import.meta.env.VITE_GATEWAY_API}transfers/user/recipients`,
      {
        full_name: payload.name,
        email: payload.email,
        question: payload.secretQuestion,
        answer: payload.secretAnswer,
        recipient_id: uuidv4(),
      },
    ),
  );
}

function updateRecipient(recipientID: string, formData: RecipientFormData) {
  let data: {
    full_name?: string;
    email?: string;
    question?: string;
    answer?: string;
  } = {};

  if (formData.name) {
    data.full_name = formData.name;
  }
  if (formData.email) {
    data.email = formData.email;
  }
  if (formData.secretQuestion) {
    data.question = formData.secretQuestion;
  }
  if (formData.secretAnswer) {
    data.answer = formData.secretAnswer;
  }

  return from(
    axios.put<AddUpdateRecipientResponse>(
      `${
        import.meta.env.VITE_GATEWAY_API
      }transfers/user/recipient/${recipientID}`,
      data,
    ),
  );
}

function getRecipients() {
  return from(
    axios.get<GetRecipientsResponse>(
      `${import.meta.env.VITE_GATEWAY_API}transfers/user/recipients`,
    ),
  );
}

function sendETransfer(recipientID: string, amount: string) {
  return from(
    axios.post<StandardResponse>(
      `${import.meta.env.VITE_GATEWAY_API}transfers/user/etransfers`,
      {
        recipient_id: recipientID,
        transaction_id: uuidv4(),
        amount: new Money(amount, 'en').format(), // avoids request being sent with FR formatting
      },
    ),
  );
}

const exportedArray = [
  addRecipientEpic,
  updateRecipientEpic,
  getRecipientsEpic,
  sendETransferEpic,
  clickSendETransferEpic,
];

export default exportedArray;
