import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getLaunchDarklyAnonymousUserId } from '..';
import {
  Loadable,
  createDefaultLoadable,
  createErrorLoadable,
  createLoadingLoadable,
  createSuccessLoadable,
} from '../../../utility/loadable';

export interface LaunchDarklyState {
  anonymous: {
    hash: string;
    loadable: Loadable;
    id: string;
  };
  authenticated: {
    hash: string;
    loadable: Loadable;
  };
  flags: {
    loadable: Loadable;
  };
}

const initialState: LaunchDarklyState = {
  anonymous: {
    hash: '',
    loadable: createDefaultLoadable(),
    id: getLaunchDarklyAnonymousUserId(),
  },
  authenticated: {
    hash: '',
    loadable: createDefaultLoadable(),
  },
  flags: {
    loadable: createDefaultLoadable(),
  },
};

const launchDarklySlice = createSlice({
  name: 'launchDarkly',
  initialState: initialState,
  reducers: {
    getUserHashRequest: (state: LaunchDarklyState) => {
      state.authenticated = {
        hash: '',
        loadable: createLoadingLoadable(),
      };
      state.flags = {
        loadable: createLoadingLoadable(),
      };
    },
    getUserHashResponse: (
      state: LaunchDarklyState,
      action: PayloadAction<string>,
    ) => {
      state.authenticated = {
        hash: action.payload,
        loadable: createSuccessLoadable(),
      };
    },
    getUserHashError: (state: LaunchDarklyState) => {
      state.authenticated = {
        hash: '',
        loadable: createErrorLoadable('Unable to load user hash'),
      };
      state.flags = {
        loadable: createErrorLoadable('Unable to obtain flags'),
      };
    },
    getAnonymousUserHashRequest: (state: LaunchDarklyState) => {
      state.anonymous = {
        ...state.anonymous,
        hash: '',
        loadable: createLoadingLoadable(),
      };
      state.flags = {
        loadable: createLoadingLoadable(),
      };
    },
    getAnonymousUserHashResponse: (
      state: LaunchDarklyState,
      action: PayloadAction<string>,
    ) => {
      state.anonymous = {
        ...state.anonymous,
        hash: action.payload,
        loadable: createSuccessLoadable(),
      };
    },
    getAnonymousUserHashError: (state: LaunchDarklyState) => {
      state.anonymous = {
        ...state.anonymous,
        hash: '',
        loadable: createErrorLoadable('Unable to load anonymous user hash'),
      };
      state.flags = {
        loadable: createErrorLoadable('Unable to obtain flags'),
      };
    },
    getFlagsSuccess: (state: LaunchDarklyState) => {
      state.flags = {
        loadable: createSuccessLoadable(),
      };
    },
    getFlagsError: (state: LaunchDarklyState) => {
      state.flags = {
        loadable: createErrorLoadable('Unable to obtain flags'),
      };
    },
  },
});

export const launchDarklyActions = launchDarklySlice.actions;
export default launchDarklySlice.reducer;
