/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import AddCircle from '@material-ui/icons/AddCircle';
import ChevronRight from '@material-ui/icons/ChevronRight';

import {
  Paragraph,
  ParagraphSmall,
  TitleLarge,
  TitleSmall,
} from '../../components';
import { Recipient } from '../../models/Recipient';
import { theme } from '../../theme';
import { RecipientAvatar } from './RecipientAvatar';

export const Recipients = ({
  filter,
  list,
  onClickAddRecipient,
  onClickRecipient,
}: {
  filter?: (recipient: Recipient) => boolean;
  list: Recipient[];
  onClickAddRecipient: () => void;
  onClickRecipient: (recipient: Recipient) => void;
}) => {
  const intl = useIntl();

  const sortedRecipients = list.sort((last, next) =>
    last.initials[0] < next.initials[0] ? -1 : 1,
  );
  const groupedRecipients = sortedRecipients.reduce(
    (groups: { letter: string; list: Recipient[] }[], recipient: Recipient) => {
      if (filter && !filter(recipient)) return groups;

      const updatedGroup = [...groups];
      const lastGroup = updatedGroup[updatedGroup.length - 1];
      const recipientNameFirstLetter = recipient.fullName[0];
      if (!lastGroup || lastGroup.letter !== recipientNameFirstLetter[0]) {
        updatedGroup.push({
          letter: recipientNameFirstLetter[0],
          list: [recipient],
        });
        return updatedGroup;
      }

      lastGroup.list.push(recipient);
      return updatedGroup;
    },
    [],
  );

  return (
    <section>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item sx={{ width: '80%' }}>
          <TitleLarge>
            {intl.formatMessage({
              id: 'Recipients.Heading',
              defaultMessage: 'Who do you want to send money to?',
            })}
          </TitleLarge>
        </Grid>
        <Grid item>
          <Button onClick={() => onClickAddRecipient()}>
            <AddCircle
              sx={{ fill: theme.colors.primary, fontSize: 5 }}
            ></AddCircle>
          </Button>
        </Grid>
      </Grid>
      {groupedRecipients.map((group) => (
        <List sx={{ m: 0 }} key={group.letter}>
          <ListSubheader sx={{ p: 0 }}>
            <TitleSmall>{group.letter.toUpperCase()}</TitleSmall>
          </ListSubheader>
          {group.list.map((recipient, i) => (
            <React.Fragment key={group.letter + i}>
              <ListItem
                sx={{ px: 0, py: 3 }}
                button={true}
                divider={true}
                onClick={() => onClickRecipient(recipient)}
              >
                <ListItemIcon>
                  <RecipientAvatar recipient={recipient}></RecipientAvatar>
                </ListItemIcon>
                <ListItemText>
                  <Paragraph sx={{ m: 0 }}>{recipient.fullName}</Paragraph>
                  <ParagraphSmall>{recipient.address}</ParagraphSmall>
                </ListItemText>
                <ChevronRight sx={{ color: theme.colors.text }}></ChevronRight>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      ))}
    </section>
  );
};
