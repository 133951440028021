import { useEffect, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { List } from '@material-ui/core';
import { navigate } from '@reach/router';
import { useCreateHisaAccountMutation } from 'apis/hisa';
import {
  Button,
  Paragraph,
  ProgressBar,
  Template,
  TitleLarge,
} from 'components';
import { contactActions } from 'contact/store/contact.slice';
import { HisaRegistrationSteps } from 'hisa/pages/HisaSetupPage';
import {
  HisaState,
  selectHisaOnboarding,
  transformatHisaOnboardingDataForApi,
} from 'hisa/store/slice';
import { theme } from 'theme';

import { BulletItem } from '../components/BulletItem';
import { ContactChatModal } from '../components/ContactChatModal';

const Assumptions = [
  defineMessage({
    id: 'HisaAssumptionsPage.Assumption.Citizenship',
    defaultMessage:
      'You are not a US dual citizen, green card holder, resident alien, or person born in the US',
  }),
  defineMessage({
    id: 'HisaAssumptionsPage.Assumption.PowerOfAttorney',
    defaultMessage:
      "You don't want to give anyone Power of Attorney over your account",
  }),
  defineMessage({
    id: 'HisaAssumptionsPage.Assumption.PTCHead',
    defaultMessage:
      'You are not a senior director or officer of a publicly traded company',
  }),
  defineMessage({
    id: 'HisaAssumptionsPage.Assumption.PTCControl',
    defaultMessage:
      "You don't own more than 10% of a publicly traded company (either individually or as a group)",
  }),
  defineMessage({
    id: 'HisaAssumptionsPage.Assumption.PEP',
    defaultMessage:
      "You're not related to any politically exposed persons (e.g. Prime Minister of Canada)",
  }),
  defineMessage({
    id: 'HisaAssumptionsPage.Assumption.Mail',
    defaultMessage:
      "You don't want to receive mail from the money in your portfolio",
  }),
];

export const HisaAssumptionsPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onboardingData: HisaState = useSelector(selectHisaOnboarding);

  const [openDialog, setOpenDialog] = useState(false);
  const [hisaError, setHisaError] = useState<string | null>(null);

  const [createAccount, createRequest] = useCreateHisaAccountMutation();

  useEffect(() => {
    if (onboardingData.citizenship === '') {
      // this starts the flow over in case of a reset (which clears the store)
      navigate('/hisa/setup');
    }
  }, [onboardingData]);

  const createAccountOnClick = async () => {
    let accountData = transformatHisaOnboardingDataForApi(onboardingData);

    try {
      await createAccount(accountData).unwrap();
      navigate('/hisa/agreement');
    } catch (error) {
      if (typeof error === 'object' && error != null && 'status' in error) {
        console.error('Error creating account:', error);
        setHisaError(JSON.stringify(error, null, 2));
      }
    }
  };

  return (
    <Template name="HISA Assumptions" variant="center">
      <ProgressBar
        currentStep={HisaRegistrationSteps.length + 1}
        totalSteps={HisaRegistrationSteps.length + 2}
      />
      <TitleLarge>
        {intl.formatMessage({
          id: 'HisaAssumptionsPage.Title',
          defaultMessage: 'Our assumptions about you',
        })}
      </TitleLarge>
      <Paragraph sx={{ my: 2 }}>
        {intl.formatMessage({
          id: 'HisaAssumptionsPage.Description',
          defaultMessage:
            "To make it easy, we've made the following assumptions about you:",
        })}
      </Paragraph>

      <List sx={{ pl: 4, listStyleType: 'disc' }}>
        {Assumptions.map((assumption) => (
          <BulletItem key={assumption.id}>
            {intl.formatMessage(assumption)}
          </BulletItem>
        ))}
      </List>

      {hisaError === null && (
        <>
          <Button
            sx={{ mt: 4 }}
            disabled={hisaError != null || createRequest.isLoading}
            trackName="HISA Assumptions Continue"
            onClick={() => createAccountOnClick()}
          >
            {intl.formatMessage({
              id: 'HisaAssumptionsPage.Continue',
              defaultMessage: 'These assumptions are correct',
            })}
          </Button>

          <Button
            sx={{ mt: 3 }}
            disabled={createRequest.isLoading}
            variant="tertiary"
            trackName="HISA Assumptions Cancel"
            onClick={() => setOpenDialog(true)}
          >
            {intl.formatMessage({
              id: 'HisaAssumptionsPage.Cancel',
              defaultMessage: 'One or more is NOT correct',
            })}
          </Button>
        </>
      )}

      {hisaError && (
        <Paragraph
          sx={{ mt: 3, textAlign: 'center', color: theme.colors.danger }}
        >
          {intl.formatMessage({
            id: 'HisaAssumptionsPage.Error',
            defaultMessage:
              'Something went wrong. Please reach out to support to continue.',
          })}
        </Paragraph>
      )}

      {hisaError && (
        <Button
          sx={{ mt: 3 }}
          disabled={createRequest.isLoading}
          variant="clear"
          trackName="HISA Assumptions Support"
          onClick={() => dispatch(contactActions.intercomOpen())}
        >
          {intl.formatMessage({
            id: 'HisaAssumptionsPage.Error',
            defaultMessage: 'Reach out to support',
          })}
        </Button>
      )}

      <ContactChatModal
        openDialog={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </Template>
  );
};
