import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { CreateHisaAccountRequest } from '../../apis/hisa';

export type EmploymentStatus =
  | 'employed'
  | 'unemployed'
  | 'student'
  | 'retired'
  | 'self-employed'
  | '';

export interface EmploymentInputRecord {
  title: string;
  code: string | null;
}

export interface HisaState {
  citizenship: string;
  employmentStatus: EmploymentStatus;
  employmentDetails: {
    companyName: string;
    companyCategory: EmploymentInputRecord;
    employmentRole: EmploymentInputRecord;
  };
  sin: string;
  successfullyCreated: boolean; // true after it is created and before the success modal is shown
}

const initialState: HisaState = {
  citizenship: '',
  employmentStatus: '',
  employmentDetails: {
    companyName: '',
    companyCategory: { title: '', code: null },
    employmentRole: { title: '', code: null },
  },
  sin: '',
  successfullyCreated: false,
};

const hisaSlice = createSlice({
  name: 'hisaOnboarding',
  initialState: initialState,
  reducers: {
    setCitizenship: (state, action) => {
      state.citizenship = action.payload;
    },
    setIndustry: (state, action: PayloadAction<EmploymentInputRecord>) => {
      state.employmentDetails.companyCategory = action.payload;
    },
    setRole: (state, action: PayloadAction<EmploymentInputRecord>) => {
      state.employmentDetails.employmentRole = action.payload;
    },
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.employmentDetails.companyName = action.payload;
    },
    setEmploymentStatus: (state, action: PayloadAction<EmploymentStatus>) => {
      state.employmentStatus = action.payload;
    },
    setSIN: (state, action: PayloadAction<string>) => {
      state.sin = action.payload.replace(/-/g, '').trim();
    },
    setSuccessfullyCreated: (state, action: PayloadAction<boolean>) => {
      state.successfullyCreated = action.payload;
    },
  },
});

export const hisaActions = hisaSlice.actions;
export default hisaSlice.reducer;

export const selectHisaOnboarding = (state: RootState) => state.hisaOnboarding;

export function transformatHisaOnboardingDataForApi(
  hisaUserData: HisaState,
): CreateHisaAccountRequest {
  const payload: CreateHisaAccountRequest = {
    citizenships: hisaUserData.citizenship
      ? [{ iso_code: hisaUserData.citizenship }]
      : [{ iso_code: 'CA' }],
    employment_status: hisaUserData.employmentStatus,
    sin: hisaUserData.sin,
  };

  if (
    hisaUserData.employmentDetails.companyCategory.code &&
    hisaUserData.employmentDetails.employmentRole.code
  ) {
    payload.employment_detail = {
      company_category: hisaUserData.employmentDetails.companyCategory.code,
      company_name: hisaUserData.employmentDetails.companyName,
      role: hisaUserData.employmentDetails.employmentRole.code,
    };
  }

  return payload;
}
