import { createSlice } from '@reduxjs/toolkit';
import { AutodepositAlias, autodepositApi } from 'apis/autodeposit';

export interface AliasState {
  data: AutodepositAlias[];
}

const initialState: AliasState = {
  data: [],
};

const aliasSlice = createSlice({
  name: 'aliases',
  initialState: initialState,
  reducers: {
    setAliases: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        autodepositApi.endpoints.getAutodepositAliases.matchFulfilled,
        (state, { payload }) => {
          state.data = payload;
        },
      )
      .addMatcher(
        autodepositApi.endpoints.registerAlias.matchFulfilled,
        (state, { payload }) => {
          state.data = payload;
        },
      )
      .addMatcher(
        autodepositApi.endpoints.unregisterAlias.matchFulfilled,
        (state) => {
          state.data = [];
        },
      );
  },
});

export default aliasSlice.reducer;
