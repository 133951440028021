import { ReactComponent as Credit } from './credit.svg';
import { ReactComponent as Grow } from './grow.svg';
import { ReactComponent as Saving } from './saving.svg';
import { ReactComponent as Spending } from './spending.svg';

export const Navigation = {
  Credit,
  Grow,
  Saving,
  Spending,
};
