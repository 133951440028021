import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Box, Chip, Typography, makeStyles } from '@material-ui/core';
import { navigate } from '@reach/router';
import { useGetTiersQuery } from 'apis/tiers';
import { KDSIcons } from 'assets/images/kds_icons';
import { Button, Widget } from 'components';
import DownloadApp from 'features/modals/DownloadApp';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectProfile, selectProfileVerified } from 'profile/store/selectors';
import { theme } from 'theme';
import { useUpgradeMetadata } from 'tiers/hooks/useUpgradeMetadata';
import { TiersFeatureFlags } from 'tiers/models/TiersFeatureFlags';
import { selectIsPaidTier } from 'tiers/store/selectors';
import { getTierManagementUrl, isTrialEligible } from 'tiers/utils/helpers';

const useAlertStyles = makeStyles({
  label: { paddingRight: 4 },
});

const FreeTrialAlert = () => {
  const intl = useIntl();
  const classes = useAlertStyles();
  return (
    <Box display="flex" flexDirection="row" sx={{ mb: 3 }}>
      <Chip
        sx={{
          overflow: 'visible',
          bg: theme.colors.paleNavy,
          color: theme.colors.blueDark,
          px: '10px',
          py: '8px',
        }}
        icon={
          <KDSIcons.Icons.Tiers
            color={theme.colors.blueDark}
            sx={{ width: '16px' }}
          />
        }
        label={
          <Typography
            component="span"
            sx={{ fontWeight: 700, fontSize: '12px', lineHeight: 1.5 }}
          >
            {intl.formatMessage({
              id: 'UpgradePlanWidget.FreeTrial',
              defaultMessage: 'Free 30-day Trial',
            })}
          </Typography>
        }
        classes={{ label: classes.label }}
      />
    </Box>
  );
};

export const UpgradePlanWidget = () => {
  const profile = useSelector(selectProfile);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const intendedUse = profile?.intended_use;
  const { ctaText, description, img, title } = useUpgradeMetadata(intendedUse);

  const { data: tiers } = useGetTiersQuery();
  const trialEligible = !!tiers && isTrialEligible(tiers);
  const userVerified = !!useSelector(selectProfileVerified);
  const isPaidTier = useSelector(selectIsPaidTier);
  const navigationUrl = getTierManagementUrl({
    userVerified,
    isPaidTier,
    trialEligible,
  });

  const isTiersSelectionEnabled =
    useFlags()[TiersFeatureFlags.EnableNonMemberTiersSelection];

  const handleOnClick = () => setShowDownloadModal(true);
  const handleOnClose = () => setShowDownloadModal(false);
  const navigateToTiersSelection = () => navigate(navigationUrl);

  return (
    <>
      <Widget
        alerts={
          isTiersSelectionEnabled && trialEligible ? <FreeTrialAlert /> : null
        }
        description={description}
        image={img}
        title={title}
        inverted
      >
        <Button
          aria-label={ctaText}
          data-cy="tiers-upgrade-plan-widget-cta"
          onClick={
            isTiersSelectionEnabled ? navigateToTiersSelection : handleOnClick
          }
          trackName="Tiers - Upgrade plan widget"
          variant="primaryInverted"
        >
          {ctaText}
        </Button>
      </Widget>
      {/* TODO: refactor to only have one global instance of DownloadApp */}
      {!isTiersSelectionEnabled && (
        <DownloadApp open={showDownloadModal} onClose={handleOnClose} />
      )}
    </>
  );
};

export default UpgradePlanWidget;
