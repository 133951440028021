/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import { KDSIcons } from 'assets/images/kds_icons';
import { Button, Link, Modal, Paragraph, TitleMedium } from 'components';

export const RegistrationDuplicateUserModal = ({
  isOpen,
  onModalClosed,
}: {
  isOpen: boolean;
  onModalClosed: () => void;
}) => {
  const intl = useIntl();

  return (
    <Modal
      open={isOpen}
      onClose={onModalClosed}
      PaperProps={{
        style: {
          borderRadius: '8px',
        },
      }}
    >
      <div sx={{ px: [1, 3] }}>
        <KDSIcons.Spot.Contacts />
        <TitleMedium sx={{ mt: 1 }}>
          {intl.formatMessage({
            id: 'RegistrationDuplicateUserModal.Title',
            defaultMessage: 'Have we met?',
          })}
        </TitleMedium>
        <Paragraph>
          {intl.formatMessage({
            id: 'RegistrationDuplicateUserModal.Description',
            defaultMessage:
              'Looks like you’ve signed-up for KOHO before. Please login to continue.',
          })}
        </Paragraph>
        <Link trackName="login" to="/login">
          <Button onClick={onModalClosed}>
            {intl.formatMessage({
              id: 'RegistrationDuplicateUserModal.Login',
              defaultMessage: 'Login',
            })}
          </Button>
        </Link>
      </div>
    </Modal>
  );
};
