import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUnixTime } from 'date-fns';
import { LDUser, useLDClient } from 'launchdarkly-react-client-sdk';

import { selectProfile } from '../../../profile/store/selectors';
import {
  selectLaunchDarklyAnonymousHash,
  selectLaunchDarklyAnonymousId,
  selectLaunchDarklyAnonymousIsSuccess,
  selectLaunchDarklyAuthenticatedHash,
  selectLaunchDarklyAuthenticatedIsSuccess,
  selectLaunchDarklyIsAuthenticated,
  selectLaunchDarklyUserRef,
} from '../store/selectors';
import { launchDarklyActions } from '../store/slice';

export const useLaunchDarkly = () => {
  const ldClient = useLDClient();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(selectLaunchDarklyIsAuthenticated);

  const isLdAnonymousSuccess = useSelector(
    selectLaunchDarklyAnonymousIsSuccess,
  );
  const ldAnonymousId = useSelector(selectLaunchDarklyAnonymousId);
  const ldAnonymousHash = useSelector(selectLaunchDarklyAnonymousHash);

  const isLdAuthenticatedSuccess = useSelector(
    selectLaunchDarklyAuthenticatedIsSuccess,
  );
  const ldAuthenticatedHash = useSelector(selectLaunchDarklyAuthenticatedHash);
  const userRef = useSelector(selectLaunchDarklyUserRef);
  const profile = useSelector(selectProfile);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(launchDarklyActions.getAnonymousUserHashRequest());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && userRef) {
      dispatch(launchDarklyActions.getUserHashRequest());
    }
  }, [dispatch, isAuthenticated, userRef]);

  useEffect(() => {
    if (ldClient && isLdAnonymousSuccess && !isAuthenticated) {
      ldClient
        .identify(
          {
            key: ldAnonymousId,
            anonymous: true,
          },
          ldAnonymousHash,
        )
        .then(() => {
          dispatch(launchDarklyActions.getFlagsSuccess());
        })
        .catch(() => {
          dispatch(launchDarklyActions.getFlagsError());
        });
    }
  }, [
    dispatch,
    ldClient,
    isLdAnonymousSuccess,
    ldAnonymousHash,
    ldAnonymousId,
    isAuthenticated,
  ]);

  useEffect(() => {
    if (ldClient && isLdAuthenticatedSuccess && isAuthenticated) {
      // though LDUser is deprecated, it is still used the 3.0 SDK as an option instead of LDContext
      let userContext: LDUser = { key: userRef };

      if (profile) {
        let attributes: Record<string, string | number> = {};

        const createdOn = new Date(profile.created_on);

        // if profile.created_on is undefined, isoCreatedTime will be an invalid Date
        if (!isNaN(createdOn.getTime())) {
          // we use UNIX time so that LaunchDarkly can segment using numeric comparisons
          const unixTime = getUnixTime(createdOn);
          // we use "RegisteredAt" as the key to match existing context data in LD
          attributes['RegisteredAt'] = unixTime;
        }

        const newUser = profile.new_user;
        if (newUser) {
          attributes['NewUser'] = 1;
        }

        userContext.custom = {
          ...attributes,
        };
      }

      ldClient
        .identify(userContext, ldAuthenticatedHash)
        .then(() => {
          dispatch(launchDarklyActions.getFlagsSuccess());
        })
        .catch(() => {
          dispatch(launchDarklyActions.getFlagsError());
        });
    }
  }, [
    dispatch,
    ldClient,
    ldAuthenticatedHash,
    isLdAuthenticatedSuccess,
    userRef,
    profile,
    isAuthenticated,
  ]);

  return null;
};
