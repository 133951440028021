import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import * as braze from '@braze/web-sdk';
import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useNavigate } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';
import { LOCALE_EN, LocaleContext, TitleSmall } from 'components';
import ButtonUnstyled from 'components/ButtonUnstyledTw';
import {
  KhChip,
  KhChipDecorationType,
  KhChipTheme,
  KhChipType,
} from 'components/kds/KhChip';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import { theme } from 'theme';

import { selectHomeCards } from './store/selectors';

export const BrazeHomeCards = () => {
  const intl = useIntl();
  const [mySwiper, setMySwiper] = React.useState<any>();
  const homeCards = useSelector(selectHomeCards);
  const [isViewingFirstCard, setIsViewingFirstCard] = React.useState(true);
  const [isViewingLastCard, setIsViewingLastCard] = React.useState(false);

  const useStyles = makeStyles(() => ({
    bullet: {
      '& .swiper-pagination-bullet': {
        background: theme.colors.tertiary,
        opacity: 0.2,
      },
      '& .swiper-pagination-bullet-active': {
        opacity: 1,
      },

      '.swiper-slide': {
        width: 'auto',
      },
    },
  }));

  const swiperStyles = useStyles();

  const navigate = useNavigate();
  const localeContext = useContext(LocaleContext);

  const onCardClick = (card: braze.ClassicCard) => {
    braze.logCardClick(card);

    if (isValidExternalUrl(card.extras.web_link)) {
      const url = isKohoCaLink(card.extras.web_link)
        ? getKohoCaLink(card.extras.web_link, localeContext.locale)
        : card.extras.web_link;

      const win = window.open(url, '_blank');
      if (win != null) {
        win.focus();
      }
    } else {
      navigate(card.extras.web_link);
    }
  };

  const onSlideChanged = (index: number) => {
    if (!!homeCards) {
      braze.logCardImpressions([homeCards[index]]);
    }
  };

  return (
    <Box>
      {!homeCards && (
        <Skeleton
          className={`rounded-lg aspect-[344/167] w-full h-full`}
        ></Skeleton>
      )}
      {!!homeCards && homeCards.length > 0 && (
        <Box>
          <Box className="flex gap-2 mb-3">
            <ButtonUnstyled
              disabled={isViewingFirstCard}
              className={twMerge(
                `rounded-full flex items-center bg-tertiary-75 w-[44px] h-[44px]`,
                isViewingFirstCard ? 'opacity-50' : '',
              )}
              onClick={() => mySwiper?.slidePrev()}
              aria-label={intl.formatMessage({
                id: 'BrazeHomeCards.Prev',
                defaultMessage: 'Previous',
              })}
            >
              <KDSIcons.Icons.ChevronLeft className={`m-auto h-4 w-4`} />
            </ButtonUnstyled>
            <ButtonUnstyled
              disabled={isViewingLastCard}
              className={twMerge(
                `rounded-full flex items-center bg-tertiary-75 w-[44px] h-[44px]`,
                isViewingLastCard ? 'opacity-50' : '',
              )}
              onClick={() => mySwiper?.slideNext()}
              aria-label={intl.formatMessage({
                id: 'BrazeHomeCards.Next',
                defaultMessage: 'Next',
              })}
            >
              <KDSIcons.Icons.ChevronLeft
                className={`rotate-180 m-auto h-4 w-4`}
              />
            </ButtonUnstyled>
          </Box>
          <Swiper
            modules={[Pagination]}
            spaceBetween={10}
            slidesPerView={'auto'}
            pagination={true}
            onInit={(swiper) => {
              setMySwiper(swiper);
              braze.logCardImpressions([homeCards[0]]);
            }}
            onActiveIndexChange={(swiper) => {
              setIsViewingFirstCard(swiper.isBeginning);
              setIsViewingLastCard(swiper.isEnd);
              onSlideChanged(swiper.activeIndex);
            }}
            className={swiperStyles.bullet}
          >
            {homeCards?.map((card: braze.ClassicCard, index: number) => {
              return (
                <SwiperSlide key={index} className="w-full max-w-[360px]">
                  <ButtonUnstyled
                    className={`bg-cover bg-no-repeat rounded-lg p-4 flex flex-col justify-between aspect-[344/167] mb-9 w-full h-full max-w-[360px]`}
                    sx={{ backgroundImage: `url(${card.imageUrl})` }}
                    trackName={card.title}
                    onClick={() => onCardClick(card)}
                  >
                    <KhChip
                      type={(card.extras.chip_type as KhChipType) || 'dark'}
                      chipTheme={
                        (card.extras.chip_theme as KhChipTheme) || 'primary'
                      }
                      decoration={
                        card.extras.chip_decoration as KhChipDecorationType
                      }
                      className="flex-grow-0"
                    >
                      {card.extras.chip_text ||
                        intl.formatMessage({
                          id: 'BrazeHomeCards.Chip.LearnMore',
                          defaultMessage: 'Learn More',
                        })}
                    </KhChip>
                    <TitleSmall
                      sx={{
                        color: card.extras.text_color || theme.colors.white,
                        textAlign: 'left',
                        fontSize: '18px',
                        m: 0,
                        width: '70%',
                      }}
                    >
                      {card.title}
                    </TitleSmall>
                  </ButtonUnstyled>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      )}
    </Box>
  );
};

const isValidExternalUrl = (page: string): boolean => {
  try {
    const url = new URL(page);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (err) {
    return false;
  }
};

const isKohoCaLink = (page: string): boolean => {
  const { hostname } = new URL(page);

  return hostname === 'www.koho.ca' || hostname === 'koho.ca';
};

const getKohoCaLink = (page: string, currentLang: string): string => {
  if (currentLang === LOCALE_EN) {
    return page;
  }

  const { pathname } = new URL(page);

  return `https://www.koho.ca/${currentLang}${pathname}`;
};
