import { useIntl } from 'react-intl';

import { Typography } from '@material-ui/core';
import { KDSIcons } from 'assets/images/kds_icons';
import { Button } from 'components';
import { DrawerModal } from 'components/DrawerModal';
import { theme } from 'theme';

const QuestionIcon = () => (
  <KDSIcons.Icons.Question
    color={theme.colors.tertiary}
    sx={{ ml: 1, mb: '2px' }}
  />
);

export const PaymentMethodsInfoModal = () => {
  const intl = useIntl();

  return (
    <DrawerModal
      content={({ closeModal }) => (
        <>
          <KDSIcons.Spot.Activity width={64} />

          <Typography
            sx={{
              mb: 2,
              mt: 0,
              fontWeight: 900,
              fontSize: 32,
              color: theme.colors.text,
            }}
          >
            {intl.formatMessage({
              id: 'PaymentMethodsInfo.Title',
              defaultMessage: 'Payment methods',
            })}
          </Typography>
          <Typography
            component="span"
            sx={{
              display: 'block',
              color: theme.colors.text,
              '& ul': { my: 0 },
              mb: 4,
            }}
          >
            {intl.formatMessage({
              id: 'PaymentMethodsInfo.Content',
              defaultMessage:
                "We'll always use the money in your KOHO account first to pay for your KOHO features like your plan, or credit building. To keep your benefits seamless if your KOHO account balance runs low, we'll try the other payment methods you've added to your account as a backup.",
            })}
          </Typography>
          <Typography
            component="span"
            sx={{
              display: 'block',
              color: theme.colors.text,
              mb: 4,
              fontWeight: 500,
              fontSize: '12px',
            }}
          >
            {intl.formatMessage({
              id: 'PaymentMethodsInfo.ExtraInfo',
              defaultMessage:
                'By adding a payment method, you authorize KOHO to charge your payment method when your KOHO Account balance runs low. You can change your payment method at any time.',
            })}
          </Typography>
          {}
          <Button
            sx={{
              p: '11px',
            }}
            onClick={closeModal}
          >
            {intl.formatMessage({
              id: 'PaymentMethodsInfo.Cta',
              defaultMessage: 'Close',
            })}
          </Button>
        </>
      )}
    >
      <QuestionIcon />
    </DrawerModal>
  );
};
