import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { navigate } from '@reach/router';
import { useGetCardsQuery } from 'apis/cards';
import {
  personalizedOnboardingChecklistItem,
  useGetPersonalizedOnboardingQuery,
  useUpdatePersonalizedOnboardingItemMutation,
} from 'apis/personalizedOnboardingApi';
import { KDSIcons } from 'assets/images/kds_icons';
import { AccountCard } from 'cards/models';
import {
  Box,
  ButtonUnstyled,
  Paragraph,
  ParagraphSmall,
  Template,
  TitleLarge,
  TitleMedium,
} from 'components';
import DownloadApp from 'features/modals/DownloadApp';
import { selectProfileVerified } from 'profile/store/selectors';
import { theme } from 'theme';

import kohoPurse from '../../assets/images/koho_purse.png';
import virtualCardMC from '../../assets/images/virtual-card-mc.png';
import { CardStatusIndicator } from '../components/CardStatusIndicator';

export const MyCardsPage = (): React.ReactNode => {
  const intl = useIntl();
  const [updatePersonalizedOnboardingItem] =
    useUpdatePersonalizedOnboardingItemMutation();

  const { data: cards } = useGetCardsQuery();
  const userIsVerified = useSelector(selectProfileVerified);
  const { data: personalizedOnboarding } = useGetPersonalizedOnboardingQuery();
  const hasNotSeenCards =
    personalizedOnboarding?.onboarding_page.checklist.some(
      (item) =>
        item.key === personalizedOnboardingChecklistItem.HasViewedCards &&
        !item.completed,
    );

  const virtualCard = cards?.find((card) => card.is_virtual);
  const physicalCard = cards?.find((card) => !card.is_virtual);

  const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (hasNotSeenCards) {
      updatePersonalizedOnboardingItem(
        personalizedOnboardingChecklistItem.HasViewedCards,
      );
    }
  }, [updatePersonalizedOnboardingItem, hasNotSeenCards]);

  return (
    <Template name="My Cards" variant="center">
      <TitleLarge sx={{ mb: 5 }}>
        {intl.formatMessage({
          id: 'MyCards.Title',
          defaultMessage: 'My Cards',
        })}
      </TitleLarge>

      {physicalCard && <CardLink card={physicalCard} />}
      {virtualCard && <CardLink card={virtualCard} />}

      {!!cards?.length && !physicalCard && (
        <ButtonUnstyled
          trackName="MyCards OrderPhysicalCard"
          onClick={() => {
            let dest = '/cards/physical';

            if (userIsVerified === false) {
              dest = '/kyc/entry';
            }

            navigate(dest);
          }}
        >
          <PhysicalCardBanner />
        </ButtonUnstyled>
      )}

      <DownloadApp
        onClose={() => setDownloadModalOpen(false)}
        open={downloadModalOpen}
      />
    </Template>
  );
};

function CardLink({ card }: { card: AccountCard }) {
  const intl = useIntl();

  const cardAlt = intl.formatMessage({
    id: 'MyCards.CardLink.CardAlt',
    defaultMessage: 'Your KOHO card',
  });

  const showCardNumber =
    card.card_status === 'active' ||
    card.card_status === 'locked' ||
    card.card_status === 'cancelled';

  const clickable =
    showCardNumber ||
    card.card_status === 'waiting for payment' ||
    card.card_status === 'shipped';

  const CardItem = ({ children }) => {
    if (clickable) {
      return (
        <ButtonUnstyled onClick={() => navigate('/cards/' + card.card_id)}>
          {children}
        </ButtonUnstyled>
      );
    }

    return <Box>{children}</Box>;
  };

  return (
    <CardItem>
      <Box
        variant="shadow"
        sx={{
          p: 3,
          mb: 5,
          display: 'flex',
          alignItems: 'center',
        }}
        key={card.card_id}
      >
        <img
          alt={cardAlt}
          src={card.is_virtual ? virtualCardMC : card.image_url}
          width={80}
          style={{
            display: 'inline-block',
            borderRadius: '4px',
            marginRight: '20px',
          }}
        />
        <Box sx={{ display: 'inline-block' }}>
          <TitleMedium sx={{ py: 0, mt: 0, mb: 1 }}>
            {card.is_virtual
              ? intl.formatMessage({
                  id: 'MyCards.CardLink.VirtualCard',
                  defaultMessage: 'Virtual card',
                })
              : intl.formatMessage({
                  id: 'MyCards.CardLink.PhysicalCard',
                  defaultMessage: 'Physical card',
                })}
          </TitleMedium>
          <Paragraph sx={{ color: theme.colors.midGrey, py: 0, my: 0 }}>
            {showCardNumber && <>••••&nbsp;{card.last_four_digits}&nbsp;</>}

            {card?.is_locked && (
              <span style={{ color: theme.colors.primary }}>Locked&nbsp;</span>
            )}

            <CardStatusIndicator card={card} />
          </Paragraph>
        </Box>
        {clickable && (
          <Box sx={{ flex: 1, textAlign: 'right' }}>
            <KDSIcons.Icons.Drilldown />
          </Box>
        )}
      </Box>
    </CardItem>
  );
}

function PhysicalCardBanner() {
  const intl = useIntl();

  return (
    <Box>
      <Box
        sx={{
          p: 3,
          display: 'flex',
          alignItems: 'center',
          borderRadius: '8px 8px 0 0',
          backgroundColor: theme.colors.primary50,
        }}
      >
        <Box sx={{ display: 'inline-block', mr: 5 }}>
          <TitleMedium sx={{ py: 0, mt: 0, mb: 1 }}>
            {intl.formatMessage({
              id: 'MyCards.PhysicalCardBanner.Title',
              defaultMessage: 'Get your physical KOHO card',
            })}
          </TitleMedium>
        </Box>
        <img
          alt=""
          src={kohoPurse}
          width={80}
          style={{
            display: 'inline-block',
            borderRadius: '4px',
          }}
        />
      </Box>
      <Box
        sx={{
          p: 3,
          mb: 5,
          borderRadius: ' 0 0 8px 8px',
          backgroundColor: theme.colors.blueNavy,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <ParagraphSmall sx={{ py: 0, my: 0, color: 'white' }}>
          {intl.formatMessage({
            id: 'MyCards.PhysicalCardBanner.Description',
            defaultMessage: 'Receive a free KOHO card in the mail in 14 days.',
          })}
        </ParagraphSmall>
        <Box
          sx={{
            ml: 3,
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            color: theme.colors.white,
            fontWeight: 700,
          }}
        >
          {intl.formatMessage({
            id: 'MyCards.PhysicalCardBanner.OrderCard',
            defaultMessage: 'Order card',
          })}
          <KDSIcons.Icons.ArrowRight
            sx={{ color: theme.colors.white, ml: 2 }}
          />
        </Box>
      </Box>
    </Box>
  );
}
