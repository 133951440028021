import { SnackbarProps } from '@material-ui/core/Snackbar/Snackbar';
import { AlertProps } from '@material-ui/lab/Alert/Alert';
import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface SnackbarNotificationState {
  open?: boolean;
  message?: string;
  autoHideDuration?: number;
  anchorOrigin?: SnackbarProps['anchorOrigin'];
  type?: AlertProps['severity'];
}

const initialState: SnackbarNotificationState = {
  open: false,
};

export type SnackbarOptions = Omit<
  SnackbarNotificationState,
  'open' | 'message'
>;

const snackbarSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    show: (_, { payload }: PayloadAction<SnackbarNotificationState>) => ({
      open: true,
      message: payload.message,
      autoHideDuration: payload.autoHideDuration || 5000,
      anchorOrigin: payload.anchorOrigin || {
        horizontal: 'right',
        vertical: 'top',
      },
      type: payload.type,
    }),
    clear: (_) => ({
      open: false,
    }),
  },
});

export const snackbarActions = snackbarSlice.actions;
export const showSnackbar = (
  message: SnackbarNotificationState['message'],
  options?: SnackbarOptions,
) => {
  return (dispatch: Dispatch): void => {
    dispatch(snackbarActions.show({ message, ...options }));
  };
};

export const clearSnackbar = () => {
  return (dispatch: Dispatch): void => {
    dispatch(snackbarActions.clear());
  };
};

export default snackbarSlice.reducer;
