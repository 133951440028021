import { useIntl } from 'react-intl';

import { Dialog } from '@material-ui/core';
import { ReferralReward } from 'apis/referralApi';
import { KDSIcons } from 'assets/images/kds_icons';
import headerImg from 'assets/images/referAndEarn/referral-info-extra-header.jpg';
import { Box, Button, Paragraph, TitleLarge } from 'components';

export const RewardItemInfoModal = ({
  open,
  onClose,
  canRedeem,
  onRedeemClicked,
  reward,
}: {
  open: boolean;
  onClose: () => void;
  canRedeem: boolean;
  onRedeemClicked: (rewardId: string) => void;
  reward: ReferralReward | null;
}) => {
  const intl = useIntl();

  return (
    <Dialog
      open={reward !== null}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '8px',
        },
      }}
    >
      <img src={headerImg} alt="" sx={{ position: 'relative' }} />
      <button
        onClick={onClose}
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: '20px',
          right: '24px',
          background: 'transparent',
          border: 'none',
        }}
        aria-label={intl.formatMessage({
          id: 'ReferAndEarn.RewardItemInfoModal.CloseButtonLabel',
          defaultMessage: 'Close dialog',
        })}
      >
        <KDSIcons.Icons.Cross sx={{ color: 'white' }} />
      </button>
      <Box sx={{ padding: '24px' }}>
        <TitleLarge sx={{ textAlign: 'center' }}>
          {reward?.reward_header}
        </TitleLarge>
        <Paragraph sx={{ textAlign: 'center' }}>
          {reward?.reward_sub_header}
        </Paragraph>
        <Button
          onClick={() => onRedeemClicked(reward?.id || '')}
          disabled={!canRedeem}
        >
          {!canRedeem && (
            <KDSIcons.Icons.LockDetailed
              width={16}
              height={16}
              sx={{ color: 'white', marginRight: '10px', marginBottom: '-2px' }}
            />
          )}
          {canRedeem
            ? intl.formatMessage({
                defaultMessage: 'Redeem',
                id: 'ReferAndEarn.RewardItemInfoModal.ButtonEnabled',
              })
            : intl.formatMessage({
                defaultMessage: 'Refer to redeem',
                id: 'ReferAndEarn.RewardItemInfoModal.ButtonDisabled',
              })}
        </Button>
      </Box>
    </Dialog>
  );
};
