/** @jsxImportSource theme-ui */
import { defineMessage, useIntl } from 'react-intl';

import { Box, Dialog, DialogContent } from '@material-ui/core';
import { Button, Paragraph, TitleSmall } from 'components';
import { InboundETransferErrors } from 'inboundETransfer/store/slice';

export const InboundETransferErrorHeadings = {
  [InboundETransferErrors.Generic]: defineMessage({
    id: 'InboundETransferPage.ErrorHeading.Generic',
    defaultMessage: 'Oops',
  }),
  [InboundETransferErrors.SubmissionIncorrectAnswer]: defineMessage({
    id: 'InboundETransferPage.ErrorHeading.IncorrectAnswer',
    defaultMessage: 'Answer incorrect',
  }),
  [InboundETransferErrors.SubmissionTransferCanceled]: defineMessage({
    id: 'InboundETransferPage.ErrorHeading.TransferCanceled',
    defaultMessage: 'Transfer cancelled',
  }),
};

export const mapErrorsToHeading = (errorType: InboundETransferErrors) => {
  return (
    InboundETransferErrorHeadings[errorType] ??
    InboundETransferErrorHeadings[InboundETransferErrors.Generic]
  );
};

export const InboundETransferErrorMessages = {
  [InboundETransferErrors.Generic]: defineMessage({
    id: 'InboundETransfer.ErrorMessage.Generic',
    defaultMessage: 'Something went wrong, would you like to retry?',
  }),
  [InboundETransferErrors.ServiceTemporarilyUnavailable]: defineMessage({
    id: 'InboundETransfer.ErrorMessage.ServiceUnavailable',
    defaultMessage:
      'Looks like this service is temporarily unavailable. Please try again later.',
  }),
  [InboundETransferErrors.RetrieveBadTransferId]: defineMessage({
    id: 'InboundETransfer.ErrorMessage.BadTransfer',
    defaultMessage:
      'Something went wrong, please try again starting from the e-Transfer link in your email.',
  }),
  [InboundETransferErrors.RetrieveKycNotCleared]: defineMessage({
    id: 'InboundETransfer.ErrorMessage.KycNotCleared',
    defaultMessage: 'Verify your account to deposit your e-Transfer.',
  }),
  [InboundETransferErrors.RetrieveTransferUnavailable]: defineMessage({
    id: 'InboundETransfer.ErrorMessage.TransferUnavailable',
    defaultMessage: 'The e-Transfer is no longer available.',
  }),
  [InboundETransferErrors.SubmissionIncorrectAnswer]: defineMessage({
    id: 'InboundETransferPage.ErrorMessage.IncorrectAnswer',
    defaultMessage:
      'Be careful! After too many failed attempts, this transfer will be automatically cancelled.',
  }),
  [InboundETransferErrors.SubmissionTransferCanceled]: defineMessage({
    id: 'InboundETransferPage.ErrorMessage.TransferCanceled',
    defaultMessage:
      'The security answer was entered incorrectly too many times. For your security, Interac has cancelled the transaction.',
  }),
};

export const mapErrorsToMessage = (errorType: InboundETransferErrors) => {
  return (
    InboundETransferErrorMessages[errorType] ??
    InboundETransferErrorMessages[InboundETransferErrors.Generic]
  );
};

export interface ErrorAlertProps {
  isOpen: boolean;
  errorType: InboundETransferErrors;
  handleClose: () => void;
  handleRetry: () => void;
}

export const ErrorDialog: React.FC<ErrorAlertProps> = ({
  isOpen,
  errorType,
  handleClose,
  handleRetry,
}) => {
  const intl = useIntl();

  const showRetry = errorType === InboundETransferErrors.Generic;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="iet-error-alert-title"
      aria-describedby="iet-error-alert-description"
      data-cy="iet-error-alert"
    >
      <DialogContent>
        <div id="iet-error-alert-title">
          <TitleSmall sx={{ mb: 3, mt: 0 }}>
            {intl.formatMessage(mapErrorsToHeading(errorType))}
          </TitleSmall>
        </div>
        <Paragraph sx={{ mt: 0 }} id="iet-error-alert-description">
          <span data-cy="iet-error-message">
            {intl.formatMessage(mapErrorsToMessage(errorType))}
          </span>
        </Paragraph>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: showRetry ? '1fr 1fr' : '1fr',
            gridColumnGap: '2',
          }}
        >
          <Button
            onClick={handleClose}
            sx={{ mb: 2 }}
            data-cy="iet-error-alert-cta-ok"
            variant={showRetry ? 'secondary' : 'primary'}
          >
            {intl.formatMessage({
              id: 'InboundETransferPage.ErrorAlert.OkCta',
              defaultMessage: `Ok`,
            })}
          </Button>
          {showRetry && (
            <Button
              onClick={handleRetry}
              sx={{ mb: 2 }}
              data-cy="iet-error-alert-cta-retry"
            >
              {intl.formatMessage({
                id: 'InboundETransferPage.ErrorAlert.RetryCta',
                defaultMessage: `Retry`,
              })}
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
