import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  Loadable,
  createDefaultLoadable,
  createErrorLoadable,
  createLoadingLoadable,
  createSuccessLoadable,
} from '../../utility/loadable';
import { AccountsAllAccount } from '../models';

export interface AccountsState {
  accountsAll: {
    accounts: AccountsAllAccount[] | null;
    loadable: Loadable;
  };
  postAccount: {
    loadable: Loadable;
  };
}

const initialState: AccountsState = {
  accountsAll: {
    accounts: null,
    loadable: createDefaultLoadable(),
  },
  postAccount: {
    loadable: createDefaultLoadable(),
  },
};

const getAccountsAll = {
  request: (state) => {
    state.accountsAll.loadable = createLoadingLoadable();
  },
  response: (state, action: PayloadAction<any>) => {
    state.accountsAll.accounts = action.payload.accounts;
    state.accountsAll.loadable = createSuccessLoadable();
  },
  error: (state, action: PayloadAction<any>) => {
    state.accountsAll.loadable = createErrorLoadable(
      action.payload || 'unable to get accounts',
    );
  },
  reset: (state) => {
    state.accountsAll.loadable = createDefaultLoadable();
  },
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: initialState,
  reducers: {
    getAccountsAllRequest: getAccountsAll.request,
    getAccountsAllResponse: getAccountsAll.response,
    getAccountsAllError: getAccountsAll.error,
    getAccountsAllReset: getAccountsAll.reset,
    getAccountsAllAtLoginRequest: getAccountsAll.request,
    getAccountsAllAtLoginResponse: getAccountsAll.response,
    getAccountsAllAtLoginError: getAccountsAll.error,
    resetPostAccount: (state) => {
      state.postAccount = {
        ...state.postAccount,
        loadable: createDefaultLoadable(),
      };
    },
    noop: (state) => state,
  },
});

export const accountsActions = accountsSlice.actions;
export default accountsSlice.reducer;
