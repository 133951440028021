import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum Language {
  EN = 'en',
  FR = 'fr',
}

export type THEMES = 'legacy' | 'light' | 'dark';

interface ThemeState {
  name: THEMES;
}

export interface AppState {
  currentLanguage: Language;
  theme: ThemeState;
}

const initialState: AppState = {
  currentLanguage: Language.EN,
  theme: {
    name: 'legacy',
  },
};

const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    languageChanged: (state, action) => {
      state.currentLanguage = action.payload;
    },
    setTheme: (state, action: PayloadAction<THEMES>) => {
      state.theme.name = action.payload;
    },
  },
});

export const appStateActions = appStateSlice.actions;
export const appStateReducer = appStateSlice.reducer;

export const selectTheme = (state) => state.appState.theme.name;

export default appStateReducer;
