import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const contactState = (state: RootState) => state.contact;

export const contactSelectors = {
  contactModalOpenSelector: createSelector(
    contactState,
    (state) => state?.modalOpen,
  ),
};
