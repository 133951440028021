import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Loadable, createDefaultLoadable } from '../../utility/loadable';
import { Transaction } from '../models';

export interface TransactionsState {
  data: Transaction[] | null;
  loadable: Loadable;
}

const initialState: TransactionsState = {
  data: null,
  loadable: createDefaultLoadable(),
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: initialState,
  reducers: {
    getTransactionsRequest: (state) => {
      state.loadable = {
        loading: true,
        success: false,
        error: null,
      };
    },
    getTransactionsResponse: (state, action: PayloadAction<Transaction[]>) => {
      state.loadable = {
        loading: false,
        success: true,
        error: null,
      };
      state.data = action.payload;
    },
    getTransactionsError: (state) => {
      state.loadable = {
        loading: false,
        success: false,
        error: 'Failed to get transactions',
      };
    },
    noop: (state) => state,
  },
});

export const transactionsActions = transactionsSlice.actions;
export default transactionsSlice.reducer;
