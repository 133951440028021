/** @jsxImportSource theme-ui */
import React, { useState } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { defineMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { track } from 'analytics/analytics';
import { useLogoutMutation } from 'apis/auth';
import { ReactComponent as CheckMarkSuccess } from 'assets/images/checkmark-success.svg';
import { selectAuthIsAuthenticated } from 'auth/store/selectors';
import { ErrorParagraph, Paragraph } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { LinkTw } from 'components/LinkTw';
import { TitleLarge } from 'components/TypographyTw';
import { useValidationMsg } from 'components/forms/useValidationMsg';
import { KhInputEmailTw } from 'components/inputs/KhInputEmailTw';
import { KhInputPasswordTw } from 'components/inputs/KhInputPasswordTw';
import { PasswordValidationRules } from 'components/inputs/PasswordValidationRules';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PasswordRequirement } from 'models';
import { RegistrationIdentity } from 'registration/models/RegistrationIdentity';
import { Autoplay } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  useTrackElementViewedOnce,
  useTrackPageViewedOnceDeprecated,
} from 'utility/analyticsHooks';
import { validatePasswordComplexity } from 'utility/validatePassword';

import { RegistrationFeatureFlags } from '../models/RegistrationFeatureFlags';

const carouselValues = [
  defineMessage({
    id: 'Registration.IdentityPage.CarouselValues.Cashback',
    defaultMessage: 'earning cash back',
  }),
  defineMessage({
    id: 'Registration.IdentityPage.CarouselValues.Credit',
    defaultMessage: 'building credit history',
  }),
  defineMessage({
    id: 'Registration.IdentityPage.CarouselValues.Interest',
    defaultMessage: `earning up to {interestRate}% interest`,
  }),
  defineMessage({
    id: 'Registration.IdentityPage.CarouselValues.Fees',
    defaultMessage: 'not paying fees',
  }),
];

export const RegistrationIdentityPage = ({
  submitForm,
  identityForm,
  apiErrorMsg,
  referralCode,
  referrerName,
  loading,
}: {
  submitForm: SubmitHandler<RegistrationIdentity>;
  identityForm: UseFormReturn<RegistrationIdentity, any>;
  apiErrorMsg: string;
  referralCode?: string;
  referrerName?: string;
  loading: boolean;
}) => {
  useTrackPageViewedOnceDeprecated({
    name: 'registration-identity',
    overrideUrl: `registration/identity`,
  });
  const carouselCaptionArgs = [
    undefined,
    undefined,
    {
      interestRate: useFlags()[RegistrationFeatureFlags.InterestRate],
    },
    undefined,
  ];

  const intl = useIntl();

  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const [isInit, setIsInit] = useState(true);
  const [logout] = useLogoutMutation();

  if (isInit) {
    if (isAuthenticated) {
      logout({ navigateToLogin: false, deleteSession: true });
    }

    setIsInit(false);
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getFieldState,
  } = identityForm;

  const [isPasswordTouched, setIsPasswordTouched] = React.useState(false);
  const [passwordRequirements, setPasswordRequirements] =
    React.useState<PasswordRequirement>({
      hasMinLength: false,
      hasUpperCase: false,
      hasLowerCase: false,
    });
  const [passwordChangeTracked, setPasswordChangeTracked] =
    React.useState(false);
  const [passwordValidTracked, setPasswordValidTracked] = React.useState(false);

  const onPasswordChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPasswordRequirements(validatePasswordComplexity(value));

    // Only track change event once per component instance
    if (!passwordChangeTracked) {
      track({ event: 'Registration Identity Page Password Value Updated' });
      setPasswordChangeTracked(true);
    }

    const passState = getFieldState('new-password');

    // Only track valid true once per component instance
    if (!passwordValidTracked && !passState?.error) {
      track({ event: 'Registration Identity Page Password Valid' });
      setPasswordValidTracked(true);
    }
  };

  useTrackElementViewedOnce({
    element: errors.email,
    name: 'identity-email-input-error',
  });

  return (
    <>
      <TitleLarge className="mb-0 text-2xl md:text-3xl">
        {intl.formatMessage({
          id: 'Registration.IdentityPage.Title',
          defaultMessage: 'Join over 1 million Canadians',
        })}
      </TitleLarge>
      <Swiper
        autoHeight={true}
        autoplay={{ delay: 3000 }}
        speed={500}
        loop
        direction="vertical"
        modules={[Autoplay]}
        className="mb-8 light:mb-6 !h-full"
      >
        {carouselValues.map((val, i) => (
          <SwiperSlide key={i} className="!h-full">
            <TitleLarge className="legacy:bg-clip-text legacy:bg-gradient-to-br legacy:from-primary-100 legacy:to-primary-300 my-0 text-2xl md:text-3xl legacy:text-transparent">
              {intl.formatMessage(
                val,
                carouselCaptionArgs[i] && { ...carouselCaptionArgs[i] },
              )}
            </TitleLarge>
          </SwiperSlide>
        ))}
      </Swiper>

      {referralCode && (
        <Box
          sx={{
            mb: 5,
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <CheckMarkSuccess className="h-6 mr-2 w-6" />
          <Paragraph>
            {referrerName
              ? intl.formatMessage(
                  {
                    id: 'Registration.IdentityPage.ReferralApplied',
                    defaultMessage:
                      "{name}'s referral code {referralCodeValue} was applied!",
                  },
                  {
                    referralCodeValue: referralCode,
                    name: referrerName,
                  },
                )
              : intl.formatMessage(
                  {
                    id: 'Registration.IdentityPage.PartnerReferralApplied',
                    defaultMessage:
                      'Referral code {referralCodeValue} was applied!',
                  },
                  {
                    referralCodeValue: referralCode,
                  },
                )}
          </Paragraph>
        </Box>
      )}
      <form>
        <div className="mb-6">
          <KhInputEmailTw
            {...register('email')}
            label={intl.formatMessage({
              id: 'RegistrationIdentityPage.KhInputEmail.Label',
              defaultMessage: 'Email address',
            })}
            trackName="identity-email"
            error={!!errors.email}
            helperText={useValidationMsg(errors.email?.message)}
            data-cy="identity-email-input"
            id="email"
            autoComplete="username"
            onEnterPressed={() => identityForm.setFocus('new-password')}
          />
        </div>

        <div className="mb-6">
          <KhInputPasswordTw
            {...register('new-password', {
              onChange: (e) => {
                onPasswordChange(e);
              },
            })}
            label={intl.formatMessage({
              id: 'RegistrationIdentityPage.KhInputPassword.Label',
              defaultMessage: 'Password',
            })}
            trackName="identity-password"
            error={!!errors['new-password']}
            onFocus={() => setIsPasswordTouched(true)}
            onEnterPressed={handleSubmit(submitForm)}
            data-cy="identity-password-input"
            id="new-password"
            autoComplete="new-password"
          />
          {isPasswordTouched && (
            <PasswordValidationRules
              passwordRequirements={passwordRequirements}
              className="mt-2"
              data-cy="identity-password-rules"
            />
          )}
        </div>
        <ErrorParagraph>{apiErrorMsg}</ErrorParagraph>
        <ButtonTw
          className="mb-8"
          data-cy="identity-cta-button"
          disabled={!isValid || loading}
          loading={loading}
          onClick={handleSubmit(submitForm)}
          trackName="move-identity"
          type="button"
        >
          {intl.formatMessage({
            id: 'Registration.IdentityPage.CTA',
            defaultMessage: 'Next',
          })}
        </ButtonTw>
        <div className="text-center">
          <LinkTw to="/login" trackName="login">
            {intl.formatMessage({
              id: 'Registration.IdentityPage.Login',
              defaultMessage: 'Have an account? Log in',
            })}
          </LinkTw>
        </div>
      </form>
    </>
  );
};
