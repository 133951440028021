import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button as MuiButton, makeStyles } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { AddFundsShadowBox } from 'addFunds/components/AddFundsShadowBox';
import { AddFundsFeatureFlags } from 'addFunds/feature-flags';
import { track } from 'analytics/analytics';
import { KDSIcons } from 'assets/images/kds_icons';
import {
  Box,
  ButtonLinkExternal,
  H4,
  LocaleContext,
  Modal,
  Paragraph,
  ParagraphSmall,
  Template,
  TitleLarge,
  TitleMedium,
  ValueCopier,
} from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  selectProfileEmail,
  selectProfileEtransferAnswer,
  selectProfileEtransferEmail,
} from 'profile/store/selectors';
import { theme } from 'theme';

import { AutodepositFormCard } from '../components/AutodepositForm';

const useStyles = makeStyles({
  // page styles
  accordionRoot: {
    padding: 0,
    boxShadow: 'none',
    marginTop: '12px',
    '&:before': {
      content: 'none',
    },
  },
  noPadding: {
    padding: 0,
    margin: 0,
  },
  details: {
    padding: 0,
    display: 'block',
  },
  // modal styles
  paperWidthXs: {
    maxWidth: 500,
  },
  paper: {
    borderRadius: '12px',
    padding: '0 8px',
  },
});

export const LoadViaETransfer = () => {
  const intl = useIntl();

  const accountEmail = useSelector(selectProfileEmail);
  const eTransferEmail = useSelector(selectProfileEtransferEmail);
  const eTransferAnswer = useSelector(selectProfileEtransferAnswer);

  const classes = useStyles();

  const [showInstantModal, setInstantModal] = useState(false);
  const [showPersonalModal, setPersonalModal] = useState(false);

  const autodepositEnabled =
    useFlags()[AddFundsFeatureFlags.AutodepositCardEnabled];

  function handleShowInstantModal() {
    track({
      event: 'Clicked',
      properties: {
        name: 'eTransferInstantModal',
        element: 'button',
        type: 'button',
      },
    });
    setInstantModal(true);
  }

  function handleShowPersonalModal() {
    track({
      event: 'Clicked',
      properties: {
        name: 'eTransferPersonalModal',
        element: 'button',
        type: 'button',
      },
    });
    setPersonalModal(true);
  }

  return (
    <Template name="LoadViaETransfer" variant="center">
      <TitleLarge>
        {intl.formatMessage({
          id: 'LoadViaETransfer.Title',
          defaultMessage: 'Load via e-Transfer',
        })}
      </TitleLarge>

      <AddFundsShadowBox>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TitleMedium sx={{ marginBottom: '4px' }}>
            {intl.formatMessage({
              id: 'LoadViaETransfer.KohoInstantTitle',
              defaultMessage: 'KOHO (Instant)',
            })}
          </TitleMedium>
          <MuiButton
            onClick={() => handleShowInstantModal()}
            sx={{ marginRight: '-16px' }}
          >
            <KDSIcons.Icons.Info color="#00579B" />
          </MuiButton>
        </Box>
        <Paragraph sx={{ marginTop: '4px' }}>
          {intl.formatMessage({
            id: 'LoadViaETransfer.KohoInstantDescription',
            defaultMessage:
              'e-Transfer money directly to your account using your unique KOHO email.',
          })}
        </Paragraph>

        <ParagraphSmall>
          {intl.formatMessage({
            id: 'LoadViaETransfer.CopyPasteEmail',
            defaultMessage: 'Copy & paste this email',
          })}
        </ParagraphSmall>
        <ValueCopier value={eTransferEmail || ''} />
        <Accordion classes={{ root: classes.accordionRoot }}>
          <AccordionSummary
            onClick={() =>
              track({
                event: 'Clicked',
                properties: {
                  name: 'security-qa',
                  element: 'accordion',
                  type: 'button',
                },
              })
            }
            expandIcon={<ArrowDropDownIcon sx={{ color: 'black' }} />}
            id="security-qa-header"
            aria-controls="security-qa-content"
            classes={{
              root: classes.noPadding,
              content: classes.noPadding,
              expanded: classes.noPadding,
            }}
          >
            <H4>
              {intl.formatMessage({
                id: 'LoadInstructions.SecurityTitle',
                defaultMessage: 'Security question & answer',
              })}
            </H4>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.details }}>
            <Paragraph>
              {intl.formatMessage({
                id: 'LoadViaETransfer.SecurityQuestionDescription',
                defaultMessage:
                  'If your bank requires a security question and answer for e-Transfers, copy and paste the the following information:',
              })}
            </Paragraph>
            <ParagraphSmall sx={{ mt: 3 }}>
              {intl.formatMessage({
                id: 'LoadInstructions.SecurityQuestion',
                defaultMessage: 'Security question (if your bank requires one)',
              })}
            </ParagraphSmall>
            <ValueCopier
              value={intl.formatMessage({
                id: 'LoadInstructions.KohoLoadCode',
                defaultMessage: 'KOHO Load Code',
              })}
            />
            <ParagraphSmall sx={{ mt: 3 }}>
              {intl.formatMessage({
                id: 'LoadInstructions.SecurityAnswer',
                defaultMessage:
                  'Security answer (must match the below to work)',
              })}
            </ParagraphSmall>
            <ValueCopier
              sx={{ fontFamily: 'Roboto Mono' }}
              value={eTransferAnswer || ''}
            />
          </AccordionDetails>
        </Accordion>
      </AddFundsShadowBox>

      <AddFundsShadowBox>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TitleMedium sx={{ marginBottom: '4px' }}>
            {intl.formatMessage({
              id: 'LoadViaETransfer.PersonalTitle',
              defaultMessage: 'Personal',
            })}
          </TitleMedium>
          <MuiButton
            onClick={() => handleShowPersonalModal()}
            sx={{ marginRight: '-16px' }}
          >
            <KDSIcons.Icons.Info color="#00579B" />
          </MuiButton>
        </Box>
        <Paragraph sx={{ marginTop: '4px' }}>
          {intl.formatMessage({
            id: 'LoadViaETransfer.PersonalDescription',
            defaultMessage:
              'You can use your personal email to add money to KOHO easily just as you would with any e-Transfer.',
          })}
        </Paragraph>

        <ParagraphSmall>
          {intl.formatMessage({
            id: 'LoadViaETransfer.CopyPasteEmail',
            defaultMessage: 'Copy & paste this email',
          })}
        </ParagraphSmall>
        <ValueCopier value={accountEmail || ''} />

        <Paragraph sx={{ margin: '24px 0 8px' }}>
          {intl.formatMessage({
            id: 'LoadViaETransfer.AutodepositDescription',
            defaultMessage:
              'If your email is setup for auto-deposit with another bank you will need to enable autodeposit below.',
          })}
        </Paragraph>
      </AddFundsShadowBox>

      {autodepositEnabled && <AutodepositFormCard />}

      <InstantTransferModal
        open={showInstantModal}
        onClose={() => setInstantModal(false)}
      ></InstantTransferModal>

      <PersonalTransferModal
        open={showPersonalModal}
        onClose={() => setPersonalModal(false)}
      ></PersonalTransferModal>
    </Template>
  );
};

/**
 * Used in intl.formatMessage to bold text
 */
const boldText = (text: string) => <strong>{text}</strong>;

/**
 * Component used in each of the modals to provide a link to the help center
 */
function HelpButton() {
  const intl = useIntl();
  const context = useContext(LocaleContext);

  return (
    <ButtonLinkExternal
      trackName="eTransferHelp"
      variant="secondary"
      sx={{ marginTop: '16px' }}
      href={`https://help.koho.ca/${context.locale}/articles/1610518-e-transfer-time-to-load`}
    >
      {intl.formatMessage({
        id: 'LoadViaETransfer.InstantTransferModal.Questions',
        defaultMessage: 'Still got questions?',
      })}
    </ButtonLinkExternal>
  );
}

function BoxIcon({ children }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          width: '40px',
          height: '40px',
          backgroundColor: theme.colors.primary,
          borderRadius: theme.borderRadius.medium,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

const InstantTransferModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      classes={{ paperWidthXs: classes.paperWidthXs, paper: classes.paper }}
    >
      <TitleMedium sx={{ mt: 0 }}>
        {intl.formatMessage({
          id: 'LoadViaETransfer.InstantTransferModal.Title',
          defaultMessage: 'How to e-Transfer to your KOHO email',
        })}
      </TitleMedium>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <BoxIcon>
          <KDSIcons.Icons.Send
            sx={{ wibdth: '24px', height: '24px', color: theme.colors.white }}
          />
        </BoxIcon>
        <Paragraph>
          {intl.formatMessage({
            id: 'LoadViaETransfer.InstantTransferModal.StepOne',
            defaultMessage:
              'Open your banking app, and tap on "Send Money" or "Send Interac e-Transfer"',
          })}
        </Paragraph>
      </Box>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <BoxIcon>
          <KDSIcons.Icons.Copy
            sx={{ width: '24px', height: '24px', color: theme.colors.white }}
          />
        </BoxIcon>
        <Paragraph>
          {intl.formatMessage({
            id: 'LoadViaETransfer.InstantTransferModal.StepTwo',
            defaultMessage:
              'Tap to copy and paste the exact information to send an e-Transfer',
          })}
        </Paragraph>
      </Box>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <BoxIcon>
          <KDSIcons.Icons.Rich
            sx={{ width: '24px', height: '24px', color: theme.colors.white }}
          />
        </BoxIcon>
        <Paragraph>
          {intl.formatMessage({
            id: 'LoadViaETransfer.InstantTransferModal.StepThree',
            defaultMessage:
              "That's it!  Your money will be added to your KOHO account",
          })}
        </Paragraph>
      </Box>
      <HelpButton />
    </Modal>
  );
};

const PersonalTransferModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const email = useSelector(selectProfileEmail);

  return (
    <Modal
      open={open}
      onClose={onClose}
      classes={{ paperWidthXs: classes.paperWidthXs, paper: classes.paper }}
    >
      <TitleMedium sx={{ mt: 0 }}>
        {intl.formatMessage({
          id: 'LoadViaETransfer.PersonalTransferModal.Title',
          defaultMessage: 'How to e-Transfer to your KOHO email',
        })}
      </TitleMedium>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <BoxIcon>
          <KDSIcons.Icons.Send
            sx={{ wibdth: '24px', height: '24px', color: theme.colors.white }}
          />
        </BoxIcon>
        <Paragraph>
          {intl.formatMessage(
            {
              id: 'LoadViaETransfer.PersonalTransferModal.StepOne',
              defaultMessage: `Open your banking app, and set up an e-Transfer to <boldText>{email}</boldText>`,
            },
            { boldText: (text: string) => boldText(text), email },
          )}
        </Paragraph>
      </Box>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <BoxIcon>
          <KDSIcons.Icons.Email
            sx={{ wibdth: '24px', height: '24px', color: theme.colors.white }}
          />
        </BoxIcon>
        <Paragraph>
          {intl.formatMessage(
            {
              id: 'LoadViaETransfer.PersonalTransferModal.StepTwo',
              defaultMessage:
                'Open your email and search for <boldText>"KOHO Powered by Peoples Trust"</boldText>',
            },
            { boldText: (text: string) => boldText(text) },
          )}
        </Paragraph>
      </Box>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <BoxIcon>
          <KDSIcons.Icons.Rich
            sx={{ wibdth: '24px', height: '24px', color: theme.colors.white }}
          />
        </BoxIcon>
        <Paragraph>
          {intl.formatMessage({
            id: 'LoadViaETransfer.PersonalTransferModal.StepThree',
            defaultMessage:
              'Accept your transfer, and the money will be added to your KOHO account',
          })}
        </Paragraph>
      </Box>
      <HelpButton />
    </Modal>
  );
};
