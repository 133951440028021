/** @jsxImportSource theme-ui */
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ListItemIcon, ListItemText } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import { useLanguageMutation } from 'apis/users';
import { selectUserRefID } from 'profile/store/selectors';

import { ButtonUnstyled, Text } from '.';
import { LOCALE_EN, LOCALE_FR } from '../components/I18NContextProvider';
import { LocaleContext } from './I18NContextProvider';

export const LanguageToggle = ({
  color = 'text',
  variant,
}: {
  color?: string;
  variant?: 'menuItem';
}) => {
  const toggleText = { en: 'FR', fr: 'EN' };
  const context = useContext(LocaleContext);
  const [languageMutation] = useLanguageMutation();
  const userRef = useSelector(selectUserRefID);

  const onClick = () => {
    const localeToSet = context.locale === LOCALE_EN ? LOCALE_FR : LOCALE_EN;
    context.setLanguage(localeToSet);
    localStorage.setItem('locale', localeToSet);

    if (userRef) {
      languageMutation({ userId: userRef, request: { language: localeToSet } });
    }
  };

  if (variant === 'menuItem') {
    return (
      <div
        onClick={onClick}
        sx={{
          display: 'flex',
        }}
      >
        <ListItemIcon>
          <LanguageIcon sx={{ color: 'text' }} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            style: {
              display: 'inline-flex',
              fontWeight: 800,
              color: '#212121',
            },
          }}
          sx={{ alignSelf: 'flex-end' }}
        >
          {toggleText[context.locale]}
        </ListItemText>
      </div>
    );
  }
  return (
    <ButtonUnstyled
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <LanguageIcon sx={{ color }} />
      <Text sx={{ alignSelf: 'flex-end', color }}>
        {toggleText[context.locale]}
      </Text>
    </ButtonUnstyled>
  );
};
