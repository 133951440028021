import { track } from 'analytics/analytics';
import { OnfidoApplicantReportType } from 'kyc/store/slice';
import { CommonSdkParameters } from 'onfido-sdk-ui/types/shared/SdkParameters';
import { StepDefinition } from 'onfido-sdk-ui/types/shared/Types';
import { theme } from 'theme';

export type OnfidoInitOptions = {
  token?: string;
  locale?: string;
  reportTypes?: OnfidoApplicantReportType[];
  onComplete: CommonSdkParameters['onComplete'];
  onError: CommonSdkParameters['onComplete'];
};

export const onfidoSteps: Record<OnfidoApplicantReportType, StepDefinition> = {
  document: {
    type: 'document',
    options: {
      useLiveDocumentCapture: true,
      uploadFallback: false,
      useUploader: false,
      documentTypes: {
        driving_licence: {
          country: 'CAN',
        },
        passport: true,
        national_identity_card: {
          country: 'CAN',
        },
        residence_permit: {
          country: 'CAN',
        },
      },
    },
  },
  face_video: {
    type: 'face',
    options: {
      useMultipleSelfieCapture: true,
      requestedVariant: 'video',
    },
  },
} as const;

export const initOnfido = async ({
  token,
  locale,
  reportTypes,
  onComplete,
  onError,
}: OnfidoInitOptions): Promise<void> => {
  // import the library on demand to reduce unnecessary downloading
  // if user don't have to do Onfido
  const { Onfido } = await import('onfido-sdk-ui');
  const steps = reportTypes?.map((type) => onfidoSteps[type]);

  if (!token || !Onfido || !steps?.length) {
    return;
  }

  track({ event: 'Onfido loaded' });

  Onfido.init({
    token,
    containerId: 'onfido-mount',
    crossDevicePolicy: 'force',
    steps,
    language: locale || 'en',
    customUI: {
      colorBackgroundSurfaceModal: 'white',
      borderWidthSurfaceModal: '0',
      fontFamilyTitle: theme.fonts.heading,
      fontSizeTitle: `${theme.fontSizes[5]}px`,
      fontWeightTitle: theme.fontWeights[3],
      fontFamilySubtitle: theme.fonts.heading,
      fontSizeSubtitle: `${theme.fontSizes[2]}px`,
      fontWeightSubtitle: theme.fontWeights[1],
      fontFamilyBody: theme.fonts.body,
      fontSizeBody: `${theme.fontSizes[2]}px`,
      fontWeightBody: theme.fontWeights[1],
      colorContentButtonPrimaryText: 'white',
      colorBorderLinkUnderline: theme.colors.primary,
      colorContentLinkTextHover: theme.colors.primary,
      colorBackgroundButtonPrimary: theme.colors.primary,
      colorBackgroundButtonPrimaryHover: '#003A67',
      colorBackgroundButtonPrimaryActive: '#003A67',
      colorBorderButtonPrimary: theme.colors.primary,
      colorContentButtonSecondaryText: theme.colors.primary,
      colorBackgroundButtonSecondary: 'white',
      colorContentAlertInfo: 'white',
      colorBackgroundAlertInfo: theme.colors.primary,
      colorBackgroundButtonSecondaryHover: theme.colors.primary,
      colorBackgroundButtonSecondaryActive: theme.colors.primary,
      colorBorderButtonSecondary: theme.colors.primary,
      colorContentInfoPill: 'white',
      borderRadiusButton: '4px',
      colorBackgroundLinkActive: 'transparent',
      colorBackgroundLinkHover: 'transparent',
      colorIcon: theme.colors.tertiary,
    },
    onComplete,
    onError,
  });
};
