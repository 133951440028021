export enum TierName {
  Easy = 'Easy',
  Essential = 'Essential',
  Extra = 'Extra',
  Everything = 'Everything',
}

export enum TierKey {
  Easy = 'easy',
  Essential = 'essential',
  Extra = 'extra',
  Everything = 'everything',
}

export const tierOrder = Object.values(TierKey);
