import { useContext } from 'react';

import { navigate } from '@reach/router';
import { trackInput } from 'analytics/analytics';
import { ICurrentBenefit, useGetCurrentTierQuery } from 'apis/tiers';
import { ReactComponent as TiersIcon } from 'assets/images/logos/everything-blue-en.svg';
import { ReactComponent as TiersIconFr } from 'assets/images/logos/everything-blue-fr.svg';
import everythingBg from 'assets/images/tiers/everything-background-gradient.png';
import clsx from 'clsx';
import { LOCALE_EN, LOCALE_FR, LocaleContext } from 'components';
import { ParagraphBold } from 'components/TypographyTw';
import { twMerge } from 'tailwind-merge';
import { useTiersData } from 'tiers/hooks/useTiersData';

import { TierRenewal } from './TierRenewal';

const everythingLogoOptions = {
  [LOCALE_EN]: TiersIcon,
  [LOCALE_FR]: TiersIconFr,
};

const goToMyPlan = () => {
  trackInput({
    type: 'Clicked',
    name: 'Tiers - Current tier widget - Manage plan',
    details: {
      element: 'a',
      to: '/tiers/my-plan',
    },
  });

  navigate('/tiers/my-plan');
};

export const ManagePlanWidget = ({ benefit }: { benefit: ICurrentBenefit }) => {
  const { locale } = useContext(LocaleContext);
  const { data: currentTier } = useGetCurrentTierQuery();
  const { getTierName } = useTiersData();

  const EverythingLogo = everythingLogoOptions[locale];
  const isEverything = benefit.benefit_group?.name === 'Everything';
  const tierName = benefit.benefit_group?.name
    ? getTierName(benefit.benefit_group?.name)
    : '';

  return (
    <>
      <button
        className={twMerge(
          'rounded-lg mb-4 px-6 py-5 w-full text-left cursor-pointer bg-[105%] bg-left-top',
          'light:bg-primary-300',
        )}
        data-cy="manage-plan-widget"
        onClick={goToMyPlan}
        style={{
          // TODO: update for new theme
          backgroundImage: isEverything
            ? `url(${everythingBg})`
            : 'linear-gradient(98.61deg, #5347E1 0.03%, #2F3CCF 37.02%, #6D79FD 100.06%)',
        }}
      >
        {isEverything ? (
          <EverythingLogo
            className={clsx(
              'h-auto mb-1',
              locale === LOCALE_FR ? 'w-[110px]' : 'w-[135px]',
            )}
          />
        ) : (
          <ParagraphBold className="m-0 text-2xl text-white">
            {tierName}
          </ParagraphBold>
        )}

        {currentTier ? <TierRenewal currentTier={currentTier} /> : null}
      </button>
    </>
  );
};
