/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';

import phonesImageMC from '../assets/images/general/phoneSpongeCubeMC.png';
import { H2, P } from '../components';
import {
  Card,
  FooterImageWrapper,
  LayoutBasic,
} from '../components/LayoutBasic';

export const GenericErrorPage = (props: RouteComponentProps) => {
  const intl = useIntl();

  return (
    <LayoutBasic pageName="Unidentified Error" sx={{ bg: '#E8E6E7' }}>
      <Card>
        <KDSIcons.Spot.Wait sx={{ mb: 3 }} />
        <H2>
          {intl.formatMessage({
            id: 'ErrorGeneric.Title',
            defaultMessage: 'Oops! Something went wrong',
          })}
        </H2>
        <P>
          {intl.formatMessage({
            id: 'ErrorGeneric.Content',
            defaultMessage:
              'Please check your internet connection or try again in a few minutes. If the problem persists, please contact our support team.',
          })}
        </P>
      </Card>
      <FooterImageWrapper>
        <img
          src={phonesImageMC}
          alt={`${intl.formatMessage({
            id: 'ErrorGeneric.FooterImageAltTag',
            defaultMessage: 'generic error footer image',
          })}`}
          sx={{ width: 260, position: 'relative', zIndex: 2 }}
        />
      </FooterImageWrapper>
    </LayoutBasic>
  );
};
