import { useIntl } from 'react-intl';

import { Typography } from '@material-ui/core';
import { useGetCurrentTierQuery } from 'apis/tiers';
import { Button, Modal } from 'components';
import { useToggle } from 'hooks/useToggle';
import { theme } from 'theme';

import { Steps } from '../tierChangeSteps';
import { DowngradeSteps } from './DowngradeSteps';

export type DowngradeSuccessProps = {
  downgradeDate: string;
  newTierCost: string;
  newTierName: string;
};

export const DowngradeSuccess = ({
  downgradeDate,
  newTierCost,
  newTierName,
}: DowngradeSuccessProps) => {
  const intl = useIntl();
  const { value: isModalOpen, off: closeModal } = useToggle(true);
  const { data: currentTier } = useGetCurrentTierQuery();

  const currentUsersTierPlan = currentTier?.benefit_group?.plans?.find(
    (plan) => plan.key === currentTier.frequency,
  );

  const currentTierSubscription = currentTier?.benefit_group;

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      title={intl.formatMessage(
        {
          id: 'DowngradeSuccess.Modal.Title',
          defaultMessage: 'Your plan will change on {date}',
        },
        { date: downgradeDate },
      )}
    >
      <Typography sx={{ color: theme.colors.text, mb: 4 }}>
        {intl.formatMessage({
          id: 'DowngradeSuccess.Modal.Subtitle',
          defaultMessage: "Here's what happens next:",
        })}
      </Typography>
      {currentUsersTierPlan && currentTierSubscription && (
        <Steps
          selectedPlan={currentUsersTierPlan}
          selectedTier={currentTierSubscription}
        >
          {({ activeTier }) => (
            <DowngradeSteps
              frequency={activeTier.frequency}
              oldTier={activeTier}
              newTierCost={newTierCost}
              newTierName={newTierName}
            />
          )}
        </Steps>
      )}
      <Button
        sx={{ bg: theme.colors.blueNavy, mt: 5 }}
        onClick={closeModal}
        trackName="tiers-downgrade-confirm"
      >
        {intl.formatMessage({
          id: 'DowngradeSuccess.Modal.GotIt',
          defaultMessage: 'Got it',
        })}
      </Button>
    </Modal>
  );
};
