import React from 'react';
import { MessageDescriptor, defineMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Box, Dialog } from '@material-ui/core';
import { OnboardingChecklistDynamicItemKeys } from 'apis/onboardingChecklistDynamicApi';
import { useSendSMSLinkMutation } from 'apis/registration';
import bgImage from 'assets/images/app-download/app-download-bg.9aslkna.jpg';
import appStoreEn from 'assets/images/app-download/apple-en.svg';
import appStoreFr from 'assets/images/app-download/apple-fr.svg';
import googlePlayEn from 'assets/images/app-download/google-en.png';
import googlePlayFr from 'assets/images/app-download/google-fr.png';
import {
  Button,
  LOCALE_EN,
  Link,
  LocaleContext,
  ModalHeader,
  Paragraph,
  TitleLarge,
} from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectProfileTel } from 'profile/store/selectors';
import { RegistrationFeatureFlags } from 'registration/models/RegistrationFeatureFlags';

enum GetAppModalType {
  Credit = 'credit',
  Saving = 'saving',
  Referral = 'referral',
}

export const GetTheAppModal = ({
  checklistItemKey,
  open,
  onClose,
}: {
  checklistItemKey: OnboardingChecklistDynamicItemKeys;
  open: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();
  const [linkSent, setLinkSent] = React.useState(false);
  const tel = useSelector(selectProfileTel);
  const [sendSMSLink, sendSmsLinkMutationData] = useSendSMSLinkMutation();
  const { deepLinks, locale } = React.useContext(LocaleContext);

  const modalType = getType(checklistItemKey);
  const modalVariant = getModalVariant(modalType, deepLinks);
  const interestRate = useFlags()[RegistrationFeatureFlags.InterestRate];

  const getLinkButtonClicked = async () => {
    try {
      await sendSMSLink({
        type: 'onboarding',
        tel_no: tel!,
      });

      setLinkSent(true);
    } catch {
      console.warn('send sms link failed');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '16px',
        },
      }}
      maxWidth="xs"
    >
      <ModalHeader onClose={onClose} />
      <div sx={{ px: 4 }}>
        <TitleLarge sx={{ textAlign: 'center', mt: 0 }}>
          {intl.formatMessage({
            id: 'GetTheAppModal.Title',
            defaultMessage: 'Get the app',
          })}
        </TitleLarge>
        <Paragraph sx={{ textAlign: 'center', mb: 4 }}>
          {intl.formatMessage(modalVariant.description, { interestRate })}
        </Paragraph>
        <Button
          trackName="GetTheAppModal Send SMS Link Button"
          loading={sendSmsLinkMutationData.isLoading}
          onClick={getLinkButtonClicked}
          sx={{ mb: 4 }}
        >
          {linkSent
            ? intl.formatMessage({
                id: 'GetTheAppModal.LinkSentButton',
                defaultMessage: 'Link sent!',
              })
            : intl.formatMessage({
                id: 'GetTheAppModal.TextLinkButton',
                defaultMessage: 'Text me a download link',
              })}
        </Button>
        <Link
          trackName={`${modalType}-faq`}
          to={modalVariant.link}
          external={true}
          sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}
        >
          {intl.formatMessage(modalVariant.faqButtonText)}
        </Link>
      </div>
      <Box
        sx={{
          background: `url(${bgImage})`,
          backgroundSize: 'cover',
          pt: 4,
          pb: 5,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            alignItems: 'center',
            p: 3,
            borderRadius: '8px',
            gap: '8px',
            background: 'white',
          }}
        >
          <Link
            trackName="GetTheAppModal Apple Download"
            external
            to="https://apps.apple.com/ca/app/koho-financial/id1091010942"
            aria-label={intl.formatMessage({
              id: 'GetTheAppModal.DownloadFromApple',
              defaultMessage: 'Download KOHO from the Apple app store',
            })}
          >
            <img
              src={locale === LOCALE_EN ? appStoreEn : appStoreFr}
              alt=""
              sx={{ height: '44px', display: 'block' }}
            />
          </Link>
          <Link
            trackName="GetTheAppModal Google Play Download"
            external
            to="https://play.google.com/store/apps/details?id=ca.koho"
            aria-label={intl.formatMessage({
              id: 'GetTheAppModal.DownloadFromGoogle',
              defaultMessage: 'Download KOHO from Google Play',
            })}
          >
            <img
              src={locale === LOCALE_EN ? googlePlayEn : googlePlayFr}
              alt=""
              sx={{ height: '44px', display: 'block' }}
            />
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
};

const getType = (key: OnboardingChecklistDynamicItemKeys): GetAppModalType => {
  switch (key) {
    case 'HasSeenCreditScore':
    case 'IsCreditBuildingRegistered':
    case 'HasSetUtilizationRate':
      return GetAppModalType.Credit;
    case 'IsEarningInterest':
    case 'IsSavingGoal':
      return GetAppModalType.Saving;
    case 'IsIdentityVerified':
      return GetAppModalType.Referral;
    default:
      throw new Error('Invalid checklist key passed to GetTheAppModal');
  }
};

const getModalVariant = (
  type: GetAppModalType,
  deeplinks: any,
): {
  description: MessageDescriptor;
  faqButtonText: MessageDescriptor;
  link: string;
} => {
  switch (type) {
    case GetAppModalType.Credit:
      return {
        description: defineMessage({
          id: 'GetTheAppModal.Description.Credit',
          defaultMessage:
            'Borrow up to $225 simply and fairly to grow your credit history with the KOHO app.',
        }),
        faqButtonText: defineMessage({
          id: 'GetTheAppModal.FAQButton.Credit',
          defaultMessage: 'Read Credit FAQs',
        }),
        link: deeplinks.KOHO.CreditFAQ,
      };
    case GetAppModalType.Saving:
      return {
        description: defineMessage({
          id: 'GetTheAppModal.Description.Saving',
          defaultMessage:
            '{interestRate}% interest is waiting on the KOHO app. Sign-up for save, and start earning.',
        }),
        faqButtonText: defineMessage({
          id: 'GetTheAppModal.FAQButton.Saving',
          defaultMessage: 'Read Save FAQs',
        }),
        link: deeplinks.KOHO.SaveFAQ,
      };
    case GetAppModalType.Referral:
      return {
        description: defineMessage({
          id: 'GetTheAppModal.Description.Referral',
          defaultMessage:
            'Unlock your referral reward and put it to great use earning cash back, building credit, and more.',
        }),
        faqButtonText: defineMessage({
          id: 'GetTheAppModal.FAQButton.Referral',
          defaultMessage: 'Read referral FAQs',
        }),
        link: deeplinks.KOHO.ReferralFAQ,
      };
    default:
      throw new Error('Invalid checklist type passed to getModalVariant');
  }
};
