import { validPhoneRegex } from 'components/forms';
import { InputValidationMsg } from 'components/forms/useValidationMsg';
import { z } from 'zod';

export const zodFormRegistrationMobileNumber = z.object({
  mobileNumber: z
    .string()
    .min(1, { message: InputValidationMsg.FieldRequired })
    .regex(validPhoneRegex, {
      message: InputValidationMsg.InvalidPhone,
    }),
});

export type MobileNumberSchema = z.infer<
  typeof zodFormRegistrationMobileNumber
>;
