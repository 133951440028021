import { InputValidationMsg } from 'components/forms/useValidationMsg';
import { validateEmail } from 'utility/validateEmail';
import { z } from 'zod';

export const zodLoginForm = z.object({
  email: z
    .string()
    .min(1, {
      message: InputValidationMsg.FieldRequired,
    })
    .max(64, {
      message: InputValidationMsg.EmailTooLong,
    })
    .refine((value) => validateEmail(value), {
      message: InputValidationMsg.EmailInvalid,
    }),
  'current-password': z
    .string()
    .min(1, { message: InputValidationMsg.FieldRequired }),
});

export type LoginFormSchema = z.infer<typeof zodLoginForm>;
