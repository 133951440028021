/** @jsxImportSource theme-ui */
import { RouteComponentProps, useLocation } from '@reach/router';
import { Logo } from 'components/Logo';
import { Flex } from 'theme-ui';

import { LanguageToggle } from '../../components';
import { LoginSmsOtpForm } from '../components/LoginSmsOtpForm';

interface SmsVerifyPageLocationState {
  fallback?: boolean;
}

export const SmsVerifyPage = (props: RouteComponentProps) => {
  const { state } = useLocation();

  const { fallback = false } = (state as SmsVerifyPageLocationState) || {
    resend: false,
  };

  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        width: ['100%', '100%'],
        minHeight: '100vh',
        pb: [3, 0, 0, 0],
      }}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          width: ['100%', '100%', '100%', '100%'],
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 4,
          px: [4, '2%', '2%', '2%'],
          height: '60px',
        }}
      >
        <Logo />
        <LanguageToggle />
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: ['100%', '80%', '70%', '50%'],
          maxWidth: '550px',
          mt: [2, 5],
          flexGrow: 1,
          px: 4,
        }}
      >
        <Flex
          sx={{
            justifyContent: 'left',
            flexDirection: 'column',
            mb: 3,
            flexGrow: 1,
          }}
        >
          <LoginSmsOtpForm fallback={fallback} />
        </Flex>
      </Flex>
    </Flex>
  );
};
