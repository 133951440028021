import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import { InputBaseComponentProps } from '@material-ui/core/InputBase';

export const TextMaskPostalCode = ({
  inputRef,
  ...props
}: Omit<MaskedInputProps & InputBaseComponentProps, 'css'>) => (
  <MaskedInput
    {...props}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[
      /[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]/,
      /[0-9]/,
      /[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]/,
      ' ',
      /[0-9]/,
      /[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]/,
      /[0-9]/,
    ]}
    guide={false}
    placeholderChar={'\u2000'}
    keepCharPositions={false}
  />
);
