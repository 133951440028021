import { MessageDescriptor, defineMessage } from 'react-intl';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Loadable, createDefaultLoadable } from '../../utility/loadable';

type TSmsOtpScreen = 'request' | 'enter';

const initialState: SmsOtpState = {
  otp: '',
  token: '',
  screen: 'request',
  loadable: createDefaultLoadable(),
  loadableResend: createDefaultLoadable(),
};

const smsOTPSlice = createSlice({
  name: 'smsOtp',
  initialState,
  reducers: {
    requestForgotPassword: (state) => state,
    set: (state, action: PayloadAction<string>) => {
      state.otp = action.payload;
      state.loadable = createDefaultLoadable();
    },
    tokenSet: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    screenSet: (state, action: PayloadAction<TSmsOtpScreen>) => {
      state.screen = action.payload;
    },
    verify: (state) => {
      state.loadable = {
        error: null,
        loading: true,
        success: false,
      };
    },
    loadableFulfilled: (state) => {
      state.loadable = {
        error: null,
        loading: false,
        success: true,
      };
    },
    loadableFulfilledResend: (state) => {
      state.loadableResend = {
        error: null,
        loading: false,
        success: true,
      };
    },
    loadableReset: (state) => {
      state.loadable = createDefaultLoadable();
    },
    loadableFailed: (
      state,
      action: PayloadAction<{
        error?: { error_message: MessageDescriptor };
      }>,
    ) => {
      state.loadable = {
        error: action.payload.error ? action.payload.error : null,
        loading: false,
        success: false,
      };
    },
    loadableFailedResend: (state) => {
      state.loadableResend = {
        error: defineMessage({
          id: 'SmsOtp.FailedResend',
          defaultMessage:
            'There was a problem resending the code, please try again later!',
        }),
        loading: false,
        success: false,
      };
    },
  },
});

export interface SmsOtpState {
  otp: string;
  token: string;
  screen: TSmsOtpScreen;
  loadable: Loadable;
  loadableResend: Loadable;
}

export interface SmsOtpVerifyError {
  error_code?: number;
  error_message?: string;
  status_code: number;
  status_name: string;
}

export const smsOtpActions = smsOTPSlice.actions;
export default smsOTPSlice.reducer;
