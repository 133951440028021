import { ReactNode, useContext } from 'react';
import { useIntl } from 'react-intl';

import { IBenefit } from 'apis/tiers';
import { LocaleContext } from 'components';
import { Money } from 'models';
import { IntendedUse } from 'profile/models/profile';
import { TierKey, TierName } from 'tiers/models';

type IntendedUseSubset =
  | IntendedUse.Cashback
  | IntendedUse.Credit
  | IntendedUse.Saving
  | IntendedUse.Borrowing;

export type TFeatures = Record<
  string,
  {
    value: string | ReactNode;
    tooltip?: {
      title: string;
      content: string;
    };
  }
>;

export const useTiersData = () => {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const getTierName = (name: TierName) =>
    ({
      [TierName.Essential]: intl.formatMessage({
        defaultMessage: 'Essential',
        id: 'TiersData.Essential',
      }),
      [TierName.Extra]: intl.formatMessage({
        defaultMessage: 'Extra',
        id: 'TiersData.Extra',
      }),
      [TierName.Everything]: intl.formatMessage({
        defaultMessage: 'Everything',
        id: 'TiersData.Everything',
      }),
    }[name]);

  const headerText: Record<IntendedUseSubset, string> = {
    cashback: intl.formatMessage({
      id: 'TiersData.Header.cashback',
      defaultMessage: 'Upgrade to 2% cash back with a plan',
    }),
    credit: intl.formatMessage({
      id: 'TiersData.Header.credit',
      defaultMessage: 'Save on your credit journey with a plan',
    }),
    saving: intl.formatMessage({
      id: 'TiersData.Header.saving',
      defaultMessage: 'Upgrade to 5% interest with a plan',
    }),
    borrowing: intl.formatMessage({
      id: 'TiersData.Header.borrowing',
      defaultMessage: 'Upgrade your borrowing with a plan',
    }),
  };

  const getTierDescription = (tierKey: TierKey) => {
    switch (tierKey) {
      case TierKey.Essential:
        return intl.formatMessage({
          id: 'TiersData.Description.essential',
          defaultMessage:
            'Everyday cash back, interest, and savings on what KOHO has to offer.',
        });
      case TierKey.Extra:
        return intl.formatMessage({
          id: 'TiersData.Description.extra',
          defaultMessage:
            'Even better cash back, interest, and savings on what KOHO has to offer.',
        });
      case TierKey.Everything:
        return intl.formatMessage({
          id: 'TiersData.Description.everything',
          defaultMessage:
            'Get the best of everything to optimize your earning, saving, and credit.',
        });
      default:
        return '';
    }
  };

  const getHeaderText = (
    intent: IntendedUse | undefined,
  ): string | ReactNode => {
    const oneTimeOffer = intl.formatMessage({
      defaultMessage: 'One-time offer:',
      id: 'TiersData.HeaderPrefix.default',
    });
    const defaultText = intl.formatMessage(
      {
        defaultMessage: '{text} try any KOHO plan free for 30 days',
        id: 'TiersData.Header.default',
      },
      {
        text: <span>{oneTimeOffer}</span>,
      },
    );

    if (!intent) return defaultText;
    return headerText[intent] ?? defaultText;
  };

  function getFeatures(
    benefits: IBenefit[],
    benefitKey: TierKey,
    essentialCreditBuildingBenefit: IBenefit | undefined,
  ): TFeatures {
    const essentialCreditAmount = intl.formatMessage(
      {
        defaultMessage: '{unit}{price}',
        id: 'TiersData.CreditDollarAmount',
      },
      {
        unit: essentialCreditBuildingBenefit?.unit,
        price: essentialCreditBuildingBenefit?.value,
      },
    );

    const features = benefits.reduce<TFeatures>(
      (acc, item) => {
        switch (item.key) {
          case 'grocery':
            const groceryBold = intl.formatMessage(
              {
                id: 'TiersData.Cashback',
                defaultMessage: '{value} cash back',
              },
              { value: `${item.value}${item.unit}` },
            );
            acc.cashbackCategories.value = intl.formatMessage(
              {
                defaultMessage:
                  '{value} on groceries, transportation, plus food & drink',
                id: 'TiersData.CashbackCategoriesDetail',
              },
              { value: <strong>{groceryBold}</strong> },
            );
            break;
          case 'save-rate':
            const saveRateBold = intl.formatMessage(
              {
                defaultMessage: '{value} interest',
                id: 'TiersData.Interest',
              },
              { value: `${item.value}${item.unit}` },
            );
            acc.balanceInterest.value = intl.formatMessage(
              {
                defaultMessage: '{value} on your balance',
                id: 'TiersData.BalanceInterestDetail',
              },
              { value: <strong>{saveRateBold}</strong> },
            );
            break;
          case 'all-purchases':
            const allPurchasesBold = `${item.value}${item.unit}`;
            acc.allPurchases.value =
              benefitKey !== TierKey.Everything
                ? ''
                : intl.formatMessage(
                    {
                      defaultMessage: '{value} cash back on every purchase',
                      id: 'TiersData.AllPurchasesDetails',
                    },
                    { value: <strong>{allPurchasesBold}</strong> },
                  );
            break;
          case 'free-credit-score':
            const creditScoreBold = intl.formatMessage({
              id: 'TiersData.Free',
              defaultMessage: 'Free',
            });
            acc.freeCreditScore.value = intl.formatMessage(
              {
                defaultMessage: '{value} credit score',
                id: 'TiersData.FreeCreditScoreDetail',
              },
              { value: <strong>{creditScoreBold}</strong> },
            );
            acc.freeCreditScore.tooltip = {
              title: intl.formatMessage({
                defaultMessage: 'Free credit score',
                id: 'TiersData.FreeCreditScoreTooltip.title',
              }),
              content: intl.formatMessage({
                defaultMessage:
                  "You'll get a free regularly updated credit score powered by Equifax as long as you're on the Essential, Extra or Everything plan.",
                id: 'TiersData.FreeCreditScoreTooltip.description',
              }),
            };
            break;
          case 'cash-back-partners':
            const cashBackBold = intl.formatMessage({
              defaultMessage: '1,000+ cash back partners',
              id: 'TiersData.NumberCashBackPartners',
            });
            acc.cashBackPartners.value = intl.formatMessage(
              {
                defaultMessage:
                  '{value} offering 6.5% extra cash back on average',
                id: 'TiersData.CashBackPartnersDetail',
              },
              { value: <strong>{cashBackBold}</strong> },
            );
            break;
          case 'credit-monthly-price':
            const creditBuildingBold = intl.formatMessage(
              {
                // eslint-disable-next-line no-template-curly-in-string
                defaultMessage: '{text}${value}/month',
                id: 'TiersData.Credit',
              },
              {
                text:
                  benefitKey === TierKey.Essential ? null : (
                    <s>{essentialCreditAmount}</s>
                  ),
                value: item.value,
              },
            );
            acc.creditBuilding.value = intl.formatMessage(
              {
                defaultMessage: '{value} Credit Building',
                id: 'TiersData.CreditBuildingDetail',
              },
              { value: <strong>{creditBuildingBold}</strong> },
            );
            acc.creditBuilding.tooltip = {
              title: intl.formatMessage({
                defaultMessage: 'Credit building savings',
                id: 'TiersData.CreditBuildingTooltip.title',
              }),
              content: intl.formatMessage({
                defaultMessage:
                  "If you're signed-up to Credit Building, your membership will save you on your monthly subscription.",
                id: 'TiersData.CreditBuildingTooltip.description',
              }),
            };
            break;
          case 'no-foreign-tx-fees':
            const noForeignTxBold = intl.formatMessage({
              id: 'TiersData.NoForeignTxBold',
              defaultMessage: 'No foreign transaction fees',
            });
            acc.foreingTransactionFees.value =
              item.value === 'yes' && benefitKey === TierKey.Everything
                ? intl.formatMessage(
                    {
                      defaultMessage: '{value}',
                      id: 'TiersData.NoForeignTxDetail',
                    },
                    { value: <strong>{noForeignTxBold}</strong> },
                  )
                : '';
            acc.foreingTransactionFees.tooltip = {
              title: intl.formatMessage({
                defaultMessage: 'No foreign transaction fees',
                id: 'TiersData.NoForeignTxTooltip.title',
              }),
              content: intl.formatMessage({
                defaultMessage:
                  "That's right. You won't pay any foreign transaction fees whenever you use your KOHO card to spend abroad.",
                id: 'TiersData.NoForeignTxTooltip.description',
              }),
            };
        }

        return acc;
      },
      {
        cashbackCategories: { value: '' },
        balanceInterest: { value: '' },
        allPurchases: { value: '' },
        freeCreditScore: { value: '' },
        cashBackPartners: { value: '' },
        creditBuilding: { value: '' },
        foreingTransactionFees: { value: '' },
      },
    );

    return features;
  }

  function getSavingPerYear(
    savingsPerYear: string,
    benefitName: TierName,
  ): ReactNode {
    const savings = new Money(savingsPerYear, locale).format();
    const perYear = intl.formatMessage(
      {
        defaultMessage: '{savings} per year',
        id: 'TiersData.PerYear',
      },
      { savings },
    );

    return intl.formatMessage(
      {
        defaultMessage: '{tierName} members save {value} on average',
        id: 'TiersData.SavingsPerYear',
      },
      {
        tierName: getTierName(benefitName),
        value: <span>{perYear}</span>,
      },
    );
  }

  function getSavingPerMonth(savingsPerMonth: string): ReactNode {
    const savings = new Money(savingsPerMonth, locale).format('pretty');
    return intl.formatMessage(
      {
        defaultMessage: 'Save {value} per month on average.',
        id: 'TiersData.SavingsPerMonth',
      },
      { value: savings },
    );
  }

  function getStartYourTrialMessage(tierKey: TierKey): string {
    return {
      [TierKey.Essential]: intl.formatMessage({
        id: 'TiersUpgrade.Modal.Essential.HappensToday.free',
        defaultMessage: 'Start your 30 day free trial of Essential.',
      }),
      [TierKey.Extra]: intl.formatMessage({
        id: 'TiersUpgrade.Modal.Extra.HappensToday.free',
        defaultMessage: 'Start your 30 day free trial of Extra.',
      }),
      [TierKey.Everything]: intl.formatMessage({
        id: 'TiersUpgrade.Modal.Everything.HappensToday.free',
        defaultMessage: 'Start your 30 day free trial of Everything.',
      }),
    }[tierKey];
  }

  function getTierUpgradeSteps({
    currentTierIsFreeTrial,
    isPaidTier,
    planKey,
    planCost,
    proratedCost,
  }: {
    currentTierIsFreeTrial: boolean;
    isPaidTier: boolean;
    planKey: string;
    planCost: string | number;
    proratedCost: string | number;
  }): { upgradeFirstStep: string; upgradeLastStep: string } {
    const localizedPlanCost = new Money(planCost, locale).format('pretty');
    const localizedProratedCost = new Money(proratedCost, locale).format(
      'pretty',
    );

    const planVariants = {
      monthly: {
        upgradeFirstStep: currentTierIsFreeTrial
          ? intl.formatMessage({
              id: 'TiersUpgrade.Modal.HappensToday.upgradeForRestOfTrial',
              defaultMessage:
                "You'll immediately be upgraded for the remainder of your trial.",
            })
          : intl.formatMessage(
              {
                id: 'TiersUpgrade.Modal.HappensToday.upgradeMonthly',
                defaultMessage:
                  "You'll immediately be upgraded, and pay {cost} for the rest of the month.",
              },
              { cost: isPaidTier ? localizedProratedCost : localizedPlanCost },
            ),
        upgradeLastStep: intl.formatMessage(
          {
            id: 'TiersUpgrade.Modal.MonthRenewal',
            defaultMessage: "You'll pay {value}/month.",
          },
          { value: localizedPlanCost },
        ),
      },
      annual: {
        upgradeFirstStep: currentTierIsFreeTrial
          ? intl.formatMessage({
              id: 'TiersUpgrade.Modal.HappensToday.upgradeForRestOfTrial',
              defaultMessage:
                "You'll immediately be upgraded for the remainder of your trial.",
            })
          : intl.formatMessage(
              {
                id: 'TiersUpgrade.Modal.HappensToday.upgradeYearly',
                defaultMessage:
                  "You'll immediately be upgraded, and pay {cost} for the rest of the year.",
              },
              { cost: localizedProratedCost },
            ),
        upgradeLastStep: intl.formatMessage(
          {
            id: 'TiersUpgrade.Modal.YearRenewal',
            defaultMessage: "Your plan renews, and you'll pay {cost}/year.",
          },
          { cost: localizedPlanCost },
        ),
      },
    }[planKey];

    return (
      planVariants || {
        upgradeFirstStep: '',
        upgradeLastStep: '',
      }
    );
  }

  return {
    getFeatures,
    getHeaderText,
    getSavingPerMonth,
    getSavingPerYear,
    getStartYourTrialMessage,
    getTierDescription,
    getTierName,
    getTierUpgradeSteps,
  };
};
