/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import InputAdornment from '@material-ui/core/InputAdornment';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTypedSelector } from 'hooks/redux';

import { anonymousUserActions } from '../../anonymousUser/store/slice';
import { H2, H3, P, SpinnerCircularProgress } from '../../components';
import { Button } from '../../components';
import { TextInput } from '../../components/forms';
import { smsOtpActions } from '../store/slice';

export const SmsOtp = ({ onSuccess }: { onSuccess: () => void }) => {
  const dispatch = useDispatch();
  const state = useTypedSelector((state) => state);
  const [smsRequestLoading, setSmsRequestLoading] =
    React.useState<boolean>(false);
  const intl = useIntl();

  const [secondsSinceSmsOtpResent, setSecondsSinceSmsOtpResent] =
    React.useState<number>(0);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (secondsSinceSmsOtpResent) {
        setSecondsSinceSmsOtpResent(secondsSinceSmsOtpResent - 1);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [secondsSinceSmsOtpResent]);

  React.useEffect(() => {
    if (state.anonymousUser.id) {
      dispatch(smsOtpActions.screenSet('request'));
    } else {
      dispatch(anonymousUserActions.fetch());
    }
  }, [state.anonymousUser.id, dispatch]);

  React.useEffect(() => {
    if (state.smsOtp.loadable.success && onSuccess) {
      onSuccess();
    }
  }, [state.smsOtp.loadable.success, onSuccess]);

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const numberRegex = /^[0-9\b]+$/;
    const code = event.target.value;
    if (event.target.value === '' || numberRegex.test(event.target.value)) {
      const val = code.length ? code.substr(0, 6) : '';
      dispatch(smsOtpActions.set(val));

      if (val.length === 6) {
        dispatch(smsOtpActions.verify());
      }
    }
  };

  const resendCode = () => {
    setSecondsSinceSmsOtpResent(60);
    dispatch(smsOtpActions.requestForgotPassword());
  };

  switch (state.smsOtp.screen) {
    case 'request':
      return (
        <div>
          <H2>
            {intl.formatMessage({
              id: 'SmsOtp.Screen.Title',
              defaultMessage: 'Confirm your phone number',
            })}
          </H2>
          <P>
            {intl.formatMessage({
              id: 'SmsOtp.Screen.Content',
              defaultMessage:
                'This is what we currently have on file for you. If this is correct, confirm and continue. If not, chat with us to update your phone number.',
            })}
          </P>

          {state.anonymousUser.phoneNumberExcerpt ? (
            <div
              sx={{
                bg: `greyXLight`,
                py: 2,
                px: 3,
                borderRadius: 4,
                mb: 3,
              }}
            >
              <H3
                sx={{
                  fontSize: 11,
                  lineHeight: '12px',
                  letterSpacing: `-0.14`,
                  fontWeight: '500',
                }}
              >
                {intl.formatMessage({
                  id: 'SmsOtp.PhoneNumber',
                  defaultMessage: 'Phone number',
                })}
              </H3>
              <P sx={{ mb: 0 }}>
                <b>+1</b> *** *** {state.anonymousUser.phoneNumberExcerpt}
              </P>
            </div>
          ) : (
            <Skeleton variant="rect" height={58} sx={{ mb: 3 }} />
          )}

          {state.anonymousUser.phoneNumberExcerpt ? (
            <Button
              trackName="Request SMS-OTP"
              type="button"
              onClick={() => {
                dispatch(smsOtpActions.requestForgotPassword());
                setSmsRequestLoading(true);
              }}
            >
              {smsRequestLoading ? (
                <SpinnerCircularProgress variant="large" />
              ) : (
                intl.formatMessage({
                  id: 'Global.Button.Continue',
                  defaultMessage: 'Continue',
                })
              )}
            </Button>
          ) : (
            <Skeleton variant="rect" height={54} />
          )}
        </div>
      );
    case 'enter':
      return (
        <form onSubmit={(e) => e.preventDefault()}>
          <H2>
            {intl.formatMessage({
              id: 'SmsOtp.Enter.Title',
              defaultMessage: 'Enter your code',
            })}
          </H2>
          <P>
            {intl.formatMessage(
              {
                id: 'SmsOtp.Enter.Content',
                defaultMessage: `We've sent a code to your phone number ending in {boldNumber} — please enter it below. Be sure not to share this info with anyone!`,
              },
              { boldNumber: <b>{state.anonymousUser.phoneNumberExcerpt}</b> },
            )}
          </P>

          <TextInput
            label={intl.formatMessage({
              id: 'Global.Label.SmsCode',
              defaultMessage: 'SMS Code',
            })}
            onChange={onChange}
            autoFocus
            id="sms-otp"
            value={state.smsOtp.otp}
            wrapperStyle={{ mb: 3 }}
            error={!!state.smsOtp.loadable.error}
            helperText={
              state.smsOtp.loadable.error
                ? intl.formatMessage({
                    id: 'SmsOtp.Enter.Error',
                    defaultMessage:
                      'Oops! The code you entered is wrong or expired. Try again or tap resend code.',
                  })
                : ''
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {state.smsOtp.loadable.loading && (
                    <div sx={{ width: 24, height: 24, pr: 3 }}>
                      <SpinnerCircularProgress variant="large" />
                    </div>
                  )}
                </InputAdornment>
              ),
            }}
            inputProps={{ inputMode: 'numeric' }}
            sx={{
              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                ...{ WebkitAppearance: 'none', margin: 0 },
              },
            }}
          />
          <Button
            type="button"
            onClick={resendCode}
            trackName="verify SMS-OTP"
            variant="tertiary"
            disabled={!!secondsSinceSmsOtpResent}
          >
            {secondsSinceSmsOtpResent === 0
              ? intl.formatMessage({
                  id: 'SmsOtp.Resend',
                  defaultMessage: 'Resend me the code',
                })
              : intl.formatMessage(
                  {
                    id: 'SmsOtp.ResendAfterSeconds',
                    defaultMessage: `Resend code after {time} seconds`,
                  },
                  { time: secondsSinceSmsOtpResent },
                )}
          </Button>
        </form>
      );
    default:
      return null;
  }
};
