import { ReactComponent as Interest } from './interest.svg';
import { ReactComponent as Powerups } from './powerups.svg';
import { ReactComponent as Roundups } from './roundup.svg';
import { ReactComponent as Umbrella } from './umbrella.svg';

export const Features = {
  Interest,
  Powerups,
  Roundups,
  Umbrella,
};
