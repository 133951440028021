import { useIntl } from 'react-intl';

import { KDSIcons } from 'assets/images/kds_icons';
import {
  Box,
  ButtonLink,
  Divider,
  Paragraph,
  SecurelyStoredInfo,
  Template,
  TitleLarge,
} from 'components';
import { theme } from 'theme';

export const HisaRequirementsPage = () => {
  const intl = useIntl();
  return (
    <Template name="HISA Requirements" variant="center">
      <TitleLarge>
        {intl.formatMessage({
          id: 'HisaRequirementsPage.Title',
          defaultMessage: 'Just a few steps to get you set up',
        })}
      </TitleLarge>

      <Paragraph>
        {intl.formatMessage({
          id: 'HisaRequirementsPage.Description',
          defaultMessage:
            'To protect your identity, Canadian regulations require us to use the following information to verify who you are.',
        })}
      </Paragraph>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <KDSIcons.Icons.CheckmarkFilled sx={{ color: theme.colors.primary }} />
        <Paragraph sx={{ ml: 2, my: 2 }}>
          {intl.formatMessage({
            id: 'HisaRequirementsPage.Citizenship',
            defaultMessage: 'Citizenship',
          })}
        </Paragraph>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <KDSIcons.Icons.CheckmarkFilled sx={{ color: theme.colors.primary }} />
        <Paragraph sx={{ ml: 2, my: 2 }}>
          {intl.formatMessage({
            id: 'HisaRequirementsPage.EmploymentStatus',
            defaultMessage: 'Your employment status',
          })}
        </Paragraph>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <KDSIcons.Icons.CheckmarkFilled sx={{ color: theme.colors.primary }} />
        <Paragraph sx={{ ml: 2, my: 2 }}>
          {intl.formatMessage({
            id: 'HisaRequirementsPage.SIN',
            defaultMessage: 'Your Social Insurance Number',
          })}
        </Paragraph>
      </Box>

      <ButtonLink
        trackName="HISA Requirements Continue"
        sx={{ mt: 4 }}
        to="/hisa/setup"
      >
        {intl.formatMessage({
          id: 'HisaRequirementsPage.Continue',
          defaultMessage: 'Continue',
        })}
      </ButtonLink>

      <Divider />

      <SecurelyStoredInfo />
    </Template>
  );
};
