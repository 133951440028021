import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { RouteComponentProps } from '@reach/router';
import { ReactComponent as CheckmarkCircle } from 'assets/images/kds_icons/icons/checkmark.svg';
import { BoxTw } from 'components/BoxTw';
import CircularProgressTw from 'components/CircularProgressTw';
import { LinkTw } from 'components/LinkTw';
import { Paragraph, TitleLarge } from 'components/TypographyTw';
import { Box } from 'theme-ui';
import { useTrackPageViewedOnceDeprecated } from 'utility/analyticsHooks';
import { Loadable } from 'utility/loadable';

import logo from '../assets/images/logo-background.svg';
import cardCheckoutImage from '../assets/images/referrals/card-checkout.png';
import cardCheckoutImageWebp from '../assets/images/referrals/card-checkout.webp';
import referralUsersImage from '../assets/images/referrals/referral-users.png';
import referralUsersImageWebp from '../assets/images/referrals/referral-users.webp';
import { LocaleContext } from '../components';
import { LanguageToggle } from '../components/LanguageToggle';
import { SignUpWidgetTw } from './components/SignUpWidgetTw';
import { ReferralSources } from './models/Referral';
import {
  selectReferrerDataData,
  selectReferrerDataLoadable,
  selectReferrerDataSuccess,
  selectReferrerExpired,
  selectReferrerTriggerEvent,
} from './store/selectors';
import { referralActions } from './store/slice';

export const ReferralLandingPage = ({
  paramReferralCode = '',
}: {
  paramReferralCode?: string;
} & RouteComponentProps) => {
  const dispatch = useDispatch();
  const referralsExpired = useSelector(selectReferrerExpired);
  const referralsDataSuccess = useSelector(selectReferrerDataSuccess);
  const referralTriggerEvent = useSelector(selectReferrerTriggerEvent);
  const referrerDataLoadable = useSelector(selectReferrerDataLoadable);
  const referrerData = useSelector(selectReferrerDataData);
  const intl = useIntl();
  const { deepLinks, apiLanguageHeader } = useContext(LocaleContext);

  useEffect(() => {
    if (paramReferralCode) {
      dispatch(referralActions.getReferrerDataRequest(paramReferralCode));
    }
  }, [dispatch, paramReferralCode, apiLanguageHeader]);

  useEffect(() => {
    if (referralsDataSuccess && !referralsExpired && paramReferralCode) {
      dispatch(
        referralActions.setRegistrationReferralCode({
          campaign: paramReferralCode,
          source: ReferralSources.User,
        }),
      );
    }
  }, [dispatch, paramReferralCode, referralsDataSuccess, referralsExpired]);

  useTrackPageViewedOnceDeprecated({ name: 'Referrals' });

  return (
    <BoxTw>
      <Hero
        referralsExpired={referralsExpired}
        referralTriggerEvent={referralTriggerEvent}
        referrerDataLoadable={referrerDataLoadable}
        referrerName={referrerData?.referral_user?.given_name}
      />

      <Benefits />

      <BoxTw className="text-center border-t border-grey-200 py-16 bg-primary-300">
        <LinkTw
          className="inline-flex gap-4 md:gap-[75px] items-center"
          to={deepLinks.KOHO.Home}
          target="_blank"
          trackName="Referral Page - Learn more about KOHO"
          external={true}
        >
          <img
            alt={`${intl.formatMessage({
              id: 'ReferralLandingPage.KOHOLogoAltTag',
              defaultMessage: 'KOHO Logo',
            })}`}
            src={logo}
          />
          <Paragraph className="my-0 text-white font-bold">
            {intl.formatMessage({
              id: 'ReferralLandingPage.FooterLink',
              defaultMessage: 'Learn more about KOHO',
            })}
          </Paragraph>
        </LinkTw>
      </BoxTw>
    </BoxTw>
  );
};

const Hero = ({
  referralsExpired,
  referralTriggerEvent,
  referrerDataLoadable,
  referrerName,
}: {
  referralsExpired: boolean | undefined;
  referralTriggerEvent: string | undefined;
  referrerDataLoadable: Loadable;
  referrerName: string | undefined;
}) => {
  const intl = useIntl();

  const languageToggleRow = (
    <BoxTw className="p-4 md:p-8 flex justify-between">
      <BoxTw className="[&>img]:w-[85px]">
        <img
          alt={`${intl.formatMessage({
            id: 'ReferralLandingPage.KOHOLogoAltTag',
            defaultMessage: 'KOHO Logo',
          })}`}
          src={logo}
        />
      </BoxTw>
      <BoxTw className="mb-4">
        <LanguageToggle color="#FFF" />
      </BoxTw>
    </BoxTw>
  );

  return (
    <BoxTw className="bg-primary-300">
      <BoxTw className="max-w-full sm:max-w-[540px] md:max-w-[900px] lg:max-w-[1140px] mx-auto">
        {languageToggleRow}
        <BoxTw className="px-[30px] sm:px-4 pt-2 md:pt-4 lg:pt-8 pb-8 md:pb-16">
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={6}
              className="order-2 lg:order-1 pt-0 md:pt-[auto]"
            >
              <BoxTw className="lg:w-[90%]">
                <TitleLarge className="legacy:text-white light:text-white legacy:text-[32px] light:text-[32px] legacy:md:text-[52px] light:md:text-[52px] tracking-[-0.5px] leading-[1.1] mb-1 mt-0">
                  {intl.formatMessage({
                    id: 'ReferralLandingPage.Title',
                    defaultMessage: `Join KOHO with a friend for a double reward!`,
                  })}
                </TitleLarge>
                {!referrerDataLoadable?.success &&
                !referrerDataLoadable?.error ? (
                  <BoxTw className="text-center mb-6">
                    <CircularProgressTw className="text-white" />
                  </BoxTw>
                ) : null}
                {referrerDataLoadable?.error || referralsExpired ? (
                  <BoxTw>
                    <Paragraph className="text-white font-bold">
                      {intl.formatMessage({
                        id: 'ReferralLandingPage.Paragraph.ExpiredCode',
                        defaultMessage:
                          'This referral code has changed or expired. Ask your pal for a new one and use it during sign-up.',
                      })}
                    </Paragraph>
                    <Paragraph className="text-white font-bold mb-6">
                      {intl.formatMessage({
                        id: 'ReferralLandingPage.Paragraph.DownloadApp',
                        defaultMessage:
                          'Meanwhile, you can still download the app below!',
                      })}
                    </Paragraph>
                  </BoxTw>
                ) : null}
                {referrerDataLoadable?.success && !referralsExpired ? (
                  referralTriggerEvent === 'loads.direct' ? (
                    <BoxTw className="mb-6">
                      <Paragraph className="text-white font-bold">
                        {intl.formatMessage(
                          {
                            id: 'ReferralLandingPage.SuperReferral',
                            defaultMessage: `Use {name}'s referral code when you sign up for KOHO and add $50 or more to your account to get 4 free months of KOHO Everything plan benefits. That's a $76 saving!`,
                          },
                          {
                            name: referrerName,
                          },
                        )}
                      </Paragraph>
                    </BoxTw>
                  ) : (
                    <BoxTw className="mb-6">
                      <Paragraph className="text-white font-bold">
                        {intl.formatMessage(
                          {
                            id: 'ReferralLandingPage.Paragraph.Promotion',
                            defaultMessage: `Use {name}'s referral code when you sign up for a KOHO account and make a purchase within 30 days to get $20 or three free months of our Extra plan (that’s a $27 saving!)`,
                          },
                          {
                            name: referrerName,
                          },
                        )}
                      </Paragraph>

                      <Paragraph className="text-white font-bold">
                        {intl.formatMessage({
                          id: 'ReferralLandingPage.Paragraph.PromotionOption',
                          defaultMessage: `Take your pick!`,
                        })}
                      </Paragraph>
                    </BoxTw>
                  )
                ) : null}

                <SignUpWidgetTw
                  className="text-center md:text-left"
                  btnStyles="bg-white hover:bg-white text-primary-300 px-auto md:px-16 w-full md:w-auto"
                  osDefault="web"
                />
              </BoxTw>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className="text-center md:text-left order-1 md:order-2 pb-0 md:pb-[auto] mb-8 md:mb-0"
            >
              <BoxTw className="rounded-[32px] overflow-hidden mx-auto shadow-[0,12px,24px,rgba(21,45,64,0.1)] max-w-full md:max-w-[530px] [&>picture]:flex [&>picture>img]:object-cover [&>picture>img]:w-full">
                <picture>
                  <source srcSet={referralUsersImageWebp} />
                  <img
                    src={referralUsersImage}
                    alt={`${intl.formatMessage({
                      id: 'ReferralLandingPage.ReferralUsersImageAltTag',
                      defaultMessage: 'Referral Users',
                    })}`}
                  />
                </picture>
              </BoxTw>
            </Grid>
          </Grid>
        </BoxTw>
      </BoxTw>
    </BoxTw>
  );
};

const Benefits = () => {
  const intl = useIntl();

  const benefits = [
    intl.formatMessage({
      id: 'ReferralLandingPage.Benefit1',
      defaultMessage: `Get a virtual Mastercard for instant spending`,
    }),
    intl.formatMessage({
      id: 'ReferralLandingPage.Benefit2',
      defaultMessage: `Earn cash back and high interest`,
    }),
    intl.formatMessage({
      id: 'ReferralLandingPage.Benefit3',
      defaultMessage: `Build your credit history`,
    }),
    intl.formatMessage({
      id: 'ReferralLandingPage.Benefit4',
      defaultMessage: `Save more with clever saving features in the app`,
    }),
  ];

  return (
    <BoxTw className="max-w-full sm:max-w-[540px] md:max-w-[900px] lg:max-w-[1140px] mx-auto">
      <BoxTw className="px-[30px] sm:px-4 py-16 md:py-32">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} className="pb-0 md:pb-auto">
            <Box className="rounded-[32px] overflow-hidden mx-auto max-w-full md:max-w-[530px] [&>picture]:flex [&>picture>img]:object-cover [&>picture>img]:w-full">
              <picture>
                <source srcSet={cardCheckoutImageWebp} />
                <img
                  src={cardCheckoutImage}
                  alt={`${intl.formatMessage({
                    id: 'ReferralLandingPage.CardCheckoutImageAltTag',
                    defaultMessage: 'Card Checkout',
                  })}`}
                />
              </picture>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="pt-0 flex items-center">
            <BoxTw className="w-auto">
              <TitleLarge className="leading-[1.1] mt-0 mb-6 legacy:text-[32px] light:text-[32px] legacy:md:text-[52px] light:md:text-[52px] tracking-[-0.5px]">
                {intl.formatMessage({
                  id: 'ReferralLandingPage.WhatIsKOHOTitle',
                  defaultMessage: `Wait, what exactly is KOHO?`,
                })}
              </TitleLarge>
              <Paragraph className="mb-4 md:mb-8">
                {intl.formatMessage({
                  id: 'ReferralLandingPage.WhatIsKOHOSubtitle',
                  defaultMessage: `KOHO is your everyday account and money management app.`,
                })}
              </Paragraph>
              {benefits.map((benefit, i) => (
                <BoxTw
                  key={`referral-page-benefit-${i}`}
                  className="flex gap-4 md:gap-7 [&:not(:last-of-type)]:mb-4"
                >
                  <BoxTw>
                    <CheckmarkCircle className="text-primary-300 w-6 md:w-8 h-6 md:h-8" />
                  </BoxTw>
                  <BoxTw className="mt-1">
                    <Paragraph className="my-0 font-medium">
                      {benefit}
                    </Paragraph>
                  </BoxTw>
                </BoxTw>
              ))}
            </BoxTw>
          </Grid>
        </Grid>
      </BoxTw>
    </BoxTw>
  );
};
