/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import { TextInput, TextInputProps, Validators } from '../../components/forms';

export const TextInputEmail = (props: TextInputProps) => {
  const intl = useIntl();

  return (
    <TextInput
      label={intl.formatMessage({
        id: 'TextInputEmail.Label',
        defaultMessage: 'Email address',
      })}
      name="email"
      sx={{ mb: 4 }}
      type="email"
      validators={[Validators.required, Validators.email]}
      {...props}
    />
  );
};
