import { InputValidationMsg } from 'components/forms/useValidationMsg';
import { z } from 'zod';

export const zodFormRegistrationIdentityExisting = z.object({
  email: z.string(),
  'current-password': z
    .string()
    .min(1, { message: InputValidationMsg.FieldRequired }),
});

export type IdentityExistingFormSchema = z.infer<
  typeof zodFormRegistrationIdentityExisting
>;
