import axios from 'axios';
import { UAParser } from 'ua-parser-js';

import { track } from '../analytics/analytics';

export interface FingerprintsData {
  app_version: string | undefined;
  event_type: FingerprintEventTypes;
  is_virtual: boolean;
  language: string;
  /**
   * Login Type: The login type used for this fingerprint. This will be empty during registration.
   */
  login_type?: string;
  manufacturer: string | undefined;
  /**
   * 2SV Option: The current MFA option for this fingerprint. This will be empty during registration.
   */
  mfa_option?: string;
  model: string | undefined;
  os_version: string | undefined;
  platform: string;
  screen_height: string;
  screen_width: string;
  serial: string;
  uuid: string;
}

export enum FingerprintEventTypes {
  UserRegistered = 'UserRegistered',
  LoggedIn = 'LoggedIn',
}

export const createFingerprints = (
  uuid: string,
  eventType: FingerprintEventTypes,
  loginType: string = '',
  mfaOption: string = '',
) => {
  const userAgent = new UAParser();

  const data: FingerprintsData = {
    app_version: import.meta.env.VITE_APP_VERSION_HEADER,
    event_type: eventType,
    is_virtual: false,
    language:
      window.navigator.language || (window.navigator as any).userLanguage,
    login_type: loginType,
    manufacturer: userAgent.getOS().name,
    mfa_option: mfaOption,
    model: userAgent.getBrowser().name,
    os_version: userAgent.getBrowser().version,
    platform: 'web',
    screen_height: window.innerWidth.toString(),
    screen_width: window.innerHeight.toString(),
    serial: '',
    uuid: uuid,
  };

  // TODO: look into why this is not getting set in App.tsx for this case
  axios.defaults.headers.common['x-koho-app-version'] =
    import.meta.env.VITE_APP_VERSION_HEADER;

  axios
    .post(`${import.meta.env.VITE_GATEWAY_API}fingerprints`, {
      fingerprint: data,
    })
    .then((res) => {
      track({ event: 'Fingerprint Successful' });
    })
    .catch((err) => {
      track({ event: 'Fingerprint Errored', properties: { message: err } });
    });
};
