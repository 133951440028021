import { rest } from 'msw';

const userHandlers = [
  rest.get(/\/user\/past_transactions/, (req, res, ctx) => {
    return res(
      ctx.json({
        last_timestamp: '2021-10-20T19:49:12Z',
        results_count: 1,
        transactions: [
          {
            identifier: 'mocked-transaction-identifier',
            activityType: 'AD',
            postTimestamp: '2022-01-01T00:00:00Z',
            amount: '$200.00',
            details: 'Mocked - Details',
            description: 'Mocked - Description',
            sourceId: '11111',
            when: '2022-01-01T00:00:00Z',
            transactionCode: 'ADia',
            extraDetails: 'ADJUSTMENT',
            category: {
              name: 'Income',
              guid: 'mocked-category-guid',
              parent_category: null,
              icon_url: 'https://assets.koho.ca/icons/categories/v4/income.svg',
              editable: false,
            },
            merchant: {
              mcc: 0,
              merchant_id: '',
              name: 'mocked-merchant-name',
              location: '',
              logo: null,
              normalized_name: '',
            },
            source: { source_type: '', source_identifier: '' },
            price_match_eligible: false,
            merchant_name: '',
            merchant_logo_url: null,
            mcc: 0,
          },
        ],
      }),
    );
  }),

  rest.post(/\/user\/access_code\/verify/, (req, res, ctx) => {
    // return res(ctx.status(200), ctx.json({ result: true }));
    // return res(ctx.status(200), ctx.json({ result: false }));
    return;
  }),
];

export default userHandlers;
