import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

import { selectNotification } from './store/selectors';
import { clearSnackbar } from './store/slice';

export const SnackbarNotification = () => {
  const dispatch = useDispatch();

  const { open, anchorOrigin, autoHideDuration, message, type } =
    useSelector(selectNotification);

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      aria-describedby="client-snackbar"
      message={!type ? message : undefined}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      {type ? (
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      ) : undefined}
    </Snackbar>
  );
};
