import { IntlShape } from 'react-intl';
import { MessageDescriptor, defineMessage } from 'react-intl';

import axios, { AxiosResponse } from 'axios';
import {
  endOfMonth,
  isAfter,
  isBefore,
  isValid,
  parseISO,
  subYears,
} from 'date-fns';

import { track } from '../../analytics/analytics';
import { formatDate } from '../../utility/formatDate';
import { userInputs } from '../../utility/validatePassword';

export const validPhoneRegex = /\([1-9]\d\d\) \d\d\d-\d\d\d\d/;
export const validNameRegex =
  /^['a-zA-Z-\\ àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/;
export const validPostalCodeRegex =
  /[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY][0-9][abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ] [0-9][abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ][0-9]/;
export const validUpperCaseRegex = /[A-Z]+/;
export const validLowerCaseRegex = /[a-z]+/;
export const replaceMaskedPhoneRegex = /( |-|\(|\))/g;

// PO Box Regex: https://regexr.com/7caod
export const validPOBoxRegex = new RegExp(
  /^\s*(.*((p|post|postal)[-./\s]*(o|off|office)[-.\s]*(b|box|bin)[-.\s]*)|.*((p|post|postal)[-./\s]*(o|off|office)[-.\s]*)|.*((p|post|postal)[-./\s]*(b|box|bin)[-.\s]*)|.*((b|c|boîte|case)[-./\s]*(p|postale)[-.\s]*)|(box|bin|boîte)[-.\s]*)(:|#|n|no|num|number)?\s*\d+/,
  'i',
);

export const errorsMessages = {
  required: defineMessage({
    id: 'Validators.ErrorMessage.RequiredInfo',
    defaultMessage: 'This information is required.',
  }),
  alphaOnly: defineMessage({
    id: 'Validators.ErrorMessage.alphaOnly',
    defaultMessage:
      'Please avoid using spaces or special characters (&, ?, â, ê, etc.).',
  }),
  alphaWithWhitespace: defineMessage({
    id: 'Validators.ErrorMessage.alphaWithWhitespace',
    defaultMessage:
      'Please avoid using numbers or special characters (&, ?, â, ê, etc.).',
  }),
  email: defineMessage({
    id: 'Validators.ErrorMessage.Email',
    defaultMessage: 'Enter a valid email address.',
  }),
  minlength: (n: string, intl) =>
    intl.formatMessage(
      {
        id: 'Validators.ErrorMessage.MinLength',
        defaultMessage: 'Please enter a minimum of {n} characters.',
      },
      {
        n,
      },
    ),
  maxlength: (n: string, intl) =>
    intl.formatMessage(
      {
        id: 'Validators.ErrorMessage.MaxLength',
        defaultMessage:
          'Maximum number of characters reached. Please enter less than {n} characters.',
      },
      {
        n,
      },
    ),
  min: (n: string, intl) =>
    intl.formatMessage(
      {
        id: 'Validators.ErrorMessage.Min',
        defaultMessage: 'Must be at least {n}.',
      },
      {
        n,
      },
    ),
  max: (n: string, intl) =>
    intl.formatMessage(
      {
        id: 'Validators.ErrorMessage.Max',
        defaultMessage: 'Must be at most {n}.',
      },
      {
        n,
      },
    ),
  upperCase: defineMessage({
    id: 'Validators.ErrorMessage.UpperCase',
    defaultMessage: 'Please include an uppercase character.',
  }),
  lowerCase: defineMessage({
    id: 'Validators.ErrorMessage.LowerCase',
    defaultMessage: 'Please include a lowercase character.',
  }),
  emailAlreadyExists: defineMessage({
    id: 'Validators.ErrorMessage.EmailExists',
    defaultMessage: 'That email already exists.',
  }),
  referralCode: defineMessage({
    id: 'Validators.ErrorMessage.ReferralCode',
    defaultMessage: `That code doesn't exist.`,
  }),
  referralCodeDisabled: defineMessage({
    id: 'Validators.ErrorMessage.ReferralDisabled',
    defaultMessage: 'This code is no longer valid.',
  }),
  referralCodeTooMany: defineMessage({
    id: 'Validators.ErrorMessage.ReferralTooMany',
    defaultMessage: 'Too many accounts have used this code',
  }),
  alphaWithAccent: defineMessage({
    id: 'Validators.ErrorMessage.Name',
    defaultMessage: 'Enter your real name.',
  }),
  phone: defineMessage({
    id: 'Validators.ErrorMessage.Phone',
    defaultMessage: 'Enter your real mobile number.',
  }),
  date: defineMessage({
    id: 'Validators.ErrorMessage.BirthDate',
    defaultMessage: 'Enter a complete date (yyyy-mm-dd)',
  }),
  ageOfMajority: defineMessage({
    id: 'Validators.ErrorMessage.AgeOfMajority',
    defaultMessage: 'Sorry, you must be age of majority.',
  }),
  postalCode: defineMessage({
    id: 'Validators.ErrorMessage.PostalCode',
    defaultMessage: 'Enter a complete postal code.',
  }),
  password: defineMessage({
    id: 'Validators.ErrorMessage.Password',
    defaultMessage: 'Password is too weak, try different characters.',
  }),
  passwordsDoNotMatch: defineMessage({
    id: 'Validators.ErrorMessage.PasswordsDoNotMatch',
    defaultMessage: 'Your passwords do not match',
  }),
  number: defineMessage({
    id: 'Validators.ErrorMessage.Number',
    defaultMessage: 'Enter a valid number.',
  }),
  expiryDate: defineMessage({
    id: 'Validators.ErrorMessage.ExpiryDate',
    defaultMessage: 'Enter a valid expiry.',
  }),
  somethingWrong: defineMessage({
    id: 'Validators.ErrorMessage.SomethingWrong',
    defaultMessage: 'Something went wrong.',
  }),
  secretAnswer: defineMessage({
    id: 'Validators.ErrorMessage.SecretAnswer',
    defaultMessage:
      'Please avoid using spaces or special characters (&, ?, â, ê, etc.).',
  }),
  secretQuestion: defineMessage({
    id: 'Validators.ErrorMessage.SecretQuestion',
    defaultMessage: 'Please avoid using special characters (&, ?, â, ê, etc.).',
  }),
};

export const validationFunctions: {
  [key: string]: (
    key: string,
    min: string,
    intl?: IntlShape,
  ) => string | MessageDescriptor | null;
} = {
  required: (value: string) => (value ? null : errorsMessages.required),
  email: (value: string) =>
    /\S+@\S+\.\S+/.test(value) ? null : errorsMessages.email,

  minlength: (value: string, min: string, intl) => {
    if (value && value.length >= Number(min)) {
      return null;
    }
    return errorsMessages.minlength(min, intl);
  },
  maxlength: (value: string, max: string, intl) => {
    if (value && value.length <= Number(max)) {
      return null;
    }
    return errorsMessages.maxlength(max, intl);
  },
};

export abstract class Validators {
  public static required: ValidationFn = (value: string) => {
    return value ? null : errorsMessages.required;
  };

  public static alphaWithWhitespace: ValidationFn = (value: string) => {
    return /^[a-zA-Z ]+$/.test(value)
      ? null
      : errorsMessages.alphaWithWhitespace;
  };

  public static alphaOnly: ValidationFn = (value: string) => {
    return /^[a-zA-Z]*$/.test(value) ? null : errorsMessages.alphaOnly;
  };

  public static passwordMatch: (toMatch: string) => ValidationFn =
    (toMatch) => (value: string) => {
      return value === toMatch ? null : errorsMessages.passwordsDoNotMatch;
    };

  public static email: ValidationFn = (value: string) => {
    return /\S+@\S+\.\S+/.test(value) ? null : errorsMessages.email;
  };

  public static phone: ValidationFn = (value: string) => {
    return validPhoneRegex.test(value) ? null : errorsMessages.phone;
  };

  public static alphaWithAccent: ValidationFn = (value: string) => {
    return validNameRegex.test(value) ? null : errorsMessages.alphaWithAccent;
  };

  public static postalCode: ValidationFn = (value: string) => {
    return validPostalCodeRegex.test(value) ? null : errorsMessages.postalCode;
  };

  public static minLength: (n: number, intl: IntlShape) => ValidationFn =
    (n, intl) => (value: string) => {
      if (value && value.length >= n) {
        return null;
      }
      return errorsMessages.minlength(String(n), intl);
    };

  public static maxLength: (n: number, intl: IntlShape) => ValidationFn =
    (n, intl) => (value: string) => {
      if (value && value.length <= n) {
        return null;
      }
      return errorsMessages.maxlength(String(n), intl);
    };

  public static min: (
    n: number,
    intl: IntlShape,
    customMessage?: string,
  ) => ValidationFn = (n, intl, customMessage) => (value: number) => {
    if (value && value >= n) {
      return null;
    }
    return customMessage || errorsMessages.min(String(n), intl);
  };

  public static max: (
    n: number,
    intl: IntlShape,
    customMessage?: string,
  ) => ValidationFn = (n, intl, customMessage) => (value: number) => {
    if (value && value <= n) {
      return null;
    }
    return customMessage || errorsMessages.max(String(n), intl);
  };

  public static date: ValidationFn = (value: string) => {
    return isValid(parseISO(value)) ? null : errorsMessages.date;
  };

  public static ageOfMajority: ValidationFn = (value: string) => {
    const date = new Date(formatDate(value));
    if (!isValid(date)) {
      return null;
    }
    const ageOfMajorityDoB = subYears(new Date(), 18);
    const ageOfOldestDoB = subYears(new Date(), 119);

    return isAfter(date, ageOfMajorityDoB) || isBefore(date, ageOfOldestDoB)
      ? errorsMessages.ageOfMajority
      : null;
  };

  public static cardNumber: ValidationFn = (value: string) => {
    return /^\d{16}$/.test(value) ? null : errorsMessages.number;
  };

  public static expiryDate: ValidationFn = (value: string) => {
    const [mm, yy] = value.split('/');
    const date = endOfMonth(new Date(`20${yy}/${mm}/01`));
    if (!isValid(date) || isBefore(date, new Date())) {
      return errorsMessages.expiryDate;
    }
    return null;
  };

  public static cvv: ValidationFn = (value: string) => {
    return /^\d{3}$/.test(value) ? null : errorsMessages.number;
  };

  public static secretQuestion: ValidationFn = (value: string) => {
    return /^[ a-zA-Z0-9.!@/;:,=$?*()]+$/.test(value)
      ? null
      : errorsMessages.secretQuestion;
  };

  public static secretAnswer: ValidationFn = (value: string) => {
    return /^[a-zA-Z0-9-]*$/.test(value) ? null : errorsMessages.secretAnswer;
  };

  public static passwordStrength: ValidationFn = (value: string) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~-]).*$/.test(
      value,
    )
      ? null
      : errorsMessages.password;
  };

  public static upperCase: ValidationFn = (value: string) => {
    return validUpperCaseRegex.test(value) ? null : errorsMessages.upperCase;
  };

  public static lowerCase: ValidationFn = (value: string) => {
    return validLowerCaseRegex.test(value) ? null : errorsMessages.lowerCase;
  };
}

export abstract class AsyncValidators {
  public static password: AsyncValidationFn = (value: string) => {
    return import('zxcvbn').then((zxcvbn) => {
      const result = zxcvbn.default(value, userInputs);
      return result.score < 1 ? errorsMessages.password : null;
    });
  };

  public static checkEmail: AsyncValidationFn = (value: string) =>
    new Promise((resolve) => {
      if (!value || !/\S+@\S+\.\S+/.test(value)) {
        resolve(null);
      } else {
        axios
          .post(`${import.meta.env.VITE_GATEWAY_API}email`, {
            email: value,
          })
          .then((res) => {
            !res.data.taken
              ? resolve(null)
              : resolve(errorsMessages.emailAlreadyExists);
          })
          .catch((error) => {
            track({
              event: 'Email Network Errored',
              properties: { error: error.toString() },
            });
            resolve(errorsMessages.somethingWrong);
          });
      }
    });

  public static referralCode: AsyncValidationFn = (value: string) =>
    new Promise((resolve) => {
      if (!value) {
        resolve(null);
      } else if (value.length < 5) {
        resolve(errorsMessages.referralCode);
      } else {
        axios
          .get(
            `${import.meta.env.VITE_GATEWAY_API}referrals/code/verify/` + value,
          )
          .then(
            (
              res: AxiosResponse<{
                invalid_reason?: 'exhausted' | 'disabled';
                type: 'partner' | 'referral';
              }>,
            ) => {
              if (res.data.invalid_reason === 'exhausted') {
                resolve(errorsMessages.referralCodeTooMany);
              } else if (res.data.invalid_reason === 'disabled') {
                resolve(errorsMessages.referralCodeDisabled);
              } else {
                resolve(null);
              }
            },
          )
          .catch(() => resolve(errorsMessages.referralCode));
      }
    });
}

export type ValidationFn = (params?: any) => MessageDescriptor | string | null;
export type AsyncValidationFn = (
  params?: any,
) => Promise<MessageDescriptor | null>;
