import { rootApi } from 'apis/rootApi';

type Plan = {
  id: string;
  amount: string;
  fee: string;
  plan_amount?: string;
};

type OverdraftBundle = {
  id: string;
  status: 'active' | 'inactive' | 'pending_cancel';
  current_plan: Plan | null;
  eligible_plan: Plan;
  eligible_plans: Plan[] | null;
  accepted_plan: Plan | null;
  initial_plan: Plan;
};

const coverApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getCover: build.query<OverdraftBundle, void>({
      query: () => ({
        url: `1.0/overdraft`,
        method: 'GET',
      }),
      providesTags: ['Cover'],
    }),
    openCover: build.mutation<unknown, { plan_id: string }>({
      query: ({ plan_id }) => ({
        url: `1.0/overdraft`,
        method: 'POST',
        body: { plan_id },
      }),
      invalidatesTags: ['Cover'],
    }),
  }),
});

export const { useGetCoverQuery, useOpenCoverMutation } = coverApi;
