import { useEffect } from 'react';

import { useNavigate } from '@reach/router';
import { PrivateRoute } from 'auth/components/PrivateRoute';
import { HISAFeatureFlags } from 'hisa/hisaFeatureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const HisaReleaseRoute = ({ component, path }) => {
  const isHisaReleased = useFlags()[HISAFeatureFlags.EnableOnboardingFlow];
  const navigate = useNavigate();

  useEffect(() => {
    if (isHisaReleased === false) {
      navigate('/transactions');
    }
  }, [navigate, isHisaReleased]);

  return <PrivateRoute component={component} path={path} />;
};
