/** @jsxImportSource theme-ui */
import { Fragment, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Box, IconButton } from '@material-ui/core';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import LockOutlined from '@material-ui/icons/LockOutlined';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import { navigate } from '@reach/router';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as Sentry from '@sentry/browser';
import { useGetCardImageQuery, useGetCardsQuery } from 'apis/cards';
import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import { usePagePermissions } from 'auth/hooks/usePagePermissions';
import { ButtonTw } from 'components/ButtonTw';
import ButtonUnstyledTw from 'components/ButtonUnstyledTw';
import CircularProgressTw from 'components/CircularProgressTw';
import { NumberExtraLarge, Paragraph } from 'components/TypographyTw';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { twMerge } from 'tailwind-merge';
import { Flex } from 'theme-ui';
import { TxFeatureFlags } from 'transactions/transactions-feature-flags';

import { selectAccountPersonalAccountGroupId } from '../../accounts/store/selectors';
import { track } from '../../analytics/analytics';
import {
  selectBalanceIsLoading,
  selectBalancePersonalSpendable,
} from '../../balance/store/selectors';
import { balanceActions } from '../../balance/store/slice';
import { cardsActions } from '../../cards/store/slice';
import { LocaleContext } from '../../components';
import { Circle } from '../../components/icons/';
import { DeviceVerificationModal } from '../../deviceVerification/components';
import { selectDeviceVerificationOpen } from '../../deviceVerification/store/selectors';
import { deviceVerificationActions } from '../../deviceVerification/store/slice';

export const VirtualCardWithBalance = (
  props: Readonly<{ showBalance: Function }>,
) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const context = useContext(LocaleContext);
  const themeName = useSelector(selectTheme);
  const isLight = themeName === 'light';

  const personalSpendableBalance = useSelector(selectBalancePersonalSpendable);
  const isBalanceLoading = useSelector(selectBalanceIsLoading);

  const { data: cardsResponse, isLoading: getCardsIsLoading } =
    useGetCardsQuery();

  const virtualCard = cardsResponse?.find((card) => card.is_virtual);

  const {
    data: cardImageResponse,
    isUninitialized: cardImageUninitialized,
    isLoading: cardImageLoading,
    error: cardImageError,
  } = useGetCardImageQuery(virtualCard?.card_id ?? skipToken);

  const [cardImageUrl, setCardImageUrl] = useState<string | null>(null);

  const personalAccountIdentifier = useSelector(
    selectAccountPersonalAccountGroupId,
  );

  const deviceVerificationOpen = useSelector(selectDeviceVerificationOpen);

  const isVirtualCardLocked = virtualCard?.is_locked ?? false;

  const pagePermissions = usePagePermissions();
  const [primaryCardImage, setPrimaryCardImage] = useState('');

  useEffect(() => {
    if (cardsResponse) {
      const primaryCard = cardsResponse.find(
        (card) => card.account_type === 'primary',
      );
      if (primaryCard) {
        setPrimaryCardImage(primaryCard.image_url);
      } else {
        setPrimaryCardImage(cardsResponse[0].image_url);
      }
    }
  }, [cardsResponse, setPrimaryCardImage]);

  useEffect(() => {
    if (personalAccountIdentifier) {
      dispatch(balanceActions.getBalanceRequest());
    }
  }, [dispatch, personalAccountIdentifier, context.apiLanguageHeader]);

  useEffect(() => {
    if (deviceVerificationOpen) {
      track({ event: 'Card Device Verification Opened' });
    }
  }, [deviceVerificationOpen]);

  const toggleCardNumber = () => {
    if (cardImageError) {
      if ('status' in cardImageError && cardImageError.status === 403) {
        dispatch(deviceVerificationActions.openDeviceVerification());
      } else {
        // indicates a runtime error eg. TypeError
        Sentry.captureException(cardImageError);
      }
    } else {
      if (!cardImageUrl) {
        track({ event: 'Card Number Show Clicked' });
        setCardImageUrl(cardImageResponse || null);
      } else {
        track({ event: 'Card Number Hide Clicked' });
        setCardImageUrl(null);
      }
    }
  };

  const toggleCardUsability = () => {
    const isLocked = !isVirtualCardLocked;
    const lockedMessage = intl.formatMessage({
      id: 'VirtualCardWithBalance.LockCard.Locked',
      defaultMessage: 'Card has been locked',
    });
    const unlockedMessage = intl.formatMessage({
      id: 'VirtualCardWithBalance.LockCard.Unlocked',
      defaultMessage: 'Card has been unlocked',
    });

    dispatch(
      cardsActions.changeCardUsabilityRequest({
        cardId: virtualCard?.card_id ?? '',
        isLocked,
        successMessage: isLocked ? lockedMessage : unlockedMessage,
      }),
    );
  };

  const handleMyCards = () => {
    if (cardImageError) {
      if ('status' in cardImageError && cardImageError.status === 403) {
        dispatch(deviceVerificationActions.openDeviceVerification());
      } else {
        // indicates a runtime error eg. TypeError
        Sentry.captureException(cardImageError);
      }
    } else {
      navigate('/cards');
    }
  };

  const viewCardAriaLabel = !cardImageResponse
    ? intl.formatMessage({
        id: 'VirtualCardWithBalance.CardPrivacy.Show',
        defaultMessage: 'Show virtual card',
      })
    : intl.formatMessage({
        id: 'VirtualCardWithBalance.CardPrivacy.Hide',
        defaultMessage: 'Hide virtual card',
      });

  const lockCardAriaLabel = isVirtualCardLocked
    ? intl.formatMessage({
        id: 'VirtualCardWithBalance.LockCard.Unlock',
        defaultMessage: 'Unlock card',
      })
    : intl.formatMessage({
        id: 'VirtualCardWithBalance.LockCard.Lock',
        defaultMessage: 'Lock card',
      });

  return (
    <Fragment>
      <div className="flex flex-col justify-center items-center">
        <div className="relative">
          <div
            className={twMerge(
              'flex items-center justify-center w-[320px] h-[202px] rounded-lg overflow-hidden max-md:w-full',
              '[&_img]:w-full [&_img]:h-full',
            )}
          >
            {getCardsIsLoading ? (
              <CircularProgressTw className="m-auto" />
            ) : (
              <>
                <img
                  src={cardImageResponse}
                  style={{ display: cardImageUrl ? 'block' : 'none' }}
                  alt={intl.formatMessage({
                    id: 'VirtualCardWithBalance.VirtualCardPublic',
                    defaultMessage: 'Virtual Card With Number',
                  })}
                />

                <img
                  src={primaryCardImage}
                  style={{ display: cardImageUrl ? 'none' : 'block' }}
                  alt={intl.formatMessage({
                    id: 'VirtualCardWithBalance.VirtualCardPrivate',
                    defaultMessage: 'Virtual Card',
                  })}
                />
              </>
            )}
          </div>

          {!pagePermissions.canSeeMyCardsPage && (
            <div
              className={twMerge(
                'flex flex-col items-center justify-center absolute right-[-78px] gap-2 bottom-4',
                'max-md:right-0 max-md:top-2 max-md:flex-row max-md:relative max-md:mt-2',
              )}
            >
              <RoundButton
                onClick={toggleCardNumber}
                label={viewCardAriaLabel}
                hideLabel={true}
              >
                {!cardImageUrl ? (
                  <VisibilityOutlined className="text-white" />
                ) : (
                  <VisibilityOffOutlined className="text-white" />
                )}
              </RoundButton>
              <RoundButton
                onClick={toggleCardUsability}
                label={lockCardAriaLabel}
                hideLabel={true}
              >
                {isVirtualCardLocked ? (
                  <LockOutlined className="text-white" />
                ) : (
                  <LockOpenOutlined className="text-white" />
                )}
              </RoundButton>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center mt-3 xl:mt-6 legacy:xl:mt-0">
        <Paragraph className="legacy:font-bold legacy:mb-0 font-medium leading-normal mb-2">
          {isLight
            ? intl.formatMessage({
                id: 'VirtualCardWithBalance.SpendableBalance',
                defaultMessage: 'Spendable balance',
              })
            : intl.formatMessage({
                id: 'VirtualCardWithBalance.Balance',
                defaultMessage: 'Balance',
              })}
        </Paragraph>

        {isBalanceLoading ? (
          <Box display="flex">
            <NumberExtraLarge>&nbsp;</NumberExtraLarge>
            <CircularProgressTw />
          </Box>
        ) : (
          <BalanceIndicator
            balance={personalSpendableBalance}
            showBalance={props.showBalance}
          />
        )}

        {!pagePermissions.canSeeMyCardsPage && (
          <ButtonTw
            className="w-[229px] md:w-[320px] mt-6"
            trackName="add-money-cta"
            onClick={() => navigate('/load')}
          >
            {intl.formatMessage({
              id: 'VirtualCardWithBalance.AddMoneyButton',
              defaultMessage: 'Add money',
            })}
          </ButtonTw>
        )}

        {pagePermissions.canSeeMyCardsPage && (
          <Flex sx={{ mt: 4, gap: 2 }}>
            <RoundButton
              light
              onClick={() => navigate('/load')}
              label={intl.formatMessage({
                id: 'VirtualCardWithBalance.AddMoneyButton',
                defaultMessage: 'Add money',
              })}
            >
              <KDSIcons.Icons.AddLegacy className="legacy:text-primary-300 text-white" />
            </RoundButton>

            <RoundButton
              light
              disabled={cardImageUninitialized || cardImageLoading}
              onClick={() => handleMyCards()}
              label={intl.formatMessage({
                id: 'VirtualCardWithBalance.MyCards.Button',
                defaultMessage: 'Cards',
              })}
            >
              <KDSIcons.Icons.CreditCard className="legacy:text-primary-300 text-white" />
            </RoundButton>
          </Flex>
        )}
      </div>

      <DeviceVerificationModal
        open={deviceVerificationOpen}
        onClose={() => {
          track({ event: 'Card Device Verification Closed' });
          dispatch(deviceVerificationActions.closeDeviceVerification());
        }}
      />
    </Fragment>
  );
};

type RoundButtonProps = {
  light?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
  label: string;
  hideLabel?: boolean;
};
const RoundButton = ({
  children,
  onClick,
  label,
  disabled = false,
  hideLabel = false,
  light,
}: Readonly<RoundButtonProps>) => {
  return (
    <ButtonUnstyledTw
      onClick={onClick}
      disabled={disabled}
      aria-label={label}
      className={twMerge(
        'min-w-[80px] flex flex-col items-center rounded-3xl',
        'hover:enabled:rounded-3xl focus:rounded-3xl disabled:opacity-40',
      )}
    >
      <Circle light={light}>{children}</Circle>
      {hideLabel || (
        <Paragraph className="my-2 legacy:font-normal legacy:text-base font-medium text-sm">
          {label}
        </Paragraph>
      )}
    </ButtonUnstyledTw>
  );
};

const BalanceIndicator = ({ balance, showBalance }) => {
  const formatMoney = useMoneyFormatter();
  const showBalanceSheet = useFlags()[TxFeatureFlags.ShowBalanceTooltip];

  if (!showBalanceSheet) {
    return (
      <NumberExtraLarge>{balance && formatMoney(balance)}</NumberExtraLarge>
    );
  }

  return (
    <div className="text-center flex relative left-[24px]">
      <NumberExtraLarge>{balance && formatMoney(balance)}</NumberExtraLarge>
      <IconButton className="ml-2 light:p-1" onClick={() => showBalance()}>
        <KDSIcons.Icons.Info />
      </IconButton>
    </div>
  );
};
