import { getDeviceID } from 'libs/axios';

export class ThreatMetrixService {
  public static readonly session_id = getDeviceID();

  public static profile(page_id: number): void {
    window.threatmetrix &&
      window.threatmetrix.profile(
        import.meta.env.VITE_THREATMETRIX_PROFILING_DOMAIN,
        import.meta.env.VITE_THREATMETRIX_ORG_ID,
        this.session_id,
        page_id,
      );
  }
}

export { TM_PAGES } from 'libs/threatmetrix/consts';

export default ThreatMetrixService;
