import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectTheme } from 'appState/appState.slice';
import { ReactComponent as LogoLight } from 'assets/images/logo-light.svg';
import { ReactComponent as LogoLegacy } from 'assets/images/logo.svg';
import { selectAuthIsAuthenticated } from 'auth/store/selectors';
import { LinkTw } from 'components/LinkTw';

export const Logo = () => {
  const intl = useIntl();
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const themeName = useSelector(selectTheme);

  const altText = intl.formatMessage({
    id: 'Header.LogoImageAltTag',
    defaultMessage: 'KOHO Logo',
  });

  const link = isAuthenticated ? '/transactions' : '/';

  const Logo = themeName === 'light' ? LogoLight : LogoLegacy;

  return (
    <LinkTw trackName="KOHO Logo" to={link}>
      <Logo
        title={altText}
        className="light:fill-primary-400 light:hover:fill-primary-300"
      />
    </LinkTw>
  );
};
