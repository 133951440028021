import { validNameRegex } from 'components/forms';
import { InputValidationMsg } from 'components/forms/useValidationMsg';
import { registrationValidators } from 'registration/utils/Validators';
import { z } from 'zod';

export const zodFormRegistrationUserInfo = z.object({
  givenName: z
    .string()
    .min(1, {
      message: InputValidationMsg.FirstNameRequired,
    })
    .regex(validNameRegex, {
      message: InputValidationMsg.InvalidName,
    })
    .min(2, {
      message: InputValidationMsg.InvalidName,
    }),
  familyName: z
    .string()
    .min(1, {
      message: InputValidationMsg.LastNameRequired,
    })
    .regex(validNameRegex, {
      message: InputValidationMsg.InvalidName,
    })
    .min(2, {
      message: InputValidationMsg.InvalidName,
    }),
  dateOfBirth: z
    .string()
    .min(1, {
      message: InputValidationMsg.ValidDobRequired,
    })
    .refine(
      (value) =>
        registrationValidators.dateOfBirthDateValidator(value) &&
        registrationValidators.dateOfBirthMaxAgeValidator(value),
      {
        message: InputValidationMsg.InvalidDate,
      },
    )
    .refine(
      (value) => registrationValidators.dateOfBirthMinAgeValidator(value),
      {
        message: InputValidationMsg.MinAgeError,
      },
    ),
});

export type UserInfoSchema = z.infer<typeof zodFormRegistrationUserInfo>;
