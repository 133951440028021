import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Button, Paragraph, Template, TitleLarge } from 'components';
import { contactActions } from 'contact/store/contact.slice';

export const HisaAddressPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Template name="Hisa Address" variant="center">
      <TitleLarge>
        {intl.formatMessage({
          id: 'HisaAddressPage.Title',
          defaultMessage: 'Update your address',
        })}
      </TitleLarge>

      <Paragraph>
        {intl.formatMessage({
          id: 'HisaAddressPage.Description',
          defaultMessage:
            'We noticed a discrepency with your home address that is preventing us from continuing with your interest account registration.',
        })}
      </Paragraph>
      <Paragraph>
        {intl.formatMessage({
          id: 'HisaAddressPage.ContactSupport',
          defaultMessage: 'Please reach out to our support team to continue.',
        })}
      </Paragraph>

      <Button
        sx={{ mt: 4 }}
        trackName="HISAAdress Continue"
        onClick={() => dispatch(contactActions.intercomOpen())}
      >
        {intl.formatMessage({
          id: 'HisaAddressPage.Continue',
          defaultMessage: 'Continue with Support',
        })}
      </Button>
    </Template>
  );
};
