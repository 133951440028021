import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { AccountsAllAccount } from '../models';

export const selectAccountsState = (state: RootState) => state.accounts;

export const selectPostAccountState = createSelector(
  selectAccountsState,
  (state) => state?.postAccount,
);

export const selectPostAccountLoadable = createSelector(
  selectPostAccountState,
  (postAccount) => postAccount.loadable,
);

export const selectPostAccountError = createSelector(
  selectPostAccountLoadable,
  (state) => state.error,
);

export const selectAccountsAll = createSelector(
  selectAccountsState,
  (state) => state?.accountsAll,
);

export const selectHasRetrievedAccounts = createSelector(
  selectAccountsAll,
  (accountsAll) => {
    return !!accountsAll.accounts;
  },
);

export const selectHasAccount = createSelector(
  selectAccountsAll,
  (accountsAll) => {
    return accountsAll.accounts && accountsAll.accounts.length > 0;
  },
);

export const selectAccountsAllPersonalAccount = createSelector(
  selectAccountsAll,
  (data) =>
    data &&
    data.accounts &&
    data.accounts.find(
      (account: AccountsAllAccount) => account.category === 'personal',
    ),
);

export const selectAccountPersonalAccountGroupId = createSelector(
  selectAccountsAllPersonalAccount,
  (personalAccount) => personalAccount && personalAccount.group_id,
);

export const selectAccountsAllLoadable = createSelector(
  selectAccountsAll,
  (accountsAll) => accountsAll?.loadable,
);

export const selectAccountsAllIsLoading = createSelector(
  selectAccountsAllLoadable,
  (loadable) => loadable?.loading,
);

export const selectAccountsAllIsLoaded = createSelector(
  selectAccountsAllLoadable,
  (loadable) => loadable?.success,
);
