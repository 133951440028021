/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectAuthIsSessionRefreshError } from 'auth/store/selectors';
import { Modal, Paragraph, TitleLarge } from 'components';

import { track } from '../../analytics/analytics';
import { Button } from '../../components/Button';

export const SessionRefreshErrorModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const intl = useIntl();
  const isRefreshError = useSelector(selectAuthIsSessionRefreshError);

  useEffect(() => {
    if (isRefreshError) {
      track({
        event: 'Viewed',
        properties: { name: 'Refresh Error  Modal Logout Forced' },
      });
      setIsModalOpen(true);
    }
  }, [isRefreshError]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxWidth: '624px',
        },
      }}
    >
      <TitleLarge sx={{ mt: 0, mb: 2 }}>
        {intl.formatMessage({
          id: 'RefreshErrorModal.Title',
          defaultMessage: 'Sorry, there was an error.',
        })}
      </TitleLarge>

      <Paragraph>
        {intl.formatMessage({
          id: 'RefreshErrorModal.Content',
          defaultMessage: `Something went wrong. You've been logged out. Log back in to continue using KOHO.`,
        })}
      </Paragraph>
      <Button
        onClick={handleClose}
        trackName="Refresh Error Modal Logout Forced Acknowledged"
      >
        {intl.formatMessage({
          id: 'Global.Button.OkayGotIt',
          defaultMessage: 'Okay, got it',
        })}
      </Button>
    </Modal>
  );
};
