/** @jsxImportSource theme-ui */
import { Box, BoxProps } from '@material-ui/core';
import { theme } from 'theme';

export const RequestMoneyEmojiBackground = ({
  emoji,
  ...props
}: BoxProps & {
  emoji?: string;
}) => {
  if (!emoji) {
    emoji = '💸';
  }

  const rows = [...Array(20).keys()];
  const cols = [...Array(10).keys()];
  return (
    <Box
      sx={{
        background: theme.colors.primary,
        borderRadius: '8px',
        overflow: 'hidden',
        maxHeight: '300px',
        pointerEvents: 'none',
        userSelect: 'none',
      }}
      aria-label="hidden"
      {...props}
    >
      {rows.map((row) => (
        <div
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pointerEvents: 'none',
            opacity: 0.7,
          }}
          key={row}
        >
          {cols.map((col) => (
            <div
              sx={{
                position: 'relative',
                lineHeight: '40px',
                fontSize: '20px',
                left: row % 2 === 0 && '26px',
                mr: '30px',
              }}
              key={col}
            >
              {emoji}
            </div>
          ))}
        </div>
      ))}
    </Box>
  );
};
