import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectSmsOtpState = (state: RootState) => state.smsOtp;

export const selectSmsOtp = createSelector(
  selectSmsOtpState,
  (state) => state?.otp,
);

export const selectSmsOtpToken = createSelector(
  selectSmsOtpState,
  (state) => state?.token,
);

export const selectSmsOtpLoadable = createSelector(
  selectSmsOtpState,
  (state) => state?.loadable,
);

export const selectSmsOtpVerifyError = createSelector(
  selectSmsOtpLoadable,
  (state) => state?.error,
);
