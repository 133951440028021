import {
  Dispatch,
  MouseEvent,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeOutlined from '@material-ui/icons/NavigateBeforeOutlined';
import { selectTheme } from 'appState/appState.slice';
import clsx from 'clsx';
import { TitleLarge } from 'components/TypographyTw';
// types
import type { Image } from 'types/Image';

export interface ModalProps extends Omit<DialogProps, 'css'> {
  open: boolean;
  title?: string;
  icon?: ReactElement;
  image?: Image;
  closeButton?: boolean;
  closeIconProps?: Omit<SvgIconProps, 'css'>;
  backButton?: boolean;
  onClickBackButton?: () => void;
  closeIconFontSize?: 'small' | 'medium' | 'large';
  setNewSubscription?: Dispatch<SetStateAction<boolean>>;
  absolutePositioning?: boolean;
}

export const ModalHeader = ({
  closeButton = true,
  backButton = false,
  icon,
  title,
  onClose,
  onClickBackButton,
  closeIconProps,
  closeIconFontSize = 'large',
  absolutePositioning = true,
}: {
  closeButton?: boolean;
  backButton?: boolean;
  icon?: React.ReactElement;
  title?: string;
  onClose?: Extract<DialogProps['onClose'], Function>;
  onClickBackButton?: () => void;
  closeIconProps?: Omit<SvgIconProps, 'css'>;
  closeIconFontSize?: 'small' | 'medium' | 'large';
  absolutePositioning?: boolean;
}) => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);

  const isLegacyTheme = themeName === 'legacy';

  return (
    <>
      {(closeButton || backButton) && (
        <div
          className={clsx(
            'flex w-full pt-6 px-6 pb-4 items-center',
            title === undefined && absolutePositioning && 'light:absolute',
            backButton ? 'justify-between' : 'justify-end',
          )}
        >
          {backButton ? (
            <ModalButton
              onClick={(event) => {
                if (onClickBackButton) {
                  onClickBackButton();
                } else {
                  onClose?.(event, 'backdropClick');
                }
              }}
            >
              <NavigateBeforeOutlined />
              {intl.formatMessage({
                id: 'Modal.Back',
                defaultMessage: 'Back',
              })}
            </ModalButton>
          ) : null}

          {!isLegacyTheme && title && (
            <TitleLarge className="my-0 p-0">{title}</TitleLarge>
          )}
          {closeButton ? (
            <ModalButton
              onClick={(event) => onClose?.(event, 'backdropClick')}
              className="items-start ml-auto"
            >
              <CloseIcon fontSize={closeIconFontSize} {...closeIconProps} />
            </ModalButton>
          ) : null}
        </div>
      )}

      {icon && <div className="m-4">{icon}</div>}

      {isLegacyTheme && title && (
        <TitleLarge className="my-0 px-6 py-0">{title}</TitleLarge>
      )}
    </>
  );
};

export const Modal = (props: Readonly<ModalProps>) => {
  const {
    children,
    onClickBackButton,
    onClose,
    open,
    title,
    icon,
    image,
    maxWidth = 'xs',
    closeIconProps,
    closeIconFontSize,
    absolutePositioning,
    ...rest
  } = props;

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onClose={onClose}
      className="backdrop-blur-sm"
      {...rest}
    >
      {image && (
        <div className="relative overflow-hidden">
          <div
            style={{
              backgroundImage: `url(${image.url})`,
            }}
            className="bg-cover bg-center h-[160px] sm:h-[350px] w-full"
          ></div>
        </div>
      )}
      <ModalHeader
        title={title}
        {...props}
        closeIconProps={closeIconProps}
        closeIconFontSize={closeIconFontSize}
      />
      <DialogContent className="light:mt-0 light:pt-0">
        <DialogContentText component="div">{children}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const ModalButton = ({
  children,
  className = '',
  onClick,
}: {
  children: ReactNode;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}) => (
  <button onClick={onClick} className={className}>
    {children}
  </button>
);

export default Modal;
