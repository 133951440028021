import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Box, Chip, Typography } from '@material-ui/core';
import { IBenefitGroup, IPlan } from 'apis/tiers';
import { ReactComponent as TiersIconGradient } from 'assets/images/logos/everything-gradient-en.svg';
import { ReactComponent as TiersIconGradientFr } from 'assets/images/logos/everything-gradient-fr.svg';
import { ReactComponent as TiersIconWhite } from 'assets/images/logos/everything-white-en.svg';
import { ReactComponent as TiersIconWhiteFr } from 'assets/images/logos/everything-white-fr.svg';
import { ReactComponent as Check } from 'assets/images/white-check.svg';
import { LOCALE_EN, LOCALE_FR, LocaleContext } from 'components';
import { Money } from 'models';
import { theme } from 'theme';
import { useTiersData } from 'tiers/hooks/useTiersData';
import { TierKey } from 'tiers/models';

const everythingLogoOptions = {
  [LOCALE_EN]: {
    white: TiersIconWhite,
    gradient: TiersIconGradient,
  },
  [LOCALE_FR]: {
    white: TiersIconWhiteFr,
    gradient: TiersIconGradientFr,
  },
};

type Props = {
  description: string;
  isFree: boolean;
  plan: IPlan | undefined;
  selectable: boolean;
  selected: boolean;
  tier: IBenefitGroup;
};

export const TierCardHeader = ({
  description,
  isFree,
  plan,
  selectable,
  selected,
  tier,
}: Props) => {
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const { getTierName } = useTiersData();

  const EverythingLogo =
    everythingLogoOptions[locale][selectable ? 'gradient' : 'white'];
  const planCost = new Money(plan?.amount_cents ?? 0, locale);
  const headerBG = selectable
    ? 'transparent'
    : tier.key === TierKey.Everything
    ? theme.colors.blueDark
    : theme.colors.whiteSmoke;

  const tierName = getTierName(tier.name);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          backgroundColor: headerBG,
          borderRadius: '8px',
          padding: selectable ? '0 0 8px 0' : '16px 16px 8px 16px',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {tier.key === TierKey.Everything ? (
            <EverythingLogo
              height="32px"
              data-cy="tiers-management-everything-tier"
            />
          ) : (
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 900,
                color:
                  tier.key === TierKey.Essential && !selectable
                    ? theme.colors.orange
                    : theme.colors.blueDark,
              }}
            >
              {tierName}
            </Typography>
          )}
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 900,
                marginRight: '8px',
                color: selectable
                  ? theme.colors.text
                  : tier.key === TierKey.Everything
                  ? theme.colors.greyLavender
                  : theme.colors.midGrey,
                textDecoration: isFree ? 'line-through' : 'none',
              }}
            >
              {plan?.key === 'monthly'
                ? intl.formatMessage(
                    {
                      id: 'TiersCard.PlanCost.monthly',
                      defaultMessage: '{cost}/month',
                    },
                    { cost: planCost.format('pretty') },
                  )
                : intl.formatMessage(
                    {
                      id: 'TiersCard.PlanCost.yearly',
                      defaultMessage: '{cost}/year',
                    },
                    { cost: planCost.format('pretty') },
                  )}
            </Typography>
            {isFree && (
              <Chip
                data-cy="tiers-management-free-chip"
                label={intl.formatMessage({
                  defaultMessage: '30 days free',
                  id: 'TiersCard.TrialDaysChip',
                })}
                sx={{
                  color: theme.colors.primary,
                  backgroundColor: theme.colors.primary50,
                  fontWeight: 600,
                  '& span': {
                    lineHeight: '13px',
                  },
                }}
              />
            )}
          </Box>
        </Box>
        {selectable && (
          <Box
            display="flex"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              border: selected ? 'none' : '1px solid',
              borderColor: theme.colors.greyLight,
              width: 24,
              height: 24,
              borderRadius: '100%',
              bg: selected ? theme.colors.blueNavy : 'transparent',
            }}
          >
            {selected ? (
              <Check data-cy={`tier-card-check-${tier.key}`} />
            ) : null}
          </Box>
        )}
      </Box>
      <Typography sx={{ fontSize: '14px', mb: 16 }}>{description}</Typography>
    </Box>
  );
};
