/** @jsxImportSource theme-ui */
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { RouteComponentProps } from '@reach/router';
import { Box } from 'theme-ui';
import { useTrackPageViewedOnceDeprecated } from 'utility/analyticsHooks';
import { Loadable } from 'utility/loadable';

import logo from '../assets/images/logo-background.svg';
import cardCheckoutImage from '../assets/images/referrals/card-checkout.png';
import cardCheckoutImageWebp from '../assets/images/referrals/card-checkout.webp';
import referralUsersImage from '../assets/images/referrals/referral-users.png';
import referralUsersImageWebp from '../assets/images/referrals/referral-users.webp';
import {
  Link,
  LocaleContext,
  Paragraph,
  SpinnerCircularProgress,
  TitleLarge,
} from '../components';
import { LanguageToggle } from '../components/LanguageToggle';
import { SignUpWidget } from './components/SignUpWidget';
import { ReferralSources } from './models/Referral';
import {
  selectReferrerDataData,
  selectReferrerDataLoadable,
  selectReferrerDataSuccess,
  selectReferrerExpired,
  selectReferrerTriggerEvent,
} from './store/selectors';
import { referralActions } from './store/slice';

export const ReferralLandingPage = ({
  paramReferralCode = '',
}: {
  paramReferralCode?: string;
} & RouteComponentProps) => {
  const dispatch = useDispatch();
  const referralsExpired = useSelector(selectReferrerExpired);
  const referralsDataSuccess = useSelector(selectReferrerDataSuccess);
  const referralTriggerEvent = useSelector(selectReferrerTriggerEvent);
  const referrerDataLoadable = useSelector(selectReferrerDataLoadable);
  const referrerData = useSelector(selectReferrerDataData);
  const intl = useIntl();
  const { deepLinks, apiLanguageHeader } = useContext(LocaleContext);

  useEffect(() => {
    if (paramReferralCode) {
      dispatch(referralActions.getReferrerDataRequest(paramReferralCode));
    }
  }, [dispatch, paramReferralCode, apiLanguageHeader]);

  useEffect(() => {
    if (referralsDataSuccess && !referralsExpired && paramReferralCode) {
      dispatch(
        referralActions.setRegistrationReferralCode({
          campaign: paramReferralCode,
          source: ReferralSources.User,
        }),
      );
    }
  }, [dispatch, paramReferralCode, referralsDataSuccess, referralsExpired]);

  useTrackPageViewedOnceDeprecated({ name: 'Referrals' });

  return (
    <Box>
      <Hero
        referralsExpired={referralsExpired}
        referralTriggerEvent={referralTriggerEvent}
        referrerDataLoadable={referrerDataLoadable}
        referrerName={referrerData?.referral_user?.given_name}
      />

      <Benefits />

      <Box
        sx={{
          textAlign: 'center',
          backgroundColor: '#2E5CA9',
          borderTop: 'solid 1px',
          borderColor: 'lightGrey',
          pt: 6,
          pb: 6,
        }}
      >
        <Link
          sx={{
            display: 'inline-flex',
            gap: ['16px', '16px', '75px'],
            alignItems: 'center',
          }}
          to={deepLinks.KOHO.Home}
          target="_blank"
          trackName="Referral Page - Learn more about KOHO"
          external={true}
        >
          <img
            alt={`${intl.formatMessage({
              id: 'ReferralLandingPage.KOHOLogoAltTag',
              defaultMessage: 'KOHO Logo',
            })}`}
            src={logo}
          />
          <Paragraph sx={{ my: 0, color: 'white', fontWeight: 'bold' }}>
            {intl.formatMessage({
              id: 'ReferralLandingPage.FooterLink',
              defaultMessage: 'Learn more about KOHO',
            })}
          </Paragraph>
        </Link>
      </Box>
    </Box>
  );
};

const Hero = ({
  referralsExpired,
  referralTriggerEvent,
  referrerDataLoadable,
  referrerName,
}: {
  referralsExpired: boolean | undefined;
  referralTriggerEvent: string | undefined;
  referrerDataLoadable: Loadable;
  referrerName: string | undefined;
}) => {
  const intl = useIntl();

  const languageToggleRow = (
    <Box
      sx={{
        p: [3, 5],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ '& > img': { width: '85px' } }}>
        <img
          alt={`${intl.formatMessage({
            id: 'ReferralLandingPage.KOHOLogoAltTag',
            defaultMessage: 'KOHO Logo',
          })}`}
          src={logo}
        />
      </Box>
      <Box sx={{ mb: 3 }}>
        <LanguageToggle color="#FFF" />
      </Box>
    </Box>
  );

  return (
    <Box sx={{ backgroundColor: '#2E5CA9' }}>
      <Box sx={{ maxWidth: [`100%`, 540, 900, 1140], mx: 'auto' }}>
        {languageToggleRow}
        <Box sx={{ px: ['30px', 3, 3, 3], pt: [2, 3, 5], pb: [5, 6] }}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                order: [2, 2, 1],
                pt: ['0px !important', '0px !important', 'auto'],
              }}
            >
              <Box sx={{ width: ['auto', 'auto', 'auto', '90%'] }}>
                <TitleLarge
                  sx={{
                    color: 'white',
                    fontSize: ['32px', '32px', '52px', '52px'],
                    letterSpacing: '-0.5px',
                    lineHeight: 1.1,
                    mb: 4,
                    mt: 0,
                  }}
                >
                  {intl.formatMessage({
                    id: 'ReferralLandingPage.Title',
                    defaultMessage: `Join KOHO with a friend for a double reward!`,
                  })}
                </TitleLarge>
                {!referrerDataLoadable?.success &&
                !referrerDataLoadable?.error ? (
                  <Box sx={{ textAlign: 'center', mb: 24 }}>
                    <SpinnerCircularProgress sx={{ color: 'white' }} />
                  </Box>
                ) : null}
                {referrerDataLoadable?.error || referralsExpired ? (
                  <Box>
                    <Paragraph sx={{ color: 'white', fontWeight: 'bold' }}>
                      {intl.formatMessage({
                        id: 'ReferralLandingPage.Paragraph.ExpiredCode',
                        defaultMessage:
                          'This referral code has changed or expired. Ask your pal for a new one and use it during sign-up.',
                      })}
                    </Paragraph>
                    <Paragraph
                      sx={{ color: 'white', fontWeight: 'bold', mb: 4 }}
                    >
                      {intl.formatMessage({
                        id: 'ReferralLandingPage.Paragraph.DownloadApp',
                        defaultMessage:
                          'Meanwhile, you can still download the app below!',
                      })}
                    </Paragraph>
                  </Box>
                ) : null}
                {referrerDataLoadable?.success && !referralsExpired ? (
                  referralTriggerEvent === 'loads.direct' ? (
                    <Box sx={{ mb: 4 }}>
                      <Paragraph sx={{ color: 'white', fontWeight: 'bold' }}>
                        {intl.formatMessage(
                          {
                            id: 'ReferralLandingPage.SuperReferral',
                            defaultMessage: `Use {name}'s referral code when you sign up for KOHO and add $50 or more to your account to get 4 free months of KOHO Everything plan benefits. That's a $76 saving!`,
                          },
                          {
                            name: referrerName,
                          },
                        )}
                      </Paragraph>
                    </Box>
                  ) : (
                    <Box sx={{ mb: 4 }}>
                      <Paragraph sx={{ color: 'white', fontWeight: 'bold' }}>
                        {intl.formatMessage(
                          {
                            id: 'ReferralLandingPage.Paragraph.Promotion',
                            defaultMessage: `Use {name}'s referral code when you sign up for a KOHO account and make a purchase within 30 days to get $20 or three free months of our Extra plan (that’s a $27 saving!)`,
                          },
                          {
                            name: referrerName,
                          },
                        )}
                      </Paragraph>

                      <Paragraph sx={{ color: 'white', fontWeight: 'bold' }}>
                        {intl.formatMessage({
                          id: 'ReferralLandingPage.Paragraph.PromotionOption',
                          defaultMessage: `Take your pick!`,
                        })}
                      </Paragraph>
                    </Box>
                  )
                ) : null}

                <SignUpWidget
                  btnStyles={{
                    '&, &:hover': {
                      backgroundColor: 'white !important',
                      color: '#2E5CA9 !important',
                      px: ['auto', 'auto', 6],
                      width: ['100%', '100%', 'auto', 'auto'],
                    },
                  }}
                  osDefault="web"
                  sx={{
                    textAlign: ['center', 'left', 'left'],
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                textAlign: ['center', 'center', 'left'],
                order: [1, 1, 2],
                pb: ['0px !important', '0px !important', 'auto'],
                mb: [5, 5, 0],
              }}
            >
              <Box
                sx={{
                  borderRadius: '32px',
                  boxShadow: '0 12px 24px rgba(21,45,64,0.1)',
                  overflow: 'hidden',
                  maxWidth: ['100%', '530px', '530px'],
                  mx: 'auto',
                  '& > picture': {
                    display: 'flex',
                  },
                  '& > picture > img': {
                    objectFit: 'cover',
                    width: ['100%', '100%', '100%', '100%'],
                  },
                }}
              >
                <picture>
                  <source srcSet={referralUsersImageWebp} />
                  <img
                    src={referralUsersImage}
                    alt={`${intl.formatMessage({
                      id: 'ReferralLandingPage.ReferralUsersImageAltTag',
                      defaultMessage: 'Referral Users',
                    })}`}
                  />
                </picture>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const Benefits = () => {
  const intl = useIntl();

  const benefits = [
    intl.formatMessage({
      id: 'ReferralLandingPage.Benefit1',
      defaultMessage: `Get a virtual Mastercard for instant spending`,
    }),
    intl.formatMessage({
      id: 'ReferralLandingPage.Benefit2',
      defaultMessage: `Earn cash back and high interest`,
    }),
    intl.formatMessage({
      id: 'ReferralLandingPage.Benefit3',
      defaultMessage: `Build your credit history`,
    }),
    intl.formatMessage({
      id: 'ReferralLandingPage.Benefit4',
      defaultMessage: `Save more with clever saving features in the app`,
    }),
  ];

  return (
    <Box sx={{ maxWidth: [`100%`, 540, 900, 1140], mx: 'auto' }}>
      <Box
        sx={{ px: ['30px', 3, 3, 3], py: ['64px', '64px', '128px', '128px'] }}
      >
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              pb: ['0px !important', '0px !important', 'auto'],
            }}
          >
            <Box
              sx={{
                borderRadius: '32px',
                overflow: 'hidden',
                maxWidth: ['100%', '530px', '530px'],
                mb: [5, 5, 0],
                mx: 'auto',
                '& > picture': {
                  display: 'flex',
                },
                '& > picture > img': {
                  objectFit: 'cover',
                  width: ['100%', '100%', '100%', '100%'],
                },
              }}
            >
              <picture>
                <source srcSet={cardCheckoutImageWebp} />
                <img
                  src={cardCheckoutImage}
                  alt={`${intl.formatMessage({
                    id: 'ReferralLandingPage.CardCheckoutImageAltTag',
                    defaultMessage: 'Card Checkout',
                  })}`}
                />
              </picture>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              pt: ['0px !important', '0px !important', '0px !important'],
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box sx={{ width: ['auto', 'auto', 'auto', 'auto'] }}>
              <TitleLarge
                sx={{
                  lineHeight: 1.1,
                  mt: 0,
                  mb: 4,
                  fontSize: ['32px', '32px', '52px', '52px'],
                  letterSpacing: '-0.5px',
                }}
              >
                {intl.formatMessage({
                  id: 'ReferralLandingPage.WhatIsKOHOTitle',
                  defaultMessage: `Wait, what exactly is KOHO?`,
                })}
              </TitleLarge>
              <Paragraph sx={{ mb: ['16px', '16px', '32px'] }}>
                {intl.formatMessage({
                  id: 'ReferralLandingPage.WhatIsKOHOSubtitle',
                  defaultMessage: `KOHO is your everyday account and money management app.`,
                })}
              </Paragraph>
              {benefits.map((benefit, i) => (
                <Box
                  key={`referral-page-benefit-${i}`}
                  sx={{
                    display: 'flex',
                    gap: ['16px', '16px', '30px'],
                    '&:not(:last-of-type)': { mb: ['16px'] },
                  }}
                >
                  <Box sx={{ width: ['24px', '24px', '32px'] }}>
                    <SvgCheckmarkCircle />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Paragraph sx={{ my: 0, fontWeight: 'medium' }}>
                      {benefit}
                    </Paragraph>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const SvgCheckmarkCircle = ({ ...props }) => (
  <Box
    sx={{
      '& > svg': { width: ['24px', '24px', '32px'] },
      '& > svg > path': { fill: '#2E5CA9' },
    }}
  >
    <svg
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 32C7.18 32 0 24.82 0 16S7.18 0 16 0s16 7.18 16 16-7.171 16-16 16Zm0-30.473C8.026 1.527 1.527 8.017 1.527 16c0 7.983 6.49 14.473 14.473 14.473 7.983 0 14.473-6.49 14.473-14.473 0-7.983-6.49-14.473-14.473-14.473Z" />
      <path d="M13.278 22.264a.807.807 0 0 1-.663-.35l-5.383-5.382a.769.769 0 0 1 0-1.082.769.769 0 0 1 1.082 0l4.938 4.938L23.695 9.954a.769.769 0 0 1 1.081 0 .769.769 0 0 1 0 1.082L13.95 21.854a.76.76 0 0 1-.672.41Z" />
    </svg>
  </Box>
);
