import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: LoginState = {
  destination: '/transactions',
  rememberDevice: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    loginSuccess: (state) => state,
    smsOtpStarted: (state) => state,
    smsOtpSuccess: (state, action: PayloadAction<boolean>) => state,
    rememberDeviceSelected: (state, action: PayloadAction<boolean>) => {
      state.rememberDevice = action.payload;
    },
    navigateToLoginDestination: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.destination = action?.payload || state.destination;
    },
    setLoginDestination: (state, action: PayloadAction<string | undefined>) => {
      state.destination = action?.payload || state.destination;
    },
    noop: (state) => state,
  },
});

export interface LoginState {
  destination: string;
  rememberDevice: boolean;
}

export interface LoginAction {
  email: string;
  password: string;
}

export interface LoginActionError {
  error?: any;
}

export const loginActions = loginSlice.actions;
export default loginSlice.reducer;
