const scriptEqualWeb = (locale: 'EN' | 'FR'): string =>
  `window.interdeal = { "sitekey": "dd4f94788601d746ef6b03e10c0ceafd", "Position": "Right", "Menulang": '${locale}', "domains": { "js": "https://cdn.equalweb.com/", "acc": "https://access.equalweb.com/" }, "btnStyle": { "vPosition": [ "80%", null ], "scale": [ "0.8", "0.8" ], "color": { "main": "#1876c9" }, "icon": { "type": 7, "shape": "semicircle", "outline": false } } }; (function(doc, head, body){ var coreCall = doc.createElement('script'); coreCall.setAttribute('id', 'equalweb-script'); coreCall.src = interdeal.domains.js + 'core/4.5.6/accessibility.js'; coreCall.defer = true; coreCall.integrity = 'sha512-rotepgID7jK/CgENxVXOfMA1+62xwcnONU14mNlHVkywU+O9+uN5JqPHxBCJpGJhc3eUFYdtdjRaTdf6uffOyg=='; coreCall.crossOrigin = 'anonymous'; coreCall.setAttribute('data-cfasync', true ); body? body.appendChild(coreCall) : head.appendChild(coreCall); })(document, document.head, document.body);`;

const appendScriptEqualWeb = (locale: 'EN' | 'FR'): void => {
  const scriptElement = document.createElement('script');
  scriptElement.setAttribute('id', 'equalweb-load-script');
  scriptElement.innerHTML = scriptEqualWeb(locale);
  document.head.appendChild(scriptElement);
};

export const handleScriptEqualWeb = ({
  locale,
  localeChanged,
}: {
  locale: string;
  localeChanged: boolean;
}): void => {
  const isSignUpPage =
    window?.location?.pathname?.toLowerCase().includes('signup') ||
    window?.location?.pathname?.toLowerCase().includes('sign-up') ||
    window?.location?.pathname?.toLowerCase().includes('registration') ||
    window?.location?.pathname === '/';

  if (isSignUpPage) {
    // If locale changed, remove previous equalweb script
    if (localeChanged) {
      delete (window as any).interdeal;
      document?.getElementById('equalweb-script')?.remove();
      document?.getElementById('equalweb-load-script')?.remove();
    }

    appendScriptEqualWeb(locale === 'fr' ? 'FR' : 'EN');
  }
};
