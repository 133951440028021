import { defineMessage } from 'react-intl';

import { navigate } from '@reach/router';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { selectLoginOtpRememberDevice } from 'login/store/selectors';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RootState } from 'store';

import { track } from '../../analytics/analytics';
import { SmsOtpVerifyError, smsOtpActions } from './slice';

export interface SmsOtpVerifyResponse {
  token: string;
}

export const smsOtpRequestEpic: Epic<any, any, RootState> = (
  action$,
  store,
) => {
  return action$.pipe(
    ofType(smsOtpActions.requestForgotPassword.toString()),

    switchMap(() => {
      return from(
        axios.post(`${import.meta.env.VITE_GATEWAY_API}phone/request`, {
          reference_identifier: store.value.anonymousUser.id,
        }),
      ).pipe(
        map(() => {
          return smsOtpActions.screenSet('enter');
        }),
        catchError(() => {
          navigate('/error');
          return of({ type: 'noop' });
        }),
      );
    }),
  );
};

export const smsOtpVerifyEpic: Epic<any, any, RootState> = (action$, store) => {
  return action$.pipe(
    ofType(smsOtpActions.verify.toString()),

    switchMap(() => {
      const url = `${import.meta.env.VITE_GATEWAY_API}phone/verify`;
      const rememberDevice = selectLoginOtpRememberDevice(store.value);
      return from(
        axios.post(url, {
          verification_code: store.value.smsOtp.otp,
          reference_identifier:
            store.value.profile.data?.reference_identifier ||
            store.value.anonymousUser.id,
          remember_me: rememberDevice,
        }),
      ).pipe(
        switchMap((res: AxiosResponse<SmsOtpVerifyResponse>) =>
          of(
            smsOtpActions.tokenSet(res.data.token),
            smsOtpActions.loadableFulfilled(),
          ),
        ),
        catchError((err: AxiosError<SmsOtpVerifyError>) => errorHandling(err)),
      );
    }),
  );
};

const errorHandling = (err: AxiosError<SmsOtpVerifyError>) => {
  return of(
    smsOtpActions.set(''),
    (() => {
      switch (err.response?.status) {
        case 400:
          track({ event: 'sms-otp verification bad request error' });
          if (err.response.data.error_code) {
            return smsOtpActions.loadableFailed({
              error: {
                error_message: defineMessage({
                  id: 'SmsOtp.InvalidCode',
                  defaultMessage: 'Invalid verification code.',
                }),
              },
            });
          }
          return smsOtpActions.loadableFailed({
            error: {
              error_message: defineMessage({
                id: 'SmsOtp.SomethingWrong',
                defaultMessage: 'Something was wrong, please try again.',
              }),
            },
          });
        case 401:
          track({ event: 'sms-otp verification unauthorized error' });
          return smsOtpActions.loadableFailed({});
        case 429:
          navigate('/password-reset/otp-limit-reached');
          track({
            event: 'sms-otp verification rate limit reached error',
          });
          return { type: 'noop' };
        case 500:
          navigate('/error');
          track({ event: 'sms-otp verification server error' });
          return { type: 'noop' };
        default:
          track({ event: 'sms-otp verification unhandled error' });
          navigate('/error');
          return { type: 'noop' };
      }
    })(),
  );
};

const exportedArray = [smsOtpRequestEpic, smsOtpVerifyEpic];
export default exportedArray;
