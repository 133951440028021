export enum RegistrationPage {
  Identity = 'Identity',
  IdentityExisting = 'IdentityExisting',
  UserInfo = 'UserInfo',
  Address = 'Address',
  HomeAddress = 'HomeAddress',
  MobileNumber = 'MobileNumber',
  VerifyMobile = 'VerifyMobile',
  EditMobileNumber = 'EditMobileNumber',
  Occupation = 'Occupation',
  Intent = 'Intent',
}
