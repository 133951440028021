/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Modal, Paragraph, TitleLarge } from 'components';

import { track } from '../../analytics/analytics';
import { Button } from '../../components/Button';
import { selectAuthIsForcedLogout } from '../store/selectors';

export const ReLoginModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isForcedLogout = useSelector(selectAuthIsForcedLogout);

  const intl = useIntl();

  useEffect(() => {
    if (isForcedLogout) {
      track({
        event: 'Viewed',
        properties: { name: 'Relogin Modal Logout Forced' },
      });

      setIsModalOpen(true);
    }
  }, [isForcedLogout]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxWidth: '624px',
        },
      }}
    >
      <TitleLarge sx={{ mt: 0, mb: 2 }}>
        {intl.formatMessage({
          id: 'ReloginModal.Title',
          defaultMessage: 'We logged you out due to inactivity.',
        })}
      </TitleLarge>

      <Paragraph>
        {intl.formatMessage({
          id: 'ReloginModal.Content',
          defaultMessage:
            'For security purposes, you’ve been logged out because you were inactive. Log back in to continue using KOHO.',
        })}
      </Paragraph>
      <Button
        onClick={handleClose}
        trackName="Relogin Modal Logout Forced Acknowledged"
      >
        {intl.formatMessage({
          id: 'Global.Button.OkayGotIt',
          defaultMessage: 'Okay, got it',
        })}
      </Button>
    </Modal>
  );
};
