import { hashEmail } from 'utility/hashEmail';

const trackSignUpScript = (email: string, hashedEmail: string) =>
  `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_e4ba8g8u', {"optOut":false,"useDecimalCurrencyValues":true,"email":"${email}"});rdt('track', 'SignUp', { "conversionId": "${hashedEmail}" });`;

export const trackRedditSignUp = (email: string) => {
  const scriptElement = document.createElement('script');
  const emailHashed = hashEmail(email);
  scriptElement.innerHTML = trackSignUpScript(email, emailHashed);
  document.body.appendChild(scriptElement);
};
