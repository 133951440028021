import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Box, makeStyles } from '@material-ui/core';
import { useGetCoverQuery } from 'apis/cover';
import { KDSIcons } from 'assets/images/kds_icons';
import { selectBalancePersonalAccount } from 'balance/store/selectors';
import { Money } from 'models';
import { theme } from 'theme';

import { Button, Divider, Modal, ModalProps } from '../../components';
import {
  NumberSmall,
  Paragraph,
  ParagraphBold,
  Subtitle,
  SubtitleMedium,
  TitleLarge,
} from '../../components/Typography';
import { useMoneyFormatter } from '../../hooks/useMoneyFormatter';

export function useBalanceModal() {
  const [isShowing, setShowBalanceModal] = React.useState(false);

  return { isShowing, showBalanceModal: setShowBalanceModal };
}

const useStyles = makeStyles({ paperWidthXs: { maxWidth: 500 } });

export const BalanceModal = ({
  open,
  onClose,
}: { open: boolean; onClose: () => void } & Readonly<ModalProps>) => {
  const intl = useIntl();
  const formatMoney = useMoneyFormatter();

  let balance = useSelector(selectBalancePersonalAccount);
  let { data } = useGetCoverQuery();

  const classes = useStyles();

  // selectBalancePersonalAccount includes the loaned out Overdraft amount
  //   so we have to do some operations to get our display values

  const spendable = (function (balance, coverBalance) {
    if (!balance || !coverBalance) return '';

    if (new Money(balance).gteq(new Money(coverBalance))) {
      return formatMoney(new Money(balance).sub(coverBalance));
    }

    return formatMoney('0.00');
  })(balance?.spendable, data?.current_plan?.amount);

  const coverBalance = (function (balance, coverBalance) {
    if (!balance || !coverBalance) return '';

    if (new Money(balance).lteq(0)) return formatMoney('0.00');

    if (new Money(balance).gteq(new Money(coverBalance))) {
      return formatMoney(coverBalance);
    }

    return formatMoney(balance);
  })(balance?.spendable, data?.current_plan?.amount);

  const savings = (function (balance) {
    if (!balance) return '';

    return formatMoney(
      new Money(balance?.vault).add(balance?.goals).add(balance?.roundups),
    );
  })(balance);

  const total = (function (balance, coverBalance) {
    if (!balance || !coverBalance) return '';

    return formatMoney(
      new Money(balance?.spendable)
        .add(balance?.vault)
        .add(balance?.goals)
        .add(balance?.roundups)
        .add(coverBalance),
    );
  })(balance, data?.current_plan?.amount);

  return (
    <Modal classes={classes} open={open} fullWidth onClose={onClose}>
      <KDSIcons.Spot.Paid sx={{ display: 'block' }} />
      <TitleLarge>
        {intl.formatMessage({
          id: 'BalanceModal.Title',
          defaultMessage: 'Your balance',
        })}
      </TitleLarge>
      <Paragraph color={theme.colors.tertiaryT300}>
        {intl.formatMessage({
          id: 'BalanceModal.DetailsBreakdown',
          defaultMessage:
            'See a detailed breakdown below of your total account balances',
        })}
      </Paragraph>

      <Box sx={{ mt: 4 }}>
        <Row>
          <ParagraphBold margin={2}>
            {intl.formatMessage({
              id: 'BalanceModal.TotalAmount',
              defaultMessage: 'Total amount',
            })}
          </ParagraphBold>
          <NumberSmall>{total}</NumberSmall>
        </Row>
        <Row>
          <ParagraphBold sx={{ mt: 2, mb: 0 }}>
            {intl.formatMessage({
              id: 'BalanceModal.AccountBalance',
              defaultMessage: 'Account Balance',
            })}
          </ParagraphBold>
          <NumberSmall>{formatMoney(balance?.spendable)}</NumberSmall>
        </Row>
        <Row>
          <Box display="flex" alignItems="center" sx={{ gap: '16px' }}>
            <KDSIcons.Navigation.Spending />
            <SubtitleMedium>
              {intl.formatMessage({
                id: 'BalanceModal.Spendable',
                defaultMessage: 'Spendable',
              })}
            </SubtitleMedium>
          </Box>
          <Subtitle>{spendable}</Subtitle>
        </Row>
        {data && (
          <Row>
            <Box display="flex" alignItems="center" sx={{ gap: '16px' }}>
              <KDSIcons.Features.Umbrella />
              <SubtitleMedium>
                {intl.formatMessage({
                  id: 'BalanceModal.Cover',
                  defaultMessage: 'Cover',
                })}
              </SubtitleMedium>
            </Box>
            <Subtitle>{coverBalance}</Subtitle>
          </Row>
        )}
        <Row>
          <ParagraphBold margin={2}>
            {intl.formatMessage({
              id: 'BalanceModal.Save',
              defaultMessage: 'Savings',
            })}
          </ParagraphBold>
          <NumberSmall>{savings}</NumberSmall>
        </Row>
        <Row>
          <Box display="flex" alignItems="center" sx={{ gap: '16px' }}>
            <KDSIcons.Navigation.Saving />
            <SubtitleMedium>
              {intl.formatMessage({
                id: 'BalanceModal.Vault',
                defaultMessage: 'Vault',
              })}
            </SubtitleMedium>
          </Box>
          <Subtitle>{formatMoney(balance?.vault)}</Subtitle>
        </Row>
        <Row>
          <Box display="flex" alignItems="center" sx={{ gap: '16px' }}>
            <KDSIcons.Icons.GoalAmount />
            <SubtitleMedium>
              {intl.formatMessage({
                id: 'BalanceModal.Goals',
                defaultMessage: 'Goals',
              })}
            </SubtitleMedium>
          </Box>
          <Subtitle>{formatMoney(balance?.goals)}</Subtitle>
        </Row>
        <Row last>
          <Box display="flex" alignItems="center" sx={{ gap: '16px' }}>
            <KDSIcons.Features.Roundups />
            <SubtitleMedium>
              {intl.formatMessage({
                id: 'BalanceModal.Roundups',
                defaultMessage: 'Roundups',
              })}
            </SubtitleMedium>
          </Box>
          <Subtitle>{formatMoney(balance?.roundups)}</Subtitle>
        </Row>
      </Box>
      <Button type="button" onClick={onClose}>
        {intl.formatMessage({
          id: 'Modal.Close',
          defaultMessage: 'Close',
        })}
      </Button>
    </Modal>
  );
};

type RowProps = {
  children: React.ReactNode;
  last?: boolean;
};
const Row = ({ last = false, children }: Readonly<RowProps>) => (
  <React.Fragment>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {children}
    </Box>
    <Divider margin={3} sx={{ opacity: last ? 0 : 1 }} />
  </React.Fragment>
);
