import axios, { AxiosError, AxiosResponse } from 'axios';
import { Epic } from 'redux-observable';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { RootState } from 'store';

import { showSnackbar } from '../../components';
import { AccountCard } from '../models';
import { cardsActions } from './slice';

interface CardUsabilityResponse {
  data: AccountCard;
  status_code: number;
  status_name: string;
}

const changeCardUsabilityEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(cardsActions.changeCardUsabilityRequest.toString()),
    switchMap(({ payload: { cardId, isLocked, successMessage } }) => {
      return from(
        axios.patch(
          `${import.meta.env.VITE_GATEWAY_API}accounts/card/${cardId}`,
          {
            is_locked: isLocked,
          },
        ),
      ).pipe(
        mergeMap((response: AxiosResponse<CardUsabilityResponse>) =>
          of(
            cardsActions.changeCardUsabilityResponse(response.data.data),
            showSnackbar(successMessage, {
              type: 'success',
            }),
          ),
        ),
        catchError((error: AxiosError) => {
          return from([
            showSnackbar(error.message, { type: 'error' }),
            cardsActions.changeCardUsabilityError(),
          ]);
        }),
      );
    }),
  );
};

const exportedArray = [changeCardUsabilityEpic];

export default exportedArray;
