import { defineMessage } from 'react-intl';
import { MessageDescriptor } from 'react-intl';

export interface Occupation {
  title: MessageDescriptor;
  code: string;
  value: string;
}

// prettier-ignore
export const occupations: Occupation[] = [
  {code: "0005", title: defineMessage({id: "Occupation.0.0005",defaultMessage: "Unemployed"}), value: "Unemployed"},
  {code: "0006", title: defineMessage({id: "Occupation.1.0006",defaultMessage: "Student"}), value: "Student"},
  {code: "0007", title: defineMessage({id: "Occupation.2.0007",defaultMessage: "Retired"}), value: "Retired"},
  {code: "0008", title: defineMessage({id: "Occupation.3.0008",defaultMessage: "Disability - No Employment"}), value: "Disability - No Employment"},
  {code: "0011", title: defineMessage({id: "Occupation.4.0011",defaultMessage: "School Board Trustee"}), value: "School Board Trustee"},
  {code: "0011", title: defineMessage({id: "Occupation.5.0011",defaultMessage: "Legislators And Politicians"}), value: "Legislators And Politicians"},
  {code: "0011", title: defineMessage({id: "Occupation.6.0011",defaultMessage: "Mayor"}), value: "Mayor"},
  {code: "0011", title: defineMessage({id: "Occupation.7.0011",defaultMessage: "Member of Parliament (MP)"}), value: "Member of Parliament (MP)"},
  {code: "0011", title: defineMessage({id: "Occupation.8.0011",defaultMessage: "Member of the Provincial Parliament (MPP)"}), value: "Member of the Provincial Parliament (MPP)"},
  {code: '0011', title: defineMessage({id: "Occupation.908.0011",defaultMessage: "Aboriginal Band Council Member"}), value: "Aboriginal Band Council Member"},
  {code: '0011', title: defineMessage({id: "Occupation.909.0011",defaultMessage: "Band Chief, First Nations"}), value: "Band Chief, First Nations"},
  {code: '0011', title: defineMessage({id: "Occupation.910.0011",defaultMessage: "Cabinet Minister"}), value: "Cabinet Minister"},
  {code: "0012", title: defineMessage({id: "Occupation.9.0012",defaultMessage: "Member of Legislative Assembly (MLA)"}), value: "Member of Legislative Assembly (MLA)"},
  {code: "0013", title: defineMessage({id: "Occupation.10.0013",defaultMessage: "City Councillor"}), value: "City Councillor"},
  {code: "0012", title: defineMessage({id: "Occupation.11.0012",defaultMessage: "Senior Government Manager"}), value: "Senior Government Manager"},
  {code: "0012", title: defineMessage({id: "Occupation.12.0012",defaultMessage: "Senior Government Official"}), value: "Senior Government Official"},
  {code: "0013", title: defineMessage({id: "Occupation.13.0013",defaultMessage: "Executive Business and Finance"}), value: "Executive Business and Finance"},
  {code: "0013", title: defineMessage({id: "Occupation.14.0013",defaultMessage: "Director Business and Finance"}), value: "Director Business and Finance"},
  {code: "0013", title: defineMessage({id: "Occupation.15.0013",defaultMessage: "Senior Manager Finance"}), value: "Senior Manager Finance"},
  {code: "0013", title: defineMessage({id: "Occupation.16.0013",defaultMessage: "Senior Manager Communications"}), value: "Senior Manager Communications"},
  {code: "0013", title: defineMessage({id: "Occupation.17.0013",defaultMessage: "Senior Manager Other Business Services"}), value: "Senior Manager Other Business Services"},
  {code: '0014', title: defineMessage({id: "Occupation.911.0014",defaultMessage: "President, Political Party"}), value: "President, Political Party"},
  {code: "0014", title: defineMessage({id: "Occupation.18.0014",defaultMessage: "Senior Manager Health"}), value: "Senior Manager Health"},
  {code: "0014", title: defineMessage({id: "Occupation.19.0014",defaultMessage: "Senior Manager Education"}), value: "Senior Manager Education"},
  {code: "0014", title: defineMessage({id: "Occupation.20.0014",defaultMessage: "Senior Manager Social And Community Services"}), value: "Senior Manager Social And Community Services"},
  {code: "0015", title: defineMessage({id: "Occupation.21.0015",defaultMessage: "Senior Manager Trade"}), value: "Senior Manager Trade"},
  {code: "0015", title: defineMessage({id: "Occupation.22.0015",defaultMessage: "Senior Manager Broadcasting"}), value: "Senior Manager Broadcasting"},
  {code: "0016", title: defineMessage({id: "Occupation.23.0016",defaultMessage: "Senior Manager Construction"}), value: "Senior Manager Construction"},
  {code: "0016", title: defineMessage({id: "Occupation.24.0016",defaultMessage: "Senior Manager Transportation"}), value: "Senior Manager Transportation"},
  {code: "0016", title: defineMessage({id: "Occupation.25.0016",defaultMessage: "Senior Manager Production"}), value: "Senior Manager Production"},
  {code: "0016", title: defineMessage({id: "Occupation.26.0016",defaultMessage: "Senior Manager Utilities"}), value: "Senior Manager Utilities"},
  {code: "0111", title: defineMessage({id: "Occupation.27.0111",defaultMessage: "Director Financial Services"}), value: "Director Financial Services"},
  {code: "0111", title: defineMessage({id: "Occupation.28.0111",defaultMessage: "Director Finance "}), value: "Director Finance "},
  {code: "0111", title: defineMessage({id: "Occupation.29.0111",defaultMessage: "Finance Manager"}), value: "Finance Manager"},
  {code: "0111", title: defineMessage({id: "Occupation.30.0111",defaultMessage: "Controller"}), value: "Controller"},
  {code: "0111", title: defineMessage({id: "Occupation.31.0111",defaultMessage: "Treasurer"}), value: "Treasurer"},
  {code: "0112", title: defineMessage({id: "Occupation.32.0112",defaultMessage: "Human Resources Manager"}), value: "Human Resources Manager"},
  {code: "0113", title: defineMessage({id: "Occupation.33.0113",defaultMessage: "Purchasing Manager"}), value: "Purchasing Manager"},
  {code: "0114", title: defineMessage({id: "Occupation.34.0114",defaultMessage: "Other Administrative Services Manager"}), value: "Other Administrative Services Manager"},
  {code: "0121", title: defineMessage({id: "Occupation.35.0121",defaultMessage: "Insurance Manager"}), value: "Insurance Manager"},
  {code: "0121", title: defineMessage({id: "Occupation.36.0121",defaultMessage: "Real Estate Manager"}), value: "Real Estate Manager"},
  {code: "0121", title: defineMessage({id: "Occupation.37.0121",defaultMessage: "Financial Brokerage Manager"}), value: "Financial Brokerage Manager"},
  {code: "0122", title: defineMessage({id: "Occupation.38.0122",defaultMessage: "Banking Manager"}), value: "Banking Manager"},
  {code: "0122", title: defineMessage({id: "Occupation.39.0122",defaultMessage: "Banking Operations Manager"}), value: "Banking Operations Manager"},
  {code: "0122", title: defineMessage({id: "Occupation.40.0122",defaultMessage: "Credit Manager"}), value: "Credit Manager"},
  {code: "0124", title: defineMessage({id: "Occupation.41.0124",defaultMessage: "Advertising Manager"}), value: "Advertising Manager"},
  {code: "0124", title: defineMessage({id: "Occupation.42.0124",defaultMessage: "Marketing Manager"}), value: "Marketing Manager"},
  {code: "0124", title: defineMessage({id: "Occupation.43.0124",defaultMessage: "Public Relations Manager"}), value: "Public Relations Manager"},
  {code: "0125", title: defineMessage({id: "Occupation.44.0125",defaultMessage: "Other Business Services Manager"}), value: "Other Business Services Manager"},
  {code: '0125', title: defineMessage({id: "Occupation.912.0125",defaultMessage: "Contact Centre Manager"}), value: "Contact Centre Manager"},
  {code: '0125', title: defineMessage({id: "Occupation.913.0125",defaultMessage: "Employment Agency Manager"}), value: "Employment Agency Manager"},
  {code: '0125', title: defineMessage({id: "Occupation.914.0125",defaultMessage: "Manager, Accounting Firm"}), value: "Manager, Accounting Firm"},
  {code: "0131", title: defineMessage({id: "Occupation.45.0131",defaultMessage: "Telecommunication Carriers Manager"}), value: "Telecommunication Carriers Manager"},
  {code: "0132", title: defineMessage({id: "Occupation.46.0132",defaultMessage: "Courier Services Manager"}), value: "Courier Services Manager"},
  {code: "0132", title: defineMessage({id: "Occupation.47.0132",defaultMessage: "Postal Services Manager"}), value: "Postal Services Manager"},
  {code: "0211", title: defineMessage({id: "Occupation.48.0211",defaultMessage: "Engineering Manager"}), value: "Engineering Manager"},
  {code: "0212", title: defineMessage({id: "Occupation.49.0212",defaultMessage: "Architecture Manager"}), value: "Architecture Manager"},
  {code: "0212", title: defineMessage({id: "Occupation.50.0212",defaultMessage: "Science Manager"}), value: "Science Manager"},
  {code: "0213", title: defineMessage({id: "Occupation.51.0213",defaultMessage: "Computer And Information Systems Manager"}), value: "Computer And Information Systems Manager"},
  {code: "0311", title: defineMessage({id: "Occupation.52.0311",defaultMessage: "Manager In Health Care"}), value: "Manager In Health Care"},
  {code: "0411", title: defineMessage({id: "Occupation.53.0411",defaultMessage: "Government Managers Health Policy Development"}), value: "Government Managers Health Policy Development"},
  {code: "0411", title: defineMessage({id: "Occupation.54.0411",defaultMessage: "Government Managers Health Program Administration"}), value: "Government Managers Health Program Administration"},
  {code: "0411", title: defineMessage({id: "Occupation.55.0411",defaultMessage: "Government Managers Social Program Administration"}), value: "Government Managers Social Program Administration"},
  {code: "0411", title: defineMessage({id: "Occupation.56.0411",defaultMessage: "Government Managers Social Policy Development "}), value: "Government Managers Social Policy Development "},
  {code: '0411', title: defineMessage({id: "Occupation.915.0411",defaultMessage: "Career Development and Employment District Director - Government Services"}), value: "Career Development and Employment District Director - Government Services"},
  {code: "0412", title: defineMessage({id: "Occupation.57.0412",defaultMessage: "Government Managers Economic Analysis"}), value: "Government Managers Economic Analysis"},
  {code: "0412", title: defineMessage({id: "Occupation.58.0412",defaultMessage: "Government Managers Economic Policy Development"}), value: "Government Managers Economic Policy Development"},
  {code: "0412", title: defineMessage({id: "Occupation.59.0412",defaultMessage: "Government Managers Economic Program Administration"}), value: "Government Managers Economic Program Administration"},
  {code: "0413", title: defineMessage({id: "Occupation.60.0413",defaultMessage: "Government Managers Education Policy Development"}), value: "Government Managers Education Policy Development"},
  {code: "0413", title: defineMessage({id: "Occupation.61.0413",defaultMessage: "Government Managers Education Program Administration"}), value: "Government Managers Education Program Administration"},
  {code: "0414", title: defineMessage({id: "Occupation.62.0414",defaultMessage: "Manager Public Administration"}), value: "Manager Public Administration"},
  {code: "0421", title: defineMessage({id: "Occupation.63.0421",defaultMessage: "Administrator Post-Secondary Education"}), value: "Administrator Post-Secondary Education"},
  {code: "0421", title: defineMessage({id: "Occupation.64.0421",defaultMessage: "Administrator Vocational Training"}), value: "Administrator Vocational Training"},
  {code: "0421", title: defineMessage({id: "Occupation.65.0421",defaultMessage: "Dean"}), value: "Dean"},
  {code: "0421", title: defineMessage({id: "Occupation.66.0421",defaultMessage: "University Registrar"}), value: "University Registrar"},
  {code: "0422", title: defineMessage({id: "Occupation.67.0422",defaultMessage: "Principal"}), value: "Principal"},
  {code: "0422", title: defineMessage({id: "Occupation.68.0422",defaultMessage: "Principal Elementary and Secondary Education"}), value: "Principal Elementary and Secondary Education"},
  {code: "0422", title: defineMessage({id: "Occupation.69.0422",defaultMessage: "Administrator Elementary and Secondary Education"}), value: "Administrator Elementary and Secondary Education"},
  {code: "0423", title: defineMessage({id: "Occupation.70.0423",defaultMessage: "Manager Correctional Services"}), value: "Manager Correctional Services"},
  {code: "0423", title: defineMessage({id: "Occupation.71.0423",defaultMessage: "Manager Community Services"}), value: "Manager Community Services"},
  {code: "0423", title: defineMessage({id: "Occupation.72.0423",defaultMessage: "Manager Social Services"}), value: "Manager Social Services"},
  {code: "0431", title: defineMessage({id: "Occupation.73.0431",defaultMessage: "Commissioned Police Officer"}), value: "Commissioned Police Officer"},
  {code: "0432", title: defineMessage({id: "Occupation.74.0432",defaultMessage: "Fire Chief or Senior Firefighting Officer"}), value: "Fire Chief or Senior Firefighting Officer"},
  {code: "0433", title: defineMessage({id: "Occupation.75.0433",defaultMessage: "Commissioned Officer Of The Canadian Forces"}), value: "Commissioned Officer Of The Canadian Forces"},
  {code: "0511", title: defineMessage({id: "Occupation.76.0511",defaultMessage: "Library Manager"}), value: "Library Manager"},
  {code: "0511", title: defineMessage({id: "Occupation.77.0511",defaultMessage: "Archive Manager"}), value: "Archive Manager"},
  {code: "0511", title: defineMessage({id: "Occupation.78.0511",defaultMessage: "Museum Manager"}), value: "Museum Manager"},
  {code: "0511", title: defineMessage({id: "Occupation.79.0511",defaultMessage: "Art Gallery Manager"}), value: "Art Gallery Manager"},
  {code: "0512", title: defineMessage({id: "Occupation.80.0512",defaultMessage: "Manager Publishing"}), value: "Manager Publishing"},
  {code: "0512", title: defineMessage({id: "Occupation.81.0512",defaultMessage: "Manager Motion Pictures"}), value: "Manager Motion Pictures"},
  {code: "0512", title: defineMessage({id: "Occupation.82.0512",defaultMessage: "Manager Broadcasting"}), value: "Manager Broadcasting"},
  {code: "0512", title: defineMessage({id: "Occupation.83.0512",defaultMessage: "Manager Performing Arts"}), value: "Manager Performing Arts"},
  {code: "0513", title: defineMessage({id: "Occupation.84.0513",defaultMessage: "Sports Program And Service Directors"}), value: "Sports Program And Service Directors"},
  {code: "0513", title: defineMessage({id: "Occupation.85.0513",defaultMessage: "Recreation Program And Service Directors"}), value: "Recreation Program And Service Directors"},
  {code: "0513", title: defineMessage({id: "Occupation.86.0513",defaultMessage: "Fitness Program And Service Directors"}), value: "Fitness Program And Service Directors"},
  {code: "0601", title: defineMessage({id: "Occupation.87.0601",defaultMessage: "Corporate Sales Manager"}), value: "Corporate Sales Manager"},
  {code: "0621", title: defineMessage({id: "Occupation.88.0621",defaultMessage: "Retail And Wholesale Trade Manager"}), value: "Retail And Wholesale Trade Manager"},
  {code: '0621', title: defineMessage({id: "Occupation.916.0621",defaultMessage: "Antique Dealer"}), value: "Antique Dealer"},
  {code: '0621', title: defineMessage({id: "Occupation.917.0621",defaultMessage: "Art Dealer"}), value: "Art Dealer"},
  {code: '0621', title: defineMessage({id: "Occupation.918.0621",defaultMessage: "Car Dealer"}), value: "Car Dealer"},
  {code: '0621', title: defineMessage({id: "Occupation.919.0621",defaultMessage: "Coin Dealer"}), value: "Coin Dealer"},
  {code: '0621', title: defineMessage({id: "Occupation.920.0621",defaultMessage: "Jewellery Store Manager"}), value: "Jewellery Store Manager"},
  {code: '0621', title: defineMessage({id: "Occupation.921.0621",defaultMessage: "Retail Store Owner"}), value: "Retail Store Owner"},    
  {code: "0631", title: defineMessage({id: "Occupation.89.0631",defaultMessage: "Restaurant And Food Service Manager"}), value: "Restaurant And Food Service Manager"},
  {code: "0632", title: defineMessage({id: "Occupation.90.0632",defaultMessage: "Accommodation Service Manager"}), value: "Accommodation Service Manager"},
  {code: "0651", title: defineMessage({id: "Occupation.91.0651",defaultMessage: "Manager In Customer And Personal Services"}), value: "Manager In Customer And Personal Services"},
  {code: "0711", title: defineMessage({id: "Occupation.92.0711",defaultMessage: "Construction Manager"}), value: "Construction Manager"},
  {code: "0711", title: defineMessage({id: "Occupation.93.0711",defaultMessage: "General Contractor"}), value: "General Contractor"},
  {code: "0712", title: defineMessage({id: "Occupation.94.0712",defaultMessage: "Home Building And Renovation Manager"}), value: "Home Building And Renovation Manager"},
  {code: '0712', title: defineMessage({id: "Occupation.922.0712",defaultMessage: "Builder"}), value: "Builder"},
  {code: '0712', title: defineMessage({id: "Occupation.923.0712",defaultMessage: "Home Building Contractor"}), value: "Home Building Contractor"},
  {code: '0712', title: defineMessage({id: "Occupation.924.0712",defaultMessage: "Residential Home Builder"}), value: "Residential Home Builder"},
  {code: "0714", title: defineMessage({id: "Occupation.95.0714",defaultMessage: "Facility Operation And Maintenance Manager"}), value: "Facility Operation And Maintenance Manager"},
  {code: "0731", title: defineMessage({id: "Occupation.96.0731",defaultMessage: "Manager Transportation"}), value: "Manager Transportation"},
  {code: "0811", title: defineMessage({id: "Occupation.97.0811",defaultMessage: "Manager Natural Resources Production"}), value: "Manager Natural Resources Production"},
  {code: "0811", title: defineMessage({id: "Occupation.98.0811",defaultMessage: "Manager Fishing"}), value: "Manager Fishing"},
  {code: "0821", title: defineMessage({id: "Occupation.99.0821",defaultMessage: "Manager Agriculture"}), value: "Manager Agriculture"},
  {code: "0821", title: defineMessage({id: "Occupation.100.0821",defaultMessage: "Rancher"}), value: "Rancher"},
  {code: "0822", title: defineMessage({id: "Occupation.101.0822",defaultMessage: "Manager Horticulture"}), value: "Manager Horticulture"},
  {code: "0823", title: defineMessage({id: "Occupation.102.0823",defaultMessage: "Manager Aquaculture"}), value: "Manager Aquaculture"},
  {code: "0911", title: defineMessage({id: "Occupation.103.0911",defaultMessage: "Manager Manufacturing"}), value: "Manager Manufacturing"},
  {code: "0911", title: defineMessage({id: "Occupation.104.0911",defaultMessage: "Operations Manager Manufacturing"}), value: "Operations Manager Manufacturing"},
  {code: "0912", title: defineMessage({id: "Occupation.105.0912",defaultMessage: "Manager Utilities"}), value: "Manager Utilities"},
  {code: "1111", title: defineMessage({id: "Occupation.106.1111",defaultMessage: "Accountant"}), value: "Accountant"},
  {code: "1111", title: defineMessage({id: "Occupation.107.1111",defaultMessage: "Auditor"}), value: "Auditor"},
  {code: "1111", title: defineMessage({id: "Occupation.108.1111",defaultMessage: "Internal Auditor"}), value: "Internal Auditor"},
  {code: "1111", title: defineMessage({id: "Occupation.109.1111",defaultMessage: "External Auditor"}), value: "External Auditor"},
  {code: "1111", title: defineMessage({id: "Occupation.110.1111",defaultMessage: "Certified General Accountant"}), value: "Certified General Accountant"},
  {code: "1111", title: defineMessage({id: "Occupation.111.1111",defaultMessage: "Certified Public Accountant"}), value: "Certified Public Accountant"},
  {code: "1111", title: defineMessage({id: "Occupation.112.1111",defaultMessage: "Corporate Accountant"}), value: "Corporate Accountant"},
  {code: "1111", title: defineMessage({id: "Occupation.113.1111",defaultMessage: "Forensic Accountant"}), value: "Forensic Accountant"},
  {code: "1112", title: defineMessage({id: "Occupation.114.1112",defaultMessage: "Financial Analyst"}), value: "Financial Analyst"},
  {code: "1112", title: defineMessage({id: "Occupation.115.1112",defaultMessage: "Investment Analyst"}), value: "Investment Analyst"},
  {code: "1112", title: defineMessage({id: "Occupation.116.1112",defaultMessage: "Portfolio Manager"}), value: "Portfolio Manager"},
  {code: "1112", title: defineMessage({id: "Occupation.117.1112",defaultMessage: "Research Analyst"}), value: "Research Analyst"},
  {code: "1112", title: defineMessage({id: "Occupation.118.1112",defaultMessage: "Tax Analyst"}), value: "Tax Analyst"},
  {code: "1113", title: defineMessage({id: "Occupation.119.1113",defaultMessage: "Securities Agents, Investment Dealers And Brokers"}), value: "Securities Agents, Investment Dealers And Brokers"},
  {code: "1113", title: defineMessage({id: "Occupation.120.1113",defaultMessage: "Stockbroker"}), value: "Stockbroker"},
  {code: "1113", title: defineMessage({id: "Occupation.121.1113",defaultMessage: "Securities Sales Agent"}), value: "Securities Sales Agent"},
  {code: "1113", title: defineMessage({id: "Occupation.122.1113",defaultMessage: "Investment Dealer"}), value: "Investment Dealer"},
  {code: "1113", title: defineMessage({id: "Occupation.123.1113",defaultMessage: "Investment Broker"}), value: "Investment Broker"},
  {code: "1113", title: defineMessage({id: "Occupation.124.1113",defaultMessage: "Bond Dealer"}), value: "Bond Dealer"},
  {code: "1113", title: defineMessage({id: "Occupation.125.1113",defaultMessage: "Floor Trader-Commodity Exchange"}), value: "Floor Trader-Commodity Exchange"},
  {code: "1113", title: defineMessage({id: "Occupation.126.1113",defaultMessage: "Foreign Exchange Trader"}), value: "Foreign Exchange Trader"},
  {code: "1113", title: defineMessage({id: "Occupation.127.1113",defaultMessage: "Mutual Fund Broker"}), value: "Mutual Fund Broker"},
  {code: "1113", title: defineMessage({id: "Occupation.128.1113",defaultMessage: "Registered Investment Rep"}), value: "Registered Investment Rep"},
  {code: "1113", title: defineMessage({id: "Occupation.129.1113",defaultMessage: "Discount Broker"}), value: "Discount Broker"},
  {code: "1113", title: defineMessage({id: "Occupation.130.1113",defaultMessage: "Securities Trader"}), value: "Securities Trader"},
  {code: '1113', title: defineMessage({id: "Occupation.925.1113",defaultMessage: "Foreign Exchange Dealer"}), value: "Foreign Exchange Dealer"},
  {code: "1114", title: defineMessage({id: "Occupation.131.1114",defaultMessage: "Credit Adjudicator"}), value: "Credit Adjudicator"},
  {code: "1114", title: defineMessage({id: "Occupation.132.1114",defaultMessage: "Financial Planner"}), value: "Financial Planner"},
  {code: "1114", title: defineMessage({id: "Occupation.133.1114",defaultMessage: "Mortgage Broker"}), value: "Mortgage Broker"},
  {code: "1114", title: defineMessage({id: "Occupation.134.1114",defaultMessage: "Financial Investigator"}), value: "Financial Investigator"},
  {code: "1114", title: defineMessage({id: "Occupation.135.1114",defaultMessage: "Financial Underwriter"}), value: "Financial Underwriter"},
  {code: "1114", title: defineMessage({id: "Occupation.136.1114",defaultMessage: "Trust Officer"}), value: "Trust Officer"},
  {code: "1114", title: defineMessage({id: "Occupation.137.1114",defaultMessage: "Estate And Trust Administrator"}), value: "Estate And Trust Administrator"},
  {code: "1114", title: defineMessage({id: "Occupation.138.1114",defaultMessage: "Financial Institution Inspector"}), value: "Financial Institution Inspector"},
  {code: "1121", title: defineMessage({id: "Occupation.139.1121",defaultMessage: "Human Resources Professional"}), value: "Human Resources Professional"},
  {code: "1121", title: defineMessage({id: "Occupation.140.1121",defaultMessage: "Labour Union Business Agent"}), value: "Labour Union Business Agent"},
  {code: "1121", title: defineMessage({id: "Occupation.141.1121",defaultMessage: "Union Adviser"}), value: "Union Adviser"},
  {code: "1121", title: defineMessage({id: "Occupation.142.1121",defaultMessage: "Union Association President"}), value: "Union Association President"},
  {code: "1121", title: defineMessage({id: "Occupation.143.1121",defaultMessage: "Union Representative"}), value: "Union Representative"},
  {code: "1122", title: defineMessage({id: "Occupation.144.1122",defaultMessage: "Business Consultant"}), value: "Business Consultant"},
  {code: "1122", title: defineMessage({id: "Occupation.145.1122",defaultMessage: "Business Management Consultant"}), value: "Business Management Consultant"},
  {code: "1122", title: defineMessage({id: "Occupation.146.1122",defaultMessage: "Management Analyst"}), value: "Management Analyst"},
  {code: "1122", title: defineMessage({id: "Occupation.147.1122",defaultMessage: "Operations Management Consultant"}), value: "Operations Management Consultant"},
  {code: "1123", title: defineMessage({id: "Occupation.148.1123",defaultMessage: "Professional in Advertising or Marketing"}), value: "Professional in Advertising or Marketing"},
  {code: "1123", title: defineMessage({id: "Occupation.149.1123",defaultMessage: "Advertising Consultant"}), value: "Advertising Consultant"},
  {code: "1123", title: defineMessage({id: "Occupation.150.1123",defaultMessage: "Media Relations "}), value: "Media Relations "},
  {code: "1123", title: defineMessage({id: "Occupation.151.1123",defaultMessage: "Public Relations "}), value: "Public Relations "},
  {code: "1123", title: defineMessage({id: "Occupation.152.1123",defaultMessage: "Publicist"}), value: "Publicist"},
  {code: "1211", title: defineMessage({id: "Occupation.153.1211",defaultMessage: "General Office And Admin Support Supervisor"}), value: "General Office And Admin Support Supervisor"},
  {code: "1212", title: defineMessage({id: "Occupation.154.1212",defaultMessage: "Finance And Insurance Office Supervisor"}), value: "Finance And Insurance Office Supervisor"},
  {code: "1213", title: defineMessage({id: "Occupation.155.1213",defaultMessage: "Library And Related Information Supervisor"}), value: "Library And Related Information Supervisor"},
  {code: "1214", title: defineMessage({id: "Occupation.156.1214",defaultMessage: "Mail And Message Distribution Supervisor"}), value: "Mail And Message Distribution Supervisor"},
  {code: "1215", title: defineMessage({id: "Occupation.157.1215",defaultMessage: "Supervisor Supply Chain Logistics"}), value: "Supervisor Supply Chain Logistics"},
  {code: "1221", title: defineMessage({id: "Occupation.158.1221",defaultMessage: "Administrative Officer"}), value: "Administrative Officer"},
  {code: "1221", title: defineMessage({id: "Occupation.159.1221",defaultMessage: "Office Administrator"}), value: "Office Administrator"},
  {code: "1221", title: defineMessage({id: "Occupation.160.1221",defaultMessage: "Office Manager"}), value: "Office Manager"},
  {code: "1221", title: defineMessage({id: "Occupation.161.1221",defaultMessage: "Liaison Officer"}), value: "Liaison Officer"},
  {code: "1221", title: defineMessage({id: "Occupation.162.1221",defaultMessage: "Access To Information And Privacy Officer"}), value: "Access To Information And Privacy Officer"},
  {code: "1221", title: defineMessage({id: "Occupation.163.1221",defaultMessage: "Admissions (Academic)"}), value: "Admissions (Academic)"},
  {code: "1221", title: defineMessage({id: "Occupation.164.1221",defaultMessage: "Project Manager"}), value: "Project Manager"},
  {code: "1222", title: defineMessage({id: "Occupation.165.1222",defaultMessage: "Executive Assistant"}), value: "Executive Assistant"},
  {code: "1223", title: defineMessage({id: "Occupation.166.1223",defaultMessage: "Human Resources And Recruitment Officers"}), value: "Human Resources And Recruitment Officers"},
  {code: "1224", title: defineMessage({id: "Occupation.167.1224",defaultMessage: "Property Administrator"}), value: "Property Administrator"},
  {code: "1224", title: defineMessage({id: "Occupation.168.1224",defaultMessage: "Property Acquisition"}), value: "Property Acquisition"},
  {code: "1224", title: defineMessage({id: "Occupation.169.1224",defaultMessage: "Property Management Officer"}), value: "Property Management Officer"},
  {code: "1224", title: defineMessage({id: "Occupation.170.1224",defaultMessage: "Property Manager"}), value: "Property Manager"},
  {code: "1224", title: defineMessage({id: "Occupation.171.1224",defaultMessage: "Building Superintendent"}), value: "Building Superintendent"},
  {code: "1225", title: defineMessage({id: "Occupation.172.1225",defaultMessage: "Purchasing Agents And Officers"}), value: "Purchasing Agents And Officers"},
  {code: "1226", title: defineMessage({id: "Occupation.173.1226",defaultMessage: "Conference And Event Planner"}), value: "Conference And Event Planner"},
  {code: "1227", title: defineMessage({id: "Occupation.174.1227",defaultMessage: "Court Officer"}), value: "Court Officer"},
  {code: "1227", title: defineMessage({id: "Occupation.175.1227",defaultMessage: "Court Services"}), value: "Court Services"},
  {code: "1227", title: defineMessage({id: "Occupation.176.1227",defaultMessage: "Registrar, Courts"}), value: "Registrar, Courts"},
  {code: "1227", title: defineMessage({id: "Occupation.177.1227",defaultMessage: "Judicial Officer"}), value: "Judicial Officer"},
  {code: "1227", title: defineMessage({id: "Occupation.178.1227",defaultMessage: "Co-Ordinator Of Trials"}), value: "Co-Ordinator Of Trials"},
  {code: "1227", title: defineMessage({id: "Occupation.179.1227",defaultMessage: "Justice Of The Peace"}), value: "Justice Of The Peace"},
  {code: "1228", title: defineMessage({id: "Occupation.180.1228",defaultMessage: "Border Services Officer"}), value: "Border Services Officer"},
  {code: "1228", title: defineMessage({id: "Occupation.181.1228",defaultMessage: "Customs Inspector"}), value: "Customs Inspector"},
  {code: "1228", title: defineMessage({id: "Occupation.182.1228",defaultMessage: "Customs Appraiser"}), value: "Customs Appraiser"},
  {code: "1228", title: defineMessage({id: "Occupation.183.1228",defaultMessage: "Employment Insurance Agent"}), value: "Employment Insurance Agent"},
  {code: "1228", title: defineMessage({id: "Occupation.184.1228",defaultMessage: "Immigration Officer"}), value: "Immigration Officer"},
  {code: "1228", title: defineMessage({id: "Occupation.185.1228",defaultMessage: "Tax Collection Officer"}), value: "Tax Collection Officer"},
  {code: "1241", title: defineMessage({id: "Occupation.186.1241",defaultMessage: "Admin Assistants"}), value: "Admin Assistants"},
  {code: "1242", title: defineMessage({id: "Occupation.187.1242",defaultMessage: "Legal Admin Assistants"}), value: "Legal Admin Assistants"},
  {code: "1242", title: defineMessage({id: "Occupation.188.1242",defaultMessage: "Legal Assistant"}), value: "Legal Assistant"},
  {code: "1243", title: defineMessage({id: "Occupation.189.1243",defaultMessage: "Medical Admin Assistants"}), value: "Medical Admin Assistants"},
  {code: "1251", title: defineMessage({id: "Occupation.190.1251",defaultMessage: "Court Reporter"}), value: "Court Reporter"},
  {code: "1251", title: defineMessage({id: "Occupation.191.1251",defaultMessage: "Court Stenographer"}), value: "Court Stenographer"},
  {code: "1251", title: defineMessage({id: "Occupation.192.1251",defaultMessage: "Court Transcriber"}), value: "Court Transcriber"},
  {code: "1251", title: defineMessage({id: "Occupation.193.1251",defaultMessage: "Deposition Reporter"}), value: "Deposition Reporter"},
  {code: "1251", title: defineMessage({id: "Occupation.194.1251",defaultMessage: "Medical Transcriber"}), value: "Medical Transcriber"},
  {code: "1251", title: defineMessage({id: "Occupation.195.1251",defaultMessage: "Medical Stenographer"}), value: "Medical Stenographer"},
  {code: "1252", title: defineMessage({id: "Occupation.196.1252",defaultMessage: "Health Information Management"}), value: "Health Information Management"},
  {code: "1253", title: defineMessage({id: "Occupation.197.1253",defaultMessage: "Records Management Technician"}), value: "Records Management Technician"},
  {code: "1254", title: defineMessage({id: "Occupation.198.1254",defaultMessage: "Statistical Officer"}), value: "Statistical Officer"},
  {code: "1254", title: defineMessage({id: "Occupation.199.1254",defaultMessage: "Statistical Researcher"}), value: "Statistical Researcher"},
  {code: "1311", title: defineMessage({id: "Occupation.200.1311",defaultMessage: "Bookkeeper"}), value: "Bookkeeper"},
  {code: "1311", title: defineMessage({id: "Occupation.201.1311",defaultMessage: "Accounting Bookkeeper"}), value: "Accounting Bookkeeper"},
  {code: "1311", title: defineMessage({id: "Occupation.202.1311",defaultMessage: "Accounting Technician"}), value: "Accounting Technician"},
  {code: "1312", title: defineMessage({id: "Occupation.203.1312",defaultMessage: "Insurance Adjusters And Claims Examiners"}), value: "Insurance Adjusters And Claims Examiners"},
  {code: "1313", title: defineMessage({id: "Occupation.204.1313",defaultMessage: "Insurance Underwriter"}), value: "Insurance Underwriter"},
  {code: "1314", title: defineMessage({id: "Occupation.205.1314",defaultMessage: "Assessors, Valuators And Appraiser"}), value: "Assessors, Valuators And Appraiser"},
  {code: "1314", title: defineMessage({id: "Occupation.206.1314",defaultMessage: "Appraiser (Except Customs)"}), value: "Appraiser (Except Customs)"},
  {code: "1314", title: defineMessage({id: "Occupation.207.1314",defaultMessage: "Real Estate Analyst"}), value: "Real Estate Analyst"},
  {code: '1314', title: defineMessage({id: "Occupation.926.1314",defaultMessage: "Property Appraiser"}), value: "Property Appraiser"},
  {code: '1314', title: defineMessage({id: "Occupation.927.1314",defaultMessage: "Real Estate Appraiser"}), value: "Real Estate Appraiser"},
  {code: "1315", title: defineMessage({id: "Occupation.208.1315",defaultMessage: "Licensed Customs Broker"}), value: "Licensed Customs Broker"},
  {code: "1315", title: defineMessage({id: "Occupation.209.1315",defaultMessage: "Customs Broker"}), value: "Customs Broker"},
  {code: "1315", title: defineMessage({id: "Occupation.210.1315",defaultMessage: "Shipbroker"}), value: "Shipbroker"},
  {code: "1315", title: defineMessage({id: "Occupation.211.1315",defaultMessage: "Chartered Shipbroker"}), value: "Chartered Shipbroker"},
  {code: "1411", title: defineMessage({id: "Occupation.212.1411",defaultMessage: "General Office Worker"}), value: "General Office Worker"},
  {code: "1414", title: defineMessage({id: "Occupation.213.1414",defaultMessage: "Receptionist"}), value: "Receptionist"},
  {code: "1415", title: defineMessage({id: "Occupation.214.1415",defaultMessage: "Personnel Clerk"}), value: "Personnel Clerk"},
  {code: "1416", title: defineMessage({id: "Occupation.215.1416",defaultMessage: "Court Clerk"}), value: "Court Clerk"},
  {code: "1422", title: defineMessage({id: "Occupation.216.1422",defaultMessage: "Data Entry Clerk"}), value: "Data Entry Clerk"},
  {code: "1423", title: defineMessage({id: "Occupation.217.1423",defaultMessage: "Desktop Publishing Operators"}), value: "Desktop Publishing Operators"},
  {code: "1431", title: defineMessage({id: "Occupation.218.1431",defaultMessage: "Accounting And Related Clerk"}), value: "Accounting And Related Clerk"},
  {code: "1434", title: defineMessage({id: "Occupation.232.1434",defaultMessage: "Credit Clerk"}), value: "Credit Clerk"},
  {code: "1434", title: defineMessage({id: "Occupation.233.1434",defaultMessage: "Loans Or Mortgage Clerk"}), value: "Loans Or Mortgage Clerk"},
  {code: "1434", title: defineMessage({id: "Occupation.234.1434",defaultMessage: "Other Financial Clerk"}), value: "Other Financial Clerk"},
  {code: "1435", title: defineMessage({id: "Occupation.235.1435",defaultMessage: "Collectors"}), value: "Collectors"},
  {code: "1435", title: defineMessage({id: "Occupation.236.1435",defaultMessage: "Collection Officer (Except Taxation)"}), value: "Collection Officer (Except Taxation)"},
  {code: "1451", title: defineMessage({id: "Occupation.237.1451",defaultMessage: "Library Assistants And Clerk"}), value: "Library Assistants And Clerk"},
  {code: "1452", title: defineMessage({id: "Occupation.238.1452",defaultMessage: "Correspondence, Publication And Regulatory Clerk"}), value: "Correspondence, Publication And Regulatory Clerk"},
  {code: "1454", title: defineMessage({id: "Occupation.239.1454",defaultMessage: "Survey Interviewers And Statistical Clerk"}), value: "Survey Interviewers And Statistical Clerk"},
  {code: "1511", title: defineMessage({id: "Occupation.240.1511",defaultMessage: "Mail Sorter"}), value: "Mail Sorter"},
  {code: "1511", title: defineMessage({id: "Occupation.241.1511",defaultMessage: "Mail Room Attendant"}), value: "Mail Room Attendant"},
  {code: "1511", title: defineMessage({id: "Occupation.242.1511",defaultMessage: "Express Mail Clerk"}), value: "Express Mail Clerk"},
  {code: "1511", title: defineMessage({id: "Occupation.243.1511",defaultMessage: "Postal Counter Attendant"}), value: "Postal Counter Attendant"},
  {code: "1512", title: defineMessage({id: "Occupation.244.1512",defaultMessage: "Letter Carrier"}), value: "Letter Carrier"},
  {code: "1513", title: defineMessage({id: "Occupation.245.1513",defaultMessage: "Courier  "}), value: "Courier  "},
  {code: "1513", title: defineMessage({id: "Occupation.246.1513",defaultMessage: "Public Notice Courier"}), value: "Public Notice Courier"},
  {code: "1513", title: defineMessage({id: "Occupation.247.1513",defaultMessage: "Office Messenger"}), value: "Office Messenger"},
  {code: "1513", title: defineMessage({id: "Occupation.248.1513",defaultMessage: "Delivery Person"}), value: "Delivery Person"},
  {code: "1513", title: defineMessage({id: "Occupation.249.1513",defaultMessage: "Door-To-Door Distributor"}), value: "Door-To-Door Distributor"},
  {code: "1521", title: defineMessage({id: "Occupation.250.1521",defaultMessage: "Shippers And Receivers"}), value: "Shippers And Receivers"},
  {code: "1522", title: defineMessage({id: "Occupation.251.1522",defaultMessage: "Storekeepers And Partspersons"}), value: "Storekeepers And Partspersons"},
  {code: "1523", title: defineMessage({id: "Occupation.252.1523",defaultMessage: "Production Logistics Co-Ordinators"}), value: "Production Logistics Co-Ordinators"},
  {code: "1524", title: defineMessage({id: "Occupation.253.1524",defaultMessage: "Purchasing And Inventory Control Worker"}), value: "Purchasing And Inventory Control Worker"},
  {code: "1524", title: defineMessage({id: "Occupation.254.1524",defaultMessage: "Inventory Control"}), value: "Inventory Control"},
  {code: "1524", title: defineMessage({id: "Occupation.255.1524",defaultMessage: "Purchasing Control"}), value: "Purchasing Control"},
  {code: "1524", title: defineMessage({id: "Occupation.256.1524",defaultMessage: "Procurement"}), value: "Procurement"},
  {code: "1525", title: defineMessage({id: "Occupation.257.1525",defaultMessage: "Dispatcher"}), value: "Dispatcher"},
  {code: "1525", title: defineMessage({id: "Occupation.258.1525",defaultMessage: "911 Dispatcher"}), value: "911 Dispatcher"},
  {code: "1525", title: defineMessage({id: "Occupation.259.1525",defaultMessage: "Taxi Dispatcher"}), value: "Taxi Dispatcher"},
  {code: "1525", title: defineMessage({id: "Occupation.260.1525",defaultMessage: "Tow Truck Dispatcher"}), value: "Tow Truck Dispatcher"},
  {code: "1525", title: defineMessage({id: "Occupation.261.1525",defaultMessage: "Dispatcher Utilities Maintenance Crews"}), value: "Dispatcher Utilities Maintenance Crews"},
  {code: "1526", title: defineMessage({id: "Occupation.262.1526",defaultMessage: "Transportation Route And Crew Schedulers"}), value: "Transportation Route And Crew Schedulers"},
  {code: "2111", title: defineMessage({id: "Occupation.263.2111",defaultMessage: "Physicist"}), value: "Physicist"},
  {code: "2111", title: defineMessage({id: "Occupation.264.2111",defaultMessage: "Astronomer"}), value: "Astronomer"},
  {code: "2112", title: defineMessage({id: "Occupation.265.2112",defaultMessage: "Chemist"}), value: "Chemist"},
  {code: "2113", title: defineMessage({id: "Occupation.266.2113",defaultMessage: "Geoscientist"}), value: "Geoscientist"},
  {code: "2113", title: defineMessage({id: "Occupation.267.2113",defaultMessage: "Oceanographer"}), value: "Oceanographer"},
  {code: "2113", title: defineMessage({id: "Occupation.268.2113",defaultMessage: "Geologist"}), value: "Geologist"},
  {code: "2114", title: defineMessage({id: "Occupation.269.2114",defaultMessage: "Meteorologists and Climatologists"}), value: "Meteorologists and Climatologists"},
  {code: "2115", title: defineMessage({id: "Occupation.270.2115",defaultMessage: "Professional Physical Sciences"}), value: "Professional Physical Sciences"},
  {code: "2121", title: defineMessage({id: "Occupation.271.2121",defaultMessage: "Biologist"}), value: "Biologist"},
  {code: "2122", title: defineMessage({id: "Occupation.272.2122",defaultMessage: "Forestry Professionals"}), value: "Forestry Professionals"},
  {code: "2123", title: defineMessage({id: "Occupation.273.2123",defaultMessage: "Agricultural Reps"}), value: "Agricultural Reps"},
  {code: "2124", title: defineMessage({id: "Occupation.274.2124",defaultMessage: "Agricultural Consultant"}), value: "Agricultural Consultant"},
  {code: "2125", title: defineMessage({id: "Occupation.275.2125",defaultMessage: "Agricultural Specialists"}), value: "Agricultural Specialists"},
  {code: "2131", title: defineMessage({id: "Occupation.276.2131",defaultMessage: "Civil Engineers"}), value: "Civil Engineers"},
  {code: "2132", title: defineMessage({id: "Occupation.277.2132",defaultMessage: "Mechanical Engineers"}), value: "Mechanical Engineers"},
  {code: "2133", title: defineMessage({id: "Occupation.278.2133",defaultMessage: "Electrical And Electronics Engineers"}), value: "Electrical And Electronics Engineers"},
  {code: "2134", title: defineMessage({id: "Occupation.279.2134",defaultMessage: "Chemical Engineers"}), value: "Chemical Engineers"},
  {code: "2141", title: defineMessage({id: "Occupation.280.2141",defaultMessage: "Industrial and Manufacturing Engineers"}), value: "Industrial and Manufacturing Engineers"},
  {code: "2142", title: defineMessage({id: "Occupation.281.2142",defaultMessage: "Metallurgical and Materials Engineers"}), value: "Metallurgical and Materials Engineers"},
  {code: "2143", title: defineMessage({id: "Occupation.282.2143",defaultMessage: "Mining Engineers"}), value: "Mining Engineers"},
  {code: "2144", title: defineMessage({id: "Occupation.283.2144",defaultMessage: "Geological Engineers"}), value: "Geological Engineers"},
  {code: "2145", title: defineMessage({id: "Occupation.284.2145",defaultMessage: "Petro Engineers"}), value: "Petro Engineers"},
  {code: "2146", title: defineMessage({id: "Occupation.285.2146",defaultMessage: "Aerospace Engineers"}), value: "Aerospace Engineers"},
  {code: "2147", title: defineMessage({id: "Occupation.286.2147",defaultMessage: "Computer Engineers"}), value: "Computer Engineers"},
  {code: "2148", title: defineMessage({id: "Occupation.287.2148",defaultMessage: "Other Professional Engineers"}), value: "Other Professional Engineers"},
  {code: "2151", title: defineMessage({id: "Occupation.288.2151",defaultMessage: "Architect"}), value: "Architect"},
  {code: "2152", title: defineMessage({id: "Occupation.289.2152",defaultMessage: "Landscape Architect"}), value: "Landscape Architect"},
  {code: "2153", title: defineMessage({id: "Occupation.290.2153",defaultMessage: "Urban And Land Use Planners"}), value: "Urban And Land Use Planners"},
  {code: "2154", title: defineMessage({id: "Occupation.291.2154",defaultMessage: "Land Surveyors"}), value: "Land Surveyors"},
  {code: "2161", title: defineMessage({id: "Occupation.292.2161",defaultMessage: "Actuary"}), value: "Actuary"},
  {code: "2161", title: defineMessage({id: "Occupation.293.2161",defaultMessage: "Mathematician"}), value: "Mathematician"},
  {code: "2161", title: defineMessage({id: "Occupation.294.2161",defaultMessage: "Statistician"}), value: "Statistician"},
  {code: "2171", title: defineMessage({id: "Occupation.295.2171",defaultMessage: "Business Analyst (IT)"}), value: "Business Analyst (IT)"},
  {code: "2171", title: defineMessage({id: "Occupation.296.2171",defaultMessage: "Information Technology (IT) Consultant"}), value: "Information Technology (IT) Consultant"},
  {code: "2171", title: defineMessage({id: "Occupation.297.2171",defaultMessage: "Information Security Analyst"}), value: "Information Security Analyst"},
  {code: "2171", title: defineMessage({id: "Occupation.298.2171",defaultMessage: "Information Systems Analyst"}), value: "Information Systems Analyst"},
  {code: '2171', title: defineMessage({id: "Occupation.928.2171",defaultMessage: "Information Technology (IT) Analyst"}), value: "Information Technology (IT) Analyst"},
  {code: "2172", title: defineMessage({id: "Occupation.299.2172",defaultMessage: "Database Analyst"}), value: "Database Analyst"},
  {code: "2172", title: defineMessage({id: "Occupation.300.2172",defaultMessage: "Data Administrator"}), value: "Data Administrator"},
  {code: "2173", title: defineMessage({id: "Occupation.301.2173",defaultMessage: "Software Engineer"}), value: "Software Engineer"},
  {code: "2173", title: defineMessage({id: "Occupation.302.2173",defaultMessage: "Software Designer"}), value: "Software Designer"},
  {code: "2174", title: defineMessage({id: "Occupation.303.2174",defaultMessage: "Computer Programmer"}), value: "Computer Programmer"},
  {code: "2174", title: defineMessage({id: "Occupation.304.2174",defaultMessage: "Interactive Media Developer"}), value: "Interactive Media Developer"},
  {code: '2174', title: defineMessage({id: "Occupation.929.2174",defaultMessage: "Software Developer"}), value: "Software Developer"},
  {code: "2175", title: defineMessage({id: "Occupation.305.2175",defaultMessage: "Web Designers And Developers"}), value: "Web Designers And Developers"},
  {code: "2211", title: defineMessage({id: "Occupation.306.2211",defaultMessage: "Chemical Technician"}), value: "Chemical Technician"},
  {code: "2212", title: defineMessage({id: "Occupation.307.2212",defaultMessage: "Mineral Technician"}), value: "Mineral Technician"},
  {code: "2212", title: defineMessage({id: "Occupation.308.2212",defaultMessage: "Geological Technician"}), value: "Geological Technician"},
  {code: "2221", title: defineMessage({id: "Occupation.309.2221",defaultMessage: "Biological Technician"}), value: "Biological Technician"},
  {code: "2222", title: defineMessage({id: "Occupation.310.2222",defaultMessage: "Fish Products Inspector"}), value: "Fish Products Inspector"},
  {code: "2222", title: defineMessage({id: "Occupation.311.2222",defaultMessage: "Agricultural Products Inspector"}), value: "Agricultural Products Inspector"},
  {code: "2223", title: defineMessage({id: "Occupation.312.2223",defaultMessage: "Forestry Technician"}), value: "Forestry Technician"},
  {code: "2224", title: defineMessage({id: "Occupation.313.2224",defaultMessage: "Conservation Officer"}), value: "Conservation Officer"},
  {code: "2224", title: defineMessage({id: "Occupation.314.2224",defaultMessage: "Fishery Officer"}), value: "Fishery Officer"},
  {code: "2224", title: defineMessage({id: "Occupation.315.2224",defaultMessage: "Park Ranger"}), value: "Park Ranger"},
  {code: "2224", title: defineMessage({id: "Occupation.316.2224",defaultMessage: "Games Warden"}), value: "Games Warden"},
  {code: "2231", title: defineMessage({id: "Occupation.317.2231",defaultMessage: "Civil Engineering Technician"}), value: "Civil Engineering Technician"},
  {code: "2232", title: defineMessage({id: "Occupation.318.2232",defaultMessage: "Mechanical Engineering Technician"}), value: "Mechanical Engineering Technician"},
  {code: "2233", title: defineMessage({id: "Occupation.319.2233",defaultMessage: "Industrial Engineering And Mfg Technician"}), value: "Industrial Engineering And Mfg Technician"},
  {code: "2234", title: defineMessage({id: "Occupation.320.2234",defaultMessage: "Construction Estimators"}), value: "Construction Estimators"},
  {code: "2241", title: defineMessage({id: "Occupation.321.2241",defaultMessage: "Electrical And Electronics Engineering Technician"}), value: "Electrical And Electronics Engineering Technician"},
  {code: "2242", title: defineMessage({id: "Occupation.322.2242",defaultMessage: "Electronic Service Technician"}), value: "Electronic Service Technician"},
  {code: "2243", title: defineMessage({id: "Occupation.323.2243",defaultMessage: "Industrial Instrument Technician"}), value: "Industrial Instrument Technician"},
  {code: "2243", title: defineMessage({id: "Occupation.324.2243",defaultMessage: "Industrial Instrument Mechanic"}), value: "Industrial Instrument Mechanic"},
  {code: "2244", title: defineMessage({id: "Occupation.325.2244",defaultMessage: "Aircraft Instrument Technician and Inspection"}), value: "Aircraft Instrument Technician and Inspection"},
  {code: "2244", title: defineMessage({id: "Occupation.326.2244",defaultMessage: "Avionics Mechanics Technician and Inspection"}), value: "Avionics Mechanics Technician and Inspection"},
  {code: "2251", title: defineMessage({id: "Occupation.327.2251",defaultMessage: "Architectural Technician"}), value: "Architectural Technician"},
  {code: "2252", title: defineMessage({id: "Occupation.328.2252",defaultMessage: "Industrial Designers"}), value: "Industrial Designers"},
  {code: "2253", title: defineMessage({id: "Occupation.329.2253",defaultMessage: "Drafter"}), value: "Drafter"},
  {code: "2253", title: defineMessage({id: "Occupation.330.2253",defaultMessage: "Draftperson"}), value: "Draftperson"},
  {code: "2254", title: defineMessage({id: "Occupation.331.2254",defaultMessage: "Land Survey Technician"}), value: "Land Survey Technician"},
  {code: "2255", title: defineMessage({id: "Occupation.332.2255",defaultMessage: "Technical Geomatics And Meteorology"}), value: "Technical Geomatics And Meteorology"},
  {code: "2261", title: defineMessage({id: "Occupation.333.2261",defaultMessage: "Non-Destructive Testers and Inspection Technician"}), value: "Non-Destructive Testers and Inspection Technician"},
  {code: "2262", title: defineMessage({id: "Occupation.334.2262",defaultMessage: "Engineering Inspection and Regulatory Officers"}), value: "Engineering Inspection and Regulatory Officers"},
  {code: "2262", title: defineMessage({id: "Occupation.335.2262",defaultMessage: "Engineering Inspector"}), value: "Engineering Inspector"},
  {code: "2262", title: defineMessage({id: "Occupation.336.2262",defaultMessage: "Electricity and Gas Meter Inspector"}), value: "Electricity and Gas Meter Inspector"},
  {code: "2262", title: defineMessage({id: "Occupation.337.2262",defaultMessage: "Weights and Measures Inspector"}), value: "Weights and Measures Inspector"},
  {code: "2262", title: defineMessage({id: "Occupation.338.2262",defaultMessage: "Air Transport Inspector"}), value: "Air Transport Inspector"},
  {code: "2263", title: defineMessage({id: "Occupation.339.2263",defaultMessage: "Health and Safety Inspector"}), value: "Health and Safety Inspector"},
  {code: "2263", title: defineMessage({id: "Occupation.340.2263",defaultMessage: "Environmental Health Inspector"}), value: "Environmental Health Inspector"},
  {code: "2263", title: defineMessage({id: "Occupation.341.2263",defaultMessage: "Health And Safety Officer"}), value: "Health And Safety Officer"},
  {code: "2263", title: defineMessage({id: "Occupation.342.2263",defaultMessage: "Public Health Inspector"}), value: "Public Health Inspector"},
  {code: "2263", title: defineMessage({id: "Occupation.343.2263",defaultMessage: "Water Inspector"}), value: "Water Inspector"},
  {code: "2263", title: defineMessage({id: "Occupation.344.2263",defaultMessage: "Hazardous Waste Inspector"}), value: "Hazardous Waste Inspector"},
  {code: "2263", title: defineMessage({id: "Occupation.345.2263",defaultMessage: "Occupational Health and Safety Inspector"}), value: "Occupational Health and Safety Inspector"},
  {code: "2264", title: defineMessage({id: "Occupation.346.2264",defaultMessage: "Construction Inspector"}), value: "Construction Inspector"},
  {code: "2271", title: defineMessage({id: "Occupation.347.2271",defaultMessage: "Air Pilot"}), value: "Air Pilot"},
  {code: "2271", title: defineMessage({id: "Occupation.348.2271",defaultMessage: "Flight Engineers"}), value: "Flight Engineers"},
  {code: "2271", title: defineMessage({id: "Occupation.349.2271",defaultMessage: "Flying Instructors"}), value: "Flying Instructors"},
  {code: "2272", title: defineMessage({id: "Occupation.350.2272",defaultMessage: "Air Traffic Controller"}), value: "Air Traffic Controller"},
  {code: "2273", title: defineMessage({id: "Occupation.351.2273",defaultMessage: "Ship Pilot"}), value: "Ship Pilot"},
  {code: "2273", title: defineMessage({id: "Occupation.352.2273",defaultMessage: "Ship Captain"}), value: "Ship Captain"},
  {code: "2273", title: defineMessage({id: "Occupation.353.2273",defaultMessage: "Ship Mate"}), value: "Ship Mate"},
  {code: "2273", title: defineMessage({id: "Occupation.354.2273",defaultMessage: "Tugboat Captain"}), value: "Tugboat Captain"},
  {code: "2273", title: defineMessage({id: "Occupation.355.2273",defaultMessage: "Deck Officer  "}), value: "Deck Officer  "},
  {code: "2274", title: defineMessage({id: "Occupation.356.2274",defaultMessage: "Engineer Officers, Water Transport"}), value: "Engineer Officers, Water Transport"},
  {code: "2275", title: defineMessage({id: "Occupation.357.2275",defaultMessage: "Railway Traffic Controllers"}), value: "Railway Traffic Controllers"},
  {code: "2275", title: defineMessage({id: "Occupation.358.2275",defaultMessage: "Marine Traffic Regulators"}), value: "Marine Traffic Regulators"},
  {code: "2275", title: defineMessage({id: "Occupation.359.2275",defaultMessage: "Train Operator"}), value: "Train Operator"},
  {code: "2281", title: defineMessage({id: "Occupation.360.2281",defaultMessage: "Computer Network Technician"}), value: "Computer Network Technician"},
  {code: '2281', title: defineMessage({id: "Occupation.930.2281",defaultMessage: "System Administrator"}), value: "System Administrator"},
  {code: "2282", title: defineMessage({id: "Occupation.361.2282",defaultMessage: "User Support Technician"}), value: "User Support Technician"},
  {code: "2283", title: defineMessage({id: "Occupation.362.2283",defaultMessage: "Information Systems Testing Technician"}), value: "Information Systems Testing Technician"},
  {code: "3011", title: defineMessage({id: "Occupation.363.3011",defaultMessage: "Nursing Co-Ordinators and Supervisors"}), value: "Nursing Co-Ordinators and Supervisors"},
  {code: "3012", title: defineMessage({id: "Occupation.364.3012",defaultMessage: "Registered Nurses"}), value: "Registered Nurses"},
  {code: "3012", title: defineMessage({id: "Occupation.365.3012",defaultMessage: "Nurse"}), value: "Nurse"},
  {code: "3012", title: defineMessage({id: "Occupation.366.3012",defaultMessage: "Registered Psychiatric Nurses"}), value: "Registered Psychiatric Nurses"},
  {code: "3111", title: defineMessage({id: "Occupation.367.3111",defaultMessage: "Specialist Physicians"}), value: "Specialist Physicians"},
  {code: "3112", title: defineMessage({id: "Occupation.368.3112",defaultMessage: "General Practitioners And Family Physicians"}), value: "General Practitioners And Family Physicians"},
  {code: "3112", title: defineMessage({id: "Occupation.369.3112",defaultMessage: "Medical Doctor"}), value: "Medical Doctor"},
  {code: "3113", title: defineMessage({id: "Occupation.370.3113",defaultMessage: "Dentist"}), value: "Dentist"},
  {code: "3114", title: defineMessage({id: "Occupation.371.3114",defaultMessage: "Veterinarian"}), value: "Veterinarian"},
  {code: "3121", title: defineMessage({id: "Occupation.372.3121",defaultMessage: "Optometrist"}), value: "Optometrist"},
  {code: "3122", title: defineMessage({id: "Occupation.373.3122",defaultMessage: "Chiropractor"}), value: "Chiropractor"},
  {code: "3124", title: defineMessage({id: "Occupation.374.3124",defaultMessage: "Allied Primary Health Practitioners"}), value: "Allied Primary Health Practitioners"},
  {code: "3124", title: defineMessage({id: "Occupation.375.3124",defaultMessage: "Midwife"}), value: "Midwife"},
  {code: "3124", title: defineMessage({id: "Occupation.376.3124",defaultMessage: "Nurse Practitioner"}), value: "Nurse Practitioner"},
  {code: "3124", title: defineMessage({id: "Occupation.377.3124",defaultMessage: "Anesthesia Assistant"}), value: "Anesthesia Assistant"},
  {code: "3125", title: defineMessage({id: "Occupation.378.3125",defaultMessage: "Other Professional Health Diagnosing and Treating"}), value: "Other Professional Health Diagnosing and Treating"},
  {code: "3125", title: defineMessage({id: "Occupation.379.3125",defaultMessage: "Osteopath"}), value: "Osteopath"},
  {code: "3125", title: defineMessage({id: "Occupation.380.3125",defaultMessage: "Chiropodist"}), value: "Chiropodist"},
  {code: "3125", title: defineMessage({id: "Occupation.381.3125",defaultMessage: "Naturopath"}), value: "Naturopath"},
  {code: "3125", title: defineMessage({id: "Occupation.382.3125",defaultMessage: "Podiatrist"}), value: "Podiatrist"},
  {code: "3131", title: defineMessage({id: "Occupation.383.3131",defaultMessage: "Pharmacist"}), value: "Pharmacist"},
  {code: "3132", title: defineMessage({id: "Occupation.384.3132",defaultMessage: "Dietitians And Nutritionists"}), value: "Dietitians And Nutritionists"},
  {code: "3141", title: defineMessage({id: "Occupation.385.3141",defaultMessage: "Audiologists"}), value: "Audiologists"},
  {code: "3141", title: defineMessage({id: "Occupation.386.3141",defaultMessage: "Speech-Language Pathologists"}), value: "Speech-Language Pathologists"},
  {code: "3142", title: defineMessage({id: "Occupation.387.3142",defaultMessage: "Physiotherapist"}), value: "Physiotherapist"},
  {code: "3143", title: defineMessage({id: "Occupation.388.3143",defaultMessage: "Occupational Therapist"}), value: "Occupational Therapist"},
  {code: "3144", title: defineMessage({id: "Occupation.389.3144",defaultMessage: "Other Professional Therapy and Assessment"}), value: "Other Professional Therapy and Assessment"},
  {code: "3211", title: defineMessage({id: "Occupation.390.3211",defaultMessage: "Medical Laboratory Technologist"}), value: "Medical Laboratory Technologist"},
  {code: "3212", title: defineMessage({id: "Occupation.391.3212",defaultMessage: "Medical Laboratory Technician"}), value: "Medical Laboratory Technician"},
  {code: "3212", title: defineMessage({id: "Occupation.392.3212",defaultMessage: "Pathologist Assistant"}), value: "Pathologist Assistant"},
  {code: "3213", title: defineMessage({id: "Occupation.393.3213",defaultMessage: "Animal Health Technologists"}), value: "Animal Health Technologists"},
  {code: "3214", title: defineMessage({id: "Occupation.394.3214",defaultMessage: "Respiratory Therapist"}), value: "Respiratory Therapist"},
  {code: "3214", title: defineMessage({id: "Occupation.395.3214",defaultMessage: "Clinical Perfusionist"}), value: "Clinical Perfusionist"},
  {code: "3214", title: defineMessage({id: "Occupation.396.3214",defaultMessage: "Cardiopulmonary Technologists"}), value: "Cardiopulmonary Technologists"},
  {code: "3215", title: defineMessage({id: "Occupation.397.3215",defaultMessage: "Medical Radiation Technologists"}), value: "Medical Radiation Technologists"},
  {code: "3216", title: defineMessage({id: "Occupation.398.3216",defaultMessage: "Medical Sonographers"}), value: "Medical Sonographers"},
  {code: "3217", title: defineMessage({id: "Occupation.399.3217",defaultMessage: "Cardiology Technologists"}), value: "Cardiology Technologists"},
  {code: "3217", title: defineMessage({id: "Occupation.400.3217",defaultMessage: "Electrophysiological Diagnostic Technologists"}), value: "Electrophysiological Diagnostic Technologists"},
  {code: "3219", title: defineMessage({id: "Occupation.401.3219",defaultMessage: "Other Medical Techs (Except Dental Health)"}), value: "Other Medical Techs (Except Dental Health)"},
  {code: "3221", title: defineMessage({id: "Occupation.402.3221",defaultMessage: "Denturist"}), value: "Denturist"},
  {code: "3222", title: defineMessage({id: "Occupation.403.3222",defaultMessage: "Dental Hygienist"}), value: "Dental Hygienist"},
  {code: "3223", title: defineMessage({id: "Occupation.404.3223",defaultMessage: "Dental Technician"}), value: "Dental Technician"},
  {code: "3223", title: defineMessage({id: "Occupation.405.3223",defaultMessage: "Dental Laboratory Assistant"}), value: "Dental Laboratory Assistant"},
  {code: "3231", title: defineMessage({id: "Occupation.406.3231",defaultMessage: "Optician"}), value: "Optician"},
  {code: "3232", title: defineMessage({id: "Occupation.407.3232",defaultMessage: "Practitioner Of Natural Healing"}), value: "Practitioner Of Natural Healing"},
  {code: "3232", title: defineMessage({id: "Occupation.408.3232",defaultMessage: "Herbalist"}), value: "Herbalist"},
  {code: "3232", title: defineMessage({id: "Occupation.409.3232",defaultMessage: "Acupuncturist"}), value: "Acupuncturist"},
  {code: "3232", title: defineMessage({id: "Occupation.410.3232",defaultMessage: "Reflexologist"}), value: "Reflexologist"},
  {code: "3233", title: defineMessage({id: "Occupation.411.3233",defaultMessage: "Licensed Practical Nurses"}), value: "Licensed Practical Nurses"},
  {code: "3234", title: defineMessage({id: "Occupation.412.3234",defaultMessage: "Paramedic"}), value: "Paramedic"},
  {code: "3234", title: defineMessage({id: "Occupation.413.3234",defaultMessage: "Medical Assistant"}), value: "Medical Assistant"},
  {code: "3234", title: defineMessage({id: "Occupation.414.3234",defaultMessage: "Medical Technician"}), value: "Medical Technician"},
  {code: "3234", title: defineMessage({id: "Occupation.415.3234",defaultMessage: "Ambulance Attendant"}), value: "Ambulance Attendant"},
  {code: "3236", title: defineMessage({id: "Occupation.416.3236",defaultMessage: "Massage Therapist"}), value: "Massage Therapist"},
  {code: "3237", title: defineMessage({id: "Occupation.417.3237",defaultMessage: "Other Techs In Therapy And Assessment"}), value: "Other Techs In Therapy And Assessment"},
  {code: "3411", title: defineMessage({id: "Occupation.418.3411",defaultMessage: "Dental Assistant"}), value: "Dental Assistant"},
  {code: "3413", title: defineMessage({id: "Occupation.419.3413",defaultMessage: "Nurse Aides, Orderlies And Patient Service Associates"}), value: "Nurse Aides, Orderlies And Patient Service Associates"},
  {code: "3413", title: defineMessage({id: "Occupation.420.3413",defaultMessage: "Emergency Medical Responder"}), value: "Emergency Medical Responder"},
  {code: "3413", title: defineMessage({id: "Occupation.421.3413",defaultMessage: "Health Care Aide"}), value: "Health Care Aide"},
  {code: "3413", title: defineMessage({id: "Occupation.422.3413",defaultMessage: "Nurse Aide  "}), value: "Nurse Aide  "},
  {code: "3413", title: defineMessage({id: "Occupation.423.3413",defaultMessage: "Orderly"}), value: "Orderly"},
  {code: "3413", title: defineMessage({id: "Occupation.424.3413",defaultMessage: "Resident Care Aide"}), value: "Resident Care Aide"},
  {code: "3414", title: defineMessage({id: "Occupation.425.3414",defaultMessage: "Other Assisting  In Support Of Health Services"}), value: "Other Assisting  In Support Of Health Services"},
  {code: "4011", title: defineMessage({id: "Occupation.426.4011",defaultMessage: "University Professors And Lecturers"}), value: "University Professors And Lecturers"},
  {code: "4012", title: defineMessage({id: "Occupation.427.4012",defaultMessage: "Post-Secondary Teaching And Research Assistants"}), value: "Post-Secondary Teaching And Research Assistants"},
  {code: "4021", title: defineMessage({id: "Occupation.428.4021",defaultMessage: "College And Other Vocational Instructors"}), value: "College And Other Vocational Instructors"},
  {code: "4031", title: defineMessage({id: "Occupation.429.4031",defaultMessage: "Secondary School Teachers"}), value: "Secondary School Teachers"},
  {code: "4031", title: defineMessage({id: "Occupation.430.4031",defaultMessage: "ESL Teacher"}), value: "ESL Teacher"},
  {code: "4031", title: defineMessage({id: "Occupation.431.4031",defaultMessage: "Special Needs Teacher"}), value: "Special Needs Teacher"},
  {code: "4032", title: defineMessage({id: "Occupation.432.4032",defaultMessage: "Elementary School And Kindergarten Teachers"}), value: "Elementary School And Kindergarten Teachers"},
  {code: "4032", title: defineMessage({id: "Occupation.433.4032",defaultMessage: "School Teacher"}), value: "School Teacher"},
  {code: "4033", title: defineMessage({id: "Occupation.434.4033",defaultMessage: "Educational Counsellor"}), value: "Educational Counsellor"},
  {code: "4111", title: defineMessage({id: "Occupation.435.4111",defaultMessage: "Judge"}), value: "Judge"},
  {code: "4112", title: defineMessage({id: "Occupation.436.4112",defaultMessage: "Crown Attorney"}), value: "Crown Attorney"},
  {code: "4112", title: defineMessage({id: "Occupation.437.4112",defaultMessage: "Law Partner"}), value: "Law Partner"},
  {code: "4112", title: defineMessage({id: "Occupation.438.4112",defaultMessage: "Lawyer "}), value: "Lawyer "},
  {code: "4112", title: defineMessage({id: "Occupation.439.4112",defaultMessage: "Legal Advisor"}), value: "Legal Advisor"},
  {code: "4112", title: defineMessage({id: "Occupation.440.4112",defaultMessage: "Solicitor"}), value: "Solicitor"},
  {code: "4112", title: defineMessage({id: "Occupation.441.4112",defaultMessage: "Corporate Counsel"}), value: "Corporate Counsel"},
  {code: "4112", title: defineMessage({id: "Occupation.442.4112",defaultMessage: "Notary"}), value: "Notary"},
  {code: "4151", title: defineMessage({id: "Occupation.443.4151",defaultMessage: "Psychologist"}), value: "Psychologist"},
  {code: "4152", title: defineMessage({id: "Occupation.444.4152",defaultMessage: "Social Worker"}), value: "Social Worker"},
  {code: "4153", title: defineMessage({id: "Occupation.445.4153",defaultMessage: "Family, Marriage And Other Related Counsellors"}), value: "Family, Marriage And Other Related Counsellors"},
  {code: "4154", title: defineMessage({id: "Occupation.446.4154",defaultMessage: "Professional In Religion"}), value: "Professional In Religion"},
  {code: "4154", title: defineMessage({id: "Occupation.447.4154",defaultMessage: "Minister - Religion"}), value: "Minister - Religion"},
  {code: "4155", title: defineMessage({id: "Occupation.448.4155",defaultMessage: "Probation Officer"}), value: "Probation Officer"},
  {code: "4155", title: defineMessage({id: "Occupation.449.4155",defaultMessage: "Parole Officer"}), value: "Parole Officer"},
  {code: "4156", title: defineMessage({id: "Occupation.450.4156",defaultMessage: "Employment Counsellor"}), value: "Employment Counsellor"},
  {code: "4161", title: defineMessage({id: "Occupation.451.4161",defaultMessage: "Natural And Applied Science Policy Researcher"}), value: "Natural And Applied Science Policy Researcher"},
  {code: "4161", title: defineMessage({id: "Occupation.452.4161",defaultMessage: "Natural And Applied Science Policy Consultant"}), value: "Natural And Applied Science Policy Consultant"},
  {code: "4161", title: defineMessage({id: "Occupation.453.4161",defaultMessage: "Natural And Applied Science Policy Program Officers"}), value: "Natural And Applied Science Policy Program Officers"},
  {code: "4162", title: defineMessage({id: "Occupation.454.4162",defaultMessage: "Business Analyst"}), value: "Business Analyst"},
  {code: "4162", title: defineMessage({id: "Occupation.455.4162",defaultMessage: "Economic Advisor"}), value: "Economic Advisor"},
  {code: "4162", title: defineMessage({id: "Occupation.456.4162",defaultMessage: "Economic Analyst"}), value: "Economic Analyst"},
  {code: "4162", title: defineMessage({id: "Occupation.457.4162",defaultMessage: "Economist  "}), value: "Economist  "},
  {code: "4162", title: defineMessage({id: "Occupation.458.4162",defaultMessage: "Financial Economist"}), value: "Financial Economist"},
  {code: "4162", title: defineMessage({id: "Occupation.459.4162",defaultMessage: "Labour Economist"}), value: "Labour Economist"},
  {code: "4162", title: defineMessage({id: "Occupation.460.4162",defaultMessage: "Economic Policy Researcher"}), value: "Economic Policy Researcher"},
  {code: "4162", title: defineMessage({id: "Occupation.461.4162",defaultMessage: "Economic Policy Analyst"}), value: "Economic Policy Analyst"},
  {code: '4162', title: defineMessage({id: "Occupation.931.4162",defaultMessage: "Risk Management Analyst"}), value: "Risk Management Analyst"},
  {code: "4163", title: defineMessage({id: "Occupation.462.4163",defaultMessage: "Business Development Officer"}), value: "Business Development Officer"},
  {code: "4163", title: defineMessage({id: "Occupation.463.4163",defaultMessage: "Marketing Researcher"}), value: "Marketing Researcher"},
  {code: "4163", title: defineMessage({id: "Occupation.464.4163",defaultMessage: "Business Development Consultant"}), value: "Business Development Consultant"},
  {code: "4164", title: defineMessage({id: "Occupation.465.4164",defaultMessage: "Social Policy Researcher"}), value: "Social Policy Researcher"},
  {code: "4164", title: defineMessage({id: "Occupation.466.4164",defaultMessage: "Social Policy Consultant"}), value: "Social Policy Consultant"},
  {code: "4164", title: defineMessage({id: "Occupation.467.4164",defaultMessage: "Social Policy Program Officers"}), value: "Social Policy Program Officers"},
  {code: '4164', title: defineMessage({id: "Occupation.932.4164",defaultMessage: "Aboriginal Affairs Officer"}), value: "Aboriginal Affairs Officer"},
  {code: "4165", title: defineMessage({id: "Occupation.468.4165",defaultMessage: "Health Policy Consultant"}), value: "Health Policy Consultant"},
  {code: "4165", title: defineMessage({id: "Occupation.469.4165",defaultMessage: "Health Policy Researcher"}), value: "Health Policy Researcher"},
  {code: "4165", title: defineMessage({id: "Occupation.470.4165",defaultMessage: "Health Policy Program Officers"}), value: "Health Policy Program Officers"},
  {code: "4166", title: defineMessage({id: "Occupation.471.4166",defaultMessage: "Education Policy Consultant"}), value: "Education Policy Consultant"},
  {code: "4166", title: defineMessage({id: "Occupation.472.4166",defaultMessage: "Education Policy Researcher"}), value: "Education Policy Researcher"},
  {code: "4166", title: defineMessage({id: "Occupation.473.4166",defaultMessage: "Education Policy Program Officers"}), value: "Education Policy Program Officers"},
  {code: "4167", title: defineMessage({id: "Occupation.474.4167",defaultMessage: "Recreation, Sports And Fitness Policy Researcher"}), value: "Recreation, Sports And Fitness Policy Researcher"},
  {code: "4167", title: defineMessage({id: "Occupation.475.4167",defaultMessage: "Recreation, Sports And Fitness Policy Consultant"}), value: "Recreation, Sports And Fitness Policy Consultant"},
  {code: "4168", title: defineMessage({id: "Occupation.476.4168",defaultMessage: "Attache"}), value: "Attache"},
  {code: "4168", title: defineMessage({id: "Occupation.477.4168",defaultMessage: "Elections Officer"}), value: "Elections Officer"},
  {code: "4168", title: defineMessage({id: "Occupation.478.4168",defaultMessage: "Returning Officer"}), value: "Returning Officer"},
  {code: "4168", title: defineMessage({id: "Occupation.479.4168",defaultMessage: "Federal Relations Officer"}), value: "Federal Relations Officer"},
  {code: "4168", title: defineMessage({id: "Occupation.480.4168",defaultMessage: "Provincial Relations Officer"}), value: "Provincial Relations Officer"},
  {code: "4168", title: defineMessage({id: "Occupation.481.4168",defaultMessage: "Foreign Service Officer"}), value: "Foreign Service Officer"},
  {code: "4168", title: defineMessage({id: "Occupation.482.4168",defaultMessage: "Protocol Officer"}), value: "Protocol Officer"},
  {code: "4169", title: defineMessage({id: "Occupation.483.4169",defaultMessage: "Other Professional In Social Science"}), value: "Other Professional In Social Science"},
  {code: "4169", title: defineMessage({id: "Occupation.484.4169",defaultMessage: "Anthropologist"}), value: "Anthropologist"},
  {code: "4169", title: defineMessage({id: "Occupation.485.4169",defaultMessage: "Archaeologist"}), value: "Archaeologist"},
  {code: "4169", title: defineMessage({id: "Occupation.486.4169",defaultMessage: "Geographer"}), value: "Geographer"},
  {code: "4169", title: defineMessage({id: "Occupation.487.4169",defaultMessage: "Historian"}), value: "Historian"},
  {code: "4169", title: defineMessage({id: "Occupation.488.4169",defaultMessage: "Linguist"}), value: "Linguist"},
  {code: "4169", title: defineMessage({id: "Occupation.489.4169",defaultMessage: "Political Scientist"}), value: "Political Scientist"},
  {code: "4169", title: defineMessage({id: "Occupation.490.4169",defaultMessage: "Sociologist"}), value: "Sociologist"},
  {code: "4211", title: defineMessage({id: "Occupation.491.4211",defaultMessage: "Paralegal"}), value: "Paralegal"},
  {code: "4211", title: defineMessage({id: "Occupation.492.4211",defaultMessage: "Notary Public"}), value: "Notary Public"},
  {code: "4211", title: defineMessage({id: "Occupation.493.4211",defaultMessage: "Title Searcher"}), value: "Title Searcher"},
  {code: "4211", title: defineMessage({id: "Occupation.494.4211",defaultMessage: "Trademark Agent"}), value: "Trademark Agent"},
  {code: '4211', title: defineMessage({id: "Occupation.933.4211",defaultMessage: "Law Clerk"}), value: "Law Clerk"},
  {code: "4212", title: defineMessage({id: "Occupation.495.4212",defaultMessage: "Social And Community Service Worker"}), value: "Social And Community Service Worker"},
  {code: "4214", title: defineMessage({id: "Occupation.496.4214",defaultMessage: "Early Childhood Educator Or Assistant"}), value: "Early Childhood Educator Or Assistant"},
  {code: "4215", title: defineMessage({id: "Occupation.497.4215",defaultMessage: "Instructors Of Persons With Disabilities"}), value: "Instructors Of Persons With Disabilities"},
  {code: "4216", title: defineMessage({id: "Occupation.498.4216",defaultMessage: "Driving Instructor"}), value: "Driving Instructor"},
  {code: "4216", title: defineMessage({id: "Occupation.499.4216",defaultMessage: "Driver's Licence Examiner"}), value: "Driver's Licence Examiner"},
  {code: "4216", title: defineMessage({id: "Occupation.500.4216",defaultMessage: "Instructor Modelling And Finishing School"}), value: "Instructor Modelling And Finishing School"},
  {code: "4216", title: defineMessage({id: "Occupation.501.4216",defaultMessage: "Other Instructors"}), value: "Other Instructors"},
  {code: "4217", title: defineMessage({id: "Occupation.502.4217",defaultMessage: "Other Religious "}), value: "Other Religious "},
  {code: "4311", title: defineMessage({id: "Occupation.503.4311",defaultMessage: "Community Relations Officer"}), value: "Community Relations Officer"},
  {code: "4311", title: defineMessage({id: "Occupation.504.4311",defaultMessage: "Detective"}), value: "Detective"},
  {code: "4311", title: defineMessage({id: "Occupation.505.4311",defaultMessage: "Police Officer"}), value: "Police Officer"},
  {code: "4311", title: defineMessage({id: "Occupation.506.4311",defaultMessage: "Constable"}), value: "Constable"},
  {code: '4311', title: defineMessage({id: "Occupation.934.4311",defaultMessage: "Police Sargeant"}), value: "Police Sargeant"},
  {code: '4311', title: defineMessage({id: "Occupation.935.4311",defaultMessage: "RCMP Officer"}), value: "RCMP Officer"},
  {code: '4311', title: defineMessage({id: "Occupation.936.4311",defaultMessage: "Staff Sargeant - Police"}), value: "Staff Sargeant - Police"},
  {code: "4312", title: defineMessage({id: "Occupation.507.4312",defaultMessage: "Firefighter"}), value: "Firefighter"},
  {code: "4313", title: defineMessage({id: "Occupation.508.4313",defaultMessage: "Non-Commissioned Ranks Of The Canadian Forces"}), value: "Non-Commissioned Ranks Of The Canadian Forces"},
  {code: "4411", title: defineMessage({id: "Occupation.509.4411",defaultMessage: "Home Child Care Provider"}), value: "Home Child Care Provider"},
  {code: "4412", title: defineMessage({id: "Occupation.510.4412",defaultMessage: "Housekeeper"}), value: "Housekeeper"},
  {code: "4412", title: defineMessage({id: "Occupation.511.4412",defaultMessage: "Home Support Worker  "}), value: "Home Support Worker  "},
  {code: "4412", title: defineMessage({id: "Occupation.512.4412",defaultMessage: "Live-In Caregiver"}), value: "Live-In Caregiver"},
  {code: "4412", title: defineMessage({id: "Occupation.513.4412",defaultMessage: "Personal Care Attendant"}), value: "Personal Care Attendant"},
  {code: "4412", title: defineMessage({id: "Occupation.514.4412",defaultMessage: "Respite Worker"}), value: "Respite Worker"},
  {code: "4412", title: defineMessage({id: "Occupation.515.4412",defaultMessage: "Attendant For Person With Disabilities"}), value: "Attendant For Person With Disabilities"},
  {code: "4412", title: defineMessage({id: "Occupation.516.4412",defaultMessage: "Homemaker"}), value: "Homemaker"},
  {code: '4412', title: defineMessage({id: "Occupation.937.4412",defaultMessage: "Personal Support Worker"}), value: "Personal Support Worker"},
  {code: "4413", title: defineMessage({id: "Occupation.517.4413",defaultMessage: "Elementary And Secondary School Teacher Assistants"}), value: "Elementary And Secondary School Teacher Assistants"},
  {code: "4421", title: defineMessage({id: "Occupation.518.4421",defaultMessage: "Sheriffs And Bailiffs"}), value: "Sheriffs And Bailiffs"},
  {code: "4422", title: defineMessage({id: "Occupation.519.4422",defaultMessage: "Correctional Service Officer"}), value: "Correctional Service Officer"},
  {code: "4423", title: defineMessage({id: "Occupation.520.4423",defaultMessage: "Animal Control Officer"}), value: "Animal Control Officer"},
  {code: "4423", title: defineMessage({id: "Occupation.521.4423",defaultMessage: "By-Law Enforcement Officer"}), value: "By-Law Enforcement Officer"},
  {code: "4423", title: defineMessage({id: "Occupation.522.4423",defaultMessage: "Comm Transport Inspector"}), value: "Comm Transport Inspector"},
  {code: "4423", title: defineMessage({id: "Occupation.523.4423",defaultMessage: "Liquor Licence Inspector"}), value: "Liquor Licence Inspector"},
  {code: "4423", title: defineMessage({id: "Occupation.524.4423",defaultMessage: "Parking Control Officer"}), value: "Parking Control Officer"},
  {code: "4423", title: defineMessage({id: "Occupation.525.4423",defaultMessage: "Zoning Inspector"}), value: "Zoning Inspector"},
  {code: "5111", title: defineMessage({id: "Occupation.526.5111",defaultMessage: "Librarian"}), value: "Librarian"},
  {code: "5112", title: defineMessage({id: "Occupation.527.5112",defaultMessage: "Museum Curator"}), value: "Museum Curator"},
  {code: "5112", title: defineMessage({id: "Occupation.528.5112",defaultMessage: "Museum Conservator"}), value: "Museum Conservator"},
  {code: "5113", title: defineMessage({id: "Occupation.529.5113",defaultMessage: "Archivists"}), value: "Archivists"},
  {code: "5121", title: defineMessage({id: "Occupation.530.5121",defaultMessage: "Authors And Writers"}), value: "Authors And Writers"},
  {code: "5122", title: defineMessage({id: "Occupation.531.5122",defaultMessage: "Editors"}), value: "Editors"},
  {code: "5123", title: defineMessage({id: "Occupation.532.5123",defaultMessage: "Journalist"}), value: "Journalist"},
  {code: "5125", title: defineMessage({id: "Occupation.533.5125",defaultMessage: "Translator"}), value: "Translator"},
  {code: "5125", title: defineMessage({id: "Occupation.534.5125",defaultMessage: "Interpreter"}), value: "Interpreter"},
  {code: "5131", title: defineMessage({id: "Occupation.535.5131",defaultMessage: "Producer"}), value: "Producer"},
  {code: "5131", title: defineMessage({id: "Occupation.536.5131",defaultMessage: "Director"}), value: "Director"},
  {code: "5131", title: defineMessage({id: "Occupation.537.5131",defaultMessage: "Choreographer"}), value: "Choreographer"},
  {code: "5132", title: defineMessage({id: "Occupation.538.5132",defaultMessage: "Conductors, Composers And Arrangers"}), value: "Conductors, Composers And Arrangers"},
  {code: "5133", title: defineMessage({id: "Occupation.539.5133",defaultMessage: "Musicians And Singers"}), value: "Musicians And Singers"},
  {code: "5134", title: defineMessage({id: "Occupation.540.5134",defaultMessage: "Dancer"}), value: "Dancer"},
  {code: "5135", title: defineMessage({id: "Occupation.541.5135",defaultMessage: "Actors And Comedians"}), value: "Actors And Comedians"},
  {code: "5136", title: defineMessage({id: "Occupation.542.5136",defaultMessage: "Painters, Sculptors And Other Visual Artists"}), value: "Painters, Sculptors And Other Visual Artists"},
  {code: "5211", title: defineMessage({id: "Occupation.543.5211",defaultMessage: "Library And Public Archive Technician"}), value: "Library And Public Archive Technician"},
  {code: "5212", title: defineMessage({id: "Occupation.544.5212",defaultMessage: "Museum Technician"}), value: "Museum Technician"},
  {code: "5221", title: defineMessage({id: "Occupation.545.5221",defaultMessage: "Photographer"}), value: "Photographer"},
  {code: "5222", title: defineMessage({id: "Occupation.546.5222",defaultMessage: "Film And Video Camera Operators"}), value: "Film And Video Camera Operators"},
  {code: "5223", title: defineMessage({id: "Occupation.547.5223",defaultMessage: "Graphic Arts Technician"}), value: "Graphic Arts Technician"},
  {code: "5224", title: defineMessage({id: "Occupation.548.5224",defaultMessage: "Broadcast Technician"}), value: "Broadcast Technician"},
  {code: "5225", title: defineMessage({id: "Occupation.549.5225",defaultMessage: "Audio and Video Recording Technician"}), value: "Audio and Video Recording Technician"},
  {code: "5226", title: defineMessage({id: "Occupation.550.5226",defaultMessage: "Other Tech and Co-Ordinating Broadcasting "}), value: "Other Tech and Co-Ordinating Broadcasting "},
  {code: "5226", title: defineMessage({id: "Occupation.551.5226",defaultMessage: "Other Tech and Co-Ordinating Motion Pictures"}), value: "Other Tech and Co-Ordinating Motion Pictures"},
  {code: "5226", title: defineMessage({id: "Occupation.552.5226",defaultMessage: "Other Tech and Co-Ordinating Performing Arts"}), value: "Other Tech and Co-Ordinating Performing Arts"},
  {code: "5227", title: defineMessage({id: "Occupation.553.5227",defaultMessage: "Support Broadcasting"}), value: "Support Broadcasting"},
  {code: "5227", title: defineMessage({id: "Occupation.554.5227",defaultMessage: "Support Motion Photography"}), value: "Support Motion Photography"},
  {code: "5227", title: defineMessage({id: "Occupation.555.5227",defaultMessage: "Support The Performing Arts"}), value: "Support The Performing Arts"},
  {code: "5231", title: defineMessage({id: "Occupation.556.5231",defaultMessage: "Announcer"}), value: "Announcer"},
  {code: "5231", title: defineMessage({id: "Occupation.557.5231",defaultMessage: "Broadcaster"}), value: "Broadcaster"},
  {code: "5232", title: defineMessage({id: "Occupation.558.5232",defaultMessage: "Busker"}), value: "Busker"},
  {code: "5232", title: defineMessage({id: "Occupation.559.5232",defaultMessage: "Exotic Dancer"}), value: "Exotic Dancer"},
  {code: "5232", title: defineMessage({id: "Occupation.560.5232",defaultMessage: "Fashion Model"}), value: "Fashion Model"},
  {code: "5232", title: defineMessage({id: "Occupation.561.5232",defaultMessage: "Magician"}), value: "Magician"},
  {code: "5232", title: defineMessage({id: "Occupation.562.5232",defaultMessage: "Acrobat"}), value: "Acrobat"},
  {code: "5232", title: defineMessage({id: "Occupation.563.5232",defaultMessage: "Ventriloquist"}), value: "Ventriloquist"},
  {code: "5232", title: defineMessage({id: "Occupation.564.5232",defaultMessage: "Other Performers"}), value: "Other Performers"},
  {code: "5241", title: defineMessage({id: "Occupation.565.5241",defaultMessage: "Graphic Designers And Illustrators"}), value: "Graphic Designers And Illustrators"},
  {code: "5241", title: defineMessage({id: "Occupation.566.5241",defaultMessage: "User Experience Designer"}), value: "User Experience Designer"},
  {code: '5241', title: defineMessage({id: "Occupation.938.5241",defaultMessage: "3D Animator"}), value: "3D Animator"},
  {code: "5242", title: defineMessage({id: "Occupation.567.5242",defaultMessage: "Interior Decorator"}), value: "Interior Decorator"},
  {code: "5242", title: defineMessage({id: "Occupation.568.5242",defaultMessage: "Interior Designer"}), value: "Interior Designer"},
  {code: "5243", title: defineMessage({id: "Occupation.569.5243",defaultMessage: "Fashion Designer"}), value: "Fashion Designer"},
  {code: "5243", title: defineMessage({id: "Occupation.570.5243",defaultMessage: "Other Creative Designers"}), value: "Other Creative Designers"},
  {code: "5244", title: defineMessage({id: "Occupation.571.5244",defaultMessage: "Artisans And Craftspersons"}), value: "Artisans And Craftspersons"},
  {code: "5245", title: defineMessage({id: "Occupation.572.5245",defaultMessage: "Patternmakers Textile, Leather And Fur"}), value: "Patternmakers Textile, Leather And Fur"},
  {code: "5251", title: defineMessage({id: "Occupation.573.5251",defaultMessage: "Athlete"}), value: "Athlete"},
  {code: "5252", title: defineMessage({id: "Occupation.574.5252",defaultMessage: "Coach"}), value: "Coach"},
  {code: "5253", title: defineMessage({id: "Occupation.575.5253",defaultMessage: "Sports Officials And Referees"}), value: "Sports Officials And Referees"},
  {code: "5254", title: defineMessage({id: "Occupation.576.5254",defaultMessage: "Recreation Instructor"}), value: "Recreation Instructor"},
  {code: "5254", title: defineMessage({id: "Occupation.577.5254",defaultMessage: "Sports Instructor"}), value: "Sports Instructor"},
  {code: "5254", title: defineMessage({id: "Occupation.578.5254",defaultMessage: "Fitness Instructor"}), value: "Fitness Instructor"},
  {code: "6211", title: defineMessage({id: "Occupation.579.6211",defaultMessage: "Retail Sales Supervisor"}), value: "Retail Sales Supervisor"},
  {code: '6211', title: defineMessage({id: "Occupation.939.6211",defaultMessage: "Pharmaceutical Sales Representative"}), value: "Pharmaceutical Sales Representative"},
  {code: "6221", title: defineMessage({id: "Occupation.580.6221",defaultMessage: "Technical Sales Specialists Wholesale Trade"}), value: "Technical Sales Specialists Wholesale Trade"},
  {code: "6222", title: defineMessage({id: "Occupation.581.6222",defaultMessage: "Buyer Retail or Wholesale"}), value: "Buyer Retail or Wholesale"},
  {code: "6231", title: defineMessage({id: "Occupation.582.6231",defaultMessage: "Insurance Sales Agent "}), value: "Insurance Sales Agent "},
  {code: "6231", title: defineMessage({id: "Occupation.583.6231",defaultMessage: "Insurance Broker"}), value: "Insurance Broker"},
  {code: "6231", title: defineMessage({id: "Occupation.584.6231",defaultMessage: "Insurance Sales Rep"}), value: "Insurance Sales Rep"},
  {code: "6232", title: defineMessage({id: "Occupation.585.6232",defaultMessage: "Real Estate Agent"}), value: "Real Estate Agent"},
  {code: "6232", title: defineMessage({id: "Occupation.586.6232",defaultMessage: "Commercial Real Estate Agent"}), value: "Commercial Real Estate Agent"},
  {code: "6232", title: defineMessage({id: "Occupation.587.6232",defaultMessage: "Real Estate Broker"}), value: "Real Estate Broker"},
  {code: "6232", title: defineMessage({id: "Occupation.588.6232",defaultMessage: "Residential Real Estate Agent"}), value: "Residential Real Estate Agent"},
  {code: "6235", title: defineMessage({id: "Occupation.589.6235",defaultMessage: "Mortgage Officer"}), value: "Mortgage Officer"},
  {code: "6235", title: defineMessage({id: "Occupation.590.6235",defaultMessage: "Personal Banking Sales Associate"}), value: "Personal Banking Sales Associate"},
  {code: "6235", title: defineMessage({id: "Occupation.591.6235",defaultMessage: "Credit Officer"}), value: "Credit Officer"},
  {code: "6311", title: defineMessage({id: "Occupation.592.6311",defaultMessage: "Food Service Supervisor"}), value: "Food Service Supervisor"},
  {code: "6312", title: defineMessage({id: "Occupation.593.6312",defaultMessage: "Executive Housekeeper"}), value: "Executive Housekeeper"},
  {code: "6313", title: defineMessage({id: "Occupation.594.6313",defaultMessage: "Accommodation Services Supervisor"}), value: "Accommodation Services Supervisor"},
  {code: "6313", title: defineMessage({id: "Occupation.595.6313",defaultMessage: "Travel Services Supervisor"}), value: "Travel Services Supervisor"},
  {code: "6313", title: defineMessage({id: "Occupation.596.6313",defaultMessage: "Tourism Services Supervisor"}), value: "Tourism Services Supervisor"},
  {code: '6313', title: defineMessage({id: "Occupation.940.6313",defaultMessage: "Casino Pit Boss"}), value: "Casino Pit Boss"},
  {code: '6313', title: defineMessage({id: "Occupation.941.6313",defaultMessage: "Casino Shift Manager"}), value: "Casino Shift Manager"},
  {code: '6313', title: defineMessage({id: "Occupation.942.6313",defaultMessage: "Dealers Shift Manager"}), value: "Dealers Shift Manager"},
  {code: "6314", title: defineMessage({id: "Occupation.597.6314",defaultMessage: "Information Services Supervisor"}), value: "Information Services Supervisor"},
  {code: "6314", title: defineMessage({id: "Occupation.598.6314",defaultMessage: "Customer Services Supervisor"}), value: "Customer Services Supervisor"},
  {code: "6315", title: defineMessage({id: "Occupation.599.6315",defaultMessage: "Cleaning Supervisor"}), value: "Cleaning Supervisor"},
  {code: "6316", title: defineMessage({id: "Occupation.600.6316",defaultMessage: "Other Services Supervisor"}), value: "Other Services Supervisor"},
  {code: "6321", title: defineMessage({id: "Occupation.601.6321",defaultMessage: "Chef"}), value: "Chef"},
  {code: "6322", title: defineMessage({id: "Occupation.602.6322",defaultMessage: "Cook"}), value: "Cook"},
  {code: "6331", title: defineMessage({id: "Occupation.603.6331",defaultMessage: "Butchers Retail And Wholesale"}), value: "Butchers Retail And Wholesale"},
  {code: "6331", title: defineMessage({id: "Occupation.604.6331",defaultMessage: "Fishmongers Retail And Wholesale"}), value: "Fishmongers Retail And Wholesale"},
  {code: "6332", title: defineMessage({id: "Occupation.605.6332",defaultMessage: "Baker"}), value: "Baker"},
  {code: "6341", title: defineMessage({id: "Occupation.606.6341",defaultMessage: "Hairstylists And Barbers"}), value: "Hairstylists And Barbers"},
  {code: "6341", title: defineMessage({id: "Occupation.607.6341",defaultMessage: "Apprentice Hairstylist"}), value: "Apprentice Hairstylist"},
  {code: '6341', title: defineMessage({id: "Occupation.943.6341",defaultMessage: "Hairdresser"}), value: "Hairdresser"},
  {code: "6342", title: defineMessage({id: "Occupation.608.6342",defaultMessage: "Tailors and Dressmakers"}), value: "Tailors and Dressmakers"},
  {code: "6343", title: defineMessage({id: "Occupation.609.6343",defaultMessage: "Shoe Repairers And Shoemakers"}), value: "Shoe Repairers And Shoemakers"},
  {code: "6344", title: defineMessage({id: "Occupation.610.6344",defaultMessage: "Jeweller"}), value: "Jeweller"},
  {code: "6344", title: defineMessage({id: "Occupation.611.6344",defaultMessage: "Watch Repairer"}), value: "Watch Repairer"},
  {code: "6345", title: defineMessage({id: "Occupation.612.6345",defaultMessage: "Upholsterer"}), value: "Upholsterer"},
  {code: "6346", title: defineMessage({id: "Occupation.613.6346",defaultMessage: "Funeral Director"}), value: "Funeral Director"},
  {code: "6346", title: defineMessage({id: "Occupation.614.6346",defaultMessage: "Embalmer"}), value: "Embalmer"},
  {code: "6411", title: defineMessage({id: "Occupation.615.6411",defaultMessage: "Advertising Time Sales Representative"}), value: "Advertising Time Sales Representative"},
  {code: "6411", title: defineMessage({id: "Occupation.616.6411",defaultMessage: "Freight Sales Agent"}), value: "Freight Sales Agent"},
  {code: "6411", title: defineMessage({id: "Occupation.617.6411",defaultMessage: "Liquor Sales Representative"}), value: "Liquor Sales Representative"},
  {code: "6411", title: defineMessage({id: "Occupation.618.6411",defaultMessage: "Oil Distributor"}), value: "Oil Distributor"},
  {code: "6411", title: defineMessage({id: "Occupation.619.6411",defaultMessage: "Transfer Company Agent"}), value: "Transfer Company Agent"},
  {code: "6411", title: defineMessage({id: "Occupation.620.6411",defaultMessage: "Wholesale Sales Rep (Non-Technical)"}), value: "Wholesale Sales Rep (Non-Technical)"},
  {code: "6421", title: defineMessage({id: "Occupation.621.6421",defaultMessage: "Automobile Salesperson"}), value: "Automobile Salesperson"},
  {code: "6421", title: defineMessage({id: "Occupation.622.6421",defaultMessage: "Car Rental Agent"}), value: "Car Rental Agent"},
  {code: "6421", title: defineMessage({id: "Occupation.623.6421",defaultMessage: "Clothing Salesperson"}), value: "Clothing Salesperson"},
  {code: "6421", title: defineMessage({id: "Occupation.624.6421",defaultMessage: "Electronic Salesperson"}), value: "Electronic Salesperson"},
  {code: "6421", title: defineMessage({id: "Occupation.625.6421",defaultMessage: "Furniture Salesperson"}), value: "Furniture Salesperson"},
  {code: "6421", title: defineMessage({id: "Occupation.626.6421",defaultMessage: "Jewellery Salesperson"}), value: "Jewellery Salesperson"},
  {code: "6421", title: defineMessage({id: "Occupation.627.6421",defaultMessage: "Retail Sales Representative"}), value: "Retail Sales Representative"},
  {code: "6421", title: defineMessage({id: "Occupation.628.6421",defaultMessage: "Florist"}), value: "Florist"},
  {code: "6511", title: defineMessage({id: "Occupation.629.6511",defaultMessage: "Maîtres D'Hôtel"}), value: "Maîtres D'Hôtel"},
  {code: "6511", title: defineMessage({id: "Occupation.630.6511",defaultMessage: "Host/Hostess"}), value: "Host/Hostess"},
  {code: "6512", title: defineMessage({id: "Occupation.631.6512",defaultMessage: "Bartender"}), value: "Bartender"},
  {code: "6513", title: defineMessage({id: "Occupation.632.6513",defaultMessage: "Food And Beverage Server"}), value: "Food And Beverage Server"},
  {code: "6513", title: defineMessage({id: "Occupation.633.6513",defaultMessage: "Waiter/Waitress"}), value: "Waiter/Waitress"},
  {code: "6521", title: defineMessage({id: "Occupation.634.6521",defaultMessage: "Travel Agent"}), value: "Travel Agent"},
  {code: "6522", title: defineMessage({id: "Occupation.635.6522",defaultMessage: "Flight Attendants"}), value: "Flight Attendants"},
  {code: "6523", title: defineMessage({id: "Occupation.636.6523",defaultMessage: "Airline Ticket And Service Agents"}), value: "Airline Ticket And Service Agents"},
  {code: "6524", title: defineMessage({id: "Occupation.637.6524",defaultMessage: "Ground And Water Transport Ticket Agents"}), value: "Ground And Water Transport Ticket Agents"},
  {code: "6524", title: defineMessage({id: "Occupation.638.6524",defaultMessage: "Cargo Service Reps"}), value: "Cargo Service Reps"},
  {code: "6525", title: defineMessage({id: "Occupation.639.6525",defaultMessage: "Hotel Front Desk Clerk"}), value: "Hotel Front Desk Clerk"},
  {code: "6525", title: defineMessage({id: "Occupation.640.6525",defaultMessage: "Hotel Guest Service Agent"}), value: "Hotel Guest Service Agent"},
  {code: "6531", title: defineMessage({id: "Occupation.641.6531",defaultMessage: "Tour And Travel Guides"}), value: "Tour And Travel Guides"},
  {code: "6532", title: defineMessage({id: "Occupation.642.6532",defaultMessage: "Outdoor Sport And Recreational Guides"}), value: "Outdoor Sport And Recreational Guides"},
  {code: "6533", title: defineMessage({id: "Occupation.643.6533",defaultMessage: "Croupier (Dealer), Casino"}), value: "Croupier (Dealer), Casino"},
  {code: "6533", title: defineMessage({id: "Occupation.644.6533",defaultMessage: "Pit Clerk (Casino)"}), value: "Pit Clerk (Casino)"},
  {code: "6533", title: defineMessage({id: "Occupation.645.6533",defaultMessage: "Casino Cashier"}), value: "Casino Cashier"},
  {code: "6533", title: defineMessage({id: "Occupation.646.6533",defaultMessage: "Gaming Table Operator"}), value: "Gaming Table Operator"},
  {code: '6533', title: defineMessage({id: "Occupation.944.6533",defaultMessage: "Casino Dealer"}), value: "Casino Dealer"},
  {code: "6541", title: defineMessage({id: "Occupation.647.6541",defaultMessage: "Security Guards And Related Security Service"}), value: "Security Guards And Related Security Service"},
  {code: "6541", title: defineMessage({id: "Occupation.648.6541",defaultMessage: "Private Detective"}), value: "Private Detective"},
  {code: "6551", title: defineMessage({id: "Occupation.649.6551",defaultMessage: "Bank Teller"}), value: "Bank Teller"},
  {code: "6551", title: defineMessage({id: "Occupation.650.6551",defaultMessage: "Credit Union Teller"}), value: "Credit Union Teller"},
  {code: "6551", title: defineMessage({id: "Occupation.651.6551",defaultMessage: "Financial Customer Service Rep"}), value: "Financial Customer Service Rep"},
  {code: "6551", title: defineMessage({id: "Occupation.652.6551",defaultMessage: "Foreign Exchange Teller"}), value: "Foreign Exchange Teller"},
  {code: "6551", title: defineMessage({id: "Occupation.653.6551",defaultMessage: "Customer Services Representative Financial Institutions"}), value: "Customer Services Representative Financial Institutions"},
  {code: "6552", title: defineMessage({id: "Occupation.654.6552",defaultMessage: "Call Centre Agent"}), value: "Call Centre Agent"},
  {code: "6552", title: defineMessage({id: "Occupation.655.6552",defaultMessage: "Contact Centre Agent"}), value: "Contact Centre Agent"},
  {code: "6552", title: defineMessage({id: "Occupation.656.6552",defaultMessage: "Customer Services Representative Contact Centre"}), value: "Customer Services Representative Contact Centre"},
  {code: "6552", title: defineMessage({id: "Occupation.657.6552",defaultMessage: "Public Relations Clerk"}), value: "Public Relations Clerk"},
  {code: "6552", title: defineMessage({id: "Occupation.658.6552",defaultMessage: "Tourism Information Clerk"}), value: "Tourism Information Clerk"},
  {code: "6552", title: defineMessage({id: "Occupation.659.6552",defaultMessage: "Other Customer And Information Services Reps"}), value: "Other Customer And Information Services Reps"},
  {code: "6561", title: defineMessage({id: "Occupation.660.6561",defaultMessage: "Image, Social And Other Personal Consultant"}), value: "Image, Social And Other Personal Consultant"},
  {code: "6562", title: defineMessage({id: "Occupation.661.6562",defaultMessage: "Esthetician"}), value: "Esthetician"},
  {code: "6562", title: defineMessage({id: "Occupation.662.6562",defaultMessage: "Electrologist"}), value: "Electrologist"},
  {code: "6563", title: defineMessage({id: "Occupation.663.6563",defaultMessage: "Pet Groomer"}), value: "Pet Groomer"},
  {code: "6563", title: defineMessage({id: "Occupation.664.6563",defaultMessage: "Dog Trainer"}), value: "Dog Trainer"},
  {code: "6563", title: defineMessage({id: "Occupation.665.6563",defaultMessage: "Other Animal Care Workers"}), value: "Other Animal Care Workers"},
  {code: "6564", title: defineMessage({id: "Occupation.666.6564",defaultMessage: "Astrologer"}), value: "Astrologer"},
  {code: "6564", title: defineMessage({id: "Occupation.667.6564",defaultMessage: "Fortune Teller"}), value: "Fortune Teller"},
  {code: "6564", title: defineMessage({id: "Occupation.668.6564",defaultMessage: "Psychic Reader"}), value: "Psychic Reader"},
  {code: "6564", title: defineMessage({id: "Occupation.669.6564",defaultMessage: "Dating Service Consultant"}), value: "Dating Service Consultant"},
  {code: "6564", title: defineMessage({id: "Occupation.670.6564",defaultMessage: "Escort"}), value: "Escort"},
  {code: '6564', title: defineMessage({id: "Occupation.945.6564",defaultMessage: "Masseur/Masseuse"}), value: "Masseur/Masseuse"},
  {code: "6611", title: defineMessage({id: "Occupation.671.6611",defaultMessage: "Cashier"}), value: "Cashier"},
  {code: '6611', title: defineMessage({id: "Occupation.946.6611",defaultMessage: "Cashier, Casino"}), value: "Cashier, Casino"},
  {code: '6611', title: defineMessage({id: "Occupation.947.6611",defaultMessage: "Cashier, Currency Exchange"}), value: "Cashier, Currency Exchange"},
  {code: '6611', title: defineMessage({id: "Occupation.948.6611",defaultMessage: "Currency Exchange Cashier"}), value: "Currency Exchange Cashier"},
  {code: "6621", title: defineMessage({id: "Occupation.672.6621",defaultMessage: "Service Station Attendant"}), value: "Service Station Attendant"},
  {code: "6621", title: defineMessage({id: "Occupation.673.6621",defaultMessage: "Gas Station Attendant"}), value: "Gas Station Attendant"},
  {code: "6622", title: defineMessage({id: "Occupation.674.6622",defaultMessage: "Store Shelf Stockers, Clerk And Order Fillers"}), value: "Store Shelf Stockers, Clerk And Order Fillers"},
  {code: "6622", title: defineMessage({id: "Occupation.675.6622",defaultMessage: "Grocery Clerk"}), value: "Grocery Clerk"},
  {code: "6622", title: defineMessage({id: "Occupation.676.6622",defaultMessage: "Retail Clerk"}), value: "Retail Clerk"},
  {code: "6623", title: defineMessage({id: "Occupation.677.6623",defaultMessage: "Street Vendor"}), value: "Street Vendor"},
  {code: "6623", title: defineMessage({id: "Occupation.678.6623",defaultMessage: "Telemarketer"}), value: "Telemarketer"},
  {code: "6623", title: defineMessage({id: "Occupation.679.6623",defaultMessage: "Other Sales Related"}), value: "Other Sales Related"},
  {code: "6711", title: defineMessage({id: "Occupation.680.6711",defaultMessage: "Food Counter Attendants, Kitchen Helpers"}), value: "Food Counter Attendants, Kitchen Helpers"},
  {code: "6711", title: defineMessage({id: "Occupation.681.6711",defaultMessage: "Food Preparer"}), value: "Food Preparer"},
  {code: '6711', title: defineMessage({id: "Occupation.949.6711",defaultMessage: "Attendant, Take-out - Fast Food"}), value: "Attendant, Take-out - Fast Food"},
  {code: '6711', title: defineMessage({id: "Occupation.950.6711",defaultMessage: "Barista"}), value: "Barista"},
  {code: "6721", title: defineMessage({id: "Occupation.682.6721",defaultMessage: "Support Accommodation Services"}), value: "Support Accommodation Services"},
  {code: "6721", title: defineMessage({id: "Occupation.683.6721",defaultMessage: "Support Travel Services"}), value: "Support Travel Services"},
  {code: "6721", title: defineMessage({id: "Occupation.684.6721",defaultMessage: "Support Facilities Set-Up Services"}), value: "Support Facilities Set-Up Services"},
  {code: "6722", title: defineMessage({id: "Occupation.685.6722",defaultMessage: "Operators and Attendants Recreation"}), value: "Operators and Attendants Recreation"},
  {code: "6722", title: defineMessage({id: "Occupation.686.6722",defaultMessage: "Operators and Attendants Amusement"}), value: "Operators and Attendants Amusement"},
  {code: "6722", title: defineMessage({id: "Occupation.687.6722",defaultMessage: "Operators and Attendants Sport"}), value: "Operators and Attendants Sport"},
  {code: "6731", title: defineMessage({id: "Occupation.688.6731",defaultMessage: "Light Duty Cleaners"}), value: "Light Duty Cleaners"},
  {code: "6732", title: defineMessage({id: "Occupation.689.6732",defaultMessage: "Specialized Cleaners"}), value: "Specialized Cleaners"},
  {code: "6733", title: defineMessage({id: "Occupation.690.6733",defaultMessage: "Janitors And Caretakers"}), value: "Janitors And Caretakers"},
  {code: "6741", title: defineMessage({id: "Occupation.692.6741",defaultMessage: "Dry Cleaning, Laundry And Related "}), value: "Dry Cleaning, Laundry And Related "},
  {code: "6742", title: defineMessage({id: "Occupation.693.6742",defaultMessage: "Other Service Support , N.E.C."}), value: "Other Service Support , N.E.C."},
  {code: "6742", title: defineMessage({id: "Occupation.694.6742",defaultMessage: "Elevator Operator"}), value: "Elevator Operator"},
  {code: "7201", title: defineMessage({id: "Occupation.695.7201",defaultMessage: "Contractor Erecting Trades"}), value: "Contractor Erecting Trades"},
  {code: "7201", title: defineMessage({id: "Occupation.696.7201",defaultMessage: "Contractor Machining"}), value: "Contractor Machining"},
  {code: "7201", title: defineMessage({id: "Occupation.697.7201",defaultMessage: "Contractor Metal Forming"}), value: "Contractor Metal Forming"},
  {code: "7202", title: defineMessage({id: "Occupation.698.7202",defaultMessage: "Contractor and Supervisor Telecommunications"}), value: "Contractor and Supervisor Telecommunications"},
  {code: "7202", title: defineMessage({id: "Occupation.699.7202",defaultMessage: "Contractor and Supervisor Electrical Trades"}), value: "Contractor and Supervisor Electrical Trades"},
  {code: "7203", title: defineMessage({id: "Occupation.700.7203",defaultMessage: "Contractor and Supervisor Pipefitting Trades"}), value: "Contractor and Supervisor Pipefitting Trades"},
  {code: "7204", title: defineMessage({id: "Occupation.701.7204",defaultMessage: "Contractor and Supervisor Carpentry Trades"}), value: "Contractor and Supervisor Carpentry Trades"},
  {code: '7204', title: defineMessage({id: "Occupation.951.7204",defaultMessage: "Carpenter Contractor"}), value: "Carpenter Contractor"},
  {code: "7205", title: defineMessage({id: "Occupation.702.7205",defaultMessage: "Contractor and Supervisor Installers And Servicers"}), value: "Contractor and Supervisor Installers And Servicers"},
  {code: "7231", title: defineMessage({id: "Occupation.703.7231",defaultMessage: "Machinists"}), value: "Machinists"},
  {code: "7231", title: defineMessage({id: "Occupation.704.7231",defaultMessage: "Machining and Tooling Inspectors"}), value: "Machining and Tooling Inspectors"},
  {code: "7232", title: defineMessage({id: "Occupation.705.7232",defaultMessage: "Tool And Die Maker"}), value: "Tool And Die Maker"},
  {code: "7233", title: defineMessage({id: "Occupation.706.7233",defaultMessage: "Sheet Metal Worker"}), value: "Sheet Metal Worker"},
  {code: "7234", title: defineMessage({id: "Occupation.707.7234",defaultMessage: "Boilermakers"}), value: "Boilermakers"},
  {code: "7235", title: defineMessage({id: "Occupation.708.7235",defaultMessage: "Platework Fabricators And Fitters"}), value: "Platework Fabricators And Fitters"},
  {code: "7235", title: defineMessage({id: "Occupation.709.7235",defaultMessage: "Structural Metal Fabricators And Fitters"}), value: "Structural Metal Fabricators And Fitters"},
  {code: "7236", title: defineMessage({id: "Occupation.710.7236",defaultMessage: "IronWorker"}), value: "IronWorker"},
  {code: "7237", title: defineMessage({id: "Occupation.711.7237",defaultMessage: "Welder"}), value: "Welder"},
  {code: "7241", title: defineMessage({id: "Occupation.712.7241",defaultMessage: "Electrician"}), value: "Electrician"},
  {code: "7242", title: defineMessage({id: "Occupation.713.7242",defaultMessage: "Industrial Electrician"}), value: "Industrial Electrician"},
  {code: "7243", title: defineMessage({id: "Occupation.714.7243",defaultMessage: "Power System Electrician"}), value: "Power System Electrician"},
  {code: "7244", title: defineMessage({id: "Occupation.715.7244",defaultMessage: "Electrical Power Line And Cable Worker"}), value: "Electrical Power Line And Cable Worker"},
  {code: "7245", title: defineMessage({id: "Occupation.716.7245",defaultMessage: "Telecommunications Technician"}), value: "Telecommunications Technician"},
  {code: "7246", title: defineMessage({id: "Occupation.717.7246",defaultMessage: "Telecommunications Installation And Repair Worker"}), value: "Telecommunications Installation And Repair Worker"},
  {code: "7247", title: defineMessage({id: "Occupation.718.7247",defaultMessage: "Cable Television Service And Maintenance Technician"}), value: "Cable Television Service And Maintenance Technician"},
  {code: "7251", title: defineMessage({id: "Occupation.719.7251",defaultMessage: "Plumber"}), value: "Plumber"},
  {code: "7252", title: defineMessage({id: "Occupation.720.7252",defaultMessage: "Steamfitter"}), value: "Steamfitter"},
  {code: "7252", title: defineMessage({id: "Occupation.721.7252",defaultMessage: "Pipefitter"}), value: "Pipefitter"},
  {code: "7252", title: defineMessage({id: "Occupation.722.7252",defaultMessage: "Sprinkler System Installer"}), value: "Sprinkler System Installer"},
  {code: "7253", title: defineMessage({id: "Occupation.723.7253",defaultMessage: "Gas Fitter"}), value: "Gas Fitter"},
  {code: "7271", title: defineMessage({id: "Occupation.724.7271",defaultMessage: "Carpenter"}), value: "Carpenter"},
  {code: "7272", title: defineMessage({id: "Occupation.725.7272",defaultMessage: "Cabinetmaker"}), value: "Cabinetmaker"},
  {code: "7281", title: defineMessage({id: "Occupation.726.7281",defaultMessage: "Bricklayer"}), value: "Bricklayer"},
  {code: "7282", title: defineMessage({id: "Occupation.727.7282",defaultMessage: "Concrete Finisher"}), value: "Concrete Finisher"},
  {code: "7283", title: defineMessage({id: "Occupation.728.7283",defaultMessage: "Tilesetter"}), value: "Tilesetter"},
  {code: "7284", title: defineMessage({id: "Occupation.729.7284",defaultMessage: "Lather"}), value: "Lather"},
  {code: "7284", title: defineMessage({id: "Occupation.730.7284",defaultMessage: "Plasterer"}), value: "Plasterer"},
  {code: "7284", title: defineMessage({id: "Occupation.731.7284",defaultMessage: "Drywall Installer"}), value: "Drywall Installer"},
  {code: "7291", title: defineMessage({id: "Occupation.732.7291",defaultMessage: "Roofers And Shinglers"}), value: "Roofers And Shinglers"},
  {code: "7292", title: defineMessage({id: "Occupation.733.7292",defaultMessage: "Glazier"}), value: "Glazier"},
  {code: "7293", title: defineMessage({id: "Occupation.734.7293",defaultMessage: "Insulator"}), value: "Insulator"},
  {code: "7294", title: defineMessage({id: "Occupation.735.7294",defaultMessage: "Painter"}), value: "Painter"},
  {code: "7294", title: defineMessage({id: "Occupation.736.7294",defaultMessage: "Decorators (Except Interior Decorators)"}), value: "Decorators (Except Interior Decorators)"},
  {code: "7295", title: defineMessage({id: "Occupation.737.7295",defaultMessage: "Floor Covering Installer"}), value: "Floor Covering Installer"},
  {code: "7301", title: defineMessage({id: "Occupation.738.7301",defaultMessage: "Contractor and Supervisor Mechanic Trades"}), value: "Contractor and Supervisor Mechanic Trades"},
  {code: "7302", title: defineMessage({id: "Occupation.739.7302",defaultMessage: "Contractor and Supervisor Heavy Equipment Operators"}), value: "Contractor and Supervisor Heavy Equipment Operators"},
  {code: "7303", title: defineMessage({id: "Occupation.740.7303",defaultMessage: "Supervisor Printing and Related "}), value: "Supervisor Printing and Related "},
  {code: "7304", title: defineMessage({id: "Occupation.741.7304",defaultMessage: "Supervisor Railway Transport Operations"}), value: "Supervisor Railway Transport Operations"},
  {code: "7305", title: defineMessage({id: "Occupation.742.7305",defaultMessage: "Supervisor Motor Transport And Other Ground Transit"}), value: "Supervisor Motor Transport And Other Ground Transit"},
  {code: "7311", title: defineMessage({id: "Occupation.743.7311",defaultMessage: "Construction Millwrights And Industrial Mechanics"}), value: "Construction Millwrights And Industrial Mechanics"},
  {code: "7312", title: defineMessage({id: "Occupation.744.7312",defaultMessage: "Heavy-Duty Equipment Mechanics"}), value: "Heavy-Duty Equipment Mechanics"},
  {code: "7313", title: defineMessage({id: "Occupation.745.7313",defaultMessage: "Refrigeration And Air Conditioning Mechanics"}), value: "Refrigeration And Air Conditioning Mechanics"},
  {code: "7314", title: defineMessage({id: "Occupation.746.7314",defaultMessage: "Railway Carmen/Women"}), value: "Railway Carmen/Women"},
  {code: "7315", title: defineMessage({id: "Occupation.747.7315",defaultMessage: "Aircraft Mechanics And Aircraft Insps"}), value: "Aircraft Mechanics And Aircraft Insps"},
  {code: "7316", title: defineMessage({id: "Occupation.748.7316",defaultMessage: "Machine Fitters"}), value: "Machine Fitters"},
  {code: "7318", title: defineMessage({id: "Occupation.749.7318",defaultMessage: "Elevator Constructors And Mechanics"}), value: "Elevator Constructors And Mechanics"},
  {code: "7321", title: defineMessage({id: "Occupation.750.7321",defaultMessage: "Automotive Mechanic"}), value: "Automotive Mechanic"},
  {code: "7321", title: defineMessage({id: "Occupation.751.7321",defaultMessage: "Automotive Service Technician"}), value: "Automotive Service Technician"},
  {code: "7321", title: defineMessage({id: "Occupation.752.7321",defaultMessage: "Truck And Bus Mechanics And Mechanical Repairers"}), value: "Truck And Bus Mechanics And Mechanical Repairers"},
  {code: '7321', title: defineMessage({id: "Occupation.952.7321",defaultMessage: "Mechanical Repairer, Motor Vehicle"}), value: "Mechanical Repairer, Motor Vehicle"},
  {code: "7322", title: defineMessage({id: "Occupation.753.7322",defaultMessage: "Motor Vehicle Body Repairers"}), value: "Motor Vehicle Body Repairers"},
  {code: "7331", title: defineMessage({id: "Occupation.754.7331",defaultMessage: "Oil And Solid Fuel Heating Mechanics"}), value: "Oil And Solid Fuel Heating Mechanics"},
  {code: "7332", title: defineMessage({id: "Occupation.755.7332",defaultMessage: "Appliance Servicers And Repairers"}), value: "Appliance Servicers And Repairers"},
  {code: "7333", title: defineMessage({id: "Occupation.756.7333",defaultMessage: "Electrical Mechanics"}), value: "Electrical Mechanics"},
  {code: "7334", title: defineMessage({id: "Occupation.757.7334",defaultMessage: "Motorcycle And All-Terrain Vehicle Mechanics"}), value: "Motorcycle And All-Terrain Vehicle Mechanics"},
  {code: "7335", title: defineMessage({id: "Occupation.758.7335",defaultMessage: "Mechanic, Small Engines and Equipment"}), value: "Mechanic, Small Engines and Equipment"},
  {code: "7335", title: defineMessage({id: "Occupation.759.7335",defaultMessage: "Other Small Engine/Small Equipment Repairers"}), value: "Other Small Engine/Small Equipment Repairers"},
  {code: "7361", title: defineMessage({id: "Occupation.760.7361",defaultMessage: "Railway And Yard Locomotive Engineers"}), value: "Railway And Yard Locomotive Engineers"},
  {code: "7362", title: defineMessage({id: "Occupation.761.7362",defaultMessage: "Railway Conductors And Brakemen/Women"}), value: "Railway Conductors And Brakemen/Women"},
  {code: "7371", title: defineMessage({id: "Occupation.762.7371",defaultMessage: "Crane Operators"}), value: "Crane Operators"},
  {code: "7372", title: defineMessage({id: "Occupation.763.7372",defaultMessage: "Drillers/Blasters - Mining, Quarrying And Const"}), value: "Drillers/Blasters - Mining, Quarrying And Const"},
  {code: "7373", title: defineMessage({id: "Occupation.764.7373",defaultMessage: "Water Well Drillers"}), value: "Water Well Drillers"},
  {code: "7381", title: defineMessage({id: "Occupation.765.7381",defaultMessage: "Printing Press Operators"}), value: "Printing Press Operators"},
  {code: "7384", title: defineMessage({id: "Occupation.766.7384",defaultMessage: "Other Trades And Related , N.E.C."}), value: "Other Trades And Related , N.E.C."},
  {code: "7441", title: defineMessage({id: "Occupation.767.7441",defaultMessage: "Resi And Comm Installers And Servicers"}), value: "Resi And Comm Installers And Servicers"},
  {code: "7442", title: defineMessage({id: "Occupation.768.7442",defaultMessage: "Waterworks And Gas Maintenance Worker"}), value: "Waterworks And Gas Maintenance Worker"},
  {code: "7444", title: defineMessage({id: "Occupation.769.7444",defaultMessage: "Pest Controllers And Fumigators"}), value: "Pest Controllers And Fumigators"},
  {code: "7445", title: defineMessage({id: "Occupation.770.7445",defaultMessage: "Bicycle Repairer"}), value: "Bicycle Repairer"},
  {code: "7445", title: defineMessage({id: "Occupation.771.7445",defaultMessage: "Camera Repairer"}), value: "Camera Repairer"},
  {code: "7445", title: defineMessage({id: "Occupation.772.7445",defaultMessage: "Piano Repairer"}), value: "Piano Repairer"},
  {code: "7445", title: defineMessage({id: "Occupation.773.7445",defaultMessage: "Sporting Goods Repairer"}), value: "Sporting Goods Repairer"},
  {code: "7445", title: defineMessage({id: "Occupation.774.7445",defaultMessage: "Vending Machine Repairer"}), value: "Vending Machine Repairer"},
  {code: "7445", title: defineMessage({id: "Occupation.775.7445",defaultMessage: "Other Repairers And Servicers"}), value: "Other Repairers And Servicers"},
  {code: "7451", title: defineMessage({id: "Occupation.776.7451",defaultMessage: "Longshore Worker"}), value: "Longshore Worker"},
  {code: "7452", title: defineMessage({id: "Occupation.777.7452",defaultMessage: "Material Handlers"}), value: "Material Handlers"},
  {code: "7511", title: defineMessage({id: "Occupation.778.7511",defaultMessage: "Truck Driver"}), value: "Truck Driver"},
  {code: "7512", title: defineMessage({id: "Occupation.779.7512",defaultMessage: "Public Transit Operators"}), value: "Public Transit Operators"},
  {code: "7512", title: defineMessage({id: "Occupation.780.7512",defaultMessage: "Bus Driver"}), value: "Bus Driver"},
  {code: "7513", title: defineMessage({id: "Occupation.781.7513",defaultMessage: "Taxi And Limousine Drivers And Chauffeurs"}), value: "Taxi And Limousine Drivers And Chauffeurs"},
  {code: '7513', title: defineMessage({id: "Occupation.953.7513",defaultMessage: "Taxi Owner-Operator"}), value: "Taxi Owner-Operator"},
  {code: "7514", title: defineMessage({id: "Occupation.782.7514",defaultMessage: "Delivery And Courier Service Drivers"}), value: "Delivery And Courier Service Drivers"},
  {code: "7521", title: defineMessage({id: "Occupation.783.7521",defaultMessage: "Heavy Equipment Operators (Except Crane)"}), value: "Heavy Equipment Operators (Except Crane)"},
  {code: "7522", title: defineMessage({id: "Occupation.784.7522",defaultMessage: "Public Works Maintenance Equipment Operators"}), value: "Public Works Maintenance Equipment Operators"},
  {code: "7531", title: defineMessage({id: "Occupation.785.7531",defaultMessage: "Railway Yard And Track Maintenance Worker"}), value: "Railway Yard And Track Maintenance Worker"},
  {code: "7532", title: defineMessage({id: "Occupation.786.7532",defaultMessage: "Water Transport Deck And Engine Room Crew"}), value: "Water Transport Deck And Engine Room Crew"},
  {code: "7533", title: defineMessage({id: "Occupation.787.7533",defaultMessage: "Boat Operator"}), value: "Boat Operator"},
  {code: "7533", title: defineMessage({id: "Occupation.788.7533",defaultMessage: "Cable Ferry Operator"}), value: "Cable Ferry Operator"},
  {code: "7534", title: defineMessage({id: "Occupation.789.7534",defaultMessage: "Air Transport Ramp Attendants"}), value: "Air Transport Ramp Attendants"},
  {code: "7535", title: defineMessage({id: "Occupation.790.7535",defaultMessage: "Automotive Mechanical Installers And Servicers"}), value: "Automotive Mechanical Installers And Servicers"},
  {code: "7611", title: defineMessage({id: "Occupation.791.7611",defaultMessage: "Construction Trades Helpers And Labourers"}), value: "Construction Trades Helpers And Labourers"},
  {code: "7612", title: defineMessage({id: "Occupation.792.7612",defaultMessage: "Other Trades Helpers And Labourers"}), value: "Other Trades Helpers And Labourers"},
  {code: "7621", title: defineMessage({id: "Occupation.793.7621",defaultMessage: "Public Works And Maintenance Labourers"}), value: "Public Works And Maintenance Labourers"},
  {code: "7622", title: defineMessage({id: "Occupation.794.7622",defaultMessage: "Railway And Motor Transport Labourers"}), value: "Railway And Motor Transport Labourers"},
  {code: "8211", title: defineMessage({id: "Occupation.795.8211",defaultMessage: "Supervisor Logging and Forestry"}), value: "Supervisor Logging and Forestry"},
  {code: "8221", title: defineMessage({id: "Occupation.796.8221",defaultMessage: "Supervisor Mining and Quarrying"}), value: "Supervisor Mining and Quarrying"},
  {code: "8222", title: defineMessage({id: "Occupation.797.8222",defaultMessage: "Supervisor Oil and Gas Drilling and Services"}), value: "Supervisor Oil and Gas Drilling and Services"},
  {code: "8222", title: defineMessage({id: "Occupation.798.8222",defaultMessage: "Contractors, Oil And Gas Drilling And Services"}), value: "Contractors, Oil And Gas Drilling And Services"},
  {code: "8231", title: defineMessage({id: "Occupation.799.8231",defaultMessage: "Underground Production Miners"}), value: "Underground Production Miners"},
  {code: "8232", title: defineMessage({id: "Occupation.800.8232",defaultMessage: "Oil And Gas Well Drillers, Servicers, Testers"}), value: "Oil And Gas Well Drillers, Servicers, Testers"},
  {code: "8241", title: defineMessage({id: "Occupation.801.8241",defaultMessage: "Logging Machinery Operators"}), value: "Logging Machinery Operators"},
  {code: "8252", title: defineMessage({id: "Occupation.802.8252",defaultMessage: "Agricultural Service Contractors"}), value: "Agricultural Service Contractors"},
  {code: "8252", title: defineMessage({id: "Occupation.803.8252",defaultMessage: "Farm Supervisor"}), value: "Farm Supervisor"},
  {code: "8252", title: defineMessage({id: "Occupation.804.8252",defaultMessage: "Specialized Livestock Worker"}), value: "Specialized Livestock Worker"},
  {code: "8255", title: defineMessage({id: "Occupation.805.8255",defaultMessage: "Contractor and Supervisor Landscaping"}), value: "Contractor and Supervisor Landscaping"},
  {code: "8255", title: defineMessage({id: "Occupation.806.8255",defaultMessage: "Contractor and Supervisor Grounds Maintenance"}), value: "Contractor and Supervisor Grounds Maintenance"},
  {code: "8255", title: defineMessage({id: "Occupation.807.8255",defaultMessage: "Contractor and Supervisor Horticulture Services"}), value: "Contractor and Supervisor Horticulture Services"},
  {code: "8261", title: defineMessage({id: "Occupation.808.8261",defaultMessage: "Fishing Masters and Officers"}), value: "Fishing Masters and Officers"},
  {code: "8262", title: defineMessage({id: "Occupation.809.8262",defaultMessage: "Fishermen/Women"}), value: "Fishermen/Women"},
  {code: "8411", title: defineMessage({id: "Occupation.810.8411",defaultMessage: "Underground Mine Service And Support Worker"}), value: "Underground Mine Service And Support Worker"},
  {code: "8412", title: defineMessage({id: "Occupation.811.8412",defaultMessage: "Oil And Gas Well Drilling And Services Operators"}), value: "Oil And Gas Well Drilling And Services Operators"},
  {code: "8421", title: defineMessage({id: "Occupation.812.8421",defaultMessage: "Chain Saw And Skidder Operators"}), value: "Chain Saw And Skidder Operators"},
  {code: "8422", title: defineMessage({id: "Occupation.813.8422",defaultMessage: "Silviculture And Forestry Worker"}), value: "Silviculture And Forestry Worker"},
  {code: "8431", title: defineMessage({id: "Occupation.814.8431",defaultMessage: "General Farm Worker"}), value: "General Farm Worker"},
  {code: "8431", title: defineMessage({id: "Occupation.815.8431",defaultMessage: "Dairy Farmer"}), value: "Dairy Farmer"},
  {code: "8431", title: defineMessage({id: "Occupation.816.8431",defaultMessage: "Fruit Farmer"}), value: "Fruit Farmer"},
  {code: "8432", title: defineMessage({id: "Occupation.817.8432",defaultMessage: "Nursery And Greenhouse Worker"}), value: "Nursery And Greenhouse Worker"},
  {code: "8441", title: defineMessage({id: "Occupation.818.8441",defaultMessage: "Fishing Vessel Deckhands"}), value: "Fishing Vessel Deckhands"},
  {code: "8442", title: defineMessage({id: "Occupation.819.8442",defaultMessage: "Trappers And Hunters"}), value: "Trappers And Hunters"},
  {code: "8611", title: defineMessage({id: "Occupation.820.8611",defaultMessage: "Harvesting Labourers"}), value: "Harvesting Labourers"},
  {code: "8612", title: defineMessage({id: "Occupation.821.8612",defaultMessage: "Landscaper"}), value: "Landscaper"},
  {code: "8613", title: defineMessage({id: "Occupation.822.8613",defaultMessage: "Aquaculture And Marine Harvest Labourers"}), value: "Aquaculture And Marine Harvest Labourers"},
  {code: "8614", title: defineMessage({id: "Occupation.823.8614",defaultMessage: "Mine Labourers"}), value: "Mine Labourers"},
  {code: "8615", title: defineMessage({id: "Occupation.824.8615",defaultMessage: "Oil And Gas Drilling, Servicing Labourers"}), value: "Oil And Gas Drilling, Servicing Labourers"},
  {code: "8616", title: defineMessage({id: "Occupation.825.8616",defaultMessage: "Logging And Forestry Labourers"}), value: "Logging And Forestry Labourers"},
  {code: "9211", title: defineMessage({id: "Occupation.826.9211",defaultMessage: "Supervisor Mineral And Metal Processing"}), value: "Supervisor Mineral And Metal Processing"},
  {code: "9212", title: defineMessage({id: "Occupation.827.9212",defaultMessage: "Supervisor Petro And Gas Processing And Utilities"}), value: "Supervisor Petro And Gas Processing And Utilities"},
  {code: "9212", title: defineMessage({id: "Occupation.828.9212",defaultMessage: "Supervisor Plant "}), value: "Supervisor Plant "},
  {code: "9213", title: defineMessage({id: "Occupation.829.9213",defaultMessage: "Supervisor Food And Beverage Products Processing"}), value: "Supervisor Food And Beverage Products Processing"},
  {code: "9214", title: defineMessage({id: "Occupation.830.9214",defaultMessage: "Supervisor Plastic And Rubber Products Manufacturing"}), value: "Supervisor Plastic And Rubber Products Manufacturing"},
  {code: "9215", title: defineMessage({id: "Occupation.831.9215",defaultMessage: "Supervisor Forest Products Processing"}), value: "Supervisor Forest Products Processing"},
  {code: "9217", title: defineMessage({id: "Occupation.832.9217",defaultMessage: "Supervisor Textile And Leather Products Manufacturing"}), value: "Supervisor Textile And Leather Products Manufacturing"},
  {code: "9221", title: defineMessage({id: "Occupation.833.9221",defaultMessage: "Supervisor Motor Vehicle Assembling"}), value: "Supervisor Motor Vehicle Assembling"},
  {code: "9222", title: defineMessage({id: "Occupation.834.9222",defaultMessage: "Supervisor Electronics Manufacturing"}), value: "Supervisor Electronics Manufacturing"},
  {code: "9223", title: defineMessage({id: "Occupation.835.9223",defaultMessage: "Supervisor Electrical Products Manufacturing"}), value: "Supervisor Electrical Products Manufacturing"},
  {code: "9224", title: defineMessage({id: "Occupation.836.9224",defaultMessage: "Supervisor Furniture And Fixtures Manufacturing"}), value: "Supervisor Furniture And Fixtures Manufacturing"},
  {code: "9226", title: defineMessage({id: "Occupation.837.9226",defaultMessage: "Supervisor Mechanical And Metal Products Manufacturing"}), value: "Supervisor Mechanical And Metal Products Manufacturing"},
  {code: "9227", title: defineMessage({id: "Occupation.838.9227",defaultMessage: "Supervisor Other Products Manufacturing And Assembly"}), value: "Supervisor Other Products Manufacturing And Assembly"},
  {code: "9231", title: defineMessage({id: "Occupation.839.9231",defaultMessage: "Process Operators, Mineral And Metal Processing"}), value: "Process Operators, Mineral And Metal Processing"},
  {code: "9232", title: defineMessage({id: "Occupation.840.9232",defaultMessage: "Petro, Gas And Chemical Process Operators"}), value: "Petro, Gas And Chemical Process Operators"},
  {code: "9235", title: defineMessage({id: "Occupation.841.9235",defaultMessage: "Pulping, Papermaking Control Operators"}), value: "Pulping, Papermaking Control Operators"},
  {code: "9241", title: defineMessage({id: "Occupation.842.9241",defaultMessage: "Power Engineers And Power Systems Operators"}), value: "Power Engineers And Power Systems Operators"},
  {code: "9243", title: defineMessage({id: "Occupation.843.9243",defaultMessage: "Water And Waste Treatment Plant Operators"}), value: "Water And Waste Treatment Plant Operators"},
  {code: "9411", title: defineMessage({id: "Occupation.844.9411",defaultMessage: "Machine Operators, Mineral And Metal Processing"}), value: "Machine Operators, Mineral And Metal Processing"},
  {code: "9412", title: defineMessage({id: "Occupation.845.9412",defaultMessage: "Foundry Worker"}), value: "Foundry Worker"},
  {code: "9413", title: defineMessage({id: "Occupation.846.9413",defaultMessage: "Glass Forming Machine Operators And Glass Cutters"}), value: "Glass Forming Machine Operators And Glass Cutters"},
  {code: "9414", title: defineMessage({id: "Occupation.847.9414",defaultMessage: "Concrete, Clay And Stone Forming Operators"}), value: "Concrete, Clay And Stone Forming Operators"},
  {code: "9415", title: defineMessage({id: "Occupation.848.9415",defaultMessage: "Inspectors And Testers, Mineral And Metal Processing"}), value: "Inspectors And Testers, Mineral And Metal Processing"},
  {code: "9416", title: defineMessage({id: "Occupation.849.9416",defaultMessage: "Metalworking And Forging Machine Operators"}), value: "Metalworking And Forging Machine Operators"},
  {code: "9417", title: defineMessage({id: "Occupation.850.9417",defaultMessage: "Machining Tool Operators"}), value: "Machining Tool Operators"},
  {code: "9418", title: defineMessage({id: "Occupation.851.9418",defaultMessage: "Other Metal Products Machine Operators"}), value: "Other Metal Products Machine Operators"},
  {code: "9421", title: defineMessage({id: "Occupation.852.9421",defaultMessage: "Chemical Plant Machine Operators"}), value: "Chemical Plant Machine Operators"},
  {code: "9422", title: defineMessage({id: "Occupation.853.9422",defaultMessage: "Plastics Processing Machine Operators"}), value: "Plastics Processing Machine Operators"},
  {code: "9423", title: defineMessage({id: "Occupation.854.9423",defaultMessage: "Rubber Processing Machine Operators"}), value: "Rubber Processing Machine Operators"},
  {code: "9431", title: defineMessage({id: "Occupation.855.9431",defaultMessage: "Sawmill Machine Operators"}), value: "Sawmill Machine Operators"},
  {code: "9432", title: defineMessage({id: "Occupation.856.9432",defaultMessage: "Pulp Mill Machine Operators"}), value: "Pulp Mill Machine Operators"},
  {code: "9433", title: defineMessage({id: "Occupation.857.9433",defaultMessage: "Papermaking Machine Operators"}), value: "Papermaking Machine Operators"},
  {code: "9434", title: defineMessage({id: "Occupation.858.9434",defaultMessage: "Other Wood Processing Machine Operators"}), value: "Other Wood Processing Machine Operators"},
  {code: "9435", title: defineMessage({id: "Occupation.859.9435",defaultMessage: "Paper Converting Machine Operators"}), value: "Paper Converting Machine Operators"},
  {code: "9436", title: defineMessage({id: "Occupation.860.9436",defaultMessage: "Lumber Graders"}), value: "Lumber Graders"},
  {code: "9437", title: defineMessage({id: "Occupation.861.9437",defaultMessage: "Woodworking Machine Operators"}), value: "Woodworking Machine Operators"},
  {code: "9441", title: defineMessage({id: "Occupation.862.9441",defaultMessage: "Textile And Hide/Pelt Processing Machine Operators"}), value: "Textile And Hide/Pelt Processing Machine Operators"},
  {code: "9442", title: defineMessage({id: "Occupation.863.9442",defaultMessage: "Weavers, Knitters And Other Fabric Making "}), value: "Weavers, Knitters And Other Fabric Making "},
  {code: "9445", title: defineMessage({id: "Occupation.864.9445",defaultMessage: "Fabric, Fur And Leather Cutters"}), value: "Fabric, Fur And Leather Cutters"},
  {code: "9446", title: defineMessage({id: "Occupation.865.9446",defaultMessage: "Industrial Sewing Machine Operators"}), value: "Industrial Sewing Machine Operators"},
  {code: "9447", title: defineMessage({id: "Occupation.866.9447",defaultMessage: "Graders, Textile And Leather Products Manufacturing"}), value: "Graders, Textile And Leather Products Manufacturing"},
  {code: "9461", title: defineMessage({id: "Occupation.867.9461",defaultMessage: "Machine Operators, Food, Beverage Processing"}), value: "Machine Operators, Food, Beverage Processing"},
  {code: "9462", title: defineMessage({id: "Occupation.868.9462",defaultMessage: "Industrial Butchers And Related Worker"}), value: "Industrial Butchers And Related Worker"},
  {code: "9463", title: defineMessage({id: "Occupation.869.9463",defaultMessage: "Fish And Seafood Plant Worker"}), value: "Fish And Seafood Plant Worker"},
  {code: "9465", title: defineMessage({id: "Occupation.870.9465",defaultMessage: "Testers, Food And Beverage Products Processing"}), value: "Testers, Food And Beverage Products Processing"},
  {code: "9471", title: defineMessage({id: "Occupation.871.9471",defaultMessage: "Plateless Printing Equipment Operators"}), value: "Plateless Printing Equipment Operators"},
  {code: "9471", title: defineMessage({id: "Occupation.872.9471",defaultMessage: "Printing Machine Operator"}), value: "Printing Machine Operator"},
  {code: "9472", title: defineMessage({id: "Occupation.873.9472",defaultMessage: "Camera, Platemaking and Other Prepress "}), value: "Camera, Platemaking and Other Prepress "},
  {code: "9473", title: defineMessage({id: "Occupation.874.9473",defaultMessage: "Binding and Finishing Machine Operators"}), value: "Binding and Finishing Machine Operators"},
  {code: "9474", title: defineMessage({id: "Occupation.875.9474",defaultMessage: "Photographic and Film Processors"}), value: "Photographic and Film Processors"},
  {code: "9521", title: defineMessage({id: "Occupation.876.9521",defaultMessage: "Aircraft Assembler"}), value: "Aircraft Assembler"},
  {code: "9521", title: defineMessage({id: "Occupation.877.9521",defaultMessage: "Aircraft Inspector"}), value: "Aircraft Inspector"},
  {code: "9522", title: defineMessage({id: "Occupation.878.9522",defaultMessage: "Motor Vehicle Assembler"}), value: "Motor Vehicle Assembler"},
  {code: "9522", title: defineMessage({id: "Occupation.879.9522",defaultMessage: "Motor Vehicle Inspector or Tester"}), value: "Motor Vehicle Inspector or Tester"},
  {code: "9523", title: defineMessage({id: "Occupation.880.9523",defaultMessage: "Electronics Assembler"}), value: "Electronics Assembler"},
  {code: "9523", title: defineMessage({id: "Occupation.881.9523",defaultMessage: "Electronics Inspector or Tester"}), value: "Electronics Inspector or Tester"},
  {code: "9524", title: defineMessage({id: "Occupation.882.9524",defaultMessage: "Electrical Appliance Assembler"}), value: "Electrical Appliance Assembler"},
  {code: "9524", title: defineMessage({id: "Occupation.883.9524",defaultMessage: "Electrical Appliance Inspector or Tester "}), value: "Electrical Appliance Inspector or Tester "},
  {code: "9525", title: defineMessage({id: "Occupation.884.9525",defaultMessage: "Fabricators, Industrial Electrical Motors and Transformers"}), value: "Fabricators, Industrial Electrical Motors and Transformers"},
  {code: "9525", title: defineMessage({id: "Occupation.885.9525",defaultMessage: "Assemblers, Industrial Electrical Motors and Transformers"}), value: "Assemblers, Industrial Electrical Motors and Transformers"},
  {code: "9525", title: defineMessage({id: "Occupation.886.9525",defaultMessage: "Inspectors, Industrial Electrical Motors And Transformers"}), value: "Inspectors, Industrial Electrical Motors And Transformers"},
  {code: "9526", title: defineMessage({id: "Occupation.887.9526",defaultMessage: "Mechanical Assemblers and Inspectors"}), value: "Mechanical Assemblers and Inspectors"},
  {code: "9527", title: defineMessage({id: "Occupation.888.9527",defaultMessage: "Machine Operators and Inspector, Electrical Apparatus Manufacturing"}), value: "Machine Operators and Inspector, Electrical Apparatus Manufacturing"},
  {code: "9531", title: defineMessage({id: "Occupation.889.9531",defaultMessage: "Boat Assemblers And Inspectors"}), value: "Boat Assemblers And Inspectors"},
  {code: "9532", title: defineMessage({id: "Occupation.890.9532",defaultMessage: "Furniture And Fixture Assemblers And Inspectors"}), value: "Furniture And Fixture Assemblers And Inspectors"},
  {code: "9533", title: defineMessage({id: "Occupation.891.9533",defaultMessage: "Other Wood Products Assemblers And Inspectors"}), value: "Other Wood Products Assemblers And Inspectors"},
  {code: "9534", title: defineMessage({id: "Occupation.892.9534",defaultMessage: "Furniture Finishers And Refinishers"}), value: "Furniture Finishers And Refinishers"},
  {code: "9535", title: defineMessage({id: "Occupation.893.9535",defaultMessage: "Plastic Products Assemblers, Finishers And Inspectors"}), value: "Plastic Products Assemblers, Finishers And Inspectors"},
  {code: "9536", title: defineMessage({id: "Occupation.894.9536",defaultMessage: "Industrial Painters"}), value: "Industrial Painters"},
  {code: "9536", title: defineMessage({id: "Occupation.895.9536",defaultMessage: "Coaters And Metal Finishing Process Operators"}), value: "Coaters And Metal Finishing Process Operators"},
  {code: "9537", title: defineMessage({id: "Occupation.896.9537",defaultMessage: "Other Products Assemblers, Finishers And Inspectors"}), value: "Other Products Assemblers, Finishers And Inspectors"},
  {code: "9611", title: defineMessage({id: "Occupation.897.9611",defaultMessage: "Labourer Mineral And Metal Processing"}), value: "Labourer Mineral And Metal Processing"},
  {code: "9612", title: defineMessage({id: "Occupation.898.9612",defaultMessage: "Labourer Metal Fabrication"}), value: "Labourer Metal Fabrication"},
  {code: "9613", title: defineMessage({id: "Occupation.899.9613",defaultMessage: "Labourer Chemical Products Processing"}), value: "Labourer Chemical Products Processing"},
  {code: "9614", title: defineMessage({id: "Occupation.900.9614",defaultMessage: "Labourer Wood, Pulp And Paper Processing"}), value: "Labourer Wood, Pulp And Paper Processing"},
  {code: "9615", title: defineMessage({id: "Occupation.901.9615",defaultMessage: "Labourer Rubber And Plastic Products Mfg"}), value: "Labourer Rubber And Plastic Products Mfg"},
  {code: "9616", title: defineMessage({id: "Occupation.902.9616",defaultMessage: "Labourer Textile Processing"}), value: "Labourer Textile Processing"},
  {code: "9617", title: defineMessage({id: "Occupation.903.9617",defaultMessage: "Labourer Food And Beverage Processing"}), value: "Labourer Food And Beverage Processing"},
  {code: "9618", title: defineMessage({id: "Occupation.904.9618",defaultMessage: "Labourer Fish And Seafood Processing"}), value: "Labourer Fish And Seafood Processing"},
  {code: "9619", title: defineMessage({id: "Occupation.905.9619",defaultMessage: "Labourer in Processing Other"}), value: "Labourer in Processing Other"},
  {code: "9619", title: defineMessage({id: "Occupation.906.9619",defaultMessage: "Labourer in Manufacturing Other"}), value: "Labourer in Manufacturing Other"},
  {code: "9619", title: defineMessage({id: "Occupation.907.9619",defaultMessage: "Labourer in Utilities Other"}), value: "Labourer in Utilities Other"},
];
