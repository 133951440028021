/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';

import { H2, P } from '../components';

export const PasswordResetSuccess = (props: RouteComponentProps) => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <KDSIcons.Spot.Success />
      <H2>
        {intl.formatMessage({
          id: 'PasswordResetSuccess.Title',
          defaultMessage: 'Your password has been changed!',
        })}
      </H2>
      <P>
        {intl.formatMessage(
          {
            id: 'PasswordResetSuccess.Content',
            defaultMessage: 'Open the {link} and log in to enjoy KOHO.',
          },
          { link: <a href="https://koho.onelink.me/Iw0N/3bcbce62">app</a> },
        )}
      </P>
    </React.Fragment>
  );
};
