import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Button, Modal, Paragraph, TitleLarge } from 'components';
import { contactActions } from 'contact/store/contact.slice';

export const ContactChatModal = ({ openDialog, onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Modal open={openDialog} onClose={onClose}>
      <TitleLarge sx={{ mt: 0 }}>
        {intl.formatMessage({
          id: 'HisaAssumptionsPage.ChatModal.Title',
          defaultMessage: 'Chat with us to continue',
        })}
      </TitleLarge>
      <Paragraph>
        {intl.formatMessage({
          id: 'HisaAssumptionsPage.ChatModal.Description',
          defaultMessage:
            'If these assumptions are not correct, please speak to an agent to continue signing up.',
        })}
      </Paragraph>
      <Button
        sx={{ mt: 2 }}
        variant="tertiary"
        onClick={() => {
          dispatch(contactActions.intercomOpen());
          onClose();
        }}
      >
        {intl.formatMessage({
          id: 'HisaAssumptionsPage.ChatModal.Close',
          defaultMessage: 'Chat with us',
        })}
      </Button>
    </Modal>
  );
};
