import { track } from './analytics';

export const trackGaIds = (userId: string) => {
  const gaClientIdValueFromCookie = document.cookie.match(/_ga=(.+?);/);

  const gaClientIdValue = gaClientIdValueFromCookie
    ? gaClientIdValueFromCookie[1]
    : null;
  const gaClientIdParsed = gaClientIdValue
    ? gaClientIdValue.split('.').slice(-2).join('.')
    : null;

  const gaGidValueFromCookie = document.cookie.match(/_gid=(.+?);/);
  const gaGidValue = gaGidValueFromCookie ? gaGidValueFromCookie[1] : null;

  track({
    event: 'GA IDs Synced',
    properties: {
      userId,
      clientId: gaClientIdParsed,
      _ga: gaClientIdValue,
      _gid: gaGidValue,
    },
  });
};
