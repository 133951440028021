import { useIntl } from 'react-intl';

import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { IBillingPaymentMethod } from 'apis/billing';
import { KDSIcons } from 'assets/images/kds_icons';
import { ReactComponent as Amex } from 'assets/images/logos/amex.svg';
import { ReactComponent as DinersClub } from 'assets/images/logos/diners-club.svg';
import { ReactComponent as Discover } from 'assets/images/logos/discover.svg';
import { ReactComponent as Jcb } from 'assets/images/logos/jcb.svg';
import { ReactComponent as MCard } from 'assets/images/logos/mastercard.svg';
import { ReactComponent as UnionPay } from 'assets/images/logos/unionPay.svg';
import { ReactComponent as Visa } from 'assets/images/logos/visa.svg';
import { ReactComponent as KOHOCardIcon } from 'assets/images/transactions/koho-card.svg';
import { PaymentMethodCardNetwork } from 'billing/models/PaymentMethodCardNetwork';
import { mobile, theme } from 'theme';

export const CardLogo = ({
  brand,
}: {
  brand: PaymentMethodCardNetwork | undefined;
}) => {
  switch (brand) {
    case PaymentMethodCardNetwork.Visa:
      return <Visa sx={{ width: 40, mr: '12px' }} />;
    case PaymentMethodCardNetwork.Mastercard:
      return <MCard sx={{ width: 40, mr: '12px' }} />;
    case PaymentMethodCardNetwork.Amex:
      return <Amex sx={{ width: 40, mr: '12px' }} />;
    case PaymentMethodCardNetwork.Discover:
      return <Discover sx={{ width: 40, mr: '12px' }} />;
    case PaymentMethodCardNetwork.Jcb:
      return <Jcb sx={{ width: 40, mr: '12px' }} />;
    case PaymentMethodCardNetwork.Diners:
      return <DinersClub sx={{ width: 40, mr: '12px' }} />;
    case PaymentMethodCardNetwork.Unionpay:
      return <UnionPay sx={{ width: 40, mr: '12px' }} />;
    default:
      return <KDSIcons.Navigation.Spending sx={{ width: 40, mr: '12px' }} />;
  }
};

const KOHOMethod = ({
  balance,
  disabled,
  longForm = false,
}: {
  balance: string | undefined;
  disabled: boolean;
  longForm?: boolean;
}) => {
  const intl = useIntl();
  const opacity = disabled ? '0.5' : '1';

  return (
    <>
      <KOHOCardIcon sx={{ width: 40, mr: '12px', opacity }} />
      <Typography
        sx={{
          fontWeight: 900,
          color: theme.colors.text,
          mr: 1,
          opacity,
        }}
      >
        {longForm
          ? intl.formatMessage({
              id: 'KOHOPaymentMethod.Title',
              defaultMessage: 'KOHO Spendable',
            })
          : 'KOHO'}
      </Typography>
      <Typography
        sx={{
          fontWeight: 500,
          color: theme.colors.text,
          opacity,
        }}
      >
        ({balance})
      </Typography>
    </>
  );
};

const CardMethod = ({ method }: { method: IBillingPaymentMethod }) => {
  return (
    <>
      <CardLogo brand={method.card_brand} />

      <Typography sx={{ fontWeight: 900, color: theme.colors.text, mr: 1 }}>
        {'•••• ' + method.last4}
      </Typography>
    </>
  );
};

const AddCardMethod = () => {
  const intl = useIntl();
  const isMobile = useMediaQuery(mobile);

  return (
    <Box display="flex" flexDirection="row" sx={{ alignItems: 'center' }}>
      <KDSIcons.Icons.AddLegacy
        sx={{ color: theme.colors.tertiary, mr: '12px' }}
      />
      <Typography sx={{ fontWeight: 900, color: theme.colors.text, mr: 1 }}>
        {isMobile
          ? intl.formatMessage({
              id: 'PaymentMethodOptions.AddNew.short',
              defaultMessage: 'Debit/Credit card',
            })
          : intl.formatMessage({
              id: 'PaymentMethodOptions.AddNew',
              defaultMessage: 'Add credit/debit card',
            })}
      </Typography>
    </Box>
  );
};

export { AddCardMethod, CardMethod, KOHOMethod };
