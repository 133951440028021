/** @jsxImportSource theme-ui */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import {
  Backdrop,
  Box,
  Grid,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import {
  PutMoneyRequestError,
  PutMoneyRequestErrorCodes,
  PutMoneyRequestErrorResponse,
  useMoneyRequestPutNonceMutation,
} from 'apis/requestMoneyApi';
import { KDSIcons } from 'assets/images/kds_icons';
import { HttpStatusCode } from 'axios';
import {
  LayoutBasic,
  Paragraph,
  ParagraphSmall,
  SpinnerCircularProgress,
} from 'components';
import { CopyButton } from 'components/CopyButton';
import { RequestMoneyDownloadApp } from 'requestMoney/components/RequestMoneyDownloadApp';
import { RequestMoneyETransferDetails } from 'requestMoney/components/RequestMoneyETransferDetails';
import { RequestMoneyEmojiBackground } from 'requestMoney/components/RequestMoneyEmojiBackground';
import { RequestMoneyErrorCard } from 'requestMoney/components/RequestMoneyErrorCard';
import { RequestMoneyInfoDesktop } from 'requestMoney/components/RequestMoneyInfoDesktop';
import { RequestMoneyInfoMobile } from 'requestMoney/components/RequestMoneyInfoMobile';
import { RequestMoneyQRCode } from 'requestMoney/components/RequestMoneyQRCode';
import { RequestMoneyReferralDetails } from 'requestMoney/components/RequestMoneyReferralDetails';
import { RequestMoneyRequestCodeInfoModal } from 'requestMoney/components/RequestMoneyRequestCodeInfoModal';
import { desktop, theme } from 'theme';

export const RequestMoneyIntermediaryPage = ({
  requestCode = '',
}: {
  requestCode?: string;
} & RouteComponentProps) => {
  const intl = useIntl();
  const isDesktop = useMediaQuery(desktop);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
  const [putMoneyRequest, moneyRequestData] = useMoneyRequestPutNonceMutation();
  const [putMoneyRequestErr, setPutMoneyRequestErr] = React.useState<
    PutMoneyRequestError | undefined
  >();

  useEffect(() => {
    if (requestCode) {
      putMoneyRequest(requestCode)
        .unwrap()
        .catch((err) => {
          const errorRes = err as PutMoneyRequestErrorResponse;
          let moneyRequestError: PutMoneyRequestError;

          switch (errorRes.status) {
            case HttpStatusCode.NotFound:
              moneyRequestError = PutMoneyRequestError.InvalidCode;
              break;
            case HttpStatusCode.Gone:
              moneyRequestError = PutMoneyRequestError.RequestFulfilled;
              break;
            default:
              if (
                errorRes.error?.code ===
                PutMoneyRequestErrorCodes.RequestExpired
              ) {
                moneyRequestError = PutMoneyRequestError.RequestExpired;
              } else {
                moneyRequestError = PutMoneyRequestError.UnknownError;
              }
          }

          setPutMoneyRequestErr(moneyRequestError);
        });
    }
  }, [requestCode, putMoneyRequest]);

  return (
    <LayoutBasic pageName="Request Money">
      <Backdrop
        open={moneyRequestData.isLoading}
        sx={{ zIndex: 10 }}
        invisible={true}
      >
        <SpinnerCircularProgress />
      </Backdrop>
      <RequestMoneyRequestCodeInfoModal
        open={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
      />
      {!!putMoneyRequestErr && (
        <Box sx={{ px: [4, 0] }}>
          <RequestMoneyErrorCard
            error={putMoneyRequestErr}
            sx={{ mb: 5, width: ['100%', '400px'] }}
          />
          <RequestMoneyDownloadApp sx={{ width: ['100%', '400px'] }} />
        </Box>
      )}
      {!!moneyRequestData.data && (
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          spacing={isDesktop ? 10 : 0}
          sx={{ p: [3, 6] }}
        >
          <Grid item lg={4} xs={12}>
            {isDesktop ? (
              <RequestMoneyInfoDesktop request={moneyRequestData.data} />
            ) : (
              <RequestMoneyInfoMobile request={moneyRequestData.data} />
            )}
          </Grid>
          <Grid item lg={4} xs={12}>
            {isDesktop && (
              <>
                <Box
                  position="relative"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <RequestMoneyEmojiBackground
                    emoji={moneyRequestData.data.emoji}
                  />
                  <RequestMoneyQRCode
                    position="absolute"
                    destination={moneyRequestData.data.qr_code_destination}
                    initials={moneyRequestData.data.requester_initials}
                  />
                </Box>
                <div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Paragraph sx={{ fontWeight: 'bold' }}>
                    {intl.formatMessage({
                      id: 'RequestMoneyPage.UseCode',
                      defaultMessage: 'Or use the request code',
                    })}
                  </Paragraph>
                  <IconButton onClick={() => setIsInfoModalOpen(true)}>
                    <KDSIcons.Icons.QuestionBold color={theme.colors.primary} />
                  </IconButton>
                </div>
              </>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${theme.colors.greyLight}`,
              }}
            >
              <Paragraph>
                {intl.formatMessage({
                  id: 'RequestMoneyCopyField.Label',
                  defaultMessage: 'Request code',
                })}
                :
              </Paragraph>
              <Box sx={{ display: 'flex' }}>
                <Paragraph>{moneyRequestData.data.request_code}</Paragraph>
                <CopyButton
                  value={moneyRequestData.data.request_code}
                  trackingLabel="Request code"
                  sx={{ ml: 2, px: 3 }}
                />
              </Box>
            </Box>
            <RequestMoneyETransferDetails
              request={moneyRequestData.data}
              sx={{ mb: 5 }}
            ></RequestMoneyETransferDetails>
            {isDesktop ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 3,
                }}
              >
                <KDSIcons.Icons.QuestionBold sx={{ mr: 2 }} />
                <ParagraphSmall>
                  {intl.formatMessage(
                    {
                      id: 'RequestMoneyExpiry.ExpiresAt',
                      defaultMessage: 'This request will expire on {date}',
                    },
                    {
                      date: intl.formatDate(moneyRequestData.data.expires_at, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        timeZone: 'utc',
                      }),
                    },
                  )}
                </ParagraphSmall>
              </Box>
            ) : (
              <RequestMoneyReferralDetails request={moneyRequestData.data} />
            )}
          </Grid>
        </Grid>
      )}
    </LayoutBasic>
  );
};
