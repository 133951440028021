/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import { Box, Paragraph, TitleMedium } from 'components';
import DownloadAppButton from 'features/buttons/DownloadApp';

import headerImg from '../../assets/images/sideContent.jpg';

export const DownloadAppWidget = ({ ...props }) => {
  const intl = useIntl();

  return (
    <Box
      {...props}
      sx={{
        borderRadius: '8px',
        height: 'fit-content',
        overflow: 'hidden',
      }}
      variant="shadow"
    >
      <img
        src={headerImg}
        alt="download-app-img"
        sx={{
          height: ['140px', '260px', '160px'],
          width: '100%',
          objectFit: 'cover',
        }}
      ></img>
      <div sx={{ p: 4 }}>
        <TitleMedium sx={{ mt: 0 }}>
          {intl.formatMessage({
            id: 'DownloadAppWidget.Title',
            defaultMessage: 'Unlock even more',
          })}
        </TitleMedium>
        <Paragraph>
          {intl.formatMessage({
            id: 'DownloadAppWidget.Description',
            defaultMessage:
              'Build your credit history, effortlessly save, and more with the KOHO app.',
          })}
        </Paragraph>
        <DownloadAppButton />
      </div>
    </Box>
  );
};
