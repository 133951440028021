/** @jsxImportSource theme-ui */
import React from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ReferralRequest } from 'apis/registration';
import {
  Button,
  ErrorParagraph,
  Modal,
  Paragraph,
  SecurityAssurance,
  TitleLarge,
  TitleMedium,
} from 'components';
import { useValidationMsg } from 'components/forms/useValidationMsg';
import { KhInputMobileNumber } from 'components/inputs/KhInputMobileNumber';
import { RegistrationReferralCodeInput } from 'registration/components/RegistrationReferralCodeInput';
import { MobileNumberSchema } from 'registration/zodForms/zodFormRegistrationMobileNumber';
import { theme } from 'theme';
import { Flex } from 'theme-ui';
import {
  useTrackElementViewedOnce,
  useTrackPageViewedOnceDeprecated,
} from 'utility/analyticsHooks';

import referral from '../../assets/images/referral.png';

export const RegistrationMobileNumberPage = ({
  mobileNumberForm,
  submitForm,
  onReferralRequestUpdated,
  createUserLoading,
  referralCodePreSet,
  createUserError,
}: {
  submitForm: SubmitHandler<MobileNumberSchema>;
  mobileNumberForm: UseFormReturn<MobileNumberSchema, any>;
  onReferralRequestUpdated: (referralRequest: ReferralRequest | null) => void;
  createUserLoading: boolean;
  referralCodePreSet?: string;
  createUserError?: string;
}) => {
  const intl = useIntl();
  const {
    register,
    formState: { errors, isValid },

    getValues,
  } = mobileNumberForm;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [referralCodeValid, setReferralCodeValid] = React.useState(true);

  useTrackPageViewedOnceDeprecated({
    name: 'registration-mobile-number',
    overrideUrl: `registration/mobile-number`,
  });

  useTrackElementViewedOnce({
    element: errors?.mobileNumber,
    name: 'mobile-number-input-error',
  });

  const nextButtonClicked = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        PaperProps={{
          style: {
            borderRadius: '12px',
          },
        }}
      >
        <div sx={{ px: [1, 3] }}>
          <TitleMedium sx={{ mb: 3, mt: 0 }}>
            {intl.formatMessage(
              {
                id: 'RegistrationMobileNumberPage.Modal.Title',
                defaultMessage: 'Send to {mobile}?',
              },
              { mobile: getValues('mobileNumber') },
            )}
          </TitleMedium>
          <Paragraph sx={{ mb: 4 }}>
            {intl.formatMessage({
              id: 'RegistrationMobileNumberPage.Modal.Subtitle',
              defaultMessage:
                "We'll send your verification code to this phone number.",
            })}
          </Paragraph>
          <Button
            sx={{ mb: 3 }}
            onClick={() => {
              submitForm(getValues());
              setIsModalOpen(false);
            }}
            data-cy="mobile-confirm-button"
            trackName="mobile-number-confirm"
          >
            {intl.formatMessage({
              id: 'RegistrationMobileNumberPage.Modal.Confirm',
              defaultMessage: 'Confirm',
            })}
          </Button>
          <Button
            onClick={() => setIsModalOpen(false)}
            sx={{ mb: 3 }}
            variant="secondary"
            trackName="mobile-number-update"
          >
            {intl.formatMessage({
              id: 'RegistrationMobileNumberPage.Modal.Update',
              defaultMessage: 'Update',
            })}
          </Button>
        </div>
      </Modal>
      <TitleLarge sx={{ mb: [3, 5] }} data-cy="mobile-number-title">
        {intl.formatMessage({
          id: 'Registration.MobileNumberPage.Title',
          defaultMessage: "What's your mobile number?",
        })}
      </TitleLarge>
      <Paragraph sx={{ mb: [3, 5] }}>
        {intl.formatMessage({
          id: 'Registration.MobileNumberPage.Subtitle',
          defaultMessage: 'We’ll use this to keep your account secure.',
        })}
      </Paragraph>
      <KhInputMobileNumber
        {...register('mobileNumber')}
        label={intl.formatMessage({
          id: 'Registration.MobileNumberPage.MobileNumberLabel',
          defaultMessage: 'Mobile number',
        })}
        trackName="mobile-number"
        error={!!errors.mobileNumber}
        helperText={useValidationMsg(errors.mobileNumber?.message)}
        sx={{ mb: 3 }}
        data-cy="mobile-input"
      ></KhInputMobileNumber>
      <div
        sx={{
          width: '100%',
          mb: [3, 4],
          pb: [4, 5],
          px: 3,
          borderRadius: theme.borderRadius.small,
          backgroundColor: theme.colors.greyXLight,
        }}
      >
        <Flex
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pb: 0,
          }}
        >
          <Paragraph sx={{ fontWeight: 700 }}>
            {intl.formatMessage({
              id: 'Registration.MobileNumberPage.ReferralCodeTitle',
              defaultMessage: 'Have a referral code?',
            })}
          </Paragraph>
          <img
            alt={`${intl.formatMessage({
              id: 'Registration.MobileNumberPage.ReferralAltTag',
              defaultMessage: 'Refer code',
            })}`}
            sx={{
              width: '120px',
              height: '65px',
            }}
            src={referral}
          />
        </Flex>
        <RegistrationReferralCodeInput
          onValidChanged={setReferralCodeValid}
          onReferralRequestUpdated={onReferralRequestUpdated}
          referralCodePreSet={referralCodePreSet}
        />
      </div>

      <Button
        onClick={nextButtonClicked}
        trackName="move-mobile-number"
        disabled={!isValid || !referralCodeValid || createUserLoading}
        loading={createUserLoading}
        sx={{ mb: 4 }}
        data-cy="mobile-cta-button"
      >
        {intl.formatMessage({
          id: 'Registration.MobileNumberPage.CTA',
          defaultMessage: 'Next',
        })}
      </Button>

      {createUserError && (
        <ErrorParagraph sx={{ mb: 4, textAlign: 'center' }}>
          {createUserError}
        </ErrorParagraph>
      )}
      <SecurityAssurance />
    </>
  );
};
