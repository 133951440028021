/** @jsxImportSource theme-ui */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { TextInput, TextInputProps } from '../../components/forms';
import {
  isPasswordRequirementsMet,
  validatePassword,
  validatePasswordComplexity,
} from '../../utility/validatePassword';
import { ShowHideButton } from './ButtonShowHide';

export type InputPasswordProps = TextInputProps & {
  label: string;
  onValueChange?: ({ value, valid }: { value: string; valid: boolean }) => void;
  showHelperText?: boolean;
};

export const InputPassword = ({
  label,
  onValueChange,
  showHelperText,
  ...props
}: InputPasswordProps) => {
  const [input, setInput] = useState<string>('');
  const [passVisible, setPassVisible] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [touched, setTouched] = useState(false);
  const [valid, setValid] = useState<boolean>(false);

  const intl = useIntl();

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valid = isPasswordRequirementsMet(validatePasswordComplexity(value));

    setValid(valid);

    if (onValueChange) {
      onValueChange({ valid, value });
    }

    validatePassword(value, intl).then((result) => {
      setHelperText(result.message);
      setInput(value);
    });
  };

  return (
    <TextInput
      variant="outlined"
      name="Password Input"
      type={passVisible ? 'text' : 'password'}
      value={input}
      helperText={!!showHelperText && helperText}
      label={label}
      error={!valid && input.length !== 0 && touched}
      onBlur={() => setTouched(true)}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {valid && <CheckCircleIcon sx={{ color: 'success', mr: 2 }} />}
            <ShowHideButton
              onClick={() => setPassVisible(!passVisible)}
              visible={passVisible}
            />
          </InputAdornment>
        ),
      }}
      sx={{
        '.MuiFormHelperText-root': {
          mx: 0,
        },
      }}
      {...props}
      id="new-password"
      autoComplete="new-password"
    />
  );
};
