import { FocusEvent, KeyboardEvent, forwardRef } from 'react';

import { TextField, TextFieldProps } from '@material-ui/core';
import { trackInput } from 'analytics/analytics';

export const ENTER_KEY = 'Enter';

export type KhInputTextProps = TextFieldProps & {
  trackName?: string;
  onEnterPressed?: () => void;
};

export const KhInputTextTw = forwardRef<any, KhInputTextProps>(
  ({ trackName, onFocus, onEnterPressed, ...props }, ref) => {
    const focus = (event: FocusEvent<HTMLInputElement>) => {
      if (trackName) {
        trackInput({ name: trackName, type: 'Focused' });
      }

      if (onFocus) {
        onFocus(event);
      }
    };

    const keyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (onEnterPressed && event.key === ENTER_KEY) {
        onEnterPressed();
      }
    };

    return (
      <TextField
        variant="outlined"
        type="text"
        fullWidth
        ref={ref}
        onFocus={focus}
        onKeyDown={keyDown}
        {...props}
      />
    );
  },
);
