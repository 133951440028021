import React from 'react';
import { useIntl } from 'react-intl';

import { IconButton, IconButtonProps, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { KDSIcons } from 'assets/images/kds_icons';
import { theme } from 'theme';
import { handleCopyValue } from 'utility/handleCopyValue';

export const CopyButton = ({
  value,
  trackingLabel,
  ...props
}: IconButtonProps & {
  value: string;
  trackingLabel: string;
}) => {
  const intl = useIntl();
  const [toastOpen, setToastOpen] = React.useState(false);
  return (
    <>
      <IconButton
        onClick={() => {
          handleCopyValue(value, trackingLabel);
          setToastOpen(true);
        }}
        {...props}
      >
        <KDSIcons.Icons.Copy color={theme.colors.primary} />
      </IconButton>
      <Snackbar
        autoHideDuration={2000}
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success">
          {intl.formatMessage({
            id: 'CopyButton.Copied',
            defaultMessage: 'Copied to clipboard!',
          })}
        </Alert>
      </Snackbar>
    </>
  );
};
