import { rootApi } from './rootApi';

export enum RewardTypes {
  Cash = 'cash',
  Tiers = 'tier',
  CreditBuilding = 'credit_building',
}

export type ReferralReward = {
  id: string;
  name: string;
  description: string;
  value: string;
  discounted_value: string;
  free_months: number;
  success_copy: string;
  reward_header: string;
  reward_sub_header: string;
  reward_type: RewardTypes;
  new: boolean;
  auto_redeem?: boolean;
  limited_time_offer?: boolean;
  cta_button: null | {
    cta_copy: string;
    cta_link: string;
  };
};

export type ReferralRewardsResponse = {
  rewards: Array<ReferralReward>;
  rewards_header: string;
  rewards_sub_header: string;
  share_message_text: string;
  referral_url: string;
  referral_code: string;
  gifts: number;
  claimed_rewards: Array<string>;
};

export const referralApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getReferralsRewards: build.query<ReferralRewardsResponse, void>({
      query: () => ({ url: `/1.0/users/referrals/rewards` }),
      providesTags: ['User'],
    }),
    claimReward: build.mutation<ReferralReward, string>({
      query: (rewardId) => ({
        url: `/1.0/users/referrals/rewards/${rewardId}/claim`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const { useGetReferralsRewardsQuery, useClaimRewardMutation } =
  referralApi;
