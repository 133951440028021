import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Box, BoxProps } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useLocation, useNavigate } from '@reach/router';
import { trackInput } from 'analytics/analytics';

import { desktop, theme } from '../../theme';

const SidebarChip = ({
  chipSx,
  children,
}: {
  chipSx?: BoxProps['sx'];
  children: ReactNode;
}) => (
  <Box
    sx={{
      background: theme.colors.primary,
      borderRadius: '10%',
      color: 'greyXLight',
      fontWeight: 'bold',
      fontSize: '16px',
      px: 2,
      ml: 2,
      ...chipSx,
    }}
  >
    {children}
  </Box>
);

export const PathItem = ({ page }) => {
  const Icon = page.icon;

  const location = useLocation();
  const intl = useIntl();

  const navigate = useNavigate();

  const clickhandler = () => {
    trackInput({
      type: 'Clicked',
      name: page.name,
      details: { to: page.path ? page.path : '' },
    });

    page.path && navigate(page.path);
    page.onClick && page.onClick();
  };

  return (
    <>
      {page.component}
      <ListItem
        button
        onClick={() => clickhandler()}
        key={page.name}
        data-cy={page.path}
        sx={{
          width: '100%',
          borderRight:
            location.pathname === page.path ? `5px solid #00597B` : 'none',
          borderBottom: `1px solid ${theme.colors.greyLight}`,
          [desktop]: {
            display: page.path === '/settings' ? 'none' : 'flex',
            borderBottom: 'none',
            '&:hover': {
              backgroundColor: `${theme.colors.primaryDisabled}`,
            },
          },
        }}
      >
        <ListItemIcon>
          <Icon sx={{ color: '#373e4d' }} />
        </ListItemIcon>
        <ListItemText
          primary={page.name}
          primaryTypographyProps={{
            style: {
              fontWeight: location.pathname === page.path ? 800 : 400,
            },
          }}
        />
        {page.new && (
          <SidebarChip>
            {intl.formatMessage({
              id: 'Global.Label.New',
              defaultMessage: 'New',
            })}
          </SidebarChip>
        )}
        {page.chipMessage && (
          <SidebarChip chipSx={{ fontSize: '12px', lineHeight: '18px', py: 1 }}>
            {page.chipMessage}
          </SidebarChip>
        )}
      </ListItem>
    </>
  );
};
