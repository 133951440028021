import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { selectAuthIsAuthenticated } from '../../../auth/store/selectors';
import { selectPasswordResetUserRef } from '../../../passwordReset/store/selectors';
import { selectUserRefID } from '../../../profile/store/selectors';

export const selectLaunchDarklyState = (state: RootState) => state.launchDarkly;

export const selectLaunchDarklyAuthenticated = createSelector(
  selectLaunchDarklyState,
  (state) => state && state.authenticated,
);

export const selectLaunchDarklyAuthenticatedHash = createSelector(
  selectLaunchDarklyAuthenticated,
  (state) => state && state.hash,
);

export const selectLaunchDarklyAuthenticatedLoadable = createSelector(
  selectLaunchDarklyAuthenticated,
  (state) => state && state.loadable,
);

export const selectLaunchDarklyAuthenticatedIsLoading = createSelector(
  selectLaunchDarklyAuthenticatedLoadable,
  (state) => state && state.loading,
);
export const selectLaunchDarklyAuthenticatedIsSuccess = createSelector(
  selectLaunchDarklyAuthenticatedLoadable,
  (state) => state && state.success,
);

export const selectLaunchDarklyAnonymousState = createSelector(
  selectLaunchDarklyState,
  (state) => state && state.anonymous,
);

export const selectLaunchDarklyAnonymousHash = createSelector(
  selectLaunchDarklyAnonymousState,
  (state) => state && state.hash,
);

export const selectLaunchDarklyAnonymousId = createSelector(
  selectLaunchDarklyAnonymousState,
  (state) => state && state.id,
);

export const selectLaunchDarklyAnonymousLoadable = createSelector(
  selectLaunchDarklyAnonymousState,
  (state) => state && state.loadable,
);

export const selectLaunchDarklyAnonymousIsLoading = createSelector(
  selectLaunchDarklyAnonymousLoadable,
  (loadable) => loadable && loadable.loading,
);

export const selectLaunchDarklyAnonymousIsSuccess = createSelector(
  selectLaunchDarklyAnonymousLoadable,
  (loadable) => loadable && loadable.success,
);

export const selectLaunchDarklyFlagsState = createSelector(
  selectLaunchDarklyState,
  (state) => state && state.flags,
);

export const selectLaunchDarklyFlagsLoadable = createSelector(
  selectLaunchDarklyFlagsState,
  (state) => state?.loadable,
);
export const selectLaunchDarklyFlagsIsLoading = createSelector(
  selectLaunchDarklyFlagsState,
  (state) => state?.loadable?.loading,
);

export const selectLaunchDarklyFlagsIsSuccess = createSelector(
  selectLaunchDarklyFlagsLoadable,
  (loadable) => loadable?.success,
);

export const selectLaunchDarklyFlagsIsError = createSelector(
  selectLaunchDarklyFlagsLoadable,
  (loadable) => loadable?.error,
);

export const selectLaunchDarklyUserRef = createSelector(
  selectUserRefID,
  selectPasswordResetUserRef,
  (profileUserRefID, passwordResetUserRef) =>
    profileUserRefID || passwordResetUserRef,
);

export const selectLaunchDarklyIsAuthenticated = createSelector(
  selectAuthIsAuthenticated,
  selectPasswordResetUserRef,
  (isAuthenticated, passwordResetUserRef) =>
    isAuthenticated || !!passwordResetUserRef,
);
