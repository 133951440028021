import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { track } from 'analytics/analytics';
import { IBenefitGroup, ICurrentBenefit, IPlan } from 'apis/tiers';
import allTiersImg from 'assets/images/tiers/all-tiers.png';
import { Button, LocaleContext } from 'components';
import { StatelessDrawerModal } from 'components/DrawerModal';
import { LoadingModal } from 'components/Loading/Modal';
import { theme } from 'theme';
import { useChangeTier } from 'tiers/hooks/useChangeTier';
import { useTiersData } from 'tiers/hooks/useTiersData';

import { ErrorAlert } from '../ErrorAlert';

type Props = {
  oldTier: ICurrentBenefit;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  plan: IPlan;
  newTier: IBenefitGroup;
};

export const DowngradeConfirmationModal = ({
  oldTier,
  onClose,
  onConfirm,
  open,
  plan,
  newTier,
}: Props) => {
  const intl = useIntl();
  const muiTheme = useTheme();
  const isAfterMobile = useMediaQuery(muiTheme.breakpoints.up('sm'));
  const { getTierName } = useTiersData();
  const { intlFormatMoney } = useContext(LocaleContext);

  const oldTierName = oldTier.benefit_group
    ? getTierName(oldTier.benefit_group?.name)
    : '';
  const newTierName = getTierName(newTier.name);
  const newTierPlan = newTier.plans?.find(
    (plan) => plan.key === oldTier.frequency,
  );

  const {
    handleSubscription,
    activeTierSuccess,
    activeTierError,
    isSubscriptionLoading,
    subscriptionFailed,
  } = useChangeTier({
    selectedTier: newTier,
    selectedPlan: plan,
    onConfirm,
    downgradeData: {
      newTierName,
      newTierCost: intlFormatMoney(newTierPlan?.amount_cents ?? '$0').format(),
      downgradeDate: oldTier.next_payment_date
        ? intl.formatDate(oldTier.next_payment_date, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        : '',
    },
  });

  return (
    <>
      <StatelessDrawerModal
        modalSx={{
          '& .MuiPaper-root': { width: isAfterMobile ? 500 : 'auto', p: '8px' },
        }}
        isOpen={open}
        closeModal={() => {
          track({
            event: 'Clicked',
            properties: { name: 'tiers-downgrade-close' },
          });
          onClose();
        }}
        modalProps={{ maxWidth: false }}
        title={intl.formatMessage({
          id: 'TiersDowngrade.Modal.Title',
          defaultMessage: 'Confirm your benefits downgrade',
        })}
        content={() => (
          <>
            {(subscriptionFailed || activeTierError) && (
              <Box sx={{ mb: 3 }}>
                <ErrorAlert />
              </Box>
            )}
            <Typography
              sx={{ fontWeight: 500, color: theme.colors.text, mb: 4 }}
            >
              {intl.formatMessage(
                {
                  id: 'TiersDowngrade.Modal.Subtitle',
                  defaultMessage:
                    'Switching from {oldTier} to {newTier} means your plan benefits will change.',
                },
                { oldTier: oldTierName, newTier: newTierName },
              )}
            </Typography>
            <Box
              display="inline-block"
              sx={{
                overflow: 'hidden',
                width: '100%',
                height: '132px',
                borderRadius: '8px',
                mb: '32px',
              }}
            >
              <img
                alt="Tier Downgrade"
                src={allTiersImg}
                width="100%"
                height="132px"
                style={{
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Button
              onClick={onClose}
              disabled={!activeTierSuccess}
              sx={{ bg: theme.colors.blueNavy, fontWeight: 900, mb: 2 }}
              trackName="tiers-downgrade-keep"
            >
              {intl.formatMessage({
                id: 'TiersDowngrade.Modal.KeepBenefits',
                defaultMessage: 'Keep my benefits',
              })}
            </Button>
            <Button
              variant="clear"
              disabled={!activeTierSuccess}
              onClick={handleSubscription}
              sx={{ color: theme.colors.blueNavy, fontWeight: 900 }}
              data-cy="tier-management-confirm-downgrade"
              trackName="tiers-downgrade-accept"
            >
              {intl.formatMessage(
                {
                  id: 'TiersDowngrade.Modal.Downgrade',
                  defaultMessage: 'Downgrade to {tier}',
                },
                { tier: newTierName },
              )}
            </Button>
          </>
        )}
      />
      {isSubscriptionLoading && <LoadingModal />}
    </>
  );
};
