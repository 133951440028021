import { useIntl } from 'react-intl';

import ButtonUnstyledTw from 'components/ButtonUnstyledTw';

export const ShowHideButtonTw = ({
  visible,
  ...props
}: { visible: boolean } & React.ComponentPropsWithRef<'button'>) => {
  const intl = useIntl();
  return (
    <ButtonUnstyledTw
      tabIndex={-1}
      className="text-primary-300 font-bold legacy:font-legacyBody light:font-body text-base min-w-[50px] p-0.5 text-center"
      {...props}
    >
      {visible
        ? intl.formatMessage({
            id: 'ButtonShowHide.Hide',
            defaultMessage: 'Hide',
          })
        : intl.formatMessage({
            id: 'ButtonShowHide.Show',
            defaultMessage: 'Show',
          })}
    </ButtonUnstyledTw>
  );
};
