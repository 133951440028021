import { useIntl } from 'react-intl';

import { Box, Chip, Typography } from '@material-ui/core';
import { ICurrentBenefit } from 'apis/tiers';
import { theme } from 'theme';
import { useTiersData } from 'tiers/hooks/useTiersData';
import { isOnFreeTrial, tierIsCancelled } from 'tiers/utils/helpers';

const FreeChip = ({ daysRemaining }: { daysRemaining: string }) => {
  const intl = useIntl();
  return (
    <Chip
      sx={{
        bg: theme.colors.paleNavy,
        color: theme.colors.blueDark,
        fontWeight: 700,
        fontSize: '12px',
        height: '24px',
        px: '8px',
        '& .MuiChip-label': { px: 0 },
      }}
      data-cy="my-plan-trial-chip"
      label={intl.formatMessage(
        {
          defaultMessage: '{daysRemaining} days free',
          id: 'MyPlan.TrialDays',
        },
        { daysRemaining },
      )}
    />
  );
};

const CancellationChip = () => {
  const intl = useIntl();
  return (
    <Chip
      sx={{
        bg: theme.colors.alertLight,
        color: theme.colors.alert,
        fontWeight: 700,
        fontSize: '12px',
        height: '24px',
        px: '8px',
        '& .MuiChip-label': { px: 0 },
      }}
      data-cy="my-plan-cancellation-chip"
      label={intl.formatMessage({
        defaultMessage: 'Pending cancellation',
        id: 'MyPlan.PendingCancellation',
      })}
    />
  );
};

const useGetDateLabel = ({
  isCancelling,
  downgradeTierName,
}: {
  isCancelling: boolean;
  downgradeTierName: string;
}) => {
  const intl = useIntl();
  if (isCancelling)
    return intl.formatMessage({ id: 'MyPlan.Ends', defaultMessage: 'Ending:' });

  if (downgradeTierName)
    return intl.formatMessage(
      {
        id: 'MyPlan.StartedNewTier',
        defaultMessage: '{tierName} starting:',
      },
      { tierName: downgradeTierName },
    );

  return intl.formatMessage({ id: 'MyPlan.Renews', defaultMessage: 'Renews:' });
};

const RenewalDate = ({
  date,
  downgradeTierName,
  isCancelling,
  textColor,
}: {
  date: Date;
  downgradeTierName: string;
  isCancelling: boolean;
  textColor: string;
}) => {
  const intl = useIntl();
  const dateLabel = useGetDateLabel({ isCancelling, downgradeTierName });
  return (
    <Typography
      data-cy="my-plan-renewal-label"
      sx={{
        fontWeight: 600,
        fontSize: '12px',
        color: textColor,
        '& > b': { fontWeight: 900 },
      }}
    >
      <b>{dateLabel}</b>{' '}
      {intl.formatDate(date, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}
    </Typography>
  );
};

type Props = {
  currentTier: ICurrentBenefit;
  textColor: string;
};

export const TierRenewal = ({ currentTier, textColor }: Props) => {
  const { getTierName } = useTiersData();
  const downgradeTierName = currentTier.delayed_downgrade?.benefit_group?.name;
  const localizedDowngradeTierName = downgradeTierName
    ? getTierName(downgradeTierName)
    : '';
  const renewalDate = currentTier.next_payment_date;
  const onFreeTrial = isOnFreeTrial(currentTier);
  const pendingCancellation = tierIsCancelled(currentTier);

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{ gap: '8px', alignItems: 'center' }}
    >
      {onFreeTrial && !pendingCancellation && (
        <FreeChip daysRemaining={currentTier.trial_days_remaining} />
      )}
      {pendingCancellation && <CancellationChip />}
      {renewalDate && (
        <RenewalDate
          date={renewalDate}
          downgradeTierName={localizedDowngradeTierName}
          textColor={textColor}
          isCancelling={pendingCancellation}
        />
      )}
    </Box>
  );
};
