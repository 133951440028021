/** @jsxImportSource theme-ui */
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { TextMaskPostalCode } from 'components/forms/TextMaskPostalCode';
import { useValidationMsg } from 'components/forms/useValidationMsg';
import {
  KhAutocompleteSelect,
  SelectOption,
} from 'components/inputs/KhAutocompleteSelect';
import { KhInputText } from 'components/inputs/KhInputText';
import { provinceInfo } from 'profile/data/provinces';
import { AddressFormSchema } from 'registration/zodForms/zodFormRegistrationAddress';
import { useTrackElementViewedOnce } from 'utility/analyticsHooks';

export const RegistrationAddressEntry = ({
  form,
  submit,
}: {
  form: UseFormReturn<AddressFormSchema, any>;
  submit: SubmitHandler<AddressFormSchema>;
}) => {
  const intl = useIntl();

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    resetField,
    trigger,
  } = form;

  const provinceOptions = Object.keys(provinceInfo).map((key) => ({
    label: intl.formatMessage(provinceInfo[key].localizedName),
    value: provinceInfo[key].code,
  }));

  if (!getValues('province')) {
    setValue('province', provinceOptions[0].value);
  }

  useTrackElementViewedOnce({
    element: errors.addressLine1,
    name: 'line-1-input-error',
  });
  useTrackElementViewedOnce({
    element: errors.addressLine2,
    name: 'line-2-input-error',
  });
  useTrackElementViewedOnce({ element: errors.city, name: 'city-input-error' });
  useTrackElementViewedOnce({
    element: errors.postalCode,
    name: 'postal-code-error',
  });

  return (
    <>
      <KhInputText
        {...register('addressLine1')}
        sx={{ mb: 4 }}
        label={intl.formatMessage({
          id: 'Registration.Address.AddressLine1',
          defaultMessage: 'Address line 1',
        })}
        error={!!errors.addressLine1}
        helperText={useValidationMsg(errors.addressLine1?.message)}
        onEnterPressed={() => form.setFocus('addressLine2')}
        data-cy="registration-address-entry-line1"
      />

      <KhInputText
        {...register('addressLine2')}
        sx={{ mb: 4 }}
        label={intl.formatMessage({
          id: 'Registration.Address.AddressLine2',
          defaultMessage: 'Unit/ Suite/ Apt/ PO Box (Optional)',
        })}
        helperText={useValidationMsg(errors.addressLine2?.message)}
        onEnterPressed={() => form.setFocus('city')}
        data-cy="registration-address-entry-line2"
      />
      <KhInputText
        {...register('city')}
        sx={{ mb: 4 }}
        label={intl.formatMessage({
          id: 'Registration.Address.City',
          defaultMessage: 'City',
        })}
        error={!!errors.city}
        helperText={useValidationMsg(errors.city?.message)}
        onEnterPressed={() => form.setFocus('province')}
        data-cy="registration-address-entry-city"
      />
      <div sx={{ display: ['block', 'flex'], mb: 4 }}>
        <KhAutocompleteSelect
          {...register('province')}
          trackName="province"
          disableClearable={true}
          label={intl.formatMessage({
            id: 'Registration.Address.Province',
            defaultMessage: 'Province',
          })}
          options={provinceOptions}
          error={useValidationMsg(errors.province?.message)}
          sx={{ mr: [0, 3], mb: [4, 0] }}
          value={getValues('province') || provinceOptions[0].value}
          onChange={async (_, value: SelectOption) => {
            if (value) {
              setValue('province', value.value);
              await trigger('province');
              form.setFocus('postalCode');
            } else {
              resetField('province');
            }
          }}
          onEnterPressed={() => form.setFocus('postalCode')}
          data-cy="registration-address-entry-province"
        ></KhAutocompleteSelect>
        <KhInputText
          {...register('postalCode')}
          label={intl.formatMessage({
            id: 'Registration.Address.PostalCode',
            defaultMessage: 'Postal code',
          })}
          error={!!errors.postalCode}
          helperText={useValidationMsg(errors.postalCode?.message)}
          InputProps={{ inputComponent: TextMaskPostalCode }}
          data-cy="registration-address-entry-postal-code"
          onEnterPressed={() => form.handleSubmit(submit)()}
        />
      </div>
    </>
  );
};
