import { v4 as uuidv4 } from 'uuid';

import { getMobileOperatingSystem } from '../utility/getMobileOperatingSystem';
import { rakuntenConversion } from './rakutenConversionScript';

interface RakutenCoversionTagData {
  affiliateConfig: {
    ranMID: string;
    discountType: string;
    includeStatus: string;
    taxRate: number;
  };
  orderid: string;
  currency: string;
  customerStatus: string;
  conversionType: string;
  customerID: string;
  discountCode: string;
  discountAmount: number;
  taxAmount: number;
  lineitems: [
    {
      quantity: number;
      unitPrice: number;
      unitPriceLessTax: number;
      SKU: string;
      productName: string;
    },
  ];
}

const isMobile = getMobileOperatingSystem() !== 'unknown';
const productName = isMobile ? 'mobile_registration' : 'desktop_registration';
const rmTransDefault: RakutenCoversionTagData = {
  affiliateConfig: {
    ranMID: '46490',
    discountType: 'item',
    includeStatus: 'false',
    taxRate: 5,
  },
  orderid: getRakutenOrderID(),
  currency: 'CAD',
  customerStatus: 'new',
  conversionType: 'lead',
  customerID: '',
  discountCode: '',
  discountAmount: 0,
  taxAmount: 0,
  lineitems: [
    {
      quantity: 1,
      unitPrice: 1,
      unitPriceLessTax: 1,
      SKU: '',
      productName: productName,
    },
  ],
};

export function rakutenGlobaltag() {
  if (typeof window !== 'undefined' && import.meta.env.VITE_ENABLE_RAKUTEN) {
    (function (url) {
      /*Tracking Bootstrap Set Up DataLayer objects/properties here*/
      if (!window['DataLayer']) {
        window['DataLayer'] = {};
      }
      if (!window['DataLayer'].events) {
        window['DataLayer'].events = {};
      }
      window['DataLayer'].events.SPIVersion =
        window['DataLayer'].events.SPIVersion || '3.4.1';
      window['DataLayer'].events.SiteSection = '1';

      var loc,
        ct = document.createElement('script');
      ct.type = 'text/javascript';
      ct.async = true;
      ct.src = url;
      loc = document.getElementsByTagName('script')[0];
      loc.parentNode.insertBefore(ct, loc);
    })(document.location.protocol + '//tag.rmp.rakuten.com/122943.ct.js');
    // END Rakuten Marketing Tracking
  }
}

export function getRakutenOrderID(): string {
  let rakutenOrderId = localStorage.getItem('rakutenOrderID');
  if (!rakutenOrderId) {
    rakutenOrderId = uuidv4();
    localStorage.setItem('rakutenOrderID', rakutenOrderId);
  }

  return rakutenOrderId;
}

export function commissionUserRegistered(userRef: string) {
  if (import.meta.env.VITE_ENABLE_RAKUTEN) {
    const rm_trans: RakutenCoversionTagData = {
      ...rmTransDefault,
      orderid: userRef,
      customerID: userRef,
      lineitems: [
        {
          quantity: 1,
          unitPrice: 1,
          unitPriceLessTax: 1,
          SKU: isMobile
            ? 'kohoMobileWeb_UserRegistered'
            : 'kohoDesktopWeb_UserRegistered',
          productName: productName,
        },
      ],
    };
    rakuntenConversion(window, document, rm_trans);
  }
}
