import { useState } from 'react';

import { segmentAnalytics, track } from 'analytics/analytics';

export const useTrackElementViewedOnce = ({
  element,
  name,
}: {
  element: any;
  name: string;
}) => {
  const [seen, setSeen] = useState(false);

  if (!!element && !seen) {
    setSeen(true);

    track({ event: 'Viewed', properties: { name } });
  }
};

// deprecated
/**
 * @deprecated using this hook creates a new event type for each page view.
 * To reduce the number of event types, use useTrackPageViewedOnce instead.
 */
export const useTrackPageViewedOnceDeprecated = ({
  name,
  overrideUrl,
}: {
  name: string;
  overrideUrl?: string;
}) => {
  const [seen, setSeen] = useState(false);

  if (!seen) {
    setSeen(true);

    if (overrideUrl) {
      const currentUrl = new URL(window.location.href);
      const newUrl = new URL(overrideUrl, currentUrl.origin);

      // Preserve the query string and hash fragment
      newUrl.search = currentUrl.search;
      newUrl.hash = currentUrl.hash;

      window.history.pushState({}, '', newUrl.toString());
    }

    segmentAnalytics.page(name);
  }
};

export const useTrackPageViewedOnce = (
  data: {
    name?: string;
    path?: string;
    url?: string;
  } = {},
) => {
  const [seen, setSeen] = useState(false);

  if (!seen) {
    setSeen(true);

    segmentAnalytics.page(data);
  }
};
