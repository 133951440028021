/** @jsxImportSource theme-ui */
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation, useNavigate } from '@reach/router';
import { useGetAutodepositAliasesQuery } from 'apis/autodeposit';
import { ButtonTw } from 'components/ButtonTw';
import { Paragraph, TitleLarge, TitleMedium } from 'components/TypographyTw';
import { ContainerTw } from 'layout/ContainerTw';
import { TemplateTw } from 'layout/TemplateTw';

import { selectAccountPersonalAccountGroupId } from '../../accounts/store/selectors';
import { ReactComponent as TadaIcon } from '../../assets/images/transactions/tada.svg';
import { usePagePermissions } from '../../auth/hooks/usePagePermissions';
import { selectBalanceUsable } from '../../balance/store/selectors';
import { balanceActions } from '../../balance/store/slice';
import { Modal } from '../../components';
import { LocaleContext } from '../../components/I18NContextProvider';
import LoadingCentered from '../../components/Loading/Centered';
import { TransferAmount } from '../../components/TransferAmount';
import { TransferReview } from '../../components/TransferReview';
import { RecipientForm } from '../../components/forms/RecipientForm';
import { DeviceVerificationModal as VerificationModal } from '../../deviceVerification/components';
import { Money } from '../../models/Money';
import { Recipient } from '../../models/Recipient';
import { RecipientFormData } from '../../models/RecipientFormData';
import { TransferFormData } from '../../models/TransferFormData';
import { Loadable, isLoadableDefault } from '../../utility/loadable';
import { Recipients } from '../components/Recipients';
import {
  selectAddRecipientLoadable,
  selectRecipientsData,
  selectSendETransferLoadable,
  selectUpdateRecipientLoadable,
} from '../store/selectors';
import {
  DEVICE_VERIFICATION_ERROR,
  E_TRANSFER_FUNDS_ERROR,
  transferActions,
} from '../store/slice';

export const SendMoneyDetailsPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const context = useContext(LocaleContext);
  const [submit, setSubmit] = useState(false);
  const location = useLocation();
  const pagePermissions = usePagePermissions();
  const navigate = useNavigate();

  const state = location.state as {
    amount: string;
    groupIdentifier?: string;
  };

  const { amount: defaultAmount = '' } = state;

  // --- loading, success, and error ---
  const sendETransferLoadable: Loadable = useSelector(
      selectSendETransferLoadable,
    ),
    addRecipientLoadable: Loadable = useSelector(selectAddRecipientLoadable),
    updateRecipientLoadable: Loadable = useSelector(
      selectUpdateRecipientLoadable,
    );

  const [showSuccess, setShowSuccess] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDeviceVerification, setShowDeviceVerification] = useState(false);
  const [showFundsError, setShowFundsError] = useState(false);

  const [showAutoDepositBlocker, setShowAutodepositBlocker] = useState(false);
  const { data } = useGetAutodepositAliasesQuery();

  useEffect(() => {
    if (
      isLoadableDefault(sendETransferLoadable) &&
      isLoadableDefault(addRecipientLoadable) &&
      isLoadableDefault(updateRecipientLoadable)
    ) {
      return;
    }
    setShowSuccess(sendETransferLoadable.success);
    setShowLoading(
      sendETransferLoadable.loading ||
        addRecipientLoadable.loading ||
        updateRecipientLoadable.loading,
    );

    const isError =
      !!sendETransferLoadable.error &&
      sendETransferLoadable.error !== DEVICE_VERIFICATION_ERROR;
    setShowError(
      isError ||
        !!addRecipientLoadable.error ||
        !!updateRecipientLoadable.error,
    );

    setShowDeviceVerification(
      !!sendETransferLoadable.error &&
        sendETransferLoadable.error === DEVICE_VERIFICATION_ERROR,
    );

    setShowFundsError(
      !!sendETransferLoadable.error &&
        sendETransferLoadable.error === E_TRANSFER_FUNDS_ERROR,
    );

    if (sendETransferLoadable.success || sendETransferLoadable.error) {
      dispatch(transferActions.resetSendETransfer());
    }
  }, [
    sendETransferLoadable,
    addRecipientLoadable,
    updateRecipientLoadable,
    dispatch,
  ]);

  // --- form data and submission ---
  const [formData, setFormData] = useState<TransferFormData>({
    amount: defaultAmount,
    recipient: {
      name: '',
      email: '',
      secretQuestion: '',
      secretAnswer: '',
    },
  });

  useEffect(() => {
    dispatch(transferActions.resetLoadable());
  }, [dispatch]);

  useEffect(() => {
    if (!submit) return;
    setSubmit(false);

    dispatch(transferActions.clickSendETransfer({ formData }));
  }, [formData, submit, dispatch]);

  // --- usableBalance and fee ---
  const usableBalance = useSelector(selectBalanceUsable);
  const personalAccountIdentifier = useSelector(
    selectAccountPersonalAccountGroupId,
  );

  useEffect(() => {
    dispatch(transferActions.getRecipientsRequest());
  }, [dispatch, context.apiLanguageHeader]);

  useEffect(() => {
    if (personalAccountIdentifier) {
      dispatch(balanceActions.getBalanceRequest());
    }
  }, [dispatch, personalAccountIdentifier, context.apiLanguageHeader]);

  // TODO - fetch fee value from backend
  let fee: Money = context.intlFormatMoney('$0.00');

  // --- Recipients section ---
  const recipients: Recipient[] = useSelector(selectRecipientsData)?.map(
    (data) => new Recipient(data),
  );
  const onClickRecipient = (recipient: Recipient) => {
    const { fullName, address, securityQuestion } = recipient;

    const activeAutodeposit = data?.find(
      (autodeposit) =>
        autodeposit.email === address && autodeposit.status === 'ACTIVE',
    );

    if (activeAutodeposit) {
      setShowAutodepositBlocker(true);

      return;
    }

    setFormData({
      ...formData,
      recipient: {
        name: fullName,
        email: address,
        secretQuestion: securityQuestion,
        secretAnswer: '',
      },
    });
    setStep(3);
  };
  const onClickAddRecipient = () => {
    setStep(2);
  };
  const RecipientsSection = (
    <Recipients
      filter={(recipient) => recipient.supportsETransfer()}
      list={recipients}
      onClickRecipient={onClickRecipient}
      onClickAddRecipient={onClickAddRecipient}
    />
  );

  // --- Amount section ---
  const onAmountSectionContinue = (amount: string) => {
    setFormData({ ...formData, amount });
    setStep(recipients && recipients?.length > 0 ? 1 : 2);
  };
  const min = fee.add(1); // minimum should be above fee amount
  const AmountSection = usableBalance ? (
    <TransferAmount
      type="out"
      min={min}
      feeAmount={fee.format()}
      max={context.intlFormatMoney(usableBalance)}
      onContinue={onAmountSectionContinue}
      initial={formData.amount}
      showTotalAvailable={true}
    />
  ) : (
    <LoadingCentered />
  );

  // --- Recipient form section ---
  const onRecipientFormSectionContinue = (recipientData: RecipientFormData) => {
    const activeAutodeposit = data?.find(
      (autodeposit) =>
        autodeposit.email === recipientData?.email &&
        autodeposit.status === 'ACTIVE',
    );

    if (activeAutodeposit) {
      setShowAutodepositBlocker(true);

      return;
    }

    setFormData({ ...formData, recipient: { ...recipientData } });
    setStep(3);
  };
  const RecipientFormSection = (
    <>
      <TitleLarge className="mt-0 mb-6">
        {intl.formatMessage({
          id: 'SaveMoneyDetailsPage.Form.Heading',
          defaultMessage: 'Add a recipient to send money',
        })}
      </TitleLarge>

      <RecipientForm onContinue={onRecipientFormSectionContinue} />
    </>
  );

  // --- Review section ---
  const onReviewSectionContinue = (updatedSecret: {
    question: string;
    answer: string;
  }) => {
    setFormData({
      ...formData,
      recipient: {
        ...formData.recipient,
        secretQuestion: updatedSecret.question,
        secretAnswer: updatedSecret.answer,
      },
    });

    setSubmit(true);
  };

  const ReviewSection = (
    <TransferReview
      onContinue={onReviewSectionContinue}
      type="etransfer"
      formData={formData}
      fee={fee}
      busy={showLoading}
    />
  );

  const handleCloseSuccess = () => {
    navigate('/transactions');
    setShowSuccess(false);

    dispatch(transferActions.resetLoadable());
  };

  const SuccessModal = (
    <Modal
      classes={{ paperWidthXs: 'max-w-[275px]' }}
      closeButton={false}
      onClose={handleCloseSuccess}
      open={showSuccess}
    >
      <div className="text-center pt-2">
        <TadaIcon />
        <TitleMedium className="mt-0 mb-2">
          {intl.formatMessage({
            id: 'SendMoneyDetailsPage.Success.Heading',
            defaultMessage: 'e-Transfer sent',
          })}
        </TitleMedium>
      </div>
    </Modal>
  );

  const handleCloseError = () => {
    setStep(0);
    setShowError(false);
    dispatch(transferActions.resetLoadable());
  };

  const ErrorModal = (
    <Modal
      classes={{ paperWidthXs: 'max-w-[275px]' }}
      closeButton={false}
      onClose={handleCloseError}
      open={Boolean(showError)}
    >
      <div className="text-center">
        <TitleMedium className="mt-0 mb-2">
          {intl.formatMessage({
            id: 'SendMoneyDetailsPage.Error.Heading',
            defaultMessage: 'Oops, we couldn’t transfer the funds',
          })}
        </TitleMedium>
        <Paragraph className="mt-2">
          {showFundsError
            ? intl.formatMessage({
                id: 'SendMoneyDetailsPage.Error.BodyAlt',
                defaultMessage:
                  'Not enough funds in your Spendable. If you have Cover please note Cover funds are not included in Spendable.',
              })
            : intl.formatMessage({
                id: 'SendMoneyDetailsPage.Error.Body',
                defaultMessage:
                  'Please try again or contact our support team if it persists.',
              })}
        </Paragraph>
        <ButtonTw onClick={handleCloseError}>
          {intl.formatMessage({
            id: 'Global.Button.Okay',
            defaultMessage: 'Okay',
          })}
        </ButtonTw>
      </div>
    </Modal>
  );

  const handleCloseDeviceVerification = () => {
    setStep(0);
    setShowDeviceVerification(false);
    dispatch(transferActions.resetLoadable());
  };

  const DeviceVerificationModal = (
    <VerificationModal
      closeButton={false}
      onClose={handleCloseDeviceVerification}
      open={Boolean(showDeviceVerification)}
    >
      <ButtonTw className="mb-2" onClick={handleCloseDeviceVerification}>
        {intl.formatMessage({
          id: 'Global.Button.Okay',
          defaultMessage: 'Okay',
        })}
      </ButtonTw>
    </VerificationModal>
  );

  const [step, setStep] = useState(0);
  const steps = [
    AmountSection,
    RecipientsSection,
    RecipientFormSection,
    ReviewSection,
  ];

  const handleCloseAutodepositBlocker = () => {
    setShowAutodepositBlocker(false);
  };

  const AutodepositBlockerModal = (
    <Modal
      onClose={handleCloseAutodepositBlocker}
      open={showAutoDepositBlocker}
    >
      <div className="px-2 py-1">
        <TitleMedium className="mt-0 mb-4">
          {intl.formatMessage({
            id: 'SendMoneyDetailsPage.AutodepositBlocker.Heading',
            defaultMessage: 'Invalid recipient',
          })}
        </TitleMedium>
        <Paragraph className="mt-0 mb-6">
          {intl.formatMessage({
            id: 'SendMoneyDetailsPage.AutodepositBlocker.Body',
            defaultMessage:
              'You have registered this email for Autodeposit to KOHO, please use a different email.',
          })}
        </Paragraph>
        <ButtonTw onClick={handleCloseAutodepositBlocker}>
          {intl.formatMessage({
            id: 'SendMoneyDetailsPage.AutodepositBlocker.Button',
            defaultMessage: 'Got it',
          })}
        </ButtonTw>
      </div>
    </Modal>
  );

  return (
    <TemplateTw name="Send Money Page">
      {pagePermissions.canSeeSendMoneyPage && (
        <ContainerTw className="px-6 lg:px-0 py-0 w-full lg:max-w-[500px]">
          {steps[step]}
          {SuccessModal}
          {AutodepositBlockerModal}
          {ErrorModal}
          {DeviceVerificationModal}
        </ContainerTw>
      )}
    </TemplateTw>
  );
};
