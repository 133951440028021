import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import { ButtonTw } from 'components/ButtonTw';
import { DrawerModal } from 'components/DrawerModal';
import { Paragraph, TitleMedium } from 'components/TypographyTw';
import { useIcons } from 'hooks/useIcon';

const QuestionIcon = () => {
  const {
    Icons: { Question },
  } = useIcons();
  return <Question className="ml-1 mb-[2px] text-grey-300" />;
};

export const PaymentMethodsInfoModal = () => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);

  return (
    <DrawerModal
      content={({ closeModal }) => (
        <>
          {themeName === 'legacy' && <KDSIcons.Spot.Activity width={64} />}

          <TitleMedium className="legacy:mt-0 mt-5 mb-2 legacy:text-[32px] text-[32px] legacy:text-grey-400">
            {intl.formatMessage({
              id: 'PaymentMethodsInfo.Title',
              defaultMessage: 'Payment methods',
            })}
          </TitleMedium>
          <Paragraph className="block text-grey-400 [&_ul]:my-0 mb-6">
            {intl.formatMessage({
              id: 'PaymentMethodsInfo.Content',
              defaultMessage:
                "We'll always use the money in your KOHO account first to pay for your KOHO features like your plan, or credit building. To keep your benefits seamless if your KOHO account balance runs low, we'll try the other payment methods you've added to your account as a backup.",
            })}
          </Paragraph>
          <Paragraph className="block text-grey-400 mb-6 legacy:font-medium text-xs">
            {intl.formatMessage({
              id: 'PaymentMethodsInfo.ExtraInfo',
              defaultMessage:
                'By adding a payment method, you authorize KOHO to charge your payment method when your KOHO Account balance runs low. You can change your payment method at any time.',
            })}
          </Paragraph>
          {}
          <ButtonTw className="p-3" onClick={closeModal}>
            {intl.formatMessage({
              id: 'PaymentMethodsInfo.Cta',
              defaultMessage: 'Close',
            })}
          </ButtonTw>
        </>
      )}
    >
      <QuestionIcon />
    </DrawerModal>
  );
};
