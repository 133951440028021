import { useIntl } from 'react-intl';

import { IBenefitGroup, IPlan } from 'apis/tiers';
import { addDays, addYears } from 'date-fns';
import { Money } from 'models';
import { useTiersData } from 'tiers/hooks/useTiersData';

import { Step, StepIcons } from '../tierChangeSteps';

export type FreeTrialStepsProps = {
  plan: IPlan;
  tier: IBenefitGroup;
};

export const FreeTrialSteps = ({ plan, tier }: FreeTrialStepsProps) => {
  const intl = useIntl();
  const { getStartYourTrialMessage } = useTiersData();
  const planCost = new Money(plan?.amount_cents ?? 0).format('pretty');
  const today = new Date();
  const monthFromNow = addDays(today, 30);
  const nextAnnualBillingDateWithTrial = addYears(monthFromNow, 1);
  const todayTitle = intl.formatMessage({
    id: 'TiersUpgrade.Modal.Today',
    defaultMessage: 'Today',
  });

  const todayStep = (
    <Step
      isFirst
      icon={<StepIcons.Unlock />}
      title={todayTitle}
      description={getStartYourTrialMessage(tier.key)}
    />
  );

  if (plan.key === 'monthly') {
    return (
      <>
        {todayStep}
        <Step
          isLast
          icon={<StepIcons.Calendar />}
          title={intl.formatDate(monthFromNow, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
          description={intl.formatMessage(
            {
              id: 'TiersUpgrade.Modal.MonthRenewal',
              defaultMessage: "You'll pay {value}/month.",
            },
            { value: planCost },
          )}
        />
      </>
    );
  }

  return (
    <>
      {todayStep}
      <Step
        icon={<StepIcons.Calendar />}
        title={intl.formatDate(monthFromNow, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
        description={intl.formatMessage(
          {
            id: 'TiersUpgrade.Modal.YearTrialEnds',
            defaultMessage: "You'll pay {cost} for the rest of the year.",
          },
          { cost: planCost },
        )}
      />
      <Step
        isLast
        icon={<StepIcons.Renew />}
        title={intl.formatDate(nextAnnualBillingDateWithTrial, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
        description={intl.formatMessage(
          {
            id: 'TiersUpgrade.Modal.Renewal',
            defaultMessage: "Your plan renews, and you'll pay {cost}/year.",
          },
          { cost: planCost },
        )}
      />
    </>
  );
};
