import { MessageDescriptor, defineMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Box, Button, ButtonUnstyled, Text } from 'components';
import { EmploymentStatus as Employment, hisaActions } from 'hisa/store/slice';
import { theme } from 'theme';

const EmploymentButtons: { title: MessageDescriptor; value: Employment }[] = [
  {
    title: defineMessage({
      id: 'EmploymentStatus.Employed',
      defaultMessage: 'Employed',
    }),
    value: 'employed',
  },
  {
    title: defineMessage({
      id: 'EmploymentStatus.Selfemployed',
      defaultMessage: 'Self-employed',
    }),
    value: 'self-employed',
  },
  {
    title: defineMessage({
      id: 'EmploymentStatus.Retired',
      defaultMessage: 'Retired',
    }),
    value: 'retired',
  },
  {
    title: defineMessage({
      id: 'EmploymentStatus.Student',
      defaultMessage: 'Student',
    }),
    value: 'student',
  },
  {
    title: defineMessage({
      id: 'EmploymentStatus.Unemployed',
      defaultMessage: 'Unemployed',
    }),
    value: 'unemployed',
  },
];

export const EmploymentStatus = ({ status, onContinue }) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  return (
    <Box sx={{ my: 2 }}>
      {EmploymentButtons.map((button) => (
        <ButtonUnstyled
          key={button.value}
          sx={{
            boxShadow: theme.shadows.light,
            borderRadius: theme.borderRadius.medium,
            p: 3,
            my: 2,
            width: '100%',
            backgroundColor:
              status === button.value
                ? theme.colors.greyXLight
                : theme.colors.white,
            ':active': {
              transform: 'scale(0.99)',
            },
            ':focus:hover, :focus': {
              borderColor: theme.colors.primaryAlt,
            },
          }}
          onClick={() =>
            dispatch(hisaActions.setEmploymentStatus(button.value))
          }
        >
          <Text>{intl.formatMessage(button.title)}</Text>
        </ButtonUnstyled>
      ))}

      <Button
        sx={{ mt: 4 }}
        trackName="HISA Employment Continue"
        disabled={!status}
        onClick={onContinue}
      >
        {intl.formatMessage({
          id: 'HisaSetupPage.Continue',
          defaultMessage: 'Continue',
        })}
      </Button>
    </Box>
  );
};
