import { FormEvent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';
import { Paragraph, TitleLarge } from 'components/TypographyTw';
import { useTypedSelector } from 'hooks/redux';

import { ButtonTw } from '../components/ButtonTw';
import CircularProgressTw from '../components/CircularProgressTw';
import { passwordResetActions } from './store/slice';

export const PasswordResetEmailSent = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const state = useTypedSelector((state) => state);
  const { email } = state.passwordReset.request;
  const { loading } = state.passwordReset.emailResetLoadable;
  const intl = useIntl();

  return (
    <form
      className="mb-4"
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(passwordResetActions.resetEmailLoadableLoading());
      }}
    >
      <KDSIcons.Spot.Success />
      <TitleLarge>
        {intl.formatMessage({
          id: 'EmailSent.Title',
          defaultMessage: 'Password reset sent',
        })}
      </TitleLarge>
      <Paragraph>
        {intl.formatMessage(
          {
            id: 'EmailSent.Content',
            defaultMessage: `We’ve sent an email to {boldEmail} containing a link to reset your password.`,
          },
          { boldEmail: <b>{email}</b> },
        )}
      </Paragraph>
      <ButtonTw type="submit" trackName="Send reset link">
        {!loading &&
          intl.formatMessage({
            id: 'Global.Button.ResendEmail',
            defaultMessage: 'Resend email',
          })}
        {loading && <CircularProgressTw variant="large" />}
      </ButtonTw>
    </form>
  );
};
