/** @jsxImportSource theme-ui */
import { useDispatch, useSelector } from 'react-redux';

import { Backdrop } from '@material-ui/core';
import { useNavigate } from '@reach/router';
import { AddFundsFeatureFlags } from 'addFunds/feature-flags';
import { fbTrackEvent } from 'analytics/fbq';
import { trackRedditSignUp } from 'analytics/rdt';
import { stackAdaptTrackEvent } from 'analytics/saq';
import { trackSpotifySignUpFinished } from 'analytics/spotify';
import { tiktokTrackEvent } from 'analytics/ttq';
import { trackTwitterSignUpFinished } from 'analytics/twitter';
import { trackEventUnbounce } from 'analytics/unbounce';
import { trackVeritonicPurchase } from 'analytics/veritonic';
import { useCreateAccountMutation } from 'apis/registration';
import { SessionRefreshModal } from 'auth/components';
import { Card, LayoutBasic, SpinnerCircularProgress } from 'components';
import { IntendedUse } from 'kyc/models/IntendedUse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { loginActions } from 'login/store/slice';
import {
  selectProfileEmail,
  selectProfileFamilyName,
  selectProfileGivenName,
} from 'profile/store/selectors';
import { RegistrationIntentComponent } from 'registration/components/RegistrationIntentComponent';
import { RegistrationFeatureFlags } from 'registration/models/RegistrationFeatureFlags';
import { HelpAndAccessibility } from 'support/components';

export const RegistrationAccountFinishPage = ({ path: string }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileEmail = useSelector(selectProfileEmail);
  const profileFamilyName = useSelector(selectProfileFamilyName);
  const profileGivenName = useSelector(selectProfileGivenName);

  const [createAccount, createAccountMutationData] = useCreateAccountMutation();
  const isAlwaysRedirectToKycEnabled =
    useFlags()[RegistrationFeatureFlags.AlwaysRedirectToKYC];

  // bucket users by evaluating flag at end of registration
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  useFlags()[AddFundsFeatureFlags.DebitCardLoadingEnabled];

  const ctaClicked = async (intent?: IntendedUse) => {
    const body = intent
      ? {
          intended_use: intent,
        }
      : {};

    createAccount(body)
      .unwrap()
      .then(() => {
        if (profileEmail && profileFamilyName && profileGivenName) {
          fbTrackEvent('CompleteRegistration', {
            'ud[em]': profileEmail,
            'ud[fn]': profileGivenName,
            'ud[ln]': profileFamilyName,
          });
        }

        if (profileEmail) {
          tiktokTrackEvent('CompleteRegistration', {
            settings: { email: profileEmail },
          });

          trackRedditSignUp(profileEmail);
        }

        trackSpotifySignUpFinished();

        trackTwitterSignUpFinished();

        stackAdaptTrackEvent({ 'button-click': 'btn-complete-registration' });

        trackEventUnbounce();

        trackVeritonicPurchase();

        if (isAlwaysRedirectToKycEnabled) {
          navigate('/kyc/entry');
        } else {
          dispatch(loginActions.navigateToLoginDestination());
        }
      })
      .catch(() => {
        navigate('/registration-account-error');
      });
  };

  return (
    <LayoutBasic pageName="Registration - Account Finish" chat={false}>
      <Backdrop open={createAccountMutationData.isLoading} sx={{ zIndex: 10 }}>
        <SpinnerCircularProgress />
      </Backdrop>
      <SessionRefreshModal />

      <Card>
        <RegistrationIntentComponent intentClicked={ctaClicked} />
      </Card>

      <HelpAndAccessibility />
    </LayoutBasic>
  );
};
