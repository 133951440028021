/** @jsxImportSource theme-ui */
import { Fragment, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Box, IconButton } from '@material-ui/core';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import LockOutlined from '@material-ui/icons/LockOutlined';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import { navigate } from '@reach/router';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as Sentry from '@sentry/browser';
import { useGetCardImageQuery, useGetCardsQuery } from 'apis/cards';
import { KDSIcons } from 'assets/images/kds_icons';
import { usePagePermissions } from 'auth/hooks/usePagePermissions';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flex } from 'theme-ui';
import { TxFeatureFlags } from 'transactions/transactions-feature-flags';

import { selectAccountPersonalAccountGroupId } from '../../accounts/store/selectors';
import { track } from '../../analytics/analytics';
import virtualCardMC from '../../assets/images/virtual-card-mc.png';
import {
  selectBalanceIsLoading,
  selectBalancePersonalSpendable,
} from '../../balance/store/selectors';
import { balanceActions } from '../../balance/store/slice';
import { cardsActions } from '../../cards/store/slice';
import {
  Button,
  ButtonUnstyled,
  LocaleContext,
  NumberExtraLarge,
  Paragraph,
  SpinnerCircularProgress,
} from '../../components';
import { Circle } from '../../components/icons/';
import { DeviceVerificationModal } from '../../deviceVerification/components';
import { selectDeviceVerificationOpen } from '../../deviceVerification/store/selectors';
import { deviceVerificationActions } from '../../deviceVerification/store/slice';
import { mobile, theme } from '../../theme';

export const VirtualCardWithBalance = (
  props: Readonly<{ showBalance: Function }>,
) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const context = useContext(LocaleContext);

  const personalSpendableBalance = useSelector(selectBalancePersonalSpendable);
  const isBalanceLoading = useSelector(selectBalanceIsLoading);

  const { data: cardsResponse } = useGetCardsQuery();

  const virtualCard = cardsResponse?.find((card) => card.is_virtual);

  const {
    data: cardImageResponse,
    isUninitialized: cardImageUninitialized,
    isLoading: cardImageLoading,
    error: cardImageError,
  } = useGetCardImageQuery(virtualCard?.card_id ?? skipToken);

  const [cardImageUrl, setCardImageUrl] = useState<string | null>(null);

  const personalAccountIdentifier = useSelector(
    selectAccountPersonalAccountGroupId,
  );

  const deviceVerificationOpen = useSelector(selectDeviceVerificationOpen);

  const isVirtualCardLocked = virtualCard?.is_locked ?? false;

  const pagePermissions = usePagePermissions();

  useEffect(() => {
    if (personalAccountIdentifier) {
      dispatch(balanceActions.getBalanceRequest());
    }
  }, [dispatch, personalAccountIdentifier, context.apiLanguageHeader]);

  useEffect(() => {
    if (deviceVerificationOpen) {
      track({ event: 'Card Device Verification Opened' });
    }
  }, [deviceVerificationOpen]);

  const toggleCardNumber = () => {
    if (cardImageError) {
      if ('status' in cardImageError && cardImageError.status === 403) {
        dispatch(deviceVerificationActions.openDeviceVerification());
      } else {
        // indicates a runtime error eg. TypeError
        Sentry.captureException(cardImageError);
      }
    } else {
      if (!cardImageUrl) {
        track({ event: 'Card Number Show Clicked' });
        setCardImageUrl(cardImageResponse || null);
      } else {
        track({ event: 'Card Number Hide Clicked' });
        setCardImageUrl(null);
      }
    }
  };

  const toggleCardUsability = () => {
    const isLocked = !isVirtualCardLocked;
    const lockedMessage = intl.formatMessage({
      id: 'VirtualCardWithBalance.LockCard.Locked',
      defaultMessage: 'Card has been locked',
    });
    const unlockedMessage = intl.formatMessage({
      id: 'VirtualCardWithBalance.LockCard.Unlocked',
      defaultMessage: 'Card has been unlocked',
    });

    dispatch(
      cardsActions.changeCardUsabilityRequest({
        cardId: virtualCard?.card_id ?? '',
        isLocked,
        successMessage: isLocked ? lockedMessage : unlockedMessage,
      }),
    );
  };

  const handleMyCards = () => {
    if (cardImageError) {
      if ('status' in cardImageError && cardImageError.status === 403) {
        dispatch(deviceVerificationActions.openDeviceVerification());
      } else {
        // indicates a runtime error eg. TypeError
        Sentry.captureException(cardImageError);
      }
    } else {
      navigate('/cards');
    }
  };

  const viewCardAriaLabel = !cardImageResponse
    ? intl.formatMessage({
        id: 'VirtualCardWithBalance.CardPrivacy.Show',
        defaultMessage: 'Show virtual card',
      })
    : intl.formatMessage({
        id: 'VirtualCardWithBalance.CardPrivacy.Hide',
        defaultMessage: 'Hide virtual card',
      });

  const lockCardAriaLabel = isVirtualCardLocked
    ? intl.formatMessage({
        id: 'VirtualCardWithBalance.LockCard.Unlock',
        defaultMessage: 'Unlock card',
      })
    : intl.formatMessage({
        id: 'VirtualCardWithBalance.LockCard.Lock',
        defaultMessage: 'Lock card',
      });

  return (
    <Fragment>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div sx={{ position: 'relative' }}>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              width: '320px',
              height: '202px',
              borderRadius: theme.borderRadius.medium,
              overflow: 'hidden',
              img: {
                width: '100%',
                height: '100%',
              },
              [mobile]: {
                width: '100%',
              },
            }}
          >
            <img
              src={cardImageResponse}
              style={{ display: cardImageUrl ? 'block' : 'none' }}
              alt={intl.formatMessage({
                id: 'VirtualCardWithBalance.VirtualCardPublic',
                defaultMessage: 'Virtual Card With Number',
              })}
            />

            <img
              src={virtualCardMC}
              style={{ display: cardImageUrl ? 'none' : 'block' }}
              alt={intl.formatMessage({
                id: 'VirtualCardWithBalance.VirtualCardPrivate',
                defaultMessage: 'Virtual Card',
              })}
            />
          </Flex>

          {!pagePermissions.canSeeMyCardsPage && (
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                flexFlow: 'column',
                position: 'absolute',
                bottom: theme.fontSizes[2],
                right: '-78px',
                gap: '8px',
                [mobile]: {
                  right: 0,
                  top: 1,
                  flexFlow: 'row',
                  position: 'relative',
                  mt: 2,
                },
              }}
            >
              <RoundButton
                onClick={toggleCardNumber}
                label={viewCardAriaLabel}
                hideLabel={true}
              >
                {!cardImageUrl ? (
                  <VisibilityOutlined sx={{ color: theme.colors.background }} />
                ) : (
                  <VisibilityOffOutlined
                    sx={{ color: theme.colors.background }}
                  />
                )}
              </RoundButton>
              <RoundButton
                onClick={toggleCardUsability}
                label={lockCardAriaLabel}
                hideLabel={true}
              >
                {isVirtualCardLocked ? (
                  <LockOutlined sx={{ color: theme.colors.background }} />
                ) : (
                  <LockOpenOutlined sx={{ color: theme.colors.background }} />
                )}
              </RoundButton>
            </Flex>
          )}
        </div>
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          [mobile]: { mt: 2 },
        }}
      >
        <Paragraph sx={{ fontWeight: 700 }}>
          {intl.formatMessage({
            id: 'VirtualCardWithBalance.Balance',
            defaultMessage: 'Balance',
          })}
        </Paragraph>

        {isBalanceLoading ? (
          <Box display="flex">
            <NumberExtraLarge>&nbsp;</NumberExtraLarge>
            <SpinnerCircularProgress />
          </Box>
        ) : (
          <BalanceIndicator
            balance={personalSpendableBalance}
            showBalance={props.showBalance}
          />
        )}

        {!pagePermissions.canSeeMyCardsPage && (
          <Button
            trackName="add-money-cta"
            sx={{ width: ['229px', '320px'], mt: 4 }}
            onClick={() => navigate('/load')}
          >
            {intl.formatMessage({
              id: 'VirtualCardWithBalance.AddMoneyButton',
              defaultMessage: 'Add money',
            })}
          </Button>
        )}

        {pagePermissions.canSeeMyCardsPage && (
          <Flex sx={{ mt: 4, gap: 2 }}>
            <RoundButton
              light
              onClick={() => navigate('/load')}
              label={intl.formatMessage({
                id: 'VirtualCardWithBalance.AddMoneyButton',
                defaultMessage: 'Add money',
              })}
            >
              <KDSIcons.Icons.Add sx={{ color: theme.colors.primary }} />
            </RoundButton>

            <RoundButton
              light
              disabled={cardImageUninitialized || cardImageLoading}
              onClick={() => handleMyCards()}
              label={intl.formatMessage({
                id: 'VirtualCardWithBalance.MyCards.Button',
                defaultMessage: 'Cards',
              })}
            >
              <KDSIcons.Icons.CreditCard sx={{ color: theme.colors.primary }} />
            </RoundButton>
          </Flex>
        )}
      </Flex>

      <DeviceVerificationModal
        open={deviceVerificationOpen}
        onClose={() => {
          track({ event: 'Card Device Verification Closed' });
          dispatch(deviceVerificationActions.closeDeviceVerification());
        }}
      />
    </Fragment>
  );
};

type RoundButtonProps = {
  light?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
  label: string;
  hideLabel?: boolean;
};
const RoundButton = ({
  children,
  onClick,
  label,
  disabled = false,
  hideLabel = false,
  light,
}: Readonly<RoundButtonProps>) => {
  return (
    <ButtonUnstyled
      onClick={onClick}
      disabled={disabled}
      aria-label={label}
      sx={{
        minWidth: '80px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '24px',
        '&:hover:enabled, &:focus': {
          borderRadius: '24px',
        },
        ':disabled': {
          opacity: 0.4,
        },
      }}
    >
      <Circle light={light}>{children}</Circle>
      {hideLabel || <Paragraph sx={{ my: 1 }}>{label}</Paragraph>}
    </ButtonUnstyled>
  );
};

const BalanceIndicator = ({ balance, showBalance }) => {
  const formatMoney = useMoneyFormatter();
  const showBalanceSheet = useFlags()[TxFeatureFlags.ShowBalanceTooltip];

  if (!showBalanceSheet) {
    return (
      <NumberExtraLarge>{balance && formatMoney(balance)}</NumberExtraLarge>
    );
  }

  return (
    <Box textAlign="center" display="flex" position="relative" left="24px">
      <NumberExtraLarge>{balance && formatMoney(balance)}</NumberExtraLarge>
      <IconButton sx={{ ml: 2 }} onClick={() => showBalance()}>
        <KDSIcons.Icons.Info />
      </IconButton>
    </Box>
  );
};
