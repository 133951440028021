import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  Loadable,
  createDefaultLoadable,
  createErrorLoadable,
  createLoadingLoadable,
  createSuccessLoadable,
} from '../../utility/loadable';
import { Profile } from '../models/profile';

export interface ProfileState {
  data: Profile | null;
  loadable: Loadable;
}

const initialState: ProfileState = {
  data: null,
  loadable: createDefaultLoadable(),
};

const getProfile = {
  request: (state) => {
    state.loadable = createLoadingLoadable();
  },
  withEventsRequest: (state, action: PayloadAction<boolean>) => {
    state.loadable = createLoadingLoadable();
  },
  response: (state, action: PayloadAction<Profile>) => {
    state.loadable = createSuccessLoadable();
    state.data = { ...state.data, ...action.payload };
  },
  withEventsResponse: (
    state,
    action: PayloadAction<{
      profile: Profile;
      removeProfileProperties: boolean;
    }>,
  ) => {
    state.loadable = createSuccessLoadable();
    state.data = action.payload.profile;
  },
  error: (state) => {
    state.loadable = createErrorLoadable('Failed to get profile info');
  },
  createIdResponse: (state, { payload: user_id }: PayloadAction<string>) => {
    state.data = {
      ...state.data,
      reference_identifier: user_id,
      new_user: true,
    };
  },
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    getProfileRequest: getProfile.request,
    getProfileSuccess: getProfile.response,
    getProfileRefAfterRegistration: getProfile.createIdResponse,
    getProfileRequestWithEvents: getProfile.withEventsRequest,
    getProfileSuccessWithEvents: getProfile.withEventsResponse,
    getProfileError: getProfile.error,
    noop: (state) => state,
  },
});

export const profileActions = profileSlice.actions;
export default profileSlice.reducer;
