import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { zodResolver } from '@hookform/resolvers/zod';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isCanadaPostAffiliate, useGetAffiliatesQuery } from 'apis/affiliates';
import { useOtpRequestMutation } from 'apis/registration';
import { Language } from 'appState/appState.slice';
import { LocaleContext } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import { LinkTw } from 'components/LinkTw';
import { ErrorParagraph, Paragraph, TitleLarge } from 'components/TypographyTw';
import {
  InputValidationMsg,
  useValidationMsg,
} from 'components/forms/useValidationMsg';
import { KhInputTextTw } from 'components/inputs/KhInputTextTw';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectLoginTelLast4Digits } from 'login/store/selectors';
import { selectProfileTelLast4Digits } from 'profile/store/selectors';
import { RegistrationFeatureFlags } from 'registration/models/RegistrationFeatureFlags';
import {
  RegistrationSmsOtpVerifyForm,
  zodFormRegistrationSmsOtpVerify,
} from 'registration/zodForms/zodFormRegistrationSmsOtp';
import {
  useTrackElementViewedOnce,
  useTrackPageViewedOnceDeprecated,
} from 'utility/analyticsHooks';

export const RegistrationSmsOtpPage = ({
  phoneNumber,
  editMobileNumber,
  mobileNumberEdited,
  smsOtpInvalid,
  setSmsOtpInvalid,
  submitVerifyOtp,
  isLoading,
}: {
  phoneNumber: string;
  editMobileNumber: () => void;
  mobileNumberEdited: string;
  smsOtpInvalid: boolean;
  setSmsOtpInvalid: (invalid: boolean) => void;
  submitVerifyOtp: (data: RegistrationSmsOtpVerifyForm) => Promise<void>;
  isLoading: boolean;
}) => {
  const intl = useIntl();

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<RegistrationSmsOtpVerifyForm>({
    mode: 'onBlur',
    resolver: zodResolver(zodFormRegistrationSmsOtpVerify),
  });

  const [requestOtp, requestOtpMutationData] = useOtpRequestMutation();

  const loginMaskedTel = useSelector(selectLoginTelLast4Digits);
  const profileMaskedTel = useSelector(selectProfileTelLast4Digits);
  const maskedPhoneNumber = loginMaskedTel || profileMaskedTel;
  const mobileNumberUnedited = maskedPhoneNumber
    ? maskedPhoneNumber
    : phoneNumber;

  useTrackElementViewedOnce({
    element: errors.otp,
    name: 'verification-code-input-error',
  });

  useTrackElementViewedOnce({
    element: smsOtpInvalid,
    name: 'verification-code-input-error',
  });

  useTrackElementViewedOnce({
    element: requestOtpMutationData.error,
    name: 'otp-request-error',
  });

  const [smsOtpRequestedOnLoad, setSmsOtpRequestedOnLoad] = useState(false);

  useTrackPageViewedOnceDeprecated({
    name: 'registration-sms-otp',
    overrideUrl: `registration/sms-otp`,
  });

  if (!smsOtpRequestedOnLoad) {
    requestOtp();
    setSmsOtpRequestedOnLoad(true);
  }

  const submitRequestOtp = () => {
    requestOtp()
      .unwrap()
      .then(() => {
        setSecondsSinceSmsOtpResent(60);
      });
  };

  const [secondsSinceSmsOtpResent, setSecondsSinceSmsOtpResent] =
    useState<number>(0);

  const isAffiliateRegistrationEnabled =
    useFlags()[RegistrationFeatureFlags.EnableAffiliateRegistration];
  const affiliatesData = useGetAffiliatesQuery(
    isAffiliateRegistrationEnabled ? undefined : skipToken,
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (secondsSinceSmsOtpResent) {
        setSecondsSinceSmsOtpResent(secondsSinceSmsOtpResent - 1);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [secondsSinceSmsOtpResent]);

  return (
    <form onSubmit={handleSubmit(submitVerifyOtp)}>
      <TitleLarge className="legacy:mb-6" data-cy="sms-otp-title">
        {intl.formatMessage({
          id: 'RegistrationSmsOtpPage.Title',
          defaultMessage: 'Verify your mobile number',
        })}
      </TitleLarge>
      <div className="mb-6">
        <Paragraph className="mb-0">
          {intl.formatMessage(
            {
              id: 'RegistrationSmsOtpPage.Instructions',
              defaultMessage:
                'Enter the 6 digit code we texted to you at {mobile}. ',
            },
            {
              mobile: mobileNumberEdited
                ? mobileNumberEdited
                : mobileNumberUnedited,
            },
          )}
        </Paragraph>

        <LinkTw
          to="#"
          onClick={editMobileNumber}
          trackName="Edit Phone Number Clicked"
        >
          {intl.formatMessage({
            id: 'RegistrationSmsOtpPage.EditNumber.Button',
            defaultMessage: `Edit number.`,
          })}
        </LinkTw>
      </div>
      <KhInputTextTw
        {...register('otp', { onChange: () => setSmsOtpInvalid(false) })}
        placeholder={intl.formatMessage({
          id: 'RegistrationSmsOtpPage.InputPlaceholder',
          defaultMessage: 'Verification code',
        })}
        error={!!errors.otp}
        helperText={useValidationMsg(
          smsOtpInvalid ? InputValidationMsg.InvalidCode : errors.otp?.message,
        )}
        className="mb-4"
        data-cy="sms-otp-code-input"
        trackName="verification-code"
      />
      <Paragraph className="mb-6 light:text-tertiary-300">
        {intl.formatMessage(
          {
            id: 'RegistrationSmsOtpPage.TermsConditions',
            defaultMessage:
              'I have read and agree to be bound by KOHO’s <terms>terms and conditions</terms>.',
          },
          {
            terms: TermsConditionsLink,
          },
        )}
      </Paragraph>
      {!!affiliatesData.data?.affiliates &&
        isCanadaPostAffiliate(affiliatesData.data?.affiliates) && (
          <>
            <Paragraph className="mb-6 light:text-tertiary-300">
              {intl.formatMessage({
                id: 'Registration.SmsOtpPage.CanadaPostConsent.0',
                defaultMessage:
                  'By signing up, you agree to the collection, use and disclosure of your personal information by KOHO and Canada Post for the purpose of providing you this account and other products and services.',
              })}
            </Paragraph>
            <Paragraph className="mb-6 light:text-tertiary-300">
              {intl.formatMessage(
                {
                  id: 'Registration.SmsOtpPage.CanadaPostConsent.1',
                  defaultMessage:
                    'To know more about how KOHO and Canada Post handles your personal information, please check out the <privacyPolicy>KOHO Privacy Policy</privacyPolicy> and <cpcPrivacyPolicy>Canada Post Privacy Policy</cpcPrivacyPolicy>.',
                },
                {
                  privacyPolicy: PrivacyPolicyLink,
                  cpcPrivacyPolicy: CpcPrivacyPolicyLink,
                },
              )}
            </Paragraph>
          </>
        )}

      <ButtonTw
        type="submit"
        className="mb-4"
        disabled={!isValid || isLoading}
        loading={isLoading}
        data-cy="sms-otp-cta-button"
        trackName="move-verify-mobile"
      >
        {intl.formatMessage({
          id: 'RegistrationSmsOtpPage.Next',
          defaultMessage: 'Next',
        })}
      </ButtonTw>

      <ButtonTw
        type="button"
        variant="tertiary"
        onClick={submitRequestOtp}
        loading={requestOtpMutationData.isLoading}
        trackName="resend-code"
        disabled={!!secondsSinceSmsOtpResent}
      >
        {secondsSinceSmsOtpResent === 0
          ? intl.formatMessage({
              id: 'RegistrationSmsOtpPage.Resend',
              defaultMessage: 'Didn’t receive it? Resend code',
            })
          : intl.formatMessage(
              {
                id: 'RegistrationSmsOtpPage.ResendAfterSeconds',
                defaultMessage: `Resend code in {time}s`,
              },
              { time: secondsSinceSmsOtpResent },
            )}
      </ButtonTw>
      {requestOtpMutationData.isError && (
        <ErrorParagraph className="text-center">
          {intl.formatMessage({
            id: 'RegistrationSmsOtpPage.Error',
            defaultMessage: `Something went wrong. Please try again.`,
          })}
        </ErrorParagraph>
      )}
    </form>
  );
};

const TermsConditionsLink = (str: string) => {
  const intl = useIntl();

  return (
    <LinkTw
      className="light:text-tertiary-300"
      to={intl.formatMessage({
        id: 'RegistrationSmsOtpPage.TermsConditionsLink',
        defaultMessage:
          'https://www.koho.ca/legal/?ver=mastercard#CardHolderAgreement',
      })}
      external={true}
      trackName="registration - Terms and Conditions link"
    >
      {str}
    </LinkTw>
  );
};

const PrivacyPolicyLink = (str: string) => {
  const intl = useIntl();

  return (
    <LinkTw
      className="underline text-tertiary font-normal light:text-tertiary-300"
      to={intl.formatMessage({
        id: 'RegistrationSmsOtpPage.PrivacyPolicyLink',
        defaultMessage:
          'https://www.koho.ca/legal/?ver=mastercard#PrivacyPolicy',
      })}
      external={true}
      trackName="registration - Privacy Policy link"
    >
      {str}
    </LinkTw>
  );
};

const CpcPrivacyPolicyLink = (str: string) => {
  const localeContext = useContext(LocaleContext);

  return (
    <LinkTw
      className="underline text-tertiary font-normal light:text-tertiary-300"
      to={
        localeContext.locale === Language.EN
          ? 'https://www.canadapost-postescanada.ca/cpc/en/our-company/transparency-and-trust/privacy-centre/privacy-policy.page'
          : 'https://www.canadapost-postescanada.ca/scp/fr/notre-entreprise/transparence-et-confiance/centre-de-protection-de-la-vie-privee/politique-protection-renseignements-personnels.page'
      }
      external={true}
      trackName="registration - CPC Privacy Policy link"
    >
      {str}
    </LinkTw>
  );
};
