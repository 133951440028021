import { defineMessage } from 'react-intl';

import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { Epic } from 'redux-observable';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RootState } from 'store';
import { v4 as uuidv4 } from 'uuid';

import {
  BillPay,
  BillPayOTPResponse,
  Payee,
  PayeeCreateSubmitError,
  PayeeCreateSubmitRequest,
  PayeeHistory,
  PayeeHistoryRequest,
  PayeePayments,
  PayeeRequest,
  Payees,
} from '../models/billPay';
import { payeesActions } from './slice';

interface StandardResponse {
  status_code: number;
  status_name: string;
}

type BillPayResponse = StandardResponse & { data: BillPay };
type BillPayeeResponse = StandardResponse & { data: PayeeRequest[] };
type BillPayErrorResponse = StandardResponse & PayeeCreateSubmitError;
type PayeePaymentHistoryResponse = StandardResponse & {
  data: PayeeHistoryRequest[];
};

const getBillPayeesEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(payeesActions.getPayeesRequest.toString()),
    switchMap(() => {
      return from(
        axios.get(`${import.meta.env.VITE_GATEWAY_API}billpay/user/payees`),
      ).pipe(
        map((response: AxiosResponse<BillPayeeResponse>) => {
          const original = response.data.data;
          const modified: Payee[] = original.map(
            ({
              active,
              biller_account_number,
              biller_code,
              biller_name,
              identifier,
              is_credit,
              nickname,
            }) => ({
              active,
              accountNumber: biller_account_number,
              code: biller_code,
              name: biller_name,
              identifier,
              isCredit: is_credit,
              nickname,
            }),
          );
          return payeesActions.getPayeesSuccess(modified);
        }),
        catchError(() => of(payeesActions.getPayeesError())),
      );
    }),
  );
};

const getBillPaySearchEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(payeesActions.getPayeeSearchRequest.toString()),
    switchMap(({ payload: { searchText } }) => {
      return from(
        axios.post(
          `${import.meta.env.VITE_GATEWAY_API}billpay/user/billers/search`,
          {
            biller_name: searchText,
          },
        ),
      ).pipe(
        map((response: AxiosResponse<BillPayResponse>) => {
          const { billers, details, results_count } = response.data.data;
          const result: Payees = {
            payees: billers.map(({ biller_code, biller_name }) => ({
              payeeName: biller_name,
              payeeCode: biller_code,
            })),
            payeesCount: results_count,
            payeesDetails: details,
          };
          return payeesActions.getPayeeSearchSuccess(result);
        }),
        catchError(() => {
          return of(payeesActions.getPayeeSearchError());
        }),
      );
    }),
  );
};

const getPayeePaymentHistoryEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(payeesActions.getPayeePaymentHistoryRequest.toString()),
    switchMap(({ payload: { identifier } }) => {
      return from(
        axios.get(
          `${
            import.meta.env.VITE_GATEWAY_API
          }billpay/user/payee/${identifier}/history`,
        ),
      ).pipe(
        map((response: AxiosResponse<PayeePaymentHistoryResponse>) => {
          const result = response.data.data;
          const payments: PayeePayments = result.reduce(
            (acc, payee: PayeeHistoryRequest) => {
              acc[payee.identifier] = {
                identifier: payee.identifier,
                version: payee.Version,
                payeeIdentifier: payee.payee_identifier,
                amount: payee.amount,
                customerName: payee.customer_name,
                createdOn: payee.created_on,
                billAccountNumber: payee.bill_account_number,
                billerName: payee.biller_name,
                billerCode: payee.biller_code,
                paymentState: payee.payment_state,
                payOn: payee.pay_on,
                isRecurring: payee.is_recuring,
                recurringPeriod: payee.recuring_period,
                variableAmount: payee.variable_amount,
                parentReferenceId: payee.parent_reference_id,
              } as PayeeHistory;
              return acc;
            },
            {},
          );
          return payeesActions.getPayeePaymentHistorySuccess(payments);
        }),
        catchError(() => {
          return of(
            payeesActions.getPayeePaymentHistoryError(
              defineMessage({
                id: 'BillPay.PayHistoryError',
                defaultMessage: 'Failed to retrieve payment history',
              }),
            ),
          );
        }),
      );
    }),
  );
};

const submitPaymentToPayeeEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(payeesActions.submitPaymentToPayeeRequest.toString()),
    switchMap(({ payload: { identifier, amount } }) => {
      return from(
        axios.post(
          `${
            import.meta.env.VITE_GATEWAY_API
          }billpay/user/payee/${identifier}/pay`,
          {
            amount,
            transaction_guid: uuidv4(),
          },
        ),
      ).pipe(
        map(() => {
          return payeesActions.submitPaymentToPayeeSuccess();
        }),
        catchError(() => {
          return of(
            payeesActions.submitPaymentToPayeeError(
              defineMessage({
                id: 'BillPay.PayPayeeError',
                defaultMessage:
                  'Failed to pay payee. Please try again or contact our support team if it persists.',
              }),
            ),
          );
        }),
      );
    }),
  );
};

const submitCreatePayeeEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(payeesActions.submitCreatePayeesRequest.toString()),
    switchMap(
      ({
        payload: { payeeAccountNumber, payeeCode, payeeName, payeeNickname },
      }: PayloadAction<PayeeCreateSubmitRequest>) => {
        const requestPayload: {
          biller_code: string;
          biller_name: string;
          nickname: string;
          bill_account_number: string;
        } = {
          bill_account_number: payeeAccountNumber,
          biller_code: payeeCode,
          biller_name: payeeName,
          nickname: payeeNickname,
        };
        const requestUrl = `${
          import.meta.env.VITE_GATEWAY_API
        }billpay/user/payee/add_otp`;
        return from(axios.post(requestUrl, requestPayload)).pipe(
          map((response: AxiosResponse<BillPayOTPResponse>) => {
            const verificationUrl = response.data._links?.verify?.href;
            const phoneNumber = response.data._embedded?.verify?.phone_number;
            if (verificationUrl && phoneNumber) {
              return payeesActions.submitCreatePayeeOTPTransition({
                phoneNumber,
                verificationUrl,
              });
            }
            return payeesActions.submitCreatePayeesSuccess();
          }),
          catchError((error) => {
            const errorResponse: BillPayErrorResponse | undefined =
              error.response?.data;
            return of(payeesActions.submitCreatePayeesError(errorResponse));
          }),
        );
      },
    ),
  );
};

const submitCreatePayeeOTPResendEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(payeesActions.submitCreatePayeesResendOTPRequest.toString()),
    switchMap(
      ({
        payload: { payeeAccountNumber, payeeCode, payeeName, payeeNickname },
      }: PayloadAction<PayeeCreateSubmitRequest>) => {
        const requestPayload: {
          biller_code: string;
          biller_name: string;
          nickname: string;
          bill_account_number: string;
        } = {
          bill_account_number: payeeAccountNumber,
          biller_code: payeeCode,
          biller_name: payeeName,
          nickname: payeeNickname,
        };
        const requestUrl = `${
          import.meta.env.VITE_GATEWAY_API
        }billpay/user/payee/add_otp`;
        return from(axios.post(requestUrl, requestPayload)).pipe(
          map(() => payeesActions.submitCreatePayeesResendOTPResponse()),
          catchError(() =>
            of(payeesActions.submitCreatePayeesResendOTPError()),
          ),
        );
      },
    ),
  );
};

const submitCreatePayeeOTPVerifyEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(payeesActions.submitCreatePayeeOTPRequest.toString()),
    switchMap(
      ({
        payload: {
          payeeAccountNumber,
          payeeCode,
          payeeName,
          payeeNickname,
          payeeVerificationCode,
          payeeVerificationPath,
        },
      }: PayloadAction<PayeeCreateSubmitRequest>) => {
        const requestPayload: {
          biller_code: string;
          biller_name: string;
          nickname: string;
          bill_account_number: string;
          verification_code: string;
        } = {
          bill_account_number: payeeAccountNumber,
          biller_code: payeeCode,
          biller_name: payeeName,
          nickname: payeeNickname,
          verification_code: payeeVerificationCode || '',
        };
        const requestUrl = `${
          import.meta.env.VITE_GATEWAY_API
        }${payeeVerificationPath}`;
        return from(axios.post(requestUrl, requestPayload)).pipe(
          map(() => payeesActions.submitCreatePayeeOTPSuccess()),
          catchError(() => of(payeesActions.submitCreatePayeeOTPError())),
        );
      },
    ),
  );
};

const deleteBillPayeeEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(payeesActions.deletePayeeByIdentifierRequest.toString()),
    switchMap(({ payload: { identifier } }) => {
      return from(
        axios.post(
          `${
            import.meta.env.VITE_GATEWAY_API
          }billpay/user/payee/${identifier}/status`,
          { status: false },
        ),
      ).pipe(
        map(() => payeesActions.deletePayeeByIdentifierSuccess()),
        catchError(() => of(payeesActions.deletePayeeByIdentifierError())),
      );
    }),
  );
};

const exportedArray = [
  getBillPayeesEpic,
  getBillPaySearchEpic,
  getPayeePaymentHistoryEpic,
  submitCreatePayeeEpic,
  submitCreatePayeeOTPResendEpic,
  submitCreatePayeeOTPVerifyEpic,
  submitPaymentToPayeeEpic,
  deleteBillPayeeEpic,
];

export default exportedArray;
