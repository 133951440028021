/** @jsxImportSource theme-ui */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Box } from '@material-ui/core';
import { KDSIcons } from 'assets/images/kds_icons';
import {
  Button,
  Paragraph,
  SecurityAssurance,
  SubtitleMedium,
  SubtitleSmall,
  TitleLarge,
} from 'components';
import { Address } from 'models/Address';
import { RegistrationAddressEntry } from 'registration/components/RegistrationAddressEntry';
import { RegistrationAddressSearch } from 'registration/components/RegistrationAddressSearch';
import { AddressFormSchema } from 'registration/zodForms/zodFormRegistrationAddress';
import { theme } from 'theme';
import { useTrackPageViewedOnceDeprecated } from 'utility/analyticsHooks';

export const RegistrationHomeAddressPage = ({
  addressForm,
  submitForm,
  setCadPostAddress,
  shippingAddressLine1,
}: {
  addressForm: UseFormReturn<AddressFormSchema, any>;
  submitForm: () => void;
  setCadPostAddress: React.Dispatch<React.SetStateAction<Address | null>>;
  shippingAddressLine1: string;
}) => {
  const intl = useIntl();

  const {
    formState: { isValid },
    setValue,
    handleSubmit,
    reset,
  } = addressForm;

  const [showAddressEntry, setShowAddressEntry] = React.useState(false);

  const updateAddressValue = (address: Address): void => {
    setCadPostAddress(address);

    setValue('addressLine1', address.line1);
    setValue('addressLine2', address.line2);
    setValue('city', address.city);
    setValue('postalCode', address.postal_code);
    setValue('province', address.province_code);

    setShowAddressEntry(true);
  };

  useTrackPageViewedOnceDeprecated({
    name: 'registration-home-address',
    overrideUrl: `registration/home-address`,
  });

  return (
    <form>
      <TitleLarge>
        {intl.formatMessage({
          id: 'Registration.HomeAddressPage.Title',
          defaultMessage: 'What’s your home address?',
        })}
      </TitleLarge>
      <Paragraph sx={{ mb: 5 }}>
        {intl.formatMessage({
          id: 'Registration.HomeAddressPage.Subtitle',
          defaultMessage:
            'We need an address other than your PO Box to verify your identity.',
        })}
      </Paragraph>

      {showAddressEntry ? (
        <>
          <RegistrationAddressEntry submit={submitForm} form={addressForm} />
          <Button
            type="button"
            variant="tertiary"
            onClick={() => {
              setShowAddressEntry(false);
              reset();
            }}
            sx={{ mb: 3 }}
            data-cy="home-address-redo-search"
          >
            {intl.formatMessage({
              id: 'Registration.HomeAddressPage.RedoSearch',
              defaultMessage: 'Re-do search',
            })}
          </Button>
        </>
      ) : (
        <>
          <RegistrationAddressSearch
            sx={{ mb: 3 }}
            addressSelected={updateAddressValue}
            cantFindAddressClicked={() => setShowAddressEntry(true)}
          />
          <Box
            sx={{
              boxShadow: `3px 6px 15px 0px rgba(0, 0, 0, 0.10)`,
              p: 3,
              borderRadius: '8px',
              display: `flex`,
              gap: 3,
              justifyContent: 'space-between',
              mb: 4,
            }}
          >
            <Box>
              <SubtitleSmall sx={{ mb: 2 }}>
                {intl.formatMessage({
                  id: 'Registration.HomeAddressPage.DeliveryAddress',
                  defaultMessage: 'Delivery address',
                })}
              </SubtitleSmall>
              <SubtitleMedium data-cy="home-address-shipping">
                {shippingAddressLine1}
              </SubtitleMedium>
            </Box>
            <KDSIcons.Icons.CheckmarkFilled color={theme.colors.successful} />
          </Box>
        </>
      )}

      <Button
        type="button"
        disabled={!isValid}
        trackName="move-home-address"
        sx={{ mb: 4 }}
        data-cy="home-address-cta-button"
        onClick={handleSubmit(submitForm)}
      >
        {intl.formatMessage({
          id: 'Registration.HomeAddressPage.CTA',
          defaultMessage: 'Next',
        })}
      </Button>
      <SecurityAssurance />
    </form>
  );
};
