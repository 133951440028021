/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { theme } from 'theme';
import { Flex } from 'theme-ui';

import { track } from '../analytics/analytics';
import { P } from './Typography';

const useStyles = makeStyles({
  touchReset: {
    padding: '4px 8px',
    fontSize: '0.625rem',
  },
});

export const ValueCopier = ({
  label,
  value,
  className,
  trackName,
  ...props
}: {
  label?: string;
  value: string;
  className?: string;
  trackName?: string;
}) => {
  const intl = useIntl();

  const classes = useStyles();

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleCopyValue = () => {
    track({
      event: 'Copied Value Copier',
      properties: {
        label,
        trackName,
      },
    });
    if (window?.navigator && value) {
      window.navigator.clipboard.writeText(value);
    }
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2222);
  };

  return (
    <div {...props} className={className}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div onClick={handleTooltipOpen}>
          <P sx={{ mb: 1 }}>{label}</P>
          <Flex
            onClick={handleCopyValue}
            sx={{
              background: '#EEF5F9',
              borderRadius: 4,
              p: 2,
              fontWeight: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
              overflow: 'auto',
              border: '1px solid grey',
              borderColor: theme.colors.greyLight,
            }}
          >
            {value}
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={intl.formatMessage({
                id: 'ValueCopier.Copied',
                defaultMessage: 'Copied!',
              })}
              placement="left"
              classes={{ touch: classes.touchReset }}
            >
              <FileCopyOutlinedIcon
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '0px',
                  background: '#EEF5F9',
                }}
                color="primary"
              />
            </Tooltip>
          </Flex>
        </div>
      </ClickAwayListener>
    </div>
  );
};
