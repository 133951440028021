/** @jsxImportSource theme-ui */
import { ReactNode } from 'react';

import Box from '@material-ui/core/Box';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import { Paragraph } from './';

export type AlertProps = {
  background?: string;
  className?: string;
  color?: string;
  content?: string;
  children?: ReactNode;
  icon?: ReactNode;
  alignTop?: boolean;
};

export const Alert = ({
  color = '#00579B',
  background = '#F1F5F8',
  children,
  className,
  content,
  icon,
  alignTop = false,
  ...props
}: AlertProps) => {
  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        alignItems: alignTop ? 'start' : 'center',
        borderRadius: '6px',
        padding: '16px',
        background,
        color,
      }}
      {...props}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {icon ? icon : <InfoOutlined />}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: '16px',
        }}
      >
        {children ? (
          children
        ) : (
          <Paragraph sx={{ color, margin: '0px' }}>{content}</Paragraph>
        )}
      </Box>
    </Box>
  );
};
