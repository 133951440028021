/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import { useNavigate } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';
import { SessionRefreshModal } from 'auth/components';
import { Button, Card, LayoutBasic, Paragraph, TitleLarge } from 'components';
import { HelpAndAccessibility } from 'support/components';

export const RegistrationAccountErrorPage = ({ path }: { path: string }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <LayoutBasic pageName="RegistrationAccountError" chat={false}>
      <SessionRefreshModal />
      <Card>
        <KDSIcons.Spot.Stop />
        <TitleLarge sx={{ mb: 4 }}>
          {intl.formatMessage({
            id: 'Registration.AccountErrorPage.Title',
            defaultMessage: 'Something went wrong...',
          })}
        </TitleLarge>
        <Paragraph sx={{ mb: 4 }}>
          {intl.formatMessage({
            id: 'Registration.AccountErrorPage.Content',
            defaultMessage:
              'Try again in a few minutes. Your progress is saved, so all you have to do is log in, and we’ll handle the rest!',
          })}
        </Paragraph>
        <Button trackName="go-back-home" onClick={() => navigate('/login')}>
          {intl.formatMessage({
            id: 'Registration.AccountErrorPage.CTA',
            defaultMessage: 'Go back home',
          })}
        </Button>
      </Card>
      <HelpAndAccessibility />
    </LayoutBasic>
  );
};
