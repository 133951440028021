import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectRewardsState = (state: RootState) => state.rewards;

export const selectRewards = createSelector(
  selectRewardsState,
  (state) => state?.data,
);

export const selectRewardsLoadable = createSelector(
  selectRewardsState,
  (state) => state?.loadable,
);

export const selectCashOutLoadable = createSelector(
  selectRewardsState,
  (state) => state.loadableCashOut,
);

export const selectRewardsBalance = createSelector(
  selectRewards,
  (state) => state?.balance,
);

export const selectRewardsAllTimeBalance = createSelector(
  selectRewards,
  (state) => state?.lifetime_total,
);

export const selectRewardsPercentageRate = createSelector(
  selectRewards,
  (state) => state?.rate_percentage,
);

export const selectRewardsLoading = createSelector(
  selectRewardsLoadable,
  (loadable) => loadable?.loading,
);

export const selectCashOutLoading = createSelector(
  selectCashOutLoadable,
  (loadable) => loadable.loading,
);

export const selectCashOutSuccess = createSelector(
  selectCashOutLoadable,
  (loadable) => loadable.success,
);
