import { createSelector } from '@reduxjs/toolkit';
import { selectAccountsAllIsLoaded } from 'accounts/store/selectors';
import { RootState } from 'store';

export const selectAuthState = (state: RootState) => state.auth;
export const selectState = (state: RootState) => state;

export const selectAuthIsForcedLogout = createSelector(
  selectAuthState,
  (state) => state && state.forcedLogout,
);
export const selectAuthIsSessionRefreshError = createSelector(
  selectAuthState,
  (state) => state && state.sessionRefreshError,
);
export const selectAuthTimeout = createSelector(
  selectAuthState,
  (state) => state && state.timeout,
);

export const selectAuthIsAuthenticated = createSelector(
  selectAuthTimeout,
  (timeout) => {
    if (!timeout) return false;
    return timeout - Date.now() > 0;
  },
);

export const selectIsAuthenticationReady = createSelector(
  [selectAuthIsAuthenticated, selectAccountsAllIsLoaded],
  (authIsAuthenticated, accountsAllIsLoaded) =>
    authIsAuthenticated && accountsAllIsLoaded,
);
