import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  Loadable,
  createDefaultLoadable,
  createErrorLoadable,
  createLoadingLoadable,
  createSuccessLoadable,
} from '../../utility/loadable';
import { Rewards } from '../models/rewards';
import { RewardsConfigurationPayload } from '../models/rewards-configuration';

export interface RewardsState {
  data: Rewards | null;
  configuration: RewardsConfigurationPayload | null;
  loadable: Loadable;
  loadableCashOut: Loadable;
}

const initialState: RewardsState = {
  data: null,
  configuration: null,
  loadable: createDefaultLoadable(),
  loadableCashOut: createDefaultLoadable(),
};

const rewardsSlice = createSlice({
  name: 'rewards',
  initialState: initialState,
  reducers: {
    getRewardsRequest: (state) => {
      state.loadable = createLoadingLoadable();
    },
    getRewardsSuccess: (state, action: PayloadAction<Rewards>) => {
      state.loadable = createSuccessLoadable();
      state.data = action.payload;
    },
    getRewardsError: (state) => {
      state.loadable = createErrorLoadable('Failed to get rewards info');
    },
    getRewardsConfigurationRequest: (state) => {
      state.loadable = createLoadingLoadable();
    },
    getRewardsConfigurationSuccess: (
      state,
      action: PayloadAction<RewardsConfigurationPayload>,
    ) => {
      state.loadable = createSuccessLoadable();
      state.configuration = action.payload;
    },
    getRewardsConfigurationError: (state) => {
      state.loadable = createErrorLoadable(
        'Failed to get rewards configuration',
      );
    },
    confirmCashOutReset: (state) => {
      state.loadableCashOut = createDefaultLoadable();
    },
    confirmCashOutRequest: (state) => {
      state.loadableCashOut = createLoadingLoadable();
    },
    confirmCashOutSuccess: (state) => {
      state.loadableCashOut = createSuccessLoadable();
    },
    confirmCashOutError: (state) => {
      state.loadableCashOut = createErrorLoadable('Failed to cash out rewards');
    },
    noop: (state) => state,
  },
});

export const rewardsActions = rewardsSlice.actions;
export default rewardsSlice.reducer;
