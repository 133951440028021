import { InputValidationMsg } from 'components/forms/useValidationMsg';
import { validateEmail } from 'utility/validateEmail';
import {
  isPasswordRequirementsMet,
  validatePasswordComplexity,
} from 'utility/validatePassword';
import { z } from 'zod';

export const zodFormRegistrationIdentity = z
  .object({
    email: z
      .string()
      .min(1, {
        message: InputValidationMsg.FieldRequired,
      })
      .max(64, {
        message: InputValidationMsg.EmailTooLong,
      })
      .refine((value) => validateEmail(value), {
        message: InputValidationMsg.EmailInvalid,
      }),
    'new-password': z
      .string()
      .min(1, { message: InputValidationMsg.FieldRequired }),
  })
  .refine(
    (data) =>
      isPasswordRequirementsMet(
        validatePasswordComplexity(data['new-password']),
      ),
    {
      path: ['new-password'],
    },
  );

export type IdentityFormSchema = z.infer<typeof zodFormRegistrationIdentity>;
