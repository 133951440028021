import React, { CSSProperties, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { useNavigate } from '@reach/router';
import { GetTheAppModal } from 'OnboardingChecklistDynamic/components/GetTheAppModal';
import {
  OnboardingChecklistDynamicItemKeys,
  useChecklistDismissMutation,
  useChecklistGetQuery,
} from 'apis/onboardingChecklistDynamicApi';
import { selectTheme } from 'appState/appState.slice';
import { KDSIcons } from 'assets/images/kds_icons';
import { ReactComponent as RightArrow } from 'assets/images/rightArrow.svg';
import clsx from 'clsx';
import { LocaleContext } from 'components';
import { ButtonTw } from 'components/ButtonTw';
import ButtonUnstyledTw from 'components/ButtonUnstyledTw';
import {
  Paragraph,
  ParagraphSmall,
  TitleMedium,
} from 'components/TypographyTw';
import { HISAFeatureFlags } from 'hisa/hisaFeatureFlags';
import { useIcons } from 'hooks/useIcon';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { twMerge } from 'tailwind-merge';

export const OnboardingChecklistDynamic = () => {
  const intl = useIntl();
  const themeName = useSelector(selectTheme);
  const {
    Icons: { Question },
  } = useIcons();

  const { data: checklistData } = useChecklistGetQuery();
  const [dismissChecklist] = useChecklistDismissMutation();
  const { deepLinks } = React.useContext(LocaleContext);

  const [appDownloadModalOpen, appDownloadModalOpenSet] = useState(false);
  const [lastClickedChecklistItemKey, lastClickedChecklistItemKeySet] =
    useState<OnboardingChecklistDynamicItemKeys>('HasSeenCreditScore');

  const navigate = useNavigate();
  const isHisaReleased = useFlags()[HISAFeatureFlags.EnableOnboardingFlow];

  if (checklistData === undefined) {
    return null;
  }

  const checklistItems = checklistData.line_items;
  const completedItems = checklistItems.filter((c) => c.completed);

  const handleItemClick = (key: OnboardingChecklistDynamicItemKeys) => {
    switch (key) {
      case 'IsAccountCreated':
        window.open(deepLinks.KOHO.VirtualCardFAQ, '_blank');
        break;
      case 'IsCardAddedToWallet':
        window.open(deepLinks.KOHO.CardsFAQ, '_blank');
        break;
      case 'HasMadePurchase':
        window.open(deepLinks.KOHO.CardsFAQ, '_blank');
        break;
      case 'IsAccountFunded':
      case 'IsReferralRewardUnlocked':
        navigate('/load');
        break;
      case 'IsIdentityVerified':
        navigate('/kyc/entry');
        break;
      case 'IsEarningInterest':
        if (isHisaReleased) {
          navigate('/hisa/registration');
        } else {
          lastClickedChecklistItemKeySet(key);
          appDownloadModalOpenSet(true);
        }
        break;
      case 'IsCreditBuildingRegistered':
        navigate('/credit-building');
        break;
      case 'HasSeenCreditScore':
      case 'HasSetUtilizationRate':
      case 'IsSavingGoal':
        lastClickedChecklistItemKeySet(key);
        appDownloadModalOpenSet(true);
        break;
      default:
        break;
    }
  };

  const completedCheck =
    themeName === 'light' ? (
      <Checkbox isCompleted={true} />
    ) : (
      <KDSIcons.Icons.CheckmarkFilled className="self-center text-success-300" />
    );

  const incompleteCheck =
    themeName === 'light' ? (
      <Checkbox isCompleted={false} />
    ) : (
      <CircleUnchecked className="text-grey-200 ml-[-2px] self-center" />
    );

  return (
    <Box className="legacy:p-4 legacy:pb-6 p-8 legacy:rounded-lg rounded-xl mb-6 legacy:[box-shadow:3px_6px_15px_0px_rgba(0,0,0,0.10)] light:bg-white">
      {themeName === 'light' ? (
        <Paragraph className="font-medium text-xl mb-1">
          {checklistData.header}
        </Paragraph>
      ) : (
        <TitleMedium className="mt-0 mb-2">{checklistData.header}</TitleMedium>
      )}
      <Paragraph className="mt-0 light:text-sm light:text-grey-400 light:mb-8">
        {checklistData.subheader}
      </Paragraph>
      <Box className="h-1 w-full legacy:bg-grey-200 bg-grey-75 legacy:rounded-[4px] rounded-full mb-6">
        <Box
          className="w-[var(--progress-width)] h-[inherit] legacy:rounded-lg rounded-full legacy:bg-success-300 bg-primary-200"
          style={
            {
              '--progress-width': `${
                (completedItems.length / checklistItems.length) * 100
              }%`,
            } as CSSProperties
          }
        ></Box>
      </Box>
      <Box flexDirection="column" className="flex legacy:gap-4">
        {checklistData.line_items.map((item, index) => (
          <ButtonUnstyledTw
            key={index}
            onClick={() => handleItemClick(item.key)}
            disabled={item.completed && !item.is_info_item}
            data-cy={`OnboardingChecklistDynamic-item-${item.key}`}
            trackName={`dynamic-onboarding-checklist-item-${item.key}`}
            className="w-full"
          >
            <Box className="flex flex-row justify-between items-center gap-4 light:py-3 light:border-b light:border-grey-75 light:pr-3">
              {item.completed ? completedCheck : incompleteCheck}
              <ParagraphSmall
                className={twMerge(
                  clsx(
                    'my-0 text-grey-400 light:font-medium light:text-grey-500 light:text-base',
                    item.completed && 'text-grey-300 light:text-grey-500',
                  ),
                )}
              >
                {item.label}
              </ParagraphSmall>
              <Box className="ml-auto legacy:text-primary-300 text-grey-300">
                {item.is_info_item ? (
                  <Question />
                ) : !item.completed ? (
                  <RightArrow />
                ) : null}
              </Box>
            </Box>
          </ButtonUnstyledTw>
        ))}
      </Box>
      {checklistData.status === 'completed' ? (
        <ButtonTw
          trackName="dismiss-complete-checklist"
          className="mt-4"
          onClick={() => dismissChecklist()}
        >
          {intl.formatMessage({
            id: 'OnboardingChecklistDynamic.Completed',
            defaultMessage: 'Thanks KOHO',
          })}
        </ButtonTw>
      ) : null}
      {checklistData.status === 'dismissible' ? (
        <ButtonTw
          trackName="dismiss-incomplete-checklist"
          variant="tertiary"
          className="mt-4"
          onClick={() => dismissChecklist()}
        >
          {intl.formatMessage({
            id: 'OnboardingChecklistDynamic.Dismiss',
            defaultMessage: 'Dismiss',
          })}
        </ButtonTw>
      ) : null}

      <GetTheAppModal
        open={appDownloadModalOpen}
        checklistItemKey={lastClickedChecklistItemKey}
        onClose={() => appDownloadModalOpenSet(false)}
      />
    </Box>
  );
};

const Checkbox = ({ isCompleted }: { isCompleted: boolean }) => {
  return (
    <div
      className={twMerge(
        clsx(
          'rounded-full h-6 w-6 border border-grey-100 flex items-center justify-center',
          isCompleted && 'bg-primary-300 border-none',
        ),
      )}
    >
      <KDSIcons.Icons.Check className="text-white" />
    </div>
  );
};
