/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, TitleLarge } from 'components';
import { Paragraph } from 'theme-ui';

import { track } from '../../analytics/analytics';
import { Button } from '../../components/Button';
import {
  selectAuthIsAuthenticated,
  selectAuthIsForcedLogout,
  selectAuthTimeout,
} from '../store/selectors';
import { authActions } from '../store/slice';

const SESSION_MODAL_TIMEOUT = 30 * 1000;

export const SessionRefreshModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActionTimeout, setIsActionTimeout] = useState(false);
  const [modalTimeout, setModalTimeout] = useState<number>(0);
  const [actionTimeout, setActionTimeout] = useState<number>(0);

  const timeout = useSelector(selectAuthTimeout);
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const isForcedLogout = useSelector(selectAuthIsForcedLogout);

  useEffect(() => {
    if (timeout && isAuthenticated && !isForcedLogout) {
      const modalTimeoutTimer = window.setTimeout(() => {
        setIsModalOpen(true);
        track({
          event: 'Viewed',
          properties: { name: 'Refresh Modal Session Almost Ended' },
        });
      }, timeout - Date.now());
      setModalTimeout(modalTimeoutTimer);
    }
  }, [timeout, isForcedLogout, isAuthenticated]);

  useEffect(() => {
    if (isModalOpen) {
      const actionTimeoutTimer = window.setTimeout(
        () => setIsActionTimeout(true),
        SESSION_MODAL_TIMEOUT,
      );
      setActionTimeout(actionTimeoutTimer);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isActionTimeout) {
      dispatch(authActions.deleteSessionForced());
      track({
        event: 'Viewed',
        properties: { name: 'Refresh Modal Session Ended Forcefully' },
      });
    }
  }, [isActionTimeout, dispatch]);

  const refreshClicked = () => {
    dispatch(authActions.refreshSession());

    clearTimeout(actionTimeout);
    clearTimeout(modalTimeout);
    handleOnClose();
  };
  const handleOnClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleOnClose}
      PaperProps={{
        style: {
          maxWidth: '624px',
        },
      }}
      data-cy="session-refresh-modal"
    >
      <TitleLarge sx={{ mt: 0, mb: 2 }}>
        {intl.formatMessage({
          id: 'SessionRefreshModal.Title',
          defaultMessage: 'Still using KOHO?',
        })}
      </TitleLarge>

      <Paragraph sx={{ my: 3 }}>
        {intl.formatMessage({
          id: 'SessionRefreshModal.Content',
          defaultMessage:
            'For security purposes, we’ll log you out if you’re inactive for some time.',
        })}
      </Paragraph>
      <Button
        onClick={refreshClicked}
        trackName="Refresh Modal Session Refreshed"
        data-cy="session-refresh-modal-cta"
        type="button"
      >
        {intl.formatMessage({
          id: 'SessionRefreshModal.Button',
          defaultMessage: 'I’m still using KOHO',
        })}
      </Button>
    </Modal>
  );
};
