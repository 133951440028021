import { IBillingProducts } from 'billing/models/BillingProducts';
import { PaymentMethodCardNetwork } from 'billing/models/PaymentMethodCardNetwork';
import { BillingPaymentMethodOrganization } from 'billing/models/PaymentMethodOrganization';

import { rootApi } from './rootApi';

export interface IBillingPaymentMethod {
  id: string;
  organization: BillingPaymentMethodOrganization;
  card_brand?: PaymentMethodCardNetwork;
  last4?: string;
  latest_card: boolean;
}

export interface IBillingPaymentMethods {
  payment_methods: IBillingPaymentMethod[];
}

export interface IBillingSessionData {
  publishable_key: string;
  client_secret: string;
  stripe_account_id: string;
}

export interface IPreferredBillingMethodData {
  identifier: string;
  organization: BillingPaymentMethodOrganization;
  product: IBillingProducts;
  card_brand: PaymentMethodCardNetwork;
  last_four_digits: string;
}

export interface ISetPreferredBillingMethod {
  product: IBillingProducts;
  payment_method_org: BillingPaymentMethodOrganization;
  payment_method_id: string;
}

export const billingApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getBillingMethods: build.query<IBillingPaymentMethods, void>({
      query: () => ({
        url: '1.0/payment-methods/subscription-billing',
        method: 'GET',
      }),
      providesTags: ['BillingMethods'],
    }),
    getPreferredBillingMethods: build.query<
      ({ product: IBillingProducts } & IBillingPaymentMethod)[],
      void
    >({
      query: () => ({
        url: '1.0/subscription-billing/preferred-payment-methods',
        method: 'GET',
      }),
      providesTags: ['PreferredBillingMethods'],
      transformResponse: ({
        data,
      }: {
        data: IPreferredBillingMethodData[];
      }) => {
        return data.map((method) => ({
          id: method.identifier,
          organization: method.organization,
          card_brand: method.card_brand,
          last4: method.last_four_digits,
          latest_card: true,
          product: method.product,
        }));
      },
    }),
    createSetupIntent: build.mutation<IBillingSessionData, void>({
      query: () => ({
        url: '1.0/payment-methods/payment-intent/subscription-billing',
        method: 'POST',
        body: {},
      }),
    }),
    postPreferredMethod: build.mutation<void, ISetPreferredBillingMethod>({
      query: ({ product, payment_method_id, payment_method_org }) => ({
        url: `1.0/subscription-billing/preferred-payment-method`,
        method: 'POST',
        body: {
          product: product,
          payment_method_org: payment_method_org,
          payment_method_id: payment_method_id,
        },
      }),
      invalidatesTags: ['PreferredBillingMethods'],
    }),
  }),
});

export const {
  useGetBillingMethodsQuery,
  useGetPreferredBillingMethodsQuery,
  useCreateSetupIntentMutation,
  usePostPreferredMethodMutation,
} = billingApi;
