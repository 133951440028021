import Box from '@material-ui/core/Box';
import SpinnerCircularProgress from 'components/SpinnerCircularProgress';

const LoadingOverlay = (sx): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        ...sx,
      }}
      data-testid="loading-ui"
    >
      <SpinnerCircularProgress />
    </Box>
  );
};

export default LoadingOverlay;
