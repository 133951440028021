/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { useIntl } from 'react-intl';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface SelectOption {
  value: string;
  label: string;
}

interface CustomInputProps {
  // need to find a nice way to merge this into AutocompleteProps
  onValidChange?: Function;
  options: Array<SelectOption>;
  disableClearable?: boolean;
  value?: SelectOption;
  loading?: any;
  onOpen?: any;
  onClose?: any;
  onChangeText?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadingText?: string;
  noOptionsText?: string;
  renderOption?: (option: any, state: any) => React.ReactNode;
  open?: boolean;
  popupIcon?: React.ReactNode;
}

const useStyles = makeStyles({
  paper: {
    marginBottom: 12,
  },
  popupIndicatorOpen: {
    transform: 'none',
  },
});

export const Select: React.FC<CustomInputProps & TextFieldProps> = ({
  name,
  id,
  onChange = (value: string) => {},
  onBlur = () => {},
  placeholder,
  label,
  fullWidth = true,
  required,
  options,
  value = null,
  onValidChange = () => {},
  disableClearable,
  loading,
  onOpen,
  onClose,
  onChangeText = () => {},
  loadingText,
  noOptionsText,
  renderOption,
  open,
  popupIcon,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [selectValue, setSelectValue] = React.useState<SelectOption | null>(
    value,
  );
  const [isValid, setIsValid] = React.useState<boolean>(false);

  const change = (event: any, changeValue: SelectOption | null) => {
    setIsValid(
      required && changeValue !== null
        ? changeValue && !!changeValue.value
        : true,
    );
    setSelectValue(changeValue);
    onChange(changeValue ? (changeValue.value as any) : null);
  };

  React.useEffect(() => {
    onValidChange(isValid);
  }, [isValid]);

  React.useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const blur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur(event);
  };

  const popupIndicatorOpenStyles = popupIcon
    ? { popupIndicatorOpen: classes.popupIndicatorOpen }
    : null;

  return (
    <Autocomplete
      classes={{ paper: classes.paper, ...popupIndicatorOpenStyles }}
      options={options}
      getOptionLabel={(option: SelectOption) => option.label}
      onChange={change}
      onBlur={blur}
      value={selectValue}
      disableClearable={disableClearable}
      loading={loading}
      onOpen={onOpen}
      onClose={onClose}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      renderOption={renderOption}
      popupIcon={popupIcon ?? <ArrowDropDownIcon />}
      clearText={intl.formatMessage({
        id: 'Select.ClearButton',
        defaultMessage: 'Clear',
      })}
      open={open}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          name={name}
          placeholder={placeholder}
          label={label}
          id={id}
          fullWidth={fullWidth}
          required={required}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
          onChange={onChangeText}
        />
      )}
    />
  );
};
