import { ReactNode, RefAttributes } from 'react';

import { Link as InternalLink, LinkProps } from '@reach/router';
import { track } from 'analytics/analytics';
import { twMerge } from 'tailwind-merge';

interface LinkTWProps {
  children: ReactNode;
  className?: string;
  external?: boolean;
  trackName: string;
}

export const LinkTw = ({
  children,
  className,
  external = false,
  target = '_blank',
  to,
  trackName,
  ...props
}: RefAttributes<HTMLAnchorElement> & LinkProps<{}> & LinkTWProps) => {
  const handleClick = () => {
    track({
      event: `Clicked`,
      properties: {
        element: 'a',
        name: trackName,
        to: to,
      },
    });
  };

  const classes =
    'decoration-0 duration-300 font-black leading-5 text-base text-primary-300 hover:text-primary-400 hover:underline hover:underline-offset-1';

  if (external) {
    return (
      <a
        className={twMerge(classes, className)}
        href={to}
        onClick={handleClick}
        target={target}
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <InternalLink
      className={twMerge(classes, className)}
      onClick={handleClick}
      to={to}
      {...props}
    >
      {children}
    </InternalLink>
  );
};
