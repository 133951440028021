import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Tooltip } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Box, ButtonUnstyled, Paragraph } from 'components';
import { theme } from 'theme';

type LineCopierProps = {
  label: string;
  value: string;
  sx?: Record<string, string>;
};

export const LineCopier = ({
  label,
  value,
  ...props
}: Readonly<LineCopierProps>) => {
  const intl = useIntl();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        flexDirection: 'row',
        gap: 3,
        mb: 3,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}
      {...props}
    >
      <Box>
        <Paragraph sx={{ fontWeight: 700 }}>{label}</Paragraph>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Paragraph sx={{ color: theme.colors.midGrey }}>{value}</Paragraph>
        <ButtonUnstyled
          onClick={() => {
            setTooltipOpen(true);
            window.navigator.clipboard.writeText(value);
            setTimeout(() => setTooltipOpen(false), 2222);
          }}
        >
          <Tooltip
            PopperProps={{ disablePortal: true }}
            onClose={() => setTooltipOpen(false)}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={intl.formatMessage({
              id: 'ValueCopier.Copied',
              defaultMessage: 'Copied!',
            })}
            placement="top"
          >
            <FileCopyOutlinedIcon
              sx={{
                ml: 2,
                my: 2,
                cursor: 'pointer',
                color: theme.colors.midGrey,
              }}
            />
          </Tooltip>
        </ButtonUnstyled>
      </Box>
    </Box>
  );
};
