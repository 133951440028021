/** @jsxImportSource theme-ui */
import React from 'react';

import { KhInputText, KhInputTextProps } from './KhInputText';

export const KhInputEmail = React.forwardRef<
  HTMLInputElement,
  KhInputTextProps
>((props: KhInputTextProps, ref) => {
  return <KhInputText type="email" ref={ref} {...props} />;
});
