/** @jsxImportSource theme-ui */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { InputAdornment } from '@material-ui/core';
import { track } from 'analytics/analytics';
import { useGetAffiliatesQuery } from 'apis/affiliates';
import { ReferralRequest } from 'apis/registration';
import axios from 'axios';
import { SpinnerCircularProgress } from 'components';
import {
  InputValidationMsg,
  useValidationMsg,
} from 'components/forms/useValidationMsg';
import { KhInputText } from 'components/inputs/KhInputText';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { InvalidReferralReasons, ReferralsValidationData } from 'models';
import { RegistrationFeatureFlags } from 'registration/models/RegistrationFeatureFlags';

export const RegistrationReferralCodeInput = ({
  onValidChanged,
  onReferralRequestUpdated,
  referralCodePreSet,
}: {
  onValidChanged: (isValid: boolean) => void;
  onReferralRequestUpdated: (referralRequest: ReferralRequest | null) => void;
  referralCodePreSet?: string;
}) => {
  const intl = useIntl();

  const [referralCodeErrMessage, setReferralCodeErrMessage] =
    React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState(false);
  const { data: affiliatesData } = useGetAffiliatesQuery();
  const isAffiliateRegistrationEnabled =
    useFlags()[RegistrationFeatureFlags.EnableAffiliateRegistration];

  const {
    register,

    formState: { touchedFields },
  } = useForm<{ referralCode: string }>({
    defaultValues: {
      referralCode: referralCodePreSet || '',
    },
  });

  const verifyReferralCode = React.useCallback(
    (referralCode: string) => {
      setIsLoading(true);

      axios
        .get<ReferralsValidationData>(
          `${
            import.meta.env.VITE_GATEWAY_API
          }referrals/code/verify/${referralCode}`,
        )
        .then((response) => {
          if (response.data.invalid_reason) {
            switch (response.data.invalid_reason) {
              case InvalidReferralReasons.EXHAUSTED: {
                setReferralCodeErrMessage(
                  InputValidationMsg.ReferralCodeExhausted,
                );

                break;
              }

              case InvalidReferralReasons.DISABLED: {
                setReferralCodeErrMessage(
                  InputValidationMsg.ReferralCodeDisabled,
                );

                break;
              }

              default: {
                setReferralCodeErrMessage(InputValidationMsg.UnknownErr);

                break;
              }
            }
          } else {
            onReferralRequestUpdated({
              campaign: referralCode,
              source: response.data.type,
            });

            setReferralCodeErrMessage('');
            onValidChanged(true);
            return;
          }
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            setReferralCodeErrMessage(InputValidationMsg.InvalidCode);
          } else {
            setReferralCodeErrMessage(InputValidationMsg.UnknownErr);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [onReferralRequestUpdated, onValidChanged],
  );

  const [referralErrorTracked, setReferralErrorTracked] = React.useState(false);

  if (referralCodeErrMessage && !referralErrorTracked) {
    track({
      event: 'Viewed',
      properties: {
        name: 'referral-code-input-error',
        message: referralCodeErrMessage,
      },
    });
    setReferralErrorTracked(true);
  }

  React.useEffect(() => {
    if (referralCodePreSet) {
      verifyReferralCode(referralCodePreSet);
    }
  }, [referralCodePreSet, verifyReferralCode]);

  const onChangeReferralCode = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onReferralRequestUpdated(null);
    onValidChanged(false);
    setReferralCodeErrMessage('');

    const value = event.target.value;

    if (!value) {
      // Referral code is optional, so valid with no value
      onValidChanged(true);

      return;
    }

    if (value.length < 5) {
      setReferralCodeErrMessage(InputValidationMsg.InvalidCode);
      return;
    }

    verifyReferralCode(value);
  };

  return (
    <KhInputText
      {...register('referralCode')}
      label={intl.formatMessage({
        id: 'ReferralCode.Label',
        defaultMessage: 'Referral Code (optional)',
      })}
      trackName="referral-code-input"
      error={!!referralCodeErrMessage && touchedFields.referralCode}
      helperText={useValidationMsg(
        touchedFields.referralCode && referralCodeErrMessage,
      )}
      onChange={onChangeReferralCode}
      InputProps={{
        endAdornment: isLoading && (
          <InputAdornment position="end">
            <SpinnerCircularProgress />
          </InputAdornment>
        ),
      }}
      data-cy="referral-code-input"
      disabled={
        isAffiliateRegistrationEnabled && !!affiliatesData?.referral_code
      }
    />
  );
};
