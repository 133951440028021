import { ReactNode } from 'react';

import { navigate } from '@reach/router';
import { Button } from 'components';
import { theme } from 'theme';

type Props = {
  children: ReactNode;
  dataCy: string;
  trackName: string;
};

export const BackToTransactions = ({ children, dataCy, trackName }: Props) => {
  return (
    <Button
      sx={{
        mt: 16,
        mb: 48,
        py: 12,
        color: theme.colors.blueDark,
        fontWeight: 700,
      }}
      onClick={() =>
        navigate('/transactions', { state: { showWelcomeModal: false } })
      }
      data-cy={dataCy}
      trackName={trackName}
      variant="clear"
    >
      {children}
    </Button>
  );
};
