import { useIntl } from 'react-intl';

import { RouteComponentProps, navigate } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';

import { ButtonTw } from '../components/ButtonTw';
import { Paragraph, TitleLarge } from '../components/TypographyTw';

export const PasswordResetLinkExpired = (props: RouteComponentProps) => {
  const intl = useIntl();
  return (
    <>
      <KDSIcons.Spot.Wait />
      <TitleLarge>
        {intl.formatMessage({
          id: 'Global.OopsErrorMessage',
          defaultMessage: 'Oops! We’re having issues',
        })}
      </TitleLarge>
      <Paragraph>
        {intl.formatMessage({
          id: 'LinkExpired.Content',
          defaultMessage:
            'Sorry, the verification link expired or something went wrong on our end. Please try again.',
        })}
      </Paragraph>
      <ButtonTw type="button" onClick={() => navigate('/password-reset/email')}>
        {intl.formatMessage({
          id: 'Global.Button.ResendEmail',
          defaultMessage: 'Resend email',
        })}
      </ButtonTw>
    </>
  );
};
