/** @jsxImportSource theme-ui */
import { Fragment, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Close from '@material-ui/icons/Close';
import { KDSIcons } from 'assets/images/kds_icons';
import { Flex } from 'theme-ui';

import { selectAccountPersonalAccountGroupId } from '../accounts/store/selectors';
import { ReactComponent as TadaIcon } from '../assets/images/transactions/tada.svg';
import {
  Box,
  Button,
  ButtonUnstyled,
  NumberExtraLarge,
  Paragraph,
  TitleLarge,
} from '../components';
import LoadingCentered from '../components/Loading/Centered';
import { Money } from '../models/Money';
import {
  selectCashOutLoading,
  selectCashOutSuccess,
  selectRewardsAllTimeBalance,
  selectRewardsBalance,
  selectRewardsLoading,
} from '../rewards/store/selectors';
import { rewardsActions } from '../rewards/store/slice';
import { theme } from '../theme';
import { transactionsActions } from '../transactions/store/slice';
import { LocaleContext } from './I18NContextProvider';

export const CashOut = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const context = useContext(LocaleContext);

  const isLoading = useSelector(selectRewardsLoading);
  const balanceCurrent = useSelector(selectRewardsBalance);
  const balanceAllTimeTotal = useSelector(selectRewardsAllTimeBalance);

  const [isModal, setModal] = useState(false);
  const balance = new Money(balanceCurrent || 0);
  const personalAccountIdentifier = useSelector(
    selectAccountPersonalAccountGroupId,
  );

  useEffect(() => {
    if (personalAccountIdentifier) {
      dispatch(rewardsActions.getRewardsRequest());
      dispatch(rewardsActions.getRewardsConfigurationRequest());
    }
  }, [dispatch, context.apiLanguageHeader, personalAccountIdentifier]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = (isCashOut: boolean) => {
    setModal(false);
    dispatch(rewardsActions.confirmCashOutReset());
    if (isCashOut) {
      dispatch(rewardsActions.getRewardsRequest());
      dispatch(rewardsActions.getRewardsConfigurationRequest());
      dispatch(transactionsActions.getTransactionsRequest());
    }
  };

  if (isLoading) return <LoadingCentered />;

  return (
    <Fragment>
      <Box variant="shadow">
        <Flex
          sx={{
            padding: `${theme.space[3]}px ${theme.space[4]}px`,
            flexDirection: 'column',
            gap: theme.space[3],
          }}
        >
          <Flex
            sx={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Paragraph sx={{ fontWeight: 700, mb: 1, mt: 1 }}>
              {intl.formatMessage({
                id: 'CashOut.Earned',
                defaultMessage: 'Earned',
              })}
            </Paragraph>
            <NumberExtraLarge>
              {balanceCurrent &&
                context.intlFormatMoney(balanceCurrent).format()}
            </NumberExtraLarge>
          </Flex>
          <Button
            disabled={balance.lteq(0)}
            onClick={openModal}
            data-cy="btn-cash-out"
          >
            {intl.formatMessage({
              id: 'CashOut.CashOutRewards',
              defaultMessage: 'Cash out',
            })}
          </Button>
          <div
            sx={{
              /* Divider component has built in margins */
              backgroundColor: theme.colors.greyLight,
              width: '100%',
              height: '1px',
            }}
          />
          <Flex
            sx={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Paragraph sx={{ fontWeight: 700, my: 0 }}>
              {intl.formatMessage({
                id: 'CashOut.AllTimeTotal',
                defaultMessage: 'All-time total',
              })}
            </Paragraph>
            <Paragraph sx={{ fontWeight: 600, fontFamily: 'numbers', my: 0 }}>
              {balanceAllTimeTotal &&
                context.intlFormatMoney(balanceAllTimeTotal).format()}
            </Paragraph>
          </Flex>
        </Flex>
      </Box>

      <CashOutModal open={isModal} handleClose={() => closeModal(true)} />
    </Fragment>
  );
};

const CashOutModal = ({
  handleClose,
  open,
}: {
  handleClose: any;
  open: boolean;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isFinal, setIsFinal] = useState(false);
  const isLoading = useSelector(selectCashOutLoading);
  const isSuccess = useSelector(selectCashOutSuccess);
  const balanceCurrent = useSelector(selectRewardsBalance);
  const context = useContext(LocaleContext);

  const balance = new Money(balanceCurrent || 0);
  const intlFormatBalanceCurrent =
    balanceCurrent && context.intlFormatMoney(balanceCurrent).format();

  const onHandleClose = (isCashOut: boolean): void => {
    setIsFinal(false);
    handleClose(isCashOut);
  };

  const onHandleSubmit = (): void => {
    dispatch(rewardsActions.confirmCashOutRequest());
    setIsFinal(true);
  };

  const cashOutTransactionDisplay = () => {
    if (isSuccess) {
      return {
        title: (
          <Flex sx={{ flexFlow: 'column', width: '400px' }}>
            <TadaIcon />
            <div>
              {intl.formatMessage({
                id: 'CashOut.Success',
                defaultMessage: 'Success!',
              })}
            </div>
          </Flex>
        ),
        content: intl.formatMessage(
          {
            id: 'CashOut.PromotionAdded',
            defaultMessage: "We've added {balance} to your Spendable.",
          },
          {
            balance: intlFormatBalanceCurrent,
          },
        ),
        buttons: (
          <Flex sx={{ flexFlow: 'column', width: '100%' }}>
            <Button
              disabled={isLoading || balance.lteq(0)}
              onClick={() => onHandleClose(true)}
            >
              {intl.formatMessage({
                id: 'CashOut.Okay',
                defaultMessage: 'Okay, got it',
              })}
            </Button>
          </Flex>
        ),
      };
    }
    return {
      title: (
        <Flex sx={{ flexFlow: 'column', width: '400px' }}>
          <KDSIcons.Spot.Wait />
          <div>
            {intl.formatMessage({
              id: 'CashOut.Oops',
              defaultMessage: 'Oops!',
            })}
          </div>
        </Flex>
      ),
      content: intl.formatMessage({
        id: 'CashOut.OopsErrorMessage',
        defaultMessage: 'Oops! We could not cash out your cash back.',
      }),
      buttons: (
        <Flex sx={{ flexFlow: 'column', width: '100%' }}>
          <Button
            disabled={isLoading || balance.lteq(0)}
            onClick={() => onHandleClose(true)}
          >
            {intl.formatMessage({
              id: 'CashOut.TryAgain',
              defaultMessage: 'Try again',
            })}
          </Button>
        </Flex>
      ),
    };
  };

  const map = new Map([
    [
      true,
      {
        ...cashOutTransactionDisplay(),
      },
    ],
    [
      false,
      {
        title: intl.formatMessage({
          id: 'CashOut.CashOut',
          defaultMessage: 'Cash out your cash back?',
        }),
        content: intl.formatMessage(
          {
            id: 'CashOut.AddedSpenableBalance',
            defaultMessage:
              'We’ll add the {balance} to your Spendable balance.',
          },
          {
            balance: intlFormatBalanceCurrent,
          },
        ),
        buttons: (
          <Flex sx={{ gap: theme.space[3], width: '400px' }}>
            <Flex sx={{ width: '50%', flexFlow: 'column' }}>
              <Button
                disabled={isLoading}
                variant="secondary"
                onClick={() => onHandleClose(false)}
              >
                {intl.formatMessage({
                  id: 'CashOut.Nevermind',
                  defaultMessage: 'Nevermind',
                })}
              </Button>
            </Flex>
            <Flex sx={{ width: '50%', flexFlow: 'column' }}>
              <Button
                disabled={isLoading || balance.lteq(0)}
                onClick={onHandleSubmit}
              >
                {intl.formatMessage({
                  id: 'CashOut.Yes',
                  defaultMessage: 'Yes',
                })}
              </Button>
            </Flex>
          </Flex>
        ),
      },
    ],
  ]);

  return (
    <Dialog onClose={handleClose} aria-labelledby="koho-cashout" open={open}>
      {isLoading ? (
        <LoadingCentered />
      ) : (
        <Fragment>
          <DialogTitle
            disableTypography
            id="koho-cashout"
            sx={{
              pt: theme.space[3] + 'px',
              px: theme.space[3] + 'px',
              pb: theme.space[0],
            }}
          >
            <Flex sx={{ justifyContent: 'flex-end' }}>
              <ButtonUnstyled
                onClick={handleClose}
                aria-label={intl.formatMessage({
                  id: 'CashOut.CloseButton.AriaLabel',
                  defaultMessage: 'Close modal',
                })}
              >
                <Close style={{ color: '#373E4D' }} />
              </ButtonUnstyled>
            </Flex>
            <TitleLarge sx={{ p: theme.space[0], m: theme.space[0] }}>
              {map.get(isFinal)?.title}
            </TitleLarge>
          </DialogTitle>
          <DialogContent
            sx={{ py: theme.space[2] + 'px', px: theme.space[3] + 'px' }}
          >
            <DialogContentText
              sx={{ color: theme.colors.text, mb: theme.space[0] }}
              id="koho-cashout"
            >
              {map.get(isFinal)?.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ px: theme.space[3] + 'px', pb: theme.space[4] + 'px' }}
          >
            {map.get(isFinal)?.buttons}
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
};
