/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import { KDSIcons } from 'assets/images/kds_icons';
import { Box, Flex } from 'theme-ui';

export const InlineError = () => {
  const intl = useIntl();
  return (
    <Box
      sx={{
        borderRadius: 'small',
        backgroundColor: 'primaryDisabled',
        color: 'primary',
        p: 3,
        fontSize: 1,
      }}
    >
      <Flex>
        <Box sx={{ minWidth: '24px', mr: 2 }}>
          <Flex sx={{ alignItems: 'center', height: '100%' }}>
            <KDSIcons.Icons.Info color="#00579B" />
          </Flex>
        </Box>
        <Box sx={{ flexBasis: 'auto', flexGrow: 1 }}>
          {intl.formatMessage({
            id: 'Global.OopsInlineErrorMessage',
            defaultMessage:
              'Oops, we ran into an issue. Refresh the page and try again.',
          })}
        </Box>
      </Flex>
    </Box>
  );
};
