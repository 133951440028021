import { useGetHISAStatusQuery } from 'apis/hisa';
import { usePagePermissions } from 'auth/hooks/usePagePermissions';

export const useHISAPath = () => {
  const pagePermissions = usePagePermissions();
  const HISA = useGetHISAStatusQuery();
  let path = '';

  if (pagePermissions.canSeeSavingsPage && HISA.data) {
    path = '/savings';
    if (!HISA.data.is_agreement_signed) {
      path = HISA.data.is_external_account_created
        ? '/hisa/agreement'
        : '/hisa/registration';
    }
  }
  return path;
};
