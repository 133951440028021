/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';

import { H2, Link, P } from '../../components';
import { selectPasswordResetError } from '../store/selectors';

export const PasswordResetErrorPage = (props: RouteComponentProps) => {
  const error = useSelector(selectPasswordResetError);
  const intl = useIntl();
  let errorMessage = (
    <P>
      {intl.formatMessage({
        id: 'ErrorGeneric.Content',
        defaultMessage:
          'Please check your internet connection or try again in a few minutes. If the problem persists, please contact our support team.',
      })}
    </P>
  );
  if (error) {
    errorMessage = (
      <P>
        {intl.formatMessage(error)}.{' '}
        <Link to="/password-reset/email" trackName="password-reset">
          {intl.formatMessage({
            id: 'ErrorGeneric.TryAgain',
            defaultMessage: 'Please try again!',
          })}
        </Link>
      </P>
    );
  }

  return (
    <React.Fragment>
      <KDSIcons.Spot.Wait sx={{ mb: 3 }} />
      <H2>
        {intl.formatMessage({
          id: 'ErrorGeneric.Title',
          defaultMessage: 'Oops! Something went wrong',
        })}
      </H2>
      {errorMessage}
    </React.Fragment>
  );
};
