/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { TextInput, TextInputProps } from '../../components/forms';

interface IEmailInput extends TextInputProps {
  label?: string;
  valid: boolean;
}

export const InputEmail = ({
  label,
  id,
  valid = false,
  ...props
}: IEmailInput) => {
  const intl = useIntl();

  if (!label) {
    label = intl.formatMessage({
      id: 'InputEmail.EmailAddressField',
      defaultMessage: 'Email address',
    });
  }
  return (
    <TextInput
      variant="outlined"
      label={label}
      type={'email'}
      id={id}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {valid && <CheckCircleIcon sx={{ color: 'success' }} />}
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
