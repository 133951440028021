import { track } from 'analytics/analytics';
import axios, { AxiosError } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RootState } from 'store';

import {
  InboundETransfer,
  InboundETransferAcceptDeclineApiError,
  InboundETransferAcceptRequest,
  InboundETransferActions,
  InboundETransferDeclineRequest,
  InboundETransferErrors,
  InboundETransferRetrieveRequest,
  InboundETransferStatus,
} from './slice';

interface RetrieveTransferResponse {
  from_name: string; // "Pink Panther"
  amount_cad_cents: number; // eg 5025,
  question: string; // eg "What colour is the sky"
  memo: string; // eg "Re: Invoice ABC123",
  status: InboundETransferStatus;
}

interface ErrorResponse {
  Code: number;
  Message: {
    error_message: string;
  };
}

function retrieveTransfer(transferId: string) {
  return from(
    axios.post<RetrieveTransferResponse>(
      `${import.meta.env.VITE_GATEWAY_API}etransfers/inbound/retrieve`,
      { interac_ref_id: transferId },
    ),
  );
}

const retrieveTransferEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(InboundETransferActions.retrieveInboundETransferRequest.toString()),
    switchMap((action: { payload: InboundETransferRetrieveRequest }) => {
      const { payload } = action;

      return retrieveTransfer(payload.transferId).pipe(
        tap(() => track({ event: 'Inbound e-Transfer Retrieved' })),
        switchMap((response) => {
          const transfer: InboundETransfer = {
            fromName: response.data.from_name,
            amountCadCents: response.data.amount_cad_cents,
            securityQuestion: response.data.question,
            memo: response.data.memo,
            status: response.data.status,
          };

          if (
            response?.data?.status === InboundETransferStatus.available ||
            response?.data?.status === InboundETransferStatus.accepted
          ) {
            return [
              InboundETransferActions.retrieveInboundETransferSuccess(transfer),
            ];
          } else {
            return [
              InboundETransferActions.retrieveInboundETransferError({
                errorType: InboundETransferErrors.RetrieveTransferUnavailable,
              }),
            ];
          }
        }),
        catchError((error: AxiosError<any>) => {
          tap(() => track({ event: 'Inbound e-Transfer Retrieve Failed' }));

          let errorType;

          switch (error?.response?.status) {
            case 400:
              errorType = InboundETransferErrors.RetrieveBadTransferId;
              break;
            case 409:
              errorType = InboundETransferErrors.RetrieveKycNotCleared;
              break;
            case 503:
              errorType = InboundETransferErrors.ServiceTemporarilyUnavailable;
              break;

            default:
              errorType = InboundETransferErrors.Generic;
              break;
          }
          return of(
            InboundETransferActions.retrieveInboundETransferError({
              errorType,
            }),
          );
        }),
      );
    }),
  );
};

function declineTransfer(transferId: string, securityAnswer: string) {
  return from(
    axios.post(
      `${import.meta.env.VITE_GATEWAY_API}etransfers/inbound/decline`,
      { interac_ref_id: transferId, security_answer: securityAnswer },
    ),
  );
}

const declineTransferEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(InboundETransferActions.declineInboundETransferRequest.toString()),
    switchMap((action: { payload: InboundETransferDeclineRequest }) => {
      const { payload } = action;

      return declineTransfer(payload.transferId, payload.answer).pipe(
        tap(() => track({ event: 'Inbound e-Transfer Declined' })),
        switchMap(() => {
          return [InboundETransferActions.declineInboundETransferSuccess()];
        }),
        catchError((error: AxiosError<ErrorResponse>) => {
          tap(() => track({ event: 'Inbound e-Transfer Decline Failed' }));
          return of(
            InboundETransferActions.declineInboundETransferError({
              status: error.response?.status,
              message: error.response?.data?.Message?.error_message,
            }),
          );
        }),
      );
    }),
  );
};

function acceptTransfer(
  transferId: string,
  securityAnswer: string,
  authenticationMethod: string,
) {
  return from(
    axios.post(`${import.meta.env.VITE_GATEWAY_API}etransfers/inbound/accept`, {
      interac_ref_id: transferId,
      security_answer: securityAnswer,
      authentication_method: authenticationMethod,
    }),
  );
}

const acceptTransferEpic: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(InboundETransferActions.acceptInboundETransferRequest.toString()),
    switchMap((action: { payload: InboundETransferAcceptRequest }) => {
      const { payload } = action;

      return acceptTransfer(
        payload.transferId,
        payload.answer,
        payload.authenticationMethod,
      ).pipe(
        tap(() => track({ event: 'Inbound e-Transfer Accepted' })),
        switchMap(() => {
          return [InboundETransferActions.acceptInboundETransferSuccess()];
        }),
        catchError((error: AxiosError<ErrorResponse>) => {
          tap(() => track({ event: 'Inbound e-Transfer Accept Failed' }));
          return of(
            InboundETransferActions.acceptInboundETransferError({
              status: error.response?.status,
              message: error.response?.data?.Message?.error_message,
            }),
          );
        }),
      );
    }),
  );
};

enum InboundETransferSecretQuestionErrorMessages {
  AUTHENTICATION_FAILED_RETRY = 'AUTHENTICATION_FAILED_RETRY',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
}

const mapTransferErrors = (
  errorPayload: InboundETransferAcceptDeclineApiError,
) => {
  switch (errorPayload.status) {
    case 503:
      return InboundETransferErrors.ServiceTemporarilyUnavailable;

    case 400:
      switch (errorPayload.message) {
        case InboundETransferSecretQuestionErrorMessages.AUTHENTICATION_FAILED_RETRY:
          return InboundETransferErrors.SubmissionIncorrectAnswer;

        case InboundETransferSecretQuestionErrorMessages.AUTHENTICATION_FAILED:
          return InboundETransferErrors.SubmissionTransferCanceled;

        default:
          return InboundETransferErrors.Generic;
      }

    default:
      return InboundETransferErrors.Generic;
  }
};

const handleSubmissionError: Epic<any, any, RootState> = (action$) => {
  return action$.pipe(
    ofType(
      InboundETransferActions.declineInboundETransferError.toString(),
      InboundETransferActions.acceptInboundETransferError.toString(),
    ),
    map((action: { payload: InboundETransferAcceptDeclineApiError }) => {
      const { payload } = action;
      const transferError = mapTransferErrors(payload);

      return InboundETransferActions.InboundETransferSetSubmissionError(
        transferError,
      );
    }),
  );
};

export const InboundETransferEpics = [
  retrieveTransferEpic,
  declineTransferEpic,
  acceptTransferEpic,
  handleSubmissionError,
];
