import { useCallback } from 'react';

import { Player } from '@lottiefiles/react-lottie-player';
import { navigate } from '@reach/router';
import { track } from 'analytics/analytics';
import {
  PersonalizedOnboardingState,
  personalizedOnboardingIntentEmojiMap,
} from 'apis/personalizedOnboardingApi';
import { Icons } from 'assets/images/kds_icons/icons';
import confettiAnimation from 'assets/lottie/small-confetti-pop-lottie.json';
import {
  Box,
  ButtonUnstyled,
  Paragraph,
  SubtitleSmall,
  TitleMedium,
} from 'components';
import { useToggle } from 'hooks/useToggle';
import { theme } from 'theme';

import { CompletionModal } from './CompletionModal';
import { ProgressBar } from './ProgressBar';

export const PersonalizedOnboardingWidget = ({
  personalizedOnboardingData,
}: {
  personalizedOnboardingData: PersonalizedOnboardingState | undefined;
}) => {
  const {
    value: isCompletionModalOpen,
    on: openCompletionModal,
    off: closeCompletionModal,
  } = useToggle();

  const cardAction = useCallback(() => {
    if (!personalizedOnboardingData?.spend_card.completed) {
      track({
        event: 'Clicked',
        properties: {
          name: 'personalized-onboarding-widget',
          intent: personalizedOnboardingData?.intent,
          checklistKey: personalizedOnboardingData?.onboarding_page.header.key,
          step: personalizedOnboardingData?.step,
        },
      });
      return navigate('/onboarding-checklist');
    }

    track({
      event: 'Clicked',
      properties: {
        name: 'personalized-onboarding-completed-widget',
        intent: personalizedOnboardingData?.intent,
      },
    });
    return openCompletionModal();
  }, [
    openCompletionModal,
    personalizedOnboardingData?.intent,
    personalizedOnboardingData?.onboarding_page.header.key,
    personalizedOnboardingData?.spend_card.completed,
    personalizedOnboardingData?.step,
  ]);

  if (!personalizedOnboardingData) return null;

  const { intent, spend_card, step, total_steps } = personalizedOnboardingData;
  const isCompleted = spend_card.completed;

  return (
    <>
      <ButtonUnstyled
        sx={{
          position: 'relative',
          borderRadius: '8px',
          overflow: 'hidden',
          p: '16px',
          boxShadow: 'light',
          background: isCompleted
            ? 'linear-gradient(#2CB689, #52B462 100%)'
            : theme.colors.white,
        }}
        data-cy="personalized-onboarding-widget"
        onClick={cardAction}
      >
        {isCompleted && (
          <>
            <Player
              autoplay
              keepLastFrame
              src={confettiAnimation}
              speed={0.6}
              style={{
                pointerEvents: 'none',
                position: 'absolute',
                width: 'inherit',
                left: 0,
                right: 0,
                scale: '160%',
                zIndex: 0,
                opacity: 0.5,
              }}
            />
          </>
        )}
        <Box sx={{ zIndex: 1, position: 'relative' }}>
          <TitleMedium
            sx={{
              m: 0,
              color: isCompleted ? theme.colors.white : theme.colors.text,
            }}
          >
            {spend_card.header}
          </TitleMedium>
          <div
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              borderRadius: '100px',
              border: `1px solid ${theme.colors.primaryDisabled}`,
              py: '4px',
              px: '8px',
              my: '16px',
              background: theme.colors.white,
            }}
          >
            <SubtitleSmall sx={{ color: theme.colors.green400 }}>
              {step}/{total_steps}
            </SubtitleSmall>
            <ProgressBar step={step} totalSteps={total_steps} />
            <SubtitleSmall>
              {personalizedOnboardingIntentEmojiMap[intent]}
            </SubtitleSmall>
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Paragraph
              sx={{
                m: 0,
                color: isCompleted ? theme.colors.white : theme.colors.midGrey,
              }}
            >
              {spend_card.body}
            </Paragraph>
            <Icons.ArrowRight
              color={isCompleted ? theme.colors.white : theme.colors.primary}
            ></Icons.ArrowRight>
          </Box>
        </Box>
      </ButtonUnstyled>
      <CompletionModal
        intent={intent}
        closeModal={closeCompletionModal}
        openModal={openCompletionModal}
        isOpen={isCompletionModalOpen}
      />
    </>
  );
};
