import { contactEpics } from 'contact/store/contact.epics';
import { InboundETransferEpics } from 'inboundETransfer/store/epics';
import { combineEpics } from 'redux-observable';

import accountsEpics from '../accounts/store/epics';
import anonymousUserDataEpic from '../anonymousUser/store/epics';
import authEpics from '../auth/store/epics';
import balanceEpics from '../balance/store/epics';
import billPayEpics from '../billPay/store/epics';
import cardEpics from '../cards/store/epics';
import directDepositEpics from '../directDeposit/store/epics';
import onfidoEpics from '../kyc/store/epics';
import launchDarklyEpics from '../libs/launchdarkly/store/epics';
import loginEpics from '../login/store/epics';
import PasswordResetEpics from '../passwordReset/store/epics';
import profileEpics from '../profile/store/epics';
import referralsEpics from '../referrals/store/epics';
import rewardsEpics from '../rewards/store/epics';
import transferEpics from '../sendMoney/store/epics';
import smsOtpEpics from '../smsOtp/store/epics';
import transactionsEpics from '../transactions/store/epics';

export const rootEpic = combineEpics(
  ...PasswordResetEpics,
  ...accountsEpics,
  ...anonymousUserDataEpic,
  ...authEpics,
  ...balanceEpics,
  ...cardEpics,
  ...launchDarklyEpics,
  ...onfidoEpics,
  ...profileEpics,
  ...referralsEpics,
  ...smsOtpEpics,
  ...transactionsEpics,
  ...rewardsEpics,
  ...directDepositEpics,
  ...billPayEpics,
  ...transferEpics,
  ...contactEpics,
  ...InboundETransferEpics,
  ...loginEpics,
);
