export type CardStatus =
  | 'active'
  | 'locked'
  | 'lost'
  | 'cancelled'
  | 'frozen'
  | 'unfrozen'
  | 'preparing'
  | 'lost waiting for funds'
  | 'blocked'
  | 'disabled'
  | 'operations hold'
  | 'delinquent'
  | 'charged off'
  | 'stolen'
  | 'voided'
  | 'waiting for payment'
  | 'shipped'
  | 'deactivated';

export enum AccountTypes {
  Primary = 'primary',
  Virtual = 'virtual',
}

export enum CardIssuer {
  Mastercard = 'mastercard',
  Visa = 'visa',
}

interface EmbossedCard {
  status: 'shipped' | string;
  readable_status: string;
  created_date: string;
  emboss_date: string;
  image_url: string;
}

export interface AccountCard {
  card_id: string;
  last_four_digits: string;
  is_locked: boolean;
  is_virtual: boolean;
  card_status: CardStatus;
  embossed_cards: EmbossedCard[];
  image_url: string;
  account_type: AccountTypes;
  issuer: CardIssuer;
  ready_to_activate: boolean;
}

export interface CardImage {
  card_url: string;
}
