import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { track } from 'analytics/analytics';
import { selectIsAuthenticationReady } from 'auth/store/selectors';
import { LOCALE_EN, LocaleContext } from 'components';
import { contactFeatureFlags } from 'contact/contact-feature-flags';
import { contactActions } from 'contact/store/contact.slice';
import { intercomUpdate } from 'intercom/intercom';
import { selectProfileEmail } from 'profile/store/selectors';

import { useIsLdLoaded } from 'libs/launchdarkly/hooks/useIsLdLoaded';

const onForethoughtClose = (e: MessageEvent<any>) => {
  if (e.data.event === 'forethoughtWidgetClosed') {
    forethoughtButtonHide();
    // also need to hide the intercom launcher during handoff
    intercomUpdate({
      hide_default_launcher: true,
    });
  }
};

const onForethoughtHandoff = (e: MessageEvent<any>) => {
  if (e.data.event === 'forethoughtWidgetIntegrationHandoff') {
    track({ event: 'Forethought handoff' });
  }
};

export const useForethought = () => {
  const loggedInStoreVal = useSelector(selectIsAuthenticationReady);
  // getting rid of null state
  const loggedIn = Boolean(loggedInStoreVal);
  const userEmail = useSelector(selectProfileEmail);

  const { locale } = useContext(LocaleContext);

  const dispatch = useDispatch();

  const { ldValue: useOpenChatsEndpoint, ldFlagsIsSuccess } =
    useIsLdLoaded<boolean>(contactFeatureFlags.useOpenChatsEndpoint);

  // need to get this LD value into the store to access it in a listener
  useEffect(() => {
    if (ldFlagsIsSuccess) {
      dispatch(
        contactActions.updateUseOpenChatsEndpointLdVal(useOpenChatsEndpoint),
      );
    }
  }, [ldFlagsIsSuccess, useOpenChatsEndpoint, dispatch]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://solve-widget.forethought.ai/embed.js`;
    script.async = true;
    script.setAttribute(
      'data-api-key',
      `${import.meta.env.VITE_FORETHOUGHT_DATA_API}`,
    );
    script.setAttribute('data-ft-isLoggedIn', loggedIn + '');
    const language = locale === LOCALE_EN ? 'en' : 'fr-CA';
    script.setAttribute('data-ft-embed-script-language', language);
    // language needs to be duplicated with the `data-ft-language` attribute
    //because the `embed-script-language` attribute doesn't get set as a
    // context variable in the forethought workflow dashboard
    script.setAttribute('data-ft-language', language);
    // Forethought added code that automatically hides intercom (among other widgets)
    // until after FT does a handoff, by adding this we ensure that users can open intercom
    // before FT does a handoff
    script.setAttribute('hide-intercom-widget', 'false');
    if (userEmail) {
      script.setAttribute('data-ft-userEmail', userEmail);
    }

    document.head.appendChild(script);

    script.onload = () => {
      // we want to hide the forethought button by default so that we can
      // open it from our own UI
      forethoughtButtonHide();

      // we need to "show" the forethought button just before we show the FT
      // chat. To make sure the button is hidden again once conversation is closed
      // we can listen to the 'forethoughtWidgetClosed' message
      window.addEventListener('message', onForethoughtClose);

      // track handoff event
      window.addEventListener('message', onForethoughtHandoff);
    };

    return () => {
      if (script) {
        script.remove();
      }
      // need to remove the widget too when removing the script, so that
      // we don't end up with multiple instances on the page when a new
      // version of the script is added. Handling multiple instances, just in case.
      const widgets = document.querySelectorAll('#forethought-chat');
      if (widgets.length > 0) {
        widgets.forEach((widget) => widget.remove());
      }
      window.removeEventListener('message', onForethoughtClose);
      window.removeEventListener('message', onForethoughtHandoff);
    };
  }, [loggedIn, locale, userEmail]);
};

const forethoughtButtonHide = () => {
  const Forethought = (window as any).Forethought;
  if (Forethought) {
    Forethought('widget', 'hide');
  }
};

const forethoughtButtonShow = () => {
  const Forethought = (window as any).Forethought;
  if (Forethought) {
    Forethought('widget', 'show');
  }
};

export const forethoughtChatOpen = () => {
  const Forethought = (window as any).Forethought;
  if (Forethought) {
    // widget needs to be in "show" state for it to open
    forethoughtButtonShow();
    Forethought('widget', 'open');
  }
};

export const forethoughtChatClose = () => {
  const Forethought = (window as any).Forethought;
  if (Forethought) {
    Forethought('widget', 'close');
  }
};
