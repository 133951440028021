import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { IChangeEstimate, ICurrentBenefit, IPlan } from 'apis/tiers';
import { useTiersData } from 'tiers/hooks/useTiersData';
import { selectIsPaidTier } from 'tiers/store/selectors';
import { isOnFreeTrial } from 'tiers/utils/helpers';

import { Step, StepIcons } from '../tierChangeSteps';

export type UpgradeStepsProps = {
  activeTier: ICurrentBenefit;
  changeEstimate: IChangeEstimate;
  plan: IPlan;
};

export const UpgradeSteps = ({
  activeTier,
  changeEstimate,
  plan,
}: UpgradeStepsProps) => {
  const intl = useIntl();
  const { getTierUpgradeSteps } = useTiersData();
  const planCost = plan.amount_cents ?? 0;
  const nextPayDay = changeEstimate.next_billing_date;
  const isPaidTier = useSelector(selectIsPaidTier);
  const currentTierIsFreeTrial = isOnFreeTrial(activeTier);

  const proratedCost = isPaidTier
    ? changeEstimate.prorated_subscription_amount
    : planCost;

  const translatedSteps = getTierUpgradeSteps({
    planKey: plan.key,
    planCost,
    proratedCost,
    isPaidTier,
    currentTierIsFreeTrial,
  });

  return (
    <>
      <Step
        isFirst
        icon={<StepIcons.Unlock />}
        title={intl.formatMessage({
          id: 'TiersUpgrade.Modal.Today',
          defaultMessage: 'Today',
        })}
        description={translatedSteps.upgradeFirstStep}
      />
      <Step
        isLast
        icon={<StepIcons.Calendar />}
        title={
          nextPayDay
            ? intl.formatDate(nextPayDay, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            : ''
        }
        description={translatedSteps.upgradeLastStep}
      />
    </>
  );
};
