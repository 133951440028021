import { Box, BoxProps } from '@material-ui/core';

export const RequestMoneyInitialsAvatar = ({
  initials,
  ...props
}: BoxProps & {
  initials: string;
}) => {
  return (
    <Box
      sx={{
        fontWeight: 'bold',
        display: 'flex',
        height: '48px',
        width: '48px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        color: 'white',
        border: '1px solid white',
        background: '#A01861',
      }}
      {...props}
    >
      {initials}
    </Box>
  );
};
