/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';
import axios from 'axios';
import { ButtonTw } from 'components/ButtonTw';
import CircularProgressTw from 'components/CircularProgressTw';
import { Paragraph, TitleMedium } from 'components/TypographyTw';

import { track } from '../analytics/analytics';
import phonesImageMC from '../assets/images/general/phoneSpongeCubeMC.png';
import {
  Card,
  FooterImageWrapper,
  LayoutBasic,
} from '../components/LayoutBasic';

export const DeviceVerificationLandingPage = (props: RouteComponentProps) => {
  const [verification, setVerification] = useState<
    'pending' | 'success' | 'failed'
  >('pending');
  const [emailStatus, setEmailStatus] = useState<
    'not sent' | 'pending' | 'sent' | 'failed'
  >('not sent');
  const [token, setToken] = useState('');
  const [loaded, setLoaded] = useState(false);
  const intl = useIntl();

  const resendVerificationEmail = () => {
    setEmailStatus('pending');
    axios
      .post(
        `${
          import.meta.env.VITE_GATEWAY_API
        }fingerprints/devices/verification/reissue`,
        { token },
      )
      .then(() => {
        setEmailStatus('sent');
        track({ event: 'Device verification email sent' });
      })
      .catch(() => {
        setEmailStatus('failed');
        track({ event: 'Device verification email resending failed' });
      });
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);

      // support legacy ? urls and the preferred #
      const paramsDelimiter = window.location.href.includes('?') ? '?' : '#';
      const params = new URLSearchParams(
        window.location.href.split(paramsDelimiter).pop(),
      );
      const token = params.get('token');
      setToken(token || '');
      axios
        .post(
          `${
            import.meta.env.VITE_GATEWAY_API
          }fingerprints/devices/verification`,
          {
            token,
          },
        )
        .then(() => {
          setVerification('success');
          track({ event: 'Device verified' });
        })
        .catch(() => {
          setVerification('failed');
          track({ event: 'Device verification failed' });
        });
    }
  }, [token, loaded]);

  return (
    <LayoutBasic pageName="Device verification" className="bg-grey-75">
      <Card>
        {verification === 'pending' && <CircularProgressTw />}
        {verification === 'success' && (
          <>
            <KDSIcons.Spot.Success className="mb-4" />
            <TitleMedium>
              {intl.formatMessage({
                id: 'DeviceVerificationLanding.Title',
                defaultMessage: 'Thanks for confirming your device',
              })}
            </TitleMedium>
            <Paragraph>
              {intl.formatMessage({
                id: 'DeviceVerificationLanding.Content',
                defaultMessage: `We’ve successfully confirmed your device. You can start using the KOHO app for stress-free, day-to-day banking`,
              })}
            </Paragraph>
          </>
        )}
        {verification === 'failed' && (
          <>
            <KDSIcons.Spot.Wait className="mb-4" />
            <TitleMedium>
              {intl.formatMessage({
                id: 'Global.OopsErrorMessage',
                defaultMessage: 'Oops! We’re having issues',
              })}
            </TitleMedium>
            <Paragraph>
              {intl.formatMessage({
                id: 'DeviceVerificationLanding.Content.Failed',
                defaultMessage:
                  'Sorry, the verification link expired or something went wrong on our end. Please try again.',
              })}
            </Paragraph>
            <ButtonTw
              type="button"
              trackName="Resend device verification email"
              onClick={resendVerificationEmail}
            >
              {emailStatus === 'not sent' &&
                intl.formatMessage({
                  id: 'DeviceVerificationLanding.EmailStatus.NotSent',
                  defaultMessage: 'Resend email',
                })}
              {emailStatus === 'pending' && <CircularProgressTw />}
              {emailStatus === 'sent' &&
                intl.formatMessage({
                  id: 'DeviceVerificationLanding.EmailStatus.Sent',
                  defaultMessage: 'Email sent, check your inbox.',
                })}
              {emailStatus === 'failed' &&
                intl.formatMessage({
                  id: 'DeviceVerificationLanding.EmailStatus.Failed',
                  defaultMessage: 'Error sending email - check your connection',
                })}
            </ButtonTw>
          </>
        )}
      </Card>
      <FooterImageWrapper>
        <img
          src={phonesImageMC}
          alt={`${intl.formatMessage({
            id: 'DeviceVerificationLanding.FooterImageAltTag',
            defaultMessage: 'landing-footer',
          })}`}
          className="w-[260px] relative z-[2]"
        />
      </FooterImageWrapper>
    </LayoutBasic>
  );
};
