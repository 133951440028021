/** @jsxImportSource theme-ui */
import { theme } from '../theme';

/** @deprecated - Use ContainerTw from layout/ContainerTw */
export const Container = ({ children, ...props }) => {
  return (
    <div
      sx={{
        width: theme.widths,
        mr: 'auto',
        ml: 'auto',
        mt: 6,
        pt: 2,
        pl: [3, 3, 0],
        pr: [3, 3, 0],
      }}
      {...props}
    >
      {children}
    </div>
  );
};
