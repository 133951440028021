import * as braze from '@braze/web-sdk';
import { navigate } from '@reach/router';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { loginActions } from 'login/store/slice';
import { selectProfileIsMidRegistration } from 'profile/store/selectors';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { accountsActions } from '../../accounts/store/slice';
import { analyticsReset } from '../../analytics/analytics';
import { intercomReboot } from '../../intercom/intercom';
import { clearStateAction } from '../../store/reducers';
import { AUTH_OTP_TIMEOUT, AUTH_SESSION_TIMEOUT } from '../models';
import { authActions } from './slice';

const authRefreshSessionEpic = (action$, store) => {
  return action$.pipe(
    ofType(authActions.refreshSession.toString()),
    switchMap(() => {
      const isMidRegistration = selectProfileIsMidRegistration(store.value);

      const endpoint = isMidRegistration ? 'authenticate/refresh' : 'refresh';

      return from(
        axios.post(`${import.meta.env.VITE_GATEWAY_API}${endpoint}`),
      ).pipe(map(() => authActions.setSession()));
    }),
    catchError(() => {
      return of(authActions.refreshSessionError());
    }),
  );
};

const authSetSessionEpic = (action$, store) => {
  return action$.pipe(
    ofType(authActions.setSession.toString()),
    tap(({ payload }) => {
      localStorage.setItem(AUTH_SESSION_TIMEOUT, payload.timeout);
    }),
    map(() => authActions.noop()),
  );
};

const authDeleteSessionEpic = (action$, store) => {
  return action$.pipe(
    ofType(
      authActions.deleteSession.toString(),
      authActions.deleteSessionForced.toString(),
      authActions.refreshSessionError.toString(),
    ),
    tap(() => {
      localStorage.removeItem(AUTH_SESSION_TIMEOUT);
      sessionStorage.removeItem(AUTH_OTP_TIMEOUT);
      navigate('/login');
      intercomReboot();
      braze.wipeData();
      // remove Sentry user scope
      Sentry.configureScope((scope) => scope.setUser(null));
      analyticsReset();
    }),
    map(() => clearStateAction()),
  );
};

const authSetOtpTimeoutEpic = (action$, store) => {
  return action$.pipe(
    ofType(authActions.setOtpTimeout.toString()),
    tap(({ payload }) => {
      if (store.value.login.rememberDevice) {
        localStorage.setItem(AUTH_OTP_TIMEOUT, payload.timeout);
      } else {
        sessionStorage.setItem(AUTH_OTP_TIMEOUT, payload.timeout);
      }
    }),
    map(() => authActions.noop()),
  );
};

const authDeleteOtpTimeoutEpic = (action$, store) => {
  return action$.pipe(
    ofType(authActions.deleteOtpTimeout.toString()),
    tap(({ payload }) => {
      localStorage.removeItem(AUTH_OTP_TIMEOUT);
      sessionStorage.removeItem(AUTH_OTP_TIMEOUT);
    }),
    map(() => authActions.noop()),
  );
};

const authHandleAccountCheckErrorsEpic = (actions$) => {
  return actions$.pipe(
    ofType(accountsActions.getAccountsAllAtLoginError.toString()),
    map(() => loginActions.navigateToLoginDestination()),
  );
};

const exportedArray = [
  authDeleteOtpTimeoutEpic,
  authDeleteSessionEpic,
  authRefreshSessionEpic,
  authSetOtpTimeoutEpic,
  authSetSessionEpic,
  authHandleAccountCheckErrorsEpic,
];

export default exportedArray;
