import { useState } from 'react';

export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const on = () => setValue(true);
  const off = () => setValue(false);
  const toggle = () => setValue((val) => !val);

  return { value, on, off, toggle };
};
