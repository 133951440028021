/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Button, Modal, ModalProps } from 'components';

import { contactActions } from './store/contact.slice';

export const ContactModal = ({ open, onClose, ...props }: ModalProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const openPreviousChat = () => {
    dispatch(contactActions.intercomOpen());
  };

  const openNewChat = () => {
    dispatch(contactActions.forethoughtOpen());
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="md"
      title={intl.formatMessage({
        id: 'Contact.Modal.Title',
        defaultMessage: 'Please pick an option',
      })}
      {...props}
    >
      <div
        sx={{
          display: 'flex',
          gap: 3,
          flexDirection: 'column',
          width: ['auto', 500],
        }}
      >
        <Button onClick={openNewChat}>
          {intl.formatMessage({
            id: 'Contact.Modal.OpenNewChatButton',
            defaultMessage: 'Open new chat',
          })}
        </Button>
        <Button onClick={openPreviousChat} variant="secondary">
          {intl.formatMessage({
            id: 'Contact.Modal.OpenPreviousChatButton',
            defaultMessage: 'Open previous chat',
          })}
        </Button>
      </div>
    </Modal>
  );
};
