/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';
import axios, { AxiosError } from 'axios';

import { track } from '../analytics/analytics';
import phonesImage from '../assets/images/general/phone@2x.png';
import { H2, P, SpinnerCircularProgress } from '../components';
import {
  Card,
  FooterImageWrapper,
  LayoutBasic,
} from '../components/LayoutBasic';

export const EmailChangeConfirmation = (props: RouteComponentProps) => (
  <LayoutBasic pageName="Email change confirmation" sx={{ bg: '#fff' }}>
    <Card sx={{ mb: 6 }}>
      <Main />
    </Card>
    <FooterImageWrapper bg="#FAAA9F">
      <img
        src={phonesImage}
        alt=""
        sx={{ width: 500, maxWidth: '100%', position: 'relative', zIndex: 2 }}
      />
    </FooterImageWrapper>
  </LayoutBasic>
);

export const Main = () => {
  const intl = useIntl();
  const [screen, setScreen] = React.useState<
    'pending' | 'success' | 'failed' | 'expired'
  >('pending');
  const [token, setToken] = React.useState('');
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!loaded) {
      setLoaded(true);

      // support legacy ? urls and the preferred #
      const paramsDelimeter = window.location.href.includes('?') ? '?' : '#';
      const params = new URLSearchParams(
        window.location.href.split(paramsDelimeter).pop(),
      );
      const token = params.get('token');

      setToken(token || '');

      axios
        .post(`${import.meta.env.VITE_GATEWAY_API}user/confirm-email-change`, {
          token,
        })
        .then(() => {
          setScreen('success');
          track({ event: 'Email change confirmation success' });
        })
        .catch((error: AxiosError) => {
          if (error.code === '403') {
            setScreen('expired');
          } else {
            setScreen('failed');
          }
          track({ event: 'Email change confirmation failed' });
        });
    }
  }, [token, loaded]);

  switch (screen) {
    case 'pending':
      return <SpinnerCircularProgress />;

    case 'success':
      return (
        <React.Fragment>
          <KDSIcons.Spot.Success sx={{ mb: 3 }} />
          <H2>
            {intl.formatMessage({
              id: 'EmailChangeConfirmation.SuccessTitle',
              defaultMessage: 'Thanks for confirming your email',
            })}
          </H2>
          <P>
            {intl.formatMessage({
              id: 'EmailChangeConfirmation.SuccessContent',
              defaultMessage: 'Your email has been updated.',
            })}
          </P>
        </React.Fragment>
      );

    case 'expired':
      return (
        <React.Fragment>
          <KDSIcons.Spot.Stop sx={{ mb: 3 }} />
          <H2>
            {intl.formatMessage({
              id: 'EmailChangeConfirmation.ExpiredTitle',
              defaultMessage: 'Sorry, the verification expired.',
            })}
          </H2>
          <P>
            {intl.formatMessage({
              id: 'EmailChangeConfirmation.ExpiredContent',
              defaultMessage: `You'll have to get another one.`,
            })}
          </P>
        </React.Fragment>
      );
    case 'failed':
      return (
        <React.Fragment>
          <KDSIcons.Spot.Stop sx={{ mb: 3 }} />
          <H2>
            {intl.formatMessage({
              id: 'EmailChangeConfirmation.FailedTitle',
              defaultMessage: 'Sorry, something went wrong.',
            })}
          </H2>
          <P>
            {intl.formatMessage({
              id: 'EmailChangeConfirmation.FailedContent',
              defaultMessage: 'Please try again later.',
            })}
          </P>
        </React.Fragment>
      );
    default:
      return null;
  }
};
