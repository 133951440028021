import { useState } from 'react';
import { useSelector } from 'react-redux';

import { RouteComponentProps } from '@reach/router';
import { useLogoutMutation } from 'apis/auth';
import { selectAuthIsAuthenticated } from 'auth/store/selectors';

import { LoginPage } from './LoginPage';

interface LoginPageProps
  extends RouteComponentProps<{
    location: { state?: { emailAddress?: string } };
  }> {}

export const LoginPageWrapper = (props: LoginPageProps) => {
  const [isInit, setIsInit] = useState(true);

  const [logout] = useLogoutMutation();
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  if (isInit) {
    if (isAuthenticated) {
      logout({ navigateToLogin: false, deleteSession: true });
    }

    setIsInit(false);
  }

  return <LoginPage location={props.location?.state?.emailAddress} />;
};
