import Grid from '@material-ui/core/Grid';
import { Box, ButtonUnstyled, Paragraph } from 'components';
import { CardProductData, CardProductPackage } from 'models/CardProductData';

import checkmark from '../../assets/images/green-checkmark.svg';

type CardSelectorProps = {
  products: CardProductData[];
  selected: CardProductData | null;
  onSelect: (arg: CardProductData) => void;
};

export const CardSelector = (props: Readonly<CardSelectorProps>) => {
  const { products, onSelect } = props;

  let value = props.selected?.card_design;

  return (
    <>
      <Box sx={{ mx: 6, mb: 2, textAlign: 'center' }}>
        {products.map((product) => (
          <img
            key={product.name}
            alt={product.name}
            sx={{
              height: '290px',
              borderRadius: '7px',
              display: product.card_design === value ? 'initial' : 'none',
            }}
            src={
              products.find((product) => product.card_design === value)
                ?.image_url
            }
          />
        ))}
        <Paragraph>
          {products.find((product) => product.card_design === value)?.name}
        </Paragraph>
      </Box>

      <Grid container justifyContent="center">
        {products.map((product) =>
          product.package === CardProductPackage.free ? (
            <Grid item key={product.name} sx={{ position: 'relative' }}>
              <ButtonUnstyled
                onClick={() => onSelect(product)}
                sx={{
                  width: '48px',
                  height: '48px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  m: 2,
                  borderRadius: product.card_design === value ? '50%' : null,
                  boxShadow:
                    product.card_design === value
                      ? '0 0 0px 2px #fff, 3px 6px 15px rgba(0, 0, 0, 0.3)'
                      : null,
                  '&:hover:enabled': {
                    borderRadius: '50%',
                    boxShadow:
                      '0 0 0px 2px #fff, 3px 6px 15px rgba(0, 0, 0, 0.3)',
                  },
                  '&:focus, &:visited': {
                    borderRadius: '50%',
                    borderColor: 'transparent',
                  },
                }}
              >
                <img
                  src={product.colour_palette_url}
                  alt=""
                  sx={{
                    width: '44px',
                    borderRadius: '50%',
                  }}
                />
              </ButtonUnstyled>
              <img
                src={checkmark}
                alt=""
                sx={{
                  display: product.card_design === value ? '' : 'none',
                  position: 'absolute',
                  width: '16px',
                  height: '16px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)',
                  borderRadius: '50%',
                  right: 2,
                  top: 1,
                }}
              />
            </Grid>
          ) : null,
        )}
      </Grid>
    </>
  );
};
