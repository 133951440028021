/** @jsxImportSource theme-ui */
import { useIntl } from 'react-intl';

import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { Button, Paragraph, SecurityAssurance, TitleLarge } from 'components';
import {
  KhAutocompleteSelect,
  SelectOption,
} from 'components/inputs/KhAutocompleteSelect';
import { Occupation, occupations } from 'profile/data/occupations';
import { useTrackPageViewedOnceDeprecated } from 'utility/analyticsHooks';

export const RegistrationStepOccupationPage = ({
  onSubmit,
  onOccupationSelected,
  occupation,
}: {
  onSubmit: () => void;
  onOccupationSelected: (occupation: Occupation | null) => void;
  occupation: Occupation | null;
}) => {
  const intl = useIntl();

  const selectOptionsTranslated = occupations.map((item) => ({
    value: item.value,
    label: item.title ? intl.formatMessage(item.title) : item.value,
  }));

  useTrackPageViewedOnceDeprecated({
    name: 'registration-occupation',
    overrideUrl: `registration/occupation`,
  });

  return (
    <form
      data-cy="registration-occupation-form"
      onSubmit={(e) => {
        e.preventDefault();
        if (occupation !== null) {
          onSubmit();
        }
      }}
    >
      <TitleLarge sx={{ mt: 0, mb: 3 }}>
        {intl.formatMessage({
          id: 'Registration.OccupationPage.Title',
          defaultMessage: "What's your occupation?",
        })}
      </TitleLarge>
      <Paragraph sx={{ mb: 5 }}>
        {intl.formatMessage({
          id: 'Registration.OccupationPage.Subtitle',
          defaultMessage: 'Choose the option that best matches.',
        })}
      </Paragraph>
      <KhAutocompleteSelect
        data-cy="registration-occupation-input"
        label={intl.formatMessage({
          id: 'Registration.OccupationPage.Label',
          defaultMessage: 'Occupation',
        })}
        options={selectOptionsTranslated}
        onChange={(_, selectOption: SelectOption | null) => {
          onOccupationSelected(
            occupations.find((occ) => occ.value === selectOption?.value) ??
              null,
          );
        }}
        value={occupation?.value}
        popupIcon={<SearchIcon />}
        required
        sx={{
          mb: 4,
          '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
        }}
      />

      <Button
        type="submit"
        trackName="move-occupation"
        sx={{ mb: 4 }}
        disabled={occupation === null || !occupation}
        data-cy="occupation-cta-button"
      >
        {intl.formatMessage({
          id: 'Registration.OccupationPage.CTA',
          defaultMessage: 'Next',
        })}
      </Button>
      <SecurityAssurance />
    </form>
  );
};
