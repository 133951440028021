import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  IBenefitGroup,
  IPlan,
  useGetChangeEstimateQuery,
  useGetCurrentTierQuery,
} from 'apis/tiers';
import { theme } from 'theme';

import { FreeTrialStepsProps } from '../upgradeConfirmationModal/FreeTrialSteps';
import { UpgradeStepsProps } from '../upgradeConfirmationModal/UpgradeSteps';

const StepsPlaceholder = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ gap: 20, ml: '38px', width: '70%' }}
    >
      <Skeleton variant="rect" height="36px" sx={{ borderRadius: 4 }} />
      <Skeleton
        variant="rect"
        height="36px"
        sx={{ borderRadius: 4, mb: '20px' }}
      />
    </Box>
  );
};

type Props = {
  selectedPlan: IPlan;
  selectedTier: IBenefitGroup;
  children: (props: FreeTrialStepsProps & UpgradeStepsProps) => JSX.Element;
};

export const Steps = ({ selectedPlan, selectedTier, children }: Props) => {
  const {
    data: activeTier,
    isLoading: activeTierIsLoading,
    isError: activeTierError,
  } = useGetCurrentTierQuery();
  const {
    data: changeEstimate,
    isLoading: changeEstimteIsLoading,
    isError: changeEstimateError,
  } = useGetChangeEstimateQuery({
    frequency: selectedPlan.key,
    tier: selectedTier.key,
  });

  const notLoadedSuccessfully =
    changeEstimteIsLoading ||
    changeEstimateError ||
    activeTierIsLoading ||
    activeTierError ||
    !activeTier ||
    !changeEstimate;

  return (
    <Box display="flex" flexDirection="row">
      <Box position="relative" sx={{ height: 'fit-content', width: '100%' }}>
        <Box
          position="absolute"
          sx={{
            background: `linear-gradient(${theme.colors.greyXLight}, ${theme.colors.greyXLight} 90%, ${theme.colors.background})`,
            width: 22,
            height: '100%',
            borderTopRightRadius: '100px',
            borderTopLeftRadius: '100px',
            mr: 3,
          }}
        />
        {notLoadedSuccessfully ? (
          <StepsPlaceholder />
        ) : (
          children({
            tier: selectedTier,
            plan: selectedPlan,
            changeEstimate,
            activeTier,
          })
        )}
      </Box>
    </Box>
  );
};
