export class Money {
  public cents: number;
  public locale: string | undefined;

  constructor(
    moneyValue: Money | number | string | { cents: number },
    locale?: string,
  ) {
    this.cents = 0;
    this.locale = locale;

    if (moneyValue instanceof Money) {
      this.cents = moneyValue.cents;
    } else if ('number' === typeof moneyValue) {
      this.cents = Math.round(moneyValue);
    } else if ('string' === typeof moneyValue) {
      this.cents = this.moneyStringToCentsNumber(moneyValue);
    } else if (moneyValue && moneyValue.cents) {
      this.cents = moneyValue.cents;
    } else if (!moneyValue) {
      this.cents = 0;
    } else {
      throw new Error('Bad money!');
    }
  }

  public fromCents(cents: number): Money {
    if ('number' !== typeof cents) {
      throw new Error('Invalid cents!');
    }

    return new Money(cents, this.locale);
  }

  public fromDollars(dollars: number): Money {
    if ('number' !== typeof dollars) {
      throw new Error('Invalid dollars');
    }

    return new Money(dollars * 100, this.locale);
  }

  public add(that: string | number | Money): Money {
    const thatMoney = new Money(that);
    return new Money(this.cents + thatMoney.cents, this.locale);
  }

  public sub(that: string | number | Money): Money {
    const thatMoney = new Money(that);
    return new Money(this.cents - thatMoney.cents, this.locale);
  }

  public div(that: string | number | Money): number {
    const thatMoney = new Money(that);
    // NOTE that we are returning a scalar here!
    return this.cents / thatMoney.cents;
  }

  public scalarDiv(scalar: number): Money {
    const thisCents = this.cents;
    let result = thisCents;

    // Only divide if scalar is greater than 0
    if (scalar > 0) {
      result = thisCents / scalar;
    } else {
      throw new Error(
        'Scalar must be greater than 0. (Are you trying to complete a goal today?)',
      );
    }

    const newMoney = new Money(result, this.locale);

    return newMoney;
  }

  public scalarMul(scalar: number): Money {
    const thisCents = this.cents;
    //Only multiply if scalar is not zero
    const result = thisCents ? thisCents * scalar : thisCents;
    const newMoney = new Money(result, this.locale);

    return newMoney;
  }

  public eq(that: number | Money): boolean {
    return this.cents === new Money(that).cents;
  }

  //  TODO - i18n
  public format(
    format: 'default' | 'deficit' | 'pretty' = 'default',
    stripCurrencySymbol?: boolean,
  ): string {
    switch (format) {
      case 'deficit':
        const sign = this.cents > 0 ? '+' : '';
        return (
          sign +
          this.centsNumberToMoneyString(
            Math.abs(this.cents),
            stripCurrencySymbol,
          )
        );
      case 'pretty':
        return this.centsNumberToMoneyString(
          this.cents,
          stripCurrencySymbol,
          true,
        );

      default:
        return this.centsNumberToMoneyString(this.cents, stripCurrencySymbol);
    }
  }

  public lt(that: number | Money): boolean {
    return this.cents < new Money(that).cents;
  }

  public gt(that: number | Money): boolean {
    return this.cents > new Money(that).cents;
  }

  public lteq(that: number | Money): boolean {
    return this.cents <= new Money(that).cents;
  }

  public gteq(that: number | Money): boolean {
    return this.cents >= new Money(that).cents;
  }

  /**
   * Returns the amount of money rounded to the nearest dollar
   */
  public dollars(): number {
    return Math.round(this.cents / 100);
  }

  public toString(): string {
    return this.format();
  }

  public abs(): Money {
    return new Money(Math.abs(this.cents), this.locale);
  }

  public negate(): Money {
    return this.scalarMul(-1);
  }

  public toNumber(): number {
    return this.cents / 100;
  }

  private moneyStringToCentsNumber(amount: string): number {
    if ('$0.00' === amount) {
      return 0;
    }

    // ensure trailing numbers after dot
    if (amount.includes('.')) {
      const amountSplitted = amount.split('.');
      if (!amountSplitted[1]) amount = amount + '00';
      if (amountSplitted[1].length === 1) amount = amount + '0';
    }
    if (this.locale === 'fr' && amount.includes(',')) {
      const amountSplitted = amount.split(',');
      if (!amountSplitted[1]) amount = amount + '00';
      if (amountSplitted[1].length === 1) amount = amount + '0';
    }

    let isNegative = false;
    if (0 < amount.length && '-' === amount.charAt(0)) {
      amount = amount.substr(1);
      isNegative = true;
    }

    if (0 < amount.length && '$' === amount.charAt(0)) {
      amount = amount.substr(1);
    }

    if (this.locale === 'en' && amount.indexOf('.') === -1) {
      // assume no cents parts
      amount = amount + '00';
    }

    if (4 <= amount.length && '.' === amount.charAt(amount.length - 3)) {
      const dollarPart = amount.substr(0, amount.length - 3);
      const centsPart = amount.substr(amount.length - 2);

      // Note that this is 2 strings being concatenated together.
      amount = dollarPart + centsPart;
    }

    // Remove any zeros in the front (so that parseInt() does not intepret
    // the string number as octals).
    amount = amount.replace(/^0+/g, '');
    if (amount === '') {
      amount = '0';
    }

    // Remove any commas.
    amount = amount.replace(/,/g, '');

    let amountNumber = parseInt(amount, 10);

    if (isNegative) {
      amountNumber = -1 * amountNumber;
    }

    // Return.
    return amountNumber;
  }

  private centsNumberToMoneyString(
    amountNumber: number,
    stripCurrencySymbol?: boolean,
    pretty?: boolean,
  ): string {
    if (!amountNumber && amountNumber !== 0) {
      return '';
    }
    let isNegative = false;
    if (0 > amountNumber) {
      amountNumber *= -1;
      isNegative = true;
    }

    let amountString = new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: 'CAD',
    })
      .format(amountNumber / 100)
      .replace('CA', '');

    if (stripCurrencySymbol) {
      amountString = new Intl.NumberFormat(this.locale, {
        style: 'decimal',
      }).format(amountNumber / 100);
    }

    if (pretty) {
      if (this.locale === 'fr') {
        amountString = amountString.replace(/,00/, '');
      } else {
        amountString = amountString.replace(/\.00/, '');
      }
    }

    if (isNegative) {
      amountString = '-' + amountString;
    }

    return amountString;
  }

  public static validate(value: string): boolean {
    return !value || /^[0-9]+\.?([0-9]{1,2})?$/.test(value);
  }
}
