import React from 'react';
import { useIntl } from 'react-intl';

import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useGetNbaEligibilityQuery } from 'apis/tiers';
import { KDSIcons } from 'assets/images/kds_icons';
import nbaLogo from 'assets/images/tiers/nba/nba-logo.png';
import nbaStoreLogo from 'assets/images/tiers/nba/nba-store-logo.png';
import { Template } from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { theme } from 'theme';

import EarnedGiftCard from './EarnedGiftCard';
import HasNotEarnedGiftCard from './HasNotEarnedGiftCard';

const NbaPage: React.FC = () => {
  const intl = useIntl();
  const muiTheme = useTheme();
  const isTablet = useMediaQuery(muiTheme.breakpoints.up('sm'));
  const isNbaIncentivesEnabled = useFlags()['web-nba-incentive-enabled'];
  const skipNbaIncentives = isNbaIncentivesEnabled ? undefined : true;
  const { data: nbaData, isLoading: getNbaIsLoading } =
    useGetNbaEligibilityQuery(isNbaIncentivesEnabled, {
      skip: skipNbaIncentives,
    });

  const hasGiftCard = !!nbaData?.reward_link;

  if (getNbaIsLoading) return <CircularProgress />;

  return (
    <Template name="NBA Page" bgColor={hasGiftCard ? '#000' : '#1D428A'}>
      <Grid
        item
        sm={12}
        lg={12}
        sx={{
          backgroundColor: hasGiftCard ? '#000' : '#1D428A',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: isTablet ? 'center' : 'space-between',
          padding: isTablet ? '80px 64px' : '24px 0px 0px 0px',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignSelf="center"
          sx={{
            maxWidth: '500px',
            width: '100%',
            px: isTablet ? '0p' : '16px',
            marginBottom: '48px',
          }}
        >
          <img
            src={hasGiftCard ? nbaStoreLogo : nbaLogo}
            alt="nba logo"
            style={{ maxWidth: '500px', width: '100%', marginBottom: '48px' }}
          />
          <Chip
            icon={<KDSIcons.Icons.GiftBold color="#1D428A" />}
            label={intl.formatMessage({
              defaultMessage: 'Bonus offer',
              id: 'NbaPage.ChipLabel',
            })}
            sx={{
              width: 'fit-content',
              backgroundColor: theme.colors.white,
              color: '#1D428A',
              borderColor: '#1D428A',
              marginBottom: '24px',
              '& span': {
                lineHeight: 'normal',
                fontSize: '12px',
                color: '#1D428A',
                fontWeight: 700,
              },
              '& svg': {
                color: '#1D428A',
              },
            }}
          />
          {hasGiftCard ? (
            <>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '40px',
                  lineHeight: '48px',
                  color: theme.colors.white,
                }}
              >
                {intl.formatMessage({
                  id: 'NbaPage.Header.HasGiftCard',
                  defaultMessage:
                    'Your $75 NBAStore.ca e-Gift card is ready! 🏀',
                })}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  color: theme.colors.white,
                  marginTop: '8px',
                }}
              >
                {intl.formatMessage({
                  id: 'NbaPage.Header.HasGiftCard.Subtitle',
                  defaultMessage: "Congratulations, you've earned it! 🎉",
                })}
              </Typography>
            </>
          ) : (
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '40px',
                lineHeight: '48px',
                color: theme.colors.white,
              }}
            >
              {intl.formatMessage({
                id: 'NbaPage.Header',
                defaultMessage: 'Want a $75 NBAStore.ca e-Gift card? 🏀',
              })}
            </Typography>
          )}
        </Box>
        {isNbaIncentivesEnabled && hasGiftCard ? (
          <EarnedGiftCard data={nbaData} />
        ) : (
          <HasNotEarnedGiftCard />
        )}
      </Grid>
    </Template>
  );
};

export default NbaPage;
