import { rest } from 'msw';

const registrationContextHandlers = [
  rest.get(/\/context/, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        identity_only: false,
        has_account: false,
        user_reference_identifier: 'ABc123Def',
        email: 'test+mocked@koho.ca',
      }),
    );
  }),
];

export default registrationContextHandlers;
