import { useIntl } from 'react-intl';

export enum InputValidationMsg {
  FieldRequired = 'FieldRequired',
  EmailInvalid = 'EmailInvalid',
  EmailTooLong = 'EmailTooLong',
  InvalidCredentials = 'InvalidCredentials',
  InvalidName = 'InvalidName',
  InvalidDate = 'InvalidDate',
  MinAgeError = 'MinAgeError',
  InvalidPostalCode = 'InvalidPostalCode',
  InvalidPhone = 'InvalidPhone',
  InvalidCode = 'InvalidCode',
  UnknownErr = 'UnknownErr',
  ReferralCodeExhausted = 'ReferralCodeExhausted',
  ReferralCodeDisabled = 'ReferralCodeDisabled',
  FirstNameRequired = 'FirstNameRequired',
  LastNameRequired = 'LastNameRequired',
  ValidDobRequired = 'ValidDobRequired',
  ValidStreetAddressRequired = 'ValidStreetAddressRequired',
  ValidCityRequired = 'ValidCityRequired',
  POBoxNotAllowed = 'POBoxNotAllowed',
}

export const useValidationMsg = (msg?: any): string => {
  const intl = useIntl();

  switch (msg) {
    case InputValidationMsg.FieldRequired:
      return intl.formatMessage({
        id: 'ValidationErrors.FieldRequired',
        defaultMessage: 'This field is required',
      });
    case InputValidationMsg.EmailInvalid:
      return intl.formatMessage({
        id: 'ValidationErrors.EmailInvalid',
        defaultMessage: 'Please enter a valid email address',
      });
    case InputValidationMsg.EmailTooLong:
      return intl.formatMessage({
        id: 'ValidationErrors.EmailTooLong',
        defaultMessage: 'That email address is too long',
      });
    case InputValidationMsg.InvalidCredentials:
      return intl.formatMessage({
        id: 'ValidationErrors.InvalidCredentials',
        defaultMessage: 'The email and password combination entered is invalid',
      });
    case InputValidationMsg.InvalidName:
      return intl.formatMessage({
        id: 'ValidationErrors.InvalidName',
        defaultMessage: 'Please enter a valid name',
      });
    case InputValidationMsg.InvalidDate:
      return intl.formatMessage({
        id: 'ValidationErrors.InvalidDate',
        defaultMessage: 'Please enter a valid date',
      });
    case InputValidationMsg.MinAgeError:
      return intl.formatMessage({
        id: 'ValidationErrors.MinAgeError',
        defaultMessage: 'You must be at least 18 years of age',
      });
    case InputValidationMsg.InvalidPostalCode:
      return intl.formatMessage({
        id: 'ValidationErrors.InvalidPostalCode',
        defaultMessage: 'Please enter a valid postal code',
      });
    case InputValidationMsg.InvalidPhone:
      return intl.formatMessage({
        id: 'ValidationErrors.InvalidPhone',
        defaultMessage: 'Please enter a valid phone number',
      });
    case InputValidationMsg.InvalidCode:
      return intl.formatMessage({
        id: 'ValidationErrors.InvalidCode',
        defaultMessage: 'Invalid code',
      });
    case InputValidationMsg.UnknownErr:
      return intl.formatMessage({
        id: 'ValidationErrors.UnknownError',
        defaultMessage: 'Please try again later',
      });
    case InputValidationMsg.ReferralCodeExhausted:
      return intl.formatMessage({
        id: 'ValidationErrors.ReferralCodeExhausted',
        defaultMessage: 'Too many accounts have used this code',
      });
    case InputValidationMsg.ReferralCodeDisabled:
      return intl.formatMessage({
        id: 'ValidationErrors.ReferralCodeDisabled',
        defaultMessage: 'This code is no longer valid',
      });
    case InputValidationMsg.FirstNameRequired:
      return intl.formatMessage({
        id: 'ValidationErrors.FirstNameRequired',
        defaultMessage: 'Please enter your first name',
      });
    case InputValidationMsg.LastNameRequired:
      return intl.formatMessage({
        id: 'ValidationErrors.LastNameRequired',
        defaultMessage: 'Please enter your last name',
      });
    case InputValidationMsg.ValidDobRequired:
      return intl.formatMessage({
        id: 'ValidationErrors.ValidDobRequired',
        defaultMessage: 'Please enter a valid date of birth',
      });
    case InputValidationMsg.ValidStreetAddressRequired:
      return intl.formatMessage({
        id: 'ValidationErrors.ValidStreetAddressRequired',
        defaultMessage: 'Please enter a valid street address',
      });
    case InputValidationMsg.ValidCityRequired:
      return intl.formatMessage({
        id: 'ValidationErrors.ValidCityRequired',
        defaultMessage: 'Please enter a valid city',
      });
    case InputValidationMsg.POBoxNotAllowed:
      return intl.formatMessage({
        id: 'ValidationErrors.POBoxNotAllowed',
        defaultMessage: 'Please enter your non-PO box home address',
      });
    default:
      return '';
  }
};
