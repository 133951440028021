/** @jsxImportSource theme-ui */
import React from 'react';
import { useIntl } from 'react-intl';

import { RouteComponentProps } from '@reach/router';
import { KDSIcons } from 'assets/images/kds_icons';
import axios from 'axios';

import { track } from '../analytics/analytics';
import phonesImage from '../assets/images/general/phone@2x.png';
import { Button, H2, P } from '../components';
import {
  Card,
  FooterImageWrapper,
  LayoutBasic,
} from '../components/LayoutBasic';
import Spinner from '../components/SpinnerCircularProgress';

export const EmailVerificationLanding = (props: RouteComponentProps) => (
  <LayoutBasic pageName="Email confirmation" sx={{ bg: '#fff' }}>
    <Card sx={{ mb: 6 }}>
      <Main />
    </Card>
    <FooterImageWrapper bg="#FAAA9F">
      <img
        src={phonesImage}
        alt=""
        sx={{ width: 500, maxWidth: '100%', position: 'relative', zIndex: 2 }}
      />
    </FooterImageWrapper>
  </LayoutBasic>
);

export const Main = () => {
  const [screen, setScreen] = React.useState<'pending' | 'success' | 'failed'>(
    'pending',
  );
  const [emailStatus, setEmailStatus] = React.useState<
    'not sent' | 'pending' | 'sent' | 'failed'
  >('not sent');
  const [token, setToken] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [loaded, setLoaded] = React.useState(false);
  const intl = useIntl();

  const resendVerificationEmail = () => {
    setEmailStatus('pending');
    axios
      .post(`${import.meta.env.VITE_GATEWAY_API}email/request`, { email })
      .then(() => {
        setEmailStatus('sent');
        track({ event: 'email confirmation email sent' });
      })
      .catch(() => {
        setEmailStatus('failed');
        track({ event: 'email confirmation email resending failed' });
      });
  };

  React.useEffect(() => {
    if (!loaded) {
      setLoaded(true);

      // support legacy ? urls and the preferred #
      const paramsDelimeter = window.location.href.includes('?') ? '?' : '#';
      const params = new URLSearchParams(
        window.location.href.split(paramsDelimeter).pop(),
      );
      const token = params.get('token');
      const email = params.get('email');

      setToken(token || '');
      setEmail(email || '');

      axios
        .post(`${import.meta.env.VITE_GATEWAY_API}email/verify`, {
          token,
          email,
        })
        .then(() => {
          setScreen('success');
          track({ event: 'email verification success' });
        })
        .catch(() => {
          setScreen('failed');
          track({ event: 'Email verification failed' });
        });
    }
  }, [token, loaded]);

  switch (screen) {
    case 'pending':
      return <Spinner />;

    case 'success':
      return (
        <React.Fragment>
          <KDSIcons.Spot.Success sx={{ mb: 3 }} />
          <H2>
            {intl.formatMessage({
              id: 'EmailVerificationLanding.SuccessTitle',
              defaultMessage: 'Thanks for confirming your email',
            })}
          </H2>
          <P>
            {intl.formatMessage({
              id: 'EmailVerificationLanding.SuccessContent',
              defaultMessage:
                'Get started by adding funds within the KOHO mobile app! Shop online with your Virtual Card while you wait for your physical card to arrive in the mail.',
            })}
          </P>
        </React.Fragment>
      );

    case 'failed':
      return (
        <React.Fragment>
          <KDSIcons.Spot.Stop sx={{ mb: 3 }} />
          <H2>
            {intl.formatMessage({
              id: 'EmailVerificationLanding.FailedTitle',
              defaultMessage: 'Sorry, this verification link expired.',
            })}
          </H2>
          <P>
            {intl.formatMessage({
              id: 'EmailVerificationLanding.FailedContent',
              defaultMessage:
                'For security purposes, this verification link has expired. Resend the link to confirm your email!',
            })}
          </P>
          <Button
            type="button"
            trackName="Resend device verification email"
            onClick={resendVerificationEmail}
          >
            {(() => {
              switch (emailStatus) {
                case 'not sent':
                  return intl.formatMessage({
                    id: 'EmailVerificationLanding.NotSentButton',
                    defaultMessage: 'Resend email',
                  });
                case 'pending':
                  return <Spinner variant="large" />;
                case 'sent':
                  return intl.formatMessage({
                    id: 'EmailVerificationLanding.SentButton',
                    defaultMessage: 'Email sent, check your inbox.',
                  });
                case 'failed':
                  return intl.formatMessage({
                    id: 'EmailVerificationLanding.FailedButton',
                    defaultMessage:
                      'Error sending email - check your connection?',
                  });
              }
            })()}
          </Button>
        </React.Fragment>
      );
    default:
      return null;
  }
};
